
import { ComponentProps, FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import PathCourseSidebarComponent from '../../../components/path-components/path-course-components/path-course-sidebar-component';
import PathCourseContentComponent from '../../../components/path-components/path-course-components/path-course-content-component';
import { useParams } from 'react-router';
import { getSideBarSectionContent, setStudentProgress } from '../../../global-state/actions/student-training-actions';
import { getDetailsSubscriptions } from '../../../global-state/actions/course-actions';
interface RouteParams {
    idCourse: any
}
const CourseContentPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { idCourse } = useParams<RouteParams>();
    const [courseContent, setCourseContent] = useState<any>(undefined);
    const [sections, setsections] = useState<any>(undefined)
    const [followId, setFollowId] = useState<number>()
    const [sectionsIndex, setSectionsIndex] = useState<number>(0)
    const [currentSection, setCurrentSection] = useState<any>(undefined)
    const [courseProgress, setCourseProgress] = useState<any>(null);


    useEffect(() => {
        if (props?.currentTrainingProgress && props?.currentTrainingProgress !== null) {
            setCourseProgress(props?.currentTrainingProgress)
        } else {
            setCourseProgress(null)
        }
    }, [props?.currentTrainingProgress])

    useEffect(() => {
        if (props?.sections !== sections) {
            setsections(props?.sections)
        }
    }, [props?.sections])

    useEffect(() => {
        if (courseContent !== props?.course) {
            setCourseContent(props?.course);
            getCourseOfferFn(props?.course?.id, props?.connectedUser?.idPerson)
            setFollowId(props?.course?.followUp?.id)

        }

    }, [props?.course])

    useEffect(() => {
        if (sections != undefined) {
            getContent(sectionsIndex)
        }

    }, [sections])

    const getContent = (index: number) => {
        setSectionsIndex(index);
        if (currentSection != sections[index]) {
            setCurrentSection(sections[index])
            if (sections[index]?.progression?.progress !== "COMPLETED" && sections[index]?.video?.length === 0) {
                chapterProgression(sections[index])
            }
        }
        props?.getSideBarSectionContent(sections[index], index, sections[index]?.type, followId);
    }
    const chapterProgression = async (progressionState: any) => {
        let prog = {
            id: (progressionState?.progress !== null) ? progressionState?.progress?.id : null,
            chapterId: progressionState?.id,
            follow: { id: courseContent?.followUp?.id },
            progress: "COMPLETED",
            duration: 100,
            progressTrainingId: (courseContent?.followUp?.progressTraining === null) ? null : courseContent?.followUp?.progressTraining?.id,
            entityId: courseContent?.id,
        }
        if ((progressionState?.progress === null || progressionState?.progress?.progress !== "COMPLETED") && courseContent != null && courseContent != undefined) {
            await props?.setStudentProgress(prog, courseContent, courseProgress, props?.listFollowUps);
        }
    }
    const getCourseOfferFn = async (courseId: number, userId: number) => {
        let object = {
            id: courseId,
            idPerson: userId
        }
        await props?.getDetailsSubscriptions(object)

    }

    return (
        <div style={{ margin: "auto", display: "flex", flexDirection: "row" }}>
            <div style={{ backgroundColor: "#FFFFFF" }}>
                <PathCourseSidebarComponent courseSections={sections} getContent={getContent}/>
            </div>
            < PathCourseContentComponent courseSections={sections} getTrainingContent={getContent} currentSection={currentSection} />
        </div>
    );
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    getSideBarSectionContent,
    setStudentProgress,
    getDetailsSubscriptions

}, dispatch);

const mapStateToProps = (state: AppState) => ({
    course: state.studentTraining.currentCourse,
    connectedUser: state.authReducer.connectedUser,
    training: state.studentTraining.currentTraining,
    listFollows: state.studentTraining.listFollowUps,
    currentCourseTraining: state.studentTraining.currentTrainingProgress,
    sections: state.studentTraining.sections
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseContentPage);

