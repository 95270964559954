import "../../translations/i18n";
import { useTranslation } from 'react-i18next';
import { ComponentProps, FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { useHistory } from "react-router-dom";
import { setCurrentQuestion } from "../../global-state/actions/exam-actions";
import { returnStartPathUrl } from "../../helpers/domainCheck";

const HeaderPassExam: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const [currentQuestion, setCurrentQuestion] = useState<any[]>([])
    const [index, setIndex] = useState(0);
    const [nbrQuestions, setNbrQuestions] = useState();
    const history = useHistory();
    const [actualTab, setTab] = useState<string>(props?.tabProgressManager);
    const [course, setCourse] = useState<any>({});
    const [examType, setExamType] = useState(props?.section?.sectionContent?.qnaType);
    const [leftLoader, setLeftLoader] = useState<boolean>(false)
    const [rightLoader, setRightLoader] = useState<boolean>(false)

    useEffect(() => {
        setIndex(props?.indexQuestionToPass);
    }, [props?.indexQuestionToPass])

    const nextQuestion = (nextValue: any, side: string) => {
        if (side === "right") {
            setRightLoader(true)
        }
        else {
            setLeftLoader(true)
        }
        props?.setCurrentQuestion(nextValue).then(() => {
            setLeftLoader(false)
            setRightLoader(false)
        })
    }

    useEffect(() => {
        setCurrentQuestion(props?.listOfQuestions);
        setNbrQuestions(props?.listOfQuestions?.length);
        // setTitleExam(props?.listOfQuestions[index]?.question?.questionStatement)
    }, [props?.listOfQuestions])

    useEffect(() => {
        setCourse(props?.currentCourse ? props?.currentCourse : props?.currentPath);

    }, [props?.currentCourse, props?.currentPath])

    const goBackToList = () => {
        history.push(`${returnStartPathUrl()}/courses/my-courses`);
        props?.setActualTabManageProgress("previewProgress");
    }

    useEffect(() => {
        if (typeof props?.section?.qnaType != undefined) {
            setExamType(props?.section?.sectionContent?.qnaType)
        }

    }, [props?.section])

    return (
        <div className={props.isMobile ? "bg-with-border grey-backgounrd" : "bg-with-border grey-backgounrd p-4"}>
            {props.isMobile && <div className="navbar navbar-expand-lg  d-flex justify-content-between p-0 ">
                <div className="d-flex  " style={{ borderBottom: "1px solid #F2F2F5" }}>
                    <div className="col-2 px-4">
                        <a onClick={() => { goBackToList() }} className="d-flex justify-content-left align-items-center nav-back">
                            <span className="material-icons-round">
                                chevron_left
                            </span>
                        </a>
                    </div>
                    <div className="d-flex justify-content-center col-9">
                        <div className="cours-nav">
                            <div className="course-title">
                                {course && course.title}
                            </div>
                            <div className="sub-title">
                                {course && (course.type === "COURSE" ? (" " + t('courseBy')) : course.type === "PATH" ? (" " + t('pathBy')) : (" " + t('sessionBy'))) + " " + course.personName}
                            </div>
                        </div>
                    </div>

                </div>

            </div>}
            {props.isMobile && (examType === "KNOWLEDGETEST") && <div className="text-center">
                <div className='py-3 H3-Headline' style={{ borderBottom: "1px solid #F2F2F5" }}>Test de connaissance</div>

            </div>}

            {props.isMobile && (examType === "FINALEXAM") && <div className="text-center">
                <div className='py-3 H3-Headline' style={{ borderBottom: "1px solid #F2F2F5" }}>Examen</div>

            </div>}
            <div className={props.isMobile ? "d-flex flex-row justify-content-between mx-2 my-4" : "d-flex flex-row justify-content-between mx-2"}>
                <div className=" d-flex align-items-center justify-content-start me-4">
                    {!leftLoader ? <button disabled={index === 0} type="button" className="btn-QuickActions" onClick={() => { nextQuestion(index - 1, "left") }}><span className="material-icons-outlined">keyboard_arrow_left</span></button>
                        :
                        <button className="btn-QuickActions" style={{ padding: 0 }}>
                            <svg className="spinner" viewBox="0 0 50 50">
                                <circle className="path" cx="25" cy="25" r="14" fill="none" strokeWidth="2"></circle>
                            </svg>
                        </button>
                    }
                </div>
                <div className="d-flex flex-column" >
                    <div className=" d-flex flex-row justify-content-center">
                        <span className="H3-Headline mb-2 pb-1"><span className="H3-Headline neutral-3">{t('Question')} </span> {index + 1}<span className="H3-Headline neutral-3">/ {nbrQuestions} </span></span>
                    </div>
                    <div className=" d-flex flex-row justify-content-center">
                        <span className="H4-Subtitle bold text-center">{(currentQuestion && currentQuestion[index]) && currentQuestion[index]?.question?.questionStatement}</span>
                    </div>
                </div>
                <div className=" d-flex align-items-center justify-content-end ms-4">
                    {!rightLoader ?
                        <button disabled={index === currentQuestion?.length - 1} type="button" className="btn-QuickActions" onClick={() => { nextQuestion(index + 1, "right") }}>
                            <span className="material-icons-outlined">keyboard_arrow_right</span>
                        </button>
                        :
                        <button className="btn-QuickActions" style={{ padding: 0 }}>
                            <svg className="spinner" viewBox="0 0 50 50">
                                <circle className="path" cx="25" cy="25" r="14" fill="none" strokeWidth="2"></circle>
                            </svg>
                        </button>
                    }
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: AppState) => ({
    listOfQuestions: state.examReducer.questionsToPass,
    indexQuestionToPass: state.examReducer.indexQuestionToPass,
    currentCourse: state.studentTraining.currentCourse,
    currentPath: state.studentTraining.currentTraining,
    section: state.studentTraining.sectionContentDetails,

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators({
        setCurrentQuestion
    }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HeaderPassExam);

