import { ComponentProps, FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import { redirect, clearHistoryArray} from "../../global-state/actions/breadcrumb-actions";
import { getCourse, getCoursesForDirector, getCourseTests, getPendingCoursesForDirector, getRegistrationForm } from "../../global-state/actions/course-actions";
import { getTrainingById } from "../../global-state/actions/training-actions";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from "../../global-state/store/root-reducers";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router";
import TableWithExpandElementNotVisible from "../../elements/table/table-with-expand-element-not-visible";
import FilterAllCoursesComponent from "../filters/filter-all-courses-component";
import Axios from '../../helpers/interceptors';
import EmptyListElement from "../../elements/empty-list/normal-empty-list-element";
import DefaultTitle from '../../helpers/default-title'
import { returnStartPathUrl } from "../../helpers/domainCheck";


const TablePendingComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {

    const { t } = useTranslation();
    const [atBottom, setAtBottom] = useState<boolean>(false);
    const status = [
        { label: t('Avalider'), value: "Pending", color: "warning" },
        { label: t('Enligne'), value: "PUBLISHED", color: "primary" },
        { label: t('Archive'), value: "Archive", color: "secondary" },
        { label: t('Refuse'), value: "Refused", color: "danger" },
        { label: t('Deactivate'), value: "Deactivate", color: "dark" }
    ]

    const history = useHistory();
    const [courses, setCourses] = useState<any>();
    const [filteredCourses, setFilteredCourses] = useState<any>();
    const [loadingPending, setLoadingPending] = useState(true);
    const [pageNumberPending ,setPageNumberPending] = useState(props?.pageNumberPending);
    const [isFilteredByProf, setIsFilteredByProf] = useState<boolean>(false);

    useEffect(() => {
        setPageNumberPending(props?.pageNumberPending)
    }, [props?.pageNumberPending]);


    const headers = [
        { title: t('TitleFormations'), sorted: true, classNames: "w-32", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t('Professeurs'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t('typeTable'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t("creationDate"), sorted: true, classNames: "w-25", scope: "col", buttonClasses: "d-flex button-trie px-0 " },
        { title: t('statusFilter'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 ms-auto me-3" },
    
    ]

    const handleScroll = async () => {

        if (!(atBottom) &&  !(setIsFilteredByProf) && (window.innerHeight + window.scrollY + 1) >= document.body.offsetHeight && props?.pendingcourses?.length < props?.LengthCoursesPending && !loadingPending) {
            setAtBottom(true);

            let filterPending = {
                entityId: props?.user?.idSchool,
                entityType: 'SCHOOL',
                pageSize: 12,
                pageNumber: pageNumberPending,
                status:"PENDING"
            }

            await props?.getPendingCoursesForDirector(filterPending, 'no')

            document.removeEventListener('scroll', handleScroll);
        }



        if ((window.innerHeight + window.scrollY) <= document.body.offsetHeight) {
            setAtBottom(false);
        }

    }
    const trainingTitle:any = {
        "COURSE": t('Cours'),
        "PATH": t('parcoursTitle'),
        "SESSION": t('sessionTitle')
    }

useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    return () => {
        document.removeEventListener('scroll', handleScroll);
    }
})

useEffect(() => {
    setLoadingPending(props?.loadingPendingCoursesScroll)
}, [props?.loadingPendingCoursesScroll]);

const doAction = async (value: any) => {
    if (value.action === 'goToPath') {
        if (value.row.course.type === 'COURSE') {
            await props?.getCourse(value.row.course.id);
            if (!!value.row.course.idRegistration && value.row.course.idRegistration !== 0) {
                await props?.getRegistrationForm(value.row.course.idRegistration);
            }
            await props?.getCourseTests(value.row.course.id);
            props?.clearHistoryArray()
            props?.redirect(value.row.course.title, `${returnStartPathUrl()}/all-courses/course/${value.row.course.id}/description`)
            history.push(`${returnStartPathUrl()}/all-courses/course/${value.row.course.id}/description`);
        } else if (value.row.course.type === 'PATH') {
            props?.clearHistoryArray()
            props?.redirect(value.row.course.title, `${returnStartPathUrl()}/all-courses/path/${value.row.course.id}/description`)
            await props?.getTrainingById('paths', value.row.course.id);
            if (!!value.row.course.idRegistration && value.row.course.idRegistration !== 0) {
                await props?.getRegistrationForm(value?.row?.course?.idRegistration);
            }
             history.push(`${returnStartPathUrl()}/all-courses/path/${value.row.course.id}/description`);
        } else {
            props?.clearHistoryArray()
            props?.redirect(value.row.course.title, `${returnStartPathUrl()}/all-courses/session/${value.row.course.id}/description`)
            await props?.getTrainingById('sessions', value.row.course.id);
             history.push(`${returnStartPathUrl()}/all-courses/session/${value.row.course.id}/description`);
        }
    }
}
const doFilterPending = (filter: any) => {
    if(filter.searchProfessors) {
        if(filter.searchProfessors !== 0) {setIsFilteredByProf(true)} else {setIsFilteredByProf(false)}

    } 
  }

let getData = (tab: string) => {

    Axios.get(`${process.env.REACT_APP_BASE_URL2}person/all/${props?.user?.idSchool}/profs`).then(response => {

        const dataPending = {
            headers: headers,
            body: props?.pendingCourses?.map((course: any) => {
                const actualStatus: any = status.find(obj => obj?.value?.toLowerCase() === course?.status?.toLowerCase());
                return {
                    row: {
                        course: course,
                        actionName: "",
                        classNames: "table-white",
                        columns: [
                            { content: <DefaultTitle title={course?.title} type={course?.type}/>, classNames: "w-30", actionName: 'goToPath' },
                            { content: course.personName , classNames: "w-25", actionName: 'goToPath' },
                            { content: trainingTitle[course.type], classNames: "w-16", actionName: 'goToPath' },
                            { content: course.creationDate.substring(0,10).split("-").reverse().join("/") , actionName: 'goToPath' },
                            { content: t(actualStatus?.label), classNames: ` text-end badge-text ${actualStatus?.color}`, actionName: 'goToPath' },

                        ],

                    }
                }
            })
        }
        setCourses(dataPending);
        setFilteredCourses(dataPending);
    })
}




useEffect(() => {
    getData(props?.actualTabAllCourses);
}, [props?.actualTabAllCourses]);

useEffect(() => {
    getData(props?.actualTabAllCourses);
}, [props?.pendingCourses]);

return (
    <div className="container-fluid">
        <div className="row justify-content-center">
        {(props?.LengthCoursesPending > 0) ?

            <><div className="col-md-12">
                    <FilterAllCoursesComponent doFilter={(filter: any) => doFilterPending(filter)} pageNumber={pageNumberPending} />
                </div><div className="col-md-12 mt-5">
                        <TableWithExpandElementNotVisible data={filteredCourses} doAction={(value: any) => { doAction(value); } } size={props?.LengthCoursesPending} />
                        {(props?.pendingcourses?.length > 0 && loadingPending) &&
                            <div className="d-flex w-100 align-items-center justify-content-center">
                                <div className="m-3">
                                    <svg className="spinner" viewBox="0 0 50 50">
                                        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                                    </svg>
                                </div>
                            </div>}
                    </div></>:   
                <div className="w-100 mt-5 pt-5">
                
                    <div className="msg-formation-list-empty">
                        <EmptyListElement lines={[t('FormationAValider')]} />
                    </div>
              </div>
         }  
        </div >
    </div>
);



}

const mapStateToProps = (state: AppState) => ({
    pendingCourses: state.courseReducer.pendingCourses,
    user: state.authReducer.connectedUser,
    actualTabAllCourses: state.courseReducer.actualTabAllCourses,
    LengthCourses: state.courseReducer.LengthCourses,
    loadingPendingCourses: state.courseReducer.loadingPendingCourses,
    loadingPendingCoursesScroll: state.courseReducer.loadingPendingCoursesScroll,
    LengthCoursesPending: state.courseReducer.LengthCoursesPending,
    pageNumberPending: state.courseReducer.pageNumberPending
});


const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        { redirect, clearHistoryArray, getCoursesForDirector, getPendingCoursesForDirector, getCourse, getCourseTests, getRegistrationForm, getTrainingById }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TablePendingComponent);
