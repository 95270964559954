import { ComponentProps, FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { AppState } from "../../../global-state/store/root-reducers";
import ManageCommunityProgressComponent from '../community-progress/manage-community-progress-community';
import ListCardCommentStudent from '../../community/course-community/comments/list-card-comment-student';
import CommentCardLoading from '../../community/course-community/comments/comment-card/comment-card-loading';
import {setActualTabManageChat,getSubjectCommunity} from '../../../global-state/actions/chat-actions';


let outsideTab = "";
let outsidepageDiscussion = 0;
let outsidepageQuestion = 0;
let outsideTrie = "";
let outsidesearchFilter = "";
let outsideAtBottom = false;
const CommunityProgressComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {

    const [actualTab, setActualTab] = useState<string>("discussion")
    const [trie, setTrie] = useState("recent");
    const [searchFilter, setSearchFilter] = useState("");
    const [doSearch, setDoSearch] = useState(false);
    const [pageDiscussion, setPageDiscussion] = useState(0);
    const [pageQuestion, setPageQuestion] = useState(0);
    const [atBottom, setAtBottom] = useState(false);
    const [refresh, setRefresh]= useState(false);
    const [chapter, setChapter] = useState<any>();
    outsideTab = actualTab;
    outsidepageDiscussion = pageDiscussion;
    outsidepageQuestion = pageQuestion;
    outsideTrie = trie;
    outsidesearchFilter = searchFilter;
    outsideAtBottom = atBottom;
    useEffect(() => {
        setRefresh(props?.refresh)
    }, [props?.refresh]);
    useEffect(() => {
        const element: any = document?.getElementById('communityScroll');
        if(element){
        element.addEventListener('scroll', handleScroll, { passive: true });
        //changeList("recent","", 0, true, false)
        setActualTab("discussion");
        return () => {
            element.removeEventListener('scroll', handleScroll);
            props?.setActualTabManageChat("discussion");
            setTrie("recent");
            setDoSearch(false);
            setSearchFilter("");
            setPageDiscussion(0);
            setPageQuestion(0);
        }
    }
    }, [])
    const handleScroll = (event: any) => {
        if (!(outsideAtBottom) && isBottom()) {
            setAtBottom(true);
            changeList(outsideTrie, outsidesearchFilter, (outsideTab === 'discussion') ? outsidepageDiscussion + 1 : outsidepageQuestion + 1, false, false);
            if (outsideTab === 'discussion') {
                setPageDiscussion(outsidepageDiscussion + 1)
            } else {
                setPageQuestion(outsidepageQuestion + 1)
            }
        }
        if (!isBottom()) {
            setAtBottom(false);
        }
    }
    const isBottom = () => {
        const element: any = document?.getElementById('communityScroll');
        console.dir(element)

        return ((element.offsetHeight + element.scrollTop) >= element.scrollHeight);
    }
    useEffect(() => {
        if(!(chapter && props?.chapter?.chapterId === chapter?.chapterId)) {
            changeList("recent","", 0, true, false)
        }
        setChapter(props?.chapter);
    }, [props?.chapter])
    const changeList=(trieInput:string, searchFilterInput: string, page: number,  isSearch: boolean, refreshInput: boolean)=>{

        if (actualTab==="discussion"){
            let subjects=props?.chapter?.id + " CHAPTER FORUM";
            let object={
                subject: [subjects],
                trie:trieInput, 
                searchFilter:searchFilterInput,
                page:page,
                refresh: refreshInput,
                
            }
            if(typeof object!= undefined && object?.subject?.length!=0){
            props?.getSubjectCommunity(object,props?.connectionUser.idSchool, isSearch);
            }
        }else if(actualTab==="question"){
            let subjects=props?.chapter?.id + " CHAPTER QUESTION";
            let object={
                subject: [subjects],
                trie:trieInput,
                searchFilter:searchFilterInput,
                page:page,
                refresh: refreshInput,
               
            }
            if(typeof object!= undefined && object?.subject?.length!=0){
            props?.getSubjectCommunity(object,props?.connectionUser.idSchool, isSearch);
            }
            }
        }
    const Search = (doSearchInput: any) => {
        switch (outsideTab) {
            case 'discussion':
                setPageDiscussion(0);
                break;
            case 'question':
                setPageQuestion(0);
                break;
        }
        setTrie(doSearchInput.trie);
        setSearchFilter(doSearchInput.searchFilter)
        setDoSearch(true);
        changeList(doSearchInput.trie, doSearchInput.searchFilter, 0, true, true);
    }
    return (
        <>
            <ManageCommunityProgressComponent Searching={Search} chapter={props?.chapter} />
            <div style={{ maxWidth: '1200px', margin: 'auto' }}>
                {refresh ?
                    <div className="col-md-10">
                        <CommentCardLoading />
                        <CommentCardLoading />
                        <CommentCardLoading />
                        <CommentCardLoading />
                    </div> :
                    <>
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-12 p-0 mt-5">
                                <ListCardCommentStudent from = {"communityProgress"} isMobile={props.isMobile} isSearch={doSearch} what={actualTab} postsList={props?.listPosts} chapter={props?.chapter}/>
                            </div>
                        </div>
                    </>}

            </div>
        </>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    tabChatManager: state.chatReducer.tabChatManager,
    connectionUser: state.authReducer.connectedUser,
    listPosts: state.chatReducer.listPosts,
    refresh: state.chatReducer.refreshPosts,
    chapter: ownProps?.chapter

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators({
        setActualTabManageChat,
    getSubjectCommunity
    }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(CommunityProgressComponent);

