import { FC, useState, ComponentProps, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { AppState } from "../../global-state/store/root-reducers";
import CardFeedbackPreview from "../feedback/card-feedback-preview";
import { getAllFeedbacksApi } from "../../services/feedBack.service";
import { useParams } from "react-router";


const ListFeedbackSectionComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(0)
  const [feedBacks, setFeedBacks] = useState<any>(0)
  const { id } = useParams<any>();
  const readMore = async (numberPage: number) =>{
    setPage(numberPage);
    getAllFeedbacks(numberPage,'concat')
  }

  const getAllFeedbacks = async (page: number, action: string) =>{
    const result = await getAllFeedbacksApi(id, page, action)
    setFeedBacks(result)
  }

  useEffect(()=>{
    if(id!==undefined && typeof id === 'number'){
    getAllFeedbacks(page,'refresh')
    }
  },[])

  return (
    <>
      <div >
        {(feedBacks?.totalItems!==0) && <>
          <div className="d-flex align-items-center justify-content-center mt-5">
            <h3 className="H3-Headline black-800  text-center">{t('avis')+" ("+feedBacks?.totalItems ? feedBacks?.totalItems : ""+")"}</h3>
          </div>
          <div style={{maxWidth:1200, margin:'auto'}} className="mb-4">
            <hr style={{border:' 1px solid #F2F2F5', width: '100%'}}/>
            { (feedBacks?.feedbacks?.length < feedBacks?.totalItems) && <button className="btn btn-offline-dark offset-5 border-bottom  mt-5" onClick={(e)=>readMore(page + 1)}> {t('nextCommentsFeedback')}</button>}
          </div>
        {feedBacks?.feedbacks?.map((feedback:any) => {
          return <div style={{maxWidth:1200, margin:'auto'}}><CardFeedbackPreview feedback={feedback} /></div>
        })}
        
        </>}
          <div className="d-flex align-items-center justify-content-center mt-5 mb-5">
            <h3 className="H3-Headline black-800  text-center">{t('avis')}({feedBacks?.totalItems ? feedBacks?.totalItems : 0 })</h3>            
          </div>
      </div>
    </>
  )
}

const mapStateToProps = (state: AppState, ownProps: any) => ({
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators( { }, dispatch );

export default connect(mapStateToProps, mapDispatchToProps)(ListFeedbackSectionComponent);