import axiosInstance from '../../helpers/interceptors';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { IReduxActivityState } from '../reducers/activity-reducer'
import { fetchData } from '../../base/functions/Functions';
export enum EReduxActionTypes {
   GET_ACTIVITY = 'GET_ACTIVITY',
   GET_NEW_COURSES = 'GET_NEW_COURSES',
   GET_STATISTICS = 'GET_STATISTICS',
   GET_SUBSCRIPTION = 'GET_SUBSCRIPTION',
   GET_STATISTICS_CONNECTION = 'GET_STATISTICS_CONNECTION',
   GET_STATISTICS_CERTIFICATION = 'GET_STATISTICS_CERTIFICATION',
   GET_TIMES = 'GET_TIMES',
   GET_TIMES_CONNECTION = ' GET_TIMES_CONNECTION',
   GET_ACTIVITYDIRECTOR = 'GET_ACTIVITYDIRECTOR',
   GET_ACTIVITYSUBSCRIPTION = 'GET_ACTIVITYSUBSCRIPTION',
   GET_LEVEL_STUDENT = 'GET_LEVEL_STUDENT',
   GET_DIPLOMA = 'GET_DIPLOMA',
   LOADING_ACTIVITIES = 'LOADING_ACTIVITIES',
   LOADING_NEWCOURSES = 'LOADING_NEWCOURSES',
   LOADING_SUBSCRIPTION = 'LOADING_SUBSCRIPTION',
   LOADING_ACTIVITYSUBSCRIPTION = 'LOADING_ACTIVITYSUBSCRIPTION',
   LOADING_ACTIVITYDIRECTOR = 'LOADING_ACTIVITYDIRECTOR',
   LOADING_DIPLOMA = 'LOADING_DIPLOMA',
   GET_LIST_COURSES = 'GET_LIST_COURSES',
   GET_GENDER_STATS = 'GET_GENDER_STATS',

}
interface IReduxBaseAction {
   type: EReduxActionTypes;
}
export interface IReduxGetActivityAction extends IReduxBaseAction {
   type: EReduxActionTypes.GET_ACTIVITY | EReduxActionTypes.LOADING_ACTIVITIES;
   data: any;
}
export interface IReduxGetDiplomaAction extends IReduxBaseAction {
   type: EReduxActionTypes.GET_DIPLOMA | EReduxActionTypes.LOADING_DIPLOMA;
   data: any;
}
export interface IReduxGetNewCoursesAction extends IReduxBaseAction {
   type: EReduxActionTypes.GET_NEW_COURSES | EReduxActionTypes.LOADING_NEWCOURSES;
   data: any;
}
export interface IReduxGetTimesConnectionAction extends IReduxBaseAction {
   type: EReduxActionTypes.GET_TIMES_CONNECTION;
   data: any;
}

export interface IReduxGetActivitySubscriptionAction extends IReduxBaseAction {
   type: EReduxActionTypes.GET_ACTIVITYSUBSCRIPTION | EReduxActionTypes.LOADING_ACTIVITYSUBSCRIPTION;
   data: any;
}

export interface IReduxGetSubscriptionAction extends IReduxBaseAction {
   type: EReduxActionTypes.GET_SUBSCRIPTION | EReduxActionTypes.LOADING_SUBSCRIPTION;
   data: any;
}
export interface IReduxGetLevelStudentAction extends IReduxBaseAction {
   type: EReduxActionTypes.GET_LEVEL_STUDENT;
   data: any;
}
export interface IReduxGetStatisticsAction extends IReduxBaseAction {
   type: EReduxActionTypes.GET_STATISTICS;
   data: any;
}
export interface IReduxGetStatisticsConnection extends IReduxBaseAction {
   type: EReduxActionTypes.GET_STATISTICS_CONNECTION;
   data: any;
}
export interface IReduxGetStatisticsCertificationAction extends IReduxBaseAction {
   type: EReduxActionTypes.GET_STATISTICS_CERTIFICATION;
   data: any;
}
export interface IReduxGetTimesAction extends IReduxBaseAction {
   type: EReduxActionTypes.GET_TIMES;
   data: any;
}
export interface IReduxGetActivityDirectorAction extends IReduxBaseAction {
   type: EReduxActionTypes.GET_ACTIVITYDIRECTOR | EReduxActionTypes.LOADING_ACTIVITYDIRECTOR;
   data: any;
}
export interface IReduxGetListCoursesAction extends IReduxBaseAction {
   type: EReduxActionTypes.GET_LIST_COURSES;
   data: any;
}

export interface IReduxGetGenderStatsAction extends IReduxBaseAction {
   type: EReduxActionTypes.GET_GENDER_STATS;
   data: any;
}



export function getGenderStats(schoolId: any): ThunkAction<Promise<IReduxGetGenderStatsAction>, IReduxActivityState, undefined, IReduxGetGenderStatsAction> {
   return async (dispatch: ThunkDispatch<IReduxActivityState, undefined, IReduxGetGenderStatsAction>) => {
      const baseUrl = process.env.REACT_APP_BASE_URL2;
      let stats: any = await axiosInstance.get(baseUrl + "person/countSchoolStudentGender/" + schoolId).then(res => { return res.data }).catch(err => { return 0 });

      return dispatch({
         type: EReduxActionTypes.GET_GENDER_STATS,
         data: stats
      });


   }
}

export function getActivity(list: any): ThunkAction<Promise<IReduxGetActivityAction>, IReduxActivityState, undefined, IReduxGetActivityAction> {
   return async (dispatch: ThunkDispatch<IReduxActivityState, undefined, IReduxGetActivityAction>) => {
      dispatch({ type: EReduxActionTypes.LOADING_ACTIVITIES, data: undefined })
      const baseUrl = process.env.REACT_APP_BASE_URL3;
      const baseUrl1 = process.env.REACT_APP_BASE_URL2;
      const baseUrl3 = process.env.REACT_APP_BASE_URL6;
      let connectedList: any = await axiosInstance.post(baseUrl + `kpi/students/course`, { ids: list }).then(res => { return res.data }).catch(err => {
         return {
            studentNbre: 0,
            lastDays: 0
         }
      });
      let followers: any = await axiosInstance.post(baseUrl + 'kpi/all', { ids: list }).then(res => { return res.data }).catch(err => { return 0 });
      let visit: any = await axiosInstance.post(baseUrl1 + 'visit/lastDays', { ids: list }).then(res => { return res.data }).catch(err => { return 0 });
      let appFile: any = await axiosInstance.post(baseUrl + 'kpi/appFile/course', { ids: list }).then(res => { return res.data }).catch(err => { return 0 });
      let formation: any = await axiosInstance.post(baseUrl + 'kpi/For/course', { ids: list }).then(res => { return res.data }).catch(err => { return 0 });
      let certif: any = await axiosInstance.post(baseUrl + 'kpi/Certif/course', { ids: list }).then(res => { return res.data }).catch(err => { return 0 });
      let statistic: any = await axiosInstance.post(baseUrl + 'kpi/statisticStudent/course', { ids: list }).then(res => { return res.data }).catch(err => {
         return {
            percentagemale: 0,
            percentagefemale: 0,
            avgage: 0,
            ville: []
         }
      });
      let refused: any = await axiosInstance.post(baseUrl + 'kpi/suspendue', { ids: list }).then(res => { return res.data }).catch(err => { return { suspendueNbre: 0 } });
      let markMedian: any = await axiosInstance.post(baseUrl3 + 'progress/median/course', { ids: list }).then(res => { return res.data }).catch(err => { return 0 });
      let date = localStorage?.getItem('lastConnexion') ? localStorage?.getItem('lastConnexion')?.replace(/\"/g, "")?.split("T")[0] : new Date().toISOString().split('T')[0]; 
      let newCandidate: number = 0; 
      if(date !=undefined){
         newCandidate= await axiosInstance
      .post(baseUrl + 'kpi/For/new', { times: date, ids: list })
      .then(res => { return res.data })
      .catch(err => { return 0 });
      }

      let data = {
         connectedList: connectedList,
         followers: followers,
         visit: visit,
         appFile: appFile,
         formation: formation,
         certif: certif,
         statistic: statistic,
         refused: refused,
         newCandidate: newCandidate,
         markMedian: markMedian,
      }

      return dispatch({
         type: EReduxActionTypes.GET_ACTIVITY,
         data: data
      });


   }
}

export function getDiploma(list: any): ThunkAction<Promise<IReduxGetDiplomaAction>, IReduxActivityState, undefined, IReduxGetDiplomaAction> {
   return async (dispatch: ThunkDispatch<IReduxActivityState, undefined, IReduxGetDiplomaAction>) => {
      dispatch({ type: EReduxActionTypes.LOADING_DIPLOMA, data: undefined })
      const baseUrl = process.env.REACT_APP_BASE_URL3;
      let avgDuration: any = await axiosInstance.post(baseUrl + `kpi/avgDuration`, { ids: list }).then(res => { return res.data }).catch(err => { return 0 });
      let diplomaDays: any = await axiosInstance.post(baseUrl + `kpi/diplomaDays`, { ids: list }).then(res => { return res.data }).catch(err => { return 0 });

      let data = {

         avgDuration: avgDuration,
         diplomaDays: diplomaDays
      }

      return dispatch({
         type: EReduxActionTypes.GET_DIPLOMA,
         data: data
      });


   }
}

export function getListCourses(filter: any): ThunkAction<Promise<IReduxGetListCoursesAction>, IReduxActivityState, undefined, IReduxGetListCoursesAction> {
   return async (dispatch: ThunkDispatch<IReduxActivityState, undefined, IReduxGetListCoursesAction>) => {

      const baseUrl = process.env.REACT_APP_BASE_URL14;
      let coursesList = await axiosInstance.post(baseUrl + `training/allT`, filter).then(res => { return res.data }).catch(err => { return { data: [] } });


      return dispatch({
         type: EReduxActionTypes.GET_LIST_COURSES,
         data: coursesList
      });


   }
}
export function getNewCourses(list: any): ThunkAction<Promise<IReduxGetNewCoursesAction>, IReduxActivityState, undefined, IReduxGetNewCoursesAction> {
   return async (dispatch: ThunkDispatch<IReduxActivityState, undefined, IReduxGetNewCoursesAction>) => {
      dispatch({ type: EReduxActionTypes.LOADING_NEWCOURSES, data: undefined })
      const baseUrl = process.env.REACT_APP_BASE_URL3;
      const baseUrl2 = process.env.REACT_APP_BASE_URL;
      let newInscribed: any = await axiosInstance.post(baseUrl + 'kpi/inscrit/new', { times: localStorage?.getItem('lastConnexion')?.replace(/\"/g, "")?.split("T")[0], ids: list }).then(res => { return res.data }).catch(err => { return 0 });
      let newCourses: any = await axiosInstance.post(baseUrl2 + 'courses/new', { times: localStorage?.getItem('lastConnexion')?.replace(/\"/g, "")?.split("T")[0], ids: list }).then(res => { return res.data }).catch(err => { return 0 });

      let data = {

         newInscribed: newInscribed,
         newCourses: newCourses,

      }

      return dispatch({
         type: EReduxActionTypes.GET_NEW_COURSES,
         data: data
      });


   }
}






export function getSubscription(list: any): ThunkAction<Promise<IReduxGetSubscriptionAction>, IReduxActivityState, undefined, IReduxGetSubscriptionAction> {
   return async (dispatch: ThunkDispatch<IReduxActivityState, undefined, IReduxGetSubscriptionAction>) => {
      dispatch({ type: EReduxActionTypes.LOADING_SUBSCRIPTION, data: undefined })
      const baseUrl = process.env.REACT_APP_BASE_URL3;

      let subscription: any = await axiosInstance.post(baseUrl + 'kpi/allInscribedBySchools', { ids: list }).then(res => { return res.data }).catch(err => { return 0 });

      let data = {
         subscription: subscription,


      }

      return dispatch({
         type: EReduxActionTypes.GET_SUBSCRIPTION,
         data: data
      });


   }
}


export function getLevelStudent(list: any): ThunkAction<Promise<IReduxGetLevelStudentAction>, IReduxActivityState, undefined, IReduxGetLevelStudentAction> {
   return async (dispatch: ThunkDispatch<IReduxActivityState, undefined, IReduxGetLevelStudentAction>) => {
      const baseUrl = process.env.REACT_APP_BASE_URL14;
      let levelStudent: any = await axiosInstance.post(baseUrl + `kpi/getStudentsEducationalLevelsByFilter`, { ids: list })
      .then(res => { return res.data })
      .catch(err => {
         return {
            studentNbre: 0,
            schoolingList: []
         }
      });

      let data = {

         levelStudent: levelStudent,

      }

      return dispatch({
         type: EReduxActionTypes.GET_LEVEL_STUDENT,
         data: data
      });


   }
}



export function getStatistics(year: any, list: any, schoolId: number): ThunkAction<Promise<IReduxGetStatisticsAction>, IReduxActivityState, undefined, IReduxGetStatisticsAction> {
   return async (dispatch: ThunkDispatch<IReduxActivityState, undefined, IReduxGetStatisticsAction>) => {
      const baseUrl1 = process.env.REACT_APP_BASE_URL2;
      let appFile: any = await axiosInstance.get(baseUrl1 + `person/getActifStudentPerYear/${schoolId}/${year}`)
      let visit: any = await axiosInstance.post(baseUrl1 + `visit/period`, { year: year, ids: list }).then(res => { return res.data }).catch(err => { return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] });

      let data = {
         appFile: appFile.data,
         visit: visit,

      }
      return dispatch({
         type: EReduxActionTypes.GET_STATISTICS,
         data: data
      });


   }
}
export function getStatisticsConnection(year: any, list: any): ThunkAction<Promise<IReduxGetStatisticsConnection>, IReduxActivityState, undefined, IReduxGetStatisticsConnection> {
   return async (dispatch: ThunkDispatch<IReduxActivityState, undefined, IReduxGetStatisticsConnection>) => {
      const baseUrl = process.env.REACT_APP_BASE_URL3;

      let connexion: any = await axiosInstance.post(baseUrl + `kpi/students/period/course`, { year: year, ids: list }).then(res => { return res.data }).catch(err => { return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] });

      let data = {

         connexion: connexion

      }
      return dispatch({
         type: EReduxActionTypes.GET_STATISTICS_CONNECTION,
         data: data
      });


   }
}

export function getStatisticsCertification(year: any, list: any): ThunkAction<Promise<IReduxGetStatisticsCertificationAction>, IReduxActivityState, undefined, IReduxGetStatisticsCertificationAction> {
   return async (dispatch: ThunkDispatch<IReduxActivityState, undefined, IReduxGetStatisticsCertificationAction>) => {
      const baseUrl = process.env.REACT_APP_BASE_URL3;

      let subscription: any = await axiosInstance.post(baseUrl + `kpi/inscrit/period`, { year: year, ids: list }).then(res => { return res.data }).catch(err => { return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] });
      let certification: any = await axiosInstance.post(baseUrl + `kpi/Certif/period`, { year: year, ids: list }).then(res => { return res.data }).catch(err => { return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] });

      let data = {

         subscription: subscription,
         certification: certification,

      }
      return dispatch({
         type: EReduxActionTypes.GET_STATISTICS_CERTIFICATION,
         data: data
      });


   }
}



export function getTimes(): ThunkAction<Promise<IReduxGetTimesAction>, IReduxActivityState, undefined, IReduxGetTimesAction> {
   return async (dispatch: ThunkDispatch<IReduxActivityState, undefined, IReduxGetTimesAction>) => {
      const baseUrl = process.env.REACT_APP_BASE_URL3;
      let visit: any = await axiosInstance.get(baseUrl + `kpi/years`).then(res => { return res.data }).catch(err => { return [] });
      let data = {
         visit: visit,
      }
      return dispatch({
         type: EReduxActionTypes.GET_TIMES,
         data: data
      });


   }
}
export function getTimesConnection(): ThunkAction<Promise<IReduxGetTimesConnectionAction>, IReduxActivityState, undefined, IReduxGetTimesConnectionAction> {
   return async (dispatch: ThunkDispatch<IReduxActivityState, undefined, IReduxGetTimesConnectionAction>) => {
      const baseUrl4 = process.env.REACT_APP_BASE_URL1;
      let connected: any = await axiosInstance.get(baseUrl4 + `history/years`, { headers: { Authorization: 'Bearer ' + localStorage?.getItem('access_token')?.replace(/\"/g, "") } }).then(res => { return res.data }).catch(err => { return [] });
      let data = {

         connected: connected


      }
      return dispatch({
         type: EReduxActionTypes.GET_TIMES_CONNECTION,
         data: data
      });


   }
}




export function getActivityDirector(list: any): ThunkAction<Promise<IReduxGetActivityDirectorAction>, IReduxActivityState, undefined, IReduxGetActivityDirectorAction> {
   return async (dispatch: ThunkDispatch<IReduxActivityState, undefined, IReduxGetActivityDirectorAction>) => {

      dispatch({ type: EReduxActionTypes.LOADING_ACTIVITYDIRECTOR, data: undefined })
      let subscriptionSchool: number = 0;
      const baseUrl2 = process.env.REACT_APP_BASE_URL;
      let profs: any = await fetchData('POST', baseUrl2 + `courses/profs`, { ids: [list] })
      let newProfs: any = await fetchData('POST', baseUrl2 + `courses/newProfs`, { times: localStorage?.getItem('lastConnexion')?.replace(/\"/g, "")?.split("T")[0], ids: [list] })
      //let subscriptionSchool: any = await axiosInstance.get(baseUrl + `kpi/allInscribedBySchool/${list}/`).then(res => { return res.data }).catch(err => { return 0 });
      if ((localStorage.getItem('access_token') !=undefined) && (localStorage.getItem('access_token')!=null) && (localStorage.getItem('access_token')!='') ) {
         subscriptionSchool = await fetchData('GET', process.env.REACT_APP_BASE_URL2 + `person/countSchoolActifStudent/${list}`, {})

      }

      let data = {
         profs: profs,
         newProfs: newProfs,
         subscriptionSchool: subscriptionSchool
      }

      return dispatch({
         type: EReduxActionTypes.GET_ACTIVITYDIRECTOR,
         data: data
      });


   }
}

export function getActivitySubscription(list: any): ThunkAction<Promise<IReduxGetActivitySubscriptionAction>, IReduxActivityState, undefined, IReduxGetActivitySubscriptionAction> {
   return async (dispatch: ThunkDispatch<IReduxActivityState, undefined, IReduxGetActivitySubscriptionAction>) => {
      dispatch({ type: EReduxActionTypes.LOADING_ACTIVITYSUBSCRIPTION, data: undefined })
      const baseUrl = process.env.REACT_APP_BASE_URL3;


      let subscriptionSchool: any = await axiosInstance.get(baseUrl + `kpi/allInscribedBySchool/${list}/`).then(res => { return res.data }).catch(err => { return 0 });

      let data = {

         subscriptionSchool: subscriptionSchool,

      }

      return dispatch({
         type: EReduxActionTypes.GET_ACTIVITYSUBSCRIPTION,
         data: data
      });


   }
}



