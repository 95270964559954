import { ComponentProps, FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { AppState } from '../../global-state/store/root-reducers';
import PathCourseComponent from './path-content-components/path-course-component';
import PathExamComponent from './path-content-components/path-exam-component';
import PathInPathComponent from './path-content-components/path-in-path-component';
import { get } from 'lodash';
import { getCurrentCourse, updateIndexPathSessionPass, getCurrentPath, createProgressExam, setExamResult } from '../../global-state/actions/student-training-actions';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { redirect, clearHistoryArray, addLastTrainingId } from '../../global-state/actions/breadcrumb-actions';
import { getDataFromURL } from '../../base/functions/Functions';
import { openModal } from '../../global-state/actions/modal-actions';
import { ModalIDS } from '../modals/modal-ids';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import StarOutline from '@material-ui/icons/StarOutline';
import { returnStartPathUrl } from '../../helpers/domainCheck';
import { getDetailsSubscriptions } from '../../global-state/actions/course-actions';
import axiosInstance from '../../helpers/interceptors';
import { getMyFeedbackApi } from '../../services/feedBack.service';
interface Params {
    idTraining: string
}
const iconChapter = <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 1.5715H9.48145V3.28578H16V1.5715Z" fill="#CACBCE" />
    <path d="M16 6.14283H9.48145V7.85712H16V6.14283Z" fill="#CACBCE" />
    <path d="M16 10.7143H0V12.4286H16V10.7143Z" fill="#CACBCE" />
    <path d="M16 15.2857H0V16.9999H16V15.2857Z" fill="#CACBCE" />
    <path d="M1.18519 7.85714H5.62963C5.94396 7.85714 6.24542 7.73674 6.46768 7.52241C6.68995 7.30808 6.81482 7.01739 6.81482 6.71429V2.14286C6.81482 1.83975 6.68995 1.54906 6.46768 1.33474C6.24542 1.12041 5.94396 1 5.62963 1H1.18519C0.870854 1 0.569398 1.12041 0.347133 1.33474C0.124868 1.54906 0 1.83975 0 2.14286V6.71429C0 7.01739 0.124868 7.30808 0.347133 7.52241C0.569398 7.73674 0.870854 7.85714 1.18519 7.85714ZM1.77778 2.71429H5.03704V6.14286H1.77778V2.71429Z" fill="#CACBCE" />
</svg>
const iconTest = <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.9963 8.31081V12.8756C12.9963 13.1532 12.7778 13.3784 12.508 13.3784H2.06358C1.79384 13.3784 1.57531 13.1534 1.57531 12.8756V2.12442C1.57531 1.84675 1.79384 1.6218 2.06358 1.6218H10.8301V0H2.06358C0.923454 0 0 0.950777 0 2.12442V12.8758C0 14.0494 0.923454 15 2.06358 15H12.5079C13.648 15 14.5714 14.0494 14.5714 12.8758V8.31081H12.9963Z" fill="#9DDBA9" />
    <path d="M7.83045 10.487L3.99609 6.57939L5.11657 5.43755L7.83045 8.20319L15.8805 0L17.0008 1.14184L7.83045 10.487Z" fill="#9DDBA9" />
</svg>;
const iconExam = <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.3469 3.94322V1.41788C13.3469 0.633432 12.6588 0 11.7905 0H1.57023C0.706601 0 0 0.633432 0 1.41788V12.4169C0 13.2056 0.706601 13.8432 1.57023 13.8432H9.23662V15.4121C9.23662 15.6261 9.37055 15.819 9.57376 15.9239C9.77696 16.0288 10.031 16.0246 10.2296 15.9155L12.4879 14.678L14.7463 15.9155C14.8479 15.97 14.9633 15.9994 15.0834 15.9994C15.1942 15.9994 15.3143 15.9742 15.4113 15.9239C15.6145 15.819 15.7484 15.6261 15.7484 15.4121V10.7977C16.5336 10.0594 17 9.06102 17 7.95775C16.9954 5.97356 15.4252 4.31237 13.3469 3.94322ZM9.23662 12.6686H1.57023C1.41782 12.6686 1.29313 12.5596 1.29313 12.4169V1.41788C1.29313 1.27945 1.41782 1.17458 1.57023 1.17458H11.7905C11.9429 1.17458 12.0538 1.27945 12.0538 1.41788V3.8803C9.79082 4.07326 7.9712 5.82674 7.9712 7.95356C7.9712 9.05682 8.45151 10.0594 9.23662 10.7935V12.6686ZM14.4553 14.3718L12.8343 13.4867C12.6265 13.3734 12.3586 13.3734 12.1508 13.4867L10.5297 14.3718V11.6451C11.1301 11.901 11.7952 12.0478 12.4925 12.0478C13.1899 12.0478 13.8549 11.9052 14.4553 11.6451V14.3718ZM12.4925 10.8732C10.7237 10.8732 9.2828 9.56441 9.2828 7.95775C9.2828 6.3511 10.7237 5.04229 12.4925 5.04229C14.2613 5.04229 15.7023 6.34691 15.7023 7.95775C15.7023 9.56441 14.2613 10.8732 12.4925 10.8732Z" fill="#9DDBA9" />
    <path d="M8.35911 3.10413H2.54004V4.27871H8.35911V3.10413Z" fill="#9DDBA9" />
    <path d="M6.4656 6.33429H2.54004V7.50887H6.4656V6.33429Z" fill="#9DDBA9" />
    <path d="M7.34308 9.56445H2.54004V10.739H7.34308V9.56445Z" fill="#9DDBA9" />
</svg>;

const PathProgresscontentComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const content = useMemo(() => { return props?.content }, [props?.content]);
    const { t } = useTranslation();
    const [training, setTraining] = useState<any>();
    const { idTraining } = useParams<Params>();
    const currentProgress = useMemo(() => { return props?.currentProgress }, [props?.currentProgress]);
    const [numberChilds, setNumberChilds] = useState<number>(0);
    const [numberTerminated, setNumberTerminated] = useState<number>(0);
    const [currentCourse, setCurrentCourse] = useState<any>();
    const [currentSection, setCurrentSection] = useState<any>();
    const [feedBack, setFeedBack] = useState<any>();
    const history = useHistory();
    const [offerTraining, setOfferTraining] = useState<any>(undefined)
    const [contract, setContract] = useState<any>()
    let trainingProgress = useMemo(() => { return props?.currentProgress?.progress }, [props?.currentProgress?.progress])
    let currentFollow = useMemo(() => currentCourse?.followUp?.id, [currentCourse?.followUp?.id])
    // useEffect(() => {
    //     if(props?.listFollowUps && props?.listFollowUps?.length > 0){
    //       let contract = props?.listFollowUps.filter((follow:any)=>follow?.contract!=null)[0]?.contract
    //       if(contract && contract?.status[0] && contract?.status[0]?.statusType != "ONGOING"){
    //           history.push("/courses/my-courses")
    //       }
    //     }
    //   }, [props?.listFollowUps])


    useEffect(() => {
        if (props?.currentTraining !== undefined && props?.currentTraining?.diploma === null && props?.currentTraining?.followUp?.progressTraining?.progress === 100) {
            let verif: any = props?.currentTraining?.followUp?.statusList?.filter((status: any) => status?.status === "COMPLETED")
            if (verif?.length === 0) {
                let status = {
                    "id": null,
                    "status": "COMPLETED",
                    "follow": props?.currentTraining?.followUp
                }
                axiosInstance.post(`${process.env.REACT_APP_BASE_URL3}follow/status`, status)


            }
        }

    }, [props?.currentTraining])

    useEffect(() => {
        if (props?.currentTraining && typeof props?.currentTraining != undefined && typeof props?.subjectsByPath != undefined) {
            setTraining(props?.currentTraining);
        }
    }, [props?.currentTraining, props?.currentTraining?.subjectsByPath])

    const getMyFeedback = () =>{
        const result = getMyFeedbackApi({ idPerson: props?.connectedUserDetails?.idPerson, idSubject: props?.currentTraining?.id, subjectType: 'PATH' })
        setFeedBack(result)
    }

    useEffect(() => {
        if (currentProgress?.progress === 100) {
            getMyFeedback()
        }
    }, [currentProgress])

    useEffect(() => {
        setNumberChilds(training?.subjectsByPath?.length + training?.exams?.length)
    }, [training])

    const handleOpen = () => {
        props?.openModal({ idModal: ModalIDS.DEACTIVATE_SUBSCRIPTION_MODAL, size: "md", data: { course: props?.currentTraining, contract: props?.subscription?.contract } });
    }

    const getCourseOfferFn = async () => {
        let object = {
            id: props?.currentTraining?.id,
            idPerson: props?.connectedUserDetails?.idPerson
        }

        await props?.getDetailsSubscriptions(object)

    }
    useEffect(() => {
        getCourseOfferFn()
    }, [props?.currentTraining])

    useEffect(() => {
        setContract(props?.subscription)
    }, [props?.subscription])

    useEffect(() => {
        if (props?.listFollowUps && props?.listFollowUps.length > 0) {
            let number = 0;
            let success = 0;
            props?.listFollowUps?.map((obj: any) => {
                if ((obj?.id !== Number(idTraining) && obj?.ref?.split('.').filter((child: any) => child === 'subjectsByPath').length === 1)) {

                    number++;
                    if (obj?.progressTraining && obj?.progressTraining !== null && obj?.progressTraining?.progress === 100) {
                        success++;
                    }
                }
            });

            props?.currentTraining?.exams?.map((exam: any) => {
                if (exam?.progression?.status === "COMPLETED") {
                    success++
                }
            })
            setNumberTerminated(success);
            let idFollow = Math.min(...props?.listFollowUps?.filter((obj: any) => obj.courseId !== null).map((obj: any) => { return obj.id }));
            if (props?.currentTraining?.followUp?.progressTraining && props?.currentTraining?.followUp?.progressTraining !== null && props?.currentTraining?.followUp?.progressTraining?.lastProgression !== 0) {
                idFollow = props?.currentTraining?.followUp?.progressTraining?.lastProgression;
            }
            let ref = props?.listFollowUps?.find((obj: any) => obj?.id === idFollow)?.ref;
            ref = ref?.split('.');
            ref?.pop();
            ref = ref?.join('.');
            setCurrentCourse(get(props?.currentTraining, ref))
        }
    }, [props?.listFollowUps])
    const sortByOrder = (a: any, b: any) => {
        if (a?.index < b?.index) {
            return -1;
        }
        if (a?.index > b?.index) {
            return 1;
        }
        return 0;
    }
    useEffect(() => {
        if (currentCourse) {
            const copyCourse = { ...currentCourse };
            let index = 0;
            const sections = copyCourse?.orders?.concat(copyCourse?.exams)?.sort(sortByOrder);
            let section: any = sections[index];
            let course = props?.listFollowUps?.find((obj: any) => obj?.courseId === copyCourse?.id);
            if (course && course?.progressTraining && course?.progressTraining !== null && course?.progressTraining?.lastProgression !== 0) {
                if (course?.progressTraining?.lastProgression === sections?.length) {
                    section = sections[course?.progressTraining?.lastProgression - 1];
                } else {
                    section = sections[course?.progressTraining?.lastProgression];
                }
            }
            if (section?.chapter) {
                const indexChapter = copyCourse?.orders?.sort(sortByOrder)?.findIndex((obj: any) => section?.id === obj?.id);
                section = { type: 'Chapitre ' + (indexChapter + 1), title: section?.chapter?.title, icon: iconChapter };
            } else {
                const indexExam = copyCourse?.exams?.sort(sortByOrder)?.findIndex((obj: any) => section?.id === obj?.id);
                section = { type: (section?.qnaType === "KNOWLEDGETEST") ? 'Test ' + (indexExam + 1) : 'Exam ', title: section?.title, icon: (section?.qnaType === "KNOWLEDGETEST") ? iconTest : iconExam }
            }
            setCurrentSection(section);
        }
    }, [currentCourse]);

    const clickedCourse = async (idFollow: number) => {
        if (idFollow === 0 || currentCourse?.followUp?.id === undefined || currentCourse?.followUp?.id === null) {
            await props?.updateIndexPathSessionPass(currentCourse?.followUp?.id, training?.followUp?.id);
            await props?.getCurrentCourse(currentCourse?.followUp?.id);
            await props?.addLastTrainingId(training?.followUp?.id)
            await history.push(`${returnStartPathUrl()}/passPath/passCourse/${currentCourse?.followUp?.id}`);
        } else {
            await props?.updateIndexPathSessionPass(idFollow, training?.followUp?.id);
            await props?.getCurrentCourse(idFollow);
            await props?.addLastTrainingId(training?.followUp?.id)
            await history.push(`${returnStartPathUrl()}/passPath/passCourse/${idFollow}`);
        }
    }

    const clickedExam = async (exam: any) => {
        await props?.getCurrentPath(training);
        props?.clearHistoryArray()
        props?.redirect(props?.currentTraining?.title, `${returnStartPathUrl()}/passPath/progressPath/${props?.currentTraining?.followUp?.id}`)
        props?.redirect(exam?.title, `${returnStartPathUrl()}/exam/pass/${exam.id}`)

        if (exam?.progression === null) {
            await props?.createProgressExam({ id: null, entityType: props?.currentTraining?.type, entityId: props?.currentTraining?.id, idStudent: props?.connectedUserDetails?.idPerson, idQna: exam?.id, idFollow: props?.currentTraining?.followUp?.id })
        }
        if (exam?.progression !== null && (exam?.progression?.status === 'COMPLETED' || exam?.progression?.status === 'TOREVIEW')) {
            await props?.setExamResult(exam);
            history.push({ pathname: `${returnStartPathUrl()}/passPath/resultExam/${exam.id}` });
        } else {
            history.push({ pathname: `${returnStartPathUrl()}/exam/pass/${exam.id}` });
        }
    }

    return (
        <div style={{ maxWidth: "1200px", margin: "auto" }}>

            <div className="mb-4" style={{ marginTop: "40px" }}>
                <div className={props.isMobile ? 'd-none' : 'd-flex mx-2 justify-content-between'}
                >
                    <div className="bg-with-border p-4 progress-percent-card me-4 " style={{ width: 468 } && props.isMobile && { display: "none" }}>
                        <div className="d-flex flex-column mx-2">
                            <span className="H3-Headline black-800 mb-3 " style={(trainingProgress !== 100) ? { textTransform: 'capitalize' } : {}}>{(trainingProgress !== 100) ? t('progress') : t('congratsTraining')}</span>
                            {(trainingProgress !== 100) ? <>
                                <div className="d-flex flex-row align-items-center justify-content-between">
                                    <div className="progress-bar-bg w-100">
                                        <div className="progress-bar-bg success" role="progressbar" style={{ width: trainingProgress + "%" }} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}></div>
                                    </div>
                                    <span className="H3-Headline black-800 ms-2">{trainingProgress}<span className="body-sm bold black-800">%</span></span>
                                </div>
                                <span className="body-sm bold black-800">{numberTerminated}<span className="neutral-3">/{numberChilds}  {t('completedParts')}</span></span>
                            </> :
                                <div>
                                    {training?.diploma != null && <button type="button" className="btn-Secondary large icon-left" onClick={() => getDataFromURL(training?.followUp?.certificationLink, props?.connectedUserInfo?.firstName + " " + props?.connectedUserInfo?.lastName + ".pdf")}>
                                        <span className="material-icons-outlined">download</span>
                                        <div className="m-auto ps-2">{t('downloadFileCertif')}</div>
                                    </button>}
                                </div>
                            }
                        </div>
                    </div>
                    {(props?.currentTraining?.followUp?.progressTraining?.progress !== 100) ?
                        <div className="bg-with-border p-4 in-progress-card " style={{ width: '80%' }} >
                            <div className="d-flex flex-column mx-2 in-progress-card-content">
                                <span className="H3-Headline black-800 mb-4">{(currentProgress?.lastProgression === 0 && trainingProgress === 0) ? 'Premier cours' : t('Inscribed')}</span>
                                <div className="d-flex flex-row align-items-center">
                                    <div className="me-4">
                                        <img className="in-progress-card-img" src={(currentCourse?.linkImg !== null && currentCourse?.linkImg !== '') ? currentCourse?.linkImg : `${process.env.PUBLIC_URL}/imgs/no-image.png`} alt="Card cap webinar" />
                                    </div>
                                    <div className="d-flex flex-column">
                                        <span className="H4-Subtitle black-800 mb-2"> {currentCourse?.title} </span>
                                        <div className="d-flex flex-row align-items-center">
                                            {currentSection?.icon}
                                            <div className="body-sm neutral-2 ms-2"><span className="body-sm bold">{currentSection?.type} : </span>{currentSection?.title}</div>
                                        </div>
                                    </div>
                                </div>
                                <button type="button" className="btn-Primary medium in-progress-card-button" onClick={() => clickedCourse(currentProgress?.lastProgression)}>
                                    {(currentProgress?.lastProgression === 0 && trainingProgress === 0) ? 'Commencer' : 'Reprendre'}
                                </button>
                            </div>
                        </div> :
                        <div className="bg-with-border p-4 in-progress-card" style={{ width: '80%', height: 'auto' }} >
                            {feedBack ?
                                <div className="d-flex flex-column mx-2 in-progress-card-content">
                                    <span className="H4-Subtitle black-800 mb-3">{"Votre avis"}</span>
                                    <div className="d-flex align-items-top justify-content-between">
                                        <span className="body-md bold black-800 mb-2">{feedBack?.title}</span>

                                        <Box component="fieldset" borderColor="transparent" height="24px">
                                            <Rating
                                                readOnly
                                                name="customized-empty"
                                                value={feedBack?.rate}
                                                precision={0.1}
                                                size="medium"
                                                emptyIcon={<StarOutline fontSize="inherit" htmlColor="#DADBDF" />}
                                                style={{ color: "#F5B95F" }}
                                            />
                                        </Box>
                                    </div>
                                    <p className="body-md neutral-2 two-lines-paragraph pb-0 mb-0" >{feedBack?.text}</p>

                                </div> :
                                <div className="d-flex flex-column mx-2 in-progress-card-content">
                                    <span className="H3-Headline black-800">{t('donnerAvis')}</span>
                                    <span className="H3-Headline black-800 mb-4">{t('donnerAvis_3')}</span>

                                    <div className="d-flex flex-row align-items-center justify-content-between">
                                        <span className="H4-Subtitle neutral-3" > {training?.title} </span>
                                        <button type="button" className="btn-Primary medium" onClick={() => props?.openModal({ idModal: ModalIDS.GIVE_FEEDBACK_MODAL, size: "md", data: { id: training?.id, title: training?.title, type: training?.type } })}>
                                            {t('donnerAvisButton')}
                                        </button>
                                    </div>
                                </div>}
                        </div>}
                </div>
                <div className="row  mx-0 px-0" style={{ paddingTop: "40px" }}>
                    {training?.subjectsByPath?.map((content: any, index: number) => {
                        if (content?.course) {
                            return <div key={index} style={{ paddingTop: "16px" }}>
                                <PathCourseComponent isMobile={props.isMobile} width={1200} clickedCourse={clickedCourse} course={content?.course} index={index} />
                            </div>
                        }
                        if (content?.path) {
                            return <div key={index} style={{ paddingTop: "16px" }}>
                                <PathInPathComponent isMobile={props.isMobile} width={1200} clickedCourse={clickedCourse} content={content?.path} index={index} />
                            </div>
                        }
                    })}
                    {training?.exams.map((exam: any, index: number) => {
                        return <div key={index + "exam"} style={{ paddingTop: "16px" }}>
                            <PathExamComponent isMobile={props.isMobile} width={1200} clickedExam={clickedExam} exam={exam} index={training?.subjectsByPath?.length} />
                        </div>
                    })}
                </div>
            </div>
            {!!contract && !!contract?.offerTraining && contract?.offerTraining?.period !== "ONESHOT" && contract?.offerTraining?.nbPeriod == null && !!contract?.contract && contract?.contract?.contractDetails?.contractDuration?.endAt === null &&
                <button className="btn-text outlined black-800 body-md bold ms-4" onClick={handleOpen} >      
                    {t('cancelSubscription')}
                </button>
            }
        </div>

    )
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    getCurrentCourse,
    updateIndexPathSessionPass,
    getCurrentPath,
    createProgressExam,
    openModal,
    setExamResult,
    getDetailsSubscriptions,
    redirect, clearHistoryArray, addLastTrainingId
}, dispatch);

const mapStateToProps = (state: AppState) => ({
    connectedUserDetails: state.authReducer.connectedUser,
    currentTraining: state.studentTraining.currentTraining,
    currentProgress: state.studentTraining.currentProgress,
    listFollowUps: state.studentTraining.listFollowUps,
    connectedUserInfo: state.authReducer.connectedUserInfo,
    subscription: state.courseReducer.subscription
});

export default connect(mapStateToProps, mapDispatchToProps)(PathProgresscontentComponent);
