import { Slider } from '@material-ui/core';
import { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import Cropper from 'react-easy-crop'
import "../../translations/i18n";
import { useTranslation } from "react-i18next";
interface IFileSelectorCropState {
    onGetBlobFile: (blobFile: any) => void;
    imageSrc?: any;
    logoSchool?: any

}

export const ImageCropping = (props: IFileSelectorCropState) => {
    const { t } = useTranslation();
    let [imageSrc, setImageSrc] = useState<any>();
    let [fileName, setFileName] = useState<any>();
    const [counter, setCounter] = useState<number>(0)
    let [crop, setCrop] = useState({ x: 0, y: 0 })
    let [zoom, setZoom] = useState(1)

    useEffect(() => {
        setTimeout(() => {
            if (props?.imageSrc === undefined) {
                setCounter(counter + 1)
        
            } else {
          
                setImageSrc(props?.imageSrc)
            }

        }, 1000)
    }, [counter])
    const onCropChange = (crope: any) => {
        setCrop(crope)
    }

    useEffect(() => {
        setImageSrc(props?.imageSrc);

    }, [props?.imageSrc])

    const onCropComplete = (croppedArea: any, pixelCrop: any) => {

    
        const canvas = document.createElement('canvas');
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;
        const ctx: any = canvas.getContext('2d');

        let blob = dataURLtoBlobFile(imageSrc)
        let image = new Image();
        image.src = URL.createObjectURL(blob);


        //IMPORTANT: WAIT FOR IMAGE TO LOAD BEFORE TRYING TO CROP
        image.onload = () => {

            ctx.drawImage(
                image,
                pixelCrop.x,
                pixelCrop.y,
                pixelCrop.width,
                pixelCrop.height,
                0,
                0,
                pixelCrop.width,
                pixelCrop.height
            );

            new Promise((resolve, reject) => {
                canvas.toBlob((file: any) => {
                    resolve(file);
                }, 'image/jpeg');
            }).then((response: any) => {
                // send blobFile to parent
                let blob1 = new Blob([response]);
                let file = new File([blob1], new Date().getTime() + "_" + fileName);

                props?.onGetBlobFile(file);
            })
        }
    }



    const onZoomChange = (zoomm: any) => {

        setZoom(zoomm)
    }

    //**dataURL to blob** conversion
    const dataURLtoBlobFile = (dataURI: any) => {

        try {
            let byteString = atob(dataURI.split(',')[1]);
            // separate out the mime component
            let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
            // write the bytes of the string to an ArrayBuffer
            let ab = new ArrayBuffer(byteString.length);

            // create a view into the buffer
            let ia = new Uint8Array(ab);

            // set the bytes of the buffer to the correct values
            for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }

            // write the ArrayBuffer to a blob, and you're done
            let blob = new Blob([ab], { type: mimeString });
            return new File([blob], fileName);
        } catch (e) {

            let byteString = btoa(dataURI)
            // separate out the mime component

            // write the bytes of the string to an ArrayBuffer
            let abr = new ArrayBuffer(byteString.length);

            // create a view into the buffer
            let iaa = new Uint8Array(abr);

            // set the bytes of the buffer to the correct values
            for (let j = 0; j < dataURI.length; j++) {
                iaa[j] = dataURI.charCodeAt(j);
            }

            // write the ArrayBuffer to a blob, and you're done
            let blobb = new Blob([abr], { type: "image/jpeg" });
            return new File([blobb], fileName);
        }
    }

    const handleChange = (selectorFiles: FileList) => {
        let fileReader = new FileReader();
        let data;

        fileReader.onload = () => {
            data = fileReader.result;
            setImageSrc(data)
            setFileName(selectorFiles[0].name)
        };
        fileReader.readAsDataURL(selectorFiles[0]);
    }

    const onDrop = (accepted: any, rejected: any) => {
        if (Object.keys(rejected).length === 0) {

            if (props?.logoSchool === "logo")
                props?.onGetBlobFile(accepted);
            handleChange(accepted);
        }
    };

    const onDelete = (e: any) => {
        setImageSrc(null)
        props?.onGetBlobFile(null);
    }

    if (props?.logoSchool !== "logo" || props?.logoSchool === undefined) {
        return (
            <>
                {imageSrc !== null ?
                    <>
                        <div style={{ display: "flex", flexDirection: "column", width: '100%', position: "relative" }}>
                            <div style={{ position: "absolute", zIndex: 100, right: 10, top: 10 }} >
                                <svg style={{ cursor: "pointer" }} onClick={(e) => onDelete(e)} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.5 3C0.5 1.61929 1.61929 0.5 3 0.5H21C22.3807 0.5 23.5 1.61929 23.5 3V21C23.5 22.3807 22.3807 23.5 21 23.5H3C1.61929 23.5 0.5 22.3807 0.5 21V3Z" stroke="white" />
                                    <path d="M15.5 9.205L14.795 8.5L12 11.295L9.205 8.5L8.5 9.205L11.295 12L8.5 14.795L9.205 15.5L12 12.705L14.795 15.5L15.5 14.795L12.705 12L15.5 9.205Z" fill="white" />
                                </svg>
                            </div>
                            <div style={{ height: 250, width: '100%', position: "relative" }}>

                                <Cropper

                                    image={imageSrc}
                                    crop={crop}
                                    zoom={zoom}
                                    aspect={1}
                                    cropShape="round"
                                    showGrid={false}
                                    onCropChange={onCropChange}
                                    onCropComplete={onCropComplete}
                                    onZoomChange={onZoomChange}
                                    style={{ containerStyle: { "height": "250px", "width": "100%" } }}
                                />
                                <div style={{ position: "absolute", bottom: 0, width: '-webkit-fill-available', padding: '0px 40px' }}>
                                    <div className="buttons-zoom-upload d-flex">
                                        <button onClick={() => onZoomChange((zoom - 0.2))} style={{ outline: 'none', border: 'none', background: 'transparent', marginRight :'10px'}}><span className="fa fa-minus" style={{ color: 'white' }}></span></button>
                                        {imageSrc !== undefined ?
                                            <Slider
                                                value={zoom}
                                                min={1}
                                                max={3}
                                                step={0.1}
                                                aria-labelledby="Zoom"
                                                onChange={(e: any, zoom: any) => onZoomChange(zoom)}

                                            />
                                            :
                                            <></>
                                        }
                                        <button onClick={() => onZoomChange((zoom + 0.2))} style={{ outline: 'none', border: 'none', background: 'transparent', marginLeft :'10px' }} ><span className="fa fa-plus" style={{ color: 'white' }}></span></button>

                                    </div>

                                    {/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                        <span style={{ color: "black" }}>-</span>
                                        <span style={{ color: "black" }}>+</span>
                                    </div> */}
                                </div>
                            </div>

                        </div>
                    </>
                    :
                    <>
                        <div style={{ display: "flex", flexDirection: "column", cursor: 'pointer', width: '100%', position: "relative" }}>



                            <Dropzone
                                style={
                                    { width: '100%', zIndex: 1, position: "relative" }
                                }
                                multiple={false}
                                accept="image/*"
                                onDrop={(accepted, rejected) => onDrop(accepted, rejected)}
                            >
                                <><div className="upload-input-crop justify-content-middle">
                                    <div className="mx-auto" style={{ maxWidth: '384px' }}>
                                        <div className="bg-with-border dashed d-flex align-items-center justify-content-center flex-column py-5" style={{ height: '384px' }}>
                                            <span className="material-icons-outlined" style={{ color: "#DADBDF" }}>cloud_upload</span>
                                            <p className="body-md bold neutral-3 mt-3">{t('imgCrop_1')} </p>
                                            <button className="btn-Secondary large">SÉLECTIONNER</button>
                                        </div>
                                    </div>


                                </div></>
                            </Dropzone>
                        </div>
                    </>
                }
            </>
        )

    } else {
        return (
            <>
                {imageSrc !== null ?
                    <>
                        <div style={{ position: "relative" }}>
                            <div className="container upload-input-crop-logo ">
                                <div className="d-flex">
                                    <div className="d-flex justify-content-start">
                                        <img style={{ height: "64px" }} src={imageSrc} />
                                    </div>
                                    <div className="ms-auto p-2">
                                        <button className="btn btn-outline-dark grayBorder" onClick={(e) => onDelete(e)}  ><span className="material-icons-outlined">delete</span></button>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div style={{ position: "relative" }}>

                            <Dropzone
                                style={
                                    {}
                                }
                                multiple={false}
                                accept="image/*"
                                onDrop={(accepted, rejected) => onDrop(accepted, rejected)}
                            >
                                <>

                                    <div className="container upload-input-crop-logo">
                                        <div className="d-flex">
                                            <div className="p-2">
                                                <button className="btn btn-outline-dark  rounded-icon" disabled><span className="fa fa-cloud-upload" style={{ color: "#B1B1B1" }} /></button>
                                            </div>
                                            <div className="p-2" >
                                                <p className="text-upload-crop-logo"  >{t('imgCrop_3')} </p>
                                            </div>
                                            <div className="ms-auto p-2">
                                                <button type="button" className="btn-Secondary large">
                                                    {t('uploadImage_2')}
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                </>
                            </Dropzone>
                        </div>
                    </>
                }
            </>
        )
    }


}

