import { FC, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AppState } from '../../global-state/store/root-reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { deleteTraining, clearTraining, handleErrors, createTraining } from '../../global-state/actions/training-actions';
import { openModal } from '../../global-state/actions/modal-actions';

import "../../translations/i18n";
import { returnStartPathUrl } from '../../helpers/domainCheck';
import { getOfferPricingCourse } from '../../global-state/actions/course-actions';
import { ModalIDS } from '../modals/modal-ids';
import { getSurveyById } from '../../global-state/actions/sondage-actions';


const NavbarSurveyComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>> = (props) => {
    const history = useHistory();
    let idSurvey= useMemo(() => props?.idSurvey, [props?.idSurvey])
    const goTo = (where: string) => {
        history.push(`${returnStartPathUrl()}/survey/details/${props?.idSurvey}/${where}`);
    }

    const goBackToList = () => {
        history.push(`${returnStartPathUrl()}/survey`);
    }

    const closeSurvey = async()=>{   
        // props?.openModal({ idModal: ModalIDS.ARCHIVE_SONDAGE, size: 'md', data: props?.survey })

    }

    useEffect(() => {
        if(typeof idSurvey != undefined){
            props?.getSurveyById(idSurvey)
        }
    }, [idSurvey])
    

    return (

        <div className="cours-nav">
            <div className="navbar navbar-expand-lg d-flex justify-content-between p-0 flex-nowrap">
                <div className="d-flex order-0">
                    <a onClick={() => { goBackToList() }} style={{ cursor: 'pointer' }} className="d-flex justify-content-center align-items-center nav-back">
                        <span className="material-icons-round">
                            chevron_left
                        </span>
                    </a>
                    <div className="d-flex px-4 align-items-center">


                        <a onClick={() => goTo('preview')} className="d-flex justify-content-center align-items-center mx-1">
                            <span className={props?.activeTab === "preview" ? "nav-btn-black-active" : "nav-btn-black"}>
                                Sondage
                            </span>
                        </a>
                        <a onClick={() => goTo('response')} className="d-flex justify-content-center align-items-center mx-1">
                            <span className={props?.activeTab === "response" ? "nav-btn-black-active" : "nav-btn-black"}>
                                Réponses
                            </span>
                        </a>

                    </div>
                </div>




                <div className="d-flex justify-content-end order-1 w-100">

                        <button style={{ backgroundColor: "#E06A6A", border: "none" ,color:"white" , height:"64px", width:"144px"}}
                                className="d-flex justify-content-center align-items-center" 
                                onClick={()=>{closeSurvey()}}>
                                <span style={{fontFamily:"Public Sans" , fontSize:"14px", fontStyle:"normal", fontWeight:700, lineHeight:"16px", letterSpacing:"0.15px"}}>CLÔTURER</span>
                        </button>
                        </div>
            </div>
        </div>


    );
}

const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    loggedIn: state.authReducer.loggedIn,
    historySession: state.historyReducer.listHistoryBreadCrumb,
    historySessionProfesseurs: state.historyReducer.listHistoryProfesseur,
    lastSession: state.historyReducer.lastPathBeforeBreadCrumb,
    training: state.trainingReducer.session,
    activeTab: ownProps?.activeTab,
    idSurvey: ownProps?.id,
    actualOffer: state.courseReducer.actualOffer,
    courseTarificationForm: state.courseReducer.courseTarificationForm,
    // survey:state.sondageReducer.serveys

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            deleteTraining,
            getOfferPricingCourse,
            getSurveyById,
            clearTraining,
            openModal,
            handleErrors,
            createTraining
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(NavbarSurveyComponent);