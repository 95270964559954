import { FC } from 'react';
import Rating from '@material-ui/lab/Rating';
import userLogo from '../../styles/imgs/Default_user_icon.svg';
import { useTranslation } from "react-i18next";
import Box from '@material-ui/core/Box';
import StarOutline from '@material-ui/icons/StarOutline';
interface Props {
    feedback: any,
    label?: string,
}
const CardFeedbackPreview: FC<Props> = (props: Props) => {
    const dateFormat = require('dateformat');
    const { t } = useTranslation();

    return (
        <div className="my-3">
  <div className="d-flex align-items-top mb-2 w-100">
    <div className="me-3">
      <img className="img-profil small" src={(props?.feedback?.idPerson?.photo)?props?.feedback?.idPerson?.photo:userLogo} alt="profile img" />
    </div>
    <div className="d-flex flex-wrap badge-text dark align-items-center">
      <span className="body-sm bold me-3 mt-2 discuttion-titles"> {(props?.feedback.userStatus==="USER")?props?.feedback?.idPerson?.firstName+" "+props?.feedback?.idPerson?.lastName: t('Anonymous')}</span>
      <div className="d-flex flex-wrap discuttion-titles  mt-2">
        <span className="body-sm bold neutral-3 me-3">{dateFormat(props?.feedback.createdAt, "dd/mm/yyyy")}</span>
      </div>
    </div>
    <div className="d-flex flex-row mt-1 ms-auto">
      <Box component="fieldset" borderColor="transparent" height="28px" style={{margin:0 ,padding: 0}}>
      <Rating readOnly name="customized-empty" value={props?.feedback.rate} precision={0.1} emptyIcon={<StarOutline fontSize="inherit" htmlColor="#DADBDF" />}
        style={{color: "#F5B95F"}} />
    </Box>
    </div>
  </div>
  <div className="d-flex flex-column ms-5 ps-1">
    <span className="body-md bold badge-text dark">
    {props?.feedback.title}
    </span>
    <span className="body-md neutral-2 my-3">
    {props?.feedback.text}
    </span>
    <div>
    </div>
  </div>
  <hr className="my-4" style={{border:' 1px solid #F2F2F5', width: '100%'}}/>
</div>
        // <div className="container-fluid">
        //     <div className="row justify-content-center">
        //         <div className="col-8 mt-3">
        //             <hr/>
        //             <div className="card border-0 container-feedback m-0 p-0" style={{margin: 0}}>
        //                 <div className="card-body border-0 back">
        //                     <div className="row ">
        //                         <div className="col-md-8 d-flex align-items-center p-0">
        //                             <img className="circular--square" src={(props?.feedback?.idPerson?.photo)?props?.feedback?.idPerson?.photo:userLogo} alt="userImg"/>
        //                             <label className="ms-2 text-paragraph-feedback "> {(props?.feedback.userStatus==="USER")?props?.feedback?.idPerson?.firstName+" "+props?.feedback?.idPerson?.lastName: t('Anonymous')}</label>
        //                             <label className="ms-3 text-paragraph-feedback-grey "> {dateFormat(props?.feedback.createdAt, "dd/mm/yyyy")}</label>
        //                         </div>
        //                         <div className="col-md-4 text-right list-feedback p-0">
        //                         <Rating name="read-only" value={props?.feedback.rate} precision={0.1} readOnly emptyIcon={<StarBorderIcon color="primary" fontSize="inherit" />} size="small"  /> 
        //                         </div>
        //                     </div>
        //                     <div className="row">
        //                         <div className="col-md-12 mt-3 ms-4 ps-4">
        //                             <div className=" col-md-11 mb-2 ">
        //                                 <p className="text-paragraph-blod">{props?.feedback.title}</p>
        //                             </div>
        //                             <div className="col-md-12 pe-1" id="text">
        //                                 <p  className="text-paragraph-dark-gray">{props?.feedback.text}</p>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
                    
        //         </div>
        //     </div>
        // </div>
    )

}
export default CardFeedbackPreview;