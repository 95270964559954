import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import EmptyListElement from "../empty-list/normal-empty-list-element";

interface Props {
  data: any
  doAction?: any
  height?: number | string
}
export default function TableElement(props: Props) {
  const [data, setData] = useState<any>();
  const { t } = useTranslation();
  useEffect(() => {
    setData(props?.data)
  }, [props?.data]);
  const doAction = (e: any, actionName: string, row: any, index: number) => {
    if (actionName !== '') {
      e.stopPropagation();

      const object = {
        row: row,
        action: actionName,
        index: index
      }
      props?.doAction(object);
    }
  }
  return (
    <>
      <div className="mt-3" style={{minHeight: 'calc(100% - 134px)'}}>
        <div className="table-responsive d-flex mx-auto" style={{ maxWidth: 1200 }}>
          <table className="w-100 custom-table">
            {data?.body?.length > 0 && <thead className="mb-4">
              <tr>
                {data?.headers?.map((header: any) =>
                (<th key={header.title} scope={header.scope} className={header.classNames}>
                  <button className={header.buttonClasses}>
                    {t(header.title)}
                    {/* {header.sorted && <div className="d-flex flex-column">
                      <span className="material-icons-outlined trie-arrow up" >arrow_drop_up</span>
                      <span className="material-icons-outlined trie-arrow down" >arrow_drop_down</span>
                    </div>} */}
                  </button>
                </th>)
                )}
              </tr>
            </thead>}
            <tbody className="w-100" style={{ height: 30, overflow: 'scroll' }}>
              {data?.body?.map((data: any, index: number) =>
              (
                <tr key={index + 'row'} style={{ cursor: (data.row.actionName === "") ? 'default' : 'pointer' }} onClick={(e) => doAction(e, data.row.actionName, data.row, index)} className={data.row.classNames}>
                  {data.row.columns.map((column: any, index: number) =>
                  (
                    <td key={index + 'column'} onClick={(e) => doAction(e, column.actionName, data.row, index)} className={column.classNames} colSpan={column.colspan}>{column.content}</td>
                  )
                  )}
                </tr>
              )
              )}
            </tbody>
          </table>
        </div>
        {data?.body?.length === 0 && (
          <div className="msg-list-empty mt-7">
           <EmptyListElement lines={[t('noExamFound'), t('noProfessorsList_2')]} />
          </div>
        )}
      </div>
    </>
  );
}
