import { ComponentProps, FC, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AppState } from '../../../global-state/store/root-reducers';
import PathCourseComponent from './../path-content-components/path-course-component';
import { createProgressExam, getCurrentPath, setExamResult, updateIndexPathSessionPass } from '../../../global-state/actions/student-training-actions';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import PathExamComponent from './path-exam-component';
import { useHistory } from 'react-router-dom';
import { redirect, clearHistoryArray } from '../../../global-state/actions/breadcrumb-actions';
import { getDataFromURL } from '../../../base/functions/Functions';
import { returnStartPathUrl } from '../../../helpers/domainCheck';


const iconCompleted = <div className="state-cours certified"><svg className="state-cours-icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.7023 4.92903V1.77235C15.7023 0.79179 14.8927 0 13.8712 0H1.84732C0.831296 0 0 0.79179 0 1.77235V15.5212C0 16.507 0.831296 17.304 1.84732 17.304H10.8666V19.2651C10.8666 19.5326 11.0242 19.7738 11.2632 19.9049C11.5023 20.036 11.8011 20.0307 12.0348 19.8944L14.6917 18.3475L17.3485 19.8944C17.4681 19.9626 17.6039 19.9993 17.7452 19.9993C17.8756 19.9993 18.0168 19.9678 18.1309 19.9049C18.37 19.7738 18.5276 19.5326 18.5276 19.2651V13.4971C19.4512 12.5743 20 11.3263 20 9.94719C19.9946 7.46695 18.1472 5.39047 15.7023 4.92903ZM10.8666 15.8358H1.84732C1.66803 15.8358 1.52133 15.6995 1.52133 15.5212V1.77235C1.52133 1.59931 1.66803 1.46822 1.84732 1.46822H13.8712C14.0505 1.46822 14.1809 1.59931 14.1809 1.77235V4.85037C11.5186 5.09158 9.37789 7.28342 9.37789 9.94195C9.37789 11.321 9.94295 12.5743 10.8666 13.4919V15.8358ZM17.0062 17.9647L15.0992 16.8583C14.8547 16.7167 14.5395 16.7167 14.295 16.8583L12.3879 17.9647V14.5564C13.0943 14.8762 13.8767 15.0597 14.6971 15.0597C15.5175 15.0597 16.2999 14.8815 17.0062 14.5564V17.9647ZM14.6971 13.5915C12.6161 13.5915 10.9209 11.9555 10.9209 9.94719C10.9209 7.93888 12.6161 6.30286 14.6971 6.30286C16.778 6.30286 18.4732 7.93363 18.4732 9.94719C18.4732 11.9555 16.778 13.5915 14.6971 13.5915Z" fill="#9DDBA9" />
    <path d="M9.83425 3.88025H2.98828V5.34847H9.83425V3.88025Z" fill="#9DDBA9" />
    <path d="M7.60659 7.91785H2.98828V9.38607H7.60659V7.91785Z" fill="#9DDBA9" />
    <path d="M8.63892 11.9556H2.98828V13.4238H8.63892V11.9556Z" fill="#9DDBA9" />
</svg></div>
const iconOpened = <div className="state-cours unlocked-inprogress">
    <div className="threedots-small d-flex">
        <span className=""></span>
        <span className=""></span>
        <span className=""></span>
    </div>
</div>;
const iconProgress = <div className="state-cours unlocked-inprogress">
    <div className="threedots-small d-flex">
        <span className="inprogress"></span>
        <span className="inprogress"></span>
        <span className="inprogress"></span>
    </div>
</div>;
const iconClosed = <div className="state-cours locked">
<span className="material-icons-outlined neutral-4 state-cours-icon small">
  lock
</span>
</div>;
const PathInPathComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const [contentPath, setContentPath] = useState<any>();
    const [progress, setProgress] = useState<number>(0);
    const [status, setStatus] = useState<string>("");
    const history = useHistory();
    let refDiv: any = useRef(null);
    const [height, setHeight] = useState<number>(0);
    useLayoutEffect(() => {
        setHeight(refDiv?.current?.getBoundingClientRect().height);

    });
    const sortByOrder = (a: any, b: any) => {
        if ((new Date(a?.creationDate).getTime() - new Date(b?.creationDate).getTime()) > 0) {
            return -1;
        }
        if ((new Date(a?.creationDate).getTime() - new Date(b?.creationDate).getTime()) < 0) {
            return 1;
        }
        return 0;
    }
    useEffect(() => {

        setContentPath(props?.content);
        setStatus(props?.content?.followUp?.statusList?.sort(sortByOrder)[0]?.status);
        if (props?.content?.followUp?.progressTraining && props?.content?.followUp?.progressTraining !== null) {
            setProgress(props?.content?.followUp?.progressTraining?.progress);
        }
    }, [props?.content])
    const [open, setOpen] = useState(false);
    const openPathDetails = () => {
        setOpen(!open);
    }
    const clickedCourse = async (idFollow: number) => {
        await props?.updateIndexPathSessionPass(idFollow, props?.content?.followUp?.id);
        await props?.clickedCourse(idFollow);
    }
    async function clickedExam (exam: any) {
        await props?.getCurrentPath(contentPath);
        props?.clearHistoryArray()
        if (props?.currentTraining?.type === 'SESSION') {
            props?.redirect(props?.currentTraining?.title, `${returnStartPathUrl()}/passSession/progressSession/${props?.currentTraining?.followUp?.id}`)
            props?.redirect(exam?.title, `${returnStartPathUrl()}/exam/pass/${exam.id}`)
        } else {
            props?.redirect(props?.currentTraining?.title, `${returnStartPathUrl()}/passPath/progressPath/${props?.currentTraining?.followUp?.id}`)
            props?.redirect(exam?.title, `${returnStartPathUrl()}/exam/pass/${exam.id}`)
        }
        if (exam?.progression === null) {
            await props?.createProgressExam({ id: null, entityType: contentPath?.type, entityId: contentPath?.id, idStudent: props?.connectedUserDetails?.idPerson, idQna: exam?.id, idFollow: contentPath?.followUp?.id })
        }
        if (exam?.progression !== null && (exam?.progression?.status === 'COMPLETED' || exam?.progression?.status === 'TOREVIEW')) {
            await props?.setExamResult(exam);
            if (props?.currentTraining?.type === 'SESSION') {
                 history.push({ pathname: `${returnStartPathUrl()}/passSession/resultExam/${exam.id}` });
            } else {
                 history.push({ pathname: `${returnStartPathUrl()}/passPath/resultExam/${exam.id}` });
            }
        } else {
             history.push({ pathname: `${returnStartPathUrl()}/exam/pass/${exam.id}` });
        }
    }
    return (<>

        {!props.isMobile && <div className="d-flex flex-column w-100">
            <div className="d-flex flex-row align-items-center w-100">
                {props?.child && <div className="subliste-cours-ligne"></div>}
                <div className="d-flex align-items-center bg-with-border white py-28 ps-40 pe-4 w-100" style={{ maxWidth: props?.width, width: '-webkit-fill-available', position: 'relative' }}>

                    <div className="me-40">
                        {/* <span className="H3-Headline">{((props?.index + 1) < 10) ? '0' + (props?.index + 1) : (props?.index + 1)}</span> */}
                        {(props?.secondIndex === undefined) && <span className="H3-Headline">{((props?.index + 1) < 10) ? '0' + (props?.index + 1) : (props?.index + 1)}</span>}
                        {(props?.secondIndex !== undefined) && <span className="H3-Headline">{props?.index + 1}.{props?.secondIndex + 1}</span>}
                    </div>
                    <div className=" d-flex flex-row justify-content-between align-items-center detail-liste-responsive w-100">
                        <div className="d-flex align-items-center">
                            <div className="d-flex flex-column">
                                <span className="H3-Headline mb-6 formation-card horizontal title">{contentPath?.title}</span>
                                <div className="d-flex flex-row align-items-center" style={{ height: 24 }}>
                                    <div className="d-flex me-2">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.45455 2.90909H0V14.5455C0 15.3455 0.654545 16 1.45455 16H13.0909V14.5455H1.45455V2.90909ZM14.5455 0H4.36364C3.56364 0 2.90909 0.654545 2.90909 1.45455V11.6364C2.90909
                                12.4364 3.56364 13.0909 4.36364 13.0909H14.5455C15.3455 13.0909 16 12.4364 16 11.6364V1.45455C16 0.654545 15.3455 0 14.5455 0ZM14.5455 11.6364H4.36364V1.45455H14.5455V11.6364Z"
                                                fill="#FA83EE" />
                                        </svg>
                                    </div>
                                    <div className="body-sm bold neutral-2">
                                        {t('pathTitle')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" d-flex align-items-center detail-liste-responsive-actions progress-path detail-liste-responsive-session justify-content-end" style={{ minWidth: 520 }}>
                            <div className="ms-2 d-flex flex-row detail-liste-responsive-other progress-training align-items-center flex-wrap">
                                <div className="d-flex align-items-center detail-liste-responsive-level me-5 pe-3">
                                    <div className="me-4 " style={{ width: 148, textAlign: 'left' }}>
                                        <span className="body-md bold neutral-3" style={{ textTransform: 'capitalize' }}>{t('progress')} : <span className={`${(progress === 0) ? 'neutral-3' : (progress === 100) ? 'green-800' : 'black-800'}`}>{progress}%</span></span>
                                    </div>
                                    <div className="ms-2">
                                        {(status === "CLOSED" || (props?.statusParent && props?.statusParent === 'CLOSED'))? iconClosed :(progress === 0 && status === "OPENED") ? iconOpened : (status === "CERTIFIED") ? iconCompleted : iconProgress}
                                    </div>
                                </div>
                                <div className="d-flex flex-row">
                                    {(progress !== 100) ? <div className="d-flex align-items-center me-4">
                                        <span className="material-icons-outlined me-2 pe-1 neutral-3">timer</span>
                                        <span className="body-md bold">2h40</span>
                                    </div> :
                                        <button type="button" className="btn-Secondary medium icon-left" onClick={(e) => { e.stopPropagation(); getDataFromURL(contentPath?.followUp?.certificationLink) }}>
                                            <span className="material-icons-outlined">download</span>
                                            <div className="m-auto ps-2">{t('diplomeButton')}</div>
                                        </button>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="button" className="btn-QuickActions small me-2" onClick={() => openPathDetails()}>
                        {!open ?
                            <span className="material-icons-outlined">
                                expand_more</span> :
                            <span className="material-icons-outlined">
                                expand_less</span>
                        }
                    </button>
                    <div className="subliste-all-ligne" style={(open) ?
                        { height: refDiv?.current?.offsetHeight - 50 /*83 + (137 * (contentPath?.subjectsByPath?.length + contentPath?.exams?.length - 1))*/, transition: 'height .5s ease', overflowY: 'hidden' } :
                        { height: 0, transition: 'height .5s ease', overflowY: 'hidden' }}></div>
                </div>
            </div>

            <div ref={refDiv} style={(open) ?
                { minHeight: (40 + (120 * (contentPath?.subjectsByPath?.length + contentPath?.exams?.length))), transition: 'all .5s ease', overflowY: 'hidden', marginTop: 24 } :
                { minHeight: '0px', height: 0, transition: 'all .5s ease', overflowY: 'hidden', marginTop: 0 }}>
                {contentPath?.subjectsByPath?.map((content: any, i: number) => {
                    if (content?.course) {
                        return <div key={i + '' + props?.index} className="d-flex flex-row align-items-center justify-content-end mb-3" style={{ marginLeft: 55 }}>
                            <div className="subliste-cours-ligne"></div>
                            <PathCourseComponent statusParent={status} width={props?.width - 110} clickedCourse={clickedCourse} course={content?.course} index={props?.index} secondIndex={i} />
                        </div>
                    }
                    if (content?.path) {
                        return <div key={i + '' + props?.index} className="d-flex flex-row align-items-center justify-content-end mb-3" style={{ marginLeft: 55 }} >
                            <PathInPathComponent statusParent={status} {...props} width={props?.width - 110} child={true} secondIndex={i} clickedCourse={clickedCourse} content={content?.path} index={props?.index} />
                        </div>
                    }
                })}
                {contentPath?.exams?.map((exam: any, index: number) => {
                    return <div key={index + "exam"} className="d-flex flex-row align-items-center justify-content-end mb-3" style={{ marginLeft: 55 }} >
                        <div className="subliste-cours-ligne"></div>
                        <PathExamComponent {...props} width={props?.width - 110} clickedExam={clickedExam} exam={exam} secondIndex={contentPath?.subjectsByPath?.length} index={props?.index} />
                    </div>
                })}
            </div>
        </div>}

        {props.isMobile && 
        <div className="d-flex flex-column w-100" >
                {props?.child && <div className="subliste-cours-ligne"></div>}
                <div className="bg-with-border white w-100 p-3" style={{ maxWidth: props?.width, width: '-webkit-fill-available', position: 'relative' }}>
                    <div className='d-flex align-items-center'>
                        <div className="col-2">
                            {(props?.secondIndex === undefined) && <span className="H4-Subtitle">{((props?.index + 1) < 10) ? '0' + (props?.index + 1) : (props?.index + 1)}</span>}
                            {(props?.secondIndex !== undefined) && <span className="H4-Subtitle">{props?.index + 1}.{props?.secondIndex + 1}</span>}
                        </div>
                        <div className="col-7">
                            <span className="H4-Subtitle formation-card horizontal ">{contentPath?.title}</span>
                        </div>
                        <div className="d-flex flex-row mx-2">
                            {(progress !== 100) ? <div className="d-flex align-items-center">
                                <span className="material-icons-outlined  neutral-3">timer</span>
                                <span className="body-md bold">2h40</span>
                            </div> :
                                <button type="button" className="btn-Secondary medium icon-left" onClick={(e) => { e.stopPropagation(); getDataFromURL(contentPath?.followUp?.certificationLink) }}>
                                    <span className="material-icons-outlined">download</span>
                                    <div className="m-auto ps-2">{t('diplomeButton')}</div>
                                </button>}
                        </div>
                    </div>

                    <div className='d-flex mt-2'>
                        <div className='col-2'/>
                        <div className="d-flex flex-row align-items-center col-9" style={{ height: 24 }}>
                            <div className="d-flex me-2">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.45455 2.90909H0V14.5455C0 15.3455 0.654545 16 1.45455 16H13.0909V14.5455H1.45455V2.90909ZM14.5455 0H4.36364C3.56364 0 2.90909 0.654545 2.90909 1.45455V11.6364C2.90909
                        12.4364 3.56364 13.0909 4.36364 13.0909H14.5455C15.3455 13.0909 16 12.4364 16 11.6364V1.45455C16 0.654545 15.3455 0 14.5455 0ZM14.5455 11.6364H4.36364V1.45455H14.5455V11.6364Z"
                                        fill="#FA83EE" />
                                </svg>
                            </div>
                            <div className="body-sm bold neutral-2">
                                {t('pathTitle')}
                            </div>
                        </div>
                    </div>

                    <div className='d-flex justify-content-between mt-2'>
                        <div className='col-2'/>
                        <div className="d-flex align-items-center col-8">
                            <div className="" style={{ width: 148, textAlign: 'left' }}>
                                <span className="body-md bold neutral-3" style={{ textTransform: 'capitalize' }}>{t('progress')} : <span className={`${(progress === 0) ? 'neutral-3' : (progress === 100) ? 'green-800' : 'black-800'}`}>{progress}%</span></span>
                            </div>
                            <div className="">
                                {(status === "CLOSED" || (props?.statusParent && props?.statusParent === 'CLOSED'))? iconClosed :(progress === 0 && status === "OPENED") ? iconOpened : (status === "CERTIFIED") ? iconCompleted : iconProgress}
                            </div>
                        </div>
                        <div className='col-2 mx-3'>
                            <button type="button" className="btn-QuickActions small me-2" onClick={() => openPathDetails()}>
                                {!open ?
                                    <span className="material-icons-outlined">
                                        expand_more</span> :
                                    <span className="material-icons-outlined">
                                        expand_less</span>
                                }
                            </button>
                        </div>
                    </div>
                    <div className="subliste-all-ligne-follow-course" style={(open) ?
                        { height: refDiv?.current?.offsetHeight - 50 /*83 + (137 * (contentPath?.subjectsByPath?.length + contentPath?.exams?.length - 1))*/, transition: 'height .5s ease', overflowY: 'hidden' } :
                        { height: 0, transition: 'height .5s ease', overflowY: 'hidden' }}>
                    </div>
                </div>
                <div ref={refDiv} style={(open) ?
                    { minHeight: (40 + (120 * (contentPath?.subjectsByPath?.length + contentPath?.exams?.length))), transition: 'all .5s ease', overflowY: 'hidden', marginTop: 24 } :
                    { minHeight: '0px', height: 0, transition: 'all .5s ease', overflowY: 'hidden', marginTop: 0 }}>
                    {contentPath?.subjectsByPath?.map((content: any, i: number) => {
                        if (content?.course) {
                            return <div key={i + '' + props?.index} className="d-flex flex-row align-items-center justify-content-end mb-3" style={props.isMobile ? { marginLeft: 4 } : {marginLeft: 55}}>
                                <div className="subliste-cours-ligne"></div>
                                <PathCourseComponent isMobile={props.isMobile} statusParent={status} width={props?.width - 110} clickedCourse={clickedCourse} course={content?.course} index={props?.index} secondIndex={i} />
                            </div>
                        }
                        if (content?.path) {
                            return <div key={i + '' + props?.index} className="d-flex flex-row align-items-center justify-content-end mb-3" style={props.isMobile ? { marginLeft: 4 } : {marginLeft: 55}} >
                                <PathInPathComponent isMobile={props.isMobile} statusParent={status} {...props} width={props?.width - 110} child={true} secondIndex={i} clickedCourse={clickedCourse} content={content?.path} index={props?.index} />
                            </div>
                        }
                    })}
                    {contentPath?.exams?.map((exam: any, index: number) => {
                        return <div key={index + "exam"} className="d-flex flex-row align-items-center justify-content-end mb-3" style={props.isMobile ? { marginLeft: 4 } : {marginLeft: 55}} >
                            <div className="subliste-cours-ligne"></div>
                            <PathExamComponent isMobile={props.isMobile} {...props} width={props?.width - 110} clickedExam={clickedExam} exam={exam} secondIndex={contentPath?.subjectsByPath?.length} index={props?.index} />
                        </div>
                    })}
                </div>
            </div>
        }
    </>
    )
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    updateIndexPathSessionPass,
    getCurrentPath,
    createProgressExam,
    setExamResult,
    redirect, clearHistoryArray
}, dispatch);

const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUserDetails: state.authReducer.connectedUser,
    currentTraining: state.studentTraining.currentTraining,
    index: ownProps?.index,
    clickedCourse: ownProps?.clickedCourse,
    clickedCoursePath: ownProps?.clickedCoursePath,
    statusParent: ownProps?.statusParent
});

export default connect(mapStateToProps, mapDispatchToProps)(PathInPathComponent);
