import { ComponentProps, FC, useState } from 'react';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { openModal, closeModal } from '../../../global-state/actions/modal-actions';
import TextareaUnderlineElement from '../../../elements/text-area/text-area-underline-element';
import { deleteUserAccount } from '../../../global-state/actions/auth-actions';
import axios from "../../../helpers/interceptors";

const DeleteUserProfileModalComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const [firstName, setFirstName] = useState<string>(props?.userProfileDetails?.firstName);
    const [LastName, setLastName] = useState<string>(props?.userProfileDetails?.lastName);
    const [userId, setUserId] = useState(props?.connectedUser?.idPerson);
    const [schoolId, setSchoolId] = useState(props?.connectedUser?.idSchool);
    const [userComment, setUserComment] = useState<string>("");


    const deleteAccount = async (userId: number, schoolId: number) => {
        // .then(async(resp:any)=>{
        if (props?.connectedUser?.role == "role_director") {
            const object = {
                "properties": {
                    "email": props?.userProfileDetails?.email,
                    "firstname": props?.userProfileDetails?.firstName,
                    "lastname": props?.userProfileDetails?.lastName,
                    "lifecyclestage": null,
                    "hs_lead_status": "UNQUALIFIED"
                }
            }
            if (process.env.REACT_APP_BASE_HUBSPOT === "true") {
                const result = await axios.post(process.env.REACT_APP_BASE_URL21 + "hubspot/updateContact", object).then((response: any) => {
                    return response;
                })
            }
        }


        await props?.deleteUserAccount(userId, schoolId, "we'll fix it later", userComment)

        // });
    }



    return (
        <div>
            <div className="modal-header" style={{ paddingBottom: 0, borderBottom: "none" }}>
                <div className="w-100 d-flex align-items-center justify-content-end p-3">
                    <button className="btn-QuickActions" onClick={() => props?.closeModal()}><span className="material-icons">close</span></button>
                </div>
            </div>

            <div className="modal-body " style={{ paddingTop: 0, zIndex: 1000, overflow:'hidden' }}>
                <div className="row">
                    <div className="col-md-10 center" style={{ margin: 'auto' }}>
                        <div className="row">
                            <div className="col-md-12 ">
                                <h2 className="H3-Headline" style={{ fontSize: "24px", lineHeight: "32px", color: "#272727" }} >{t('suppcomte')}</h2>
                                <span className="H4-Subtitle neutral-3" >{firstName} {LastName}</span>
                            </div>
                            <div className="col-md-12">
                                <p className="body-sm black-800" style={{ marginTop: "16px" }}>{t('labeldeletecompte')}</p>
                            </div>
                            {/* <div className="mb-3 w-100">
                                <label className="form-title" style={{ marginTop: "16px" }}>{t('deleteReason')}</label>
                                <SelectElement disabled={false} taille={'lg'} defaultValue={selectReason} options={listDelete} onChange={(e: any) => { setAccountDeleteReason(e) }} placeholder={"Sélectionner un motif"} />
                            </div> */}
                            <div className="col-md-12">
                                <label className="form-title" style={{ marginTop: "16px" }}>{t('modalPublishCourse_2')}</label>
                            </div>
                            <div className="col-md-12">
                                <TextareaUnderlineElement placeholder={t('modalPublishCourse_3')} value={userComment} onChange={(value: string) => setUserComment(value)} id={1} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-footer" style={{ paddingBottom: 0, borderTop: "none" }}>
                <div className="row mt-3 mb-5">
                    <div className='col-md-9 d-flex  justify-content-end mx-0 px-0' >
                        <button className="btn-Secondary large me-3" onClick={() => { props?.closeModal() }}>{t('annuler')}</button>
                        <button disabled={userComment === ''} type="button" className="btn-Primary large danger" onClick={(e) => { deleteAccount(userId, schoolId) }} >{t('delete')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: AppState, ownProps: any) => ({
    userProfileDetails: state.authReducer.connectedUserInfo,
    connectedUser: state.authReducer.connectedUser,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            openModal,
            closeModal,
            deleteUserAccount
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(DeleteUserProfileModalComponent);
