import { FC, useEffect, useMemo, useState} from "react";
import { connect } from "react-redux";
import { getCatalog } from "../../global-state/actions/catalog-actions";
import { getStudent } from '../../global-state/actions/profile-actions';
import {getDataFromURL} from '../../base/functions/Functions';
import { getRegistrationForm, getApplicationFile, getFollow, getStudentInfoById } from '../../global-state/actions/course-actions';
import { AppState } from "../../global-state/store/root-reducers";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import TableElement from '../../elements/table/normal-table-element';
import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import { useHistory } from "react-router-dom";
import { returnStartPathUrl } from "../../helpers/domainCheck";


const iconCourse = <svg className="me-2" width="16" height="16" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0)">
    <path d="M14.2222 0.500061H1.77778C0.8 0.500061 0 1.30006 0 2.27784V14.7223C0 15.7001 0.8 16.5001 1.77778 16.5001H14.2222C15.2 16.5001 16 15.7001 16 14.7223V2.27784C16 1.30006 15.2 0.500061 14.2222 0.500061ZM14.2222 14.7223H1.77778V2.27784H14.2222V14.7223Z" fill="#5693EE" />
</g>
<line x1="5.38184" y1="10.2764" x2="10.6187" y2="10.2764" stroke="#5693EE" strokeWidth="1.5" strokeLinecap="round" />
<line x1="10.6182" y1="6.72375" x2="5.38132" y2="6.72375" stroke="#5693EE" strokeWidth="1.5" strokeLinecap="round" />
<defs>
    <clipPath id="clip0">
        <rect width="16" height="16" fill="white" transform="translate(0 0.500061)" />
    </clipPath>
</defs>
</svg>;
const iconPath = <svg className="me-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.45455 2.90909H0V14.5455C0 15.3455 0.654545 16 1.45455 16H13.0909V14.5455H1.45455V2.90909ZM14.5455 0H4.36364C3.56364 0 2.90909 0.654545 2.90909 1.45455V11.6364C2.90909
12.4364 3.56364 13.0909 4.36364 13.0909H14.5455C15.3455 13.0909 16 12.4364 16 11.6364V1.45455C16 0.654545 15.3455 0 14.5455 0ZM14.5455 11.6364H4.36364V1.45455H14.5455V11.6364Z"
    fill="#FA83EE" />
</svg>
const iconSession = <svg className="me-2" width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0)">
    <path d="M1.45455 5.22727H0V16.1364C0 16.8864 0.654545 17.5 1.45455 17.5H13.0909V16.1364H1.45455V5.22727ZM14.5455 2.5H4.36364C3.56364 2.5 2.90909 3.11364 2.90909 3.86364V13.4091C2.90909 14.1591 3.56364 14.7727 4.36364 14.7727H14.5455C15.3455 14.7727 16 14.1591 16 13.4091V3.86364C16 3.11364 15.3455 2.5 14.5455 2.5ZM14.5455 13.4091H4.36364V3.86364H14.5455V13.4091Z" fill="#8A57CC" />
</g>
<line x1="4" y1="7.35" x2="15" y2="7.35" stroke="#8A57CC" strokeWidth="1.3" />
<line x1="13.35" y1="3" x2="13.35" stroke="#8A57CC" strokeWidth="1.3" />
<line x1="5.35" y1="3" x2="5.35" stroke="#8A57CC" strokeWidth="1.3" />
<defs>
    <clipPath id="clip0">
        <rect width="16" height="16" fill="white" transform="translate(0 1.5)" />
    </clipPath>
</defs>
</svg>

const DroppedTrainingsListComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> > = (props:any) => {
  const { t } = useTranslation();
  const dateFormat = require('dateformat');
  const [data, setData] = useState<any>(null);
  const history = useHistory();
  let droppedStudentList = useMemo(() => props?.listeFollows?.dropped, [props?.listeFollows?.dropped])



  const tabHeaders = [
    { title: "FORMATION", sorted: true, classNames: "w-20", scope: "col", buttonClasses: "d-flex button-trie px-0" },
    { title: "TYPE", sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 " },
    { title: "PROFESSEUR", sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
    { title: "INSCRIPTION", sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
    { title: t('Dossier'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
    { title: "ABONDONNE LE", sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0" },
    { title: "PAIMENT", sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 " },
    { title: "AVANCEMENT", sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },

  ]

  const getType = (label:any)=>{
    switch(label){
      case "COURSE":
        return t('Cours');
      case 'PATH':
        return t('pathTitle');
      default:
        return t('sessionTitle');
    }
  } 


  useEffect(()=>{
    let list={ 
        headers:tabHeaders,
        body: droppedStudentList?.map((follow: any, index: number ) => {
          //const dateSent = Math.ceil((new Date().getTime() - new Date(statusCandidate?.creationDate).getTime()) / (1000 * 3600 * 24));
            return {
                row: {
                    actionName: "goToPath",
                    classNames: "table-white relative",
                columns: [
                  {
                    content: <div className="d-flex align-items-center">
                      <span >{follow?.trainingName}</span></div>, classNames: "", actionName: ''
                  },
                  {
                    content: <div className="d-flex align-items-center">
                      <span >{getType(follow?.trainingType)}</span></div>, classNames: "", actionName: ''
                  },
                  {
                    content: <div className="d-flex align-items-center">
                      <span className="ms-2">{follow?.professorName}</span></div>, classNames: "", actionName: ''
                  },
                  {
                    content: <div className="d-flex align-items-center">
                      <span className="ms-2">{dateFormat(follow?.inscriptionDate, "dd/mm/yyyy ")}</span></div>, classNames: "", actionName: ''
                  },
                  {
                    content: (follow?.registrationFileId!== undefined && follow?.registrationFileId!== 0 && follow?.registrationFileId !== null) ? <button style={{ position: 'absolute', top: '48%' }} className={`btn-Document accepted`}>
                    <span className="material-icons-outlined">description</span>
                  </button> : <button className="btn btn-Document" disabled><span className="material-icons-outlined" >highlight_off</span></button>, classNames: "with-icon relative-position ", actionName: ''
                  },
                  {
                    content: <div className="d-flex align-items-center">
                      <span >{dateFormat(follow?.droppedDate, "dd/mm/yyyy ")}</span></div>, classNames: "text-center", actionName: ''
                  },
                  {
                    content: <div className="d-flex align-items-center">
                      <span className="ms-2">{follow?.paymentType}</span></div>, classNames: "", actionName: ''
                  },
                  {
                    content: <div className="d-flex align-items-center justify-content-between">
                      <span className="ms-2">{follow?.progressPercentage}<span className="neutral-3">{" " + "%"}</span></span></div>, classNames: "text-center", actionName: ''
                  },
                ]
                }
            }
        })
    }
    setData(list)
}, [droppedStudentList ])

  const getTrainingIcon=(typeInput: string )=>{
    switch(typeInput){
      case 'COURSE':
        return iconCourse;
      case 'PATH':
        return iconPath;
      default:
        return iconSession;
    }
  
  }


  return (
    <TableElement data={data} doAction={(value: any) => { console.log("value") }} />
  )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
  history: ownProps?.history,
  actualStudent: state.detailsProfileReducer.actualStudent,
  schoolInfo:state.schoolInfoReducer.schoolInfo,
  studentInfo: state.courseReducer.studentInfo,
  listeFollows: state.detailsProfileReducer.studentFollows,


});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      getCatalog,
      getStudentInfoById,getApplicationFile,getRegistrationForm,getFollow,getStudent
    },
    dispatch
  );


export default connect(mapStateToProps, mapDispatchToProps)(DroppedTrainingsListComponent);