import { ComponentProps, FC, useEffect, useState } from "react"
import { connect } from "react-redux";
import { AppState } from "../../global-state/store/root-reducers";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import { getMyCourses, changeStyleCardCourse, getCoursesForDirector } from '../../global-state/actions/course-actions';
import EmptyListElement from "../../elements/empty-list/normal-empty-list-element";
import CardWebinarVertical from "./card-webinar/card-webinar-vertical-component";
import CardWebinarHorizontal from "./card-webinar/card-webinar-horizontal-component";
import CardWebinarVerticalLoading from './card-webinar/card-webinar-vertical-loading';
import CardWebinarHorizontalLoading from './card-webinar/card-webinar-horizontal-loading';
import { getCatalog } from "../../global-state/actions/catalog-duplicaction-actions";
import Webinar from "./webinar-model/Webinar";
import SelectElement from '../../elements/select/select-element';
import { getMyTrainings } from "../../global-state/actions/training-actions";
import { fetchData } from "../../base/functions/Functions";
interface Filters {
    subjectType: string,
    statusWebinar: string,
    subjectIds: any[],
    sort: string,
    search: string
}
const ProfessorListCardComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const [webinars, setWebinars] = useState<any>([])
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState<Filters>({
        subjectType: "",
        statusWebinar: "",
        subjectIds: [],
        sort: "asc",
        search: ""
    })

    const [coursesOption, setCoursesOption] = useState<any[]>([]);
    const [defaultValueTrie] = useState("asc");
    const [defaultValueStatus] = useState("none");
    const [defaultValueCourse] = useState("all");
    const [firstWebinar, setFirstWebinar] = useState<boolean>()
    const [courses, setCourses] = useState([]);

    const listTrie = [
        { label: t('SortsMostRecent'), value: "desc", id: 1 },
        { label: t('SortOldest'), value: "asc", id: 2 },
    ]

    const listStatus = [
        { label: t('allStatus'), value: t('allStatus'), id: 1 },
        { label: t('WAITING'), value: 'WAITING', id: 2 },
        { label: t('SOON'), value: 'SOON', id: 3 },
        { label: t('ONGOING'), value: 'ONGOING', id: 4 },
        { label: t('ENDED'), value: 'ENDED', id: 5 },
        { label: t('REFUSED'), value: 'REFUSED', id: 6 }
    ]

    const noWebinarMessageFn = (filters: any) => {
        if ((filters.subjectIds.length !== 1) && (filters.search === "")) {
            switch (filters.statusWebinar) {
                case "WAITING":
                    return <div className="col-md-12 text-center mt-5 ">
                        <EmptyListElement lines={[t('NoWebinarToValidate')]} >

                        </EmptyListElement>
                    </div>

                case "SOON":
                    return <div className="col-md-12 text-center mt-5 ">
                        <EmptyListElement lines={[t('NoWebinarIsComingSoon')]} >

                        </EmptyListElement>
                    </div>
                case "ONGOING":
                    return <div className="col-md-12 text-center mt-5 ">
                        <EmptyListElement lines={[t('NoWebinarIsOnGoing')]} >

                        </EmptyListElement>
                    </div>
                case "ENDED":
                    return <div className="col-md-12 text-center mt-5 ">
                        <EmptyListElement lines={[t('NoWebinarHasEnded')]} >

                        </EmptyListElement>
                    </div>
                case "REFUSED":
                    return <div className="col-md-12 text-center mt-5 ">
                        <EmptyListElement lines={[t('NoWebinarIsRefused')]} >

                        </EmptyListElement>
                    </div>
                case "": case "Tous les statuts":
                    return <div className="col-md-12 text-center mt-5 ">
                        <EmptyListElement lines={[t('NoWebinarExists')]} >

                        </EmptyListElement>
                    </div>
            }
        }
        else if (filters.subjectIds.length === 1 && (filters.statusWebinar === "" || filters.statusWebinar === "Tous les statuts") && (filters.search === "")) {
            return <div className="col-md-12 text-center mt-5 ">
                <EmptyListElement lines={[t('NoWebinarIsAssociatedToThisCourse')]} >

                </EmptyListElement>
            </div>
        }
        else {
            return <div className="col-md-12 text-center mt-5 ">
                <EmptyListElement lines={[t('NoWebinarExists')]} >

                </EmptyListElement>
            </div>
        }

    }

    const manageWebinarContentFn = (filters: any) => {

        if (webinars?.length === 0) {
            if (firstWebinar) {
                return (
                    <>
                        <div className="w-100 d-flex align-items-center justify-content-center mt-5">
                            <div className="col-md-12 text-center mt-5 ">
                                <EmptyListElement lines={[t('FirstWebinar'), t('FirstWebinar2')]} >

                                </EmptyListElement>
                            </div>
                        </div>
                        <div className="d-flex m-auto ps-2 mt-3 align-items-center">
                            <Webinar name="add" filters={filters} courses={courses?.filter((a: any) => !!a.listOrders)} />
                        </div>
                    </>
                )

            } else {
                return noWebinarMessageFn(filters)
            }
        } else {
            return (
                webinars?.map((webinar: any, index: number) => {
                    return (props?.styleCardCourse === "vertical") ?
                        <div key={"key " + webinar?.id} className=" justify-content-center" >
                            <CardWebinarVertical webinar={webinar} key={webinar.id} />
                        </div> :
                        <div key={"key " + webinar?.id} className="col-md-12 mb-3">
                            <CardWebinarHorizontal key={webinar.id} webinar={webinar} />
                        </div>
                })
            )
        }
    }

    useEffect(() => {

        let filter = {
            entityId: props?.user?.idSchool,
            entityType: "SCHOOL",
            status: "PUBLISHED",
            idProf: ((props?.user?.role === "role_director") || (props?.user?.role === "role_adminstrator")) ? null : props?.user?.idPerson,

        }
        if (props?.user?.role === "role_prof") {
            props?.getMyTrainings(filter).then((resp: any) => {
                const array = [{ value: "all", label: t('allCourses') }];
                resp.data?.filter((a: any) => a?.type == "COURSE").map((obj: any) => {
                    array.push({ value: obj.id, label: obj.title });
                });
                setCoursesOption(array);
            })
        }
        else {
            props?.getCoursesForDirector(filter).then((resp: any) => {
                const array = [{ value: "all", label: t('allCourses') }];
                resp.data?.filter((a: any) => a?.type == "COURSE").map((obj: any) => {
                    array.push({ value: obj.id, label: obj.title });
                });
                setCoursesOption(array);
            })
        }

    }, [])

    const initializeFilter = async () => {
        
        await setFilters({ ...filters, subjectIds: courses?.map((c: any) => { return c?.id }) })
    }

    useEffect(() => {
        // initializeFilter()
        // props?.getWebinars(filters)
        let filter = {
            trainingType: "COURSE",
            entityId: props?.user?.idSchool,
            entityType: "SCHOOL",
            status: "PUBLISHED",
            idProf: props?.user?.idPerson,
        }
        if(courses?.length === 0 || courses === undefined){
            getListCourses(filter)
        }
        webinars?.length === 0 ? setFirstWebinar(true) : setFirstWebinar(false)
    }, []);

    useEffect(() => {
        if (courses != undefined && courses?.length != 0 && webinars?.length === 0) {
            initializeFilter()
            getWebinars(filters)
        }

        webinars?.length === 0 ? setFirstWebinar(true) : setFirstWebinar(false)
    }, [courses]);


    const handleChoice = (e: any) => {

        if (e === "all") {
            setFilters({ ...filters, subjectIds: courses?.map((c: any) => { return c?.id }) })

        } else {
            setFilters({ ...filters, subjectIds: [e] })
        }

    }
    useEffect(() => {
        if (filters?.subjectIds != undefined && filters?.subjectIds?.length != 0) {
            getWebinars(filters)
        }
    }, [filters])

    const getWebinars = async (filters:any) =>{
        setLoading(true)
        const baseUrl = process.env.REACT_APP_BASE_URL9;
        let listWebinars = await fetchData("POST",baseUrl + `webinars/filter`, filters).then(res => { return res }).catch(err => {return []});
        setWebinars(listWebinars)
        if (listWebinars?.length > 0) {
            setFirstWebinar(false)
        }
        setLoading(false)
        return null;
    }

    const getListCourses = async (filter:any) =>{
        const baseUrl = process.env.REACT_APP_BASE_URL14;
        let coursesList = await fetchData("POST",baseUrl + `training/allT`, filter).then(res => { return res }).catch(err => { return { data: [] } });
        setCourses(coursesList)
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-md-12" style={{ backgroundColor: '#ffffff' }}>
                        <div className="row mx-auto" style={{ maxWidth: '1200px' }} >
                            <div className="col-md-12 mt-5" >
                                <div className="py-4 w-100">
                                    <div className="d-flex flex-row justify-content-between my-2" >
                                        <div className="d-flex flex-row">
                                            <span className="H2-Headline neutral-3 me-3">{t('Webinar')}</span>
                                            <p className="H2-Headline black-800">{(webinars?.length < 10) ? '0' + webinars?.length : webinars?.length}</p>
                                        </div>


                                        {!firstWebinar && <div className="d-flex align-items-center">
                                            <div className="d-flex align-items-center me-4">
                                                <span className="material-icons-outlined " onClick={() => props?.changeStyleCardCourse('horizontal')} style={{ cursor: 'pointer', color: `${(props?.styleCardCourse === 'horizontal') ? "#272727" : "#CACBCE"}`, fontSize: 30 }}> view_headline </span>
                                                <span className="material-icons-outlined " onClick={() => props?.changeStyleCardCourse('vertical')} style={{ cursor: 'pointer', color: `${(props?.styleCardCourse === 'vertical') ? "#272727" : "#CACBCE"}`, fontSize: 30 }}> apps </span>
                                            </div>

                                            <Webinar name="add" filters={filters} courses={courses?.filter((a: any) => !!a.listOrders)} />
                                        </div>}
                                    </div>
                                </div>




                            </div>
                        </div>
                    </div>

                    <div className="container-fluid" style={{ maxWidth: '1200px' }} >
                        <div className="row justify-content-center">

                            {!firstWebinar && <div className="d-flex flex-row m-auto align-items-end my-4 py-2" style={{ columnGap: 8, justifyContent: "flex-end" }}>
                                <div className="select-input" style={{ minWidth: '225px' }}>

                                    <SelectElement disabled={false} taille={'lg'} defaultValue={defaultValueTrie} options={listTrie} onChange={(e: any) => { setFilters({ ...filters, sort: e }) }} placeholder={t('statusFilter')} />


                                </div>
                                <div className="select-input" style={{ minWidth: '225px' }}>
                                    <SelectElement disabled={false} taille={'lg'} defaultValue={defaultValueStatus} options={listStatus} onChange={(e: any) => { setFilters({ ...filters, statusWebinar: e }) }} placeholder={t('allStatus')} />


                                </div>
                                <div className="select-input" style={{ minWidth: '225px' }}>
                                    <SelectElement disabled={false} taille={'lg'} defaultValue={defaultValueCourse} options={coursesOption} onChange={(e: any) => { handleChoice(e) }} placeholder={t('allCourses')} />


                                </div>

                                <div className="input-form-control" style={{ maxWidth: '225px' }}>
                                    <input dir="ltr" type="text" className="form-control input text-default" placeholder={t('searchProfessors')} onChange={(e) => { setFilters({ ...filters, search: e?.target.value }) }} />
                                    <span className="material-icons" style={{ color: '#B1B1B1' }}>search</span>
                                </div>
                            </div>}

                            <div >
                                {!loading && <div className={(props?.styleCardCourse === "vertical" && webinars?.length > 0) ? "grid-cards" : "d-flex w-100 flex-wrap align-items-center"}>
                                    {
                                        manageWebinarContentFn(filters)
                                    }
                                </div>}
                                {loading && <div className="d-flex w-100 flex-wrap align-items-center justify-content-between ">
                                    {(props?.styleCardCourse === "vertical") ?
                                        <>
                                            <div className="mb-3 justify-content-center">
                                                <CardWebinarVerticalLoading />
                                            </div>
                                            <div className="mb-3 justify-content-center">
                                                <CardWebinarVerticalLoading />
                                            </div>
                                            <div className="mb-3 justify-content-center">
                                                <CardWebinarVerticalLoading />
                                            </div>
                                        </> :
                                        <>
                                            <div className="mb-3 justify-content-center w-100">
                                                <CardWebinarHorizontalLoading />
                                            </div>
                                            <div className="mb-3 justify-content-center w-100">
                                                <CardWebinarHorizontalLoading />
                                            </div>
                                            <div className="mb-3 justify-content-center w-100">
                                                <CardWebinarHorizontalLoading />
                                            </div>
                                        </>
                                    }
                                </div>}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    myCourses: state.courseReducer.allCourses,
    connectedUser: state.authReducer.connectedUser,
    history: ownProps?.history,
    styleCardCourse: state.courseReducer.styleCardCourse,
    courseStudent: state.courseReducer.myCourses,
    user: state.authReducer.connectedUser,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        { getMyTrainings, getMyCourses, changeStyleCardCourse, getCatalog, getCoursesForDirector },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(ProfessorListCardComponent);