import { useEffect, useState } from "react";

interface Props {
  data: any
  doAction?: any
  height?: number | string
}
export default function TableStudentGroupElement(props: Props) {
  const [data, setData] = useState<any>();
  useEffect(() => {
    setData(props?.data)
  }, [props?.data]);
  const doAction = (e: any, actionName: string, row: any, index: number) => {
    if (actionName !== '') {
      e.stopPropagation();

      const object = {
        row: row,
        action: actionName,
        index: index
      }
      props?.doAction(object);
    }
  }
  return (
    <>
      <div className="my-3" >
        <div className="table-responsive d-flex mx-auto" style={{ maxWidth: 1200,  overflow: 'initial' }}>
          <table className="w-100 custom-table" style={{height: '100%', minHeight:1}}>
            <thead className="mb-4">
              <tr>
                {data?.headers?.map((header: any) =>
                (<th key={header.title} scope={header.scope} className={header.classNames}>
                  <button className={header.buttonClasses}>
                    {header.title}
                    {/* {header.sorted && <div className="d-flex flex-column">
                      <span className="material-icons-outlined trie-arrow up" >arrow_drop_up</span>
                      <span className="material-icons-outlined trie-arrow down" >arrow_drop_down</span>
                    </div>} */}
                  </button>
                </th>)
                )}
              </tr>
            </thead>
            <tbody  style={{height: '100%', minHeight:1}}>
              {data?.body?.map((dataItem: any, index: number) =>
              (
                <tr key={index + 'row'} style={{ cursor: (dataItem.row.actionName === "") ? 'default' : 'pointer' }} onClick={(e) => doAction(e, dataItem.row.actionName, dataItem.row, index)} className={dataItem.row.classNames}>
                  {dataItem.row.columns.map((column: any, indexItem: number) =>
                  (
                    <td key={indexItem + 'column'} onClick={(e) => doAction(e, column.actionName, dataItem.row, indexItem)} className={column.classNames} colSpan={column.colspan}>{column.content}</td>
                  )
                  )}
                </tr>
              )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
