import { FC, useEffect } from "react";
import deleteIcon from "../../styles/imgs/sondage/delete-icon.svg";
import arrowIcon from "../../styles/imgs/sondage/arrow-icon.svg";
import { connect } from "react-redux";
import { AnyAction, Dispatch, bindActionCreators } from "redux";
import { AppState } from "../../global-state/store/root-reducers";

const HeaderCardChoiceSondage: FC<
  ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
> = ({ questionByExam, handleOpenCardQuestion, index, isOpen }: any) => {

  return (
    <div className="header-card-choice-sondage">
      <div className="d-flex flex-column">
      <div className="title">
        <span className="title-question">Question <span className="count">{index + 1}</span></span>{" "}
        {questionByExam?.isObligatory &&
          <span className="body-sm bold neutral-3">
            Obligatoire
          </span>}

      </div>
      <div className="title">
        <span className="body-sm bold">{questionByExam?.question?.questionStatement}</span>
        {questionByExam?.isObligatory &&
        <span className="body-sm bold">*</span>}

      </div>

      </div>
     
      
      <div className="actions">
        <button>
          <img src={deleteIcon} alt="" />
        </button>
        <button onClick={() => handleOpenCardQuestion(index)}>
          <img src={arrowIcon} alt="" className={`${isOpen ? 'arrow-opened' : 'arrow-closed'}`}
          // className={`${questionByExam.isOpened ? 'is-opened' : 'is-closed'}`}
          />
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState, ownProps: any) => ({});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderCardChoiceSondage);
