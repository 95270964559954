import { FC, ComponentProps, useState, useEffect } from "react";

/* IMPORT COMPONENTS */
import InputUploadSubtitleFile from "../../elements/inputs/input-upload-subtitle-file";
import SelectElement from "../../elements/select/select-element";

/* IMPORT REDUX */
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { AppState } from "../../global-state/store/root-reducers";
import { connect } from "react-redux";
import {
  uploadSubtitleVimeo,
  getChapterSubtitleVimeoUploaded,
} from "../../global-state/actions/upload-video-chapter-actions";
import { getSessionSubtitleVimeoUploaded, getPathSubtitleVimeoUploaded } from "../../global-state/actions/training-actions"
import { getCourseSubtitleVimeoUploaded } from '../../global-state/actions/course-actions'

/* IMPORT IMGS */
import addIcone from "../../styles/imgs/add.png";

/* IMPORT FUNCTION HELPERS */
import {
  callCheckHandleMsgError,
  checkUploadFileSubtitle,
} from "../../helpers/checkUploadFileSubtitle";

import languagesJSON from "../../constants/languages.json";
import { languagesManagement } from "../../helpers/upload-subtitle/languagesManagement";

const InputUploadSubtitle: FC<
  ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> &
    ComponentProps<any>
> = ({
  idVideo,
  uploadSubtitleVimeo,
  getSubtitleUploaded,
  getChapterSubtitleVimeoUploaded,
  getChapterSubtitleUploaded,
  getSessionSubtitleUploaded,
  getPathSubtitleUploaded,
  getCourseSubtitleUploaded,
  type
}) => {

  const [fileSubtitle, setFileSubtitle] = useState<any>(null);
  const [isIncorrectFile, setIsIncorrectFile] = useState<boolean>(false);
  const [currentLanguage, setCurrentLanguage] = useState("");
  const [languages, setLanguages] = useState([{ label: "", value: "" }]);
  const [languagesSelected, setLanguagesSelected] = useState<any>([]);
  const [msgError, setMsgError] = useState("");
  const [isReset, setIsReset] = useState(false);

  const handleChangeLanguage = (value: string) => setCurrentLanguage(value);

  const handleUploadFile = (file: any) => {
    setFileSubtitle(file);
    setIsReset(false);
  };

  /* UPLOAD SUBTITLE TO VIMEO */
  const uploadSubtitle = async () => {
    setMsgError("");

    /* FUNCTION TO CHECK ERRORS INPUTS */
    callCheckHandleMsgError(
      fileSubtitle,
      setMsgError,
      currentLanguage,
      isIncorrectFile
    );

    /* CHECK IF FILE NOT READY */
    if (
      checkUploadFileSubtitle["readyToUpload"](
        fileSubtitle,
        currentLanguage,
        isIncorrectFile,
        setMsgError
      ) !== "ready"
    )
      return;

    /* ADD OBJECT FILE TO PARENT COMPONENT */

    const dataFile = new FormData();

    dataFile.append("file", fileSubtitle);

    setCurrentLanguage("none");
    setIsReset(true);
    setFileSubtitle(null);

    /* CALL API TO UPLOAD SUBTITLE FILE TO VIMEO */
    await uploadSubtitleVimeo(idVideo, dataFile, currentLanguage);
    
    // getSubtitleUploaded[type](idVideo)

    getSubtitleUploaded();

    const copyLng = [...languagesJSON];

    const copyLngFiltred = copyLng.filter(
      (el: { label: string; value: string }) => {
        return ![...languagesSelected, currentLanguage].includes(el.value) 
      }
    );
    setLanguages(copyLngFiltred)
  };
  
  useEffect(()=> {
    if(!type) return
    languagesManagement(
      type,
      getChapterSubtitleUploaded,
      getSessionSubtitleUploaded,
      getPathSubtitleUploaded,
      getCourseSubtitleUploaded,
      setLanguagesSelected,
    )
  }, [type])

  useEffect(() => {
    const copyLng = [...languagesJSON];

    const copyLngFiltred = copyLng.filter(
      (el: { label: string; value: string }) => {
        return !languagesSelected.includes(el.value) 
      }
    );
    setLanguages(copyLngFiltred)
  }, [languagesSelected]);

  return (
    <div className="row position-relative w-100 mx-0">
      <div className="col-3 ">
        <div className="body-md bold">Langue du sous-titre</div>
        <SelectElement
          taille={"lg"}
          placeholder="Langue"
          options={languages}
          onChange={(value: string) => handleChangeLanguage(value)}
          defaultValue={currentLanguage}
          disabled={false}
        />
      </div>
      <div className="col-8 p-0">
        <InputUploadSubtitleFile
          handleUploadFile={(file: any) => handleUploadFile(file)}
          incorrectFile={(value: boolean) => setIsIncorrectFile(value)}
          isReset={isReset}
        />
      </div>
      <div className="col-1 p-0 d-flex align-items-center justify-content-end">
        <button className="add-btn" onClick={()=>uploadSubtitle()}>
          <img src={addIcone} alt="" />
        </button>
      </div>
      <div className="position-absolute msg-error-upload-subtitle">
        {msgError}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      uploadSubtitleVimeo,
      getChapterSubtitleVimeoUploaded,
      getSessionSubtitleVimeoUploaded,
      getPathSubtitleVimeoUploaded,
      getCourseSubtitleVimeoUploaded
    },
    dispatch
  );

const mapStateToProps = (state: AppState, ownProps: any) => ({
  getChapterSubtitleUploaded: state.uploadChapterReducer.getChapterSubtitleUploaded,
  getSessionSubtitleUploaded: state.trainingReducer.getSessionSubtitleUploaded,
  getPathSubtitleUploaded: state.trainingReducer.getPathSubtitleUploaded,
  getCourseSubtitleUploaded: state.courseReducer.getCourseSubtitleUploaded
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InputUploadSubtitle);
