import { ComponentProps, FC, useEffect, useMemo, useState } from "react";
import { connect } from 'react-redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import SelectElement from "../../../elements/select/select-element";
import { useTranslation } from "react-i18next";
import { updatePath } from '../../../global-state/actions/breadcrumb-actions';
import { getChapter, createChapter, setIndexContent, setTypeFormContentCourse, updateChapter, deleteChapter, clearChapter, updateExam, getExam, createExam, deleteExam, } from '../../../global-state/actions/course-actions';
import { useHistory } from "react-router-dom";
const times = [
    { value: "15", label: '15 mn' },
    { value: "30", label: '30 mn' },
    { value: "45", label: '45 mn' },
    { value: "60", label: '1h00' },
    { value: "75", label: '1h15' },
    { value: "90", label: '1h30' },
    { value: "165", label: '2h45' },
    { value: "180", label: '3h00' },
]
const HeaderChapterExamPreviewComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const history = useHistory();
    const typeContent = useMemo(() => { return props?.typeContent }, [props?.typeContent]);
    const [courseForm, setCourseForm] = useState<any>();
    const [allListe, setAllListe] = useState<any[]>([]);
    const [actualIndex, setActualIndex] = useState<number>(0);
    const [listeSelect, setListeSelect] = useState<any[]>([]);
    const [listeTest, setListeTest] = useState<any[]>([]);
    const [testForm, setTestForm] = useState<any>();
    const [chapterForm, setChapterForm] = useState<any>();
    useEffect(() => {
        let compteurChapter = 1;
        let compteurTest = 1;
        const liste = props?.courseForm?.orders?.
            concat(props?.listTest?.filter((test: any) => test.qnaType === "KNOWLEDGETEST"))?.
            sort((a: any, b: any) => {
                if (a.index > b.index) { return 1; } else if (a.index < b.index) { return -1 } else { return 0 }
            })?.map((obj: any, index: number) => {
                if (obj?.chapter) {
                    obj['compteur'] = compteurChapter;
                    compteurChapter++;
                } else {
                    obj['compteur'] = compteurTest;
                    compteurTest++;
                }
                return obj;
            });
        if (props?.listTest?.filter((test: any) => test.qnaType === "FINALEXAM").length > 0) {
            liste.push(props?.listTest?.filter((test: any) => test.qnaType === "FINALEXAM")[0]);
        }
        const listSelet = liste.map((obj: any) => {
            if (obj.chapter) {
                return { label: obj.chapter.title, value: JSON.stringify({ id: obj.chapter.id, type: 'CHAPTER' }) };
            } else {
                return { label: obj.title, value: JSON.stringify({ id: obj.id, type: obj.qnaType }) };
            }
        });
        setListeSelect(listSelet)
        setAllListe(liste);
        setCourseForm(props?.courseForm);
        setListeTest(props?.listTest);
        if (props?.chapterForm) {
            setChapterForm(props?.chapterForm)
        }
        if (props?.testForm) {
            setTestForm(props?.testForm);
        }
    }, []);

    useEffect(() => {
        let index = 0;
        if(props?.typeContent === 'CHAPTER') {
             index = allListe.findIndex((obj: any) => obj?.chapter?.id === props?.chapterForm?.id);
        } else {
             index = allListe.findIndex((obj: any) => (obj?.id === props?.testForm?.id && obj.chapter === undefined));
        }
        if(index !== -1) {
            setActualIndex(index);
        }

    }, [props?.typeContent, allListe]);
    useEffect(() => {
        if (props?.testForm) {
            const copyTest = { ...testForm };
            copyTest.index = props?.indexContent + 1
            setTestForm(copyTest);
        }
    }, [props?.indexContent]);
    useEffect(() => {
        if (props?.chapterForm) {
            setChapterForm(props?.chapterForm)
        }
    }, [props?.chapterForm]);
    useEffect(() => { 
        if (props?.testForm) {
            setTestForm(props?.testForm)
        }
    }, [props?.testForm]);

    const changeContent = (value: any) => {
        const object = JSON.parse(value);

        if (object.type === "CHAPTER") {
            const chapter = courseForm.orders.find((obj: any) => obj?.chapter?.id === object.id);
            const index = courseForm.orders.findIndex((obj: any) => obj?.chapter?.id === object.id)
            const indexAll = allListe.findIndex((obj: any) => obj?.chapter?.id === object.id);

            setActualIndex(indexAll);
            props?.getChapter(chapter.chapter, index)
            props?.setIndexContent(index);
            props?.setTypeFormContentCourse('CHAPTER');
            // props?.updatePath({ title: t('breadCrumbChapter') + " " + (index + 1), index: 2 });
        } else {
            const exam = listeTest.find((obj: any) => obj.id === object.id);
            const index = listeTest.findIndex((obj: any) => obj.id === object.id);
            const indexAll = allListe.findIndex((obj: any) => (obj?.id === object.id && object.chapter === undefined));
          
            setActualIndex(indexAll);
            props?.setIndexContent(index);
            props?.getExam(exam, index);
            props?.setTypeFormContentCourse(exam.qnaType);
            // const title = (exam.qnaType === "FINALEXAM")? t('finalExmBreadcrunbTitle') : t("breadCrumbTest")+ " " + (index + 1);
            // props?.updatePath({ title: title , index: 2 });
        }
    }
    const goPreviousChapter = () => {
        if (allListe[actualIndex - 1].chapter) {
            const index = courseForm.orders.findIndex((obj: any) => obj?.chapter.id === allListe[actualIndex - 1].chapter.id)
            props?.getChapter(allListe[actualIndex - 1].chapter, index)
            props?.setIndexContent(index);
            props?.setTypeFormContentCourse('CHAPTER');
            // props?.updatePath({ title: t('breadCrumbChapter') + " " + (index + 1), index: 2 });
        } else {
            const index = listeTest.findIndex((obj: any) => obj.id === allListe[actualIndex - 1].id);
            props?.setIndexContent(index);
            props?.getExam(allListe[actualIndex - 1], index);
            props?.setTypeFormContentCourse(allListe[actualIndex - 1].qnaType);
            // const title = (allListe[actualIndex - 1].qnaType === "FINALEXAM")? t('finalExmBreadcrunbTitle') : t("breadCrumbTest")+ " " + (index + 1);
            // props?.updatePath({ title: title, index: 2 });
        }
        setActualIndex(actualIndex - 1);
    }
    const goNextChapter = () => {
        if (allListe[actualIndex + 1].chapter) {
            const index = courseForm.orders.findIndex((obj: any) => obj?.chapter.id === allListe[actualIndex + 1].chapter.id);
            props?.getChapter(allListe[actualIndex + 1].chapter, index)
            props?.setIndexContent(index);
            props?.setTypeFormContentCourse('CHAPTER');
            // props?.updatePath({ title: t('breadCrumbChapter') + " " + (index + 1), index: 2 });
        } else {
            const index = listeTest.findIndex((obj: any) => obj.id === allListe[actualIndex + 1].id);
            props?.setIndexContent(index);
            props?.getExam(allListe[actualIndex + 1], index);
            props?.setTypeFormContentCourse(allListe[actualIndex + 1].qnaType);
            // const title = (allListe[actualIndex + 1].qnaType === "FINALEXAM")? t('finalExmBreadcrunbTitle') : t("breadCrumbTest")+ " " + (index + 1);
            // props?.updatePath({ title: title, index: 2 });
        }
        setActualIndex(actualIndex + 1);
    }
    const convertTime = (minutes: number) => {
        if (minutes < 60) {
            return minutes + ' MN';
        } else {
            return Math.floor(minutes / 60) + 'H' + (((minutes % 60) < 10 ? '0' : '') + (minutes % 60));
        }
    }
    return (<div style={{ backgroundColor: '#FFFFFF' }} className="w-100 mt-5 pt-5 pb-4">
        <div style={{ maxWidth: "1200px", margin: 'auto' }} className="row">
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center flex-wrap mb-3">
                    <div style={{ width: '320px' }}>
                        <SelectElement disabled={false} taille={"lg"} onChange={(value: string) => { changeContent(value) }} defaultValue={listeSelect[actualIndex]?.value} options={listeSelect} placeholder={'select chapter or exam'} />
                    </div>
                    <button disabled={(actualIndex === 0)} onClick={() => goPreviousChapter()} type="button" className="btn-QuickActions mx-3">
                        <span className="material-icons-outlined">chevron_left</span>
                    </button>
                    <button disabled={(actualIndex === allListe.length - 1)} onClick={() => goNextChapter()} type="button" className="btn-QuickActions">
                        <span className="material-icons-outlined">chevron_right</span>
                    </button>
                </div>
                <div style={{ width: '130px' }}>
                        <div className="d-flex align-items-center">
                            <span className="material-icons-outlined me-2" style={{ color: "#CACBCE" }}>timer</span>
                            <span className="body-md bold black-800">{convertTime((typeContent === "CHAPTER")?chapterForm?.duration:testForm?.duration)}</span>
                        </div>

                </div>
            </div>
            <div className="d-flex w-100">
            <h2 className="H2-Headline black-800">{(typeContent === "CHAPTER") ?chapterForm?.title:  testForm?.title}</h2>

                {/* <input dir="ltr"type="text" id="title" value={(typeContent === "CHAPTER") ? chapterForm.title : testForm.title} onChange={(e) => { changeTitle(e.target.value) }} className="input-title" placeholder={(typeContent === 'CHAPTER') ? t('placeholderTitleChapter') : (typeContent === 'FINALEXAM') ? t('exam1') : t('exam2') + '...'} /> */}
            </div>
        </div>
    </div>)
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    updatePath,
    getChapter,
    createChapter,
    updateChapter,
    deleteChapter,
    clearChapter,
    updateExam,
    getExam,
    createExam,
    setIndexContent,
    setTypeFormContentCourse,
    deleteExam
}, dispatch);
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    courseForm: state.courseReducer.courseInfo,
    listHistory: state.historyReducer.listHistoryBreadCrumb,
    history: ownProps?.history,
    chapterForm: state.courseReducer.chapterForm,
    testForm: state.courseReducer.testForm,
    indexChapter: state.courseReducer.indexChapter,
    indexTest: state.courseReducer.indexTest,
    listTest: state.courseReducer.listTest,
    typeContent: state.courseReducer.typeContentCourse,
    indexContent: state.courseReducer.indexContent,
    changeTitle: ownProps?.changeTitle
});
export default connect(mapStateToProps, mapDispatchToProps)(HeaderChapterExamPreviewComponent)