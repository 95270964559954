import { ComponentProps, FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
import Skeleton from '@material-ui/lab/Skeleton';
import { openModal } from '../../global-state/actions/modal-actions';
import { ModalIDS } from '../modals/modal-ids';
import { useHistory } from 'react-router';
import { returnStartPathUrl } from '../../helpers/domainCheck';
const HeaderStudentsListComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();
    const [loadingGeneral, setLoadingGeneral] = useState<boolean>(true);
    const [loadingCertified, setLoadingCertified] = useState<boolean>(true);
    const [loadingInscribed, setLoadingInscribed] = useState<boolean>(true);
    const [loadingCandidate, setLoadingCandidate] = useState<boolean>(true);
    const [loadingSuspended, setLoadingSuspended] = useState<boolean>(true);
    const [loadingUnpaid, setLoadingUnpaid] = useState<boolean>(true);
    const [loadingGroup, setLoadingGroup] = useState<boolean>(true);
    const history = useHistory();
    
    useEffect(() => {
        if (props?.followCount?.GENERAL >= 0){
            setLoadingGeneral(false);
        }
        if (props?.followCount?.CANDIDATE >= 0){
            setLoadingCandidate(false);
        }
        if (props?.followCount?.UNPAID >= 0){
            setLoadingUnpaid(false);
        }
        if (props?.followCount?.SUSPENDED >= 0){
            setLoadingSuspended(false);
        }
        if (props?.followCount?.INSCRIBED >= 0){
            setLoadingInscribed(false);
        }
        if (props?.followCount?.CERTIFIED >= 0){
            setLoadingCertified(false);
        }
        if (props?.groupList?.length >= 0){
            setLoadingGroup(false);
        }
    }, [props.followCount])

    const showCOuntGeneralInfo = () => {
        if(props?.followCount === undefined) return <div className="threedots-large-Animation">
            <span></span>
            <span></span>
            <span></span>
        </div> 
        if(props?.followCount.GENERAL < 10) {
            return `0${props?.followCount.GENERAL}`
        } else return props?.followCount.GENERAL
    }
    return (
        <div style={{ backgroundColor: '#FFFFFF' }} >
            <div className="col-md-12 mb-3 px-0 mx-auto mt-5" style={{ maxWidth: '1200px' }}>
                <div className="d-flex flex-row justify-content-between">
                    <div className="d-flex flex-start">
                        <h1 className="H2-Headline neutral-3">
                            {t('Etudiants')}
                            <span className="ms-3" style={{ color: "#272727" }}>
                            {showCOuntGeneralInfo()}   
                            </span>
                        </h1>
                    </div>
                    <div className="d-flex flex-end  ">
                        <button type="button" className="btn-Primary large icon-left `" onClick={() => {
                          if( props?.subscription?.limitations?.find((obj: any) => obj.key === 'nb_student') && props?.subscription?.limitations?.find((obj: any) => obj.key === 'nb_student')?.value>0&&props?.subscription?.limitations?.find((obj: any) => obj.key === 'nb_student')?.value <= props?.countFormationStudents?.data?.GENERAL){
                            history.push(`${returnStartPathUrl()}/students/all/not-found`)
                            
                          }else{
                            props?.setModalOpened(true)
                          }
                            
                        }}>
                           {props?.connectuser?.role === "role_director" ||props?.connectuser?.role === "role_prof"  } <div className="d-flex align-items-center"><span className="material-icons">add</span>{t('addStudent')}<div className="m-auto ps-2 "></div></div></button>
                    </div>
                </div>
            </div>
            <div className="col-md-12  mt-3 px-0 pb-4 mx-auto" style={{ maxWidth: '1200px' }}>
                <div className="row justify-content-between">
                    <div className="d-flex w-100 align-items-center jsutify-content-start">

                        {!loadingGeneral ? 
                            <button 
                                id='my-third-step'
                                className={`btn-Tabs ${(props?.actualTab === 'All') ? '' : 'unselected'} me-3 d-flex align-items-center`} 
                                onClick={() => { props.setActualTab('All');}} 
                                style={{ marginRight: "16px"}}
                            >
                                {t('allStudent')}&nbsp; ({props?.followCount?.GENERAL})
                            </button>
                            : <Skeleton className="skeleton radius me-3" animation="wave" variant="rect" width={130} height={36} />
                        }
                        {
                            !loadingInscribed ? 
                            <button 
                                className={`btn-Tabs ${(props?.actualTab === 'Inscribed') ? '' : 'unselected'} me-3 d-flex align-items-center`} 
                                onClick={() => { props.setActualTab('Inscribed')}} 
                                style={{ marginRight: "16px" }}
                            >{t('Inscribed')}&nbsp; ({props?.followCount?.INSCRIBED})</button>
                            : <Skeleton className="skeleton radius me-3" animation="wave" variant="rect" width={130} height={36} />
                        }
                        {
                            !loadingCandidate ? 
                            <button 
                                className={`btn-Tabs ${(props?.actualTab === 'Candidate') ? '' : 'unselected'} me-3 d-flex align-items-center`} 
                                onClick={() => { props.setActualTab('Candidate')}} 
                                style={{ marginRight: "16px", textTransform: 'capitalize' }}
                            >{t('candidatures')}&nbsp; ({props?.followCount?.CANDIDATE})</button>
                            : <Skeleton className="skeleton radius me-3" animation="wave" variant="rect" width={130} height={36} />
                        }
                        {
                            !loadingUnpaid ? 
                            <button 
                                className={`btn-Tabs ${(props?.actualTab === 'Unpaid') ? '' : 'unselected'} me-3 d-flex align-items-center`} 
                                onClick={() => { props.setActualTab('Unpaid')}} 
                                style={{ marginRight: "16px", textTransform: 'capitalize' }}
                            >{t('Unpaid').toLowerCase()}&nbsp; ({props?.followCount?.UNPAID})</button>
                            : <Skeleton className="skeleton radius me-3" animation="wave" variant="rect" width={130} height={36} />
                        }
                        {
                            !loadingCertified ? 
                            <button 
                                className={`btn-Tabs ${(props?.actualTab === 'Certified') ? '' : 'unselected'} me-3 d-flex align-items-center`} 
                                onClick={() => { props.setActualTab('Certified'); }} 
                                style={{ marginRight: "16px", textTransform: 'capitalize' }}
                            >{t('validatedDiploma').toLowerCase()}&nbsp; ({props?.followCount?.CERTIFIED})</button>
                            : <Skeleton className="skeleton radius me-3" animation="wave" variant="rect" width={130} height={36} />
                        }
                        {
                            !loadingSuspended ? 
                            <button 
                                className={`btn-Tabs ${(props?.actualTab === 'Suspended') ? '' : 'unselected'} me-3 d-flex align-items-center`} 
                                onClick={() => { props.setActualTab('Suspended')}} 
                                style={{ marginRight: "16px", textTransform: 'capitalize' }}
                            >{t('Suspended').toLowerCase()}&nbsp;({props?.followCount?.SUSPENDED})</button>
                            : <Skeleton className="skeleton radius me-3" animation="wave" variant="rect" width={130} height={36} />
                        }
                        {
                            props?.connectuser?.role.includes("role_director")&& ( 
                            !loadingGroup ? 
                            <button className={`btn-Tabs ${(props?.actualTab === 'Groups') ? '' : 'unselected'} me-3 d-flex align-items-center`} onClick={() => { props.setActualTab('Groups')}} style={{ marginRight: "16px", textTransform: 'capitalize' }}>
                                {t('groupes')} ({props?.groupList?.length})
                            </button> :
                            <Skeleton className="skeleton radius me-3" animation="wave" variant="rect" width={130} height={36} />)
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state: AppState) => ({
    subscription: state.schoolInfoReducer.subscription,
    listeStudents: state.profReducer.listeStudents,
    lastTabClicked: state.profReducer.lastTabClicked,
    connectuser: state.authReducer.connectedUser,
    loadStudentsGeneral: state.profReducer.loadStudentsGeneral,
    countFormationStudents: state.profReducer.countFormationStudents,
    loadStudentsCandidate: state.profReducer.loadStudentsCandidate,
    loadStudentsCertified: state.profReducer.loadStudentsCertified,
    loadStudentsInscribed: state.profReducer.loadStudentsInscribed,
    loadStudentsSuspended: state.profReducer.loadStudentsSuspended,
    loadStudentsUnpaid: state.profReducer.loadStudentsUnpaid,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({ openModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HeaderStudentsListComponent);
