import { fetchData } from "../base/functions/Functions";

export const getDiplomaApi = async (list:any) =>{
    const baseUrl = process.env.REACT_APP_BASE_URL3;
    let avgDuration: any = await fetchData("POST", baseUrl + `kpi/avgDuration`, { ids: list }).then(res => { return res }).catch(err => { return 0 });
    let diplomaDays: any = await fetchData("POST", baseUrl + `kpi/diplomaDays`, { ids: list }).then(res => { return res }).catch(err => { return 0 });

    let data = {

        avgDuration: avgDuration,
        diplomaDays: diplomaDays
    }
    return data
}

export const getActivityApi = async (list: any)=>{
    const baseUrl = process.env.REACT_APP_BASE_URL3;
    const baseUrl1 = process.env.REACT_APP_BASE_URL2;
    const baseUrl3 = process.env.REACT_APP_BASE_URL6;
    let connectedList: any = await fetchData("POST",baseUrl + `kpi/students/course`, { ids: list }).then(res => { return res }).catch(err => {
        return {
            studentNbre: 0,
            lastDays: 0
        }
    });
    let followers: any = await fetchData("POST",baseUrl + 'kpi/all', { ids: list }).then(res => { return res }).catch(err => { return 0 });
    let visit: any = await fetchData("POST",baseUrl1 + 'visit/lastDays', { ids: list }).then(res => { return res }).catch(err => { return 0 });
    let appFile: any = await fetchData("POST",baseUrl + 'kpi/appFile/course', { ids: list }).then(res => { return res }).catch(err => { return 0 });
    let formation: any = await fetchData("POST",baseUrl + 'kpi/For/course', { ids: list }).then(res => { return res }).catch(err => { return 0 });
    let certif: any = await fetchData("POST",baseUrl + 'kpi/Certif/course', { ids: list }).then(res => { return res }).catch(err => { return 0 });
    let statistic: any = await fetchData("POST",baseUrl + 'kpi/statisticStudent/course', { ids: list }).then(res => { return res }).catch(err => {
        return {
            percentagemale: 0,
            percentagefemale: 0,
            avgage: 0,
            ville: []
        }
    });
    let refused: any = await fetchData("POST",baseUrl + 'kpi/suspendue', { ids: list }).then(res => { return res }).catch(err => { return { suspendueNbre: 0 } });
    let markMedian: any = await fetchData("POST",baseUrl3 + 'progress/median/course', { ids: list }).then(res => { return res }).catch(err => { return 0 });
    let date = localStorage?.getItem('lastConnexion') ? localStorage?.getItem('lastConnexion')?.replace(/\"/g, "")?.split("T")[0] : new Date().toISOString().split('T')[0]; 
    let newCandidate: number = 0; 
    if(date !=undefined){
        newCandidate= await fetchData("POST",baseUrl + 'kpi/For/new', { times: date, ids: list })
    .then(res => { return res })
    .catch(err => { return 0 });
    }

    let data = {
        connectedList: connectedList,
        followers: followers,
        visit: visit,
        appFile: appFile,
        formation: formation,
        certif: certif,
        statistic: statistic,
        refused: refused,
        newCandidate: newCandidate,
        markMedian: markMedian,
    }
    return data
}

export const getSubscriptionApi = async (list:any)=>{
    const baseUrl = process.env.REACT_APP_BASE_URL3;
    let subscription: any = await fetchData("POST",baseUrl + 'kpi/allInscribedBySchools', { ids: Array.from(list) }).then(res => { return res }).catch(err => { return 0 });

    let data = {
        subscription: subscription,
    }
    return data;
}

export const getNewCoursesApi = async (list:any) =>{
    const baseUrl = process.env.REACT_APP_BASE_URL3;
    const baseUrl2 = process.env.REACT_APP_BASE_URL;
    
    let newInscribed: any = await fetchData('POST',baseUrl + 'kpi/inscrit/new', { times: localStorage?.getItem('lastConnexion')?.replace(/\"/g, "")?.split("T")[0], ids: list }).then(res => { return res }).catch(err => { return 0 });
    let newCourses: any = await fetchData("POST",baseUrl2 + 'courses/new', { times: localStorage?.getItem('lastConnexion')?.replace(/\"/g, "")?.split("T")[0], ids: list }).then(res => { return res }).catch(err => { return 0 });

    let data = {
        newInscribed: newInscribed,
        newCourses: newCourses,
    }

    return data
}

export const getActivityDirectorApi = async (list: any) => {
    let subscriptionSchool: number = 0;
    const baseUrl2 = process.env.REACT_APP_BASE_URL;
    let profs: any = await fetchData('POST', baseUrl2 + `courses/profs`, { ids: [list] })
    let newProfs: any = await fetchData('POST', baseUrl2 + `courses/newProfs`, { times: localStorage?.getItem('lastConnexion')?.replace(/\"/g, "")?.split("T")[0], ids: [list] })
    //let subscriptionSchool: any = await axiosInstance.get(baseUrl + `kpi/allInscribedBySchool/${list}/`).then(res => { return res.data }).catch(err => { return 0 });
    if ((localStorage.getItem('access_token') !=undefined) && (localStorage.getItem('access_token')!=null) && (localStorage.getItem('access_token')!='') ) {
        subscriptionSchool = await fetchData('GET', process.env.REACT_APP_BASE_URL2 + `person/countSchoolActifStudent/${list}`, {})
    }

    let data = {
        profs: profs,
        newProfs: newProfs,
        subscriptionSchool: subscriptionSchool
    }

    return data
}

export const getGenderStatsApi = async (schoolId:any) => {
    const baseUrl = process.env.REACT_APP_BASE_URL2;
    let stats: any = await fetchData("GET",baseUrl + "person/countSchoolStudentGender/" + schoolId,{}).then(res => { return res }).catch(err => { return 0 });
    return stats;
}

export const getListCoursesApi = async (filter:any) =>{
    const baseUrl = process.env.REACT_APP_BASE_URL14;
    let coursesList = await fetchData("POST",baseUrl + `training/allT`, filter).then(res => { return res }).catch(err => { return { data: [] } });
    return coursesList;
}

export const getStatisticsApi = async (year: any, list: any, schoolId: number) => {
    const baseUrl1 = process.env.REACT_APP_BASE_URL2;
    let appFile: any = await fetchData("GET",baseUrl1 + `person/getActifStudentPerYear/${schoolId}/${year}`,{})
    let visit: any = await fetchData("POST",baseUrl1 + `visit/period`, { year: year, ids: list }).then(res => { return res }).catch(err => { return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] });

    let data = {
        appFile: appFile,
        visit: visit,
    }
    return data
}

export const getTimesApi = async () =>{
    const baseUrl = process.env.REACT_APP_BASE_URL3;
    let visit: any = await fetchData("GET",baseUrl + `kpi/years`,{}).then(res => { return res }).catch(err => { return [] });
    let data = {
        visit: visit,
    }
    return data;
}

export const getTimesConnectionApi = async () =>{
    const baseUrl4 = process.env.REACT_APP_BASE_URL1;
    let connected: any = await fetchData("GET",baseUrl4 + `history/years`, { headers: { Authorization: 'Bearer ' + localStorage?.getItem('access_token')?.replace(/\"/g, "") } }).then(res => { return res }).catch(err => { return [] });
    let data = {
        connected: connected
    }
    return data
}

export const getStatisticsConnectionApi = async (year: any, list: any) =>{
    const baseUrl = process.env.REACT_APP_BASE_URL3;

    let connexion: any = await fetchData("POST",baseUrl + `kpi/students/period/course`, { year: year, ids: list }).then(res => { return res }).catch(err => { return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] });
    let data = {
        connexion: connexion
    }
    return data
}

export const getStatisticsCertificationApi = async (year: any, list: any)=>{
    const baseUrl = process.env.REACT_APP_BASE_URL3;

    let subscription: any = await fetchData("POST",baseUrl + `kpi/inscrit/period`, { year: year, ids: list }).then(res => { return res }).catch(err => { return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] });
    let certification: any = await fetchData("POST",baseUrl + `kpi/Certif/period`, { year: year, ids: list }).then(res => { return res }).catch(err => { return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] });

    let data = {
        subscription: subscription,
        certification: certification,
    }
    return data
}