import { AppState } from '../../../../global-state/store/root-reducers';
import { ComponentProps, FC,useMemo } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';
import "../../../../translations/i18n";
import { getDuration } from '../../../../base/functions/Functions';
import { getSideBarSectionContent } from '../../../../global-state/actions/student-training-actions';


const ExamSidebarComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();
    const progress = useMemo(() => { return props?.content?.progression }, [props?.content]);

    

    return (
        <>
            <li key={props?.index} className={` component-chapters-progress-chapters-list-chapter${(props?.content?.progression !== null && (props?.content?.progression?.status !== "UNCOMPLETED" && props?.content?.progression?.status !== null)) && '-done'}
                            ${(props?.open === true) ? "component-chapters-progress-chapters-list-chapter" : "component-chapters-progress-chapters-list-chapter close"}
                            ${(props?.sectionContent?.index === props?.index) && 'component-chapters-progress-chapters-list-chapter-current'}`}
                onClick={() => { props?.displayContent(props?.content, props?.index, props?.content.type) }}>
                <div className={` d-flex flex-column w-100 ${(props?.open === true) ? "component-chapters-progress-description" : "component-chapters-progress-description close"}`}>
                    <div className="d-flex flex-row" style={{ paddingTop: 15 }} >
                        <span className="component-chapters-progress-chapters-list-chapter-title" >{props?.content?.type === "KNOWLEDGETEST" ? t('textConnaissance') : "Examen final"}</span>
                        <span className="ms-auto component-chapters-progress-chapters-list-chapter-duration">{" "}{getDuration(props?.content?.duration)}</span>
                    </div>
                    {/* <span className="component-chapters-progress-chapters-list-chapter-description mt-auto">{props?.content?.title}</span>  */}
                </div>
                {(progress?.status === 'TOREVIEW') ? <div className="component-chapters-progress-chapters-list-chapter-progress-container">
                    <div className="component-chapters-progress-chapters-list-chapter-progress-icon exam">
                        <div className="threedots-large-Animation dark-small d-flex">
                            <span className="small-exam"></span>
                            <span className="small-exam"></span>
                            <span className="small-exam"></span>
                        </div>
                        <svg className="component-chapters-progress-chapters-list-chapter-progress-arc-left " width="23" height="42" viewBox="0 0 23 42" fill="none">
                            <path d="M21.0968 2.46383C10.8218 2.46383 2.49219 10.7936 2.49219 21.0688C2.49219 31.3441 10.8218 39.6738 21.0968 39.6738" strokeWidth="3" strokeLinecap="round"></path>
                        </svg>
                        <svg className="component-chapters-progress-chapters-list-chapter-progress-arc-right " width="23" height="42" viewBox="0 0 23 42" fill="none">
                            <path d="M2.10287 39.6739C12.3755 39.6739 20.7031 31.3461 20.7031 21.0733C20.7031 10.8004 12.3755 2.47266 2.10287 2.47265" strokeWidth="3" strokeLinecap="round"></path>
                        </svg>
                    </div>
                </div> :
                    <div className="component-chapters-progress-chapters-list-chapter-progress-container">
                        <div className="component-chapters-progress-chapters-list-chapter-progress-icon">
                            {(progress !== null && progress?.status !== 'UNCOMPLETED' && progress?.status !== null) && <div className={`component-chapters-progress-chapters-list-chapter-progress-test${((progress?.finalMark / progress?.scaleNote) >= 0.5) ? '-done' : '-refused'}`}></div>}
                            <span className={`material-icons-outlined component-chapters-progress-chapters-list-chapter-progress-arc-svg ${(progress === null || progress?.status === 'UNCOMPLETED' || progress?.status === null) ? '' : ((progress?.finalMark / progress?.scaleNote) >= 0.5) ? 'test' : ' test refused'}`}>
                                emoji_events
                            </span>
                            <svg className={`component-chapters-progress-chapters-list-chapter-progress-arc-left ${(progress === null || progress?.status === 'UNCOMPLETED' || progress?.status === null) ? '' : ((progress?.finalMark / progress?.scaleNote) >= 0.5) ? 'test' : 'test refused'}`} width="23" height="42" viewBox="0 0 23 42" fill="none" >
                                <path d="M21.0968 2.46383C10.8218 2.46383 2.49219 10.7936 2.49219 21.0688C2.49219 31.3441 10.8218 39.6738 21.0968 39.6738" strokeWidth="3" strokeLinecap="round" />
                            </svg>
                            <svg className={`component-chapters-progress-chapters-list-chapter-progress-arc-right ${(progress === null || progress?.status === 'UNCOMPLETED' || progress?.status === null) ? '' : ((progress?.finalMark / progress?.scaleNote) >= 0.5) ? 'test' : 'test refused'}`} width="23" height="42" viewBox="0 0 23 42" fill="none">
                                <path d="M2.10287 39.6739C12.3755 39.6739 20.7031 31.3461 20.7031 21.0733C20.7031 10.8004 12.3755 2.47266 2.10287 2.47265" strokeWidth="3" strokeLinecap="round" />
                            </svg>
                        </div>
                    </div>}
            </li>

        </>
    )
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
        },
        dispatch
    );
const mapStateToProps = (state: AppState, ownProps: any) => ({
    content: ownProps?.content,
    index: ownProps?.index,
    open: ownProps?.open,
    displayContent: ownProps?.displayContent,
    sectionContent: state.studentTraining.sectionContentDetails
});

export default connect(mapStateToProps, mapDispatchToProps)(ExamSidebarComponent);

