import { ComponentProps, FC, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../global-state/store/root-reducers';
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
import { exportList } from '../../global-state/actions/professor-actions';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import SelectElement from '../../elements/select/select-element';


const studentsStatusList=[
    { label: "Tous les étudiants", value: "all" },
    { label: "Admis", value: "Admis" },
    { label: "En attente", value: "Enattente" },
    { label: "Suspendus", value: "Suspendu" },
]
const FilterStudentsListComponent: FC<ReturnType<typeof mapStateToProps> & ComponentProps<any>> = (props:any) => {
    const { t } = useTranslation();
    const [searchFilter, setSearchFilter] = useState("");
    const [generalFilter, setGeneralFilter] = useState("");
    const [candidatesFilter, setCandidatesFilter] = useState("");
    const [certifiedFilter, setCertifiedFilter] = useState("");
    const [inscribedFilter, setInscribedFilter] = useState("");
    const [suspendedFilter, setSuspendedFilter] = useState("");
    const [unpaidFilter, setUnpaidFilter] = useState("");
    const [exportButtonLoading, setexportButtonLoading] = useState<boolean>(props?.loadingExport)
    
    const [directorPageSearch, setdirectorPageSearch] = useState<string>("")
    const [studentStatusToDisplay, setStudentStatusToDisplay] = useState<string>("all")


    useEffect(() => {
      if(props?.connectedUser?.role === 'role_director' && props?.studentsListStatus!==undefined && props?.studentSearch!==undefined &&  window!==undefined && window.location.href!==undefined && window.location.href.includes("students/all")){
        setdirectorPageSearch(props?.studentSearch)
        setStudentStatusToDisplay(props?.studentsListStatus)
      }
    
      return () => {
        setdirectorPageSearch("")
        setStudentStatusToDisplay("")
      }
    }, [props?.studentSearch ,props?.studentsListStatus])

    useEffect(() => {
        if(props?.actualTab === "All" && window!==undefined && window.location.href!==undefined && window.location.href.includes("students/all")){
            setTimeout(() => {
                props?.updateFilterData(directorPageSearch, studentStatusToDisplay)
            }, 1000)
        }
    }, [directorPageSearch, studentStatusToDisplay , window])

    useEffect(() => {
        setexportButtonLoading(false)
    }, [])

    useEffect(() => {
        if(props?.loadingExport!==undefined){
            setexportButtonLoading(props?.loadingExport)
        }
    }, [props?.loadingExport])
    
    useEffect(() => {
        setGeneralFilter(props?.data?.generalFilter);
        setCandidatesFilter(props?.data?.candidatesFilter);
        setCertifiedFilter(props?.data?.certifiedFilter);
        setInscribedFilter(props?.data?.inscribedFilter);
        setSuspendedFilter(props?.data?.suspendedFilter);
        setUnpaidFilter(props?.data?.unpaidFilter);
    }, [props?.data])

    const handleSearchFilter = (lastTabClicked: any) => {
        if (lastTabClicked === 'All') {
            return generalFilter
        }
        if (lastTabClicked === 'Candidate') {
            return candidatesFilter
        }
        if (lastTabClicked === 'Certified') {
            return certifiedFilter
        }
        if (lastTabClicked === 'Inscribed') {
            return inscribedFilter
        }
        if (lastTabClicked === 'Unpaid') {
            return unpaidFilter
        } else {
            return suspendedFilter
        }
    }
    
    useEffect(() => {
        setSearchFilter(handleSearchFilter(props?.actualTab));
    }, [props?.actualTab])

    const filterListText = (value: string) => {
        setSearchFilter(value);
        if (props?.connectedUser?.role === 'role_director' && window!==undefined && window.location.href!==undefined && window.location.href.includes("students/all")) {
            setdirectorPageSearch(value.toLowerCase())
        }
        else {
            props?.addDataList(props?.actualTab, value);
        }
    }
    
    const exportData = async () => {
        const lang = localStorage.getItem("language")
        let actualTabOps = props?.actualTab === "Inscribed" ? t('listInscribed') : t('listSuspended')
        let actualTabOpss = props?.actualTab === "Certified" ? t('listdipv') : actualTabOps
        let actualTabOp = props?.actualTab === "Candidate" ? t('listcand') : actualTabOpss
        let actualTabUnpaid = props?.actualTab === "Unpaid" ? t('listOfUnpaidStudents') : actualTabOp
        let name = (props?.actualTab === "All" ? t('ListEtds') : actualTabUnpaid) + " " + props?.nameSchool;
        let filterCourse;
        if (props?.connectedUser?.role === 'role_prof') {
            filterCourse = { entityId: props?.connectedUser?.idSchool, entityType: "SCHOOL", idProf: props?.connectedUser?.idPerson, status: "PUBLISHED" };
        } else {
            filterCourse = { entityId: props?.connectedUser?.idSchool, entityType: "SCHOOL", status: "PUBLISHED" };
        }
        await props?.exportList(filterCourse, props?.actualTab, props?.connectedUser?.idSchool, name, lang)
    }



    return (
        <div className="mt-4 p-0" style={{ maxWidth: '1200px' }}>
            <div className={`d-flex -align-items-center justify-content${(props?.inCourse) ? '-end' : '-between'} w-100 px-0`} >
                {exportButtonLoading === false ?
                    <button type="button" style={{ marginRight: '16px' }} className="btn-Secondary large icon-left" onClick={() => { exportData() }}>
                        <span className="material-icons-outlined" style={{ marginRight: '8px' }} >download</span>
                        <div className="m-auto ps-2">{t('exportListe')}</div>
                    </button>
                    :
                    <button className="btn-Secondary loaddata large" style={{ width: "200px", height: "48px" }}>
                        <div className="threedots-large-Animation">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </button>
                }

                <div className="d-flex align-items-center flex-wrap">
                    {(props?.connectedUser?.role==='role_director' && props?.actualTab ==="All" && props?.history!==undefined &&  window!==undefined && window.location.href!==undefined && window.location.href.includes("students/all")) && <div style={{ maxWidth: '225px', minWidth: '225px', marginRight: '10px' }}>
                        <SelectElement disabled={false} taille={'lg'} defaultValue={studentsStatusList[0].value} options={studentsStatusList} onChange={(e: any) => { setStudentStatusToDisplay(e) }} placeholder={t('filterProfessors')} />
                    </div>}

                    <div className="input-form-control" style={{ maxWidth: '220px' }}>
                        <input dir="ltr" type="text" className="form-control input text-default" placeholder={t('searchProfessors')} value={searchFilter} onChange={(e: any) => {filterListText(e.target.value)}} />
                        <span className="material-icons-outlined">search</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state: AppState, ownProps: any) => ({
    nameSchool: state.authReducer.connectedSchoolInfo?.code,
    connectedUser: state.authReducer.connectedUser,
    data: ownProps?.data,
    addDataList: ownProps?.addDataList,
    trainings: state.trainingReducer.trainings,
    loadingExport:state.profReducer.loadingExport,
    history: state.historyReducer.lastPathBeforeBreadCrumb
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            exportList
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(FilterStudentsListComponent);
