export const TRANSLATIONS_IT = {
    addCourse: "NUOVO CORSO",
    createCourse: "Crea un corso",
    myCourses: "I miei corsi",
    Published: "In linea",
    Draft: "Disorganizzato",
    Pending: "In attesa di approvazione da parte del direttore",
    RequestDeactivation: "In attesa di disattivazione da parte del direttore",
    RequestActivation: "In attesa di attivazione da parte del direttore",
    RequestDeletion: "In attesa di archiviazione da parte del direttore",
    certified: "Laureati",
    candidats: "CANDIDATI",
    candidatsCapitalise: "Candidati",
    courseStatus_1: "Il corso rimarrà",
    courseStatus_2: "privato fino alla pubblicazione",
    Pending_2: "In attesa di convalida",
    RequestDeletion_2: "In attesa di archiviazione",
    RequestDeactivation_2: "In attesa di disattivazione",
    RequestActivation_2: "In attesa di attivazione",
    actvity: "ATTIVITÀ",
    students: "Studenti",
    students_2: "Alunno",
    description: "DESCRIZIONE",
    archive: "ARCHIVIO",
    publish: "PUBBLICARE",
    inscription: "ISCRIZIONE",
    inscriptionTitle: "Iscrizione",
    certifiedTitle: "Laureato",
    lastVisitProfile: "Ultima visita fa",
    lastVisitProfile_1: "l'ultima visita",
    lastVisitProfile_2: "Io si",
    descProf: "Breve descrizione del docente che apparirà nella presentazione del corso",
    content: "CONTENUTI",
    titleCoursePlaceholder: "Inserisci qui il titolo del corso...",
    titleCourseForm: "Titolo del corso",
    modalPublishCourse_1: "Invia al direttore per l'approvazione",
    modalPublishCourse_1_1: "Convalida il post",
    modalRefusedCourse_1: "Rifiuta la pubblicazione",
    modalPublishCourse_2: "Aggiungi un commento per il regista",
    modalPublishCourse_2_2: "Aggiungi un commento per l'insegnante",
    modalPublishCourse_3: "Commentatore...",
    modalPublishCourse_4: "INVIA CORSO",
    modalPublishCourse_4_4: "PUBBLICARE IL CORSO",
    modalRefusedCourse_4: "RIFIUTA IL CORSO",
    modalPublishCourseSuccess: "Il corso è stato inviato al direttore per la convalida.",
    modalPublishPathSuccess: "Il corso è stato inviato al direttore per la convalida.",
    modalPublishSessionSuccess: "La sessione è stata inviata al direttore per la convalida.",
    modalPublishCourseSuccess_1: "Il corso è pubblicato. È ora disponibile online.",
    modalDeactivateCours: "Il corso è disabilitato. Non è più disponibile online.",
    modalRefusedCourseSuccess: "Hai appena rifiutato la pubblicazione del corso. Il tuo rifiuto è stato inviato all'insegnante.",
    titleFormCourse_1: "Video di presentazione del corso",
    titleFormCourse_2: "Visual per illustrare il corso",
    titleFormCourse_3: "Presentazione testuale del corso",
    resume: "Riassunto introduttivo",
    textAreaPlaceholder: "Inserisci qui il testo...",
    placeholderInscriptionInput: "Inserisci il testo qui",
    prerequisite: "Prerequisiti per frequentare questo corso",
    objectives: "obiettivi educativi",
    skills: "Conoscenze e competenze acquisite attraverso questo corso",
    skillsPlaceholder: "Inserisci una parola chiave...",
    uploadVideo_1: "Trascina e rilascia il video del corso che desideri caricare (mp4, mov)",
    uploadVideo_2: "SELEZIONA UN FILE VIDEO",
    uploadImage_1: "Trascina e rilascia una foto per illustrare il corso (jpg, png)",
    uploadImage_2: "SELEZIONA UN'IMMAGINE",
    downloadVideo: "Scarica",
    awaitVideo: "In coda",
    whiletranscode: "Attualmente in visione",
    transcodingVideo: "Elaborazione video",
    breadCrumbChapter: "Capitolo",
    chapitre: "Capitolo",
    newTest: "NUOVA PROVA",
    newChapter: "NUOVO CAPITOLO",
    selectLevelCourse: "Livello",
    selectLevelCourse_1: "Novizio",
    selectLevelCourse_2: "Intermedio",
    selectLevelCourse_3: "Confermato",
    listChaptersContent: "Per iniziare, specifica il livello del corso e crea il tuo primo capitolo.",
    chapterSelectFomCHapter: "CAPITOLO",
    saveChapter: "REGISTRARE",
    durationChapter: "Durata",
    placeholderTitleChapter: "Inserisci qui il titolo del capitolo...",
    titleFormChapter_1: "Video del capitolo",
    titleFormChapter_2: "File da scaricare",
    titleFormChapter_3: "Testo del capitolo",
    textFileUpload_1: "Trascina e rilascia i file di caricamento dei capitoli",
    textFileUpload_2: "(audio, PDF, PowerPoint, Word, Excel...)",
    fileUploadButton: "Seleziona il file",
    uploadVideoChpter_1: "Trascina e rilascia il video del capitolo che vuoi caricare (mp4, mov)",
    tests: "Prove",
    test: "Test",
    resultat: "Risultati",
    reponseInscrit: "Richiesta di abbonamento",
    reponseAv: "Rispondi prima",
    refusedAt: "Rifiutato",
    Refused: "Rifiutare",
    Unpaid: "Pagare",
    seeDemand: "Vedi la mia richiesta",
    certifButton: "CERTIFICATO",
    courseTable: "Formazione",
    DATEACHAT: "Data di acquisto",
    exportListe: "ELENCO ESPORTAZIONE",
    filterProfessors: "Insegnante",
    statusFilter: "Stato",
    search: "Ricerca",
    Tous: "Tutti",
    Brouillon: "Disorganizzato",
    Avalider: "Per convalidare",
    Enligne: "In linea",
    Archive: "Archivio",
    Refuse: "Rifiutare",
    Cours: "Corso",
    COURSE: "Corso",
    Professeurs: "Insegnanti",
    Demandes: "Richieste",
    filterCourse: "Tutti i corsi",
    Certifies: "Certificato",
    Statut: "Stato",
    PresProf: "Presentazione del Professore",
    dernVisite: "l'ultima visita",
    Enattente: "In attesa di",
    Admis: "accettato",
    Suspendu: "Sospeso",
    inviteProf: "INVITA UN INSEGNANTE",
    inviteProf_2: "Invita un insegnante a registrarsi",
    send: "mandare",
    inviteSuccess: "Il tuo invito è stato inviato all'insegnante.",
    myAccount: "Il mio account",
    saveImg: "salva immagine",
    Profile: "Profilo",
    Nom: "Nome",
    NomObligatoire: "Nome*",
    PrenomObligatoire: "Nome*",
    Prenom: "Nome",
    country: "Paese di residenza",
    Tunisie: "Tunisia",
    France: "Francia",
    city: "comune di residenza",
    birthdate: "Data di nascita",
    birthdate_input: "Data di nascita (mm / gg / aaaa)",
    NivScolaire: "Livello scolastico",
    Primaire: "Primario",
    College: "Scuole medie",
    Lycee: "Scuola superiore",
    Bac: "Bac",
    Technicien2: "Tecnico (Bac +2)",
    License: "Licenza (Bac+3)",
    Master: "Master o equivalente (BAC +4 o + 5)",
    Ingenieur: "Ingegnere",
    Doctorat: "dottorato",
    emailAdd: "Indirizzo e-mail",
    updatePass: "Cambia la mia password",
    password: "Parola d'ordine",
    actualPass: "Password attuale",
    newPass: "nuova password",
    enterPassword: "Minimo 8 caratteri",
    msgErrorPassword: "Inserisci almeno 8 numeri/lettere",
    msgErrorConfirmPassword: "Si prega di inserire la stessa password",
    confimrNewPass: "Conferma password",
    msgErrorMail: "Si prega di inserire un indirizzo email valido",
    confimrNewEmail: "Nuovo Indirizzo Email",
    validPassButton: "Convalida la mia nuova password",
    MainSchoolColor: "Colore della scuola principale",
    AddYourColorToPersonalizeThePresentationOfTheCourses: "Aggiungi il tuo colore per personalizzare la presentazione dei corsi",
    imgCrop_1: "Trascina e rilascia una foto",
    imgCrop_3: "Trascina e rilascia il tuo logo",
    imgCrop_2: "SELEZIONA UN'IMMAGINE",
    pathBy: "Corso tenuto da",
    sessionBy: "Sessione data da",
    catalogCourses: "Catalogo dei corsi",
    Etudiants: "Studenti",
    Ecole: "Scuola",
    Catalogue: "Catalogare",
    Activite: "Attività",
    lecole: "Scuola",
    howItWorks: "Come funziona",
    hello: "Ben arrivato !",
    cnxButton: "Accesso",
    createAccountMsg: "creare il mio account",
    questionCreateAccount: "Non hai ancora creato un account?",
    forgotLogin: "Hai dimenticato la password ?",
    emailLogin: "E-mail",
    validInscription: "CONVALIDA LA MIA REGISTRAZIONE",
    activateAccount: "ATTIVA IL MIO ACCOUNT",
    madame: "DONNE",
    monsieur: "UOMO",
    other: "ALTRO",
    registerDone_1: "La tua richiesta di registrazione è stata presa in considerazione.",
    registerDone_2: "Ti è stata inviata un'e-mail.",
    registerDone_3: "Clicca sul link fornito all'interno di questa e-mail per confermare la creazione del tuo account.",
    registerDone_4: "A presto !",
    validRegister: "convalidare",
    activateMyAccount: "Attiva il mio account",
    directeur: "Direttore",
    professeur: "Insegnante",
    invalidToken: "Questo collegamento è già stato utilizzato ed è scaduto, contatta il supporto se necessario.",
    questionAccount: "Hai già un account ?",
    verifMail_1: "La tua email è stata verificata, ora puoi accedere.",
    verifMail_3: "Convalida della tua email",
    changePass: "Cambia la password",
    msgUpdatedPass: "La password è stata modificata.",
    recPass: "Recupera la password",
    reinMail: "Inserisci il tuo indirizzo email qui sotto. Riceverai un link per reimpostare la tua password.",
    reinBUttonPass: "Reimposta la mia password",
    sendMailRein: "Ti è stata inviata un'e-mail contenente il tuo link di ripristino.",
    alreadyHaveAcc: "Hai già un account ?",
    followCourse: "Segui questo corso",
    followPath: "Segui questo percorso",
    followSession: "Segui Sessione",
    objPEda: "obiettivi educativi",
    preresuiPeda: "Prerequisiti per questo corso",
    preresuiPedaPath: "Prerequisiti per questo corso",
    preresuiPedaSession: "Prerequisiti per questa sessione",
    skillsPreveiw: "Conoscenze e competenze acquisite attraverso questo corso",
    skillsPreveiwPath: "Conoscenze e competenze acquisite attraverso questo corso",
    skillsPreveiwSession: "Conoscenze e abilità acquisite durante questa sessione",
    courseProgramm: "Programma del corso",
    Chapitres: "Capitoli",
    videoCourse: "Videocorso",
    textCourse: "Testo del corso",
    downloadFiles: "File da scaricare",
    testConn: "Test di conoscenza",
    aboutSchool: "Sulla scuola",
    aboutProfessor: "A proposito dell'insegnante",
    registerCourse: "Iscriviti al corso",
    registerPath: "Iscriviti al corso",
    registerSession: "Registrati alla sessione",
    testConn_2: "Prova la tua conoscenza",
    progressView: "Tieni traccia dei tuoi progressi",
    previewText_1: "Compila la tua richiesta di registrazione in modo che l'insegnante possa convalidarla.",
    previewText_2: "Avanza nel tuo programma e impara nuove abilità.",
    previewText_3: "Convalida le tue conoscenze con i test e ottieni il tuo certificato.",
    Introduction: "introduzione",
    Documents: "Documenti",
    downloadFIleDetails: "Scaricare",
    Etudiant_1: "Alunno",
    Professeur_1: "Insegnante",
    Directeur_1: "Direttore",
    annuler: "PER CANCELLARE",
    modifier: "Modificatore",
    logout: "Disconnettersi",
    connexionButton: "Connessione",
    languageFR: "Francese",
    languageEN: "inglese",
    language: "Lingua",
    Pending_3: "Per convalidare",
    CERTIFICAT: "CERTIFICATO",
    refuseCourse: "RIFIUTA LA PUBBLICAZIONE",
    joursVisit: "giorni",
    consulter: "CONSULTARE",
    jourVisit: "Giorno",
    Candidat: "Candidato",
    enCours: "STUDENTI IN CORSO",
    certifiés: "LAUREATI",
    Dossier: "Caso",
    Demande: "Richiesta",
    DemandeEnvoyéeIlYA: "Richiesta inviata fa",
    Resultats: "Risultati",
    Certificat: "Certificato",
    Tempsdobtention: "Ottenere",
    Tempsdobtention_2: "Ottieni tempo",
    refuseCourseDirector: "Rifiuta la pubblicazione",
    refuseCourseDirector_2: "Aggiungi un commento per l'insegnante",
    refuseCourseDirector_3: "RIFIUTA IL CORSO",
    modalRefuseCourseSuccess_1: "Hai appena rifiutato la pubblicazione del corso.",
    modalRefuseCourseSuccess_2: "Il tuo rifiuto è stato inviato all'insegnante.",
    archiveCourseDirector: "Sei sicuro di voler archiviare questo corso?",
    archiveCourseDirectorSuccess: "Il corso è archiviato.",
    archiveCourseDirector_2: "ARCHIVIA IL CORSO",
    nothingTOSay: "Niente da dire",
    online: "In linea",
    offline: "disconnesso",
    validationNewPassword: "Puoi accedere con la tua nuova password.",
    showMore: "MOSTRA SEGUENTE",
    folderRegistrationTrueOrFalse: "Con riserva di convalida del file.",
    nextCommentsFeedback: "Mostra successivo",
    emailUpdateButtonShH: "CAMBIA IL MIO INDIRIZZO EMAIL",
    conditionDeValidation: "Condizioni di convalida della richiesta di iscrizione allo studente da parte del docente.",
    Acceptation: "Accettazione della registrazione automatica degli studenti.",
    AcceptationSousDossier: "L'accettazione dell'immatricolazione dello studente è subordinata alla convalida della pratica.",
    questions: "Domande da porre allo studente",
    documents: "Documenti da richiedere allo studente",
    titreQuestion: "Inserisci qui il titolo della domanda",
    titreDocument: "Indica qui il titolo del documento",
    AjouterQuestion: "Aggiungi domanda",
    AjouterDocument: "Aggiungi documento",
    demandeDinscription: "Richiesta di abbonamento",
    envoyerDemande: "INVIA LA MIA RICHIESTA",
    titreRegistration_1: "Domande poste dall'insegnante",
    titreRegistration_2: "Documenti richiesti dal docente",
    Inscribed: "In corso",
    CertifiedStudent: "Certificato",
    DraftStudent: "File di registrazione in corso",
    Candidate: "In attesa di convalida del file",
    finishRegistration: "TERMINA LA MIA RICHIESTA",
    noCoursesStudent: "Non sei iscritto a nessun corso.",
    discoverCatalog: "SCOPRI IL CATALOGO",
    noCoursesCreated: "Nessuna formazione è stata ancora creata.",
    noCoursesCreated_2: "Per iniziare, crea il tuo primo corso.",
    noCourses_1: "Nessun corso frequentato",
    noCourses_2: "Nessuna richiesta di registrazione",
    noCourses_3: "Nessun corso completato",
    noCoursesPublished: "Nessun corso è stato ancora pubblicato.",
    deleteCompte: "Cancella il mio account",
    searchProfessors: "Ricercare...",
    descriptionProfLabel: "Breve descrizione del docente che apparirà nella presentazione dei suoi corsi.",
    relaunchProfInvitation: "RISVEGLIA",
    noProfessorsList_1: "Nessun insegnante trovato.",
    noProfessorsList_2: "Si prega di cercare di nuovo.",
    noProfessorsListDefault_1: "Nessun insegnante è registrato.",
    noProfessorsListDefault_2: "Invita un insegnante a registrarsi per iniziare.",
    signatureProf: "Firma del Professore",
    signatureDirector: "Firma del direttore",
    Deactivate: "Disabilitato",
    breadCrumbTest: "Test",
    placeholderTitleExam: "Indica qui il titolo della prova...",
    exempleTitleExam: "Esempio, Prova di conoscenza della prima conoscenza",
    noQuestionTest: "Per iniziare, crea la tua prima domanda.",
    newQuestion: "NUOVO PROBLEMA",
    addQuestionText: "Aggiungi domanda",
    placeholderQuestionText: "Indica la domanda",
    answerQuestion: "Indica la risposta",
    placeholderChoiceText: "Indicare il testo delle scelte multiple",
    delete: "Cancellare",
    suppcomte: "Cancellazione dell'account",
    selectMotif: "Seleziona un modello",
    coursNeMeconvier: "Le lezioni non mi vanno bene",
    comm: "Aggiungi un commento",
    DeleteSuccess: "Il tuo account è stato eliminato con successo. Arrivederci.",
    CreationAccountProfDirector: "Il tuo account è stato creato. Puoi accedere.",
    messageEnvoyerDemande_1: "La tua richiesta di registrazione è stata inviata.",
    messageEnvoyerDemande_2: "Riceverai una risposta via e-mail",
    messageEnvoyerDemande_3: "nei prossimi 3 giorni.",
    candidat_status: "IN ATTESA DI CONVALIDA",
    refuser: "PER RIFIUTARE",
    refuser_1: "RIFIUTARE",
    valider: "CONVALIDARE",
    valider_1: "ACCETTATO",
    refuserDemandeRegistration: "Nega la registrazione dello studente",
    ajouterCommentaireEtudiant: "Aggiungi un commento per lo studente.",
    refuserLaDemande: "RIFIUTA LA RICHIESTA",
    messageRefuseRegistrationCours: "L'iscrizione degli studenti al corso è stata rifiutata con successo.",
    messageRefuseRegistrationPath: "L'iscrizione dello studente al corso è stata rifiutata con successo.",
    messageRefuseRegistrationSession: "La registrazione degli studenti alla sessione è stata rifiutata con successo.",
    messageValiderRegistration: "Lo studente è stato invitato a iniziare a seguire il corso.",
    messageValiderRegistrationParcours: "Lo studente è stato invitato a iniziare a seguire il corso",
    messageValiderRegistrationSession: "Lo studente è stato invitato a iniziare a seguire la sessione",
    SuspendProf: "Sospendere",
    textProfSuspend: "Questo insegnante non potrà più accedere alle lezioni. Hai la possibilità di reintegrarlo se cambi idea.",
    otherChoice: "Altro",
    commentProfSuspend: "Aggiungi un commento per l'insegnante",
    questionLabel: "Domanda",
    choiceLabelBON: "Risposta esatta",
    addAnswerButton: "Aggiungi una risposta",
    noQuestionTestPreview: "Niente domande",
    passedTestAverage: "Tasso di successo",
    param: "Ambientazione",
    presen: "Presentazione",
    commentSuspendInputProf: "Commento...",
    motifSuspendProf: "Seleziona un modello",
    affectOtherPRof: "Seleziona un insegnante a cui assegnare le lezioni",
    ReintegrateProf: "Reintegrare",
    ReintegrateProfText: "Questo docente potrà accedere ai corsi. Hai la possibilità di sospendere l'insegnante se cambi idea.",
    AcceptProf: "Accettare",
    aArchiver: "Da archiviare",
    aDesactiver: "Per disattivare",
    aActiver: "Attivare",
    desactiverCourse: "CONFERMA LA DISATTIVAZIONE",
    refuseDesactivationCourse: "RIFIUTA LA DISATTIVAZIONE",
    activerCours: "CONVALIDA ATTIVAZIONE",
    refuserLactivationCours: "RIFIUTA ATTIVAZIONE",
    archiverCours: "CONVALIDA ARCHIVIAZIONE",
    refuserArchivationCours: "RIFIUTA L'ARCHIVIAZIONE",
    settingEcole: "Impostazioni scolastiche",
    nomEcole: "Nome della scuola",
    logoEcole: "logo della scuola",
    persenCourte: "Breve presentazione (questo testo verrà visualizzato nelle pagine del corso)",
    listCandidates: "Richieste di registrazione",
    listFollowing: "Sono seguiti corsi di formazione",
    listeCertificated: "Allenamenti completati",
    sendDate: "Invio della richiesta",
    importVideoUpload: "Scarica video",
    waitingUpload: "Attendere prego...",
    uploadFinishing: "Il video sarà disponibile a breve",
    uploadPending: "In lavorazione...",
    candidatures: "Applicazioni",
    certifcations: "CERTIFICAZIONI",
    dashboardAllStudents: "Alunno",
    certifiedDashStudents: "Laureato",
    YesWord: "sì",
    NoWord: "Non",
    obtentionPeriod: "Ottenere",
    In: "In",
    TimePassed: "Io si",
    noStudentFound: "Nessuno studente attuale",
    noCandidateFound: "Nessuna domanda pendente",
    noSuspendedFound: "Nessuno studente è sospeso",
    noCertifedFound: "Nessuno studente certificato o laureato",
    textStudentSuspend: "Questo studente non potrà più accedere ai corsi. Hai la possibilità di reintegrarlo se cambi idea.",
    commentStudentSuspend: "Aggiungi un commento per lo studente",
    successSuspendStudent: "Lo studente non ha più accesso ai corsi.",
    ReintegrateStudentText: "Questo studente potrà accedere ai corsi.",
    ReintegrateStudentText_2: "Hai la possibilità di escludere lo studente se cambi idea.",
    successReinstateStudent: "Lo studente ha ora accesso ai corsi.",
    successSuspendProf: "Professore sospeso con successo",
    successSuspendAssitant: "Procedura guidata sospesa con successo",
    successReinstateProf: "Professore reintegrato con successo",
    successReinstateAssistant: "Procedura guidata di reintegrazione riuscita",
    returnToProfProfile: "Torna AL PROFILO",
    diplomeButton: "Diploma",
    validatedDiploma: "DIPLOMI",
    Declcatalog: "SCOPRI IL CATALOGO",
    deactivateCoursText: "Questo corso non sarà più accessibile, hai la possibilità di riattivarlo se cambi idea.",
    modalDeactivateCoursTitle: "Disattivazione del corso",
    deactivateCours: "disattivare",
    successdeactivateCours: "È stato completato con successo.",
    createBy: "Dato da",
    maleGender: "M.",
    femaleGender: "Mamma",
    modalReactivateCoursTitle: "Riattivazione del corso",
    modalReactivatePathTitle: "Riattivazione del corso",
    modalReactivateSessionTitle: "Riattivazione della sessione",
    reactivateCours: "Riattiva",
    reactivateCoursText: "Questo corso sarà accessibile.",
    reactivateCoursText_2: "Hai la possibilità di disattivarlo se cambi idea.",
    reactivatePathText: "Questo corso sarà accessibile.",
    reactivatePathText_2: "Hai la possibilità di disattivarlo se cambi idea.",
    reactivateSessionText: "Questa sessione sarà accessibile.",
    reactivateSessionText_2: "Hai la possibilità di disattivarlo se cambi idea.",
    champsObligatoires: "(*) Campi richiesti",
    continueCreatingCourse: "Spiacenti, non puoi pubblicare questo corso.",
    continueBUttonText: "CONTINUARE",
    errorChaptersLength: "Devi avere almeno un capitolo (richiesto).",
    linkImgCourseError: "Visual per illustrare il corso (obbligatorio).",
    descriptionCourseError: "Presentazione del testo del corso (obbligatoria).",
    congratulation: "Congratulazioni per la tua laurea!",
    diplomaMsg : "Per poter scaricare il tuo diploma consulta tutti i contenuti dei capitoli (Video inclusi)",
    onlineCOurses: "Corso in linea",
    validatedCourses: "Corso da convalidare",
    AllCoursesDirector: "Tutti i corsi",
    BonneRéponses: "Buone risposte",
    Question: "Domanda",
    ReturnExam: "RIPRENDI IL MIO TEST ATTUALE",
    textExa: "Seleziona una o più risposte a seconda dell'istruzione. Il test viene eseguito una sola volta.",
    StartExam: "Cominciare",
    Réponse: "Risposta",
    QCU: "Possibile solo 1 risposta corretta",
    QCM: "Molte possibili risposte",
    validCourse: "CONVALIDA LA PUBBLICAZIONE",
    refuseInscriptionStudent: "RICHIESTA RIFIUTATA",
    BonneReponses: "Buone risposte",
    placeholderTextArea: "Inserisci qui il testo...",
    languageIT: "Italia",
    languageES: "spagnolo",
    languageDE: "Tedesco",
    languageNL: "Olandese",
    languageSV: "svedese",
    obligatoireFormCourse: "obbligatorio",
    Registered: "Registrato",
    New: "Nuovi",
    Suspended: "Sospeso",
    CatalogueVisitorsInTheLast30Days: "Visitatori del catalogo negli ultimi 30 giorni.",
    Course: "Corso",
    Candidates: "Candidati",
    Teachers: "Insegnanti",
    ConversionRateOfApplicationsInTheLast30Days: "Tasso di conversione delle visite negli ultimi 30 giorni.",
    MedianCourseNotes: "Punteggio medio dell'esame finale",
    AverageAgeOfStudents: "Età media degli studenti",
    Male: "Uomini",
    Female: "Donne",
    MostRepresentedCities: "Città più rappresentate",
    VisitsAndApplicationsForRegistration: "VISITE E ISCRIZIONI",
    RegistrationsAndCertifications: "ISCRIZIONI E LAUREE",
    ConnectedStudents: "Studenti connessi",
    LevelOfStudy: "livello di studi",
    numberOfVisits: "Visite alla pagina panoramica",
    Applications: "Richieste di registrazione",
    Registration: "Numero di iscrizioni",
    StudentsNumber: "Numero di studenti collegati",
    Jan: "gen",
    Fev: "febbraio",
    Mar: "mar",
    Avr: "aprile",
    May: "Maggio",
    Jui: "Giugno",
    Jul: "Jui",
    Aug: "Me",
    Sep: "sett",
    Oct: "ottobre",
    Nov: "nov",
    Dec: "dic",
    Access: "Accedere",
    Year: "anno",
    StudentsConnectedThisMonth: "Effettuato l'accesso negli ultimi 30 giorni.",
    AverageTimeSpentOnThePlatformForGraduation: "Tempo medio trascorso sulla piattaforma per la laurea",
    AverageTimeBetweenRegistrationAndGraduation: "Tempo medio dall'immatricolazione alla laurea",
    certification: "Certificazione",
    waitingCertif: "Caricando il tuo diploma, attendi...",
    ListEtds: "Elenco studenti",
    listcand: "Elenco dei candidati",
    listdipv: "Elenco dei laureati",
    listInscribed: "Elenco degli iscritti",
    listSuspended: "Elenco dei sospesi",
    Days: "giorni",
    noVideoToShow: "Nessun video.",
    donnerAvis: "È importante !",
    donnerAvis_2: "Esprimi la tua opinione sul corso",
    avisTitle: "titolo della tua recensione",
    avisText: "Scrivi la tua recensione",
    votreAvis: "La tua opinione qui",
    publishMyReview: "Pubblicare",
    monAvis: "La mia opinione sul corso",
    avis: "Avviso",
    Anonymous: "Anonimo",
    noFeedback: "Nessuna recensione (per ora)",
    Finance: "Finanza",
    PublishedAt: "pubblicato su",
    PublishedAte: "pubblicato su",
    ActualPrice: "Ultimo prezzo del periodo",
    MoyPrix: "Prezzo medio",
    discussionButton: "Discussioni",
    questionButton: "Domande all'insegnante",
    addCommentButton: "Aggiungi un commento",
    addCommentForChapter: "Aggiungi un commento al capitolo",
    addcommentForCourse: "Aggiungi un commento al corso",
    addcommentForPath: "Aggiungi un commento al corso",
    addCommentForSession: "Aggiungi un commento alla sessione",
    commentTitle: "Titolo del commento",
    yourComment: "Scrivi il tuo commento",
    votreCommentaie: "Il tuo commento qui...",
    addQuestionButton: "Aggiungi domanda",
    addCommentSuccess: "Il tuo commento è stato aggiunto con successo",
    responses: "Risposte",
    noCommentFound: "Nessun commento (per ora)",
    repondre: "rispondere",
    labeldeletecompte: "Tutti i tuoi dati verranno eliminati definitivamente",
    listdesProf: "Elenco degli insegnanti",
    noCoursePayedDefault: "Nessun corso a pagamento.",
    noCoursePayed_1: "Nessun corso a pagamento trovato.",
    noCoursePayed_2: "Si prega di cercare di nuovo.",
    Courspayed: "Corsi a pagamento",
    CoursFree: "Lezioni gratuite",
    paie: "Elenco dei pagamenti",
    prunp: "Specifica un periodo",
    Année: "Anno",
    community: "Comunità",
    thematic: "Seleziona un tema",
    comment: "Commento",
    NumberOfCertifications: "Numero di certificati",
    inscriptionTypePayment: "Tipo di iscrizione",
    registrationFees: "Costi di iscrizione",
    gratuit: "Gratuito",
    payant: "pagando",
    addQuestionForChapter: "Fai una domanda all'insegnante",
    yourQuestion: "Scrivi la tua domanda",
    addQuestionSuccess: "La tua domanda è stata aggiunta con successo",
    noQuestionFound: "Nessuna domanda da affrontare",
    noQuestionFound_4: "Nessuna risposta alle domande",
    noQuestionFound_5: "Non hai domande.",
    paymentTitle: "Pagamento",
    paymentTitlePlural: "Pagamenti",
    paymentMethodCourses: "Metodo di pagamento del corso",
    choiceCurerncy: "Scegli Valuta",
    Questions: "Domande",
    unsettledQuestion: "Problemi da affrontare",
    settledQuestion: "Problemi affrontati",
    allQuestion: "Tutte le domande",
    recent: "Il più recente",
    oldest: "Il meno recente",
    maxResponses: "Con quante più risposte possibili",
    allSubjects: "Tutti i corsi",
    allSubjects_2: "Tutti gli argomenti",
    noQuestionFound_2: "La tua ricerca non ha soddisfatto nessuna domanda.",
    noQuestionFound_3: "Si prega di cercare di nuovo.",
    noCommentFound_2: "La tua ricerca non ha prodotto alcun commento",
    noPaymentInfo: "Devi scegliere il metodo di pagamento della tua scuola",
    NoPaymentSchoolConfig_1: "Per impostare il metodo di pagamento.",
    NoPaymentSchoolConfig_2: "Clicca qui",
    newResponses: "Nuove risposte",
    SortsMostRecent: "Piu recente",
    SortOldest: "Il più vecchio",
    allStatus: "Tutti gli stati",
    WAITING: "In attesa di convalida",
    SOON: "Venire",
    ONGOING: "In corso",
    ENDED: "Conclusa",
    REFUSED: "Rifiutare",
    allCourses: "Tutti i corsi",
    NoWebinarHasBeenCreatedYetCreateYourFirstWebinarToGetStarted: "Nessun webinar è stato ancora creato. Crea il tuo primo webinar per iniziare.",
    ForStudentsOf: "Per la formazione degli studenti",
    WebinarGivenBy: "Webinar tenuto da",
    ENTERTHEWebinar: "ENTRA NEL webinar",
    StartTHEWebinar: "Avvia IL webinar",
    AddToMyAgenda: "Aggiungi al mio calendario",
    validate: "convalidare",
    inMyAgenda: "nel mio diario",
    Edit: "Modificatore",
    REVIEWTHEwebinar: "REVISIONE IL webinar",
    IWantTheWebinarToBeAccessibleInReplayWithThePublicConcerned: "Vorrei che il webinar fosse accessibile in replica al pubblico interessato",
    NewWebinar: "Nuovo webinar",
    close: "CHIUDERE",
    Webinar: "Webinar",
    WebinarTitle: "Titolo del webinar",
    WebinarDate: "Appuntamento al webinar",
    WebinarSchedule: "Programma webinar",
    ShortDescriptionOfTheWebinar: "Breve descrizione del webinar",
    Cancel: "per cancellare",
    WaitingForValidation: "In attesa di convalida",
    ComingSoon: "In breve",
    LetsGo: "Andiamo",
    ToReview: "Revisionare",
    inscriptionCourseFaq: "Iscriviti a un corso",
    followCourseFaq: "Frequento un corso",
    certificationFaq: "La laurea",
    professorsFaq: "Professori",
    stopCourseFaq: "Interrompi una lezione",
    paymentFaq: "Fatturazione",
    PAYED: "Pagato",
    PRIX: "Prezzo",
    openQuestion: "Domanda aperta",
    Consignes: "Istruzioni",
    Valeurdelaquestion: "Valore della domanda",
    point: "punto",
    Nouveau_test: "Nuova prova",
    TEST_DE_CONNAISSANCE: "PROVA DI CONOSCENZA",
    Test_intermédiaire: "Prova di valutazione intermedia",
    Non_éliminatoire: "Non eliminatoria",
    Question_fermées: "Domande chiuse",
    Test_de_fin_de_cours: "Prova di fine corso",
    Validant_le_diplome: "Convalida del diploma",
    Question_fermée_et_ou_ouvertes: "Domande chiuse e/o aperte",
    modal_exam_title: "Che tipo di test vuoi creare?",
    AJOUTER_EXPLICATION: "AGGIUNGI UNA SPIEGAZIONE",
    Explication: "Spiegazione",
    add: "Aggiungere",
    Créer_votre_premiere_question: "Crea la tua prima domanda",
    Examen_final_envoyé_le: "Esame finale inviato",
    En_attende_de_correction_par_le_professeur: "In attesa di correzione da parte dell'insegnante",
    Répondre_aux_questions_fermees_ou_ouvertes_en_respectant_les_consignes: "Rispondi alle domande chiuse o aperte seguendo le istruzioni.",
    Le_test_seffectue_une_seule_fois_et_détermine_lobtention_de_votre_diplome: "Il test viene eseguito una sola volta e",
    Le_test_seffectue_une_seule_fois_et_détermine_lobtention_de_votre_diplome_2: "determina la tua laurea",
    Réuissi: "Riuscito",
    insuffisant: "insufficiente",
    Valider_ma_réponse: "Convalida la mia risposta",
    Rendu_le: "Consegnato il",
    Par: "di",
    NOTER_LA_QUESTION: "NOTA DOMANDA",
    Examens_à_noter: "Esami per segnare",
    Examen_notés: "Esami graduati",
    Tous_les_examens: "Tutti gli esami",
    aucun_examen_à_noter: "Nessun esame da valutare",
    NOTE: "NOTA",
    à_noter: "da notare",
    Commentaire_du_professeur: "Il commento dell'insegnante",
    Pas_de_commentaire: "Nessun commento",
    Ajouter_un_commentaire: "Aggiungi un commento",
    VALIDER_MA_NOTATION: "CONVALIDA LA MIA VALUTAZIONE",
    EXAMEN_FINAL: "ESAME FINALE",
    exam1: "Indica qui il titolo della prova finale...",
    exam2: "Indicare qui il titolo del test di conoscenza",
    votreMessage: "Scrivi il tuo messaggio...",
    noMessage: "Nessun messaggio",
    noMessageDispo: "non ancora disponibile...",
    message_1: "Devi aspettare l'insegnante",
    message_2: "apri la conversazione",
    message_1_prof: "Avvia una conversazione digitando",
    message_2_prof: "il tuo messaggio qui sotto.",
    textFaq: "E se volessi saperne di più sulla valutazione del mio compito scritto?",
    draftstatdateFollow: "Il caso è iniziato",
    finishdraftFollowButton: "Finisci il mio file",
    gotocourseStudent: "Accedi al corso",
    myFormations: "I miei allenamenti",
    SelectCourse: "Seleziona corso",
    NoWebinarHasBeenCreatedYet: "Nessun webinar è stato ancora creato.",
    TitleFormations: "Formazioni",
    InscriptionDate: "Registrati su",
    biography: "Biografia",
    labelMailProfessor: "E-mail dell'insegnante",
    comments: "Commenti",
    noStudentInscribed: "Nessuno studente è ancora registrato",
    demandTable: "Richiesta effettuata il",
    createNewFormation: "Nuova formazione",
    selectFormatFormation: "Seleziona una taglia",
    pathTitle: "Percorso",
    createPath: "creare un percorso",
    sessionTitle: "Sessione",
    createSession: "creare una sessione",
    requiredLevelText: "Livello richiesto per l'iscrizione al corso",
    anyLevelCourse: "tutti i livelli",
    firstLevel: "Principiante",
    secondLevel: "Intermedio",
    thirdLevel: "esperto",
    ANYLEVEL: "tutti i livelli",
    BEGINNER: "Principiante",
    courseDescriptionForm: "Descrivi il tuo corso",
    descriptionTitle: "Descrizione",
    courseContentTitle: "Contenuto del corso",
    pathContentTitle: "Contenuto del corso",
    sessionContentTitle: "Contenuto della sessione",
    importVideoCourse: "Importazione video",
    createContentText: "Crea il tuo primo contenuto",
    chapterPresentation: "Presentazione del capitolo",
    textPresentationChapter: "Testo introduttivo del capitolo",
    groupes: "Gruppi",
    newGroup: "Nuovo gruppo",
    groupTitle: "Titolo del gruppo di studenti",
    newTestModalTitle: "Nuova prova",
    alreadyCreateExam: "Hai già creato un esame finale",
    finalExmBreadcrunbTitle: "Esame finale",
    noGroupFound: "Nessun gruppo di studenti creato",
    noGroupFound_2: "La tua ricerca non corrisponde a nessun gruppo",
    updateGroupTitle: "Modifica titolo",
    deleteGroup: "Elimina gruppo",
    updateGroup: "Cambia il titolo del gruppo",
    addSubGroup: "Aggiungi un sottogruppo",
    addStudentToGroup: "Aggiungi studenti",
    Actions: "Azioni",
    textConnaissance: "Prova di conoscenza",
    points: "punti",
    connectedThereIs: "effettuato l'accesso fa",
    messageDeleteGroup_1: "Questo gruppo verrà eliminato definitivamente.",
    messageDeleteGroup_2: "Non hai la possibilità di ripristinare questo gruppo se cambi idea",
    messageDeleteGroup_3: "Gruppo eliminato con successo",
    closedQuestion: "Domande chiuse",
    pointsQuestion: "punti)",
    explicationText: "Scrivi la tua spiegazione...",
    allStudent: "Tutti gli studenti",
    subGroup: "sottogruppo",
    changeGroup: "Cambia gruppo",
    addToGroup: "Aggiungi al gruppo",
    deleteFromGroup: "Rimuovi dal gruppo",
    documentTextForm: "Documento",
    returnToCourse: "torna in classe",
    returnToPathButton: "tornare al corso",
    returnToSessionButton: "torna alla sessione",
    sendPublishCoursePRofTitle: "Inviare al direttore per convalidare la pubblicazione",
    returnToStudentList: "Torna all'elenco degli studenti",
    Previsualiser: "Anteprima",
    editeMode: "Modificare",
    withInscription: "Registrazione in archivio",
    freeTitleCourse: "offerto",
    questionPlaceholder: "La tua domanda qui...",
    responsePlceholder: "Scrivi una risposta qui..",
    explicationPlaceholder: "Scrivi una spiegazione qui...",
    consignesPlaceholder: "Le tue istruzioni qui...",
    goToCatalogueError: "Per tornare alla pagina precedente cliccare sul pulsante sottostante.",
    errorCode: "Codice di errore",
    oupsWord: "Ops!",
    errorInfoNotFound: "Siamo spiacenti, la pagina che stai cercando non può essere trovata.",
    errorInfoUnauthorized: "Nessuna autorizzazione trovata.",
    errorSubinfoUnauthorized: "Questa pagina non è accessibile al pubblico.",
    errorInfoAccessRefused: "Accesso negato.",
    errorSubInfoAccessRefused: "Non sei autorizzato ad accedere a questa pagina.",
    serverProblem: "problema del server",
    tryLater: "Si prega di provare tra qualche minuto.",
    unexpectedError: "Siamo spiacenti, errore imprevisto.",
    errorInfoServerProblem: "La pagina che stai cercando è attualmente in manutenzione e tornerà presto.",
    continueCreatingPath: "Spiacenti, non puoi pubblicare questo corso.",
    continueCreatingSession: "Spiacenti, non puoi pubblicare questa sessione.",
    returnToPath: "tornare al corso",
    returnToSession: "torna alla sessione",
    courseDesactivationSuccess: "Corso disattivato con successo",
    pathDesactivationSuccess: "Corso disattivato con successo",
    sessionDesactivationSuccess: "Sessione disabilitata correttamente",
    textDeactivateCourse: "Disabilita il corso",
    textDeactivatePath: "Disabilita il corso",
    textDeactivateSession: "Disattiva sessione",
    textreactiovationCourse: "Corso attivato con successo",
    textreactiovationPath: "Percorso attivato con successo",
    textreactiovationSession: "Sessione attivata con successo",
    text_1_desactivateCourse: "Questo corso non sarà più accessibile.",
    text_2_desactivateCourse: "Hai la possibilità di riattivare il corso se cambi idea",
    text_1_desactivatePath: "Questo corso non sarà più accessibile.",
    text_2_desactivatePath: "Hai la possibilità di riattivare il corso se cambi idea",
    text_1_desactivateSession: "Questa sessione non sarà più accessibile.",
    text_2_desactivateSession: "Hai la possibilità di riattivare la sessione se cambi idea",
    refuseCourseText: "Rifiuta la pubblicazione del corso",
    refusePathText: "Rifiuta la pubblicazione del percorso",
    refuseSessionText: "Nega la pubblicazione della sessione",
    backToListCourses: "torna all'elenco dei corsi",
    textRefusedCourseSuccess: "Corso rifiutato con successo",
    textRefusedPathSuccess: "Percorso negato con successo",
    textRefusedSessionSuccess: "Sessione negata con successo",
    acceptCourseText: "Convalida la pubblicazione del corso",
    acceptPathText: "Convalida la pubblicazione del percorso",
    acceptSessionText: "Convalida la pubblicazione della sessione",
    textAcceptCourse: "Questo corso sarà accessibile a tutti gli studenti.",
    textAcceptPath: "Questo corso sarà accessibile a tutti gli studenti.",
    textAcceptSession: "Questa sessione sarà accessibile a tutti gli studenti.",
    publishCourseSuccessModal: "Corso pubblicato con successo",
    publishPathSuccessModal: "Corso pubblicato con successo",
    publishSessionSuccessModal: "Sessione pubblicata con successo",
    errorContentLength: "Devi avere almeno un contenuto (richiesto).",
    errorPathLinkImg: "Visual per illustrare il corso (obbligatorio).",
    errorSessionLinkImg: "Visuale per illustrare la sessione (obbligatoria).",
    errorPathDescription: "Presentazione del testo del corso (obbligatoria).",
    errorSessionDescription: "Presentazione del testo della sessione (obbligatoria).",
    typeTable: "genere",
    parcoursTitle: "Percorso",
    PATH: "Percorso",
    progress: "avanzamento",
    gotopathStudent: "Accedi al corso",
    gotosessionStudent: "Accedi alla sessione",
    newContent: "nuovo contenuto",
    chapterTitleBegin: "Titolo del capitolo",
    testTitleBegin: "Titolo del test di conoscenza",
    examTitleBegin: "Titolo esame",
    onlineFormations: "Formazione in linea",
    validateFormations: "Formazione da convalidare",
    allFormations: "Tutti i corsi",
    inscribedTimeStudent: "registrato su",
    suspendedAt: "Sospeso",
    newFinalExam: "Aggiungi un esame finale",
    backToMyrequest: "Torniamo alla mia richiesta",
    beforeUploadText: "Trascina e rilascia i file che desideri condividere",
    uploadFile: "SELEZIONA UN FILE",
    Subscription: "Richiesta di abbonamento",
    waiting: "In attesa di",
    fileValidated: "File convalidato",
    fileRefused: "File rifiutato",
    autorisationTraining: "Permessi",
    autorisationTrainingTable: "Autorizzazione",
    autorzedStatus: "Autorizzato",
    unauthorized: "Non autorizzato",
    groupNameTable: "Nome del gruppo",
    changeMyDecision: "Cambia la mia decisione",
    backtodemand: "indietro su richiesta",
    cancelValidation: "Annulla la convalida",
    downloadFileCertif: "Scarica il mio diploma",
    nextChapterText: "Prossimo capitolo",
    getMyDiplome: "Il mio diploma",
    ListAllCourses: "Elenca tutti i corsi",
    ListOnlineCourses: "Elenco dei corsi online",
    ListPendingCourses: "Elenco dei corsi in sospeso",
    ListStudents: "Elenco degli studenti",
    courseBy: "Corso tenuto da",
    SessionGivenBy: "Sessione data da",
    PathGivenBy: "Corso tenuto da",
    PaymentDetails: "dettagli di pagamento",
    Completeyourpurchasebyprovidingyourpaymentinformation: "Completa l'acquisto fornendo i tuoi dati di pagamento.",
    LastNameandFirstName: "Cognome e nome",
    CreditCardNumber: "Numero di carta di credito",
    ExpirationDate: "data di scadenza",
    confirmandpay: "Conferma e paga",
    Yourpaymentwassuccessful: "Il tuo pagamento è stato effettuato con successo.",
    StartThePath: "inizia il viaggio",
    Yourpaymenthasbeendeclined: "Il tuo pagamento è stato rifiutato",
    StartTheCourse: "iniziare il corso",
    StartTheSession: "inizio sessione",
    selectLevelCourse_4: "Tutti i livelli",
    progressTable: "Avanzamento",
    notationLabel: "Notazione",
    singleClosedQuestion: "Domanda chiusa",
    Du: "Da",
    Au: "In",
    Examens: "Esami",
    fromWhereTraining: "Viene da",
    congratsTraining: "Congratulazioni, hai completato il corso.",
    donnerAvis_3: "Dai la tua opinione sul corso:",
    donnerAvis_4: "Esprimi la tua opinione sulla sessione:",
    donnerAvisButton: "Dai la tua opinione",
    InitialPathName: "Titolo del corso",
    PathDecriptionCreation: "Descrivi il tuo background",
    prerequisite1: "Prerequisiti per seguire questo corso",
    skillsNeeded: "Conoscenze e competenze acquisite attraverso questo corso",
    pathCoverPhot: "Trascina e rilascia una foto per illustrare il corso (jpg, png)",
    pathVideoDescription: "Video di presentazione del corso",
    pathVideoDrop: "Trascina e rilascia il video del corso che desideri caricare (mp4, mov)",
    PathLevel: "Livello richiesto per l'iscrizione al corso",
    SessionTitleCreation: "Titolo della sessione",
    SessionLevel: "Livello richiesto per la registrazione alla sessione",
    SessionDescriptionCreation: "Descrivi la tua sessione",
    prerequisite2: "Prerequisiti per seguire questa sessione",
    SessionSkills: "Conoscenze e abilità acquisite durante questa sessione",
    SessionCoverDescription: "Visual per illustrare la sessione",
    SessionCoverDrop: "Trascina e rilascia una foto per illustrare la sessione (jpg, png)",
    SessionDescriptionVideo: "Video di presentazione della sessione",
    DragDropSession: "Trascina e rilascia il video della sessione che desideri caricare (mp4, mov)",
    saveSchoolLogo: "Aggiungi una foto",
    descriptionChoiseCours: "Crea il tuo corso con i suoi capitoli, test ed esame finale. Imposta il tuo file di ammissione",
    descriptionChoisePath: "Assembla i tuoi corsi e corsi e crea un corso di formazione con le condizioni di passaggio",
    descriptionChoiseSession: "Assembla il tuo allenamento con le condizioni di passaggio stabilite nel tempo",
    returnButton: "Flip",
    waitingToPayment: "In attesa del pagamento",
    noUnpaidFound: "Nessuna aspettativa di pagamento",
    schoolAssistant: "Amministratore",
    passwordMandadory1: "La password deve contenere",
    passwordMandadory2: "Almeno 8 caratteri",
    passwordMandadory3: "Almeno una lettera maiuscola e una lettera minuscola",
    passwordMandadory4: "Almeno 1 cifra",
    passwordMandadory5: "Almeno un carattere speciale (#?!=@$%^&*-)",
    passConfirmation: "Conferma password",
    RésultatCumulé: "Risultato cumulativo",
    etudiantsInscrits: "Studenti iscritti",
    TarifActuel: "Prezzo attuale",
    TarifMoyen: "Prezzo medio",
    priceHistory: "Storico delle tariffe",
    hidePricehistory: "Nascondi cronologia delle tariffe",
    saveModification: "Modifica salvata!",
    Total: "Totale",
    TotalInscrits: "Totale registrato",
    periodMedian: "Prezzo medio per il periodo",
    totalMedian: "Prezzo medio totale",
    totalResult: "Scappatoia totale",
    QUESTIONS_OUVERTES: "DOMANDE APERTE",
    notValid: "Non valide",
    validAction: "Azione convalidata",
    studentResponse: "Risposta degli studenti",
    markUpdate: "notazione ma modificatore",
    webinarEnded: "Webinar terminato",
    added: "Aggiunto",
    warning: "Attenzione!",
    changePrice: "Hai appena cambiato il prezzo del corso:",
    priceNow: "Prezzo attuale:",
    priceAfter: "Nuovo prezzo interessato:",
    saveUpdate: "SALVA IL CAMBIAMENTO",
    profchoice: "Seleziona un insegnante a cui assegnare i corsi",
    deleteReason: "Motivo dell'eliminazione*",
    disableAssistant: "Questo Amministratore non potrà più accedere alla piattaforma. Hai la possibilità di reintegrarlo se cambi idea.",
    addCommentToAssistant: "Aggiungi un commento per la procedura guidata",
    connectAccountPaymee: "Collega il tuo conto Paymee",
    connect: "COLLEGARE",
    idPaymee: "ID Paymee",
    assistantInvitation: "Invita un Amministratore a registrarsi",
    mailAssistant: "Email Amministratore",
    membreInvitation: "Invita un membro",
    role: "Ruolo",
    newCourse: "Nuovo corso",
    newPath: "Nuovo corso",
    newSession: "Nuova sessione",
    profMandadotory: "Questo corso deve essere assegnato a un insegnante.",
    profMandadtory2: "Seleziona un insegnante a cui assegnare questo corso*",
    rehireAssistant: "Questo Amministratore potrà accedere alla piattaforma. Hai la possibilità di sospendere l'Amministratore se cambi idea.",
    dragToUpload: "Trascina e rilascia la tua foto",
    or: "O",
    selectPhoto: "seleziona la tua foto",
    offerList: "Elenco delle offerte",
    answer: "La tua risposta",
    completedParts: "parti fatte",
    followPaths: "Segui il corso",
    followSessions: "Segui il corso",
    displayVideo: "Il tuo video di presentazione o visivo",
    toValidate: "Per convalidare",
    archiveSheet: "Archivio",
    connectZoom: "Collega Zoom",
    accountZoom: "conto zoom",
    connectAccount: "Collega il mio account",
    connectAccountToschool: "Collega il tuo account Zoom con la tua scuola",
    changeOffer: "Cambia offerta",
    facture: "Fatture",
    paymentMethod: "Modalità di pagamento",
    cancelSubscription: "Annulla il mio abbonamento",
    offerPrice: "Spese di offerta",
    nextDeadline: "Prossima data di scadenza del pagamento",
    deleteOffer: "Cancellazione dell'offerta",
    deleteMsgOffer: "Vuoi cancellare la tua offerta?",
    passwordUpdated: "La tua password è stata modificata",
    passwordNoMatch: "le due password non corrispondono",
    modifyProfil: "Modifica il mio profilo",
    newEmail: "Nuovo Indirizzo Email",
    connectStripToSchool: "Collega il tuo account Stripe con la tua scuola",
    disconnect: "Disconnetti",
    connectPaymeeToSchool: "Collega il tuo account Paymee con la tua scuola",
    paymentMessage: "Completa l'acquisto fornendo i tuoi dati di pagamento.",
    paymentConfimation: "confermare il pagamento",
    activate: "Abilitare",
    month: "Mensile",
    yearly: "annuali",
    offerMessage1: "Tutto incluso nel piano Basic, più:",
    Choisir: "Scegliere",
    compareoffers: "Confronta tutte le funzionalità",
    offer: "Offerta",
    monthlyFor: "Mensile per",
    yearFor: "Annuali per",
    totalPrice: "Prezzo totale:",
    valid: "Valide",
    ProfList: "Lista prof",
    StudentList: "Elenco studenti",
    CourseList: "Elenco dei corsi",
    Inscrits: "Registrato",
    team: "Equipaggio",
    ADMINSTRATOR: "Amministratore",
    info: "Informazione",
    shortDescription: "breve descrizione",
    shortDescription1: "Breve descrizione di",
    nbrConx: "Numero di connessione",
    inscDate: "Data di iscrizione",
    role1: "ruolo",
    totalRevenu: "Reddito totale",
    makeAPayment: "effettua un pagamento",
    Fileacceptedon: "File accettato il",
    params: "Impostazioni",
    pricipalGroup: "gruppo principale",
    addPhoto: "aggiungi la tua foto",
    changePhoto: "Cambia la tua foto",
    deletePhoto: "Elimina immagine",
    seletionner: "SELEZIONARE",
    facturationDetails: "Informazioni di fatturazione",
    closedSchool: "Questa scuola è attualmente chiusa",
    hold: "Per favore",
    contact1: "Contatta il supporto",
    contact2: "per maggiori informazioni.",
    question: "domanda",
    allTeam: "L'intera squadra",
    assistants: "amministratori",
    noTeam1: "Nessun insegnante/Amministratore trovato.",
    noTeam2: "Si prega di cercare di nuovo.",
    noTeamDefault_1: "Nessun membro del team è registrato.",
    noTeamDefault_2: "Invita un membro a registrarsi per iniziare.",
    noAssistant_1: "Nessun Amministratore trovato.",
    noAssistant_2: "Si prega di cercare di nuovo.",
    noAssistantDefault_1: "Nessun Amministratore è registrato.",
    noAssistantDefault_2: "Invita un Amministratore a registrarsi per iniziare.",
    TrainingToedit: "In corso",
    webinarForm1: "Seleziona un insegnante a cui assegnare questo webinar*",
    webinarForm2: "Seleziona un insegnante",
    webinarForm3: "Per gli studenti del corso:",
    listeDesAssistants: "elenco degli amministratori",
    listeTeam: "elenco delle squadre",
    listOfUnpaidStudents: "elenco studenti non pagati",
    Nounpaidstudent: "Nessuno studente non pagato",
    memberMail: "E-mail del membro",
    integrations: "Integrazioni",
    monForfait: "Il mio pacchetto",
    Facturation: "Fatturazione",
    Detailsduforfait: "Dettagli del pacchetto",
    Gerezoumodifiezvotreforfait: "Gestisci o modifica il tuo piano",
    Votreoffre: "La tua offerta",
    Fraisdeloffre: "Spese di offerta",
    Prochainedatedecheancedupaiement: "Prossima data di scadenza del pagamento",
    Modedepayement: "Metodo di pagamento",
    Changerleforfait: "Cambia piano",
    Annulermonabonnement: "Annulla il mio abbonamento",
    Limitationduforfait: "Limitazione del pacchetto",
    Suivrelalimitationdevotreforfaitactuel: "Tieni traccia della limitazione del tuo piano attuale",
    Voirlesforfaits: "Vedi pacchetti",
    Vousavezatteintlalimitedaccesdevos: "Hai raggiunto il limite di accesso del tuo",
    Vouspouvezajouterdes: "Puoi aggiungere",
    endesactivantun: "disabilitando a",
    endesactivantune: "disabilitando a",
    actifouenameliorantvotreforfait: " aggiornando il tuo piano",
    activeouenameliorantvotreforfait: "aggiornando il tuo piano",
    Modesdepayement: "Modalità di pagamento",
    Gérezoumodifiezvotremodesdepaiement: "Gestisci o modifica i tuoi metodi di pagamento",
    congratsSession: "Congratulazioni, hai completato la sessione.",
    avisTitle1: "La mia opinione sul corso",
    avisTitle2: "La mia opinione sulla sessione",
    FormationAValider: "Nessuna formazione da convalidare.",
    FormationEnLigne: "Nessuna formazione online.",
    emptyListFormation1: "Si prega di cercare di nuovo.",
    noTrainingSearch: "Questo insegnante non ha ancora una formazione.",
    NoWebinarToValidate: "Nessun webinar da convalidare.",
    NoWebinarIsComingSoon: "Nessun webinar in arrivo.",
    NoWebinarIsOnGoing: "Nessun webinar in corso.",
    NoWebinarHasEnded: "Nessun webinar completato.",
    NoWebinarIsRefused: "Nessun webinar è stato rifiutato.",
    NoWebinarIsAssociatedToThisCourse: "Nessun webinar associato a questo corso.",
    NoWebinarExists: "Nessun webinar trovato.",
    FirstWebinar: "Nessun webinar è stato ancora creato.",
    FirstWebinar2: "Crea il tuo primo webinar per iniziare.",
    transformKnowledge: "Trasforma la tua conoscenza in ricchezza",
    addToDescription: "Aggiungi alla descrizione",
    importListOfStudents: "Importa l'elenco degli studenti.*",
    Remplacer: "Sostituire",
    returnToStudentsList: "Torna all'elenco degli studenti",
    invite: "invitare",
    continue: "Continuare",
    savedUsers: "Ho il consenso degli utenti registrati in questo file, secondo la legge sulla protezione dei dati personali del paese in vigore.",
    download: "Scarica",
    loadInformation: "Caricamento delle informazioni in corso, attendere...",
    yourAccount: "Dal tuo account",
    emailSend: "Email inviata",
    check: "dai un'occhiata",
    verifyYourEmail: "Controlla la tua email e apri il link che hai ricevuto per continuare",
    returnToConnexionPage: "torna alla pagina di accesso",
    save: "registrare",
    coursResume: "Scrivi il riassunto di questo corso, presentandone i punti più importanti e mostrandone il valore aggiunto per il socio.",
    coursObjectives: "Elenca gli obiettivi da raggiungere durante questo corso.",
    preRequisite: "Aggiungi i prerequisiti necessari per seguire questo corso.",
    skillsToLearn: "Elenca le competenze da acquisire durante questo corso sotto forma di parole chiave.",
    pathResume: "Scrivi un riassunto di questo corso, presentandone i punti più importanti e mostrandone il valore aggiunto per il socio.",
    pathObjectives: "Elenca gli obiettivi da raggiungere durante questo corso.",
    pathPreRequisite: "Aggiungi i prerequisiti necessari per seguire questo percorso.",
    pathSkillsToLearn: "Elenca le competenze da acquisire durante questo corso sotto forma di parole chiave.",
    sessionResume: "Scrivi il riassunto di questa sessione, presentandone i punti più importanti e mostrandone il valore aggiunto per il membro.",
    sessionObjectives: "Elenca gli obiettivi da raggiungere durante questa sessione.",
    sessionPreRequisite: "Aggiungi i prerequisiti necessari per seguire questa sessione.",
    sessionSkillsToLearn: "Elenca le competenze da acquisire durante questa sessione sotto forma di parole chiave.",
    timeNotAvailable: "Questo programma non è disponibile, scegline un altro.",
    annulerImportation: "Annulla importazione",
    annulerImportationConfirmation: "Sei sicuro di voler annullare l'importazione?.",
    retourImportation: "Torna a importare",
    failedRequest: "Richiesta fallita",
    lowerDeal: "Per passare a un piano inferiore, è necessario modificare il limite in base ai limiti del piano scelto.",
    activeProfessors: "Insegnanti attivi",
    activeStudents: "Studenti attivi",
    activeAssistants: "amministratori attivi",
    professorList: "Elenco degli insegnanti",
    trainingList: "Elenco dei corsi",
    setAsPrincipal: "Impostare come principale?",
    replacePaymentMode: "Questo sostituirà il tuo attuale metodo di pagamento principale.",
    deletePaymentMethod: "Elimina-metodo-di pagamento",
    deletePaymentMethodConfirmation: "Sei sicuro di voler eliminare il metodo di pagamento.",
    deleteInvitation: "Annulla invito",
    invitationAnnulationOfYourSchool: "L'annullamento dell'invito non consentirà più a quella persona di diventare un membro di",
    yourSchool: "la tua scuola.",
    youCanReinvite: "Puoi sempre invitarlo a tornare.",
    keepInvitation: "mantieni l'invito",
    noCourseFound: "Nessun corso trovato",
    wantCreateCourse: "Vuoi creare un corso?",
    cannotImportListOfStudents: "Non puoi importare un elenco studenti che contiene errori.",
    firstName: "Nome di battesimo",
    obligatoire: "è obbligatorio",
    name: "Il nome",
    emailAddressNotValid: "Indirizzo email non valido.",
    emailFormat: "Il formato dell'e-mail deve essere",
    emailExample: "votrenom@esempio.com",
    importationResult: "Importa risultato",
    succesfulImportation: "importazioni riuscite",
    lignes: "linee",
    containErrors: "contengono errori",
    addStudent: "Aggiungi uno studente",
    importList: "Importa un elenco",
    invitationSentSuccesfully: "Invito inviato con successo",
    invitationSuccessAssistant: "Il tuo invito è stato inviato con successo all'Amministratore.",
    userAlreadyExists: "L'utente con questa email esiste già.",
    enterValidEmail: "Si prega di inserire un indirizzo email valido.",
    professorLimitAtteined: "Hai raggiunto il limite di accesso dei tuoi insegnanti. Puoi aggiungere insegnanti disattivando un insegnante attivo o aggiornando il tuo piano.",
    assistantLimitAtteined: "Hai raggiunto il limite di accesso per i tuoi amministratori. Puoi aggiungere amministratori disabilitando un Amministratore attivo o aggiornando il tuo piano.",
    compareOffers: "Confronta le offerte",
    schoolUses: "La tua scuola utilizza le funzionalità",
    notIncludedInOffer: "che non sono supportati dall'offerta",
    passToOffer: "Se passi all'offerta",
    allFonctionalities: ", tutte le funzionalità",
    areDesactivated: "della tua scuola sarà disattivata e inaccessibile.",
    invitationsSentSuccesfully: "gli inviti sono stati inviati con successo",
    automaticSendOfInvitations: "Invio automatico degli inviti",
    invitationSendConfirmation: "Confermi l'invio di inviti all'elenco che hai importato?",
    confirmAndSend: "Conferma e invia",
    principal: "Principale",
    noPaymentMethodAdded: "Nessun metodo di pagamento aggiunto",
    addPaymentMethod: "Aggiungi un metodo di pagamento",
    remainning: "hai ancora",
    changePaymentMethod: "Cambia metodo di pagamento",
    leaveMsg: "Ci dispiace vederti andare!",
    annulationWarning: "Una volta annullato, il tuo account rimarrà attivo fino al termine della fatturazione",
    annulationWarning2: "Dopodiché, perderai l'accesso alla tua scuola.",
    annulationConfirmation: "Sei sicuro di voler cancellare il tuo abbonamento?",
    enterPasswordtoConfirm: "Inserisci la tua password per confermare",
    cancelationReasons: "Facci sapere perché stai cancellando l'abbonamento. I tuoi commenti ci aiuteranno a migliorare il prodotto",
    whyuCanceling: "Perché stai cancellando l'abbonamento",
    selectLowerCase: "Selezionare",
    missingFonctionality: "Manca una caratteristica che voglio",
    expensive: "Penso che sia troppo costoso",
    notInteressted: "Je n'utilise più febbre",
    movingToAnotherPlatform: "Sto passando a un'altra piattaforma",
    preciser: "Per favore specificare",
    wantToTellUsMore: "Vuoi dirci di più?",
    unlimited: "Illimitato",
    Cryptogramme: "Crittogramma",
    savePaymentMethod: "Salva questo metodo di pagamento nel mio profilo",
    paymentPassed: "Pagamento fatto",
    paymentSuccesfull: "Il tuo pagamento è stato elaborato correttamente.",
    returnToActivity: "Torna all'attività",
    twoFreeMonths: "2 mesi gratis",
    fourFreeMonths: "4 mesi gratis",
    essentialTools: "Gli strumenti essenziali per iniziare, senza costi nascosti",
    forever: "per tutto il tempo",
    actuelOffer: "La tua offerta attuale",
    selectFreeOffer: "Scegli l'offerta Gratuita",
    pathAndSessions: "Percorsi e sessioni",
    forProfessionalsAndNonProfessionals: "Per formatori professionisti e infoimprenditori.",
    yearlyBill: "Fatturati ogni anno",
    perMonth: "al mese",
    selectProOffer: "Scegli l'offerta Pro",
    courses: "Classi",
    illimitedAccess: "Per godere di Koors illimitati.",
    perStudent: "per studente",
    minimum: "minimo",
    selectBussinessOffer: "Scegli l'offerta Business",
    illimitedNumberOfStudents: "Numero illimitato di studenti",
    illimitedNumberOfCours: "Numero illimitato di lezioni",
    illimitedNumberOfProfessors: "Numero illimitato di insegnanti",
    illimitedNumberOfAssistants: "Numero illimitato di amministratori",
    subscriptionPayments: "Pagamento dell'abbonamento",
    Devise: "Escogitare",
    FacturationCycle: "Ciclo di fatturazione",
    monthly: "Mensile",
    yearly_2: "Annuale",
    ofReduction: "riduzione",
    minimalBill: "Fatturazione minima",
    nextBill: "Prossima fatturazione",
    confirmPayment: "Conferma di pagamento",
    reductionOnCurrentPlan: "Riduzione sul piano annuale",
    verification: "Verifica",
    noStudentFound_2: "Nessuno studente trovato",
    archivedTrainings: "Nessuna formazione archiviata",
    refusedTrainings: "Nessuna formazione rifiutata",
    desactivatedTrainings: "Nessun allenamento disabilitato",
    editedTrainings: "Nessun allenamento in fase di modifica",
    noTrainingHaveBeenCreated: "Nessun formaggio è stato ancora creato.",
    createFirstTraining: "Per iniziare, crea la tua prima formazione.",
    examsToNote: "Nessun esame da valutare.",
    notedExams: "Nessun esame graduato.",
    noExam: "Non hai esami.",
    resendInvitation: "Invia nuovamente l'invito",
    Copyright: "Diritto d'autore",
    rights: "Tutti i diritti riservati.",
    noExamFound: "Nessuna recensione trovata.",
    atteinedMaximum: "Hai raggiunto il numero massimo consentito per un piano",
    updatePlan: "Aggiorna il tuo piano per creare più formazioni.",
    noBill: "Nessuna fattura.",
    finalExam: "Esame finale .",
    selectAnImage: "SELEZIONA UN'IMMAGINE",
    slideToAddAnImage: "Trascina e rilascia una foto per illustrare il webinar",
    yourWebinarsAreIntegrated: "I tuoi webinar sono integrati",
    onThePlatform: "alla piattaforma con il",
    offreProAndBussiness: "Offerte Professionali e Business",
    createWebinar: "Crea semplicemente i tuoi webinar direttamente in Koors Select e invita direttamente i tuoi studenti a partecipare (i nuovi studenti idonei vengono automaticamente invitati).",
    findReplayAndPublishWebinar: "Trova i replay dei tuoi webinar e pubblicali nella tua formazione.",
    improveMyOffer: 'Migliora la mia offerta',
    soonWebinarsAvailable: "Presto ti verranno offerti webinar.",
    willInformuSoon: "Ti teniamo informato.",
    courseResumeIntroduction: "Riassunto introduttivo del corso...",
    reprendre: "Riprendere",
    freeOffreNoPaymentMethod: "Non hai metodi di pagamento. Sei registrato per l'offerta Gratuita.",
    Credit: "credito",
    DebitCard: "Carta di debito",
    offre: "Offerta",
    nameOnCard: "Nome sulla carta",
    traitement: "trattamento...",
    paiement: "Pagamento",
    offersList: "Elenco delle offerte",
    perYear: "per anno",
    wrongBirthDate: "Data di nascita errata",
    selectCountry: "Scegli un paese",
    cardAddedSuccessfully: "la tua carta è stata aggiunta con successo",
    returnToParameters: "tornare al parametro",
    addNewPaymentMethod: "Aggiungi un nuovo metodo di pagamento",
    verifyPassword: "Password errata, vuoi verificare la tua password",
    returnToOffer: "Torna alla mia offerta",
    subscriptionCancelled: "Il tuo abbonamento è stato cancellato con successo",
    perMonthPayedAnually: "mese, pagato annualmente",
    downloadTemplate: "scarica il modello",
    toSee: "excel per vedere un formato di esempio.",
    slideExcel: "Trascina e rilascia il tuo file Excel",
    reposition: "RIPOSIZIONAMENTO",
    importNewPhoto: "IMPORTA UNA NUOVA FOTO",
    importPhoto: "IMPORTA UNA FOTO",
    slideToSet: "trascinare in posizione",
    publishMyOpinion: "Pubblica la mia recensione",
    noWebinarIsPlanned: "Al momento non sono previsti webinar",
    account: "account",
    titleSessionPlaceholder: "indicare qui il titolo della sessione...",
    titlePathPlaceholder: 'indicare qui il titolo del corso...',
    addVideoUrl: "Aggiungi URL video",
    itegrateVideo: "incorporare video",
    enterValidUrl: "Assicurati di aver inserito un URL valido.",
    pasteVideoUrl: "Incolla collegamento video...",
    addVideoToIntegrate: "Aggiungi l'URL del video da incorporare",
    perYearPayedAnually: "anno, pagato annualmente",
    creationDate: "data di creazione",
    createdAt: "Creato il",
    generic_decline: "Pagamento rifiutato per motivi generici",
    insufficient_funds: "I fondi della carta sono insufficienti",
    lost_card: "La carta viene dichiarata persa",
    stolen_card: "La carta risulta rubata",
    expired_card: "La carta è scaduta",
    incorrect_cvc: "Il codice CVC non è corretto",
    processing_error: "Pagamento rifiutato a causa di un errore di elaborazione",
    gender: "Genere",
    male: "Uomo",
    female: "Donne",
    noCommentFound1: "Nessun commento",
    lastUpdate: "Ultimo messaggio",
    lignes1: "linea",
    containErrors1: "contiene errori",
    emptyTitleError: "Titolo del corso (richiesto)",
    emptyPathTitleError: "Titolo del corso (richiesto)",
    emptySessionTitleError: "Titolo della sessione (richiesto)",
    Webinar1: "Webinar",
    notFound1: "Aggiungi più studenti e aumenta la marcia ",
    notFound2: "Koors riunisce le migliori funzionalità per distribuire i corsi online a quante più persone possibile.",
    notFound3: "Grazie a Koors, puoi gestire facilmente le tue community di studenti/clienti e rimanere sempre in contatto.",
    emailAlreadyUsed: "Questo indirizzo e-mail è già in uso",
    Clôturé: "Chiuso",
    Ouvert: "Aperto",
    TITREdusondage: "Titolo del sondaggio",
    Auteur: "Autore",
    CRÉELE: "Creato il",
    CloturéLE: "Chiuso il",
    RÉPONSEs: "Risposte",
    Créerunsondage: "Crea un sondaggio",
    sondages: "Sondaggi",
    sondage: "Sondaggi",
    inscribedInSchool:"Iscrizione alla scuola",
    catalogVisits:"Visite alla pagina del catalogo",
    currentStudentNumber:"Numero di studenti attuali",
    currentStudentsWithDiploma:"Numero di studenti laureati",
    noSchooling:"Non menzionato",
    transcodeInProgress: "È in corso la finalizzazione dell'importazione. Puoi continuare a completare il contenuto.",

}