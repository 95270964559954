import { ComponentProps, FC, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { openModal, closeModal } from '../../../global-state/actions/modal-actions';
import { publishCourse, updateCoursesLists } from '../../../global-state/actions/course-actions';
import { ModalIDS } from '../modal-ids';
import TextareaUnderlineElement from '../../../elements/text-area/text-area-underline-element';
import { createTraining, publishTraining } from '../../../global-state/actions/training-actions';
import { getDomainFromUrl } from '../../../helpers/domainCheck';

const DeactivateTrainingModalComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const [courseInfo, setCourseInfo] = useState<any>();
    const [comment, setComment] = useState<string>("");
    const [type, setType] = useState<any>();
    const [training, setTraining] = useState<any>("");
    const [trainingSession, setTrainingSession] = useState<any>("");

    useEffect(() => {
        setType(props?.content?.data?.type);
    }, [props?.content])
    useEffect(() => {
        setCourseInfo(props?.courseInfo)
    }, [props?.courseInfo]);
        useEffect(() => {
        setTrainingSession(props?.trainingSession)
    }, [props?.trainingSession])
    useEffect(() => {
        setTraining(props?.training);
    }, [props?.training])
    const validateCours = async (value: string) => {
        let code = getDomainFromUrl()
        let obj={
          comment:(comment !== "") ? comment : t('nothingTOSay'),
          idCours:(type==="course")?courseInfo?.id:(type==="path")?training?.id:trainingSession?.id,
          status:"",
          title:(type==="course")?courseInfo?.title:(type==="path")?training?.title:trainingSession?.title,
          code:code,
          userRole:props?.connectionUser?.role,
          idSchool:props?.connectionUser?.idSchool,
          trainingType:(type==="course")?courseInfo?.type:(type==="path")?training?.type:trainingSession?.type,
       }
       switch(type) {
        case'course':
        await props?.publishCourse(value,courseInfo?.idProf, props?.connectionUser?.idPerson,obj); 
        await props?.openModal({idModal: ModalIDS.SUCCESS_MODAL_WITH_BUTTON, size: "md", buttonText: t('returnToCourse'), lines: [t('courseDesactivationSuccess')]});
        break;    
        case'path':
        await props?.createTraining("paths", { ...training, status: "DEACTIVATE" });
        await props?.publishTraining(value, props?.connectionUser?.idPerson, training?.idProf, obj)
        await props?.openModal({idModal: ModalIDS.SUCCESS_MODAL_WITH_BUTTON, size: "md", buttonText: t('returnToPathButton'), lines: [t('pathDesactivationSuccess')]});
        break;
        case'session':
        await props?.createTraining("sessions", {...trainingSession,status:"DEACTIVATE"});
        await props?.publishTraining(value, props?.connectionUser?.idPerson, trainingSession?.idProf, obj)
        await props?.openModal({idModal: ModalIDS.SUCCESS_MODAL_WITH_BUTTON, size: "md", buttonText: t('returnToSessionButton'), lines: [t('sessionDesactivationSuccess')]});
        break;
    }
        
      }
    return (
        <>
            <div className="modal-header p-4 pb-0" style={{ border: "none" }}>
                <div className="ms-auto">
                    <button className="btn-QuickActions" onClick={() => props?.closeModal()}>
                        <span className="material-icons-outlined" >close</span>
                    </button>
                </div>
            </div>
            <div className="modal-body" style={{paddingTop:'0', overflow:'hidden'}}>
                <div className="row body-modal-publish">
                    <div className="col-md-9 p-0" style={{ margin: 'auto' }}>
                        <div className='row col-md-12'>
                            <div className="d-flex flex-column justify-content-start">
                                <span className="H3-Headline black-800"> {t((type === 'course')?'textDeactivateCourse': (type === 'path')?'textDeactivatePath':'textDeactivateSession')}</span>
                                <span className="H4-Subtitle neutral-3"> {(type === 'course')?courseInfo?.title: (type === 'path')? training?.title: trainingSession?.title} </span>
                                <span className="mt-3 body-sm black-800"> {t((type === 'course')?'text_1_desactivateCourse': (type === 'path')?'text_1_desactivatePath':'text_1_desactivateSession')} </span>
                                <span className="body-sm black-800" > {t((type === 'course')?'text_2_desactivateCourse': (type === 'path')?'text_2_desactivatePath':'text_2_desactivateSession')} </span>
                            </div>
                            {/* <div className="col-md-12 text-center mt-3">
                                <h1 className="suspend-prof-title"> {t('createBy')} {(courseInfo?.professor?.gender === "MALE") ? t('maleGender') : t('femaleGender')}  <span> {courseInfo?.professor?.firstName + " " + courseInfo?.professor?.lastName}  </span></h1>
                            </div>
                            <div className="col-md-12 mt-3 text-center mt-3">
                                <p className="text-prof-suspend">{t('deactivateCoursText')}</p>
                            </div> */}


                            <div className="col-md-12 mt-4">
                                <p className="form-title mb-3">{t('modalPublishCourse_2_2')}</p>
                                <div className="input-text-form-control">
                                    <TextareaUnderlineElement id={"comment_desactivate"} type="text" value={comment} onChange={(e: string) => { setComment(e) }} placeholder={t('commentSuspendInputProf')} />

                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            <div className="modal-footer" style={{ border: "none" }}>
                <div className="row mt-3 mb-5 w-100">
                    <div className='col-md-9 m-auto p-0 text-center justify-content-end' style={{ display: 'flex', alignItems: 'center' }}>
                        <button type="button" className="btn-Secondary large me-3" onClick={() => { props?.closeModal() }} >{t('annuler')}</button>
                        <button type="button" className="btn-Primary large danger" onClick={() => { validateCours("DEACTIVATE"); }} disabled={(comment === '')} >{t('deactivateCours')}</button>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
const mapStateToProps = (state: AppState) => ({
    content: state.modalReducer.modalContent,
    connectionUser: state.authReducer.connectedUser,
    courseInfo: state.courseReducer.courseInfo,
    training: state.trainingReducer.path,
    trainingSession: state.trainingReducer.session

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            openModal,
            closeModal,
            publishCourse,
            updateCoursesLists,
            createTraining,
            publishTraining

        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(DeactivateTrainingModalComponent);