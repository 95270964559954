import { ComponentProps, FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {  useHistory, useLocation } from 'react-router-dom';
import { AppState } from '../../global-state/store/root-reducers';
import { goToPath, clearHistory, goToProfesseurs, clearHistoryProfesseurs, goToRedirection, clearHistoryArray } from '../../global-state/actions/breadcrumb-actions';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { ModalIDS } from '../modals/modal-ids';
import { openModal } from '../../global-state/actions/modal-actions';

const BreadCrumbGlobalComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const history = useHistory();
    let location = useLocation();
    const [historyPath, setHistoryPath] = useState(props?.historyPath)

    useEffect(() => {
        setHistoryPath(props?.historyPath);
    }, [props?.historyPath]);

    const changeHistory = (index: number) => {
        

        if (location.pathname.includes("professorsInfo")) {
            props?.clearHistoryProfesseurs()
            history.goBack();
        } else {
            if (!historyPath[index].path && historyPath.length === 2) {
                props?.clearHistory();
                history.push(props?.lastPath)
            } else if (!historyPath[index].path && historyPath.length > 2 && index !== 0) {
                props?.goToPath({ index: index - 1 });
            } else if (!historyPath[index].path && historyPath.length > 2 && index === 0) {
                history.push(props?.lastPath)
            }
            else {
                props?.goToPath({ index: index });
            }
        }


    }

    const goback = async () => {

        if (location.pathname.includes("finance/detail-course") && !!props.changed && props.changed) {

            await props?.openModal({ idModal: ModalIDS.ATTENTION, size: "md" })
        }
        else if (location.pathname.includes("professorsInfo")) {
            props?.clearHistoryProfesseurs()
            history.goBack();
        }  else {
            if(props?.history.length === 1 ){
                props?.clearHistoryArray()
                history.goBack()
            }else{
                const newArrayHistory = props?.history.slice(0,props?.history.length-1);
                await props?.goToRedirection(newArrayHistory)
                if(props?.envUrl!=="" && props?.history[newArrayHistory.length-1].path.includes(props.envUrl)) {
                    history.push(props?.history[newArrayHistory.length-1].path)
                }else if (props?.envUrl!=="" && !props?.history[newArrayHistory.length-1].path.includes(props.envUrl)){
                    history.push(props?.envUrl+props?.history[newArrayHistory.length-1].path)
                }
                else{
                    history.push(props?.history[newArrayHistory.length-1]?.path)
                }
            }
                
        }

    }

    const navigateTo = async (index: number) =>{
        const newArrayHistory = props?.history.slice(0,index+1);
        await props?.goToRedirection(newArrayHistory)
        
        if(props?.envUrl!=="" && props?.history[index].path.includes(props?.envUrl)) {
            history.push(props?.history[index].path);
        }else if(props?.envUrl!=="" && !props?.history[index].path.includes(props?.envUrl)){
            history.push(props?.envUrl+props?.history[index].path);
        }
        else{
            history.push(props?.history[index].path);
        }
        
    }

    return (
        <>
            <div id="breadcramp" className="cours-nav w-100" style={{ zIndex: 1000, position: 'fixed', top: 0 }}>
                <div className="navbar navbar-expand-lg box-shadow-navbar-bredcrumb d-flex justify-content-between p-0 flex-nowrap">
                    <div className="d-flex order-0">
                        <a style={{ cursor: 'pointer' }} onClick={() =>  goback() } className="d-flex justify-content-center align-items-center nav-back">
                            <span className="material-icons-round">
                                chevron_left
                            </span>
                        </a>
                        <div className="d-flex px-4 align-items-center">
                            <div className="d-flex flex-row align-items-center">
                                {/* {historyPath?.map((obj: any, i: number) => {
                                    if (historyPath?.length === 1) {
                                        return <span key={obj.path + i + "c"} className='body-md bold breadcrumb-links active'><Link className="body-md bold breadcrumb-links active" key={obj.path + i} onClick={() => { changeHistory(i) }} to={obj.path}>{obj.title}</Link></span>
                                    }
                                    if (historyPath?.length > 1 && i < historyPath?.length - 1) {
                                        return <div className="d-flex flex-row align-items-center" key={obj.path + i + "e"}><Link className='body-md bold breadcrumb-links' key={obj.path + i} onClick={() => { changeHistory(i) }} to={obj.path}>{obj.title}</Link>
                                            <hr className="breadcrumb-links hr-separator" />
                                        </div>
                                    }
                                    else {
                                        return <span key={obj.path + i + "d"} style={{WebkitLineClamp: "2", overflow : "hidden", textOverflow: "ellipsis", display: "-webkit-box",WebkitBoxOrient: "vertical"}} className='body-md bold breadcrumb-links active'><Link className="body-md bold breadcrumb-links active" key={obj.path + i} onClick={() => { changeHistory(i) }} to={obj.path}>{obj.title}</Link></span>
                                    }
                                })}   */}

                                {
                                    props?.history?.map((obj: any, index: number)=>{
                                        return <div className="d-flex flex-row align-items-center" key={obj.path + index + "e"}><span className='body-md bold breadcrumb-links' key={obj.path + index} onClick={() => { navigateTo(index) }}>{obj.title}</span>
                                            {index !== props?.history.length-1 && <hr className="breadcrumb-links hr-separator" />}
                                        </div>
                                        
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state: AppState) => ({
    connectedUser: state.authReducer.connectedUser,
    loggedIn: state.authReducer.loggedIn,
    historyPath: state.historyReducer.listHistoryBreadCrumb,
    historyPathProfesseurs: state.historyReducer.listHistoryProfesseur,
    history: state.historyReducer.history,
    lastPath: state.historyReducer.lastPathBeforeBreadCrumb,
    changed: state.schoolInfoReducer.changeTo,
    envUrl: state.historyReducer.envUrl
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            openModal,
            goToPath,
            clearHistory,
            goToProfesseurs,
            clearHistoryProfesseurs,
            goToRedirection,
            clearHistoryArray
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(BreadCrumbGlobalComponent);