export const TRANSLATIONS_AR = {
    addCourse: "دورة جديدة",
    createCourse: "أنشئ دورة",
    myCourses: "دوراتي",
    Published: "على الخط",
    Draft: "غير منظم",
    Pending: "في انتظار موافقة المخرج",
    RequestDeactivation: "في انتظار التعطيل من قبل المخرج",
    RequestActivation: "في انتظار التفعيل من قبل المخرج",
    RequestDeletion: "في انتظار أرشفة المخرج",
    certified: "الخريجين",
    candidats: "مرشحين",
    candidatsCapitalise: "مرشحين",
    courseStatus_1: "ستبقى الدورة",
    courseStatus_2: "خاص حتى نشره",
    Pending_2: "في انتظار التأكيد",
    RequestDeletion_2: "في انتظار الأرشفة",
    RequestDeactivation_2: "في انتظار التعطيل",
    RequestActivation_2: "في انتظار تفعيل",
    actvity: "نشاط",
    students: "الطلاب",
    students_2: "طالب علم",
    description: "وصف",
    archive: "أرشيف",
    publish: "ينشر",
    inscription: "النقش",
    inscriptionTitle: "نقش",
    certifiedTitle: "تخرج في",
    lastVisitProfile: "آخر زيارة قبل",
    lastVisitProfile_1: "الزيارة الأخيرة",
    lastVisitProfile_2: "Il y a",
    descProf: "وصف موجز للمعلم الذي سيظهر في عرض الدورة",
    content: "محتويات",
    titleCoursePlaceholder: "أدخل عنوان الدورة هنا ...",
    titleCourseForm: "عنوان الدورة",
    modalPublishCourse_1: "أرسل للمدير للموافقة",
    modalPublishCourse_1_1: "تحقق من صحة المنشور",
    modalRefusedCourse_1: "نشر رفض",
    modalPublishCourse_2: "أضف تعليقا للمخرج",
    modalPublishCourse_2_2: "أضف تعليقا للمعلم",
    modalPublishCourse_3: "المعلق ...",
    modalPublishCourse_4: "أرسل الدورة",
    modalPublishCourse_4_4: "دورة النشر",
    modalRefusedCourse_4: "ارفض الدورة",
    modalPublishCourseSuccess: "تم إرسال الدورة إلى المدير للتحقق من صحتها.",
    modalPublishPathSuccess: "تم إرسال الدورة إلى المدير للتحقق من صحتها.",
    modalPublishSessionSuccess: "تم إرسال الجلسة إلى المدير للتحقق من صحتها.",
    modalPublishCourseSuccess_1: "تم نشر الدورة. إنه متاح الآن على الإنترنت.",
    modalDeactivateCours: "الدورة معطلة. لم يعد متاحًا على الإنترنت.",
    modalRefusedCourseSuccess: "لقد رفضت للتو نشر الدورة. تم إرسال رفضك إلى المعلم.",
    titleFormCourse_1: "عرض فيديو للدورة",
    titleFormCourse_2: "مرئي لتوضيح الدورة",
    titleFormCourse_3: "عرض نصي للدورة",
    resume: "ملخص تمهيدي",
    textAreaPlaceholder: "أدخل النص هنا...",
    placeholderInscriptionInput: "أدخل النص هنا",
    prerequisite: "المتطلبات الأساسية لأخذ هذه الدورة",
    objectives: "الأهداف التعليمية",
    skills: "المعرفة والمهارات المكتسبة من خلال هذه الدورة",
    skillsPlaceholder: "أدخل كلمة رئيسية...",
    uploadVideo_1: "قم بسحب وإسقاط فيديو الدورة التدريبية الذي تريد تحميله (mp4 ، mov)",
    uploadVideo_2: "حدد ملف فيديو",
    uploadImage_1: "قم بسحب وإسقاط صورة لتوضيح الدورة التدريبية (jpg، png)",
    uploadImage_2: "اختر صورة",
    downloadVideo: "تحميل",
    awaitVideo: "في قائمة الانتظار",
    whiletranscode: "يشاهد حاليا",
    transcodingVideo: "معالجة الفيديو",
    breadCrumbChapter: "الفصل",
    chapitre: "الفصل",
    newTest: "اختبار جديد",
    newChapter: "جزء جديد",
    selectLevelCourse: "مستوى",
    selectLevelCourse_1: "مبتدئ",
    selectLevelCourse_2: "متوسط",
    selectLevelCourse_3: "مؤكد",
    listChaptersContent: "للبدء ، حدد مستوى الدورة وأنشئ فصلك الأول.",
    chapterSelectFomCHapter: "الفصل",
    saveChapter: "للتسجيل",
    durationChapter: "مدة",
    placeholderTitleChapter: "أدخل عنوان الفصل هنا ...",
    titleFormChapter_1: "فصل فيديو",
    titleFormChapter_2: "ملفات للتنزيل",
    titleFormChapter_3: "نص الفصل",
    textFileUpload_1: "قم بسحب وإسقاط ملفات تحميل الفصل",
    textFileUpload_2: "(صوت ، PDF ، PowerPoint ، Word ، Excel ...)",
    fileUploadButton: "حدد الملف",
    uploadVideoChpter_1: "قم بسحب وإسقاط مقطع الفيديو الخاص بالفصل الذي تريد تحميله (mp4، mov)",
    tests: "الاختبارات",
    test: "اختبار",
    resultat: "نتائج",
    reponseInscrit: "طلب تسجيل",
    reponseAv: "قم بالرد من قبل",
    refusedAt: "رفض يوم",
    Refused: "رفض",
    Unpaid: "للدفع",
    seeDemand: "انظر طلبي",
    certifButton: "شهادة",
    courseTable: "تشكيل - تكوين",
    DATEACHAT: "تاريخ الشراء",
    exportListe: "قائمة التصدير",
    filterProfessors: "معلم",
    statusFilter: "حالة",
    search: "بحث",
    Tous: "الجميع",
    Brouillon: "غير منظم",
    Avalider: "للتحقق من صحة",
    Enligne: "على الخط",
    Archive: "أرشيف",
    Refuse: "رفض",
    Cours: "مسار",
    COURSE: "مسار",
    Professeurs: "معلمون",
    Demandes: "الطلبات",
    filterCourse: "كل الدورات",
    Certifies: "معتمد",
    Statut: "حالة",
    PresProf: "مداخلة الأستاذ",
    dernVisite: "الزيارة الأخيرة",
    Enattente: "في الانتظار",
    Admis: "وافقت",
    Suspendu: "موقوف عن العمل",
    inviteProf: "قم بدعوة معلم",
    inviteProf_2: "ادعُ مدرسًا للتسجيل",
    send: "إرسال",
    inviteSuccess: "تم إرسال دعوتك إلى المعلم.",
    myAccount: "حسابي",
    saveImg: "احفظ الصورة",
    Profile: "الملف الشخصي",
    Nom: "اللقب",
    NomObligatoire: "اللقب*",
    PrenomObligatoire: "الاسم الاول*",
    Prenom: "الاسم الاول",
    country: "بلد الإقامة",
    Tunisie: "تونس",
    France: "فرنسا",
    city: "مسقط",
    birthdate: "تاريخ الميلاد",
    birthdate_input: "تاريخ الميلاد (الشهر / اليوم / السنة)",
    NivScolaire: "مستوى مدرسي",
    Primaire: "الأولية",
    College: "المدرسة المتوسطة",
    Lycee: "المدرسة الثانوية",
    Bac: "البكالوريا",
    Technicien2: "فنّي (بكالوريا +2)",
    License: "الرخصة (Bac + 3)",
    Master: "ماجستير أو ما يعادلها (BAC +4 أو + 5)",
    Ingenieur: "مهندس",
    Doctorat: "دكتوراه",
    emailAdd: "البريد الإلكتروني",
    updatePass: "تغيير كلمة المرور الخاصة بي",
    password: "كلمة السر",
    actualPass: "كلمة السر الحالية",
    newPass: "كلمة مرور جديدة",
    enterPassword: "8 أحرف على الأقل",
    msgErrorPassword: "الرجاء إدخال 8 أرقام / أحرف على الأقل",
    msgErrorConfirmPassword: "الرجاء إدخال نفس كلمة المرور",
    confimrNewPass: "تأكيد كلمة المرور",
    msgErrorMail: "يرجى إدخال عنوان بريد إلكتروني صالح",
    confimrNewEmail: "عنوان البريد الإلكتروني الجديد",
    validPassButton: "تحقق من صحة كلمة المرور الجديدة الخاصة بي",
    MainSchoolColor: "لون المدرسة الرئيسية",
    AddYourColorToPersonalizeThePresentationOfTheCourses: "أضف لونك لتخصيص عرض الدورات",
    imgCrop_1: "سحب وإسقاط الصورة",
    imgCrop_3: "اسحب وأسقط شعارك",
    imgCrop_2: "اختر صورة",
    pathBy: "الدورة التي قدمها",
    sessionBy: "الجلسة التي قدمها",
    catalogCourses: "كتالوج الدورة",
    Etudiants: "الطلاب",
    Ecole: "المدرسة",
    Catalogue: "فهرس",
    Activite: "نشاط",
    lecole: "المدرسة",
    howItWorks: "كيف تعمل",
    hello: "مرحبا !",
    cnxButton: "تسجيل الدخول",
    createAccountMsg: "إنشيء حسابي",
    questionCreateAccount: "لم تقم بإنشاء حساب بعد؟",
    forgotLogin: "نسيت رقمك السري ؟",
    emailLogin: "البريد الإلكتروني",
    validInscription: "التحقق من تسجيلي",
    activateAccount: "تفعيل حسابي",
    madame: "النساء",
    monsieur: "رجل",
    other: "آخر",
    registerDone_1: "تم أخذ طلب التسجيل الخاص بك في الاعتبار.",
    registerDone_2: "تم إرسال بريد إلكتروني لك.",
    registerDone_3: "انقر فوق الارتباط الموجود داخل هذا البريد الإلكتروني لتأكيد إنشاء حسابك.",
    registerDone_4: "اراك قريبا !",
    validRegister: "للتحقق من صحة",
    activateMyAccount: "تفعيل حسابي",
    directeur: "مخرج",
    professeur: "معلم",
    invalidToken: "تم استخدام هذا الرابط من قبل وانتهت صلاحيته ، يرجى الاتصال بالدعم إذا لزم الأمر.",
    questionAccount: "هل لديك حساب ؟",
    verifMail_1: "تم التحقق من بريدك الإلكتروني ، يمكنك الآن تسجيل الدخول.",
    verifMail_3: "التحقق من صحة البريد الإلكتروني الخاص بك",
    changePass: "غير كلمة السر",
    msgUpdatedPass: "تم تغيير كلمة المرور.",
    recPass: "إستعادة كلمة المرور",
    reinMail: "أدخل عنوان البريد الالكتروني أدناه. سوف تتلقى وصلة لإعادة تعيين كلمة المرور الخاصة بك.",
    reinBUttonPass: "إعادة تعيين كلمة المرور الخاصة بي",
    sendMailRein: "تم إرسال بريد إلكتروني يحتوي على رابط إعادة التعيين الخاص بك إليك.",
    alreadyHaveAcc: "هل لديك حساب ؟",
    followCourse: "خذ هذه الدورة",
    followPath: "اتبع هذا الطريق",
    followSession: "متابعة الجلسة",
    objPEda: "الأهداف التعليمية",
    preresuiPeda: "المتطلبات الأساسية لهذه الدورة",
    preresuiPedaPath: "المتطلبات الأساسية لهذه الدورة",
    preresuiPedaSession: "المتطلبات الأساسية لهذه الجلسة",
    skillsPreveiw: "المعرفة والمهارات المكتسبة من خلال هذه الدورة",
    skillsPreveiwPath: "المعرفة والمهارات المكتسبة من خلال هذه الدورة",
    skillsPreveiwSession: "المعرفة والمهارات المكتسبة خلال هذه الدورة",
    courseProgramm: "برنامج الدورة",
    Chapitres: "فصول",
    videoCourse: "دروس بالفيديو",
    textCourse: "نص الدورة",
    downloadFiles: "ملفات للتنزيل",
    testConn: "اختبارات المعرفة",
    aboutSchool: "عن المدرسة",
    aboutProfessor: "عن المعلم",
    registerCourse: "التسجيل في الدورة",
    registerPath: "التسجيل في الدورة",
    registerSession: "سجل للدورة",
    testConn_2: "اختبر معلوماتك",
    progressView: "تتبع التقدم المحرز الخاص بك",
    previewText_1: "املأ طلب التسجيل الخاص بك حتى يتمكن المعلم من التحقق من صحته.",
    previewText_2: "تقدم في برنامجك وتعلم مهارات جديدة.",
    previewText_3: "تحقق من صحة معرفتك بالاختبارات واحصل على شهادتك.",
    Introduction: "مقدمة",
    Documents: "وثائق",
    downloadFIleDetails: "تحميل",
    Etudiant_1: "طالب علم",
    Professeur_1: "معلم",
    Directeur_1: "مخرج",
    annuler: "لالغاء",
    modifier: "المعدل",
    logout: "تسجيل خروج",
    connexionButton: "الارتباط",
    languageFR: "فرنسي",
    languageEN: "إنجليزي",
    language: "لغة",
    Pending_3: "للتحقق من صحة",
    CERTIFICAT: "شهادة",
    refuseCourse: "رفض النشر",
    joursVisit: "أيام",
    consulter: "للتشاور",
    jourVisit: "يوم",
    Candidat: "مُرَشَّح",
    enCours: "الطلاب في التقدم",
    certifiés: "الخريجين",
    Dossier: "قضية",
    Demande: "طلب",
    DemandeEnvoyéeIlYA: "تم إرسال الطلب قبل",
    Resultats: "نتائج",
    Certificat: "شهادة",
    Tempsdobtention: "الحصول",
    Tempsdobtention_2: "احصل على الوقت",
    refuseCourseDirector: "نشر رفض",
    refuseCourseDirector_2: "أضف تعليقا للمعلم",
    refuseCourseDirector_3: "ارفض الدورة",
    modalRefuseCourseSuccess_1: "لقد رفضت للتو نشر الدورة.",
    modalRefuseCourseSuccess_2: "تم إرسال رفضك إلى المعلم.",
    archiveCourseDirector: "هل أنت متأكد أنك تريد أرشفة هذه الدورة؟",
    archiveCourseDirectorSuccess: "تم أرشفة الدورة.",
    archiveCourseDirector_2: "أرشفة الدورة",
    nothingTOSay: "لا شيء يقال",
    online: "على الخط",
    offline: "خارج الخط",
    validationNewPassword: "يمكنك تسجيل الدخول باستخدام كلمة المرور الجديدة الخاصة بك.",
    showMore: "اعرض ما يلي",
    folderRegistrationTrueOrFalse: "تخضع للتحقق من صحة الملف.",
    nextCommentsFeedback: "عرض التالي",
    emailUpdateButtonShH: "تغيير عنوان بريدي الإلكتروني",
    conditionDeValidation: "شروط التحقق من صحة طلب تسجيل الطالب من قبل الأستاذ.",
    Acceptation: "قبول التسجيل التلقائي للطالب.",
    AcceptationSousDossier: "قبول تسجيل الطالب خاضع للتحقق من صحة الملف.",
    questions: "أسئلة لطرحها على الطالب",
    documents: "المستندات المطلوب طلبها من الطالب",
    titreQuestion: "أدخل عنوان السؤال هنا",
    titreDocument: "أشر إلى عنوان المستند هنا",
    AjouterQuestion: "أضف سؤالاً",
    AjouterDocument: "أضف الوثيقة",
    demandeDinscription: "طلب تسجيل",
    envoyerDemande: "أرسل طلبي",
    titreRegistration_1: "أسئلة يطرحها المعلم",
    titreRegistration_2: "المستندات المطلوبة من قبل المعلم",
    Inscribed: "الجاري",
    CertifiedStudent: "معتمد",
    DraftStudent: "ملف التسجيل قيد التقدم",
    Candidate: "في انتظار التحقق من صحة الملف",
    finishRegistration: "إنهاء طلبي",
    noCoursesStudent: "أنت غير مسجل في أي دورة.",
    discoverCatalog: "اكتشف الكتالوج",
    noCoursesCreated: "لم يتم إنشاء أي تدريب حتى الآن.",
    noCoursesCreated_2: "للبدء ، قم بإنشاء الدورة التدريبية الأولى الخاصة بك.",
    noCourses_1: "لم يتم أخذ أي دورات",
    noCourses_2: "لا يوجد طلب تسجيل",
    noCourses_3: "لم تكتمل الدورات",
    noCoursesPublished: "لم يتم نشر أي دورات بعد.",
    deleteCompte: "احذف حسابي",
    searchProfessors: "للبحث ...",
    descriptionProfLabel: "وصف موجز للمعلم الذي سيظهر في عرض مقرراته.",
    relaunchProfInvitation: "اعادة احياء",
    noProfessorsList_1: "لم يتم العثور على مدرس.",
    noProfessorsList_2: "الرجاء البحث مرة أخرى.",
    noProfessorsListDefault_1: "لم يتم تسجيل أي مدرس.",
    noProfessorsListDefault_2: "ادعُ مدرسًا للتسجيل للبدء.",
    signatureProf: "توقيع الأستاذ",
    signatureDirector: "توقيع المخرج",
    Deactivate: "عاجز",
    breadCrumbTest: "اختبار",
    placeholderTitleExam: "اذكر هنا عنوان الاختبار ...",
    exempleTitleExam: "مثال اختبار معرفة المعرفة الأولى",
    noQuestionTest: "للبدء ، قم بإنشاء سؤالك الأول.",
    newQuestion: "مشكلة جديدة",
    addQuestionText: "أضف سؤالاً",
    placeholderQuestionText: "أشر إلى السؤال",
    answerQuestion: "أشر إلى الإجابة",
    placeholderChoiceText: "حدد نص الاختيارات المتعددة",
    delete: "لحذف",
    suppcomte: "حذف الحساب",
    selectMotif: "حدد نمطًا",
    coursNeMeconvier: "الطبقات لا تناسبني",
    comm: "إضافة تعليق",
    DeleteSuccess: "تم حذف حسابك بنجاح. إلى اللقاء.",
    CreationAccountProfDirector: "لقد تم إنشاء حسابك. يمكنك تسجيل الدخول.",
    messageEnvoyerDemande_1: "تم إرسال طلب التسجيل الخاص بك.",
    messageEnvoyerDemande_2: "سوف تتلقى إجابة عن طريق البريد الإلكتروني",
    messageEnvoyerDemande_3: "في الأيام الثلاثة القادمة.",
    candidat_status: "في انتظار التأكيد",
    refuser: "رفض",
    refuser_1: "رفض",
    valider: "للتحقق من صحة",
    valider_1: "وافقت",
    refuserDemandeRegistration: "رفض تسجيل الطالب",
    ajouterCommentaireEtudiant: "أضف تعليقا للطالب.",
    refuserLaDemande: "ارفض الطلب",
    messageRefuseRegistrationCours: "تم رفض تسجيل الطالب في الدورة بنجاح.",
    messageRefuseRegistrationPath: "تم رفض تسجيل الطالب للدورة بنجاح.",
    messageRefuseRegistrationSession: "تم رفض تسجيل الطالب للجلسة بنجاح.",
    messageValiderRegistration: "تمت دعوة الطالب لبدء أخذ الدورة.",
    messageValiderRegistrationParcours: "تمت دعوة الطالب لبدء متابعة الدورة",
    messageValiderRegistrationSession: "تمت دعوة الطالب لبدء متابعة الجلسة",
    SuspendProf: "للتعليق",
    textProfSuspend: "لن يتمكن هذا المعلم من الوصول إلى الدروس. لديك خيار إعادته إذا غيرت رأيك.",
    otherChoice: "آخر",
    commentProfSuspend: "أضف تعليقا للمعلم",
    questionLabel: "سؤال",
    choiceLabelBON: "إجابة جيدة",
    addAnswerButton: "أضف إجابة",
    noQuestionTestPreview: "لا توجد أسئلة",
    passedTestAverage: "معدل النجاح",
    param: "ضبط",
    presen: "عرض",
    commentSuspendInputProf: "تعليق...",
    motifSuspendProf: "حدد نمطًا",
    affectOtherPRof: "حدد مدرسًا لتعيين الدروس إليه",
    ReintegrateProf: "تكامل",
    ReintegrateProfText: "سيتمكن هذا المعلم من الوصول إلى الدروس. لديك خيار تعليق المدرس إذا غيرت رأيك.",
    AcceptProf: "قبول",
    aArchiver: "ليتم أرشفتها",
    aDesactiver: "لتعطيل",
    aActiver: "للتفعيل",
    desactiverCourse: "تأكيد التعطيل",
    refuseDesactivationCourse: "رفض التعطيل",
    activerCours: "التحقق من صحة التفعيل",
    refuserLactivationCours: "رفض التفعيل",
    archiverCours: "أرشفة التحقق",
    refuserArchivationCours: "ارفض الأرشفة",
    settingEcole: "إعدادات المدرسة",
    nomEcole: "اسم المدرسة",
    logoEcole: "شعار المدرسة",
    persenCourte: "عرض قصير (سيتم عرض هذا النص على صفحات الدورة)",
    listCandidates: "طلبات التسجيل",
    listFollowing: "يتبع الدورات التدريبية",
    listeCertificated: "تدريبات مكتملة",
    sendDate: "ارسال الطلب",
    importVideoUpload: "تنزيل الفيديو",
    waitingUpload: "من فضلك انتظر...",
    uploadFinishing: "الفيديو سيكون متاحا قريبا",
    uploadPending: "يتم معالجتها...",
    candidatures: "التطبيقات",
    certifcations: "الشهادات",
    dashboardAllStudents: "طالب علم",
    certifiedDashStudents: "الشهادة",
    YesWord: "نعم",
    NoWord: "عدم",
    obtentionPeriod: "الحصول",
    In: "في",
    TimePassed: "منذ ",
    noStudentFound: "لا يوجد طلاب حاليون",
    noCandidateFound: "لا توجد طلبات معلقة",
    noSuspendedFound: "لم يتم تعليق أي طالب",
    noCertifedFound: "لا يوجد طلاب معتمدين أو خريجين",
    textStudentSuspend: "لن يتمكن هذا الطالب من الوصول إلى الدورات بعد الآن. لديك خيار إعادته إذا غيرت رأيك.",
    commentStudentSuspend: "أضف تعليقا للطالب",
    successSuspendStudent: "لم يعد بإمكان الطالب الوصول إلى الدورات.",
    ReintegrateStudentText: "سيتمكن هذا الطالب من الوصول إلى الدورات.",
    ReintegrateStudentText_2: "لديك خيار استبعاد الطالب إذا غيرت رأيك.",
    successReinstateStudent: "يمكن للطالب الآن الوصول إلى الدورات.",
    successSuspendProf: "تم إيقاف الأستاذ بنجاح",
    successSuspendAssitant: "تم تعليق المعالج بنجاح",
    successReinstateProf: "تم إعادة دمج الأستاذ بنجاح",
    successReinstateAssistant: "معالج إعادة دمج ناجح",
    returnToProfProfile: "رجوع إلى الملف الشخصي",
    diplomeButton: "الشهادة",
    validatedDiploma: "دبلومة",
    Declcatalog: "اكتشف الكتالوج",
    deactivateCoursText: "لن يكون الوصول إلى هذه الدورة التدريبية متاحًا بعد الآن ، فلديك إمكانية إعادة تنشيطها إذا غيرت رأيك.",
    modalDeactivateCoursTitle: "تعطيل الدورة",
    deactivateCours: "تعطيل",
    successdeactivateCours: "تم الانتهاء بنجاح.",
    createBy: "معطى بواسطة",
    maleGender: "م.",
    femaleGender: "أم",
    modalReactivateCoursTitle: "إعادة تنشيط الدورة",
    modalReactivatePathTitle: "إعادة تنشيط الدورة",
    modalReactivateSessionTitle: "إعادة تنشيط الجلسة",
    reactivateCours: "اعادة تفعيل",
    reactivateCoursText: "هذه الدورة ستكون متاحة.",
    reactivateCoursText_2: "لديك خيار إيقاف تشغيله إذا غيرت رأيك.",
    reactivatePathText: "هذا الطريق سيكون متاحا.",
    reactivatePathText_2: "لديك خيار إيقاف تشغيله إذا غيرت رأيك.",
    reactivateSessionText: "ستكون هذه الجلسة متاحة.",
    reactivateSessionText_2: "لديك خيار إيقاف تشغيله إذا غيرت رأيك.",
    champsObligatoires: "(*) الحقول المطلوبة",
    continueCreatingCourse: "آسف ، لا يمكنك نشر هذه الدورة.",
    continueBUttonText: "استمر",
    errorChaptersLength: "يجب أن يكون لديك فصل واحد على الأقل (مطلوب).",
    linkImgCourseError: "مرئي لتوضيح الدورة (إلزامي).",
    descriptionCourseError: "عرض نصي للدورة (اجباري).",
    congratulation: "مبروك على تخرجك!",
    diplomaMsg : "لتتمكن من تنزيل شهادتك، يرجى الرجوع إلى جميع محتويات الفصول (مقاطع الفيديو مضمنة)",
    onlineCOurses: "دورة على شبكة الإنترنت",
    validatedCourses: "بالطبع للتحقق من صحة",
    AllCoursesDirector: "كل الدورات",
    BonneRéponses: "إجابات جيدة",
    Question: "سؤال",
    ReturnExam: "استئناف الاختبار الحالي الخاص بي",
    textExa: "حدد إجابة واحدة أو أكثر بناءً على التعليمات. يتم إجراء الاختبار مرة واحدة فقط.",
    StartExam: "يبدأ",
    Réponse: "إجابه",
    QCU: "فقط 1 إجابة صحيحة ممكنة",
    QCM: "العديد من الردود الممكنة",
    validCourse: "تحقق من صحة النشر",
    refuseInscriptionStudent: "طلب مرفوض",
    BonneReponses: "إجابات جيدة",
    placeholderTextArea: "أدخل النص هنا...",
    languageIT: "إيطاليا",
    languageES: "الأسبانية",
    languageDE: "ألمانية",
    languageNL: "هولندي",
    languageSV: "السويدية",
    obligatoireFormCourse: "واجب",
    Registered: "مسجل",
    New: "جديد",
    Suspended: "موقوف عن العمل",
    CatalogueVisitorsInTheLast30Days: "زوار الكتالوج في آخر 30 يومًا.",
    Course: "مسار",
    Candidates: "مرشحين",
    Teachers: "معلمون",
    ConversionRateOfApplicationsInTheLast30Days: "معدل تحويل الزيارات في آخر 30 يومًا.",
    MedianCourseNotes: "متوسط درجة الامتحان النهائي",
    AverageAgeOfStudents: "متوسط عمر الطلاب",
    Male: "رجال",
    Female: "النساء",
    MostRepresentedCities: "المدن الأكثر تمثيلاً",
    VisitsAndApplicationsForRegistration: "الزيارات والتسجيل",
    RegistrationsAndCertifications: "التسجيلات والدرجات",
    ConnectedStudents: "الطلاب المتصلين",
    LevelOfStudy: "المستوى الدراسي",
    numberOfVisits: "نظرة عامة على زيارات الصفحة",
    Applications: "طلبات التسجيل",
    Registration: "عدد التسجيلات",
    StudentsNumber: "عدد الطلاب المتصلين",
    Jan: "يناير",
    Fev: "فبراير",
    Mar: "مارس",
    Avr: "أبريل",
    May: "مايو",
    Jui: "يونيو",
    Jul: "جوي",
    Aug: "أنا",
    Sep: "سبتمبر",
    Oct: "أكتوبر",
    Nov: "نوفمبر",
    Dec: "ديسمبر",
    Access: "تنضم",
    Year: "أعوام",
    StudentsConnectedThisMonth: "تم تسجيل الدخول في آخر 30 يومًا.",
    AverageTimeSpentOnThePlatformForGraduation: "متوسط الوقت الذي يقضيه على المنصة للتخرج",
    AverageTimeBetweenRegistrationAndGraduation: "متوسط الوقت من التسجيل حتى التخرج",
    certification: "شهادة",
    waitingCertif: "جاري تحميل شهادتك ، برجاء الانتظار ...",
    ListEtds: "قائمة الطلاب",
    listcand: "قائمة المرشحين",
    listdipv: "قائمة الخريجين",
    listInscribed: "قائمة المسجلين",
    listSuspended: "قائمة المعلقين",
    Days: "أيام",
    noVideoToShow: "لا فيديو.",
    donnerAvis: "مهم !",
    donnerAvis_2: "أعط رأيك في الدورة",
    avisTitle: "عنوان مراجعتك",
    avisText: "اكتب مراجعتك",
    votreAvis: "رأيك هنا",
    publishMyReview: "ينشر",
    monAvis: "رأيي في الدورة",
    avis: "يلاحظ",
    Anonymous: "مجهول",
    noFeedback: "لا توجد مراجعات (في الوقت الحالي)",
    Finance: "تمويل",
    PublishedAt: "نشرت في",
    PublishedAte: "نشرت في",
    ActualPrice: "آخر سعر للفترة",
    MoyPrix: "متوسط السعر",
    discussionButton: "مناقشات",
    questionButton: "أسئلة للمعلم",
    addCommentButton: "إضافة تعليق",
    addCommentForChapter: "أضف تعليقًا على الفصل",
    addcommentForCourse: "أضف تعليقا للدورة",
    addcommentForPath: "أضف تعليقًا على الطريق",
    addCommentForSession: "أضف تعليقًا إلى الجلسة",
    commentTitle: "عنوان التعليق",
    yourComment: "اكتب تعليقك",
    votreCommentaie: "تعليقك هنا ...",
    addQuestionButton: "أضف سؤالاً",
    addCommentSuccess: "لقد تم اضافة تعليقك بنجاح",
    responses: "إجابة (إجابات)",
    noCommentFound: "لا توجد تعليقات (في الوقت الحالي)",
    repondre: "إجابه",
    labeldeletecompte: "سيتم حذف جميع البيانات الخاصة بك بشكل دائم",
    listdesProf: "قائمة المعلمين",
    noCoursePayedDefault: "لا توجد دورة مدفوعة.",
    noCoursePayed_1: "لم يتم العثور على دورات مدفوعة.",
    noCoursePayed_2: "الرجاء البحث مرة أخرى.",
    Courspayed: "الدورات المدفوعة",
    CoursFree: "دروس مجانية",
    paie: "قائمة المدفوعات",
    prunp: "حدد فترة",
    Année: "سنة",
    community: "تواصل اجتماعي",
    thematic: "حدد موضوعًا",
    comment: "تعليق",
    NumberOfCertifications: "عدد الشهادات",
    inscriptionTypePayment: "اكتب d'inscription",
    registrationFees: "رسوم التسجيل",
    gratuit: "حر",
    payant: "دفع",
    addQuestionForChapter: "اسأل المعلم سؤالا",
    yourQuestion: "اكتب سؤالك",
    addQuestionSuccess: "تمت إضافة سؤالك بنجاح",
    noQuestionFound: "لا توجد أسئلة للتعامل معها",
    noQuestionFound_4: "لم يتم الرد على أي أسئلة",
    noQuestionFound_5: "ليس لديك أسئلة.",
    paymentTitle: "دفع",
    paymentTitlePlural: "المدفوعات",
    paymentMethodCourses: "طريقة دفع الدورة",
    choiceCurerncy: "اختر العملة",
    Questions: "أسئلة",
    unsettledQuestion: "القضايا التي يجب معالجتها",
    settledQuestion: "القضايا المتداولة",
    allQuestion: "جميع الأسئلة",
    recent: "الأكثر حداثة",
    oldest: "الأقل حداثة",
    maxResponses: "مع أكبر عدد ممكن من الإجابات",
    allSubjects: "كل الدورات",
    allSubjects_2: "كل المواضيع",
    noQuestionFound_2: "بحثك لم يطابق أي أسئلة.",
    noQuestionFound_3: "الرجاء البحث مرة أخرى.",
    noCommentFound_2: "بحثك لم يطابق أي تعليقات",
    noPaymentInfo: "يجب عليك اختيار طريقة الدفع الخاصة بمدرستك",
    NoPaymentSchoolConfig_1: "لتعيين طريقة الدفع.",
    NoPaymentSchoolConfig_2: "انقر هنا",
    newResponses: "إجابة (إجابات) جديدة",
    SortsMostRecent: "الأحدث",
    SortOldest: "الأقدم",
    allStatus: "كل الحالات",
    WAITING: "في انتظار التأكيد",
    SOON: "ليأتي",
    ONGOING: "الجاري",
    ENDED: "انتهى",
    REFUSED: "رفض",
    allCourses: "كل الدورات",
    NoWebinarHasBeenCreatedYetCreateYourFirstWebinarToGetStarted: "لم يتم إنشاء ندوات عبر الإنترنت حتى الآن. قم بإنشاء أول برنامج تعليمي على الويب لتبدأ.",
    ForStudentsOf: "لتدريب الطلاب",
    WebinarGivenBy: "ندوة عبر الإنترنت مقدمة من",
    ENTERTHEWebinar: "أدخل الندوة عبر الويب",
    StartTHEWebinar: "ابدأ الندوة عبر الويب",
    AddToMyAgenda: "أضف إلى التقويم الخاص بي",
    validate: "للتحقق من صحة",
    inMyAgenda: "في يومياتي",
    Edit: "المعدل",
    REVIEWTHEwebinar: "مراجعة الندوة عبر الإنترنت",
    IWantTheWebinarToBeAccessibleInReplayWithThePublicConcerned: "أود أن تكون الندوة عبر الإنترنت متاحة في إعادة التشغيل للجمهور المعني",
    NewWebinar: "ندوة جديدة على الويب",
    close: "اغلاق",
    Webinar: "ندوات عبر الإنترنت",
    WebinarTitle: "عنوان الندوة",
    WebinarDate: "تاريخ ندوة الويب",
    WebinarSchedule: "جدول ندوة الويب",
    ShortDescriptionOfTheWebinar: "وصف موجز للندوة عبر الإنترنت",
    Cancel: "لالغاء",
    WaitingForValidation: "في انتظار التحقق",
    ComingSoon: "قريبا",
    LetsGo: "انطلاق",
    ToReview: "للمراجعة",
    inscriptionCourseFaq: "التسجيل في الدورة",
    followCourseFaq: "متابعة درس",
    certificationFaq: "دبلوم",
    professorsFaq: "الاساتذة",
    stopCourseFaq: "توقف عن الدرس",
    paymentFaq: "الفواتير",
    PAYED: "مدفوع",
    PRIX: "سعر",
    openQuestion: "سؤال مفتوح",
    Consignes: "تعليمات",
    Valeurdelaquestion: "قيمة السؤال",
    point: "نقطة",
    Nouveau_test: "اختبار جديد",
    TEST_DE_CONNAISSANCE: "اختبار المعرفة",
    Test_intermédiaire: "اختبار التقييم المتوسط",
    Non_éliminatoire: "لا إقصائي",
    Question_fermées: "أسئلة مغلقة",
    Test_de_fin_de_cours: "اختبار نهاية الدورة",
    Validant_le_diplome: "التحقق من صحة الدبلوم",
    Question_fermée_et_ou_ouvertes: "أسئلة مغلقة و / أو مفتوحة",
    modal_exam_title: "ما نوع الاختبار الذي تريد إنشاءه؟",
    AJOUTER_EXPLICATION: "أضف شرحًا",
    Explication: "تفسير",
    add: "لإضافة",
    Créer_votre_premiere_question: "قم بإنشاء سؤالك الأول",
    Examen_final_envoyé_le: "تم إرسال الاختبار النهائي بتاريخ",
    En_attende_de_correction_par_le_professeur: "بانتظار التصحيح من قبل المعلم",
    Répondre_aux_questions_fermees_ou_ouvertes_en_respectant_les_consignes: "أجب على الأسئلة المغلقة أو المفتوحة باتباع التعليمات.",
    Le_test_seffectue_une_seule_fois_et_détermine_lobtention_de_votre_diplome: "يتم إجراء الاختبار مرة واحدة فقط و",
    Le_test_seffectue_une_seule_fois_et_détermine_lobtention_de_votre_diplome_2: "يحدد تخرجك.",
    Réuissi: "ناجح",
    insuffisant: "غير كافٍ",
    Valider_ma_réponse: "تحقق من صحة جوابي",
    Rendu_le: "تم التسليم",
    Par: "حول",
    NOTER_LA_QUESTION: "سؤال ملاحظة",
    Examens_à_noter: "الامتحانات لملاحظة",
    Examen_notés: "امتحانات متدرجة",
    Tous_les_examens: "كل الامتحانات",
    aucun_examen_à_noter: "لا يوجد امتحان لتصحيحه",
    NOTE: "ملاحظة",
    à_noter: "للتدوين",
    Commentaire_du_professeur: "تعليق المعلم",
    Pas_de_commentaire: "بدون تعليق",
    Ajouter_un_commentaire: "إضافة تعليق",
    VALIDER_MA_NOTATION: "تحقق من التصنيف الخاص بي",
    EXAMEN_FINAL: "إمتحان نهائي",
    exam1: "اذكر هنا عنوان الامتحان النهائي ...",
    exam2: "حدد عنوان اختبار المعرفة هنا",
    votreMessage: "اكتب رسالتك...",
    noMessage: "لا توجد رسالة",
    noMessageDispo: "ليس متاحا بعد...",
    message_1: "عليك أن تنتظر المعلم",
    message_2: "افتح المحادثة",
    message_1_prof: "ابدأ محادثة بالكتابة",
    message_2_prof: "رسالتك أدناه.",
    textFaq: "ماذا لو كنت أرغب في معرفة المزيد عن تقدير مهمتي الكتابية؟",
    draftstatdateFollow: "بدأت القضية في",
    finishdraftFollowButton: "إنهاء ملفي",
    gotocourseStudent: "الوصول إلى الدورة",
    myFormations: "تدريباتي",
    SelectCourse: "اختر الدورة",
    NoWebinarHasBeenCreatedYet: "لم يتم إنشاء ندوات عبر الإنترنت حتى الآن.",
    TitleFormations: "تشكيلات",
    InscriptionDate: "سجل في",
    biography: "سيرة شخصية",
    labelMailProfessor: "البريد الإلكتروني للمعلم",
    comments: "تعليقات",
    noStudentInscribed: "لم يتم تسجيل أي طالب حتى الآن",
    demandTable: "طلب في",
    createNewFormation: "تشكيل جديد",
    selectFormatFormation: "اختر حجما",
    pathTitle: "رحلة",
    createPath: "إنشاء طريق",
    sessionTitle: "جلسة",
    createSession: "إنشاء جلسة",
    requiredLevelText: "المستوى المطلوب للتسجيل في الدورة",
    anyLevelCourse: "كل المستويات",
    firstLevel: "مبتدئ",
    secondLevel: "وسيط",
    thirdLevel: "خبير",

    ANYLEVEL: "كل المستويات",
    BEGINNER: "مبتدئ",
    courseDescriptionForm: "صف دورتك",
    descriptionTitle: "وصف",
    courseContentTitle: "محتوى الدورة",
    pathContentTitle: "محتوى الدورة",
    sessionContentTitle: "محتوى الجلسة",
    importVideoCourse: "استيراد الفيديو",
    createContentText: "قم بإنشاء المحتوى الأول الخاص بك",
    chapterPresentation: "عرض الفصل",
    textPresentationChapter: "نص تمهيدي للفصل",
    groupes: "مجموعات",
    newGroup: "مجموعة جديدة",
    groupTitle: "عنوان مجموعة الطلاب",
    newTestModalTitle: "اختبار جديد",
    alreadyCreateExam: "لقد قمت بالفعل بإنشاء اختبار نهائي",
    finalExmBreadcrunbTitle: "إمتحان نهائي",
    noGroupFound: "لم يتم إنشاء مجموعة طلابية",
    noGroupFound_2: "بحثك لا يطابق أي مجموعة",
    updateGroupTitle: "تحرير العنوان",
    deleteGroup: "حذف المجموعة",
    updateGroup: "تغيير عنوان المجموعة",
    addSubGroup: "أضف مجموعة فرعية",
    addStudentToGroup: "أضف الطلاب",
    Actions: "أجراءات",
    textConnaissance: "اختبار المعرفة",
    points: "نقاط",
    connectedThereIs: "تسجيل الدخول منذ",
    messageDeleteGroup_1: "سيتم حذف هذه المجموعة بشكل دائم.",
    messageDeleteGroup_2: "ليس لديك خيار استعادة هذه المجموعة إذا غيرت رأيك",
    messageDeleteGroup_3: "تم حذف المجموعة بنجاح",
    closedQuestion: "أسئلة مغلقة",
    pointsQuestion: "نقاط)",
    explicationText: "اكتب شرحك ...",
    allStudent: "جميع الطلاب",
    subGroup: "مجموعة فرعية",
    changeGroup: "تغيير المجموعة",
    addToGroup: "إضافة إلى المجموعة",
    deleteFromGroup: "إزالة من المجموعة",
    documentTextForm: "وثيقة",
    returnToCourse: "العودة إلى الفصل",
    returnToPathButton: "العودة إلى المقرر",
    returnToSessionButton: "العودة إلى الجلسة",
    sendPublishCoursePRofTitle: "أرسل إلى المخرج للتحقق من صحة النشر",
    returnToStudentList: "العودة إلى قائمة الطلاب",
    Previsualiser: "معاينة",
    editeMode: "يحرر",
    withInscription: "التسجيل تحت الملف",
    freeTitleCourse: "تقدم",
    questionPlaceholder: "سؤالك هنا ...",
    responsePlceholder: "اكتب إجابة هنا ..",
    explicationPlaceholder: "اكتب شرحا هنا ...",
    consignesPlaceholder: "تعليماتك هنا ...",
    goToCatalogueError: "للعودة إلى الصفحة السابقة اضغط على الزر أدناه.",
    errorCode: "خطا بالكود",
    oupsWord: "عذرًا!",
    errorInfoNotFound: "عذرا ، الصفحة التي تبحث عنها لا يمكن العثور عليها.",
    errorInfoUnauthorized: "لم يتم العثور على أذونات.",
    errorSubinfoUnauthorized: "هذه الصفحة ليست في متناول الجمهور.",
    errorInfoAccessRefused: "تم الرفض.",
    errorSubInfoAccessRefused: "أنت غير مفوض لدخول هذه الصفحة.",
    serverProblem: "مشكلة الخادم",
    tryLater: "يرجى المحاولة في غضون بضع دقائق.",
    unexpectedError: "عذرا ، خطأ غير متوقع.",
    errorInfoServerProblem: "الصفحة التي تبحث عنها قيد الصيانة حاليًا وستعود قريبًا.",
    continueCreatingPath: "عذرا ، لا يمكنك نشر هذا الطريق.",
    continueCreatingSession: "عذرا ، لا يمكنك نشر هذه الجلسة.",
    returnToPath: "العودة إلى المقرر",
    returnToSession: "العودة إلى الجلسة",
    courseDesactivationSuccess: "تم إلغاء تنشيط الدورة بنجاح",
    pathDesactivationSuccess: "تم إلغاء تنشيط الدورة بنجاح",
    sessionDesactivationSuccess: "تم تعطيل الجلسة بنجاح",
    textDeactivateCourse: "تعطيل الدورة",
    textDeactivatePath: "تعطيل الدورة",
    textDeactivateSession: "تعطيل الدورة",
    textreactiovationCourse: "تم تفعيل الدورة بنجاح",
    textreactiovationPath: "تم تفعيل المسار بنجاح",
    textreactiovationSession: "تم تفعيل الجلسة بنجاح",
    text_1_desactivateCourse: "لن يكون الوصول إلى هذه الدورة التدريبية متاحًا بعد الآن.",
    text_2_desactivateCourse: "لديك خيار إعادة تنشيط الدورة التدريبية إذا غيرت رأيك",
    text_1_desactivatePath: "لن يكون الوصول إلى هذه الدورة التدريبية متاحًا بعد الآن.",
    text_2_desactivatePath: "لديك خيار إعادة تنشيط الدورة التدريبية إذا غيرت رأيك",
    text_1_desactivateSession: "لن تكون هذه الجلسة متاحة بعد الآن.",
    text_2_desactivateSession: "لديك خيار إعادة تنشيط الجلسة إذا غيرت رأيك",
    refuseCourseText: "رفض نشر الدورة",
    refusePathText: "رفض نشر الدورة",
    refuseSessionText: "رفض نشر الجلسة",
    backToListCourses: "العودة إلى قائمة الدورات",
    textRefusedCourseSuccess: "تم رفض الدورة بنجاح",
    textRefusedPathSuccess: "تم رفض المسار بنجاح",
    textRefusedSessionSuccess: "تم رفض الجلسة بنجاح",
    acceptCourseText: "التحقق من صحة نشر الدورة",
    acceptPathText: "التحقق من صحة نشر الدورة",
    acceptSessionText: "التحقق من صحة نشر الدورة",
    textAcceptCourse: "ستكون هذه الدورة متاحة لجميع الطلاب.",
    textAcceptPath: "ستكون هذه الدورة متاحة لجميع الطلاب.",
    textAcceptSession: "ستكون هذه الجلسة متاحة لجميع الطلاب.",
    publishCourseSuccessModal: "تم نشر الدورة بنجاح",
    publishPathSuccessModal: "تم نشر الدورة بنجاح",
    publishSessionSuccessModal: "تم نشر الجلسة بنجاح",
    errorContentLength: "يجب أن يكون لديك محتوى واحد على الأقل (مطلوب).",
    errorPathLinkImg: "مرئي لتوضيح الدورة (إلزامي).",
    errorSessionLinkImg: "مرئي لتوضيح الجلسة (إلزامي).",
    errorPathDescription: "عرض نصي للدورة (إلزامي).",
    errorSessionDescription: "عرض نصي للدورة (اجباري).",
    typeTable: "يكتب",
    parcoursTitle: "رحلة",
    PATH: "رحلة",
    progress: "تقدم",
    gotopathStudent: "الوصول إلى الدورة",
    gotosessionStudent: "الوصول إلى الجلسة",
    newContent: "محتوى جديد",
    chapterTitleBegin: "عنوان الفصل",
    testTitleBegin: "عنوان اختبار المعرفة",
    examTitleBegin: "عنوان الامتحان",
    onlineFormations: "تدريب على الأنترنت",
    validateFormations: "سيتم التحقق من صحة التدريب",
    allFormations: "كل الدورات",
    inscribedTimeStudent: "مسجلة على",
    suspendedAt: "معلق في",
    newFinalExam: "أضف اختبارًا نهائيًا",
    backToMyrequest: "العودة إلى طلبي",
    beforeUploadText: "قم بسحب وإسقاط الملفات التي تريد مشاركتها",
    uploadFile: "حدد ملف",
    Subscription: "طلب تسجيل",
    waiting: "في الانتظار",
    fileValidated: "تم التحقق من صحة الملف",
    fileRefused: "رفض الملف",
    autorisationTraining: "أذونات",
    autorisationTrainingTable: "الإذن",
    autorzedStatus: "مخول",
    unauthorized: "غير مسموح",
    groupNameTable: "اسم المجموعة",
    changeMyDecision: "تغيير قراري",
    backtodemand: "مرة أخرى عند الطلب",
    cancelValidation: "إلغاء التحقق",
    downloadFileCertif: "تحميل شهادتي",
    nextChapterText: "الفصل التالي",
    getMyDiplome: "شهادتي",
    ListAllCourses: "قائمة بجميع الدورات",
    ListOnlineCourses: "قائمة الدورات على الإنترنت",
    ListPendingCourses: "قائمة الدورات المعلقة",
    ListStudents: "قائمة الطلاب",
    courseBy: "الدورة التي قدمها",
    SessionGivenBy: "الجلسة التي قدمها",
    PathGivenBy: "الدورة التي قدمها",
    PaymentDetails: "بيانات الدفع",
    Completeyourpurchasebyprovidingyourpaymentinformation: "أكمل عملية الشراء من خلال تقديم معلومات الدفع الخاصة بك.",
    LastNameandFirstName: "الإسم واللقب",
    CreditCardNumber: "رقم بطاقة الائتمان",
    ExpirationDate: "تاريخ انتهاء الصلاحية",
    confirmandpay: "اكد و ادفع",
    Yourpaymentwassuccessful: "تم الدفع الخاص بك بنجاح.",
    StartThePath: "ابدأ الرحلة",
    Yourpaymenthasbeendeclined: "تم رفض الدفع الخاص بك",
    StartTheCourse: "ابدأ الدورة",
    StartTheSession: "بدء الجلسة",
    selectLevelCourse_4: "كل المستويات",
    progressTable: "تقدم",
    notationLabel: "الرموز",
    singleClosedQuestion: "السؤال مغلق",
    Du: "ال",
    Au: "في",
    Examens: "الامتحانات",
    fromWhereTraining: "يأتي من",
    congratsTraining: "تهانينا ، لقد أكملت الدورة.",
    donnerAvis_3: "إبداء رأيك في الدورة:",
    donnerAvis_4: "إبداء رأيك في الجلسة:",
    donnerAvisButton: "اعطي رأيك",
    InitialPathName: "عنوان الدورة",
    PathDecriptionCreation: "صف خلفيتك",
    prerequisite1: "المتطلبات الأساسية لمتابعة هذه الدورة",
    skillsNeeded: "المعرفة والمهارات المكتسبة من خلال هذه الدورة",
    pathCoverPhot: "قم بسحب وإسقاط صورة لتوضيح الدورة التدريبية (jpg، png)",
    pathVideoDescription: "عرض فيديو للدورة",
    pathVideoDrop: "قم بسحب وإسقاط فيديو الدورة التدريبية الذي تريد تحميله (mp4 ، mov)",
    PathLevel: "المستوى المطلوب للتسجيل في الدورة",
    SessionTitleCreation: "عنوان الجلسة",
    SessionLevel: "المستوى المطلوب للتسجيل في الجلسة",
    SessionDescriptionCreation: "صف جلستك",
    prerequisite2: "المتطلبات الأساسية لمتابعة هذه الجلسة",
    SessionSkills: "المعرفة والمهارات المكتسبة خلال هذه الدورة",
    SessionCoverDescription: "مرئي لتوضيح الجلسة",
    SessionCoverDrop: "قم بسحب وإسقاط صورة لتوضيح الجلسة (jpg، png)",
    SessionDescriptionVideo: "عرض فيديو للدورة",
    DragDropSession: "قم بسحب وإسقاط فيديو الجلسة الذي تريد تحميله (mp4 ، mov)",
    saveSchoolLogo: "أضف صورة",
    descriptionChoiseCours: "أنشئ دورتك بفصولها واختباراتها وامتحانها النهائي. قم بإعداد ملف القبول الخاص بك",
    descriptionChoisePath: "قم بتجميع الدورات والدورات التدريبية الخاصة بك وإنشاء دورة تدريبية مع شروط النجاح",
    descriptionChoiseSession: "اجمع تدريبك مع شروط المرور المحددة في الوقت المناسب",
    returnButton: "يواجه",
    waitingToPayment: "انتظار الدفع",
    noUnpaidFound: "لا توقع للدفع",
    schoolAssistant: "إداري",
    passwordMandadory1: "يجب أن تحتوي كلمة المرور",
    passwordMandadory2: "8 أحرف على الأقل",
    passwordMandadory3: "حرف كبير واحد على الأقل وحرف صغير واحد",
    passwordMandadory4: "رقم واحد على الأقل",
    passwordMandadory5: "شخصية خاصة واحدة على الأقل (#؟! = @ $٪ ^ & * -)",
    passConfirmation: "تأكيد كلمة المرور",
    RésultatCumulé: "النتيجة التراكمية",
    etudiantsInscrits: "الطلاب المسجلين",
    TarifActuel: "السعر الحالي",
    TarifMoyen: "متوسط السعر",
    priceHistory: "معدل التاريخ",
    hidePricehistory: "إخفاء سجل الأسعار",
    saveModification: "تم حفظ التغيير!",
    Total: "المجموع",
    TotalInscrits: "إجمالي المسجلين",
    periodMedian: "متوسط السعر للفترة",
    totalMedian: "إجمالي متوسط السعر",
    totalResult: "مجموع الثغرة",
    QUESTIONS_OUVERTES: "أسئلة مفتوحة",
    notValid: "غير صالح",
    validAction: "تم التحقق من صحة الإجراء",
    studentResponse: "استجابة الطالب",
    markUpdate: "تعديل أماه تدوين",
    webinarEnded: "انتهى البرنامج التعليمي على الويب",
    added: "مضاف",
    warning: "انتباه !",
    changePrice: "لقد قمت للتو بتغيير سعر الدورة:",
    priceNow: "السعر الحالي:",
    priceAfter: "السعر الجديد المتأثر:",
    saveUpdate: "حفظ التغيير",
    profchoice: "حدد مدرسًا لتعيين الدورة (الدورات) إليه",
    deleteReason: "سبب الحذف*",
    disableAssistant: "لن يتمكن هذا المساعد من الوصول إلى النظام الأساسي بعد الآن. لديك خيار إعادته إذا غيرت رأيك.",
    addCommentToAssistant: "أضف تعليقًا للمعالج",
    connectAccountPaymee: "قم بتوصيل حساب Paymee الخاص بك",
    connect: "الاتصال",
    idPaymee: "معرف المستفيد",
    assistantInvitation: "قم بدعوة إداري للتسجيل",
    mailAssistant: "البريد الإلكتروني المساعد",
    membreInvitation: "قم بدعوة عضو",
    role: "دور",
    newCourse: "دورة جديدة",
    newPath: "طريق جديد",
    newSession: "فصل دراسي جديد",
    profMandadotory: "يجب تخصيص هذه الدورة لمعلم.",
    profMandadtory2: "حدد مدرسًا لتعيين هذه الدورة إليه *",
    rehireAssistant: "سيتمكن هذا المساعد من الوصول إلى النظام الأساسي. لديك خيار تعليق المساعد إذا غيرت رأيك.",
    dragToUpload: "اسحب واسقط صورتك",
    or: "أو",
    selectPhoto: "حدد صورتك",
    offerList: "قائمة العروض",
    answer: "إجابتك",
    completedParts: "الأجزاء المنجزة",
    followPaths: "اتبع الدورة",
    followSessions: "اتبع الدورة",
    displayVideo: "عرضك بالفيديو أو المرئي",
    toValidate: "للتحقق من صحة",
    archiveSheet: "أرشيف",
    connectZoom: "ربط التكبير",
    accountZoom: "حساب التكبير",
    connectAccount: "ربط حسابي",
    connectAccountToschool: "ربط حساب التكبير الخاص بك مع مدرستك",
    changeOffer: "عرض التغيير",
    facture: "الفواتير",
    paymentMethod: "طرق الدفع",
    cancelSubscription: "إلغاء اشتراكي",
    offerPrice: "رسوم العرض",
    nextDeadline: "تاريخ استحقاق الدفعة التالية",
    deleteOffer: "حذف العرض",
    deleteMsgOffer: "هل تريد حذف عرضك؟",
    passwordUpdated: "تم تغيير كلمة السر الخاصة بك",
    passwordNoMatch: "كلمتا المرور غير متطابقتان",
    modifyProfil: "تعديل ملفي الشخصي",
    newEmail: "عنوان البريد الإلكتروني الجديد",
    connectStripToSchool: "قم بتوصيل حساب Stripe الخاص بك بمدرستك",
    disconnect: "قطع الاتصال",
    connectPaymeeToSchool: "ربط حساب Paymee الخاص بك مع مدرستك",
    paymentMessage: "أكمل عملية الشراء من خلال تقديم معلومات الدفع الخاصة بك.",
    paymentConfimation: "تأكيد الدفع",
    activate: "يُمكَِن",
    month: "شهريا",
    yearly: "الحولية",
    offerMessage1: "كل شيء مدرج في الباقة الأساسية بالإضافة إلى:",
    Choisir: "اختار",
    compareoffers: "قارن جميع الميزات",
    offer: "عرض",
    monthlyFor: "شهريًا لـ",
    yearFor: "الحولية ل",
    totalPrice: "الثمن الجملي:",
    valid: "صالح",
    ProfList: "قائمة الأستاذ",
    StudentList: "قائمة الطلاب",
    CourseList: "قائمة الدورات",
    Inscrits: "مسجل",
    team: "مجهز",
    assistant: "إداري",
    info: "معلومة",
    shortDescription: "وصف قصير",
    shortDescription1: "وصف موجز لـ",
    nbrConx: "عدد التوصيلات",
    inscDate: "تاريخ التسجيل",
    role1: "وظيفة",
    totalRevenu: "إجمالي الدخل",
    makeAPayment: "أتم عملية الدفع",
    Fileacceptedon: "تم قبول الملف في",
    params: "الإعدادات",
    pricipalGroup: "المجموعة الرئيسية",
    addPhoto: "اضف صورتك",
    changePhoto: "غير صورتك",
    deletePhoto: "حذف الصورة",
    seletionner: "تحديد",
    facturationDetails: "معلومات الفواتير",
    closedSchool: "هذه المدرسة مغلقة حاليا",
    hold: "لو سمحت",
    contact1: "اتصل بالدعم",
    contact2: "للمزيد من المعلومات.",
    question: "سؤال",
    allTeam: "كل الفريق",
    assistants: "مساعدين",
    noTeam1: "لم يتم العثور على مدرس / إداري.",
    noTeam2: "الرجاء البحث مرة أخرى.",
    noTeamDefault_1: "لم يتم تسجيل أي عضو في الفريق.",
    noTeamDefault_2: "قم بدعوة عضو للتسجيل لتبدأ.",
    noAssistant_1: "لم يتم العثور على مساعدين.",
    noAssistant_2: "الرجاء البحث مرة أخرى.",
    noAssistantDefault_1: "لم يتم تسجيل أي إداري.",
    noAssistantDefault_2: "قم بدعوة إداري للتسجيل للبدء.",
    TrainingToedit: "في تَقَدم",
    webinarForm1: "حدد مدرسًا لتعيين هذا البرنامج التعليمي على الويب لـ *",
    webinarForm2: "اختر مدرسًا",
    webinarForm3: "لطلاب الدورة:",
    listeDesAssistants: "قائمة المساعدين",
    listeTeam: "قائمة الفريق",
    listOfUnpaidStudents: "قائمة الطلاب غير المسددة",
    Nounpaidstudent: "لا يوجد طالب بدون أجر",
    memberMail: "البريد الإلكتروني للعضو",
    integrations: "تكاملات",
    monForfait: "حزمة بلدي",
    Facturation: "الفواتير",
    Detailsduforfait: "حزمة من التفاصيل",
    Gerezoumodifiezvotreforfait: "إدارة أو تغيير خطتك",
    Votreoffre: "عرضك",
    Fraisdeloffre: "رسوم العرض",
    Prochainedatedecheancedupaiement: "تاريخ استحقاق الدفعة التالية",
    Modedepayement: "طريقة الدفع",
    Changerleforfait: "خطة التغيير",
    Annulermonabonnement: "إلغاء اشتراكي",
    Limitationduforfait: "قيود الحزمة",
    Suivrelalimitationdevotreforfaitactuel: "تتبع اختناق خطتك الحالية",
    Voirlesforfaits: "انظر الحزم",
    Vousavezatteintlalimitedaccesdevos: "لقد وصلت إلى حد الوصول الخاص بك",
    Vouspouvezajouterdes: "يمكنك إضافة ملفات",
    endesactivantun: "عن طريق تعطيل أ",
    endesactivantune: "عن طريق تعطيل أ",
    actifouenameliorantvotreforfait: "عن طريق ترقية خطتك",
    activeouenameliorantvotreforfait: "عن طريق ترقية خطتك",
    Modesdepayement: "طرق الدفع",
    Gérezoumodifiezvotremodesdepaiement: "إدارة أو تغيير طرق الدفع الخاصة بك",
    congratsSession: "تهانينا ، لقد أكملت الجلسة.",
    avisTitle1: "رأيي في الدورة",
    avisTitle2: "رأيي في الجلسة",
    FormationAValider: "لا يوجد تدريب للتحقق من صحة.",
    FormationEnLigne: "لا يوجد تدريب عبر الإنترنت.",
    emptyListFormation1: "الرجاء البحث مرة أخرى.",
    noTrainingSearch: "هذا المعلم ليس لديه تدريب حتى الآن.",
    NoWebinarToValidate: "لا يوجد ندوة عبر الإنترنت للتحقق من صحتها.",
    NoWebinarIsComingSoon: "لا ندوات قادمة.",
    NoWebinarIsOnGoing: "لا توجد ندوات حالية على الويب.",
    NoWebinarHasEnded: "لا توجد ندوات مكتملة على الويب.",
    NoWebinarIsRefused: "لم يتم رفض أي ندوات عبر الإنترنت.",
    NoWebinarIsAssociatedToThisCourse: "لا يوجد ندوة عبر الإنترنت مرتبطة بهذه الدورة.",
    NoWebinarExists: "لم يتم العثور على ندوات عبر الإنترنت.",
    FirstWebinar: "لم يتم إنشاء ندوات عبر الإنترنت حتى الآن.",
    FirstWebinar2: "قم بإنشاء أول برنامج تعليمي على الويب لتبدأ.",
    transformKnowledge: "حول معرفتك إلى ثروة",
    addToDescription: "أضف إلى الوصف",
    importListOfStudents: "استيراد قائمة الطلاب. *",
    Remplacer: "يحل محل",
    returnToStudentsList: "العودة إلى قائمة الطلاب",
    invite: "يدعو",
    continue: "يكمل",
    savedUsers: "لدي موافقة المستخدمين المسجلين في هذا الملف ، وفقًا لقانون حماية البيانات الشخصية المعمول به في الدولة.",
    download: "تحميل",
    loadInformation: "جاري تحميل معلوماتك ، برجاء الانتظار ...",
    yourAccount: "من حسابك",
    emailSend: "أرسل البريد الإلكتروني",
    check: "التحقق من",
    verifyYourEmail: "تحقق من بريدك الإلكتروني وافتح الرابط الذي تلقيته للمتابعة",
    returnToConnexionPage: "العودة إلى صفحة تسجيل الدخول",
    save: "للتسجيل",
    coursResume: "اكتب ملخص هذه الدورة ، مع عرض أهم نقاطها وبيان قيمتها المضافة للعضو.",
    coursObjectives: "ضع قائمة بالأهداف التي يجب تحقيقها خلال هذه الدورة.",
    preRequisite: "أضف المتطلبات الأساسية اللازمة لأخذ هذه الدورة.",
    skillsToLearn: "ضع قائمة بالمهارات التي سيتم اكتسابها خلال هذه الدورة في شكل كلمات رئيسية.",
    pathResume: "اكتب ملخصًا عن هذه الدورة ، موضحًا أهم نقاطه وبيان قيمته المضافة للعضو.",
    pathObjectives: "ضع قائمة بالأهداف التي يجب تحقيقها خلال هذه الدورة.",
    pathPreRequisite: "أضف المتطلبات الأساسية اللازمة لاتباع هذا المسار.",
    pathSkillsToLearn: "ضع قائمة بالمهارات التي سيتم اكتسابها خلال هذه الدورة في شكل كلمات رئيسية.",
    sessionResume: "اكتب ملخص هذه الجلسة مع عرض أهم نقاطها وبيان قيمتها المضافة للعضو.",
    sessionObjectives: "ضع قائمة بالأهداف التي يجب تحقيقها خلال هذه الجلسة.",
    sessionPreRequisite: "أضف المتطلبات الأساسية اللازمة لمتابعة هذه الجلسة.",
    sessionSkillsToLearn: "ضع قائمة بالمهارات التي سيتم اكتسابها خلال هذه الجلسة في شكل كلمات رئيسية.",
    timeNotAvailable: "هذا الجدول غير متوفر ، يرجى اختيار جدول آخر.",
    annulerImportation: "إلغاء الاستيراد",
    annulerImportationConfirmation: "هل أنت متأكد أنك تريد إلغاء الاستيراد ؟.",
    retourImportation: "رجوع إلى الاستيراد",
    failedRequest: "الطلب فشل",
    lowerDeal: "للتبديل إلى خطة أقل ، يجب عليك ضبط الحد الخاص بك وفقًا لحدود الخطة المختارة.",
    activeProfessors: "مدرسون نشطون",
    activeStudents: "الطلاب النشطاء",
    activeAssistants: "مساعدين نشطين",
    professorList: "قائمة المعلمين",
    trainingList: "قائمة الدورات",
    setAsPrincipal: "تعيين الرئيسية؟",
    replacePaymentMode: "سيحل هذا محل طريقة الدفع الأساسية الحالية.",
    deletePaymentMethod: "حذف طريقة الدفع",
    deletePaymentMethodConfirmation: "هل أنت متأكد أنك تريد حذف طريقة الدفع.",
    deleteInvitation: "إلغاء الدعوة",
    invitationAnnulationOfYourSchool: "لن يسمح إلغاء الدعوة بعد الآن لهذا الشخص بأن يصبح عضوًا في",
    yourSchool: "مدرستك.",
    youCanReinvite: "يمكنك دائما دعوته للعودة.",
    keepInvitation: "احتفظ بالدعوة",
    noCourseFound: "لم يتم العثور على دورات",
    wantCreateCourse: "تريد إنشاء دورة؟",
    cannotImportListOfStudents: "لا يمكنك استيراد قائمة الطلاب التي تحتوي على أخطاء.",
    firstName: "الإسم",
    obligatoire: "إجباري",
    name: "اسم",
    emailAddressNotValid: "عنوان البريد الإلكتروني غير صالح.",
    emailFormat: "يجب أن يكون تنسيق البريد الإلكتروني",
    emailExample: "votrenom@exemple.com",
    importationResult: "نتيجة الاستيراد",
    succesfulImportation: "عمليات الاستيراد الناجحة",
    lignes: "خطوط",
    containErrors: "تحتوي على أخطاء",
    addStudent: "أضف طالبًا",
    importList: "استيراد قائمة",
    invitationSentSuccesfully: "تم إرسال الدعوة بنجاح",
    invitationSuccessAssistant: "تم إرسال دعوتك بنجاح إلى المساعد.",
    userAlreadyExists: "المستخدم مع هذا البريد الإلكتروني موجود بالفعل.",
    enterValidEmail: "رجاء قم بإدخال بريد الكتروني صحيح.",
    professorLimitAtteined: "لقد وصلت إلى حد وصول المدرسين. يمكنك إضافة مدرسين عن طريق إلغاء تنشيط مدرس نشط أو ترقية خطتك.",
    assistantLimitAtteined: "لقد وصلت إلى حد الوصول لمساعديك. يمكنك إضافة مساعدين عن طريق تعطيل إداري نشط أو ترقية خطتك.",
    compareOffers: "قارن العروض",
    schoolUses: "تستخدم مدرستك الميزات",
    notIncludedInOffer: "التي لا يدعمها العرض",
    passToOffer: "إذا قمت بالتبديل إلى العرض",
    allFonctionalities: "، كل المميزات",
    areDesactivated: "من مدرستك سيتم إلغاء تنشيطها ولا يمكن الوصول إليها.",
    invitationsSentSuccesfully: "تم إرسال الدعوات بنجاح",
    automaticSendOfInvitations: "الإرسال التلقائي للدعوات",
    invitationSendConfirmation: "هل تؤكد إرسال الدعوات إلى القائمة التي قمت باستيرادها؟",
    confirmAndSend: "تأكيد وإرسال",
    principal: "المالك",
    noPaymentMethodAdded: "لا توجد طريقة دفع مضافة",
    addPaymentMethod: "إضافة طريقة الدفع",
    remainning: "تبقى لك",
    changePaymentMethod: "قم بتغيير آلية الدفع",
    leaveMsg: "نحن آسفون أن نراك تذهب!",
    annulationWarning: "بمجرد الإلغاء ، سيظل حسابك نشطًا حتى تنتهي فواتيرك في",
    annulationWarning2: "بعد ذلك ، ستفقد الوصول إلى مدرستك.",
    annulationConfirmation: "هل أنت متأكد أنك تريد إلغاء اشتراكك؟",
    enterPasswordtoConfirm: "أدخل كلمة المرور الخاصة بك للتأكيد",
    cancelationReasons: "يرجى إخبارنا بسبب إلغاء اشتراكك. تعليقاتك ، ستساعدنا على تحسين المنتج",
    whyuCanceling: "لماذا تقوم بإلغاء اشتراكك",
    selectLowerCase: "يختار",
    missingFonctionality: "الميزة التي أريدها مفقودة",
    expensive: "أعتقد أنها باهظة الثمن",
    notInteressted: "Je n'utilise بالإضافة إلى الحمى",
    movingToAnotherPlatform: "أنا أنتقل إلى منصة أخرى",
    preciser: "رجاء حدد",
    wantToTellUsMore: "هل تريد إخبارنا بالمزيد؟",
    unlimited: "غير محدود",
    Cryptogramme: "اللازهرية نبتة",
    savePaymentMethod: "احفظ طريقة الدفع هذه في ملف التعريف الخاص بي",
    paymentPassed: "دفع",
    paymentSuccesfull: "تمت معالجة الدفع الخاص بك بنجاح.",
    returnToActivity: "العودة إلى النشاط",
    twoFreeMonths: "شهرين مجانا",
    essentialTools: "الأدوات الأساسية للبدء ، بدون تكاليف خفية",
    forever: "في كل الأوقات",
    actuelOffer: "عرضك الحالي",
    selectFreeOffer: "اختر العرض المجاني",
    pathAndSessions: "طرق وجلسات",
    forProfessionalsAndNonProfessionals: "للمدربين المحترفين ورجال الأعمال.",
    yearlyBill: "دفع سنوى",
    perMonth: "في الشهر",
    selectProOffer: "اختر عرض Pro",
    courses: "الطبقات",
    illimitedAccess: "للاستمتاع بعدد غير محدود من Koors.",
    perStudent: "لكل طالب",
    minimum: "الحد الأدنى",
    selectBussinessOffer: "اختر عرض الأعمال",
    illimitedNumberOfStudents: "عدد غير محدود من الطلاب",
    illimitedNumberOfCours: "عدد غير محدود من الدروس",
    illimitedNumberOfProfessors: "عدد غير محدود من المعلمين",
    illimitedNumberOfAssistants: "عدد غير محدود من المساعدين",
    subscriptionPayments: "دفع الاشتراك",
    Devise: "ابتكر",
    FacturationCycle: "دورة الفواتير",
    monthly: "شهريا",
    yearly_2: "سنوي",
    ofReduction: "اختزال",
    minimalBill: "الحد الأدنى للفواتير",
    nextBill: "الفاتورة التالية",
    confirmPayment: "تأكيد الدفعة",
    reductionOnCurrentPlan: "التخفيض على الخطة السنوية",
    verification: "تَحَقّق",
    noStudentFound_2: "لم يتم العثور على طلاب",
    archivedTrainings: "لا يوجد تدريب مؤرشفة",
    refusedTrainings: "رفض أي تدريب",
    desactivatedTrainings: "لم يتم تعطيل أي تدريب",
    editedTrainings: "لا يتم تحرير أي تدريب",
    noTrainingHaveBeenCreated: "لم يتم إنشاء جبن حتى الآن.",
    createFirstTraining: "للبدء ، قم بإنشاء تشكيلتك الأولى.",
    examsToNote: "لا توجد امتحانات لتصحيحها.",
    notedExams: "لا يوجد امتحان متدرج.",
    noExam: "ليس لديك امتحان.",
    resendInvitation: "اعادة ارسال الدعوة",
    Copyright: "حقوق النشر",
    rights: "جميع الحقوق محفوظة.",
    noExamFound: "لم يتم العثور على أي تعليقات.",
    atteinedMaximum: "لقد وصلت إلى الحد الأقصى المسموح به للخطة",
    updatePlan: "يرجى تحديث خطتك لإنشاء المزيد من التشكيلات.",
    noBill: "لا الفاتورة.",
    finalExam: "إمتحان نهائي .",
    selectAnImage:"اختر صورة",
    slideToAddAnImage:"قم بسحب وإسقاط صورة لتوضيح الندوة عبر الإنترنت",
    yourWebinarsAreIntegrated:"ندوات الويب الخاصة بك متكاملة",
    onThePlatform:"إلى المنصة مع",
    offreProAndBussiness:"العروض المهنية والتجارية",
    createWebinar:"ما عليك سوى إنشاء ندواتك على الويب مباشرةً في Koors Select ودعوة طلابك مباشرةً للمشاركة (تتم دعوة الطلاب الجدد المؤهلين تلقائيًا).",
    findReplayAndPublishWebinar:"ابحث عن الإعادة لندوات الويب الخاصة بك وقم بنشرها في تدريبك.",
    improveMyOffer:'تحسين عرضي',
    soonWebinarsAvailable:"سيتم تقديم ندوات عبر الإنترنت لك قريبًا.",
    willInformuSoon:"نبقيك على اطلاع.",
    courseResumeIntroduction:"ملخص تمهيدي للدورة ...",
    reprendre:"الاستئناف",
    freeOffreNoPaymentMethod:"ليس لديك طرق دفع. أنت مسجل في العرض المجاني.",
    offre:"عرض",
    Credit:"الائتمان",
    DebitCard:"بطاقة ائتمان",
    nameOnCard:"الاسم على البطاقة",
    traitement:"معالجة...",
    paiement:"دفع",
    offersList:"قائمة العروض",
    perYear:"في السنة",
    account:"حساب",
    wrongBirthDate:"تاريخ ميلاد خاطئ",
    selectCountry:"اختر دولة",
    cardAddedSuccessfully:"تمت إضافة بطاقتك بنجاح",
    returnToParameters:"العودة إلى الاعدادات",
    addNewPaymentMethod:"أضف طريقة دفع جديدة",
    verifyPassword:"كلمة المرور غير صحيحة ، هل تريد التحقق من كلمة المرور الخاصة بك؟",
    returnToOffer:"العودة إلى عرضي",
    subscriptionCancelled:"تم إلغاء اشتراكك بنجاح",
    perMonthPayedAnually:"شهر ، تدفع سنويًا",
    downloadTemplate:"تحميل النموذج",
    toSee:"اكسل لمشاهدة نموذج من التنسيق",
    slideExcel:"قم بسحب وإسقاط ملف اكسل الخاص بك",
    reposition:"التعديل",
    importNewPhoto:"استيراد صورة جديدة",
    importPhoto:"استيراد صورة",
    slideToSet:"اسحب للتعديل",
    publishMyOpinion:"نشر تقييمي",
    noWebinarIsPlanned:"لم يتم جدولة ندوات عبر الإنترنت في هذا الوقت.",
    addVideoUrl:"أضف رابط الفيديو",
    itegrateVideo:"تضمين الفيديو",
    enterValidUrl:"تأكد من إدخال رابط صالح.",
    pasteVideoUrl:"لصق رابط الفيديو ...",
    addVideoToIntegrate:"إضافة رابط الفيديو لتضمين",
    perYearPayedAnually:"عام ، تدفع سنويًا",
    titleSessionPlaceholder:" ...ضع عنوان الجلسة هنا",
    titlePathPlaceholder:'...ضع عنوان الدورة هنا',
    creationDate:"تاريخ الإنشاء",
    createdAt:"أنشأ",
    lastUpdate:"اخر تحديث",
    generic_decline: "تم رفض الدفع لأسباب عامة",
    insufficient_funds: "أموال البطاقة غير كافية",
    lost_card: "أعلن فقدان البطاقة",
    stolen_card: "تم الإبلاغ عن سرقة البطاقة",
    expired_card: "انتهت صلاحية البطاقة",
    incorrect_cvc: "رمز CVC غير صحيح",
    processing_error: "تم رفض الدفع بسبب خطأ في المعالجة",
    gender:"جنس",
    male:"رجل",
    female:"مرأة",
    emptyTitleError:"عنوان الدورة (مطلوب)",
    emptyPathTitleError:"عنوان الدورة (مطلوب)",
    emptySessionTitleError:"عنوان الجلسة (مطلوب)",
    Webinar1: "ندوات عبر الإنترنت",
    notFound1:"أضف المزيد من الطلاب وارفع مستوى العتاد ",
    notFound2:"تجمع Koors بين أفضل الميزات لتوزيع الدورات التدريبية عبر الإنترنت على أكبر عدد ممكن من الأشخاص." ,
    notFound3:"بفضل Koors ، يمكنك بسهولة إدارة مجتمعات الطلاب / العملاء والبقاء على اتصال في جميع الأوقات.",
    emailAlreadyUsed:"هذا البريد الإلكتروني قيد الاستخدام بالفعل",
    Clôturé: "مغلق",
    Ouvert: "مفتوح",
    TITREdusondage: "عنوان الاستطلاع",
    Auteur: "الكاتب",
    CRÉELE: "تم الإنشاء في",
    CloturéLE: "تم الإغلاق في",
    RÉPONSEs: "الردود",
    Créerunsondage: "إنشاء استطلاع",
    sondages: "الاستطلاعات",
    sondage: "الاستطلاعات",
    transcodeInProgress:'جارٍ إنهاء عملية الاستيراد. يمكنك الاستمرار في إكمال المحتوى.'

}
