import { ComponentProps, FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { useTranslation } from 'react-i18next';
import { getStudent } from '../../../global-state/actions/profile-actions';
import { setActualTab } from '../../../global-state/actions/professor-actions';
import { redirect, clearHistoryArray } from '../../../global-state/actions/breadcrumb-actions';
import { getStudents, getStudentInfoById } from '../../../global-state/actions/course-actions';
import TableElement from '../../../elements/table/normal-table-element';
import { useHistory } from 'react-router-dom';
import EmptyListElement from '../../../elements/empty-list/normal-empty-list-element';
import TableStudentsListLoadingComponent from '../table-students-list-loading-component';
import { returnStartPathUrl } from '../../../helpers/domainCheck';


const StudentsUnpaidInfoTableComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();
    const moment = require('moment');
    const history = useHistory();
    const [loadingTable, setLoadingTable] = useState<boolean>(true);
    const [sizeUnpaid, setSizeUnpaid] = useState(20);
    const [unpaidDataLength, setUnpaidDataLength] = useState<any>(null);
    const [unpaidData, setUnpaidData] = useState<any>(null);
    const [atBottom, setAtBottom] = useState<any>(false)
    const [emptysearch, setemptysearch] = useState<any>(false);

    const unpaidHeaders = [
        { title: t('students_2'), sorted: true, classNames: "w-35", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t('courseTable'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('typeTable'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('Fileacceptedon'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 ms-auto" },
    ]
    const [actualTab, setActualTab] = useState((props?.lastTabClicked) ? props?.lastTabClicked : "All");
    const dateFormat = require('dateformat');
    const studentType:any = {
        'COURSE':t('Cours'),
        'PATH':t('parcoursTitle'),
        'SESSION':t('sessionTitle')
    }
    const badgeHandler = (dateSent:any) => {
        if (dateSent < 3) {
            return 'secondary'
        } else if (dateSent >= 3 && dateSent < 6) {
            return 'warning'
        }
        else {
            return 'refuse'
        }
    }
    useEffect(() => {
        let lang = localStorage.getItem("language") || "fr"
        if (lang !== "en") {
            require('moment/locale/' + lang);
            moment.defineLocale(lang, { parentLocale: lang });
        } else {
            require('moment/locale/' + 'fr');
            moment.defineLocale('fr', { parentLocale: 'fr' });
        }
    }, []);
    useEffect(() => {
        setLoadingTable(props?.loadStudentsUnpaid);
    }, [props?.loadStudentsUnpaid])
    useEffect(() => {
        document.addEventListener('scroll', handleScroll);

        return () => {
            document.removeEventListener('scroll', handleScroll);
        }
    })
    useEffect(() => {
      
        if (!props?.data?.searchUnpaid  && props?.studentsList?.length === 0 && props?.followCount?.UNPAID > 0) 
        {
            setemptysearch(true)
        }else{
            setemptysearch(false)
        }
    }, [props?.data])
    const handleScroll = () => {
        if (!(atBottom) && (window.innerHeight + window.scrollY +1) >= document.documentElement.scrollHeight) {
            setAtBottom(true);
                if (unpaidDataLength > sizeUnpaid) {

                    props?.addDataList('unpaid')
                }
            document.removeEventListener('scroll', handleScroll);
        }
        if (!(((window.innerHeight + window.scrollY) -5) < document.documentElement.scrollHeight)) {
            setAtBottom(false);
        }
    }
    useEffect(() => {
        setSizeUnpaid(props?.data?.sizeUnpaid);
    }, [props?.data])

    useEffect(() => {
        const list = {
            headers: unpaidHeaders,
            body: props?.studentsList?.list.map((student: any) => {
                const dateSent = Math.ceil((new Date().getTime() - new Date(student?.dateupdate).getTime()) / (1000 * 3600 * 24));
                return {
                    row: {
                        student: student,
                        actionName: "goToPath",
                        classNames: "table-white",
                        columns: [
                            { content: student?.firstName + " " + student.lastName, classNames: "", actionName: '' },
                            { content: student?.courseTitle, classNames: "text-center", actionName: '' },
                            { content: studentType[student.type], classNames: "text-center", actionName: '' },
                            {
                                content: <span>{dateFormat(student?.dateupdate, "dd/mm/yyyy ")} {(student?.status === 'UNPAID') ?
                                <span className={`badge-text ${badgeHandler(dateSent)}`}>/ {t('TimePassed')} {dateSent} {t('joursVisit')}</span>
                                :
                                <span className={`badge-text secondary`}>/ {t('TimePassed')} {dateSent} {t('joursVisit')}</span>
                                   }
                                 </span>,
                                classNames: `text-end`,
                                actionName: ''
                            }
                        ]
                    }
                }
            })
        }
        setUnpaidData(list);
        setUnpaidDataLength(props?.studentsList?.length);
    }, [props?.studentsList?.list])

    const doAction = async (value: any) => {
        if (value.action === 'goToPath') {
            await props?.getStudentInfoById(value.row.student.id, props?.schoolInfo?.id);
            await props?.getStudent(value.row.student);
            props?.clearHistoryArray()
            props?.redirect(t('students_2'), `${returnStartPathUrl()}/students/all`)
            props?.redirect(value.row.student?.firstName + " " + value.row.student?.lastName, `${returnStartPathUrl()}/students/profile/student/${value.row.student.id}`)
             history.push(`${returnStartPathUrl()}/students/profile/student/${value.row.student.id}`)
        }
    }

    return (<>
        {!loadingTable ?
        <div>
            {(unpaidData?.body?.length > 0) && <>
                <TableElement data={unpaidData} doAction={(value: any) => { doAction(value) }} />
              {(atBottom 
                    && (props?.studentsList?.list?.length < props?.followCount?.UNPAID ))
                    && <div className="d-flex w-100 align-items-center justify-content-center">
                        <div className="m-3">
                            <svg className="spinner" viewBox="0 0 50 50">
                                <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                            </svg>
                        </div>
                    </div>}
            </>
            }
            {(unpaidData?.body?.length === 0 && !props?.data?.searchUnpaid && !emptysearch) &&
                <div className="w-100 text-center mt-5 pt-5">
                    <EmptyListElement lines={[t('Nounpaidstudent')]} />
                </div>
            }
            {(emptysearch) &&
                    <div>
                    <TableStudentsListLoadingComponent />
                   </div>
               }
            {(unpaidData?.body?.length === 0 && props?.data?.searchUnpaid) &&
                    <div className="w-100 text-center mt-12">
                          <EmptyListElement lines={["Aucun étudiant trouvé","Veuillez effectuer une nouvelle recherche."]} />
                </div>}
        </div> :
    <div>
        <TableStudentsListLoadingComponent />
    </div>}
</>);
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    myStudents: state.courseReducer.myStudents,
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    listeStudents: state.profReducer.listeStudents,
    registration: state.courseReducer.registration,
    studentInfo: state.courseReducer.studentInfo,
    loadStudentsSuspended: state.profReducer.loadStudentsSuspended,
    lastTabClicked: state.profReducer.lastTabClicked,
    addDataList: ownProps?.addDataList,
    data: ownProps?.data
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            getStudents,
            getStudentInfoById,
            getStudent,
            setActualTab,
            redirect, clearHistoryArray
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(StudentsUnpaidInfoTableComponent);