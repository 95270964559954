export const TRANSLATIONS_DE = {
    addCourse: "NEUER KURS",
    createCourse: "Erstellen Sie einen Kurs",
    myCourses: "Meine Kurse",
    Published: "Online",
    Draft: "Desorganisiert",
    Pending: "Warten auf Genehmigung durch den Direktor",
    RequestDeactivation: "Warten auf Deaktivierung durch den Direktor",
    RequestActivation: "Warten auf Aktivierung durch den Direktor",
    RequestDeletion: "Warten auf Archivierung durch den Direktor",
    certified: "Absolventen",
    candidats: "KANDIDATEN",
    candidatsCapitalise: "Kandidaten",
    courseStatus_1: "Der Kurs bleibt",
    courseStatus_2: "bis zur Veröffentlichung privat",
    Pending_2: "Warten auf Bestätigung",
    RequestDeletion_2: "Warten auf Archivierung",
    RequestDeactivation_2: "Warten auf Deaktivierung",
    RequestActivation_2: "auf Aktivierung warten",
    actvity: "AKTIVITÄT",
    students: "Studenten",
    students_2: "Student",
    description: "BEZEICHNUNG",
    archive: "ARCHIV",
    publish: "VERÖFFENTLICHEN",
    inscription: "INSCHRIFT",
    inscriptionTitle: "Inschrift",
    certifiedTitle: "Abgeschlossen auf",
    lastVisitProfile: "Letzter Besuch vor",
    lastVisitProfile_1: "letzter Besuch",
    lastVisitProfile_2: "Il ja",
    descProf: "Kurze Beschreibung des Lehrers, der in der Präsentation des Kurses erscheinen wird",
    content: "INHALT",
    titleCoursePlaceholder: "Geben Sie hier den Kurstitel ein...",
    titleCourseForm: "Kursname",
    modalPublishCourse_1: "Zur Genehmigung an den Direktor senden",
    modalPublishCourse_1_1: "Bestätigen Sie den Beitrag",
    modalRefusedCourse_1: "Veröffentlichung ablehnen",
    modalPublishCourse_2: "Fügen Sie einen Kommentar für den Regisseur hinzu",
    modalPublishCourse_2_2: "Fügen Sie einen Kommentar für den Lehrer hinzu",
    modalPublishCourse_3: "Kommentator...",
    modalPublishCourse_4: "KURS SENDEN",
    modalPublishCourse_4_4: "KURS VERÖFFENTLICHEN",
    modalRefusedCourse_4: "LEHNE DEN KURS AB",
    modalPublishCourseSuccess: "Der Kurs wurde zur Validierung an den Direktor gesendet.",
    modalPublishPathSuccess: "Der Kurs wurde zur Validierung an den Direktor gesendet.",
    modalPublishSessionSuccess: "Die Sitzung wurde zur Validierung an den Direktor gesendet.",
    modalPublishCourseSuccess_1: "Der Kurs ist veröffentlicht. Es ist jetzt online verfügbar.",
    modalDeactivateCours: "Der Kurs ist deaktiviert. Es ist nicht mehr online verfügbar.",
    modalRefusedCourseSuccess: "Sie haben gerade die Veröffentlichung des Kurses abgelehnt. Ihre Ablehnung wurde an den Lehrer gesendet.",
    titleFormCourse_1: "Videopräsentation des Kurses",
    titleFormCourse_2: "Visual zur Veranschaulichung des Kurses",
    titleFormCourse_3: "Textpräsentation des Kurses",
    resume: "Einführende Zusammenfassung",
    textAreaPlaceholder: "Hier Text eingeben...",
    placeholderInscriptionInput: "Hier Text eingeben",
    prerequisite: "Voraussetzungen für die Teilnahme an diesem Kurs",
    objectives: "Bildungsziele",
    skills: "Kenntnisse und Fähigkeiten, die durch diesen Kurs erworben wurden",
    skillsPlaceholder: "Geben Sie ein Stichwort ein...",
    uploadVideo_1: "Ziehen Sie das Kursvideo, das Sie hochladen möchten, per Drag & Drop (mp4, mov)",
    uploadVideo_2: "WÄHLEN SIE EINE VIDEODATEI",
    uploadImage_1: "Ziehen Sie ein Foto per Drag-and-Drop, um den Kurs zu veranschaulichen (jpg, png)",
    uploadImage_2: "WÄHLEN SIE EIN BILD",
    downloadVideo: "Download",
    awaitVideo: "In der Warteschlange",
    whiletranscode: "Wird gerade angesehen",
    transcodingVideo: "Videobearbeitung",
    breadCrumbChapter: "Kapitel",
    chapitre: "Kapitel",
    newTest: "NEUER TEST",
    newChapter: "NEUES KAPITEL",
    selectLevelCourse: "Eben",
    selectLevelCourse_1: "Anfänger",
    selectLevelCourse_2: "Dazwischenliegend",
    selectLevelCourse_3: "Bestätigt",
    listChaptersContent: "Legen Sie zunächst das Kursniveau fest und erstellen Sie Ihr erstes Kapitel.",
    chapterSelectFomCHapter: "KAPITEL",
    saveChapter: "REGISTRIEREN",
    durationChapter: "Dauer",
    placeholderTitleChapter: "Geben Sie hier den Titel des Kapitels ein...",
    titleFormChapter_1: "Kapitel Video",
    titleFormChapter_2: "Dateien zum Herunterladen",
    titleFormChapter_3: "Kapiteltext",
    textFileUpload_1: "Drag-and-Drop-Kapitel-Upload-Dateien",
    textFileUpload_2: "(Audio, PDF, PowerPoint, Word, Excel...)",
    fileUploadButton: "Datei aussuchen",
    uploadVideoChpter_1: "Ziehen Sie das Video des Kapitels, das Sie hochladen möchten, per Drag & Drop (mp4, mov)",
    tests: "Prüfungen",
    test: "Prüfen",
    resultat: "Ergebnisse",
    reponseInscrit: "Abonnementanfrage",
    reponseAv: "Vorher antworten",
    refusedAt: "Abgelehnt",
    Refused: "Sich weigern",
    Unpaid: "Bezahlen",
    seeDemand: "Siehe meine Anfrage",
    certifButton: "ZERTIFIKAT",
    courseTable: "ََAusbildung",
    DATEACHAT: "Kaufdatum",
    exportListe: "LISTE EXPORTIEREN",
    filterProfessors: "Lehrer",
    statusFilter: "Status",
    search: "Forschung",
    Tous: "Alle",
    Brouillon: "Desorganisiert",
    Avalider: "Bestätigen",
    Enligne: "Online",
    Archive: "Archiv",
    Refuse: "Sich weigern",
    Cours: "Kurs",
    COURSE: "Kurs",
    Professeurs: "Lehrer",
    Demandes: "Anfragen",
    filterCourse: "Alle Kurse",
    Certifies: "Zertifiziert",
    Statut: "Status",
    PresProf: "Präsentation des Professors",
    dernVisite: "letzter Besuch",
    Enattente: "Ausstehend",
    Admis: "akzeptiert",
    Suspendu: "Suspendiert",
    inviteProf: "LADE EINEN LEHRER EIN",
    inviteProf_2: "Laden Sie einen Lehrer ein, sich anzumelden",
    send: "Senden",
    inviteSuccess: "Ihre Einladung wurde an den Lehrer gesendet.",
    myAccount: "Mein Konto",
    saveImg: "Bild speichern",
    Profile: "Profil",
    Nom: "Nachname",
    NomObligatoire: "Nachname*",
    PrenomObligatoire: "Vorname*",
    Prenom: "Vorname",
    country: "Land des Wohnsitzes",
    Tunisie: "Tunesien",
    France: "Frankreich",
    city: "Wohnort",
    birthdate: "Geburtsdatum",
    birthdate_input: "Geburtsdatum (MM/TT/JJJJ)",
    NivScolaire: "Schulniveau",
    Primaire: "Primär",
    College: "Mittelschule",
    Lycee: "Weiterführende Schule",
    Bac: "Bac",
    Technicien2: "Techniker (Bac +2)",
    License: "Lizenz (Bac+3)",
    Master: "Master oder Äquivalent (BAC +4 oder +5)",
    Ingenieur: "Techniker",
    Doctorat: "Promotion",
    emailAdd: "E-Mail-Addresse",
    updatePass: "Ändere mein Passwort",
    password: "Passwort",
    actualPass: "Jetziges Passwort",
    newPass: "Neues Passwort",
    enterPassword: "Mindestens 8 Zeichen",
    msgErrorPassword: "Bitte geben Sie mindestens 8 Ziffern/Buchstaben ein",
    msgErrorConfirmPassword: "Bitte geben Sie das gleiche Passwort ein",
    confimrNewPass: "Passwort Bestätigung",
    msgErrorMail: "Bitte geben Sie eine gültige E-Mail-Adresse ein",
    confimrNewEmail: "Neue Email Adresse",
    validPassButton: "Bestätige mein neues Passwort",
    MainSchoolColor: "Hauptschulfarbe",
    AddYourColorToPersonalizeThePresentationOfTheCourses: "Fügen Sie Ihre Farbe hinzu, um die Präsentation der Kurse zu personalisieren",
    imgCrop_1: "Ziehen Sie ein Foto per Drag-and-Drop",
    imgCrop_3: "Ziehen Sie Ihr Logo per Drag-and-Drop",
    imgCrop_2: "WÄHLEN SIE EIN BILD",
    pathBy: "Kurs gegeben von",
    sessionBy: "Sitzung gegeben von",
    catalogCourses: "Kurs Katalog",
    Etudiants: "Studenten",
    Ecole: "Schule",
    Catalogue: "Katalog",
    Activite: "Aktivität",
    lecole: "Schule",
    howItWorks: "Wie es funktioniert",
    hello: "Herzlich willkommen !",
    cnxButton: "Einloggen",
    createAccountMsg: "Erstelle meinen Account",
    questionCreateAccount: "Sie haben noch kein Konto erstellt?",
    forgotLogin: "Haben Sie Ihr Passwort vergessen ?",
    emailLogin: "Email",
    validInscription: "MEINE REGISTRIERUNG BESTÄTIGEN",
    activateAccount: "MEIN KONTO AKTIVIEREN",
    madame: "FRAUEN",
    monsieur: "MANN",
    other: "SONSTIGES",
    registerDone_1: "Ihre Registrierungsanfrage wurde berücksichtigt.",
    registerDone_2: "Eine E-Mail wurde an Sie gesendet.",
    registerDone_3: "Klicken Sie auf den Link in dieser E-Mail, um die Erstellung Ihres Kontos zu bestätigen.",
    registerDone_4: "Bis bald !",
    validRegister: "Bestätigen",
    activateMyAccount: "Aktivieren Sie mein Konto",
    directeur: "Direktor",
    professeur: "Lehrer",
    invalidToken: "Dieser Link wurde bereits verwendet und ist abgelaufen. Bitte wenden Sie sich bei Bedarf an den Support.",
    questionAccount: "Sie haben bereits ein Konto ?",
    verifMail_1: "Ihre E-Mail wurde bestätigt, Sie können sich jetzt anmelden.",
    verifMail_3: "Validierung Ihrer E-Mail",
    changePass: "Passwort ändern",
    msgUpdatedPass: "Das Passwort wurde geändert.",
    recPass: "Passwort wiederherstellen",
    reinMail: "Geben Sie unten Ihre E-Mail-Adresse ein. Sie erhalten einen Link zum Zurücksetzen Ihres Passworts.",
    reinBUttonPass: "Setze mein Passwort zurück",
    sendMailRein: "Eine E-Mail mit Ihrem Link zum Zurücksetzen wurde an Sie gesendet.",
    alreadyHaveAcc: "Sie haben bereits ein Konto ?",
    followCourse: "Nehmen Sie an diesem Kurs teil",
    followPath: "Folgen Sie dieser Route",
    followSession: "Sitzung folgen",
    objPEda: "Bildungsziele",
    preresuiPeda: "Voraussetzungen für diesen Kurs",
    preresuiPedaPath: "Voraussetzungen für diesen Kurs",
    preresuiPedaSession: "Voraussetzungen für diese Sitzung",
    skillsPreveiw: "Kenntnisse und Fähigkeiten, die durch diesen Kurs erworben wurden",
    skillsPreveiwPath: "Kenntnisse und Fähigkeiten, die durch diesen Kurs erworben wurden",
    skillsPreveiwSession: "Kenntnisse und Fähigkeiten, die während dieser Sitzung erworben wurden",
    courseProgramm: "Kursprogramm",
    Chapitres: "Kapitel",
    videoCourse: "Videounterricht",
    textCourse: "Kurstext",
    downloadFiles: "Dateien zum Herunterladen",
    testConn: "Wissenstests",
    aboutSchool: "Über die Schule",
    aboutProfessor: "Über den Lehrer",
    registerCourse: "Melden Sie sich für den Kurs an",
    registerPath: "Melden Sie sich für den Kurs an",
    registerSession: "Registrieren Sie sich für die Sitzung",
    testConn_2: "Teste Dein Wissen",
    progressView: "Verfolge deinen Fortschritt",
    previewText_1: "Füllen Sie Ihre Registrierungsanfrage aus, damit der Lehrer sie validieren kann.",
    previewText_2: "Machen Sie Fortschritte in Ihrem Programm und lernen Sie neue Fähigkeiten.",
    previewText_3: "Bestätigen Sie Ihr Wissen mit Tests und erhalten Sie Ihr Zertifikat.",
    Introduction: "Einführung",
    Documents: "Unterlagen",
    downloadFIleDetails: "Herunterladen",
    Etudiant_1: "Student",
    Professeur_1: "Lehrer",
    Directeur_1: "Direktor",
    annuler: "ABBRECHEN",
    modifier: "Modifikator",
    logout: "Ausloggen",
    connexionButton: "Verbindung",
    languageFR: "Französisch",
    languageEN: "Englisch",
    language: "Sprache",
    Pending_3: "Bestätigen",
    CERTIFICAT: "ZERTIFIKAT",
    refuseCourse: "Veröffentlichung ablehnen",
    joursVisit: "Tage",
    consulter: "KONSULTIEREN",
    jourVisit: "Tag",
    Candidat: "Kandidat",
    enCours: "STUDENTEN IN ARBEIT",
    certifiés: "ABSOLVENTEN",
    Dossier: "Fall",
    Demande: "Anfrage",
    DemandeEnvoyéeIlYA: "Anfrage gesendet",
    Resultats: "Ergebnisse",
    Certificat: "Zertifikat",
    Tempsdobtention: "Erhalten",
    Tempsdobtention_2: "Zeit erhalten",
    refuseCourseDirector: "Veröffentlichung ablehnen",
    refuseCourseDirector_2: "Fügen Sie einen Kommentar für den Lehrer hinzu",
    refuseCourseDirector_3: "LEHNE DEN KURS AB",
    modalRefuseCourseSuccess_1: "Sie haben gerade die Veröffentlichung des Kurses abgelehnt.",
    modalRefuseCourseSuccess_2: "Ihre Ablehnung wurde an den Lehrer gesendet.",
    archiveCourseDirector: "Möchten Sie diesen Kurs wirklich archivieren?",
    archiveCourseDirectorSuccess: "Der Kurs ist archiviert.",
    archiveCourseDirector_2: "ARCHIVIEREN SIE DEN KURS",
    nothingTOSay: "Nichts zu sagen",
    online: "Online",
    offline: "Offline",
    validationNewPassword: "Sie können sich mit Ihrem neuen Passwort anmelden.",
    showMore: "FOLGENDES ZEIGEN",
    folderRegistrationTrueOrFalse: "Vorbehaltlich der Validierung der Datei.",
    nextCommentsFeedback: "Als nächstes anzeigen",
    emailUpdateButtonShH: "MEINE E-MAIL-ADRESSE ÄNDERN",
    conditionDeValidation: "Bedingungen für die Validierung des Studenteneinschreibungsantrags durch den Professor.",
    Acceptation: "Annahme der automatischen Studenteneinschreibung.",
    AcceptationSousDossier: "Annahme der Einschreibung des Studenten vorbehaltlich der Validierung der Akte.",
    questions: "Fragen an den Schüler",
    documents: "Vom Studierenden anzufordernde Unterlagen",
    titreQuestion: "Geben Sie hier den Titel der Frage ein",
    titreDocument: "Geben Sie hier den Titel des Dokuments an",
    AjouterQuestion: "Frage hinzufügen",
    AjouterDocument: "Dokument hinzufügen",
    demandeDinscription: "Abonnementanfrage",
    envoyerDemande: "MEINE ANFRAGE SENDEN",
    titreRegistration_1: "Fragen des Lehrers",
    titreRegistration_2: "Vom Lehrer angeforderte Dokumente",
    Inscribed: "Im Gange",
    CertifiedStudent: "Zertifiziert",
    DraftStudent: "Registrierungsdatei in Bearbeitung",
    Candidate: "Warten auf Validierung der Datei",
    finishRegistration: "BEENDEN SIE MEINE ANFRAGE",
    noCoursesStudent: "Sie sind in keinem Kurs eingeschrieben.",
    discoverCatalog: "ENTDECKEN SIE DEN KATALOG",
    noCoursesCreated: "Es wurde noch keine Schulung erstellt.",
    noCoursesCreated_2: "Erstellen Sie zunächst Ihren ersten Kurs.",
    noCourses_1: "Keine Kurse belegt",
    noCourses_2: "Keine Registrierungsanfrage",
    noCourses_3: "Keine Kurse abgeschlossen",
    noCoursesPublished: "Es wurden noch keine Kurse veröffentlicht.",
    deleteCompte: "Mein Konto löschen",
    searchProfessors: "Forschen...",
    descriptionProfLabel: "Kurze Beschreibung des Lehrers, der in der Präsentation seiner Kurse erscheinen wird.",
    relaunchProfInvitation: "BELEBEN",
    noProfessorsList_1: "Kein Lehrer gefunden.",
    noProfessorsList_2: "Bitte suchen Sie erneut.",
    noProfessorsListDefault_1: "Es ist kein Lehrer registriert.",
    noProfessorsListDefault_2: "Laden Sie einen Lehrer ein, sich anzumelden, um loszulegen.",
    signatureProf: "Unterschrift des Professors",
    signatureDirector: "Unterschrift des Direktors",
    Deactivate: "Deaktiviert",
    breadCrumbTest: "Prüfen",
    placeholderTitleExam: "Geben Sie hier den Titel des Tests an...",
    exempleTitleExam: "Beispiel, Wissenstest des ersten Wissens",
    noQuestionTest: "Erstellen Sie zunächst Ihre erste Frage.",
    newQuestion: "NEUES PROBLEM",
    addQuestionText: "Frage hinzufügen",
    placeholderQuestionText: "Geben Sie die Frage an",
    answerQuestion: "Geben Sie die Antwort an",
    placeholderChoiceText: "Geben Sie den Text der Mehrfachauswahl an",
    delete: "Löschen",
    suppcomte: "Kontolöschung",
    selectMotif: "Wählen Sie ein Muster aus",
    coursNeMeconvier: "Klassen passen nicht zu mir",
    comm: "Einen Kommentar hinzufügen",
    DeleteSuccess: "Ihr Konto wurde erfolgreich gelöscht. Auf Wiedersehen.",
    CreationAccountProfDirector: "Ihr Konto wurde erstellt. Sie können sich anmelden.",
    messageEnvoyerDemande_1: "Ihre Registrierungsanfrage wurde gesendet.",
    messageEnvoyerDemande_2: "Sie erhalten eine Antwort per E-Mail",
    messageEnvoyerDemande_3: "in den nächsten 3 Tagen.",
    candidat_status: "WARTEN AUF VALIDIERUNG",
    refuser: "ABLEHNEN",
    refuser_1: "SICH WEIGERN",
    valider: "BESTÄTIGEN",
    valider_1: "AKZEPTIERT",
    refuserDemandeRegistration: "Schüleranmeldung verweigern",
    ajouterCommentaireEtudiant: "Fügen Sie einen Kommentar für den Schüler hinzu.",
    refuserLaDemande: "LEHNEN SIE DIE ANFRAGE AB",
    messageRefuseRegistrationCours: "Die Einschreibung von Studenten in den Kurs wurde erfolgreich abgelehnt.",
    messageRefuseRegistrationPath: "Die Anmeldung des Studierenden zum Kurs wurde erfolgreich abgelehnt.",
    messageRefuseRegistrationSession: "Die Studentenanmeldung für die Sitzung wurde erfolgreich abgelehnt.",
    messageValiderRegistration: "Der Student wurde eingeladen, mit dem Kurs zu beginnen.",
    messageValiderRegistrationParcours: "Der Student wurde eingeladen, dem Kurs zu folgen",
    messageValiderRegistrationSession: "Der Student wurde eingeladen, nach der Sitzung zu beginnen",
    SuspendProf: "Suspendieren",
    textProfSuspend: "Dieser Lehrer kann nicht mehr auf die Lektionen zugreifen. Sie haben die Möglichkeit, es wieder einzusetzen, wenn Sie Ihre Meinung ändern.",
    otherChoice: "Sonstiges",
    commentProfSuspend: "Fügen Sie einen Kommentar für den Lehrer hinzu",
    questionLabel: "Frage",
    choiceLabelBON: "Richtige Antwort",
    addAnswerButton: "Fügen Sie eine Antwort hinzu",
    noQuestionTestPreview: "Keine Fragen",
    passedTestAverage: "Erfolgsrate",
    param: "Einstellung",
    presen: "Präsentation",
    commentSuspendInputProf: "Kommentar...",
    motifSuspendProf: "Wählen Sie ein Muster aus",
    affectOtherPRof: "Wählen Sie einen Lehrer aus, dem Sie Unterricht zuweisen möchten",
    ReintegrateProf: "Reintegrieren",
    ReintegrateProfText: "Dieser Lehrer kann auf die Kurse zugreifen. Sie haben die Möglichkeit, den Lehrer zu suspendieren, wenn Sie Ihre Meinung ändern.",
    AcceptProf: "Annehmen",
    aArchiver: "Zu archivieren",
    aDesactiver: "Zum Deaktivieren",
    aActiver: "Aktivieren",
    desactiverCourse: "DEAKTIVIERUNG BESTÄTIGEN",
    refuseDesactivationCourse: "DEAKTIVIERUNG VERWEIGERN",
    activerCours: "AKTIVIERUNG BESTÄTIGEN",
    refuserLactivationCours: "AKTIVIERUNG VERWEIGERN",
    archiverCours: "ARCHIVIERUNG VALIDIEREN",
    refuserArchivationCours: "ARCHIVIERUNG VERWEIGERN",
    settingEcole: "Schuleinstellungen",
    nomEcole: "Name der Schule",
    logoEcole: "Schullogo",
    persenCourte: "Kurzpräsentation (dieser Text wird auf den Kursseiten angezeigt)",
    listCandidates: "Registrierungsanfragen",
    listFollowing: "Es folgten Schulungen",
    listeCertificated: "Abgeschlossene Ausbildungen",
    sendDate: "Senden der Anfrage",
    importVideoUpload: "Video-Download",
    waitingUpload: "Warten Sie mal...",
    uploadFinishing: "Das Video wird in Kürze verfügbar sein",
    uploadPending: "Wird verarbeitet...",
    candidatures: "Anwendungen",
    certifcations: "ZERTIFIZIERUNGEN",
    dashboardAllStudents: "Student",
    certifiedDashStudents: "Absolvent",
    YesWord: "Ja",
    NoWord: "Nicht",
    obtentionPeriod: "Erhalten",
    In: "Im",
    TimePassed: "Es gibt",
    noStudentFound: "Keine aktuellen Studenten",
    noCandidateFound: "Keine Bewerbungen anhängig",
    noSuspendedFound: "Kein Schüler wird suspendiert",
    noCertifedFound: "Keine zertifizierten oder graduierten Studenten",
    textStudentSuspend: "Dieser Student kann nicht mehr auf die Kurse zugreifen. Sie haben die Möglichkeit, es wieder einzusetzen, wenn Sie Ihre Meinung ändern.",
    commentStudentSuspend: "Fügen Sie einen Kommentar für den Schüler hinzu",
    successSuspendStudent: "Der Student hat keinen Zugriff mehr auf die Kurse.",
    ReintegrateStudentText: "Dieser Student kann auf die Kurse zugreifen.",
    ReintegrateStudentText_2: "Sie haben die Möglichkeit, den Schüler auszuschließen, wenn Sie es sich anders überlegen.",
    successReinstateStudent: "Der Student hat jetzt Zugang zu den Kursen.",
    successSuspendProf: "Professor erfolgreich suspendiert",
    successSuspendAssitant: "Der Administrator wurde erfolgreich angehalten",
    successReinstateProf: "Professor erfolgreich reintegriert",
    successReinstateAssistant: "Administrator für erfolgreiche Reintegration",
    returnToProfProfile: "Zurück ZUM PROFIL",
    diplomeButton: "Diplom",
    validatedDiploma: "DIPLOME",
    Declcatalog: "ENTDECKEN SIE DEN KATALOG",
    deactivateCoursText: "Dieser Kurs wird nicht mehr zugänglich sein, Sie haben die Möglichkeit, ihn zu reaktivieren, wenn Sie Ihre Meinung ändern.",
    modalDeactivateCoursTitle: "Deaktivierung des Kurses",
    deactivateCours: "Deaktivieren",
    successdeactivateCours: "Wurde erfolgreich abgeschlossen.",
    createBy: "Gegeben von",
    maleGender: "M.",
    femaleGender: "Mama",
    modalReactivateCoursTitle: "Kursreaktivierung",
    modalReactivatePathTitle: "Kursreaktivierung",
    modalReactivateSessionTitle: "Reaktivierung der Sitzung",
    reactivateCours: "Reaktivieren",
    reactivateCoursText: "Dieser Kurs wird barrierefrei sein.",
    reactivateCoursText_2: "Sie haben die Möglichkeit, es auszuschalten, wenn Sie Ihre Meinung ändern.",
    reactivatePathText: "Diese Route wird barrierefrei sein.",
    reactivatePathText_2: "Sie haben die Möglichkeit, es auszuschalten, wenn Sie Ihre Meinung ändern.",
    reactivateSessionText: "Diese Sitzung wird zugänglich sein.",
    reactivateSessionText_2: "Sie haben die Möglichkeit, es auszuschalten, wenn Sie Ihre Meinung ändern.",
    champsObligatoires: "(*) Benötigte Felder",
    continueCreatingCourse: "Sie können diesen Kurs leider nicht veröffentlichen.",
    continueBUttonText: "FORTSETZEN",
    errorChaptersLength: "Sie müssen mindestens ein Kapitel haben (erforderlich).",
    linkImgCourseError: "Visual zur Veranschaulichung des Kurses (obligatorisch).",
    descriptionCourseError: "Textpräsentation der Lehrveranstaltung (obligatorisch).",
    congratulation: "Herzlichen Glückwunsch zu deinem Abschluss!",
    diplomaMsg : "Um Ihr Diplom herunterzuladen, konsultieren Sie bitte alle Inhalte in den Kapiteln (einschließlich Videos).",
    onlineCOurses: "Online Kurs",
    validatedCourses: "Kurs zu validieren",
    AllCoursesDirector: "Alle Kurse",
    BonneRéponses: "Gute Antworten",
    Question: "Frage",
    ReturnExam: "MEINEN AKTUELLEN TEST FORTSETZEN",
    textExa: "Wählen Sie je nach Anweisung eine oder mehrere Antworten aus. Der Test wird nur einmal durchgeführt.",
    StartExam: "Start",
    Réponse: "Antworten",
    QCU: "Nur 1 richtige Antwort möglich",
    QCM: "Viele mögliche Antworten",
    validCourse: "VALIDIEREN SIE DIE PUBLIKATION",
    refuseInscriptionStudent: "ANFRAGE ABGELEHNT",
    BonneReponses: "Gute Antworten",
    placeholderTextArea: "Hier Text eingeben...",
    languageIT: "Italien",
    languageES: "Spanisch",
    languageDE: "Deutsch",
    languageNL: "Niederländisch",
    languageSV: "Schwedisch",
    obligatoireFormCourse: "obligatorisch",
    Registered: "Eingetragen",
    New: "Neu",
    Suspended: "Suspendiert",
    CatalogueVisitorsInTheLast30Days: "Katalogbesucher in den letzten 30 Tagen.",
    Course: "Kurs",
    Candidates: "Kandidaten",
    Teachers: "Lehrer",
    ConversionRateOfApplicationsInTheLast30Days: "Konversionsrate der Besuche in den letzten 30 Tagen.",
    MedianCourseNotes: "Mittlere Abschlussprüfungsnote",
    AverageAgeOfStudents: "Durchschnittsalter der Schüler",
    Male: "Männer",
    Female: "Frauen",
    MostRepresentedCities: "Die meisten vertretenen Städte",
    VisitsAndApplicationsForRegistration: "BESUCHE & ANMELDUNG",
    RegistrationsAndCertifications: "ANMELDUNG & ABSCHLÜSSE",
    ConnectedStudents: "Verbundene Schüler",
    LevelOfStudy: "Studienniveau",
    numberOfVisits: "Übersicht Seitenbesuche",
    Applications: "Registrierungsanfragen",
    Registration: "Anzahl der Anmeldungen",
    StudentsNumber: "Anzahl der verbundenen Schüler",
    Jan: "Jan",
    Fev: "Feb",
    Mar: "Beschädigen",
    Avr: "Apr",
    May: "Kann",
    Jui: "Juni",
    Jul: "Jui",
    Aug: "Mir",
    Sep: "Sep",
    Oct: "Okt",
    Nov: "Nov",
    Dec: "Dez",
    Access: "Zugreifen",
    Year: "Jahr",
    StudentsConnectedThisMonth: "In den letzten 30 Tagen eingeloggt.",
    AverageTimeSpentOnThePlatformForGraduation: "Durchschnittliche Verweildauer auf der Plattform für den Abschluss",
    AverageTimeBetweenRegistrationAndGraduation: "Durchschnittliche Zeit von der Einschreibung bis zum Abschluss",
    certification: "Zertifizierung",
    waitingCertif: "Ihr Diplom wird geladen, bitte warten...",
    ListEtds: "Schülerliste",
    listcand: "Kandidatenliste",
    listdipv: "Liste der Absolventen",
    listInscribed: "Liste der Registranten",
    listSuspended: "Liste der suspendierten",
    Days: "Tage",
    noVideoToShow: "Kein Video.",
    donnerAvis: "Es ist wichtig !",
    donnerAvis_2: "Geben Sie Ihre Meinung zum Kurs ab",
    avisTitle: "Titel deiner Bewertung",
    avisText: "schreibe deine Rezension",
    votreAvis: "Ihre Meinung hier",
    publishMyReview: "Veröffentlichen",
    monAvis: "Meine Meinung zum Kurs",
    avis: "Notiz",
    Anonymous: "Anonym",
    noFeedback: "Keine Bewertungen (vorerst)",
    Finance: "Finanzen",
    PublishedAt: "Veröffentlicht auf",
    PublishedAte: "Veröffentlicht auf",
    ActualPrice: "Letzter Preis des Zeitraums",
    MoyPrix: "Durchschnittlicher Preis",
    discussionButton: "Diskussionen",
    questionButton: "Fragen an den Lehrer",
    addCommentButton: "Einen Kommentar hinzufügen",
    addCommentForChapter: "Fügen Sie dem Kapitel einen Kommentar hinzu",
    addcommentForCourse: "Fügen Sie einen Kommentar zum Kurs hinzu",
    addcommentForPath: "Fügen Sie einen Kommentar zum Kurs hinzu",
    addCommentForSession: "Fügen Sie der Sitzung einen Kommentar hinzu",
    commentTitle: "Kommentartitel",
    yourComment: "Schreiben Sie Ihren Kommentar",
    votreCommentaie: "Dein Kommentar hier...",
    addQuestionButton: "Frage hinzufügen",
    addCommentSuccess: "Ihr Kommentar wurde erfolgreich hinzugefügt",
    responses: "Antwort(en)",
    noCommentFound: "Keine Kommentare (vorerst)",
    repondre: "Antworten",
    labeldeletecompte: "Alle Ihre Daten werden dauerhaft gelöscht",
    listdesProf: "Liste der Lehrer",
    noCoursePayedDefault: "Kein kostenpflichtiger Kurs.",
    noCoursePayed_1: "Keine bezahlten Kurse gefunden.",
    noCoursePayed_2: "Bitte suchen Sie erneut.",
    Courspayed: "Bezahlte Kurse",
    CoursFree: "Kostenlose Lektionen",
    paie: "Liste der Zahlungen",
    prunp: "Geben Sie einen Zeitraum an",
    Année: "Jahr",
    community: "Gemeinschaft",
    thematic: "Wähle ein Thema",
    comment: "Kommentar",
    NumberOfCertifications: "Anzahl der Zertifikate",
    inscriptionTypePayment: "Typ d'inscription",
    registrationFees: "Anmeldegebühren",
    gratuit: "kostenlos",
    payant: "bezahlen",
    addQuestionForChapter: "Stellen Sie dem Lehrer eine Frage",
    yourQuestion: "Schreiben Sie Ihre Frage",
    addQuestionSuccess: "Ihre Frage wurde erfolgreich hinzugefügt",
    noQuestionFound: "Keine Fragen zu behandeln",
    noQuestionFound_4: "Keine Fragen beantwortet",
    noQuestionFound_5: "Sie haben keine Fragen.",
    paymentTitle: "Zahlung",
    paymentTitlePlural: "Zahlungen",
    paymentMethodCourses: "Zahlungsmethode des Kurses",
    choiceCurerncy: "Währung wählen",
    Questions: "Fragen",
    unsettledQuestion: "Zu behandelnde Probleme",
    settledQuestion: "Behandelte Probleme",
    allQuestion: "Alle Fragen",
    recent: "Der neuesten",
    oldest: "Am wenigsten aktuell",
    maxResponses: "Mit möglichst vielen Antworten",
    allSubjects: "Alle Kurse",
    allSubjects_2: "Alle Themen",
    noQuestionFound_2: "Ihre Suche ergab keine Übereinstimmung mit Fragen.",
    noQuestionFound_3: "Bitte suchen Sie erneut.",
    noCommentFound_2: "Ihre Suche ergab keine Kommentare",
    noPaymentInfo: "Sie müssen die Zahlungsmethode Ihrer Schule auswählen",
    NoPaymentSchoolConfig_1: "Um die Zahlungsmethode festzulegen.",
    NoPaymentSchoolConfig_2: "Klick hier",
    newResponses: "Neue Antwort(en)",
    SortsMostRecent: "Neueste",
    SortOldest: "Der älteste",
    allStatus: "Alle Zustände",
    WAITING: "Warten auf Bestätigung",
    SOON: "Kommen",
    ONGOING: "Im Gange",
    ENDED: "Beendet",
    REFUSED: "Sich weigern",
    allCourses: "Alle Kurse",
    NoWebinarHasBeenCreatedYetCreateYourFirstWebinarToGetStarted: "Es wurden noch keine Webinare erstellt. Erstellen Sie Ihr erstes Webinar, um loszulegen.",
    ForStudentsOf: "Für Ausbildungsstudenten",
    WebinarGivenBy: "Webinar gegeben von",
    ENTERTHEWebinar: "TEILNEHMEN SIE DAS Webinar",
    StartTHEWebinar: "Starten Sie DAS Webinar",
    AddToMyAgenda: "Zu meinem Kalender hinzufügen",
    validate: "Bestätigen",
    inMyAgenda: "in meinem Tagebuch",
    Edit: "Modifikator",
    REVIEWTHEwebinar: "Sehen Sie sich das Webinar an",
    IWantTheWebinarToBeAccessibleInReplayWithThePublicConcerned: "Ich möchte, dass das Webinar der betroffenen Öffentlichkeit als Wiederholung zugänglich ist",
    NewWebinar: "Neues Webinar",
    close: "NAH DRAN",
    Webinar: "Webinare",
    WebinarTitle: "Titel des Webinars",
    WebinarDate: "Verabreden Sie Ihr Webinar",
    WebinarSchedule: "Webinar-Zeitplan",
    ShortDescriptionOfTheWebinar: "Kurzbeschreibung des Webinars",
    Cancel: "abbrechen",
    WaitingForValidation: "Warten auf Bestätigung",
    ComingSoon: "In Kürze",
    LetsGo: "Lass uns gehen",
    ToReview: "Zur Überprüfung",
    inscriptionCourseFaq: "Melden Sie sich für einen Kurs an",
    followCourseFaq: "Nehmen Sie eine Klasse",
    certificationFaq: "Der Schulabschluss",
    professorsFaq: "Professoren",
    stopCourseFaq: "Unterbrechen Sie eine Unterrichtsstunde",
    paymentFaq: "Abrechnung",
    PAYED: "Bezahlt",
    PRIX: "Preis",
    openQuestion: "Offene Frage",
    Consignes: "Anweisungen",
    Valeurdelaquestion: "Wert der Frage",
    point: "Punkt",
    Nouveau_test: "Neue Prüfung",
    TEST_DE_CONNAISSANCE: "WISSENSTEST",
    Test_intermédiaire: "Zwischenbewertungstest",
    Non_éliminatoire: "Nicht eliminierend",
    Question_fermées: "Geschlossene Fragen",
    Test_de_fin_de_cours: "Ende des Kurses Test",
    Validant_le_diplome: "Validierung des Diploms",
    Question_fermée_et_ou_ouvertes: "Geschlossene und/oder offene Fragen",
    modal_exam_title: "Welche Art von Test möchten Sie erstellen?",
    AJOUTER_EXPLICATION: "EINE ERLÄUTERUNG HINZUFÜGEN",
    Explication: "Erläuterung",
    add: "Hinzufügen",
    Créer_votre_premiere_question: "Erstellen Sie Ihre erste Frage",
    Examen_final_envoyé_le: "Abschlussprüfung weitergeschickt",
    En_attende_de_correction_par_le_professeur: "Warten auf Korrektur durch den Lehrer",
    Répondre_aux_questions_fermees_ou_ouvertes_en_respectant_les_consignes: "Beantworten Sie geschlossene oder offene Fragen, indem Sie den Anweisungen folgen.",
    Le_test_seffectue_une_seule_fois_et_détermine_lobtention_de_votre_diplome: "Der Test wird nur einmal durchgeführt und",
    Le_test_seffectue_une_seule_fois_et_détermine_lobtention_de_votre_diplome_2: "entscheidet über deinen Abschluss.",
    Réuissi: "Erfolgreich",
    insuffisant: "unzureichend",
    Valider_ma_réponse: "Bestätige meine Antwort",
    Rendu_le: "Geliefert am",
    Par: "um",
    NOTER_LA_QUESTION: "ACHTUNG FRAGE",
    Examens_à_noter: "Prüfungen zu beachten",
    Examen_notés: "Benotete Prüfungen",
    Tous_les_examens: "Alle Prüfungen",
    aucun_examen_à_noter: "Keine Prüfung zu benoten",
    NOTE: "HINWEIS",
    à_noter: "notieren",
    Commentaire_du_professeur: "Kommentar des Lehrers",
    Pas_de_commentaire: "Kein Kommentar",
    Ajouter_un_commentaire: "Einen Kommentar hinzufügen",
    VALIDER_MA_NOTATION: "MEINE BEWERTUNG BESTÄTIGEN",
    EXAMEN_FINAL: "ABSCHLUSSPRÜFUNG",
    exam1: "Geben Sie hier den Titel der Abschlussprüfung an ...",
    exam2: "Geben Sie hier den Titel des Wissenstests an",
    votreMessage: "Schreibe deine Nachricht...",
    noMessage: "Keine Nachricht",
    noMessageDispo: "Noch nicht verfügbar...",
    message_1: "Sie müssen auf den Lehrer warten",
    message_2: "Eröffnen Sie das Gespräch",
    message_1_prof: "Beginnen Sie eine Konversation, indem Sie tippen",
    message_2_prof: "Ihre Nachricht unten.",
    textFaq: "Was ist, wenn ich mehr über die Benotung meiner Hausarbeit wissen möchte?",
    draftstatdateFollow: "Fall begann am",
    finishdraftFollowButton: "Beende meinen Fall",
    gotocourseStudent: "Greifen Sie auf den Kurs zu",
    myFormations: "Meine Ausbildungen",
    SelectCourse: "Kurs auswählen",
    NoWebinarHasBeenCreatedYet: "Es wurden noch keine Webinare erstellt.",
    TitleFormations: "Formationen",
    InscriptionDate: "Registrieren Sie sich auf",
    biography: "Biografie",
    labelMailProfessor: "Lehrer-E-Mail",
    comments: "Kommentare",
    noStudentInscribed: "Es ist noch kein Student angemeldet",
    demandTable: "Anfrage gestellt am",
    createNewFormation: "Neue Formation",
    selectFormatFormation: "Wähle eine größe",
    pathTitle: "Reise",
    createPath: "eine Strecke erstellen",
    sessionTitle: "Sitzung",
    createSession: "eine Sitzung erstellen",
    requiredLevelText: "Erforderliches Niveau, um sich für den Kurs anzumelden",
    anyLevelCourse: "Alle Ebenen",
    firstLevel: "Anfänger",
    secondLevel: "Dazwischenliegend",
    thirdLevel: "Experte",
    ANYLEVEL: "Alle Ebenen",
    BEGINNER: "Anfänger",
    courseDescriptionForm: "Beschreiben Sie Ihren Studiengang",
    descriptionTitle: "Beschreibung",
    courseContentTitle: "Kursinhalt",
    pathContentTitle: "Kursinhalt",
    sessionContentTitle: "Sitzungsinhalt",
    importVideoCourse: "Videoimport",
    createContentText: "Erstellen Sie Ihre ersten Inhalte",
    chapterPresentation: "Vorstellung des Kapitels",
    textPresentationChapter: "Einleitungstext des Kapitels",
    groupes: "Gruppen",
    newGroup: "Neue Gruppe",
    groupTitle: "Titel der Schülergruppe",
    newTestModalTitle: "Neue Prüfung",
    alreadyCreateExam: "Sie haben bereits eine Abschlussprüfung erstellt",
    finalExmBreadcrunbTitle: "Abschlussprüfung",
    noGroupFound: "Keine Studentengruppe erstellt",
    noGroupFound_2: "Ihre Suche entspricht keiner Gruppe",
    updateGroupTitle: "Titel bearbeiten",
    deleteGroup: "Gruppe löschen",
    updateGroup: "Gruppentitel ändern",
    addSubGroup: "Fügen Sie eine Untergruppe hinzu",
    addStudentToGroup: "Schüler hinzufügen",
    Actions: "Aktionen",
    textConnaissance: "Wissenstest",
    points: "Punkte",
    connectedThereIs: "vorhin eingeloggt",
    messageDeleteGroup_1: "Diese Gruppe wird endgültig gelöscht.",
    messageDeleteGroup_2: "Sie haben nicht die Möglichkeit, diese Gruppe wiederherzustellen, wenn Sie Ihre Meinung ändern",
    messageDeleteGroup_3: "Gruppe erfolgreich gelöscht",
    closedQuestion: "Geschlossene Fragen",
    pointsQuestion: "Punkt(e)",
    explicationText: "Schreibe deine Erklärung...",
    allStudent: "Alle Schüler",
    subGroup: "Untergruppe",
    changeGroup: "Gruppe wechseln",
    addToGroup: "Zur Gruppe hinzufügen",
    deleteFromGroup: "Aus der Gruppe entfernen",
    documentTextForm: "Dokumentieren",
    returnToCourse: "zurück in die Klasse",
    returnToPathButton: "zurück zum Kurs",
    returnToSessionButton: "zurück zur Sitzung",
    sendPublishCoursePRofTitle: "An den Direktor senden, um die Veröffentlichung zu validieren",
    returnToStudentList: "Zurück zur Schülerliste",
    Previsualiser: "Vorschau",
    editeMode: "Bearbeiten",
    withInscription: "Registrierung unter Datei",
    freeTitleCourse: "angeboten",
    questionPlaceholder: "Deine Frage hier...",
    responsePlceholder: "Schreiben Sie hier eine Antwort..",
    explicationPlaceholder: "Schreiben Sie hier eine Erklärung ...",
    consignesPlaceholder: "Deine Anleitung hier...",
    goToCatalogueError: "Um zur vorherigen Seite zurückzukehren, klicken Sie auf die Schaltfläche unten.",
    errorCode: "Fehlercode",
    oupsWord: "Hoppla!",
    errorInfoNotFound: "Entschuldigung, die von Ihnen gesuchte Seite kann nicht gefunden werden.",
    errorInfoUnauthorized: "Keine Berechtigungen gefunden.",
    errorSubinfoUnauthorized: "Diese Seite ist nicht öffentlich zugänglich.",
    errorInfoAccessRefused: "Zugriff abgelehnt.",
    errorSubInfoAccessRefused: "Sie sind nicht autorisiert auf diese Seite zuzugreifen.",
    serverProblem: "Serverproblem",
    tryLater: "Bitte versuchen Sie es in ein paar Minuten.",
    unexpectedError: "Entschuldigung, unerwarteter Fehler.",
    errorInfoServerProblem: "Die von Ihnen gesuchte Seite wird derzeit gewartet und ist in Kürze wieder verfügbar.",
    continueCreatingPath: "Sie können diese Route leider nicht veröffentlichen.",
    continueCreatingSession: "Sie können diese Sitzung leider nicht veröffentlichen.",
    returnToPath: "zurück zum Kurs",
    returnToSession: "zurück zur Sitzung",
    courseDesactivationSuccess: "Kurs erfolgreich deaktiviert",
    pathDesactivationSuccess: "Kurs erfolgreich deaktiviert",
    sessionDesactivationSuccess: "Sitzung erfolgreich deaktiviert",
    textDeactivateCourse: "Kurs deaktivieren",
    textDeactivatePath: "Kurs deaktivieren",
    textDeactivateSession: "Sitzung deaktivieren",
    textreactiovationCourse: "Kurs erfolgreich aktiviert",
    textreactiovationPath: "Route erfolgreich aktiviert",
    textreactiovationSession: "Sitzung erfolgreich aktiviert",
    text_1_desactivateCourse: "Dieser Kurs ist nicht mehr zugänglich.",
    text_2_desactivateCourse: "Sie haben die Möglichkeit, den Kurs zu reaktivieren, wenn Sie Ihre Meinung ändern",
    text_1_desactivatePath: "Diese Route wird nicht mehr zugänglich sein.",
    text_2_desactivatePath: "Sie haben die Möglichkeit, den Kurs zu reaktivieren, wenn Sie Ihre Meinung ändern",
    text_1_desactivateSession: "Auf diese Sitzung kann nicht mehr zugegriffen werden.",
    text_2_desactivateSession: "Sie haben die Möglichkeit, die Sitzung erneut zu aktivieren, wenn Sie Ihre Meinung ändern",
    refuseCourseText: "Verweigern Sie die Veröffentlichung des Kurses",
    refusePathText: "Verweigern Sie die Veröffentlichung des Kurses",
    refuseSessionText: "Sitzungsveröffentlichung ablehnen",
    backToListCourses: "zurück zur Kursliste",
    textRefusedCourseSuccess: "Kurs erfolgreich abgelehnt",
    textRefusedPathSuccess: "Pfad erfolgreich abgelehnt",
    textRefusedSessionSuccess: "Sitzung erfolgreich abgelehnt",
    acceptCourseText: "Bestätigen Sie die Veröffentlichung des Kurses",
    acceptPathText: "Bestätigen Sie die Veröffentlichung des Kurses",
    acceptSessionText: "Bestätigen Sie die Veröffentlichung der Sitzung",
    textAcceptCourse: "Dieser Kurs wird für alle Studierenden zugänglich sein.",
    textAcceptPath: "Dieser Kurs wird für alle Studierenden zugänglich sein.",
    textAcceptSession: "Diese Sitzung wird für alle Studenten zugänglich sein.",
    publishCourseSuccessModal: "Kurs erfolgreich veröffentlicht",
    publishPathSuccessModal: "Kurs erfolgreich veröffentlicht",
    publishSessionSuccessModal: "Sitzung erfolgreich veröffentlicht",
    errorContentLength: "Sie müssen mindestens einen Inhalt haben (erforderlich).",
    errorPathLinkImg: "Visual zur Veranschaulichung des Kurses (obligatorisch).",
    errorSessionLinkImg: "Visual zur Veranschaulichung der Sitzung (obligatorisch).",
    errorPathDescription: "Textdarstellung des Kurses (obligatorisch).",
    errorSessionDescription: "Textpräsentation der Sitzung (obligatorisch).",
    typeTable: "Typ",
    parcoursTitle: "Reise",
    PATH: "Reise",
    progress: "Förderung",
    gotopathStudent: "Greifen Sie auf den Kurs zu",
    gotosessionStudent: "Greifen Sie auf die Sitzung zu",
    newContent: "neuer Inhalt",
    chapterTitleBegin: "Titel des Kapitels",
    testTitleBegin: "Titel des Wissenstests",
    examTitleBegin: "Prüfungstitel",
    onlineFormations: "Onlinetraining",
    validateFormations: "Zu validierende Ausbildung",
    allFormations: "Alle Kurse",
    inscribedTimeStudent: "registriert auf",
    suspendedAt: "Angehalten",
    newFinalExam: "Fügen Sie eine Abschlussprüfung hinzu",
    backToMyrequest: "Zurück zu meiner Anfrage",
    beforeUploadText: "Ziehen Sie die Dateien, die Sie freigeben möchten, per Drag-and-Drop",
    uploadFile: "WÄHLEN SIE EINE DATEI",
    Subscription: "Abonnementanfrage",
    waiting: "Ausstehend",
    fileValidated: "Datei validiert",
    fileRefused: "Datei abgelehnt",
    autorisationTraining: "Berechtigungen",
    autorisationTrainingTable: "Genehmigung",
    autorzedStatus: "Autorisiert",
    unauthorized: "Unbefugt",
    groupNameTable: "Name der Gruppe",
    changeMyDecision: "Ändere meine Entscheidung",
    backtodemand: "auf Nachfrage zurück",
    cancelValidation: "Validierung abbrechen",
    downloadFileCertif: "Laden Sie mein Diplom herunter",
    nextChapterText: "Nächstes Kapitel",
    getMyDiplome: "Mein Diplom",
    ListAllCourses: "Alle Kurse auflisten",
    ListOnlineCourses: "Liste der Online-Kurse",
    ListPendingCourses: "Liste der anstehenden Kurse",
    ListStudents: "Liste der Studenten",
    courseBy: "Kurs gegeben von",
    SessionGivenBy: "Sitzung gegeben von",
    PathGivenBy: "Kurs gegeben von",
    PaymentDetails: "Zahlungsdetails",
    Completeyourpurchasebyprovidingyourpaymentinformation: "Schließen Sie Ihren Kauf ab, indem Sie Ihre Zahlungsinformationen angeben.",
    LastNameandFirstName: "Vollständiger Name",
    CreditCardNumber: "Kreditkartennummer",
    ExpirationDate: "Ablaufdatum",
    confirmandpay: "bestätigen und bezahlen",
    Yourpaymentwassuccessful: "Ihre Zahlung wurde erfolgreich durchgeführt.",
    StartThePath: "die Reise beginnen",
    Yourpaymenthasbeendeclined: "Ihre Zahlung wurde abgelehnt",
    StartTheCourse: "Kurs starten",
    StartTheSession: "Sitzung starten",
    selectLevelCourse_4: "Alle Ebenen",
    progressTable: "Förderung",
    notationLabel: "Notation",
    singleClosedQuestion: "Frage geschlossen",
    Du: "Aus",
    Au: "Bei",
    Examens: "Prüfungen",
    fromWhereTraining: "Kommt von",
    congratsTraining: "Herzlichen Glückwunsch, Sie haben den Kurs abgeschlossen.",
    donnerAvis_3: "Geben Sie Ihre Meinung zum Kurs ab:",
    donnerAvis_4: "Geben Sie Ihre Meinung zur Sitzung ab:",
    donnerAvisButton: "Sagen Sie ihre Meinung",
    InitialPathName: "Kursname",
    PathDecriptionCreation: "Beschreiben Sie Ihren Hintergrund",
    prerequisite1: "Voraussetzungen, um diesem Kurs zu folgen",
    skillsNeeded: "Kenntnisse und Fähigkeiten, die durch diesen Kurs erworben wurden",
    pathCoverPhot: "Ziehen Sie ein Foto per Drag-and-Drop, um den Kurs zu veranschaulichen (jpg, png)",
    pathVideoDescription: "Videopräsentation des Kurses",
    pathVideoDrop: "Ziehen Sie das Kursvideo, das Sie hochladen möchten, per Drag & Drop (mp4, mov)",
    PathLevel: "Erforderliches Niveau, um sich für den Kurs anzumelden",
    SessionTitleCreation: "Sitzungstitel",
    SessionLevel: "Erforderliches Niveau, um sich für die Sitzung anzumelden",
    SessionDescriptionCreation: "Beschreiben Sie Ihre Sitzung",
    prerequisite2: "Voraussetzungen, um dieser Sitzung zu folgen",
    SessionSkills: "Kenntnisse und Fähigkeiten, die während dieser Sitzung erworben wurden",
    SessionCoverDescription: "Visual zur Veranschaulichung der Sitzung",
    SessionCoverDrop: "Ziehen Sie ein Foto per Drag-and-Drop, um die Sitzung zu veranschaulichen (jpg, png)",
    SessionDescriptionVideo: "Videopräsentation der Sitzung",
    DragDropSession: "Ziehen Sie das Sitzungsvideo, das Sie hochladen möchten, per Drag-and-Drop (mp4, mov)",
    saveSchoolLogo: "Bild hinzufügen",
    descriptionChoiseCours: "Erstellen Sie Ihren Kurs mit seinen Kapiteln, Tests und der Abschlussprüfung. Richten Sie Ihre Zulassungsakte ein",
    descriptionChoisePath: "Stellen Sie Ihre Kurse und Lehrgänge zusammen und erstellen Sie einen Lehrgang mit den bestandenen Bedingungen",
    descriptionChoiseSession: "Stellen Sie sich Ihr Training mit den zeitlich festgelegten Durchgangsbedingungen zusammen",
    returnButton: "kippen",
    waitingToPayment: "Warten auf Zahlung",
    noUnpaidFound: "Keine Zahlungserwartung",
    schoolAssistant: "Administrator",
    passwordMandadory1: "Das Passwort muss enthalten",
    passwordMandadory2: "Mindestens 8 Zeichen",
    passwordMandadory3: "Mindestens ein Großbuchstabe und ein Kleinbuchstabe",
    passwordMandadory4: "Mindestens 1 Ziffer",
    passwordMandadory5: "Mindestens ein Sonderzeichen (#?!=@$%^&*-)",
    passConfirmation: "Passwort Bestätigung",
    RésultatCumulé: "Kumulatives Ergebnis",
    etudiantsInscrits: "Eingeschriebene Studierende",
    TarifActuel: "Derzeitiger Preis",
    TarifMoyen: "Durchschnittlicher Preis",
    priceHistory: "Verlauf bewerten",
    hidePricehistory: "Kursverlauf ausblenden",
    saveModification: "Änderung gespeichert!",
    Total: "Gesamt",
    TotalInscrits: "Insgesamt registriert",
    periodMedian: "Durchschnittspreis für den Zeitraum",
    totalMedian: "Gesamtdurchschnittspreis",
    totalResult: "Totales Schlupfloch",
    QUESTIONS_OUVERTES: "OFFENE FRAGEN",
    notValid: "Ungültig",
    validAction: "Bestätigte Aktion",
    studentResponse: "Schülerantwort",
    markUpdate: "Modifikator ma Notation",
    webinarEnded: "Webinar beendet",
    added: "Hinzugefügt",
    warning: "Aufmerksamkeit !",
    changePrice: "Sie haben gerade den Kurspreis geändert:",
    priceNow: "Derzeitiger Preis:",
    priceAfter: "Neuer betroffener Preis:",
    saveUpdate: "ÄNDERUNGEN SPEICHERN",
    profchoice: "Wählen Sie einen Lehrer aus, dem Sie die Kurse zuweisen möchten",
    deleteReason: "Grund für die Streichung*",
    disableAssistant: "Dieser Administrator kann nicht mehr auf die Plattform zugreifen. Sie haben die Möglichkeit, es wieder einzusetzen, wenn Sie Ihre Meinung ändern.",
    addCommentToAssistant: "Fügen Sie einen Kommentar für den Administratoren hinzu",
    connectAccountPaymee: "Verbinden Sie Ihr Paymee-Konto",
    connect: "VERBINDEN",
    idPaymee: "ID Zahlungsempfänger",
    assistantInvitation: "Laden Sie einen Administratoren zur Registrierung ein",
    mailAssistant: "E-Mail-Adresse des Administratoren",
    membreInvitation: "Laden Sie ein Mitglied ein",
    role: "Rolle",
    newCourse: "Neuer Kurs",
    newPath: "Neuer Kurs",
    newSession: "Neue Sitzung",
    profMandadotory: "Dieser Kurs muss einem Lehrer zugeordnet werden.",
    profMandadtory2: "Wählen Sie einen Lehrer aus, dem Sie diesen Kurs zuweisen möchten*",
    rehireAssistant: "Dieser Administrator kann auf die Plattform zugreifen. Sie haben die Möglichkeit, den Administratoren zu sperren, wenn Sie Ihre Meinung ändern.",
    dragToUpload: "Ziehen Sie Ihr Foto per Drag-and-Drop",
    or: "ODER",
    selectPhoto: "Wählen Sie Ihr Foto aus",
    offerList: "Angebotsliste",
    answer: "Ihre Antwort",
    completedParts: "Teile fertig",
    followPaths: "Kurs folgen",
    followSessions: "Kurs folgen",
    displayVideo: "Ihr Präsentationsvideo oder -bild",
    toValidate: "Bestätigen",
    archiveSheet: "Archiv",
    connectZoom: "Zoom verbinden",
    accountZoom: "Zoom-Konto",
    connectAccount: "Verbinde mein Konto",
    connectAccountToschool: "Verbinden Sie Ihr Zoom-Konto mit Ihrer Schule",
    changeOffer: "Angebot ändern",
    facture: "Rechnungen",
    paymentMethod: "Zahlungsarten",
    cancelSubscription: "Mein Abonnement kündigen",
    offerPrice: "Angebotsgebühren",
    nextDeadline: "Nächstes Fälligkeitsdatum der Zahlung",
    deleteOffer: "Löschung des Angebots",
    deleteMsgOffer: "Möchten Sie Ihr Angebot löschen?",
    passwordUpdated: "Ihr Passwort wurde geändert",
    passwordNoMatch: "Die zwei Passwörter stimmen nicht überein",
    modifyProfil: "Mein Profil bearbeiten",
    newEmail: "neue Email Adresse",
    connectStripToSchool: "Verbinden Sie Ihr Stripe-Konto mit Ihrer Schule",
    disconnect: "Trennen",
    connectPaymeeToSchool: "Verbinden Sie Ihr Paymee-Konto mit Ihrer Schule",
    paymentMessage: "Schließen Sie Ihren Kauf ab, indem Sie Ihre Zahlungsinformationen angeben.",
    paymentConfimation: "bestätige Zahlung",
    activate: "Ermöglichen",
    month: "Monatlich",
    yearly: "Jahrbücher",
    offerMessage1: "Alles, was im Basisplan enthalten ist, plus:",
    Choisir: "Aussuchen",
    compareoffers: "Vergleichen Sie alle Funktionen",
    offer: "Angebot",
    monthlyFor: "Monatlich für",
    yearFor: "Jahrbücher für",
    totalPrice: "Gesamtpreis:",
    valid: "Gültig",
    ProfList: "Prof. Liste",
    StudentList: "Schülerliste",
    CourseList: "Kursliste",
    Inscrits: "Eingetragen",
    team: "Besatzung",
    ADMINSTRATOR: "Administrator",
    info: "Information",
    shortDescription: "kurze Beschreibung",
    shortDescription1: "Kurze Beschreibung von",
    nbrConx: "Nummer der Verbindung",
    inscDate: "Datum der Inschrift",
    role1: "Rolle",
    totalRevenu: "Gesamteinkünfte",
    makeAPayment: "Eine Zahlung tätigen",
    Fileacceptedon: "Datei angenommen am",
    params: "Einstellungen",
    pricipalGroup: "Hauptgruppe",
    addPhoto: "füge dein Foto hinzu",
    changePhoto: "Ändere dein Foto",
    deletePhoto: "Foto löschen",
    seletionner: "AUSWÄHLEN",
    facturationDetails: "Abrechnungsdaten",
    closedSchool: "Diese Schule ist derzeit geschlossen",
    hold: "Bitte",
    contact1: "Kontaktieren Sie Support",
    contact2: "für mehr Informationen.",
    question: "Frage",
    allTeam: "Das ganze Team",
    assistants: "Administratoren",
    noTeam1: "Kein Lehrer/Administrator gefunden.",
    noTeam2: "Bitte suchen Sie erneut.",
    noTeamDefault_1: "Es ist kein Teammitglied registriert.",
    noTeamDefault_2: "Laden Sie ein Mitglied ein, sich anzumelden, um loszulegen.",
    noAssistant_1: "Keine Administratoren gefunden.",
    noAssistant_2: "Bitte suchen Sie erneut.",
    noAssistantDefault_1: "Es ist kein Administrator registriert.",
    noAssistantDefault_2: "Laden Sie einen Administratoren ein, sich anzumelden, um loszulegen.",
    TrainingToedit: "Im Gange",
    webinarForm1: "Wählen Sie einen Lehrer aus, dem Sie dieses Webinar zuweisen möchten*",
    webinarForm2: "Wählen Sie einen Lehrer aus",
    webinarForm3: "Für Kursteilnehmer:",
    listeDesAssistants: "Liste der Administratoren",
    listeTeam: "Mannschaftsliste",
    listOfUnpaidStudents: "Liste der unbezahlten Studenten",
    Nounpaidstudent: "Kein unbezahlter Student",
    memberMail: "Mitglieds-E-Mail",
    integrations: "Integrationen",
    monForfait: "Mein Paket",
    Facturation: "Abrechnung",
    Detailsduforfait: "Paketdetails",
    Gerezoumodifiezvotreforfait: "Verwalten oder ändern Sie Ihren Plan",
    Votreoffre: "Ihr Angebot",
    Fraisdeloffre: "Angebotsgebühren",
    Prochainedatedecheancedupaiement: "Nächstes Fälligkeitsdatum der Zahlung",
    Modedepayement: "Zahlungsmethode",
    Changerleforfait: "Plan ändern",
    Annulermonabonnement: "Mein Abonnement kündigen",
    Limitationduforfait: "Paketbeschränkung",
    Suivrelalimitationdevotreforfaitactuel: "Verfolgen Sie die Drosselung Ihres aktuellen Plans",
    Voirlesforfaits: "Siehe Pakete",
    Vousavezatteintlalimitedaccesdevos: "Sie haben das Zugriffslimit Ihres erreicht",
    Vouspouvezajouterdes: "Du kannst hinzufügen",
    endesactivantun: "durch Deaktivieren von a",
    endesactivantune: "durch Deaktivieren von a",
    actifouenameliorantvotreforfait: " durch ein Upgrade Ihres Plans",
    activeouenameliorantvotreforfait: "durch ein Upgrade Ihres Plans",
    Modesdepayement: "Zahlungsarten",
    Gérezoumodifiezvotremodesdepaiement: "Verwalten oder ändern Sie Ihre Zahlungsmethoden",
    congratsSession: "Herzlichen Glückwunsch, Sie haben die Sitzung abgeschlossen.",
    avisTitle1: "Meine Meinung zum Kurs",
    avisTitle2: "Meine Meinung zur Session",
    FormationAValider: "Keine Schulung zur Validierung.",
    FormationEnLigne: "Kein Online-Training.",
    emptyListFormation1: "Bitte suchen Sie erneut.",
    noTrainingSearch: "Dieser Lehrer hat noch keine Ausbildung.",
    NoWebinarToValidate: "Kein zu validierendes Webinar.",
    NoWebinarIsComingSoon: "Keine bevorstehenden Webinare.",
    NoWebinarIsOnGoing: "Keine aktuellen Webinare.",
    NoWebinarHasEnded: "Keine abgeschlossenen Webinare.",
    NoWebinarIsRefused: "Keine Webinare abgelehnt.",
    NoWebinarIsAssociatedToThisCourse: "Mit diesem Kurs ist kein Webinar verbunden.",
    NoWebinarExists: "Keine Webinare gefunden.",
    FirstWebinar: "Es wurden noch keine Webinare erstellt.",
    FirstWebinar2: "Erstellen Sie Ihr erstes Webinar, um loszulegen.",
    transformKnowledge: "Verwandeln Sie Ihr Wissen in Reichtum",
    addToDescription: "Zur Beschreibung hinzufügen",
    importListOfStudents: "Importieren Sie die Schülerliste.*",
    Remplacer: "Ersetzen",
    returnToStudentsList: "Zurück zur Schülerliste",
    invite: "einladen",
    continue: "Fortsetzen",
    savedUsers: "Ich habe die Zustimmung der in dieser Datei registrierten Benutzer gemäß dem geltenden Datenschutzgesetz des Landes.",
    download: "Download",
    loadInformation: "Ihre Informationen werden geladen, bitte warten...",
    yourAccount: "Von Ihrem Konto",
    emailSend: "E-Mail gesendet",
    check: "überprüfen",
    verifyYourEmail: "Überprüfen Sie Ihre E-Mail und öffnen Sie den Link, den Sie erhalten haben, um fortzufahren",
    returnToConnexionPage: "zurück zur Anmeldeseite",
    save: "Zur Anmeldung",
    coursResume: "Schreiben Sie die Zusammenfassung dieses Kurses, präsentieren Sie die wichtigsten Punkte und zeigen Sie den Mehrwert für das Mitglied auf.",
    coursObjectives: "Nennen Sie die Ziele, die während dieses Kurses erreicht werden sollen.",
    preRequisite: "Fügen Sie die erforderlichen Voraussetzungen hinzu, um an diesem Kurs teilzunehmen.",
    skillsToLearn: "Führen Sie die in diesem Kurs zu erwerbenden Fähigkeiten in Form von Schlüsselwörtern auf.",
    pathResume: "Schreiben Sie eine Zusammenfassung dieses Kurses, präsentieren Sie die wichtigsten Punkte und zeigen Sie den Mehrwert für das Mitglied auf.",
    pathObjectives: "Nennen Sie die Ziele, die während dieses Kurses erreicht werden sollen.",
    pathPreRequisite: "Fügen Sie die erforderlichen Voraussetzungen hinzu, um diesem Pfad zu folgen.",
    pathSkillsToLearn: "Führen Sie die in diesem Kurs zu erwerbenden Fähigkeiten in Form von Schlüsselwörtern auf.",
    sessionResume: "Schreiben Sie die Zusammenfassung dieser Sitzung, präsentieren Sie die wichtigsten Punkte und zeigen Sie den Mehrwert für das Mitglied auf.",
    sessionObjectives: "Listen Sie die Ziele auf, die während dieser Sitzung erreicht werden sollen.",
    sessionPreRequisite: "Fügen Sie die erforderlichen Voraussetzungen hinzu, um dieser Sitzung zu folgen.",
    sessionSkillsToLearn: "Führen Sie die Fähigkeiten, die während dieser Sitzung erworben werden sollen, in Form von Schlüsselwörtern auf.",
    timeNotAvailable: "Dieser Zeitplan ist nicht verfügbar, bitte wählen Sie einen anderen aus.",
    annulerImportation: "Import abbrechen",
    annulerImportationConfirmation: "Möchten Sie den Import wirklich abbrechen?.",
    retourImportation: "Zurück zum Importieren",
    failedRequest: "Anfrage fehlgeschlagen",
    lowerDeal: "Um zu einem niedrigeren Plan zu wechseln, müssen Sie Ihr Limit entsprechend den Limits des gewählten Plans anpassen.",
    activeProfessors: "Aktive Lehrer",
    activeStudents: "Aktive Studenten",
    activeAssistants: "Aktive Administratoren",
    professorList: "Liste der Lehrer",
    trainingList: "Liste der Kurse",
    setAsPrincipal: "Als Haupt festlegen?",
    replacePaymentMode: "Dadurch wird Ihre derzeitige primäre Zahlungsmethode ersetzt.",
    deletePaymentMethod: "Zahlungsmethode löschen",
    deletePaymentMethodConfirmation: "Möchten Sie die Zahlungsmethode wirklich löschen?",
    deleteInvitation: "Einladung abbrechen",
    invitationAnnulationOfYourSchool: "Wenn Sie die Einladung stornieren, kann diese Person nicht mehr Mitglied von werden",
    yourSchool: "deine Schule.",
    youCanReinvite: "Du kannst ihn jederzeit wieder einladen.",
    keepInvitation: "Einladung behalten",
    noCourseFound: "Keine Kurse gefunden",
    wantCreateCourse: "Möchten Sie einen Kurs erstellen?",
    cannotImportListOfStudents: "Sie können keine Schülerliste importieren, die Fehler enthält.",
    firstName: "Vorname",
    obligatoire: "ist obligatorisch",
    name: "Der Name",
    emailAddressNotValid: "Ungültige E-Mail-Adresse.",
    emailFormat: "Das Format der E-Mail muss sein",
    emailExample: "votrenom@exemple.com",
    importationResult: "Ergebnis importieren",
    succesfulImportation: "erfolgreiche Importe",
    lignes: "Linien",
    containErrors: "Fehler enthalten",
    addStudent: "Fügen Sie einen Schüler hinzu",
    importList: "Importieren Sie eine Liste",
    invitationSentSuccesfully: "Einladung erfolgreich versendet",
    invitationSuccessAssistant: "Ihre Einladung wurde erfolgreich an den Administratoren gesendet.",
    userAlreadyExists: "Der Benutzer mit dieser E-Mail existiert bereits.",
    enterValidEmail: "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
    professorLimitAtteined: "Sie haben das Zugriffslimit Ihrer Lehrer erreicht. Sie können Lehrer hinzufügen, indem Sie einen aktiven Lehrer deaktivieren oder Ihren Plan aktualisieren.",
    assistantLimitAtteined: "Sie haben das Zugriffslimit für Ihre Administratoren erreicht. Sie können Administratoren hinzufügen, indem Sie einen aktiven Administratoren deaktivieren oder Ihren Plan aktualisieren.",
    compareOffers: "Angebote vergleichen",
    schoolUses: "Ihre Schule verwendet Funktionen",
    notIncludedInOffer: "die vom Angebot nicht unterstützt werden",
    passToOffer: "Wenn Sie zum Angebot wechseln",
    allFonctionalities: ", alle Features",
    areDesactivated: "Ihrer Schule deaktiviert und unzugänglich.",
    invitationsSentSuccesfully: "Einladungen wurden erfolgreich versendet",
    automaticSendOfInvitations: "Automatischer Versand von Einladungen",
    invitationSendConfirmation: "Bestätigen Sie das Senden von Einladungen an die importierte Liste?",
    confirmAndSend: "Bestätigen und senden",
    principal: "Rektor",
    noPaymentMethodAdded: "Keine Zahlungsmethode hinzugefügt",
    addPaymentMethod: "Zahlungsmethode hinzufügen",
    remainning: "hast du noch",
    changePaymentMethod: "Bezahlungsmethode ändern",
    leaveMsg: "Wir bedauern, dich gehen zu sehen!",
    annulationWarning: "Nach der Kündigung bleibt Ihr Konto aktiv, bis Ihre Abrechnung am endet",
    annulationWarning2: "Danach verlieren Sie den Zugang zu Ihrer Schule.",
    annulationConfirmation: "Möchten Sie Ihr Abonnement wirklich kündigen?",
    enterPasswordtoConfirm: "Geben Sie zur Bestätigung Ihr Passwort ein",
    cancelationReasons: "Bitte teilen Sie uns mit, warum Sie Ihr Abonnement kündigen. Ihre Kommentare helfen uns, das Produkt zu verbessern",
    whyuCanceling: "Warum kündigen Sie Ihr Abonnement?",
    selectLowerCase: "Auswählen",
    missingFonctionality: "Eine gewünschte Funktion fehlt",
    expensive: "Ich denke, es ist zu teuer",
    notInteressted: "Je n'utilise plus Fieber",
    movingToAnotherPlatform: "Ich wechsle zu einer anderen Plattform",
    preciser: "Bitte angeben",
    wantToTellUsMore: "Möchten Sie uns mehr erzählen?",
    unlimited: "Unbegrenzt",
    Cryptogramme: "Kryptogramm",
    savePaymentMethod: "Speichern Sie diese Zahlungsmethode in meinem Profil",
    paymentPassed: "Zahlung erfolgt",
    paymentSuccesfull: "Ihre Zahlung wurde erfolgreich verarbeitet.",
    returnToActivity: "Zurück zur Aktivität",
    twoFreeMonths: "2 Monate kostenlos",
    fourFreeMonths:"4 Monate kostenlos",
    essentialTools: "Die wesentlichen Tools für den Einstieg, ohne versteckte Kosten",
    forever: "für immer",
    actuelOffer: "Ihr aktuelles Angebot",
    selectFreeOffer: "Wählen Sie das kostenlose Angebot",
    pathAndSessions: "Routen und Sitzungen",
    forProfessionalsAndNonProfessionals: "Für professionelle Trainer und Infopreneurs.",
    yearlyBill: "Jährlich abgerechnet",
    perMonth: "pro Monat",
    selectProOffer: "Wählen Sie das Pro-Angebot",
    courses: "Klassen",
    illimitedAccess: "Um unbegrenzt Koors zu genießen.",
    perStudent: "pro Schüler",
    minimum: "Minimum",
    selectBussinessOffer: "Wählen Sie das Business-Angebot",
    illimitedNumberOfStudents: "Unbegrenzte Anzahl von Studenten",
    illimitedNumberOfCours: "Unbegrenzte Anzahl an Lektionen",
    illimitedNumberOfProfessors: "Unbegrenzte Anzahl von Lehrern",
    illimitedNumberOfAssistants: "Unbegrenzte Anzahl von Administratoren",
    subscriptionPayments: "Abonnementzahlung",
    Devise: "Entwickeln",
    FacturationCycle: "Rechnungskreislauf",
    monthly: "Monatlich",
    yearly_2: "Jährlich",
    ofReduction: "die Ermäßigung",
    minimalBill: "Mindestabrechnung",
    nextBill: "Nächste Abrechnung",
    confirmPayment: "Zahlungsbestätigung",
    reductionOnCurrentPlan: "Ermäßigung auf den Jahresplan",
    verification: "Überprüfung",
    noStudentFound_2: "Keine Schüler gefunden",
    archivedTrainings: "Keine Schulung archiviert",
    refusedTrainings: "Kein Training abgelehnt",
    desactivatedTrainings: "Kein Training deaktiviert",
    editedTrainings: "Es wird kein Training bearbeitet",
    noTrainingHaveBeenCreated: "Es wurden noch keine Käse erstellt.",
    createFirstTraining: "Erstellen Sie zunächst Ihre erste Formation.",
    examsToNote: "Keine Prüfungen zu benoten.",
    notedExams: "Keine benotete Prüfung.",
    noExam: "Sie haben keine Prüfung.",
    resendInvitation: "Einladung erneut versenden",
    Copyright: "Urheberrechte ©",
    rights: "Alle Rechte vorbehalten.",
    noExamFound: "Keine Bewertungen gefunden.",
    atteinedMaximum: "Sie haben die maximal zulässige Anzahl für einen Plan erreicht",
    updatePlan: "Bitte aktualisieren Sie Ihren Plan, um weitere Formationen zu erstellen.",
    noBill: "Keine Rechnung.",
    finalExam: "Abschlussprüfung .",
    selectAnImage:"WÄHLEN SIE EIN BILD",
    slideToAddAnImage:"Ziehen Sie ein Foto per Drag-and-Drop, um das Webinar zu veranschaulichen",
    yourWebinarsAreIntegrated:"Ihre Webinare sind integriert",
    onThePlatform:"zur Plattform mit der",
    offreProAndBussiness:"Professional- und Business-Angebote",
    createWebinar:"Erstellen Sie Ihre Webinare einfach direkt in Koors Select und laden Sie Ihre Schüler direkt zur Teilnahme ein (berechtigte neue Schüler werden automatisch eingeladen).",
    findReplayAndPublishWebinar:"Finden Sie die Aufzeichnungen Ihrer Webinare und veröffentlichen Sie sie in Ihren Schulungen.",
    improveMyOffer:'Mein Angebot verbessern',
    soonWebinarsAvailable:"Bald werden Ihnen Webinare angeboten.",
    willInformuSoon:"Wir halten Sie auf dem Laufenden.",
    courseResumeIntroduction:"Einführende Zusammenfassung des Kurses ...",
    reprendre:"Wieder aufzunehmen",
    freeOffreNoPaymentMethod:"Sie haben keine Zahlungsmethoden. Sie sind für das kostenlose Angebot registriert.",
    Credit:"Kredit",
    DebitCard:"Debitkarte",
    offre:"Angebot",
    nameOnCard:"Name auf der Karte",
    traitement:"Behandlung...",
    paiement:"Zahlung",
    offersList:"Angebotsliste",
    perYear:"pro Jahr",
    account:"Konto",
    wrongBirthDate:"Falsches Geburtsdatum",
    selectCountry:"Wähle ein Land",
    cardAddedSuccessfully:"Ihre Karte wurde erfolgreich hinzugefügt",
    returnToParameters:"Rückkehr zum Parameter",
    addNewPaymentMethod:"Fügen Sie eine neue Zahlungsmethode hinzu",
    verifyPassword:"Falsches Passwort, möchten Sie Ihr Passwort bestätigen",
    returnToOffer:"Zurück zu meinem Angebot",
    subscriptionCancelled:"Ihr Abonnement wurde erfolgreich gekündigt",
    perMonthPayedAnually:"Monat, jährlich bezahlt",
    downloadTemplate:"Vorlage herunterladen",
    toSee:"Excel, um ein Beispielformat anzuzeigen.",
    slideExcel:"Ziehen Sie Ihre Excel-Datei per Drag-and-Drop",
    reposition:"UMSTELLEN",
    importNewPhoto:"IMPORTIEREN SIE EIN NEUES FOTO",
    importPhoto:"EIN FOTO IMPORTIEREN",
    slideToSet:"auf Position ziehen",
    publishMyOpinion:"Veröffentlichen Sie meine Bewertung",
    noWebinarIsPlanned:"Derzeit sind keine Webinare geplant",
    addVideoUrl:"Video-URL hinzufügen",
    itegrateVideo:"Video einbetten",
    enterValidUrl:"Stellen Sie sicher, dass Sie eine gültige URL eingegeben haben.",
    pasteVideoUrl:"Videolink einfügen...",
    addVideoToIntegrate:"Video-URL zum Einbetten hinzufügen",
    perYearPayedAnually:"Jahr, jährlich bezahlt",
    titleSessionPlaceholder:"Geben Sie hier den Titel der Sitzung an...",
    titlePathPlaceholder:'Geben Sie hier den Titel des Kurses an...',
    creationDate:"Erstellungsdatum",
    createdAt:"Erstellt die",
    generic_decline: "Zahlung aus allgemeinen Gründen abgelehnt",
    insufficient_funds : "Das Kartenguthaben reicht nicht aus",
    lost_card : "Die Karte wird für verloren erklärt",
    stolen_card : "Die Karte wird als gestohlen gemeldet",
    expired_card : "Die Karte ist abgelaufen",
    incorrect_cvc : "CVC-Code ist falsch",
    processing_error : "Die Zahlung wurde aufgrund eines Verarbeitungsfehlers abgelehnt",
    gender:"Geschlecht",
    male:"Mann",
    female:"Frauen",
    noCommentFound1:"Kein Kommentar",
    lastUpdate:"Letzter Beitrag",
    lignes1:"Linie",
    containErrors1:"enthält Fehler",
    emptyTitleError:"Kurstitel (erforderlich)",
    emptyPathTitleError:"Kurstitel (erforderlich)",
    emptySessionTitleError:"Sitzungstitel (erforderlich)",
    Webinar1: "Webinare",
    notFound1:"Fügen Sie weitere Schüler hinzu und schalten Sie einen Gang höher",
    notFound2:"Koors vereint die besten Funktionen, um Online-Kurse an so viele Menschen wie möglich zu verteilen." ,
    notFound3:"Grace à Koors، vous gérez facilement vos communautés d'étudants / customers et vous gardez le contact en Permanentence.",
    emailAlreadyUsed:"Diese E-Mail-Adresse wird bereits verwendet",
    Clôturé: "Geschlossen",
    Ouvert: "Geöffnet",
    TITREdusondage: "Umfragetitel",
    Auteur: "Autor",
    CRÉELE: "Erstellt am",
    CloturéLE: "Geschlossen am",
    RÉPONSEs: "Antworten",
    Créerunsondage: "Umfrage erstellen",
    sondages: "Umfragen",
    sondage: "Umfragen",
    inscribedInSchool:"Anmeldung in der Schule",
    catalogVisits:"Besuche der Katalogseite",
    currentStudentNumber:"Anzahl der aktuellen Studierenden",
    currentStudentsWithDiploma:"Anzahl der Doktoranden",
    noSchooling:"nicht erwähnt",
    transcodeInProgress: 'Finalizing the import is in progress. You can continue to complete the content.',



};
