import { ComponentProps, FC,  useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../../global-state/store/root-reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { createCourse, clearFormTest, updateCourse, getChapter, getExam, checkFinalExam } from '../../../../global-state/actions/course-actions';
import { clearFormVideo } from '../../../../global-state/actions/upload-video-course-actions';
import SelectElement from '../../../../elements/select/select-element';
import InputSearch from '../../../../elements/inputs/input-search';
import { useTranslation } from 'react-i18next';
import { getSubjectCommunity, getTrainingQuestionForAssistant } from '../../../../global-state/actions/chat-actions';
import CommentTrainingProfessorCardHeader from '../../../../components/community/course-community/comments/comment-card/comment-training-professor-card-header';


const CommentCourseAssistant: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {

    const { t } = useTranslation();
    const displayOrder=[{value:"recent",label:t('SortsMostRecent') },{value:"oldest", label:"Le moins récent"}]
    const [courseTitle, setCourseTitle] = useState(props?.courseForm?.title)
    const [subjectFilterList, setSubjectFilterList] = useState<any>([])
    const [trie, setTrie] = useState<string>("recent")
    const [subjectFilter, setSubjectFilter] = useState<any>([])
    const [searchLabel, setSearchLabel] = useState<string>("")
    const [enableFilter, setEnableFilter] = useState<boolean>(false)
    const questionsList = useMemo(() => { return props?.forumList }, [props?.forumList]);
    const courseForm = useMemo(() => { return props?.courseForm }, [props?.courseForm]);
    let dateFormat = require('dateformat');

    

    useEffect(() => {
        if(enableFilter){
            filterComments()
        }
    }, [trie, searchLabel , subjectFilter])
    



    const filterComments = ()=>{
        let allSubject:any= []
        if(subjectFilter?.length===0){
            
            props?.courseForm?.orders?.map((order:any)=>{                
                let obj = order?.chapter?.id + " CHAPTER FORUM"
                allSubject.push(obj)
            })
            //setSubjectFilter(allSubject)
        } else {
            allSubject.push(subjectFilter)
        }
      
        let object ={
            "subject":allSubject,
            "searchFilter":searchLabel,
            "trie":trie
        }
        if(typeof object!= undefined && object?.subject?.length!=0){
        props?.getSubjectCommunity(object,props?.connectedUser?.idSchool,true)
        }
    }
    


    
    useEffect(() => {
        if(questionsList!=undefined){
            formatSubjectList()
        }
    }, [questionsList])
    
    const formatSubjectList =()=>{
        let allSubject:any= []
            props?.courseForm?.orders?.map((order:any)=>{                
                let obj = order?.chapter?.id + " CHAPTER FORUM"
                allSubject.push(obj)
            })
        let subjects:any=[{ value: allSubject, label: "Tous les sujets"}]                
        props?.courseForm?.orders?.map((order:any)=>{
            subjects.push({ value: order?.chapter?.id + " CHAPTER FORUM", label:order?.chapter?.title })
        })
        setSubjectFilterList(subjects)  
    }

    
    useEffect(() => {
      setCourseTitle(props?.courseForm?.title)
    }, [props?.courseForm])


    const getCommentsInital = async ()=>{
        let subject:any= []
            props?.courseForm?.orders?.map((order:any)=>{                
                let obj = order?.chapter?.id + " CHAPTER FORUM"
                subject.push(obj)
            })
            let object ={
                "subject":subject,
                "searchFilter":"",
                "trie":"recent", 
            }
            if(typeof object!= undefined && object?.subject?.length!=0){
            await props?.getSubjectCommunity(object,props?.connectedUser?.idSchool,false)
            }
            await setEnableFilter(true)
    }

    useEffect(() => {
        if(props?.courseForm && questionsList && props?.courseForm != undefined && questionsList?.length===0){
            getCommentsInital()
        }
    }, [])

   

    
   
    
    
    

  return (
    <div style={{width:"100%"}}>
        <div className=" d-flex" style={{ flexDirection:"column" , paddingTop:"32px" , paddingLeft:"120px" , backgroundColor:"#FFFFFF" , paddingBottom:"32px"}}>
            <span className="H2-Headline neutral-3">{t('comments')}</span>
            <span className='body-md bold'>{courseTitle} 
            <span style={{paddingLeft:"16px" , color:"#CACBCE"}}>{t('createdAt')}</span> <span style={{paddingLeft:"8px"}}>{dateFormat(courseForm?.creationDate, "dd/mm/yyyy")}</span>
            <span style={{paddingLeft:"16px" , color:"#CACBCE"}}>{t('lastUpdate')}</span> <span style={{paddingLeft:"8px"}}>{dateFormat(courseForm?.updateDate, "dd/mm/yyyy")}</span>
            </span>
        </div>


        { (props?.forumList!=undefined && props?.forumList?.length!=0)&& <div className="mx-auto"  style={{  backgroundColor:"#FBFBFD" , padding:"40px 225px 65px 225px"}}>
                <div >
                    <div className="col-md-12   d-flex justify-content-end text-right px-0">
                        <div className="select-input me-2" style={{ width: '225px' }}>
                           <SelectElement disabled={false} taille={'lg'} defaultValue={trie} options={displayOrder}  placeholder ={trie} onChange={(e:any)=>{setTrie(e)}}></SelectElement> 
                        </div> 
                        <div className="select-input me-2" style={{ width: '225px' }}>
                           <SelectElement disabled={false} taille={'lg'} defaultValue={subjectFilterList[0]?.value} options={subjectFilterList}  placeholder ={subjectFilterList[0]?.label}  onChange={(e:any)=>{setSubjectFilter(e.toString())}} ></SelectElement> 
                        </div> 
                            <InputSearch  value={""}  onChange={(e:any)=>{setSearchLabel(e)}}  placeholder={t('searchProfessors')}/>
                    </div>
                </div>
        </div>}

        
        <div className="row d-flex justify-content-center">
            <div style={{padding:"24px 255px 64px 255px"}}>
                {/* <ListCardQuestionProfessor doSearch={doSearch} what={"all"} questionList={questionsList} /> */}
                <CommentTrainingProfessorCardHeader   postsList={props?.forumList} />
            </div>
        </div>     
        
    </div>
  )
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            createCourse,
            updateCourse,
            getChapter,
            clearFormVideo,
            getExam,
            clearFormTest,
            checkFinalExam,
            getTrainingQuestionForAssistant,
            getSubjectCommunity
        },
        dispatch
    );
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    loggedIn: state.authReducer.loggedIn,
    courseForm: state.courseReducer.courseForm,
    listeHistory: state.historyReducer.listHistoryBreadCrumb,
    history: ownProps?.history,
    t: ownProps?.t,
    listTest: state.courseReducer.listTest,
    nbrFinalExam: state.courseReducer.nbrFinalExam,
    forumList:state.chatReducer.listPosts
});

export default connect(mapStateToProps, mapDispatchToProps)(CommentCourseAssistant);