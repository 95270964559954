import { FC, useEffect, useRef, useState } from 'react';
import ImageUpload from '../../../UI/cropImageExample/cropImage'
import axiosInstance from '../../../helpers/interceptors';
import { connect } from "react-redux"
import { AppState } from "../../../global-state/store/root-reducers";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { fetchData, getPeriod } from '../../../base/functions/Functions';
import { createWebinar, updateWebinar, update, clearCheck, checkAvailability, getZoomAccountDetails } from "../../../global-state/actions/webinar-actions";
import "../../../translations/i18n";
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router';
import { returnStartPathUrl } from '../../../helpers/domainCheck';
interface WebinarObject {
  title: string,
  duration: number,
  subjectType: string,
  subjectIds: any[],
  description: any,
  img: string,
  link: string,
  startDate: string,
  personSet: any[],
  recordingLink: string,
  idSchool: number,
  agenda: boolean
}
interface Props {
  courses: any[]
  filters: any,
  webinar?: WebinarObject,
  status?: string,
  name?: string,
  idWebinar: number,
}

const times = [
  { value: "00:00", label: "00:00" },
  { value: "00:30", label: "00:30" },
  { value: "01:00", label: "01:00" },
  { value: "01:30", label: "01:30" },
  { value: "02:00", label: "02:00" },
  { value: "02:30", label: "02:30" },
  { value: "03:00", label: "03:00" },
  { value: "03:30", label: "03:30" },
  { value: "04:00", label: "04:00" },
  { value: "04:30", label: "04:30" },
  { value: "05:00", label: "05:00" },
  { value: "05:30", label: "05:30" },
  { value: "06:00", label: "06:00" },
  { value: "06:30", label: "06:30" },
  { value: "07:00", label: "07:00" },
  { value: "07:30", label: "07:30" },
  { value: "08:00", label: "08:00" },
  { value: "08:30", label: "08:30" },
  { value: "09:00", label: "09:00" },
  { value: "09:30", label: "09:30" },
  { value: "10:00", label: "10:00" },
  { value: "10:30", label: "10:30" },
  { value: "11:00", label: "11:00" },
  { value: "11:30", label: "11:30" },
  { value: "12:00", label: "12:00" },
  { value: "12:30", label: "12:30" },
  { value: "13:00", label: "13:00" },
  { value: "13:30", label: "13:30" },
  { value: "14:00", label: "14:00" },
  { value: "14:30", label: "14:30" },
  { value: "15:00", label: "15:00" },
  { value: "15:30", label: "15:30" },
  { value: "16:00", label: "16:00" },
  { value: "16:30", label: "16:30" },
  { value: "17:00", label: "17:00" },
  { value: "17:30", label: "17:30" },
  { value: "18:00", label: "18:00" },
  { value: "18:30", label: "18:30" },
  { value: "19:00", label: "19:00" },
  { value: "19:30", label: "19:30" },
  { value: "20:00", label: "20:00" },
  { value: "20:30", label: "20:30" },
  { value: "21:00", label: "21:00" },
  { value: "21:30", label: "21:30" },
  { value: "22:00", label: "22:00" },
  { value: "22:30", label: "22:30" },
  { value: "23:00", label: "23:00" },
  { value: "23:30", label: "23:30" },
]

const Webinar: FC<ReturnType<typeof mapStateToProps> & Props> = (props) => {
  const [files, setFiles] = useState([])
  const history = useHistory()
  const [studentsIds, setStudentsIds] = useState([])
  const { t } = useTranslation();
  const closeRef = useRef(null);
  const openRef = useRef(null);
  const openRef1 = useRef(null);
  const [inputs, setInputs] = useState({
    field1: "",
    field2: ""
  });
  const [period, setPeriod] = useState(0)
  const [selectedValue, setSelectedValue] = useState("")
  const [webinar, setWebinar] = useState<any>({
    title: "",
    duration: 0,
    subjectType: "COURSE",
    subjectIds: [],
    description: "",
    img: "",
    link: "",
    startDate: "",
    personSet: [],
    recordingLink: "",
    idSchool: 0,
    agenda: false

  })

  const [updatedWebinar ,setUpdatedWebinar] = useState<any>();
  const [isValid, setIsValid] = useState(false);
  const [zoomAccountInfo, setZoomAccountInfo] = useState<any>()
  const [teacherToAffect, setTeacherToAffect] = useState<any>()
  const [listOfprofs, setlistOfprofs] = useState<any>([])
  const [endDate, setEndDate] = useState(times)
  const [trainingTitles, setTrainingTitles] = useState([])

  useEffect(() => {
    let startDateIndex = times?.findIndex((obj: any) => obj?.value === inputs?.field1)
    if (startDateIndex != -1) {
      setEndDate(times.slice(startDateIndex + 1, times?.length))
    }
  }, [inputs?.field1])

  const getProfs = () => {
    const professors: any = []
    axiosInstance.get(`${process.env.REACT_APP_BASE_URL2}person/all/${props?.user?.idSchool}/profs`).then(response => {
      response?.data?.forEach((prof: any) => {
        professors.push({ name: prof.name, id: prof.id })
      });
    })
    setlistOfprofs(professors)
  }

  const getTrainings = () => {

    axiosInstance.get(`${process.env.REACT_APP_BASE_URL}training/getTrainingsNames/${props?.user?.idSchool}`).then(response => {
      setTrainingTitles(response.data)
    })

  }

  useEffect(() => {
    if (listOfprofs.length != 0) {
      getProfs()
    }
    getTrainings()

  }, [])

  useEffect(() => {
    if (props?.name === "update") {
      setWebinar(props?.webinar)
    }
    // props?.clearCheck()
    setIsValid(false)
  }, [])

  const changeInputValue = (e: any) => {
    let a = document.getElementsByTagName('textarea');
    for (let i = 0, inb = a.length; i < inb; i++) {
      const b: any = document.getElementById(a[i].id);
      if (a[i].scrollHeight !== 0) {
        b.style.height = "1px";
        b.style.height = (1 + a[i].scrollHeight) + "px";
      }
    }
    setWebinar({ ...webinar, description: e?.target.value })
  }

  const handleChoice = (e: any) => {
    setSelectedValue(e.target.value)

    axiosInstance.post(`${process.env.REACT_APP_BASE_URL3}kpi/studentIds`, [e.target.value]).then((res) => {
      setStudentsIds(res.data)
    })
  }

  useEffect(() => {
    const duration = Promise.resolve(getPeriod(inputs.field1.toString(), inputs.field2.toString()))

    duration.then((resp: number) => {
      setPeriod(resp)
    })
  }, [inputs.field1, inputs.field2])

  function uploadImage(file: any) {
    const form = new FormData();
    setFiles(file)

    form.append('file', file[0]);
    axiosInstance.post(`${process.env.REACT_APP_BASE_URL4}upload/image`, form).then(async (res) => {
      setWebinar({ ...webinar, img: res.data?.link })
    });
  }

  useEffect(() => {


    let persons: Array<any> = webinar?.personSet
    studentsIds.forEach((e: number) => {
      let student = {
        personId: e,
        personRole: "ATTENDEE"


      }
      persons.push(student)

    })
    setWebinar({ ...webinar, personSet: persons })
  },
    [
      studentsIds
    ])

  const checkAvailability = async (webinarToCheck: any)=>{
    const baseUrl = process.env.REACT_APP_BASE_URL9;
    let webinarToBeCreatedStatus = await fetchData("POST",baseUrl + `webinars/checkAvailability`, webinarToCheck).then(res => { return res }).catch(err => console.error(err));
    setIsValid(webinarToBeCreatedStatus)
    
  }

  const webinarCreation = async () => {

    let startDate1 = new Date(webinar.startDate)
    startDate1.setHours(Number(inputs.field1.split(':')[0]))
    startDate1.setMinutes(Number(inputs.field1.split(':')[1]))
    let startDate2 = new Date(webinar.startDate)
    startDate2.setHours(Number(inputs.field2.split(':')[0]))
    startDate2.setMinutes(Number(inputs.field2.split(':')[1]))
    const webinarDataForVerification = {
      startDate: startDate1,
      endDate: startDate2,
      filter: {
        subjectType: "",
        statusWebinar: "",
        subjectIds: [props?.courseInfo?.id],
        sort: "asc",
        search: ""
      }
    }

    await checkAvailability(webinarDataForVerification)

  }

  useEffect(() => {
    if (webinar.startDate !== "" && inputs.field1 !== "" && inputs.field2 !== "") {
      webinarCreation();
    }
  }, [webinar, inputs]);


  const publicate = () => {
    let persons: Array<any> = webinar?.personSet

    persons.push({
      personId: (props.user?.role === "role_director") ? props?.school?.id : (props.user?.role === "role_adminstrator") ? teacherToAffect : props.user.idPerson,
      personRole: "ORGANIZER"

    })
    let startDate = new Date(webinar.startDate)
    startDate.setHours(Number(inputs.field1.substr(0, 2)))
    startDate.setMinutes(Number(inputs.field1.substr(3, 2)))
    let _webinar = {
      ...webinar,
      subjectIds: [...webinar?.subjectIds, selectedValue],
      personSet: persons,
      duration: period,
      startDate: startDate,
      idSchool: props?.school.id
    }


    const promise = Promise.resolve(createWebinar(_webinar));
    promise.then((z) => {
      (closeRef as any).current?.click()
    })
  }

  const openModal = () => {
    const promise = Promise.resolve(update(props?.webinar));
    promise.then((z) => {
      (openRef as any).current?.click()
    })
  }

  const openModal1 = () => {
    const promise = Promise.resolve(update("add"));
    promise.then((z) => {
      (openRef1 as any).current?.click()
    })
  }

  function deleteFile() {
    setFiles([]);
    setWebinar({ ...webinar, img: '' })
  }

  const updateModel = () => {
    let persons: Array<any> = webinar?.personSet
    persons.push({
      personId: ((props.user?.role === "role_director") || (props.user?.role === "role_adminstrator")) ? props?.school.id : props.user.idPerson,
      personRole: "ORGANIZER"
    })
    let startDate = new Date(webinar.startDate)
    startDate.setHours(Number(inputs.field1.substr(0, 2)))
    startDate.setMinutes(Number(inputs.field1.substr(3, 2)))
    let _webinar = {
      ...webinar,
      subjectIds: [...webinar?.subjectIds, selectedValue],
      personSet: persons,
      duration: period,
      startDate: startDate,
      idSchool: props?.school.id
    }
    const promise = Promise.resolve(updateWebinar(_webinar, updatedWebinar?.id));
    promise.then((z) => {
      (closeRef as any).current?.click()
    })
  }

  const getZoomAccountDetails = async (id: Number) =>{
    let accountInfo= await fetchData("GET",process.env.REACT_APP_BASE_URL9 + `externalAccount/getAccountInfoForDirector/`+id, {} ).then(res => { return res }).catch(err => console.error(err));
    setZoomAccountInfo(accountInfo)
  }

  const createWebinar = async (webinar: any) =>{
      const baseUrl = process.env.REACT_APP_BASE_URL9;
      await fetchData("POST",baseUrl + `webinars/create`, webinar).then(res => { return res }).catch(err => console.error(err));
  }

  const updateWebinar = async (idWebinar: Number, webinar:any) =>{
      const baseUrl = process.env.REACT_APP_BASE_URL9;
      await fetchData("PUT",baseUrl + `webinars/update/` + idWebinar, webinar).then(res => { return res }).catch(err => console.error(err));
  }

  const update = async (webinar: any) => {
    setUpdatedWebinar(webinar)
  }

  useEffect(() => {
    if (zoomAccountInfo === undefined && zoomAccountInfo === null && (props?.connectedUser?.role === "role_director") || (props?.connectedUser?.role === "role_adminstrator")) {
      getZoomAccountDetails(props?.connectedUser?.idSchool)

    } else if (zoomAccountInfo === undefined && zoomAccountInfo=== null && props?.connectedUser?.role === "role_prof") {
      getZoomAccountDetails(props?.connectedUser?.idPerson)
    }
  }, [])

  function openModalWebinar() {
    if (zoomAccountInfo != '') {
      openModal1()
    } else {
      goToParam()
    }
  }

  const goToParam = () => {
    history.push(`${returnStartPathUrl()}/param`)
  }
  
  return (<>


    {(props.user?.role !== "role_adminstrator" && !props?.status && !props.webinar) &&
      <>
        <button className="btn-Primary large icon-left" onClick={() => { openModalWebinar() }}>
          < span className="material-icons-outlined ">
            add
          </span>

          <div className="m-auto ps-2" >
            {t('NewWebinar')}
          </div>
        </button>
        <button ref={openRef1} style={{ visibility: "hidden" }} data-bs-toggle="modal" data-bs-target="#myModal">

        </button></>}

    {((props?.status === "REFUSED") && (props.user?.role === "role_prof" || props.user?.role === "role_adminstrator")) && <>

      <button className="btn-Primary large icon d-flex me-2" onClick={openModal}>
        <span className="material-icons-outlined pe-2">
          play_arrow
        </span>
        <div className="m-auto Button-md">
          {t('Edit')}
        </div>
      </button>
      <button ref={openRef} style={{ visibility: "hidden" }} data-bs-toggle="modal" data-bs-target="#myModal">

      </button>
    </>}

    <div className="modal" id="myModal" tabIndex={-1} role="dialog">
      <div id="webinarModal" className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header p-4 pb-0" style={{ border: "none" }}>
            <div className="ms-auto">
              <button type="button" className="btn-QuickActions" data-bs-dismiss="modal" aria-label="Close">
                <span className="material-icons-outlined" >close</span>
              </button>
            </div>
          </div>
          <div className="justify-content-center m-auto">
            <span className='H3-Headline'>
              {t('NewWebinar')}
            </span>
          </div>
          <div className="modal-body mt-4">
            <div className="row justify-content-center ">
              <div className="col-md-10" >
                <div className=" my-4">
                  <h1 className="form-title mb-3">{t('WebinarTitle')}</h1>
                  <input dir="ltr" type="text" className="form-control input text-default" placeholder={t('WebinarTitle')} name="title"
                    value={webinar.title}
                    onChange={(e) => { setWebinar({ ...webinar, title: e?.target.value }) }} required />
                </div>

                {(props?.user?.role === "role_director" || props?.user?.role === "role_prof") ?
                  <div className="my-4">
                    <h1 className="form-title mb-3">{t('WebinarGivenBy')}</h1>
                    <input dir="ltr" disabled type="text" className="form-control input text-default" defaultValue={((props.user?.role === "role_director") || (props.user?.role === "role_adminstrator")) ? (props?.school?.name) : (props?.connected?.firstName + " " + props?.connected?.lastName)} />
                  </div> :
                  <div>
                    <h1 className="form-title mb-3">{(!!props?.user && !!props?.user?.role && props?.user?.role === "role_adminstrator") ? t('webinarForm1') : t('WebinarGivenBy')}</h1>



                    {(props?.connectedUser?.role === "role_adminstrator") ?
                      <div className="select-input">
                        <select id="choiceProfessor" className="form-control input text-default" onChange={(e) => { setTeacherToAffect(e.target.value) }} >
                          <option className="option-placeholder" value="" selected disabled hidden >{t('webinarForm2')}</option>
                          {listOfprofs.map((profObject: any, index: number) => {

                            return <option key={profObject?.id} className="option-choice" value={profObject?.id}>{profObject?.name}</option>
                          })}
                        </select>
                      </div>

                      :

                      <div className="select-input">
                        <select id="choiceProfessor" className="form-control input text-default" onChange={(e) => { setTeacherToAffect(e.target.value) }} >
                          <option className="option-placeholder" value="" selected disabled hidden >{(props?.connected?.firstName + " " + props?.connected?.lastName)}</option>
                          {listOfprofs.map((profObject: any, index: number) => {

                            return <option key={profObject?.id} className="option-choice" value={profObject?.id}>{profObject?.name}</option>
                          })}
                        </select>
                      </div>}


                  </div>}

                <div className="row my-4">
                  <div className='col-md-6'>
                    <div className="">
                      <h1 className="form-title mb-3">{t('WebinarDate')}</h1>
                      <input dir="ltr" type="date" min={new Date().toISOString().split("T")[0]} className="form-control input text-default" name="startDate" value={webinar.startDate}
                        onChange={(e) => { setWebinar({ ...webinar, startDate: e?.target.value }) }} required />
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <h1 className="form-title mb-3" >{t('WebinarSchedule')}</h1>


                    <div className='row'>
                      <div className=" col-md-6 " >
                        <div className="select-input">
                          <select className={`form-control input text-default  mt-1 ${isValid === true ? "error" : ""}`} key="field1" name="field1" onChange={(e: any) => { setInputs(state => ({ ...state, field1: e.target.value })) }} >
                            <option className="option-placeholder" value="" selected disabled hidden >--:--</option>
                            {times.map((time: any, index: number) => {

                              return <option key={time?.value} className="option-choice" value={time?.value}>{time?.label}</option>
                            })}
                          </select>
                        </div>
                      </div>
                      <div className=" col-md-6 " >
                        <div className="select-input">
                          <select className={`form-control input text-default  mt-1 ${isValid === true ? "error" : ""}`} key="field2" name="field2" onChange={(e: any) => { setInputs(state => ({ ...state, field2: e.target.value })) }} >
                            <option className="option-placeholder" value="" selected disabled hidden >--:--</option>
                            {endDate.map((time: any, index: number) => {

                              return <option key={time?.value} className="option-choice" value={time?.value}>{time?.label}</option>
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  {(isValid === true) && <div className='row'>
                    <span className='error-input mt-1'>{t('timeNotAvailable')}</span>
                  </div>}
                </div>
                <div className="my-4">
                  <h1 className="form-title mb-3">{(props?.user?.role === "role_adminstrator") ? t('webinarForm3') : t('ForStudentsOf')} </h1>
                  <div className="select-input">
                    <select id="choiceProfessor" className="form-control input text-default" onChange={(e: any) => { handleChoice(e) }} >
                      <option className="option-placeholder" value="" selected disabled hidden>{t('allFormations')}</option>
                      {trainingTitles.map((object: any, index: number) => {
                        return <option key={object.id} className="option-choice" value={object.id}>{object.courseTitle}</option>
                      })}
                    </select>
                  </div>
                </div>
                <div className="my-4">
                  <label className="form-title">{t('ShortDescriptionOfTheWebinar')}</label>
                  <textarea id="textArea" value={webinar.description} className="textaria-textField" rows={1} style={{ resize: "none" }} required onChange={(e) => { changeInputValue(e); }} placeholder={t('ShortDescriptionOfTheWebinar')}></textarea>
                </div>
                <div className="my-4">
                  <div className="my-4">
                    <>
                      <section className="mt-5" >
                        <ImageUpload width={600} deleteFile={deleteFile} addFile={uploadImage} name={t('slideToAddAnImage') + " (jpg, png)"} page={t('selectAnImage')} files={files} />


                      </section>
                    </>
                  </div>
                  <div className="d-flex">
                    <input dir="ltr" className="input-style-checkbox" type="checkbox" id='checkBtn' />
                    <label htmlFor='checkBtn' >
                      <input dir="ltr" type="text" style={{ display: "none" }} />
                    </label>
                    <span className="body-sm ms-2">{t('IWantTheWebinarToBeAccessibleInReplayWithThePublicConcerned')}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer p-0 mb-4 justify-content-center" style={{ border: "none" }}>
            <div className="d-flex flex-direction  ">
              <div className='d-flex'>
                <div className="m-1">
                  <button ref={closeRef} type="button" className="btn-Secondary large" data-bs-dismiss="modal"
                    data-bs-toggle="modal" data-bs-target="#myModal" disabled={false}>{t('Cancel')}</button>
                </div>
                <div className="m-1">
                  {((props.user?.role === "role_director" || props.user?.role === "role_prof" || props.user?.role === "role_adminstrator") && !props?.status && updatedWebinar === "add" && !props.webinar) &&
                    <button type="button" className="btn-Primary large" disabled={!inputs.field1 || !inputs.field2 || !webinar.description || !webinar.startDate || !webinar.title || selectedValue === "" || isValid === true} onClick={publicate} data-bs-dismiss="modal" aria-label="Close">{t('publish')}</button>
                  }

                  {(!!updatedWebinar?.id) &&
                    <button type="button" className="btn-Primary large" disabled={!inputs.field1 || !inputs.field2 || !webinar.description || !webinar.startDate || !webinar.title || selectedValue === ""} onClick={updateModel} data-bs-dismiss="modal" aria-label="Close" >{t('publish')}</button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  )
}


const mapStateToProps = (state: AppState, ownProps: any) => ({
  user: state.authReducer.connectedUser,
  webinar: ownProps?.webinar,
  name: ownProps?.webinar,
  status: ownProps?.status,
  school: state.schoolInfoReducer.schoolInfo,
  connected: state.authReducer.connectedUserInfo,
  t: ownProps?.t,
  filters: ownProps?.filters,
  idWebinar: ownProps?.idWebinar,
  courseInfo: state.studentTraining.currentCourse,
  profs: state.profReducer.profs,
  connectedUser: state.authReducer.connectedUser,
});


export default connect(mapStateToProps)(Webinar);


