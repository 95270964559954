import { FC, useEffect, useState } from 'react'
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from "react-i18next";
import { calculateDateDifference, formatJavaDate, getDuration } from '../../base/functions/Functions';
import { blackBtnStatus, chatBtnValidStatus, studentsTrainingsTags, validStatuses } from '../../data-structures/constants/constantsList';
import { DisplayStyle, studentTrainingDetailsBody } from '../../data-structures/interfaces/trainings.interfaces';
import { iconCourse, iconPath, iconSession } from '../../styles/icons/icons';
import { AppState } from '../../global-state/store/root-reducers';




interface Props {
    followedTraining: studentTrainingDetailsBody
    direction: DisplayStyle
    goToTraining: any
    goToApplicationFile: any
    getPaid: any
    enableChat: any

}

const StudentTrainingCard: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & Props> = (props: ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & Props) => {

    const { t } = useTranslation();
    const [cardDisplay, setCardDisplay] = useState<DisplayStyle>("vertical");
    const [trainingDetails, setTrainingDetails] = useState<studentTrainingDetailsBody>({
        trainingId: 0,
        trainingName: '',
        trainingImgUrl: '',
        trainingType: '',
        professorName: '',
        trainingDuration: 0,
        registrationFormId: 0,
        idFollowUp: 0,
        studentProgress: 0,
        certificationUrl: '',
        certificationDate: '',
        followStatus: '',
        completedDuration: 0,
        sessionStartDate: '',
        sessionEndDate: '',
        msgNbr: 0
    });
    const tag = studentsTrainingsTags.find((el: any) => el.value === trainingDetails.followStatus) ;

    useEffect(() => {
        if (trainingDetails !== props.followedTraining) {
            setTrainingDetails(props.followedTraining)
        }
    }, [props.followedTraining])

    useEffect(() => {
        if (cardDisplay !== props?.direction) {
            setCardDisplay(props?.direction)
        }
    }, [props.direction])


    const cardClick = (e: any) => {
        e.preventDefault();
        switch (trainingDetails.followStatus) {
            case 'DRAFT':
            case 'CANDIDATE':
            case 'REFUSED':
                return props?.goToApplicationFile(e, trainingDetails);
            case 'UNPAID':
                return props?.getPaid(trainingDetails);
            case 'INSCRIBED':
            case 'COMPLETED':
            case 'CERTIFIED':
                return props?.goToTraining(trainingDetails);
        }
    }

    const getBtnLabelByStatus = () => {
        switch (trainingDetails.followStatus) {
            case 'DRAFT':
                return t('finishdraftFollowButton');
            case 'CANDIDATE':
                return t('seeDemand');
            case 'UNPAID':
                return t('makeAPayment');
            case 'REFUSED':
                return t('seeDemand');
            case 'INSCRIBED':
                return trainingDetails.trainingType === "COURSE" ? t('gotocourseStudent') : trainingDetails.trainingType === "PATH" ? t("gotopathStudent") : t("gotosessionStudent");
        }

    }

    const btnClick = (e:any) => {
        e.stopPropagation();
        cardClick(e)

    }
    const gotToChat = () => {
        props?.enableChat()
    }

    const getTrainingIcon = (type: string) => {
        switch (type) {
            case 'COURSE':
                return iconCourse;
            case 'PATH':
                return iconPath;
            default:
                return iconSession;
        }
    }


    return (
        <div onClick={(e) => cardClick(e)} className={`card vignette-hover ${(cardDisplay === "vertical") ? " dim-student-card vertical" : "flex-row w-100 dim-student-card horizental"}`} >
            <div className={` ${!(cardDisplay === "vertical") && "style-student-image-horizental"}`}>
            <img
                className={` ${(cardDisplay === "vertical") ? "card-img-top img-card" : "rounded-start img-card-horiz "}`}
                src={trainingDetails.trainingImgUrl}
            />
            </div>
            <span className={`badge badge-${tag?.color}`}>{t(tag?.label + '')}</span>

            <div className={`p-4 ${(cardDisplay === "vertical") ? "d-flex flex-column" : "card-body horiz w-100"}`}>
                <h3 className={`H3-Headline cours-card   ${(cardDisplay === "vertical") ? "vertical" : "horizontal"} title`}
                    style={{ height: (cardDisplay === "vertical") ? 64 :32 }}>
                    {trainingDetails.trainingName}
                </h3>
                <div className="d-flex flex-row align-items-center">
                    <div className="d-flex align-items-center me-2">
                        {getTrainingIcon(trainingDetails.trainingType)}
                    </div>
                    <div className="card-text">
                        <div className="body-sm bold">
                            <span className="card-text neutral-2">
                                {trainingDetails.trainingType === 'COURSE' && <> {t('courseBy')} <span className="name">{trainingDetails.professorName}</span></>}
                                {trainingDetails.trainingType === 'PATH' && <> {t('pathBy')} <span className="name">{trainingDetails.professorName}</span></>}
                                {(trainingDetails.trainingType === 'SESSION') &&
                                    <> {t('sessionTitle')}
                                        {(trainingDetails.sessionStartDate !== undefined && trainingDetails.sessionEndDate !== undefined) && <span className="name">du {formatJavaDate(trainingDetails.sessionStartDate)} au {formatJavaDate(trainingDetails.sessionEndDate)}</span>}
                                    </>}
                            </span>

                        </div>
                    </div>
                </div>
                <div className={`d-flex  mt-auto ${(cardDisplay === "vertical") ? "flex-column " : "flex-row"}`} >

                    <div className="d-flex justify-content-start  pb-2 row">
                        {validStatuses.includes(trainingDetails.followStatus) ?
                            <>
                                {trainingDetails.followStatus === "CERTIFIED" && <>
                                    <div className="d-flex pe-3 flex-column col-md-6">
                                        <span className="number details">
                                            {formatJavaDate(trainingDetails.certificationDate)}
                                        </span>
                                        <span className="body-sm bold card-text title-name">
                                            {t('certification')}
                                        </span>
                                    </div>
                                    <div className="d-flex pe-3 flex-column col-md-6">
                                        <span className="number details">
                                            {calculateDateDifference(trainingDetails.certificationDate)} {t('joursVisit')}
                                        </span>
                                        <span className="body-sm bold card-text title-name">
                                            {t('Tempsdobtention')}
                                        </span>
                                    </div>
                                </>}
                            </>
                            : <>
                                {(trainingDetails.followStatus === "INSCRIBED") &&
                                    < div className='justify-content-row d-flex pt-4 pb-2' >
                                        <div className='d-flex pe-3 flex-column col-md-6'>
                                            <span className="number details">
                                                {trainingDetails.studentProgress} %
                                            </span>
                                            <span className="body-sm bold card-text title-name">
                                                {t('progress')}
                                            </span>
                                        </div>
                                        <div className='d-flex pe-3 flex-column col-md-6'>
                                            <span className="number details">
                                                {getDuration(trainingDetails.completedDuration)}<span className="card-text title-name">/{getDuration(trainingDetails.trainingDuration)}</span>
                                            </span>
                                            <span className="body-sm bold card-text title-name">
                                                {t('durationChapter')}
                                            </span>
                                        </div>
                                    </ div >
                                }
                            </>}
                        <div className={`d-flex align-items-end pb-2 ${(cardDisplay === "horizontal" && chatBtnValidStatus.includes(trainingDetails.followStatus)) ? "" : "pt-4"}`} >
                            {(trainingDetails.followStatus === "CERTIFIED") &&
                                <button onClick={(e) => { }} type="button" className="btn-Secondary large icon-left d-flex me-2">
                                    <span className="material-icons-outlined">
                                        download
                                    </span>
                                    <div className="m-auto ps-2">{t('certifButton')}</div>
                                </button>}
                            {blackBtnStatus.includes(trainingDetails.followStatus) &&
                                <button type="button" className="btn-Primary large d-flex me-2" onClick={(e:any) => { btnClick(e) }}>
                                    {getBtnLabelByStatus()}
                                </button>
                            }
                            {chatBtnValidStatus.includes(trainingDetails.followStatus) &&
                                <button type="button" className="btn-QuickActions chat ml-1" onClick={(e) => { gotToChat() }}>
                                    <span className="material-icons-outlined">
                                        chat_bubble_outline
                                    </span>
                                    <span className="numbernotif body-sm bold">{trainingDetails.msgNbr}</span>
                                </button>
                            }
                        </div>
                    </ div>
                </div>
            </div>
        </div >
    )

}


const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {

        },
        dispatch
    );
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    connectedUserInfo: state.authReducer.connectedUserInfo,
    courseInfo: state.courseReducer.courseInfo,
    schoolInfo: state.schoolInfoReducer.schoolInfo
});
export default connect(mapStateToProps, mapDispatchToProps)(StudentTrainingCard);