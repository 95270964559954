import Axios from '../../helpers/interceptors';
import { ComponentProps, FC, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { clearHistory } from '../../global-state/actions/breadcrumb-actions'
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
import SelectElement from '../../elements/select/select-element';
import { exportListCourses } from '../../helpers/export-courses-functions'
import { getCoursesForDirector, getOnlineCoursesForDirector, getPendingCoursesForDirector } from '../../global-state/actions/course-actions';
import { debounce } from 'lodash';
const FilterAllCoursesComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {

    const [professors, setProfessors] = useState<any[]>([]);
    const [defaultValueProfessors, setDefaultValueProfessors] = useState("none");
    const [actualTab, setActualTab] = useState(props?.actualTabAllCourses);
    const [search, setSearch] = useState("");
    const [idProfessor, setIdProfessor] = useState(0);
    const { t } = useTranslation();
    useEffect(() => {
        props?.clearHistory();
        Axios.get(`${process.env.REACT_APP_BASE_URL2}person/all/${props?.user?.idSchool}/profs`).then(response => {
            const data = []
            data.push({ label: t("Tous"), value: 0, id: 0 })
            props?.user.role==="role_director" && data.push({label:props?.userInfo.firstName+" "+props?.userInfo.lastName,value:props?.user.idPerson,id:props?.user.idPerson})
            response?.data?.forEach((el: any) => {
                data.push({ label: el.name, value: el.id, id: el.id })
            });

            setProfessors(data);
        })
    }, [])

    useEffect(() => {
        setDefaultValueProfessors("none");
    }, [props?.actualTabAllCourses])

    useEffect(() => {
        setActualTab(props?.actualTabAllCourses)
    }, [props?.actualTabAllCourses])



    const exportData = () => {

        const lang = localStorage.getItem("language")
        let actualTabOp = actualTab === "online" ? t('ListOnlineCourses') : t('ListPendingCourses')
        let name = (actualTab === "all" ? t('ListAllCourses') : actualTabOp) + " " + props?.school.code;
        let filterCourse;
        if (actualTab === "all") {
            filterCourse = { entityId: props?.user.idSchool, entityType: "SCHOOL" };
        } else if (actualTab === "online") {
            filterCourse = { entityId: props?.user.idSchool, entityType: "SCHOOL", status: "PUBLISHED" };
        } else {
            filterCourse = { entityId: props?.user.idSchool, entityType: "SCHOOL", status: "PENDING" };
        }


        exportListCourses(filterCourse, name, lang)
    }

    const filterProf = async (e: any) => {
        
        let filterCourse;
        setIdProfessor(e) 

        if (e === 0) {
            if (actualTab === "all") {
                filterCourse = {
                    entityId: props?.user.idSchool, entityType: "SCHOOL", pageSize: 1000,
                    search:search,
                    pageNumber: 0
                };
                await props?.getCoursesForDirector(filterCourse, 'yes')
            } else if (actualTab === "online") {
                filterCourse = {
                    entityId: props?.user.idSchool, entityType: "SCHOOL", status: "PUBLISHED", pageSize: 1000,
                    search:search,
                    pageNumber: 0
                };
                await props?.getOnlineCoursesForDirector(filterCourse,'yes')

            } else {
                filterCourse = {
                    entityId: props?.user.idSchool, entityType: "SCHOOL", status: "PENDING", pageSize: 1000,
                    search:search,
                    pageNumber: 0
                };
                await props?.getPendingCoursesForDirector(filterCourse,'yes')
            }
        } else {
            if (actualTab === "all") {
                filterCourse = {
                    entityId: props?.user.idSchool, entityType: "SCHOOL", idProf: e, pageSize: 1000,
                    search:search,
                    pageNumber: 0
                };
                await props?.getCoursesForDirector(filterCourse, 'yes')
            } else if (actualTab === "online") {
                filterCourse = {
                    entityId: props?.user.idSchool, entityType: "SCHOOL", status: "PUBLISHED", idProf: e, pageSize: 1000,
                    search:search,
                    pageNumber: 0
                };
                await props?.getOnlineCoursesForDirector(filterCourse,'yes')

            } else {
                filterCourse = {
                    entityId: props?.user.idSchool, entityType: "SCHOOL", status: "PENDING", idProf: e, pageSize: 1000,
                    search:search,
                    pageNumber: 0
                };
                await props?.getPendingCoursesForDirector(filterCourse,'yes')
            }
        }
        props?.doFilter({ search: search , searchProfessors: e });

    }


    const filterProfbySearch = async (e:any ,selectedProf:any) => {
        let filterCourse;
        if (e === "") {
            if (actualTab === "all") {
                filterCourse = {
                    entityId: props?.user.idSchool, entityType: "SCHOOL", pageSize: 999,
                    idProf :selectedProf,
                    pageNumber: 0
                };
                await props?.getCoursesForDirector(filterCourse, 'yes')
            } else if (actualTab === "online") {
                filterCourse = {
                    entityId: props?.user.idSchool, entityType: "SCHOOL", status: "PUBLISHED", pageSize: 1000,
                    idProf :selectedProf,
                    pageNumber: 0
                };
                await props?.getOnlineCoursesForDirector(filterCourse,'yes')

            } else {
                filterCourse = {
                    entityId: props?.user.idSchool, entityType: "SCHOOL", status: "PENDING", 
                    idProf :selectedProf,
                    pageSize: 1000,
                    pageNumber: 0
                };
                await props?.getPendingCoursesForDirector(filterCourse,'yes')
            }
        } else {
            if (actualTab === "all") {
                filterCourse = {
                    entityId: props?.user.idSchool, entityType: "SCHOOL",idProf :selectedProf, pageSize: 1000,search :e,
                    pageNumber: 0
                };
                await props?.getCoursesForDirector(filterCourse, 'yes')
            } else if (actualTab === "online") {
                filterCourse = {
                    entityId: props?.user.idSchool, entityType: "SCHOOL", status: "PUBLISHED", pageSize: 1000,search :e,
                    idProf :selectedProf,
                    pageNumber: 0
                };
                await props?.getOnlineCoursesForDirector(filterCourse,'yes')

            } else {
                filterCourse = {
                    entityId: props?.user.idSchool, entityType: "SCHOOL", status: "PENDING",    idProf :selectedProf, pageSize: 999,search :e,
                    pageNumber: 0
                };
                await props?.getPendingCoursesForDirector(filterCourse,'yes')
            }
        }
        props?.doFilter({ search: e , searchProfessors: idProfessor});

    }

    const debounceSearchCourse = useCallback(debounce(filterProfbySearch, 1000), []);
    return (
        <div className="row justify-content-center">
            <div className="col-md-12">
                <div className="row mt-4">
                    <div className="col-md-12 d-flex justify-content-between" style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <button type="button" style={{ minHeight: '45px', marginRight: '10px' }} className="btn-Secondary large icon-left" onClick={() => exportData()}><span className="material-icons-outlined" >download</span> <div className="m-auto ps-2">{t('exportListe')}</div></button>
                        <div className="d-flex align-items-center flex-wrap">
                            <div style={{ maxWidth: '225px', minWidth: '225px', marginRight: '10px' }}>
                                <SelectElement disabled={false} taille={'lg'} defaultValue={defaultValueProfessors} options={professors} onChange={(e: any) => { filterProf(e)}} placeholder={t('filterProfessors')} />

                            </div>
                            {/* <div style={{ maxWidth: '225px', marginRight: '10px' }}>
                            <SelectElement disabled={false} options={status} onChange={(e: any) => { setFilter({...filter, status: e }) }} placeholder={t('statusFilter')} />
                        </div> */}
                            <div style={{ maxWidth: '225px' }} className="input-form-control">
                                <input dir="ltr"type="text" className="form-control input text-default" placeholder={t('searchProfessors')} value={search} onChange={(e: any) => { setSearch(e.target.value); debounceSearchCourse(e.target.value,   idProfessor) }} />
                                {/* <span> */}
                                    <span className="material-icons-outlined"> search </span>
                                {/* </span> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >

    );
}

const mapStateToProps = (state: AppState) => ({
    allCourses: state.courseReducer.allCourses,
    user: state.authReducer.connectedUser,
    school: state.authReducer.connectedSchoolInfo,
    userInfo: state.authReducer.connectedUserInfo,
    actualTabAllCourses: state.courseReducer.actualTabAllCourses,
  
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({ clearHistory, getCoursesForDirector , getOnlineCoursesForDirector ,getPendingCoursesForDirector }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FilterAllCoursesComponent);
