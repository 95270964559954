import { debounce } from "lodash";
import { FC, useCallback, useEffect, useState } from "react";

interface LigneProps {
    ajouterLigne: any;
    supprimerLigne: any;
    showAddButton: any;
    showDeleteButton: any;
    addPrice: any;
    data: any,
    uniqueChecked: any,
    disable: any
}
const TarificationPeriod: FC<LigneProps> = ({ ajouterLigne, supprimerLigne, showAddButton, showDeleteButton, addPrice, data, uniqueChecked, disable }) => {
    const [price, setPrice] = useState<any>(data.mp);
    const [period, setPeriod] = useState<any>(data.due);

    const [priceClass, setPriceClass] = useState<any>('form-control input text-default')
    const [priceEror, setPriceError] = useState<any>('')
    const [periodClass, setPeriodClass] = useState<any>('form-control input text-default')
    const addData = async (price: any, period: any, checked: any) => {
        if (price == 0 || period == 0) return

        if (price !== undefined && period !== undefined) {
            data.mp = price;
            data.due = period;

            addPrice(data, checked)
        }
    }

    const deleteData = async () => {
        supprimerLigne(data.index, price, period)
    }


    useEffect(() => {
        if (price == '0') {
            setPriceClass("form-control input text-default error")
            setPriceError('Mensualité erroné')
        } else {
            setPriceClass("form-control input text-default")
            setPriceError('')
        }
    }, [price])

    useEffect(() => {
        if (period == '0') {
            setPeriodClass("form-control input text-default error")
        } else {
            setPeriodClass("form-control input text-default")
        }
    }, [period])


    const debouncePricePeriod = useCallback(debounce(addData, 1000), []);
    return (
        <div className="d-flex align-items-top flex-row" style={{ marginLeft: "30px", marginTop: "20px" }}>
            <div className="d-flex align-items-top flex-column" style={{ marginRight: "30px" }}>
            <div className="d-flex align-items-top flex-column" style={{ marginRight: "30px" }}>

                <label className="form-edit" style={{ marginBottom: "8px" }}>Mensualité</label>
                <div className="input-form-control">
                    <input type="number" className={priceClass} placeholder=""
                        style={{
                            WebkitAppearance: "none",
                            MozAppearance: "textfield",
                            appearance: "none",
                            margin: 0
                        }}
                        value={price}
                        onChange={(e: any) => {
                            setPrice(e.target.value);
                            debouncePricePeriod(e.target.value, period, uniqueChecked)
                        }}
                        disabled={disable}
                        min="1"
                    />
                    <span style={{fontWeight:500}} >
                        TTC
                    </span>
                </div>
                <span className="error-input2">
                    {priceEror}
                </span>
                </div>
            </div>
            <div className="d-flex align-items-top flex-column" style={{ marginLeft: "45px" }}>
                <label className="form-edit" style={{ marginBottom: "8px" }}>Nombre d’échéance</label>
                <div className="input-form-control">
                    <input type="number" className={periodClass} placeholder=""
                        style={{
                            WebkitAppearance: "none",
                            MozAppearance: "textfield",
                            appearance: "none",
                            margin: 0,
                            padding: "12px 12px",
                            height: "50px!Important",
                        }}
                        value={period}
                        onChange={(e: any) => {
                            setPeriod(e.target.value);
                            debouncePricePeriod(price, e.target.value, uniqueChecked)
                        }}
                        min="0"
                        step="1"
                        disabled={disable}

                        onKeyPress={(e: any) => {
                            if (e.key === "." || e.key === "-") {
                                e.preventDefault(); // prevent decimal and negative values
                            }
                        }}
                    />
                    <span  style={{fontWeight:500}}>
                        Mois
                    </span>
                </div>
            </div>
            {showAddButton &&
                <div className="d-flex  flex-column" style={{ marginLeft: "44px", marginTop: "32px" }}>
                    <button disabled={disable}
                        type="button" className="btn-QuickActions" onClick={ajouterLigne}>
                        <span className="material-icons-outlined">add</span>
                    </button></div>}
            {showDeleteButton &&
                <div className="d-flex flex-column" style={{ marginLeft: "44px", marginTop: "32px" }}>
                    <button
                        disabled={disable}
                        type="button" className="btn-QuickActions" onClick={deleteData}>
                        <span className="material-icons-outlined">delete</span>
                    </button>
                </div>}

        </div>
    );
}

export default TarificationPeriod;
