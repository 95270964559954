import { ComponentProps, FC, useEffect, useState } from 'react'
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { connect } from "react-redux";
import "../../translations/i18n";
import { useTranslation } from 'react-i18next';
import SelectElement from '../../elements/select/select-element';
import { getUserProfileDetails, setConnectedUser, updateProfile } from '../../global-state/actions/auth-actions';
import { getUserById } from '../../global-state/actions/user-auth-actions';
import axios from "../../helpers/interceptors";
import { getDomainFromUrl } from '../../helpers/domainCheck';
const UpdateProfileInfosComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    let dateFormat = require('dateformat');
    const { t } = useTranslation();
    const [userProfileDetails, setUserProfileDetails] = useState<any>(props?.userProfileDetails);
    const [firstName, setFirstName] = useState(props?.userProfileDetails?.firstName);
    const [typeBirthdate, setTypeBirthdate] = useState<any>((props?.userProfileDetails?.dateNaiss !== null) ? props?.userProfileDetails?.dateNaiss : "");
    const [country, setCountry] = useState(props?.userProfileDetails?.country);
    const [lastName, setLastName] = useState(props?.userProfileDetails?.lastName);
    const [city, setCity] = useState(props?.userProfileDetails?.city);
    const [gender,setGender] = useState(props?.userProfileDetails?.gender);
    const [domain, setDomain] = useState(props?.userProfileDetails?.schooling);
    const [check, setCheck] = useState<any>(true)
    const [msg, setMsg] = useState<any>("")
    const [options] = useState([
        { label: t('Primaire'), value: "Primaire" },
        { label: t('College'), value: "Collège" },
        { label: t('Lycee'), value: "Lycée" },
        { label: t('Bac'), value: "Bac" },
        { label: t('Technicien2'), value: "Technicien (Bac +2)" },
        { label: t('License'), value: "Licence (Bac+3)" },
        { label: t('Master'), value: "Master ou équivalent (BAC +4 ou + 5)" },
        { label: t('Ingenieur'), value: "Ingénieur" },
        { label: t('Doctorat'), value: "Doctorat" },
    ])
    const [countryOptions, setCountryOptions] = useState([
        { label: t("France"), value: "FR" },
        { label: t("Tunisie"), value: "TN" },
    ]);
    const [email, setEmail] = useState(props?.userProfileDetails?.email);
    const [photo, setPhoto] = useState<any>(props?.userProfileDetails?.photo);
    const [open, setOpen] = useState(false);

    const uiGender:any ={
        "MALE":t('male'),
        "FEMALE":t('female')
    }

    
   
    
    useEffect(() => {
        setUserProfileDetails(props?.userProfileDetails);
        props?.setConnectedUser(props?.userProfileDetails)


        setFirstName(props?.userProfileDetails?.firstName);
        setTypeBirthdate((props?.userProfileDetails?.dateNaiss) ? props?.userProfileDetails?.dateNaiss : "");
        setCountry(props?.userProfileDetails?.country);
        setLastName(props?.userProfileDetails?.lastName)
        setCity(props?.userProfileDetails?.city) 
        setDomain(props?.userProfileDetails?.schooling)
         setEmail(props?.userProfileDetails?.email);
        setPhoto(props?.userProfileDetails?.photo);
        setGender(props?.userProfileDetails?.gender)
        
    }, [props?.userProfileDetails])
    useEffect(() => {
        if (new Date(typeBirthdate) >= new Date(Date.now())) {
            setCheck(false);
            setMsg(t("wrongBirthDate"))
        } else {
            setCheck(true);
            setMsg("")
        };

    }, [typeBirthdate])
    const updateProfile = async () => {
        const updatedProfile = {
            id: props?.connectedUser?.idPerson,
            email: email,
            lastName: lastName,
            firstName: firstName,
            dateNaiss: typeBirthdate,
            photo: photo,
            schooling: domain,
            country: country,
            city: city,
            gender:gender,
            domainUrl: getDomainFromUrl()
        }

        await props?.updateProfile(updatedProfile).then(async(resp:any)=>{
            if(!!props?.connectedUser?.role && props?.connectedUser?.role=="role_director" && process.env.REACT_APP_BASE_HUBSPOT === "true"){
                const object = {
                    "properties": {
                      "email": email,
                      "firstname": firstName,
                      "lastname": lastName,
                    //   "lifecyclestage":"customer",
                    //   "hs_lead_status":"OPEN"
                    }
                  }
                  await axios.post(process.env.REACT_APP_BASE_URL21 + "hubspot/updateContact", object )
              
            }
        });
        await props?.getUserProfileDetails(props?.connectedUser?.id)
        await props?.updateProfile(updatedProfile);
        setOpen(false);
    }

    async function profileDomain (filter: string) {
        setDomain(filter);
    }

    async function profileCountry(filter: string) {
        setCountry(filter);
    }

    const cancelUpdate = () => {
        setOpen(false);
        props?.getUserProfileDetails(props?.connectedUser?.id, 'dont')  //person
    }

    useEffect(() => {
        setUserProfileDetails(props?.userProfileDetails);
        setPhoto(props?.userProfileDetails?.photo);
    }, [props?.userProfileDetails?.photo])

    return (
        <div className="profile-info-component ">
            
                
                    <div className="row " >
                        <div className="d-flex align-items-center justify-content-between">
                            <span className="H3-Headline">{t("Profile")}</span>
                            
                            <div className='first-action-profile'>                    
                        <button className="btn-text outlined first-letter-capitalize" onClick={(e) => setOpen(!open)}>{t("modifyProfil")}</button>
                    </div>
                    <div className='second-action-profile'>
                        <button type="button" className="btn-QuickActions medium" onClick={(e) => setOpen(!open)}><span className="material-icons-outlined">edit</span></button>
                    </div>
                        </div>
                    </div>
                    {(open === true) ? (
                        <div className="d-flex flex-row profile-edit-small" style={{ paddingTop: "24px", gap: 24 }}>
                            <div className="w-100 d-flex flex-column">
                                <div >
                                    <span className="pt-2 form-title">{t('Prenom')}</span>
                                    <input dir="ltr"type="text" className="form-control input text-default mt-2" placeholder={firstName} value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                </div>
                                {/* <div style={{ marginTop: "24px" }}>
                                    <span className="pt-2 form-title ">{t('country')}</span>
                                    <div className="mt-2">
                                        <SelectElement disabled={false} taille={'lg'} defaultValue={country} options={countryOptions} placeholder={t('selectCountry')} onChange={profileCountry} />
                                    </div>
                                </div> */}
                                <div style={{ marginTop: "24px" }}>
                                    <span className="form-title pt-2">{t('birthdate')}</span>
                                    <input dir="ltr"type="date" name="date" className="form-control input input-date text-default mt-2" placeholder={typeBirthdate} value={typeBirthdate.split('T')[0]} onChange={(e) => setTypeBirthdate(e.target.value)} max={new Date().toISOString().split("T")[0]}/>
                                    {msg&&<div className="mt-1">
                                        <label className="error-input mt-1">{msg}</label>
                                    </div>}
                                </div>
                                <div style={{ marginTop: "24px" }}>
                                    <span className="pt-2 form-title">{t('NivScolaire')}</span>
                                    <div className="mt-2">
                                        <SelectElement disabled={false} taille={'lg'} defaultValue={domain} options={options} placeholder={t('NivScolaire')} onChange={profileDomain} />
                                    </div>
                                </div>
                            </div>
                            <div className="w-100 d-flex flex-column">

                                <div>
                                    <span className="form-title" >{t('Nom')}</span>
                                    <input dir="ltr"type="text" className="form-control input text-default mt-2" placeholder={lastName} value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                </div>
                                <div style={{ marginTop: "24px" }}>
                                    <span className="pt-2 form-title ">{t('city')}</span>
                                    <input dir="ltr"type="text" className="form-control input text-default mt-2" placeholder={city} value={city} onChange={(e) => setCity(e.target.value)} />
                                </div>
                                <div style={{ marginTop: "24px" }}>
                                    <span className="pt-2 form-title ">{t("gender")}</span>
                                    <div className='d-flex flex-row mt-4'>
                                    <input id="homme" className="input-style-radio " type="radio" checked={gender==="MALE"} value="MALE" onChange={(e) => setGender("MALE")} />
                                    <label htmlFor="homme" ></label>
                                    <span className="form-title ps-1 ms-2 me-3">{t('male')}</span>
                                    <input id="femme" type="radio" className="input-style-radio " checked={gender==="FEMALE"} value="FEMALE" onChange={(e) => setGender("FEMALE")} />
                                    <label htmlFor="femme" ></label>
                                    <span className="form-title ps-1 ms-2">{t('female')}</span>
                                    </div>
                                </div>
                                <div className="d-flex flex-row justify-content-end" style={{marginTop:'140px'}}>
                                    <button type="button" className="btn-Secondary large" style={{ marginRight: "12px" }} onClick={cancelUpdate}>
                                        {t('annuler')}
                                    </button>
                                    <button type="button" className="btn-Primary large" onClick={updateProfile} disabled={!check}>
                                        {t('modifier')}
                                    </button>
                                </div>

                            </div>
                        </div>
                    ) : (
                        <div className="row" style={{ paddingTop: "22px" , width:"100%" }}>
                            <div className="col-md-6" style={{ display: "flex", flexDirection: "column" }}>
                                <div style={{ display: "flex", flexDirection: "column" }} >
                                    <span className="form-title" style={{ color: "#CACBCE" }} >{t('Prenom')}</span>
                                    { (firstName?.length !=0 && firstName!=null) ? <span className="form-title">{firstName}</span> :<span className="form-title ">-</span>}
                                </div>
                                {/* <div style={{ display: "flex", flexDirection: "column" }}>
                                    <span className="form-title " style={{ color: "#CACBCE" }} >{t('country')}</span>
                                    {(country === "TN") ? <>
                                        <span className="form-title ">{t('Tunisie')}</span>
                                    </> : (country==="FR") ? <>
                                        <span className="form-title ">{t('France')}</span>
                                    </> :<>
                                    <span className="form-title ">-</span>
                                    </>}

                                </div> */}
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <span className="form-title " style={{ color: "#CACBCE" }} >{t('birthdate')}</span>
                                    {(props?.userProfileDetails?.dateNaiss !== null &&  props?.userProfileDetails?.dateNaiss?.length !=0) ? <span className=" form-title mb-0">{dateFormat((props?.userProfileDetails?.dateNaiss !== null) ? props?.userProfileDetails?.dateNaiss : "", "dd-mm-yyyy")}</span> : <span className="form-title  mb-0">-</span> }

                                </div>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <span className="form-title " style={{ color: "#CACBCE" }} >{t('NivScolaire')}</span>
                                   { (domain!=null && domain?.length!=0) ?
                                        <span className="form-title  mb-0">{domain}</span> : <span className="form-title  mb-0">-</span> 
                                   }   
                                </div>
                            </div>
                            <div className="col-md-6">

                                <div style={{ display: "flex", flexDirection: "column" }} >
                                    <span className="form-title" style={{ color: "#CACBCE" }} >{t('Nom')}</span>
                                    { (lastName?.length!=0 && lastName!=null) ? <span className="form-title ">{lastName}</span> : <span className="form-title ">-</span>}
                                </div>
                                {/* <div style={{ display: "flex", flexDirection: "column" }}>
                                    <span className="form-title " style={{ color: "#CACBCE" }} >{t('city')}</span>
                                    { (city && city !== "" && city!=null) ? <span className="form-title ">{city}</span> : <span className="form-title ">-</span>}
                                </div> */}
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <span className="form-title " style={{ color: "#CACBCE" }} >{t('gender')}</span>
                                    { (gender && gender !== "" && gender!=null) ? <span className="form-title ">{uiGender[gender]}</span> : <span className="form-title ">-</span>}
                                </div>                
                            </div>
                        </div>
                    )}
                


            
            <div className="mt-4">
                <hr style={{ border: "1px solid #f8f8fa" }} />
            </div>
        </div>
    )
}


const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    userProfileDetails: state.authReducer.connectedUserInfo
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    getUserProfileDetails,
    updateProfile,
    getUserById,
    setConnectedUser
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProfileInfosComponent);
