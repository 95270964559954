import { ComponentProps, FC, useState } from 'react'
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { connect } from "react-redux";
import "../../translations/i18n";
import { useTranslation } from 'react-i18next';
import { changePassword, getUserProfileDetails } from '../../global-state/actions/auth-actions';
import { openModal } from '../../global-state/actions/modal-actions';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from '@material-ui/lab/Alert';


const UpdatePasswordComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();
    const [password, setPassword] = useState<string>();
    const [newPasswords, setNewPasswords] = useState<string>();
    const [confirmedPassword, setConfirmedPassword] = useState<string>()
    const [open, setOpen] = useState<boolean>(false);
    let [showP, SetShowP] = useState<boolean>(false);
    let [showNewPassword, SetShowNewPassword] = useState<boolean>(false);
    const [showConfirmedPassword, setshowConfirmedPassword] = useState<boolean>(false);
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [wrongNewPassword, setWrongNewPassword] = useState(false)

    const changePassword = async () => {
        const checkPassword = {
            password: password,
            email: props?.userProfileDetails?.email
        }

        const newPassword = {
            id: props?.userProfileDetails?.id,
            password: newPasswords,
            email: props?.userProfileDetails?.email,
            firstName: props?.userProfileDetails?.firstName,
            lastName: props?.userProfileDetails?.lastName,
            domain: undefined
        }
        if (confirmedPassword === newPasswords) {
            await props?.changePassword(checkPassword, newPassword);
            setSnackBarOpen(true);
            setOpen(false);
            setNewPasswords("");
            setConfirmedPassword("");
            setPassword("");
        } else {
            setWrongNewPassword(true);

            setNewPasswords("");
            setConfirmedPassword("");
            setPassword("");
        }
    }
    const cancelUpdate = () => {
        setOpen(false);
        setNewPasswords("");
        setConfirmedPassword("");
        setPassword("");
        props?.getUserProfileDetails(props?.connectedUser?.id)

    }

    return (
        // <div className="d-flex justify-content-end w-100" style={{  margin: 'auto' }}  >
        //     <div className="justify-content-end"  >
                // <div className="d-flex flex-column">
                //     <div className="row">
                //         <div className="d-flex align-items-center justify-content-between">
                //             <span className="H3-Headline">{t("password")}</span>
                //             <button className="btn-text outlined first-letter-capitalize" onClick={(e) => setOpen(!open)}>{t("updatePass")}</button>
                //         </div>
                //     </div>
                    // {open === true &&
                    //     <div>
                    //         <div className="row"  >
                    //             <span className="form-title " > {t("actualPass")} </span>


                    //             <div className="col-md-6 input-form-control" style={{ paddingTop: "15px" }}>
                    //                 <input dir="ltr"type={showP ? "text" : "password"} className="form-control input text-default" placeholder={t('actualPass')} autoComplete="new-password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    //                 {!showP && password === '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#B1B1B1', paddingTop: "15px", paddingRight: '10px' }} onClick={() => SetShowP(!showP)}>visibility_off</span>}
                    //                 {showP && password === '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#B1B1B1', paddingTop: "15px", paddingRight: '10px' }} onClick={() => SetShowP(!showP)}>visibility</span>}
                    //                 {!showP && password !== '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#272727', paddingTop: "15px", paddingRight: '10px' }} onClick={() => SetShowP(!showP)}>visibility_off</span>}
                    //                 {showP && password !== '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#272727', paddingTop: "15px", paddingRight: '10px' }} onClick={() => SetShowP(!showP)}>visibility</span>}
                    //             </div>
                    //         </div>

                    //         <div className="row" style={{ marginTop: "24px" }}>

                    //             <div className="col-md-6">
                    //                 <div >
                    //                     <span className="form-title " >{t("newPass")} </span>
                    //                 </div>
                    //                 <div className="input-form-control" style={{ paddingTop: "15px" }}>
                    //                     <input dir="ltr"type={showNewPassword ? "text" : "password"} className="form-control input text-default" placeholder={t('newPass')} value={newPasswords} onChange={(e) => setNewPasswords(e.target.value)} />
                    //                     {!showNewPassword && newPasswords === '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#B1B1B1', paddingTop: "15px" }} onClick={() => SetShowNewPassword(!showNewPassword)}>visibility_off</span>}
                    //                     {showNewPassword && newPasswords === '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#B1B1B1', paddingTop: "15px" }} onClick={() => SetShowNewPassword(!showNewPassword)}>visibility</span>}
                    //                     {!showNewPassword && newPasswords !== '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#272727', paddingTop: "15px" }} onClick={() => SetShowNewPassword(!showNewPassword)}>visibility_off</span>}
                    //                     {showNewPassword && newPasswords !== '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#272727', paddingTop: "15px" }} onClick={() => SetShowNewPassword(!showNewPassword)}>visibility</span>}
                    //                 </div>
                    //             </div>

                    //             <div className="col-md-6" style={{ display: "flex", flexDirection: "column" }} >
                    //                 <span className="form-title ">{t("confimrNewPass")} </span>
                    //                 <div className="input-form-control">
                    //                     <input dir="ltr"type={showConfirmedPassword ? "text" : "password"} className="form-control input text-default" placeholder={t('confimrNewPass')} autoComplete="new-password" value={confirmedPassword} onChange={(e) => setConfirmedPassword(e.target.value)} />
                    //                     {!showConfirmedPassword && confirmedPassword === '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#B1B1B1' }} onClick={() => setshowConfirmedPassword(!showConfirmedPassword)}>visibility_off</span>}
                    //                     {showConfirmedPassword && confirmedPassword === '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#B1B1B1' }} onClick={() => setshowConfirmedPassword(!showConfirmedPassword)}>visibility</span>}
                    //                     {!showConfirmedPassword && confirmedPassword !== '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#272727' }} onClick={() => setshowConfirmedPassword(!showConfirmedPassword)}>visibility_off</span>}
                    //                     {showConfirmedPassword && confirmedPassword !== '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#272727' }} onClick={() => setshowConfirmedPassword(!showConfirmedPassword)}>visibility</span>}
                    //                 </div>
                    //                 <div style={{ marginTop: "32px", marginLeft: "auto" }}>
                    //                     <button type="button" className="btn-Secondary large" style={{ marginRight: "12px" }} onClick={cancelUpdate} >
                    //                         {t('annuler')}
                    //                     </button>
                    //                     <button disabled={password === '' || confirmedPassword === '' || newPasswords === ''} type="button" className="btn-Primary large" onClick={changePassword}  >
                    //                         {t('modifier')}
                    //                     </button>
                    //                 </div>
                    //             </div>
                    //         </div>
                    //     </div>


                    // }


                    // <Snackbar open={wrongNewPassword} autoHideDuration={3000} onClose={(e) => setSnackBarOpen(false)}>
                    //     <Alert severity="error">
                    //     {t('passwordNoMatch')}
                    //     </Alert>
                    // </Snackbar>
                    // <div className="row">
                        
                    //     <div className="col-md-12">
                    //         <hr className="mb-5" style={{ border: "1px solid #F2F2F5" }} />
                    //     </div>
                    // </div>
        //         </div>
        //     </div>
        // </div>

        <div className='w-100'>
            <div className="d-flex flex-column">
                <div className="row">
                    <div className="d-flex align-items-center justify-content-between">
                        <span className="H3-Headline">{t("password")}</span>


                        <div className='first-action-profile'>                    
                        <button className="btn-text outlined first-letter-capitalize" onClick={(e) => setOpen(!open)}>{t("updatePass")}</button>
                    </div>
                    <div className='second-action-profile'>
                        <button type="button" className="btn-QuickActions medium" onClick={(e) => setOpen(!open)}><span className="material-icons-outlined">edit</span></button>
                    </div>
                    </div>
                </div>
            </div>



            {open === true &&
                        <div>
                            <div className="d-flex flex-column w-49 mt-4 password-detail-edit-small"  style={{width: "49%!important"}}>
                                <label className="form-title " > {t("actualPass")} </label>
                                <div className="input-form-control">


                                    <input dir="ltr"type={showP ? "text" : "password"} className="form-control input text-default" placeholder={t('actualPass')} autoComplete="new-password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                    {!showP && password === '' && <span className="material-icons" style={{ cursor: 'pointer' }} onClick={() => SetShowP(!showP)}>visibility_off</span>}
                                    {showP && password === '' && <span className="material-icons" style={{ cursor: 'pointer' }} onClick={() => SetShowP(!showP)}>visibility</span>}
                                    {!showP && password !== '' && <span className="material-icons" style={{ cursor: 'pointer' }} onClick={() => SetShowP(!showP)}>visibility_off</span>}
                                    {showP && password !== '' && <span className="material-icons" style={{ cursor: 'pointer' }} onClick={() => SetShowP(!showP)}>visibility</span>}
                                </div>
                            </div>

                            <div className="d-flex mt-4 password-edit-small" style={{gap: 24}}>

                                <div className="d-flex w-100 flex-column password-edit-update">
                                    <div >
                                        <span className="form-title " >{t("newPass")} </span>
                                    </div>
                                    <div className="input-form-control" style={{ paddingTop: "15px" }}>
                                        <input dir="ltr"type={showNewPassword ? "text" : "password"} className="form-control input text-default" placeholder={t('newPass')} value={newPasswords} onChange={(e) => setNewPasswords(e.target.value)} />
                                        {!showNewPassword && newPasswords === '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#B1B1B1', paddingTop: "15px" }} onClick={() => SetShowNewPassword(!showNewPassword)}>visibility_off</span>}
                                        {showNewPassword && newPasswords === '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#B1B1B1', paddingTop: "15px" }} onClick={() => SetShowNewPassword(!showNewPassword)}>visibility</span>}
                                        {!showNewPassword && newPasswords !== '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#272727', paddingTop: "15px" }} onClick={() => SetShowNewPassword(!showNewPassword)}>visibility_off</span>}
                                        {showNewPassword && newPasswords !== '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#272727', paddingTop: "15px" }} onClick={() => SetShowNewPassword(!showNewPassword)}>visibility</span>}
                                    </div>
                                </div>

                                <div className="d-flex w-100 flex-column" >
                                    <span className="form-title ">{t("confimrNewPass")} </span>
                                    <div className="input-form-control">
                                        <input dir="ltr"type={showConfirmedPassword ? "text" : "password"} className="form-control input text-default" placeholder={t('confimrNewPass')} autoComplete="new-password" value={confirmedPassword} onChange={(e) => setConfirmedPassword(e.target.value)} />
                                        {!showConfirmedPassword && confirmedPassword === '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#B1B1B1' }} onClick={() => setshowConfirmedPassword(!showConfirmedPassword)}>visibility_off</span>}
                                        {showConfirmedPassword && confirmedPassword === '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#B1B1B1' }} onClick={() => setshowConfirmedPassword(!showConfirmedPassword)}>visibility</span>}
                                        {!showConfirmedPassword && confirmedPassword !== '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#272727' }} onClick={() => setshowConfirmedPassword(!showConfirmedPassword)}>visibility_off</span>}
                                        {showConfirmedPassword && confirmedPassword !== '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#272727' }} onClick={() => setshowConfirmedPassword(!showConfirmedPassword)}>visibility</span>}
                                    </div>
                                    <div style={{ marginTop: "32px", marginLeft: "auto" }}>
                                        <button type="button" className="btn-Secondary large" style={{ marginRight: "12px" }} onClick={cancelUpdate} >
                                            {t('annuler')}
                                        </button>
                                        <button disabled={password === '' || confirmedPassword === '' || newPasswords === ''} type="button" className="btn-Primary large" onClick={changePassword}  >
                                            {t('modifier')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>


                    }
                    <Snackbar open={snackBarOpen} autoHideDuration={3000} onClose={(e) => setSnackBarOpen(false)}>
                        <Alert severity="success">
                        {t('passwordUpdated')}
                        </Alert>
                    </Snackbar>

                    <Snackbar open={wrongNewPassword} autoHideDuration={3000} onClose={(e) => setSnackBarOpen(false)}>
                        <Alert severity="error">
                        {t('passwordNoMatch')}
                        </Alert>
                    </Snackbar>





            <div className="mt-4">
                <hr style={{ border: "1px solid #F2F2F5" }} />
            </div>

        </div>

    )
}




const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    userProfileDetails: state.authReducer.connectedUserInfo
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    getUserProfileDetails,
    changePassword,
    openModal,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePasswordComponent);
