import { ComponentProps, FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { AppState } from "../../global-state/store/root-reducers";
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import StarOutline from '@material-ui/icons/StarOutline';
import { createFeedback } from "../../global-state/actions/feedback-actions";
import TextareaUnderlineElement from "../../elements/text-area/text-area-underline-element";
import { closeModal } from "../../global-state/actions/modal-actions";
import { createFeedbackApi, getMyFeedbackApi } from "../../services/feedBack.service";
import Skeleton from "@material-ui/lab/Skeleton";

const FeedbackTrainingRateComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const [id, setId] = useState<any>(null);
    const [idSubject, setIdSubject] = useState<any>(null);
    const [rate, setRate] = useState<any>(0);
    const [text, setText] = useState<string>("");
    const [title, setTitle] = useState<string>("");
    const [subjectType, setSubjectType] = useState<string>("");
    const [feedbackForm, setFeedbackForm] = useState<any>(undefined);
    const [loading, setLoading] = useState<boolean>(true);
    useEffect(() => {
        if (feedbackForm) {
            setId(feedbackForm?.id);
            setRate(feedbackForm?.rate)
            setText(feedbackForm?.text)
            setTitle(feedbackForm?.title)
        }
    }, [feedbackForm])
    useEffect(() => {
        getMyFeedback()
    }, [])

    useEffect(() => {
        setIdSubject(props?.coursInfo?.id);
        setSubjectType(props?.coursInfo?.type)
    }, [props?.coursInfo])

    const createFeedback = async (object: any) => {
        const result = await createFeedbackApi(object)
        setFeedbackForm(result)
    }

    const getMyFeedback = async () => {
        try {
            const result = await getMyFeedbackApi({
                idPerson: props?.connectedUser?.idPerson,
                idSubject: props?.coursInfo?.id,
                subjectType: props?.coursInfo.type
            });
            setFeedbackForm(result);
        } catch (error) {
            console.error('Error fetching feedback:', error);
        } finally {
            setLoading(false);
        }
    }

    const addOpinionButton = async () => {
        let object = {
            id: id,
            idSubject: idSubject,
            idPerson: props?.connectedUser?.idPerson,
            rate: rate,
            text: text,
            title: title,
            subjectType: subjectType,
            userStatus: "USER",
        };
        await createFeedback(object);
        if (props?.modalContent) {
            await props?.closeModal();
        }
    }
    if (loading) {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-12 d-flex flex-column align-items-center">
                        <Skeleton variant="text" width={600} height={100} />
                        <Skeleton variant="text" width={500} height={80} />
                        <Skeleton variant="text" width={400} height={40} />
                        <Skeleton variant="text" width={200} height={20} />
                        <Skeleton variant="text" width={100} height={10} />
                    </div>
                </div>
            </div>
        );
    }
    return (
        <>
            <div className="container">
                <div className="row " >
                    {(feedbackForm === undefined) &&
                        <div className="col-md-12 center">
                            <div className="row justify-content-center">
                                <div className="col-md-12 text-center">
                                    <span className={props.isMobile ? "H3-Headline" : "H2-Headline"}>{t('donnerAvis')}</span><br />
                                    {subjectType === "COURSE" && <span className={props.isMobile ? "H3-Headline" : "H2-Headline"}>{t('donnerAvis_2')}</span>}
                                    {subjectType === "PATH" && <span className={props.isMobile ? "H3-Headline" : "H2-Headline"}>{t('avisTitle1')}</span>}
                                    {subjectType === "SESSION" && <span className={props.isMobile ? "H3-Headline" : "H2-Headline"}>{t('avisTitle2')}</span>}

                                </div>
                                <div className="col-md-9 mt-2 mb-2 text-center">
                                    <p className={props?.isMobile ? "H4-Subtitle neutral-3" : "H3-Headline neutral-3"}>{props?.coursInfo?.title}</p>
                                </div>
                                <div className="col-md-9 text-center mb-3 rating ">
                                    {/* <Rating name="size-large" value= {rate} precision={1} 
                                    size="large" 
                                    emptyIcon={<StarBorderIcon   color="primary" fontSize="inherit"/>}
                                    onChange={(e,newValue) => { setRate(newValue) }}/> */}
                                    <Box component="fieldset" borderColor="transparent" height="24px">
                                        <Rating
                                            value={rate}
                                            size="large"
                                            precision={1}
                                            emptyIcon={<StarOutline fontSize="inherit" htmlColor="#DADBDF" />}
                                            style={{ color: "#F5B95F" }}
                                            onChange={(event, newValue) => { setRate(newValue) }}
                                        />
                                    </Box>
                                </div>
                                <div className={props.isMobile ? "col-md-10 my-4" : "col-md-10 mb-3"}>
                                    <label className="form-title">{t('avisTitle')}</label>
                                    <input dir="ltr" type="text" value={title} className="form-control input text-default" onChange={(e) => { setTitle(e.target.value) }} placeholder={t('avisTitle')} />


                                </div>

                                <div className="col-md-10 mb-3">
                                    <label className="form-title">{t('avisText')}</label>
                                    <TextareaUnderlineElement style={true} placeholder={t('votreAvis')} value={text} onChange={(value: string) => setText(value)} id={1} />

                                </div>
                                {
                                    props.isMobile ?
                                        <div className="d-flex align-items-center justify-content-center">
                                            <button type="button" className="btn-Primary large" onClick={() => { addOpinionButton() }} disabled={(text === '')}>{t('publishMyOpinion')}</button>
                                        </div>
                                        :
                                        <div className="col-md-10 mt-4 d-flex align-items-center justify-content-end">
                                            <button type="button" className="btn-Primary large" onClick={() => { addOpinionButton() }} disabled={(text === '')}>{t('publishMyOpinion')}</button>
                                        </div>

                                }

                            </div>
                        </div>}
                    {(feedbackForm) &&
                        <div className="col-md-12 center">
                            <div className="row justify-content-center">
                                <div className="col-md-12 mb-3 text-center">
                                    <span className="H2-Headline black-800">{t('monAvis')}</span>
                                </div>
                                <div className="col-md-12 mb-3 pb-2 mt-2 text-center">
                                    <span className="H3-Headline neutral-3">{props?.coursInfo?.title}</span>
                                </div>
                                <div className="col-md-12 text-center feedback-component mb-4 pb-2">

                                    <Box component="fieldset" borderColor="transparent" height="24px">
                                        <Rating
                                            readOnly
                                            value={rate}
                                            precision={0.1}
                                            size="large"
                                            emptyIcon={<StarOutline fontSize="inherit" htmlColor="#DADBDF" />}
                                            style={{ color: "#F5B95F" }}
                                        />
                                    </Box>
                                </div>
                                <div className="col-md-12 mb-3 input-text-form-control text-center">
                                    <span className="body-md bold black-800">{title}</span>
                                </div>
                                <div className="col-md-7  text-center">
                                    <span className="body-md neutral-2">{text}</span>
                                </div>
                            </div>
                        </div>}
                </div>
            </div>
        </>
    );
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({ createFeedback, closeModal }, dispatch);

const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    coursInfo: ownProps?.coursInfo,
    modalContent: state.modalReducer.modalContent,
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackTrainingRateComponent);
