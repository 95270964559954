import { mySteps } from "../../constants/mySteps";
import {
  EReduxActionTypes,
  IReduxHandleCallbackAction,
  IReduxHandleReloadAction,
  IReduxHandleStartAction,
  IReduxHandleStartProfUserGuideAction,
} from "../actions/user-guide-actions";

export interface IReduxUserGuideState {
  userGuide: {
    run: boolean;
    stepIndex: number;
    steps: any[];
    tourActive: boolean;
    isOpenCloseUserInfo: boolean;
    isIncrement: number | null;
    isRefresh: boolean;
    startGuide: boolean;
    endOfSteps: boolean;
  };
}

const initialState: IReduxUserGuideState = {
  userGuide: {
    steps: mySteps,
    run: false,
    stepIndex: 0,
    tourActive: false,
    isOpenCloseUserInfo: true,
    isIncrement: 0,
    isRefresh: false,
    startGuide: false,
    endOfSteps: false,
  },
};

type UserGuideReducerAction =
  | IReduxHandleCallbackAction
  | IReduxHandleStartAction
  | IReduxHandleReloadAction
  | IReduxHandleReloadAction
  | IReduxHandleStartProfUserGuideAction

export default function(
  state: IReduxUserGuideState = initialState,
  action: UserGuideReducerAction
) {
  switch (action.type) {
    case EReduxActionTypes.SET_NEXT_STEP:
      return {
        ...state,
        userGuide: {
          ...state.userGuide,
          ...action.data
        },
      };
    case EReduxActionTypes.SET_START_USER_GUIDE:
      return {
        ...state,
        userGuide: {
          ...state.userGuide,
          ...action.data,
        },
      };
    case EReduxActionTypes.SET_RESET_STEP:
      return {
        ...state,
        userGuide: {
          ...state.userGuide,
          ...action.data,
        },
      };
    // case EReduxActionTypes.SET_DEFAULT_STEP:
    //   return { 
    //     ...state,
      
    // };

    case EReduxActionTypes.SET_RELOAD_USER_GUIDE:
      return {
        ...state,
        userGuide: {
          ...state.userGuide,
          ...action.data,
        },
      };
    case EReduxActionTypes.SET_START_PROF_USER_GUIDE:
      return {
        userGuide: {
          ...state.userGuide,
          ...action.data,
        },
      };
    case EReduxActionTypes.SET_COMPLETE_USER_GUIDE:
      return {
        userGuide: {
          ...state.userGuide,
          ...action.data
        },
      };
    default:
      return state;
  }
}
