export const TRANSLATIONS_ES = {
    addCourse: "NUEVO CURSO",
    createCourse: "crear un curso",
    myCourses: "Mis cursos",
    Published: "En línea",
    Draft: "Borrador",
    Pending: "En espera de la aprobación del director",
    RequestDeactivation: "En espera de desactivación por parte del director",
    RequestActivation: "En espera de activación por parte del director",
    RequestDeletion: "En espera de archivo por el director",
    certified: "Graduados",
    candidats: "CANDIDATOS",
    candidatsCapitalise: "Candidatos",
    courseStatus_1: "El curso permanecerá",
    courseStatus_2: "privado hasta que se publique",
    Pending_2: "Esperando la validación",
    RequestDeletion_2: "Esperando a archivar",
    RequestDeactivation_2: "Esperando la desactivación",
    RequestActivation_2: "Esperando activación",
    actvity: "ACTIVIDAD",
    students: "Estudiantes",
    students_2: "Alumno",
    description: "DESCRIPCIÓN",
    archive: "ARCHIVO",
    publish: "PUBLICAR",
    inscription: "INSCRIPCIÓN",
    inscriptionTitle: "Inscripción",
    certifiedTitle: "Graduado en",
    lastVisitProfile: "Última visita hace",
    lastVisitProfile_1: "última visita",
    lastVisitProfile_2: "Yo ya",
    descProf: "Breve descripción del profesor que aparecerá en la presentación del curso",
    content: "CONTENIDO",
    titleCoursePlaceholder: "Ingrese el título del curso aquí...",
    titleCourseForm: "Título del curso",
    modalPublishCourse_1: "Enviar al director para su aprobación",
    modalPublishCourse_1_1: "Validar la publicación",
    modalRefusedCourse_1: "Rechazar publicación",
    modalPublishCourse_2: "Añadir un comentario para el director.",
    modalPublishCourse_2_2: "Añadir un comentario para el profesor.",
    modalPublishCourse_3: "Comentarista...",
    modalPublishCourse_4: "ENVIAR CURSO",
    modalPublishCourse_4_4: "PUBLICAR CURSO",
    modalRefusedCourse_4: "RECHAZAR EL CURSO",
    modalPublishCourseSuccess: "El curso ha sido enviado al director para su validación.",
    modalPublishPathSuccess: "El curso ha sido enviado al director para su validación.",
    modalPublishSessionSuccess: "La sesión fue enviada al director para su validación.",
    modalPublishCourseSuccess_1: "El curso está publicado. Ahora está disponible en línea.",
    modalDeactivateCours: "El curso está deshabilitado. Ya no está disponible en línea.",
    modalRefusedCourseSuccess: "Acabas de rechazar la publicación del curso. Tu rechazo ha sido enviado al profesor.",
    titleFormCourse_1: "Video presentación del curso.",
    titleFormCourse_2: "Visual para ilustrar el curso.",
    titleFormCourse_3: "Texto de presentación del curso.",
    resume: "Resumen introductorio",
    textAreaPlaceholder: "Introducir texto aquí...",
    placeholderInscriptionInput: "Introducir texto aquí",
    prerequisite: "Requisitos previos para tomar este curso",
    objectives: "metas educativas",
    skills: "Conocimientos y habilidades adquiridos a través de este curso.",
    skillsPlaceholder: "Introduzca una palabra clave...",
    uploadVideo_1: "Arrastre y suelte el video del curso que desea cargar (mp4, mov)",
    uploadVideo_2: "SELECCIONE UN ARCHIVO DE VÍDEO",
    uploadImage_1: "Arrastra y suelta una foto para ilustrar el curso (jpg, png)",
    uploadImage_2: "SELECCIONA UNA IMAGEN",
    downloadVideo: "Descargar",
    awaitVideo: "En la cola",
    whiletranscode: "Viendo actualmente",
    transcodingVideo: "Procesamiento de video",
    breadCrumbChapter: "Capítulo",
    chapitre: "Capítulo",
    newTest: "PRUEBA NUEVA",
    newChapter: "NUEVO CAPITULO",
    selectLevelCourse: "Nivel",
    selectLevelCourse_1: "Principiante",
    selectLevelCourse_2: "Intermedio",
    selectLevelCourse_3: "Confirmado",
    listChaptersContent: "Para comenzar, especifique el nivel del curso y cree su primer capítulo.",
    chapterSelectFomCHapter: "CAPÍTULO",
    saveChapter: "PARA REGISTRARSE",
    durationChapter: "Duración",
    placeholderTitleChapter: "Ingrese el título del capítulo aquí...",
    titleFormChapter_1: "Vídeo del capítulo",
    titleFormChapter_2: "Archivos para descargar",
    titleFormChapter_3: "Texto del capítulo",
    textFileUpload_1: "Arrastrar y soltar archivos de carga de capítulos",
    textFileUpload_2: "(audio, PDF, PowerPoint, Word, Excel...)",
    fileUploadButton: "Seleccione Archivo",
    uploadVideoChpter_1: "Arrastra y suelta el video del capítulo que quieras subir (mp4, mov)",
    tests: "Pruebas",
    test: "Prueba",
    resultat: "Resultados",
    reponseInscrit: "Solicitud de suscripción",
    reponseAv: "Responder antes",
    refusedAt: "rechazado el",
    Refused: "Rechazar",
    Unpaid: "A pagar",
    seeDemand: "ver mi solicitud",
    certifButton: "CERTIFICADO",
    courseTable: "Formación",
    DATEACHAT: "Fecha de compra",
    exportListe: "LISTA DE EXPORTACIÓN",
    filterProfessors: "Maestro",
    statusFilter: "Estado",
    search: "Investigar",
    Tous: "Todos",
    Brouillon: "Borrador",
    Avalider: "A validar",
    Enligne: "En línea",
    Archive: "Archivo",
    Refuse: "Rechazar",
    Cours: "Clase",
    COURSE: "Clase",
    Professeurs: "Profesores",
    Demandes: "Peticiones",
    filterCourse: "Todos los cursos",
    Certifies: "Certificado",
    Statut: "Estado",
    PresProf: "Presentación del Profesor",
    dernVisite: "última visita",
    Enattente: "A la espera",
    Admis: "aceptado",
    Suspendu: "Suspendido",
    inviteProf: "INVITA A UN PROFESOR",
    inviteProf_2: "Invitar a un profesor a registrarse",
    send: "enviar a",
    inviteSuccess: "Tu invitación ha sido enviada al profesor.",
    myAccount: "Mi cuenta",
    saveImg: "guardar imagen",
    Profile: "Perfil",
    Nom: "apellido",
    NomObligatoire: "Apellido*",
    PrenomObligatoire: "Nombre de pila*",
    Prenom: "Nombre de pila",
    country: "país de residencia",
    Tunisie: "Túnez",
    France: "Francia",
    city: "ciudad de residencia",
    birthdate: "Fecha de nacimiento",
    birthdate_input: "Fecha de nacimiento (mm / dd / aaaa)",
    NivScolaire: "Nivel escolar",
    Primaire: "Primario",
    College: "Escuela intermedia",
    Lycee: "Escuela secundaria",
    Bac: "Bac",
    Technicien2: "Técnico (Bac +2)",
    License: "Licencia (Bac+3)",
    Master: "Máster o equivalente (BAC +4 o +5)",
    Ingenieur: "Ingeniero",
    Doctorat: "doctorado",
    emailAdd: "Dirección de correo electrónico",
    updatePass: "cambiar mi contraseña",
    password: "Contraseña",
    actualPass: "Contraseña actual",
    newPass: "Nueva contraseña",
    enterPassword: "Mínimo 8 caracteres",
    msgErrorPassword: "Ingrese al menos 8 números/letras",
    msgErrorConfirmPassword: "Por favor ingrese la misma contraseña",
    confimrNewPass: "Confirmación de contraseña",
    msgErrorMail: "Por favor, introduce una dirección de correo electrónico válida",
    confimrNewEmail: "Nueva dirección de correo electrónico",
    validPassButton: "Validar mi nueva contraseña",
    MainSchoolColor: "color principal de la escuela",
    AddYourColorToPersonalizeThePresentationOfTheCourses: "Añade tu color para personalizar la presentación de los cursos",
    imgCrop_1: "Arrastra y suelta una foto",
    imgCrop_3: "Arrastra y suelta tu logo",
    imgCrop_2: "SELECCIONA UNA IMAGEN",
    pathBy: "Curso impartido por",
    sessionBy: "Sesión impartida por",
    catalogCourses: "Catálogo de cursos",
    Etudiants: "Estudiantes",
    Ecole: "Escuela",
    Catalogue: "Catálogo",
    Activite: "Actividad",
    lecole: "Escuela",
    howItWorks: "Como funciona",
    hello: "Bienvenidos !",
    cnxButton: "Iniciar sesión",
    createAccountMsg: "Crear mi cuenta",
    questionCreateAccount: "¿Aún no has creado una cuenta?",
    forgotLogin: "Contraseña olvidada ?",
    emailLogin: "Correo electrónico",
    validInscription: "VALIDAR MI REGISTRO",
    activateAccount: "ACTIVAR MI CUENTA",
    madame: "MUJERES",
    monsieur: "HOMBRE",
    other: "OTRO",
    registerDone_1: "Su solicitud de registro ha sido tenida en cuenta.",
    registerDone_2: "Se le ha enviado un correo electrónico.",
    registerDone_3: "Haga clic en el enlace proporcionado dentro de este correo electrónico para confirmar la creación de su cuenta.",
    registerDone_4: "Hasta ahora !",
    validRegister: "Validar",
    activateMyAccount: "activar mi cuenta",
    directeur: "Director",
    professeur: "Maestro",
    invalidToken: "Este enlace se ha utilizado antes y ha caducado, póngase en contacto con el soporte si es necesario.",
    questionAccount: "Ya tienes una cuenta ?",
    verifMail_1: "Su correo electrónico ha sido verificado, ahora puede iniciar sesión.",
    verifMail_3: "Validación de su correo electrónico",
    changePass: "Cambia la contraseña",
    msgUpdatedPass: "La contraseña ha sido cambiada.",
    recPass: "Recuperar contraseña",
    reinMail: "Introduzca su dirección de correo electrónico a continuación. Recibirás un enlace para restablecer tu contraseña.",
    reinBUttonPass: "Restablecer mi contraseña",
    sendMailRein: "Se le ha enviado un correo electrónico con su enlace de restablecimiento.",
    alreadyHaveAcc: "Ya tienes una cuenta ?",
    followCourse: "toma este curso",
    followPath: "Sigue esta ruta",
    followSession: "Seguir sesión",
    objPEda: "metas educativas",
    preresuiPeda: "Requisitos previos para este curso",
    preresuiPedaPath: "Requisitos previos para este curso",
    preresuiPedaSession: "Requisitos previos para esta sesión",
    skillsPreveiw: "Conocimientos y habilidades adquiridos a través de este curso.",
    skillsPreveiwPath: "Conocimientos y habilidades adquiridos a través de este curso.",
    skillsPreveiwSession: "Conocimientos y habilidades adquiridos durante esta sesión.",
    courseProgramm: "programa del curso",
    Chapitres: "Capítulos",
    videoCourse: "Vídeo curso",
    textCourse: "Texto del curso",
    downloadFiles: "Archivos para descargar",
    testConn: "Pruebas de conocimiento",
    aboutSchool: "sobre la escuela",
    aboutProfessor: "sobre el maestro",
    registerCourse: "Regístrese para el curso",
    registerPath: "Regístrese para el curso",
    registerSession: "Regístrese para la sesión",
    testConn_2: "Prueba tus conocimientos",
    progressView: "Sigue tu progreso",
    previewText_1: "Rellena tu solicitud de registro para que el profesor pueda validarlo.",
    previewText_2: "Avanza en tu programa y aprende nuevas habilidades.",
    previewText_3: "Valida tus conocimientos con pruebas y obtén tu certificado.",
    Introduction: "Introducción",
    Documents: "Documentos",
    downloadFIleDetails: "Descargar",
    Etudiant_1: "Alumno",
    Professeur_1: "Maestro",
    Directeur_1: "Director",
    annuler: "ANULAR",
    modifier: "modificador",
    logout: "Cerrar sesión",
    connexionButton: "Conexión",
    languageFR: "Francés",
    languageEN: "inglés",
    language: "Lengua",
    Pending_3: "A validar",
    CERTIFICAT: "CERTIFICADO",
    refuseCourse: "RECHAZAR PUBLICACIÓN",
    joursVisit: "Días",
    consulter: "CONSULTAR",
    jourVisit: "Día",
    Candidat: "Candidato",
    enCours: "ESTUDIANTES EN CURSO",
    certifiés: "GRADUADOS",
    Dossier: "Carpetas",
    Demande: "Solicitud",
    DemandeEnvoyéeIlYA: "Solicitud enviada hace",
    Resultats: "Resultados",
    Certificat: "Certificado",
    Tempsdobtention: "Obtención",
    Tempsdobtention_2: "obtener tiempo",
    refuseCourseDirector: "Rechazar publicación",
    refuseCourseDirector_2: "Añadir un comentario para el profesor.",
    refuseCourseDirector_3: "RECHAZAR EL CURSO",
    modalRefuseCourseSuccess_1: "Acabas de rechazar la publicación del curso.",
    modalRefuseCourseSuccess_2: "Tu rechazo ha sido enviado al profesor.",
    archiveCourseDirector: "¿Está seguro de que desea archivar este curso?",
    archiveCourseDirectorSuccess: "El curso está archivado.",
    archiveCourseDirector_2: "ARCHIVAR EL CURSO",
    nothingTOSay: "Nada que decir",
    online: "En línea",
    offline: "Fuera de linea",
    validationNewPassword: "Puedes iniciar sesión con tu nueva contraseña.",
    showMore: "MOSTRAR LO SIGUIENTE",
    folderRegistrationTrueOrFalse: "Sujeto a validación del expediente.",
    nextCommentsFeedback: "Mostrar siguiente",
    emailUpdateButtonShH: "CAMBIAR MI DIRECCIÓN DE CORREO ELECTRÓNICO",
    conditionDeValidation: "Condiciones de validación de la solicitud de matrícula de estudiante por parte del profesor.",
    Acceptation: "Aceptación del registro automático de estudiantes.",
    AcceptationSousDossier: "Aceptación de la matrícula del alumno sujeta a validación del expediente.",
    questions: "Preguntas para hacerle al estudiante",
    documents: "Documentos a solicitar al estudiante",
    titreQuestion: "Ingrese el título de la pregunta aquí",
    titreDocument: "Indique aquí el título del documento",
    AjouterQuestion: "Agregar pregunta",
    AjouterDocument: "Agregar documento",
    demandeDinscription: "Solicitud de suscripción",
    envoyerDemande: "ENVIAR MI SOLICITUD",
    titreRegistration_1: "Preguntas hechas por el profesor.",
    titreRegistration_2: "Documentos solicitados por el profesor",
    Inscribed: "En curso",
    CertifiedStudent: "Certificado",
    DraftStudent: "Expediente de registro en curso",
    Candidate: "En espera de validación del expediente",
    finishRegistration: "TERMINAR MI SOLICITUD",
    noCoursesStudent: "No estás inscrito en ningún curso.",
    discoverCatalog: "DESCUBRE EL CATÁLOGO",
    noCoursesCreated: "Aún no se ha creado ninguna formación.",
    noCoursesCreated_2: "Para empezar, crea tu primer curso.",
    noCourses_1: "No se tomaron cursos",
    noCourses_2: "Sin solicitud de registro",
    noCourses_3: "No hay cursos completados",
    noCoursesPublished: "Aún no se han publicado cursos.",
    deleteCompte: "Borrar mi cuenta",
    searchProfessors: "Buscar...",
    descriptionProfLabel: "Breve descripción del profesor que aparecerá en la presentación de sus cursos.",
    relaunchProfInvitation: "REANIMAR",
    noProfessorsList_1: "No se encontró ningún maestro.",
    noProfessorsList_2: "Por favor busque de nuevo.",
    noProfessorsListDefault_1: "Ningún maestro está registrado.",
    noProfessorsListDefault_2: "Invita a un maestro a registrarse para comenzar.",
    signatureProf: "firma del profesor",
    signatureDirector: "firma del director",
    Deactivate: "Desactivado",
    breadCrumbTest: "Prueba",
    placeholderTitleExam: "Indique aquí el título de la prueba...",
    exempleTitleExam: "Ejemplo, Test de conocimiento de los primeros conocimientos",
    noQuestionTest: "Para comenzar, cree su primera pregunta.",
    newQuestion: "NUEVA EDICIÓN",
    addQuestionText: "Agregar pregunta",
    placeholderQuestionText: "Indica la pregunta",
    answerQuestion: "Indica la respuesta",
    placeholderChoiceText: "Indique el texto de las opciones múltiples",
    delete: "Borrar",
    suppcomte: "Eliminación de cuenta",
    selectMotif: "Seleccione un patrón",
    coursNeMeconvier: "las clases no me convienen",
    comm: "añadir un comentario",
    DeleteSuccess: "Su cuenta ha sido eliminada con éxito. Adiós.",
    CreationAccountProfDirector: "Tu cuenta ha sido creada. Puedes iniciar sesión.",
    messageEnvoyerDemande_1: "Su solicitud de registro ha sido enviada.",
    messageEnvoyerDemande_2: "Recibirás una respuesta por correo electrónico.",
    messageEnvoyerDemande_3: "en los próximos 3 días.",
    candidat_status: "ESPERANDO VALIDACIÓN",
    refuser: "RECHAZAR",
    refuser_1: "RECHAZAR",
    valider: "VALIDAR",
    valider_1: "ACEPTADO",
    refuserDemandeRegistration: "Negar registro de estudiante",
    ajouterCommentaireEtudiant: "Agregue un comentario para el estudiante.",
    refuserLaDemande: "RECHAZAR LA SOLICITUD",
    messageRefuseRegistrationCours: "La inscripción de estudiantes en el curso se rechazó con éxito.",
    messageRefuseRegistrationPath: "La inscripción del estudiante para el curso ha sido rechazada con éxito.",
    messageRefuseRegistrationSession: "El registro de estudiantes para la sesión se rechazó con éxito.",
    messageValiderRegistration: "El estudiante fue invitado a comenzar a tomar el curso.",
    messageValiderRegistrationParcours: "El estudiante ha sido invitado a comenzar a seguir el curso.",
    messageValiderRegistrationSession: "El estudiante ha sido invitado a comenzar a seguir la sesión.",
    SuspendProf: "Suspender",
    textProfSuspend: "Este profesor ya no podrá acceder a las lecciones. Tienes la opción de restablecerlo si cambias de opinión.",
    otherChoice: "Otro",
    commentProfSuspend: "Añadir un comentario para el profesor.",
    questionLabel: "Pregunta",
    choiceLabelBON: "Buena respuesta",
    addAnswerButton: "Agregar una respuesta",
    noQuestionTestPreview: "Sin preguntas",
    passedTestAverage: "Tasa de éxito",
    param: "Ajuste",
    presen: "Presentación",
    commentSuspendInputProf: "Comentario...",
    motifSuspendProf: "Seleccione un patrón",
    affectOtherPRof: "Seleccione un profesor para asignar lecciones a",
    ReintegrateProf: "Reintegrar",
    ReintegrateProfText: "Este profesor podrá acceder a las lecciones. Tienes la opción de suspender al profesor si cambias de opinión.",
    AcceptProf: "Aceptar",
    aArchiver: "Para ser archivado",
    aDesactiver: "para desactivar",
    aActiver: "Activar",
    desactiverCourse: "CONFIRMAR DESACTIVACIÓN",
    refuseDesactivationCourse: "RECHAZAR DESACTIVACIÓN",
    activerCours: "VALIDAR ACTIVACIÓN",
    refuserLactivationCours: "RECHAZAR ACTIVACIÓN",
    archiverCours: "VALIDAR EL ARCHIVO",
    refuserArchivationCours: "ARCHIVO DE RECHAZOS",
    settingEcole: "Configuración de la escuela",
    nomEcole: "nombre de la escuela",
    logoEcole: "logotipo de la escuela",
    persenCourte: "Breve presentación (este texto se mostrará en las páginas del curso)",
    listCandidates: "Solicitudes de registro",
    listFollowing: "Cursos de formación seguidos",
    listeCertificated: "Entrenamientos completados",
    sendDate: "Enviando la solicitud",
    importVideoUpload: "Descarga de vídeo",
    waitingUpload: "Espere por favor...",
    uploadFinishing: "El video estará disponible en breve",
    uploadPending: "Siendo procesados...",
    candidatures: "Aplicaciones",
    certifcations: "CERTIFICACIONES",
    dashboardAllStudents: "Alumno",
    certifiedDashStudents: "Diplomado",
    YesWord: "Sí",
    NoWord: "No",
    obtentionPeriod: "Obtención",
    In: "En",
    TimePassed: "Yo ya",
    noStudentFound: "No hay estudiantes actuales",
    noCandidateFound: "No hay aplicaciones pendientes",
    noSuspendedFound: "Ningún estudiante es suspendido",
    noCertifedFound: "No hay estudiantes certificados o graduados",
    textStudentSuspend: "Este estudiante ya no podrá acceder a los cursos. Tienes la opción de restablecerlo si cambias de opinión.",
    commentStudentSuspend: "Agregar un comentario para el estudiante",
    successSuspendStudent: "El alumno ya no tiene acceso a los cursos.",
    ReintegrateStudentText: "Este estudiante podrá acceder a los cursos.",
    ReintegrateStudentText_2: "Tiene la opción de excluir al estudiante si cambia de opinión.",
    successReinstateStudent: "El estudiante ahora tiene acceso a los cursos.",
    successSuspendProf: "Profesor suspendido con éxito",
    successSuspendAssitant: "Administrador suspendido con éxito",
    successReinstateProf: "Profesor reintegrado exitosamente",
    successReinstateAssistant: "Administrador de reintegración exitosa",
    returnToProfProfile: "Volver AL PERFIL",
    diplomeButton: "Diplomado",
    validatedDiploma: "DIPLOMAS",
    Declcatalog: "DESCUBRE EL CATÁLOGO",
    deactivateCoursText: "Este curso ya no será accesible, tienes la posibilidad de reactivarlo si cambias de opinión.",
    modalDeactivateCoursTitle: "Desactivación del curso",
    deactivateCours: "Deshabilitar",
    successdeactivateCours: "Ha sido completado con éxito.",
    createBy: "Dada por",
    maleGender: "METRO.",
    femaleGender: "Mamá",
    modalReactivateCoursTitle: "Reactivación de curso",
    modalReactivatePathTitle: "Reactivación de curso",
    modalReactivateSessionTitle: "Reactivación de sesión",
    reactivateCours: "Reactivar",
    reactivateCoursText: "Este curso será accesible.",
    reactivateCoursText_2: "Tienes la opción de apagarlo si cambias de opinión.",
    reactivatePathText: "Esta ruta será accesible.",
    reactivatePathText_2: "Tienes la opción de apagarlo si cambias de opinión.",
    reactivateSessionText: "Esta sesión será accesible.",
    reactivateSessionText_2: "Tienes la opción de apagarlo si cambias de opinión.",
    champsObligatoires: "(*) Campos obligatorios",
    continueCreatingCourse: "Lo sentimos, no puedes publicar este curso.",
    continueBUttonText: "SEGUIR",
    errorChaptersLength: "Debes tener al menos un capítulo (obligatorio).",
    linkImgCourseError: "Visual para ilustrar el curso (obligatorio).",
    descriptionCourseError: "Presentación del texto del curso (obligatorio).",
    congratulation: "¡Felicitaciones en tu graduación!",
    diplomaMsg :"Para poder descargar tu diploma, por favor consulta todos los contenidos de los capítulos (Videos incluidos)",
    onlineCOurses: "Curso por Internet",
    validatedCourses: "Curso para convalidar",
    AllCoursesDirector: "Todos los cursos",
    BonneRéponses: "buenas respuestas",
    Question: "Pregunta",
    ReturnExam: "REANUDAR MI PRUEBA ACTUAL",
    textExa: "Seleccione una o más respuestas según la instrucción. La prueba se realiza una sola vez.",
    StartExam: "Comenzar",
    Réponse: "Responder",
    QCU: "Solo 1 respuesta correcta posible",
    QCM: "Muchas respuestas posibles",
    validCourse: "VALIDA LA PUBLICACIÓN",
    refuseInscriptionStudent: "SOLICITUD RECHAZADA",
    BonneReponses: "buenas respuestas",
    placeholderTextArea: "Introducir texto aquí...",
    languageIT: "Italia",
    languageES: "español",
    languageDE: "Alemán",
    languageNL: "Holandés",
    languageSV: "sueco",
    obligatoireFormCourse: "obligatorio",
    Registered: "Registrado",
    New: "Nuevo",
    Suspended: "Suspendido",
    CatalogueVisitorsInTheLast30Days: "Visitantes del catálogo en los últimos 30 días.",
    Course: "Clase",
    Candidates: "Candidatos",
    Teachers: "Profesores",
    ConversionRateOfApplicationsInTheLast30Days: "Tasa de conversión de visitas en los últimos 30 días.",
    MedianCourseNotes: "Puntaje medio del examen final",
    AverageAgeOfStudents: "Edad promedio de los estudiantes",
    Male: "Hombres",
    Female: "Mujeres",
    MostRepresentedCities: "Ciudades más representadas",
    VisitsAndApplicationsForRegistration: "VISITAS Y REGISTRO",
    RegistrationsAndCertifications: "REGISTROS Y TÍTULOS",
    ConnectedStudents: "Estudiantes conectados",
    LevelOfStudy: "nivel de estudios",
    numberOfVisits: "Visitas a la página de resumen",
    Applications: "Solicitudes de registro",
    Registration: "Número de registros",
    StudentsNumber: "Número de estudiantes conectados",
    Jan: "Ene",
    Fev: "Feb",
    Mar: "Mar",
    Avr: "Abr",
    May: "Mayo",
    Jui: "Junio",
    Jul: "Jui",
    Aug: "Yo",
    Sep: "Sep",
    Oct: "Oct",
    Nov: "Nov",
    Dec: "Dic",
    Access: "Acceder",
    Year: "año",
    StudentsConnectedThisMonth: "Conectado en los últimos 30 días.",
    AverageTimeSpentOnThePlatformForGraduation: "Tiempo promedio de permanencia en la plataforma para la graduación",
    AverageTimeBetweenRegistrationAndGraduation: "Tiempo promedio desde la inscripción hasta la graduación",
    certification: "Certificación",
    waitingCertif: "Cargando su diploma, por favor espere...",
    ListEtds: "lista de estudiantes",
    listcand: "lista de candidatos",
    listdipv: "Lista de graduados",
    listInscribed: "Lista de inscritos",
    listSuspended: "Lista de suspendidos",
    Days: "días",
    noVideoToShow: "No hay video.",
    donnerAvis: "Es importante !",
    donnerAvis_2: "Da tu opinión sobre el curso.",
    avisTitle: "El titulo de tu reseña",
    avisText: "Escribe tu reseña",
    votreAvis: "tu opinión aquí",
    publishMyReview: "Publicar",
    monAvis: "Mi opinión sobre el curso.",
    avis: "Aviso",
    Anonymous: "Anónimo",
    noFeedback: "Sin reseñas (por ahora)",
    Finance: "Finanzas",
    PublishedAt: "Publicado en",
    PublishedAte: "Publicado en",
    ActualPrice: "Último precio del período",
    MoyPrix: "Precio medio",
    discussionButton: "Discusiones",
    questionButton: "preguntas al profesor",
    addCommentButton: "añadir un comentario",
    addCommentForChapter: "Añadir un comentario al capítulo.",
    addcommentForCourse: "Agregar un comentario al curso",
    addcommentForPath: "Añadir un comentario a la ruta",
    addCommentForSession: "Agregar un comentario a la sesión",
    commentTitle: "Título del comentario",
    yourComment: "Escribe tu comentario",
    votreCommentaie: "Tu comentario aquí...",
    addQuestionButton: "Agregar pregunta",
    addCommentSuccess: "Tu comentario ha sido agregado exitosamente",
    responses: "Respuesta(s)",
    noCommentFound: "Sin comentarios (por ahora)",
    repondre: "Responder",
    labeldeletecompte: "Todos sus datos serán eliminados permanentemente",
    listdesProf: "Lista de profesores",
    noCoursePayedDefault: "Sin curso pagado.",
    noCoursePayed_1: "No se encontraron cursos pagos.",
    noCoursePayed_2: "Por favor busque de nuevo.",
    Courspayed: "Cursos pagados",
    CoursFree: "Lecciones gratis",
    paie: "Lista de pagos",
    prunp: "Especificar un período",
    Année: "Año",
    community: "Comunidad",
    thematic: "Selecciona un tema",
    comment: "Comentario",
    NumberOfCertifications: "Número de certificados",
    inscriptionTypePayment: "Tipo de inscripción",
    registrationFees: "Cuotas de inscripción",
    gratuit: "Libre",
    payant: "pago",
    addQuestionForChapter: "Hazle una pregunta al profesor",
    yourQuestion: "Escribe tu pregunta",
    addQuestionSuccess: "Su pregunta ha sido añadida con éxito",
    noQuestionFound: "No hay preguntas para tratar",
    noQuestionFound_4: "Ninguna pregunta respondida",
    noQuestionFound_5: "No tienes preguntas.",
    paymentTitle: "Pago",
    paymentTitlePlural: "Pagos",
    paymentMethodCourses: "Forma de pago del curso",
    choiceCurerncy: "Elegir Moneda",
    Questions: "Preguntas",
    unsettledQuestion: "Problemas a abordar",
    settledQuestion: "Problemas abordados",
    allQuestion: "Todas las preguntas",
    recent: "Más reciente",
    oldest: "El menos reciente",
    maxResponses: "Con tantas respuestas como sea posible",
    allSubjects: "Todos los cursos",
    allSubjects_2: "Todos los temas",
    noQuestionFound_2: "Su búsqueda no coincidió con ninguna pregunta.",
    noQuestionFound_3: "Por favor busque de nuevo.",
    noCommentFound_2: "Tu búsqueda no coincidió con ningún comentario.",
    noPaymentInfo: "Debes elegir el método de pago de tu escuela",
    NoPaymentSchoolConfig_1: "Para configurar el método de pago.",
    NoPaymentSchoolConfig_2: "Haga clic aquí",
    newResponses: "Nuevas respuestas",
    SortsMostRecent: "El más reciente",
    SortOldest: "El más viejo",
    allStatus: "Todos los estados",
    WAITING: "Esperando la validación",
    SOON: "A venir",
    ONGOING: "En curso",
    ENDED: "Terminado",
    REFUSED: "Rechazar",
    allCourses: "Todos los cursos",
    NoWebinarHasBeenCreatedYetCreateYourFirstWebinarToGetStarted: "Aún no se han creado seminarios web. Cree su primer seminario web para comenzar.",
    ForStudentsOf: "Para la formación de estudiantes",
    WebinarGivenBy: "seminario web impartido por",
    ENTERTHEWebinar: "ENTRAR AL seminario web",
    StartTHEWebinar: "Comience EL seminario web",
    AddToMyAgenda: "Agregar a mi calendario",
    validate: "Validar",
    inMyAgenda: "en mi diario",
    Edit: "modificador",
    REVIEWTHEwebinar: "REVISE EL seminario web",
    IWantTheWebinarToBeAccessibleInReplayWithThePublicConcerned: "Me gustaría que el seminario web fuera accesible en reproducción para el público interesado",
    NewWebinar: "Nuevo seminario web",
    close: "CERCA",
    Webinar: "seminarios web",
    WebinarTitle: "Título del seminario web",
    WebinarDate: "Fecha de su seminario web",
    WebinarSchedule: "Calendario de seminarios web",
    ShortDescriptionOfTheWebinar: "Breve descripción del seminario web",
    Cancel: "anular",
    WaitingForValidation: "Esperando la validación",
    ComingSoon: "Próximamente",
    LetsGo: "Se fue",
    ToReview: "Hasta luego",
    inscriptionCourseFaq: "Regístrese para un curso",
    followCourseFaq: "Seguir un curso",
    certificationFaq: "La graduación",
    professorsFaq: "Los profesores",
    stopCourseFaq: "detener una lección",
    paymentFaq: "Facturación",
    PAYED: "Pagado",
    PRIX: "Premio",
    openQuestion: "Pregunta abierta",
    Consignes: "Instrucciones",
    Valeurdelaquestion: "Valor de la pregunta",
    point: "punto",
    Nouveau_test: "nueva prueba",
    TEST_DE_CONNAISSANCE: "PRUEBA DE CONOCIMIENTOS",
    Test_intermédiaire: "Prueba de evaluación intermedia",
    Non_éliminatoire: "no eliminatorio",
    Question_fermées: "Preguntas cerradas",
    Test_de_fin_de_cours: "prueba de fin de curso",
    Validant_le_diplome: "Convalidación del diploma",
    Question_fermée_et_ou_ouvertes: "Preguntas cerradas y/o abiertas",
    modal_exam_title: "¿Qué tipo de prueba quieres crear?",
    AJOUTER_EXPLICATION: "AÑADIR UNA EXPLICACIÓN",
    Explication: "Explicación",
    add: "Para agregar",
    Créer_votre_premiere_question: "Crea tu primera pregunta",
    Examen_final_envoyé_le: "Examen final enviado el",
    En_attende_de_correction_par_le_professeur: "En espera de corrección por parte del profesor.",
    Répondre_aux_questions_fermees_ou_ouvertes_en_respectant_les_consignes: "Responda preguntas cerradas o abiertas siguiendo las instrucciones.",
    Le_test_seffectue_une_seule_fois_et_détermine_lobtention_de_votre_diplome: "La prueba se realiza una sola vez y",
    Le_test_seffectue_une_seule_fois_et_détermine_lobtention_de_votre_diplome_2: "determina su graduación.",
    Réuissi: "Exitoso",
    insuffisant: "insuficiente",
    Valider_ma_réponse: "validar mi respuesta",
    Rendu_le: "Entregado en",
    Par: "sobre",
    NOTER_LA_QUESTION: "NOTA PREGUNTA",
    Examens_à_noter: "Exámenes para anotar",
    Examen_notés: "Exámenes calificados",
    Tous_les_examens: "Todos los exámenes",
    aucun_examen_à_noter: "Sin examen para calificar",
    NOTE: "NOTA",
    à_noter: "anotar",
    Commentaire_du_professeur: "comentario del profesor",
    Pas_de_commentaire: "Sin comentarios",
    Ajouter_un_commentaire: "añadir un comentario",
    VALIDER_MA_NOTATION: "VALIDAR MI CALIFICACIÓN",
    EXAMEN_FINAL: "EXAMEN FINAL",
    exam1: "Indique aquí el título del examen final...",
    exam2: "Indique aquí el título de la prueba de conocimientos",
    votreMessage: "Escribe tu mensaje...",
    noMessage: "Sin mensaje",
    noMessageDispo: "no disponible aún...",
    message_1: "Tienes que esperar al maestro.",
    message_2: "abre la conversación",
    message_1_prof: "Inicie una conversación escribiendo",
    message_2_prof: "su mensaje a continuación.",
    textFaq: "¿Qué pasa si quiero saber más sobre la calificación de mi trabajo escrito?",
    draftstatdateFollow: "El caso comenzó el",
    finishdraftFollowButton: "terminar mi archivo",
    gotocourseStudent: "Accede al curso",
    myFormations: "mis entrenamientos",
    SelectCourse: "Seleccionar curso",
    NoWebinarHasBeenCreatedYet: "Aún no se han creado seminarios web.",
    TitleFormations: "formaciones",
    InscriptionDate: "Regístrese en",
    biography: "Biografía",
    labelMailProfessor: "correo electrónico del maestro",
    comments: "Comentarios",
    noStudentInscribed: "Ningún estudiante está registrado todavía",
    demandTable: "Solicitud realizada el",
    createNewFormation: "Nueva formación",
    selectFormatFormation: "Seleccione una talla",
    pathTitle: "Viaje",
    createPath: "crear una ruta",
    sessionTitle: "Sesión",
    createSession: "crear una sesión",
    requiredLevelText: "Nivel requerido para matricularse en el curso",
    anyLevelCourse: "todos los niveles",
    firstLevel: "Principiante",
    secondLevel: "Intermedio",
    thirdLevel: "Experto",
    ANYLEVEL: "todos los niveles",
    BEGINNER: "Principiante",
    courseDescriptionForm: "Describa su curso",
    descriptionTitle: "Descripción",
    courseContentTitle: "Contenido del curso",
    pathContentTitle: "Contenido del curso",
    sessionContentTitle: "Contenido de la sesión",
    importVideoCourse: "Importación de vídeo",
    createContentText: "Crea tu primer contenido",
    chapterPresentation: "presentación del capitulo",
    textPresentationChapter: "Texto introductorio del capítulo.",
    groupes: "Grupos",
    newGroup: "Nuevo grupo",
    groupTitle: "Título del grupo de estudiantes",
    newTestModalTitle: "nueva prueba",
    alreadyCreateExam: "Ya has creado un examen final",
    finalExmBreadcrunbTitle: "Examen final",
    noGroupFound: "No se ha creado ningún grupo de estudiantes",
    noGroupFound_2: "Tu búsqueda no coincide con ningún grupo",
    updateGroupTitle: "Editar título",
    deleteGroup: "Eliminar grupo",
    updateGroup: "Cambiar el título del grupo",
    addSubGroup: "Agregar un subgrupo",
    addStudentToGroup: "Agregar estudiantes",
    Actions: "Comportamiento",
    textConnaissance: "Prueba de conocimientos",
    points: "puntos",
    connectedThereIs: "se conectó hace",
    messageDeleteGroup_1: "Este grupo será eliminado permanentemente.",
    messageDeleteGroup_2: "No tienes la opción de restaurar este grupo si cambias de opinión.",
    messageDeleteGroup_3: "Grupo eliminado con éxito",
    closedQuestion: "Preguntas cerradas",
    pointsQuestion: "puntos)",
    explicationText: "Escribe tu explicación...",
    allStudent: "Todos los estudiantes",
    subGroup: "subgrupo",
    changeGroup: "Cambiar grupo",
    addToGroup: "Añadir al grupo",
    deleteFromGroup: "Sacar del grupo",
    documentTextForm: "Documento",
    returnToCourse: "regreso a clases",
    returnToPathButton: "volver al curso",
    returnToSessionButton: "volver a la sesión",
    sendPublishCoursePRofTitle: "Enviar al director para validar la publicación",
    returnToStudentList: "Volver a la lista de estudiantes",
    Previsualiser: "Avance",
    editeMode: "Editar",
    withInscription: "Registro bajo archivo",
    freeTitleCourse: "Ofrecido",
    questionPlaceholder: "Tu pregunta aquí...",
    responsePlceholder: "Escribe una respuesta aquí..",
    explicationPlaceholder: "Escribe una explicación aquí...",
    consignesPlaceholder: "Tus instrucciones aquí...",
    goToCatalogueError: "Para volver a la página anterior, haga clic en el botón de abajo.",
    errorCode: "Código de error",
    oupsWord: "¡Vaya!",
    errorInfoNotFound: "Lo sentimos, no se puede encontrar la página que está buscando.",
    errorInfoUnauthorized: "No se encontraron permisos.",
    errorSubinfoUnauthorized: "Esta página no es accesible al público.",
    errorInfoAccessRefused: "Acceso denegado.",
    errorSubInfoAccessRefused: "No tiene permisos para acceder a esta página.",
    serverProblem: "problema del servidor",
    tryLater: "Inténtalo en unos minutos.",
    unexpectedError: "Lo siento, error inesperado.",
    errorInfoServerProblem: "La página que está buscando se encuentra actualmente en mantenimiento y volverá pronto.",
    continueCreatingPath: "Lo sentimos, no puedes publicar este curso.",
    continueCreatingSession: "Lo sentimos, no puedes publicar esta sesión.",
    returnToPath: "volver al curso",
    returnToSession: "volver a la sesión",
    courseDesactivationSuccess: "Curso desactivado correctamente",
    pathDesactivationSuccess: "Curso desactivado correctamente",
    sessionDesactivationSuccess: "Sesión deshabilitada con éxito",
    textDeactivateCourse: "Deshabilitar curso",
    textDeactivatePath: "Deshabilitar curso",
    textDeactivateSession: "Desactivar sesión",
    textreactiovationCourse: "Curso activado con éxito",
    textreactiovationPath: "Ruta activada con éxito",
    textreactiovationSession: "Sesión activada con éxito",
    text_1_desactivateCourse: "Este curso ya no será accesible.",
    text_2_desactivateCourse: "Tienes la opción de reactivar el curso si cambias de opinión",
    text_1_desactivatePath: "Esta ruta ya no será accesible.",
    text_2_desactivatePath: "Tienes la opción de reactivar el curso si cambias de opinión",
    text_1_desactivateSession: "Esta sesión ya no será accesible.",
    text_2_desactivateSession: "Tienes la opción de reactivar la sesión si cambias de opinión",
    refuseCourseText: "Rechazar la publicación del curso.",
    refusePathText: "Rechazar la publicación del curso.",
    refuseSessionText: "Denegar la publicación de la sesión",
    backToListCourses: "volver a la lista de cursos",
    textRefusedCourseSuccess: "Curso rechazado con éxito",
    textRefusedPathSuccess: "Ruta denegada con éxito",
    textRefusedSessionSuccess: "Sesión denegada con éxito",
    acceptCourseText: "Validar la publicación del curso",
    acceptPathText: "Validar la publicación del curso",
    acceptSessionText: "Validar la publicación de la sesión",
    textAcceptCourse: "Este curso será accesible para todos los estudiantes.",
    textAcceptPath: "Este curso será accesible para todos los estudiantes.",
    textAcceptSession: "Esta sesión será accesible para todos los estudiantes.",
    publishCourseSuccessModal: "Curso publicado con éxito",
    publishPathSuccessModal: "Curso publicado con éxito",
    publishSessionSuccessModal: "Sesión publicada con éxito",
    errorContentLength: "Debe tener al menos un contenido (obligatorio).",
    errorPathLinkImg: "Visual para ilustrar el curso (obligatorio).",
    errorSessionLinkImg: "Visual para ilustrar la sesión (obligatorio).",
    errorPathDescription: "Texto de presentación del curso (obligatorio).",
    errorSessionDescription: "Presentación del texto de la sesión (obligatorio).",
    typeTable: "escribe",
    parcoursTitle: "Viaje",
    PATH: "Viaje",
    progress: "avance",
    gotopathStudent: "Accede al curso",
    gotosessionStudent: "Accede a la sesión",
    newContent: "nuevo contenido",
    chapterTitleBegin: "Título del capítulo",
    testTitleBegin: "título de la prueba de conocimiento",
    examTitleBegin: "título del examen",
    onlineFormations: "Entrenamiento en linea",
    validateFormations: "Formación a validar",
    allFormations: "Todos los cursos",
    inscribedTimeStudent: "registrado en",
    suspendedAt: "suspendido en",
    newFinalExam: "Agregar un examen final",
    backToMyrequest: "Volver a mi solicitud",
    beforeUploadText: "Arrastra y suelta los archivos que quieras compartir",
    uploadFile: "SELECCIONE UN ARCHIVO",
    Subscription: "Solicitud de suscripción",
    waiting: "A la espera",
    fileValidated: "Archivo validado",
    fileRefused: "Archivo rechazado",
    autorisationTraining: "permisos",
    autorisationTrainingTable: "Permiso",
    autorzedStatus: "Autorizado",
    unauthorized: "No autorizado",
    groupNameTable: "nombre del grupo",
    changeMyDecision: "Cambiar mi decisión",
    backtodemand: "de vuelta bajo demanda",
    cancelValidation: "Cancelar validación",
    downloadFileCertif: "Descargar mi diploma",
    nextChapterText: "Siguiente capítulo",
    getMyDiplome: "Mi diploma",
    ListAllCourses: "Listar todos los cursos",
    ListOnlineCourses: "Lista de cursos en línea",
    ListPendingCourses: "Lista de cursos pendientes",
    ListStudents: "Lista de estudiantes",
    courseBy: "Curso impartido por",
    SessionGivenBy: "Sesión impartida por",
    PathGivenBy: "Curso impartido por",
    PaymentDetails: "Detalles del pago",
    Completeyourpurchasebyprovidingyourpaymentinformation: "Complete su compra proporcionando su información de pago.",
    LastNameandFirstName: "Apellido y Nombre",
    CreditCardNumber: "número de tarjeta de crédito",
    ExpirationDate: "fecha de vencimiento",
    confirmandpay: "confirmar y pagar",
    Yourpaymentwassuccessful: "Su pago se ha realizado con éxito.",
    StartThePath: "empezar el viaje",
    Yourpaymenthasbeendeclined: "Su pago ha sido rechazado",
    StartTheCourse: "empezar el curso",
    StartTheSession: "iniciar sesion",
    selectLevelCourse_4: "Todos los niveles",
    progressTable: "Avance",
    notationLabel: "Notación",
    singleClosedQuestion: "Pregunta cerrada",
    Du: "De",
    Au: "A",
    Examens: "Exámenes",
    fromWhereTraining: "Proviene de",
    congratsTraining: "Felicidades, has completado el curso.",
    donnerAvis_3: "Da tu opinión sobre el curso:",
    donnerAvis_4: "Da tu opinión sobre la sesión:",
    donnerAvisButton: "Da tu opinion",
    InitialPathName: "Título del curso",
    PathDecriptionCreation: "Describa sus antecedentes",
    prerequisite1: "Requisitos previos para seguir este curso",
    skillsNeeded: "Conocimientos y habilidades adquiridos a través de este curso.",
    pathCoverPhot: "Arrastra y suelta una foto para ilustrar el curso (jpg, png)",
    pathVideoDescription: "Video presentación del curso.",
    pathVideoDrop: "Arrastre y suelte el video del curso que desea cargar (mp4, mov)",
    PathLevel: "Nivel requerido para inscribirse en el curso.",
    SessionTitleCreation: "Título de la sesión",
    SessionLevel: "Nivel requerido para registrarse en la sesión",
    SessionDescriptionCreation: "Describe tu sesión",
    prerequisite2: "Requisitos previos para seguir esta sesión",
    SessionSkills: "Conocimientos y habilidades adquiridos durante esta sesión.",
    SessionCoverDescription: "Visual para ilustrar la sesión.",
    SessionCoverDrop: "Arrastra y suelta una foto para ilustrar la sesión (jpg, png)",
    SessionDescriptionVideo: "Video presentación de la sesión.",
    DragDropSession: "Arrastre y suelte el video de la sesión que desea cargar (mp4, mov)",
    saveSchoolLogo: "Añadir una imagen",
    descriptionChoiseCours: "Crea tu curso con sus capítulos, pruebas y examen final. Configura tu expediente de admisión",
    descriptionChoisePath: "Arma tus cursos y cursos y crea un curso de capacitación con las condiciones para aprobar",
    descriptionChoiseSession: "Arma tu entrenamiento con las condiciones de paso establecidas en el tiempo",
    returnButton: "dar la vuelta",
    waitingToPayment: "En espera de pago",
    noUnpaidFound: "Sin expectativa de pago",
    schoolAssistant: "Administrador",
    passwordMandadory1: "La contraseña debe contener",
    passwordMandadory2: "Al menos 8 carácteres",
    passwordMandadory3: "Al menos una letra mayúscula y una letra minúscula",
    passwordMandadory4: "Al menos 1 dígito",
    passwordMandadory5: "Al menos un carácter especial (#?!=@$%^&*-)",
    passConfirmation: "Confirmación de contraseña",
    RésultatCumulé: "resultado acumulativo",
    etudiantsInscrits: "Estudiantes registrados",
    TarifActuel: "Precio actual",
    TarifMoyen: "Precio promedio",
    priceHistory: "Historial de tarifas",
    hidePricehistory: "Ocultar historial de tarifas",
    saveModification: "¡Cambio guardado!",
    Total: "Total",
    TotalInscrits: "Total registrados",
    periodMedian: "Precio medio del periodo",
    totalMedian: "Precio promedio total",
    totalResult: "escapatoria total",
    QUESTIONS_OUVERTES: "PREGUNTAS ABIERTAS",
    notValid: "No es válido",
    validAction: "Acción validada",
    studentResponse: "Respuesta del estudiante",
    markUpdate: "modificador ma notación",
    webinarEnded: "Seminario web terminado",
    added: "Adicional",
    warning: "Atención !",
    changePrice: "Acabas de cambiar el precio del curso:",
    priceNow: "Precio actual:",
    priceAfter: "Nuevo precio afectado:",
    saveUpdate: "GUARDAR CAMBIOS",
    profchoice: "Seleccione un profesor para asignar el curso (s) a",
    deleteReason: "Razón para borrar*",
    disableAssistant: "Este Administrador ya no podrá acceder a la plataforma. Tienes la opción de restablecerlo si cambias de opinión.",
    addCommentToAssistant: "Agregar un comentario para el Administrador",
    connectAccountPaymee: "Conecta tu cuenta Paymee",
    connect: "CONECTAR",
    idPaymee: "ID de pago",
    assistantInvitation: "Invitar a un Administrador a registrarse",
    mailAssistant: "Correo electrónico del Administrador",
    membreInvitation: "invitar a un miembro",
    role: "Role",
    newCourse: "Nuevo curso",
    newPath: "Nuevo curso",
    newSession: "Nueva sesión",
    profMandadotory: "Este curso debe ser asignado a un profesor.",
    profMandadtory2: "Seleccione un profesor para asignarle este curso*",
    rehireAssistant: "Este Administrador podrá acceder a la plataforma. Tiene la opción de suspender al Administrador si cambia de opinión.",
    dragToUpload: "Arrastra y suelta tu foto",
    or: "O",
    selectPhoto: "selecciona tu foto",
    offerList: "Lista de ofertas",
    answer: "Su respuesta",
    completedParts: "partes hechas",
    followPaths: "Seguir curso",
    followSessions: "Seguir curso",
    displayVideo: "Tu presentación en video o visual",
    toValidate: "A validar",
    archiveSheet: "Archivo",
    connectZoom: "Conectar Ampliar",
    accountZoom: "ampliar cuenta",
    connectAccount: "conectar mi cuenta",
    connectAccountToschool: "Conecta tu cuenta de zoom con tu escuela",
    changeOffer: "Cambiar oferta",
    facture: "Facturas",
    paymentMethod: "Métodos de pago",
    cancelSubscription: "cancelar mi suscripción",
    offerPrice: "Tarifas de oferta",
    nextDeadline: "Próxima fecha de vencimiento del pago",
    deleteOffer: "Eliminación de oferta",
    deleteMsgOffer: "¿Quieres eliminar tu oferta?",
    passwordUpdated: "Tu contraseña ha sido cambiada",
    passwordNoMatch: "Las dos contraseñas no coinciden",
    modifyProfil: "Editar mi perfil",
    newEmail: "Nueva dirección de correo electrónico",
    connectStripToSchool: "Conecta tu cuenta de Stripe con tu escuela",
    disconnect: "Desconectar",
    connectPaymeeToSchool: "Conecta tu cuenta Paymee con tu escuela",
    paymentMessage: "Complete su compra proporcionando su información de pago.",
    paymentConfimation: "confirmar pago",
    activate: "Habilitar",
    month: "Mensual",
    yearly: "Anuales",
    offerMessage1: "Todo lo incluido en el plan Básico, más:",
    Choisir: "Eligiendo",
    compareoffers: "Compara todas las características",
    offer: "Oferta",
    monthlyFor: "Mensual para",
    yearFor: "Anuales para",
    totalPrice: "Precio total:",
    valid: "Válido",
    ProfList: "Lista de profesores",
    StudentList: "Lista de estudiantes",
    CourseList: "Lista de cursos",
    Inscrits: "Registrado",
    team: "Tripulación",
    ADMINSTRATOR: "Administrador",
    info: "Información",
    shortDescription: "Breve descripción",
    shortDescription1: "Breve descripción de",
    nbrConx: "Número de conexión",
    inscDate: "Fecha de inscripción",
    role1: "role",
    totalRevenu: "Ingresos totales",
    makeAPayment: "realizar un pago",
    Fileacceptedon: "Archivo aceptado el",
    params: "configuraciones",
    pricipalGroup: "grupo principal",
    addPhoto: "agrega tu foto",
    changePhoto: "Cambia tu foto",
    deletePhoto: "Eliminar imagen",
    seletionner: "SELECCIONE",
    facturationDetails: "Datos de facturación",
    closedSchool: "Esta escuela está actualmente cerrada.",
    hold: "Por favor",
    contact1: "Soporte de contacto",
    contact2: "para más información.",
    question: "pregunta",
    allTeam: "Todo el equipo",
    assistants: "administradores",
    noTeam1: "No se encontró ningún maestro/Administrador.",
    noTeam2: "Por favor busque de nuevo.",
    noTeamDefault_1: "Ningún miembro del equipo está registrado.",
    noTeamDefault_2: "Invita a un miembro a registrarse para comenzar.",
    noAssistant_1: "No se encontraron administradores.",
    noAssistant_2: "Por favor busque de nuevo.",
    noAssistantDefault_1: "No hay ningún Administrador registrado.",
    noAssistantDefault_2: "Invita a un Administrador a registrarse para comenzar.",
    TrainingToedit: "En progreso",
    webinarForm1: "Seleccione un maestro para asignarle este seminario web*",
    webinarForm2: "Selecciona un profesor",
    webinarForm3: "Para los estudiantes del curso:",
    listeDesAssistants: "lista de administradores",
    listeTeam: "lista de equipos",
    listOfUnpaidStudents: "lista de estudiantes no remunerados",
    Nounpaidstudent: "Ningún estudiante no remunerado",
    memberMail: "correo electrónico del miembro",
    integrations: "integraciones",
    monForfait: "Mi paquete",
    Facturation: "Facturación",
    Detailsduforfait: "Detalles del paquete",
    Gerezoumodifiezvotreforfait: "Administra o cambia tu plan",
    Votreoffre: "Su oferta",
    Fraisdeloffre: "Tarifas de oferta",
    Prochainedatedecheancedupaiement: "Próxima fecha de vencimiento del pago",
    Modedepayement: "Forma de pago",
    Changerleforfait: "Cambio de plan",
    Annulermonabonnement: "cancelar mi suscripción",
    Limitationduforfait: "Limitación de paquetes",
    Suivrelalimitationdevotreforfaitactuel: "Seguimiento de la limitación de su plan actual",
    Voirlesforfaits: "ver paquetes",
    Vousavezatteintlalimitedaccesdevos: "Ha alcanzado el límite de acceso de su",
    Vouspouvezajouterdes: "Puedes añadir",
    endesactivantun: "al deshabilitar un",
    endesactivantune: "al deshabilitar un",
    actifouenameliorantvotreforfait: "actualizando su plan",
    activeouenameliorantvotreforfait: "actualizando su plan",
    Modesdepayement: "metodos de pago",
    Gérezoumodifiezvotremodesdepaiement: "Administra o cambia tus métodos de pago",
    congratsSession: "Felicitaciones, ha completado la sesión.",
    avisTitle1: "Mi opinión sobre el curso.",
    avisTitle2: "Mi opinión sobre la sesión.",
    FormationAValider: "No hay entrenamiento para validar.",
    FormationEnLigne: "Sin formación en línea.",
    emptyListFormation1: "Por favor busque de nuevo.",
    noTrainingSearch: "Este profesor aún no tiene formación.",
    NoWebinarToValidate: "No hay seminario web para validar.",
    NoWebinarIsComingSoon: "No hay próximos seminarios web.",
    NoWebinarIsOnGoing: "No hay seminarios web actuales.",
    NoWebinarHasEnded: "No hay seminarios web completados.",
    NoWebinarIsRefused: "No se rechazaron los seminarios web.",
    NoWebinarIsAssociatedToThisCourse: "No hay webinar asociado con este curso.",
    NoWebinarExists: "No se encontraron seminarios web.",
    FirstWebinar: "Aún no se han creado seminarios web.",
    FirstWebinar2: "Cree su primer seminario web para comenzar.",
    transformKnowledge: "Convierte tu conocimiento en riqueza",
    addToDescription: "Agregar a la descripción",
    importListOfStudents: "Importar la lista de alumnos.*",
    Remplacer: "Reemplazar",
    returnToStudentsList: "Volver a la lista de estudiantes",
    invite: "invitar",
    continue: "seguir",
    savedUsers: "Cuento con el consentimiento de los usuarios registrados en este fichero, según la ley de protección de datos personales del país vigente.",
    download: "Descargar",
    loadInformation: "Cargando su información, por favor espere...",
    yourAccount: "Desde tu cuenta",
    emailSend: "Email enviado",
    check: "controlar",
    verifyYourEmail: "Revisa tu correo electrónico y abre el enlace que recibiste para continuar",
    returnToConnexionPage: "volver a la página de inicio de sesión",
    save: "para registrarse",
    coursResume: "Escriba el resumen de este curso, presentando sus puntos más importantes y mostrando su valor agregado para el miembro.",
    coursObjectives: "Enumerar los objetivos a alcanzar durante este curso.",
    preRequisite: "Agrega los prerrequisitos necesarios para tomar este curso.",
    skillsToLearn: "Enumere las habilidades que se adquirirán durante este curso en forma de palabras clave.",
    pathResume: "Escriba un resumen de este curso, presentando sus puntos más importantes y mostrando su valor agregado para el miembro.",
    pathObjectives: "Enumerar los objetivos a alcanzar durante este curso.",
    pathPreRequisite: "Agrega los prerrequisitos necesarios para seguir este curso.",
    pathSkillsToLearn: "Enumere las habilidades que se adquirirán durante este curso en forma de palabras clave.",
    sessionResume: "Escriba el resumen de esta sesión, presentando sus puntos más importantes y mostrando su valor agregado para el miembro.",
    sessionObjectives: "Enumere los objetivos a alcanzar durante esta sesión.",
    sessionPreRequisite: "Agregue los requisitos previos necesarios para seguir esta sesión.",
    sessionSkillsToLearn: "Enumere las habilidades que se adquirirán durante esta sesión en forma de palabras clave.",
    timeNotAvailable: "Este horario no está disponible, por favor elige otro.",
    annulerImportation: "Cancelar importación",
    annulerImportationConfirmation: "¿Está seguro de que desea cancelar la importación?.",
    retourImportation: "volver a importar",
    failedRequest: "Solicitud fallida",
    lowerDeal: "Para cambiar a un plan inferior, debe ajustar su límite de acuerdo con los límites del plan elegido.",
    activeProfessors: "Profesores activos",
    activeStudents: "Estudiantes activos",
    activeAssistants: "administradores activos",
    professorList: "Lista de profesores",
    trainingList: "Lista de cursos",
    setAsPrincipal: "Establecer como principal?",
    replacePaymentMode: "Esto reemplazará su método de pago principal actual.",
    deletePaymentMethod: "Eliminar método de pago",
    deletePaymentMethodConfirmation: "¿Está seguro de que desea eliminar el método de pago?",
    deleteInvitation: "Cancelar invitación",
    invitationAnnulationOfYourSchool: "Cancelar la invitación ya no permitirá que esa persona se convierta en miembro de",
    yourSchool: "su escuela.",
    youCanReinvite: "Siempre puedes invitarlo de vuelta.",
    keepInvitation: "quédate con la invitación",
    noCourseFound: "No se encontraron cursos",
    wantCreateCourse: "¿Quieres crear un curso?",
    cannotImportListOfStudents: "No puede importar una lista de estudiantes que contenga errores.",
    firstName: "El nombre",
    obligatoire: "es obligatorio",
    name: "El apellido",
    emailAddressNotValid: "Dirección de correo electrónico no válida.",
    emailFormat: "El formato del correo electrónico debe ser",
    emailExample: "votrenom@ejemplo.com",
    importationResult: "Importar resultado",
    succesfulImportation: "importaciones exitosas",
    lignes: "líneas",
    containErrors: "contener errores",
    addStudent: "agregar un estudiante",
    importList: "Importar una lista",
    invitationSentSuccesfully: "Invitación enviada con éxito",
    invitationSuccessAssistant: "Su invitación ha sido enviada con éxito al Administrador.",
    userAlreadyExists: "El usuario con este correo electrónico ya existe.",
    enterValidEmail: "Por favor, introduce una dirección de correo electrónico válida.",
    professorLimitAtteined: "Has alcanzado el límite de acceso de tus profesores. Puede agregar maestros desactivando un maestro activo o actualizando su plan.",
    assistantLimitAtteined: "Ha alcanzado el límite de acceso para sus administradores. Puede agregar administradores desactivando un Administrador activo o actualizando su plan.",
    compareOffers: "Comparar ofertas",
    schoolUses: "Su escuela usa características",
    notIncludedInOffer: "que no son compatibles con la oferta",
    passToOffer: "Si cambias a la oferta",
    allFonctionalities: ", todas las características",
    areDesactivated: "de su escuela será desactivado e inaccesible.",
    invitationsSentSuccesfully: "las invitaciones han sido enviadas exitosamente",
    automaticSendOfInvitations: "Envío automático de invitaciones",
    invitationSendConfirmation: "¿Confirmas el envío de invitaciones a la lista que importaste?",
    confirmAndSend: "Confirmar y enviar",
    principal: "Principal",
    noPaymentMethodAdded: "No se agregó ningún método de pago",
    addPaymentMethod: "Añade un metodo de pago",
    remainning: "aún tienes",
    changePaymentMethod: "Cambiar método de pago",
    leaveMsg: "¡Sentimos verte partir!",
    annulationWarning: "Una vez que cancela, su cuenta permanecerá activa hasta que su facturación finalice el",
    annulationWarning2: "Después de eso, perderá el acceso a su escuela.",
    annulationConfirmation: "¿Está seguro de que desea cancelar su suscripción?",
    enterPasswordtoConfirm: "Ingrese su contraseña para confirmar",
    cancelationReasons: "Por favor, háganos saber por qué está cancelando su suscripción. Tus comentarios, nos ayudarán a mejorar el producto.",
    whyuCanceling: "¿Por qué estás cancelando tu suscripción?",
    selectLowerCase: "Seleccione",
    missingFonctionality: "Falta una característica que quiero",
    expensive: "creo que es demasiado caro",
    notInteressted: "Je n'utilise más fiebre",
    movingToAnotherPlatform: "me cambio a otra plataforma",
    preciser: "Por favor especifica",
    wantToTellUsMore: "quieres decirnos más?",
    unlimited: "Ilimitado",
    Cryptogramme: "Criptograma",
    savePaymentMethod: "Guardar este método de pago en mi perfil",
    paymentPassed: "Pago realizado",
    paymentSuccesfull: "Su pago ha sido procesado con éxito.",
    returnToActivity: "volver a la actividad",
    twoFreeMonths: "2 meses gratis",
    fourFreeMonths:"4 meses gratis",
    essentialTools: "Las herramientas esenciales para empezar, sin costes ocultos",
    forever: "para siempre",
    actuelOffer: "Tu oferta actual",
    selectFreeOffer: "Elige la oferta Gratis",
    pathAndSessions: "Rutas y sesiones",
    forProfessionalsAndNonProfessionals: "Para formadores profesionales e infoemprendedores.",
    yearlyBill: "Facturado anualmente",
    perMonth: "por mes",
    selectProOffer: "Elija la oferta Pro",
    courses: "clase",
    illimitedAccess: "Para disfrutar de Koors ilimitados.",
    perStudent: "por estudiante",
    minimum: "mínimo",
    selectBussinessOffer: "Elige la oferta Business",
    illimitedNumberOfStudents: "Número ilimitado de estudiantes",
    illimitedNumberOfCours: "Número ilimitado de lecciones",
    illimitedNumberOfProfessors: "Número ilimitado de profesores",
    illimitedNumberOfAssistants: "Número ilimitado de administradores",
    subscriptionPayments: "pago de suscripción",
    Devise: "Idear",
    FacturationCycle: "Ciclo de facturación",
    monthly: "Mensual",
    yearly_2: "Anual",
    ofReduction: "reducción",
    minimalBill: "Facturación mínima",
    nextBill: "Próxima facturación",
    confirmPayment: "Confirmación de pago",
    reductionOnCurrentPlan: "Reducción en el plan anual",
    verification: "Verificación",
    noStudentFound_2: "No se encontraron estudiantes",
    archivedTrainings: "No hay formación archivada",
    refusedTrainings: "Ningún entrenamiento rechazado",
    desactivatedTrainings: "Sin entrenamiento deshabilitado",
    editedTrainings: "No se está editando ningún entrenamiento",
    noTrainingHaveBeenCreated: "Aún no se han creado quesos.",
    createFirstTraining: "Para comenzar, crea tu primera formación.",
    examsToNote: "No hay exámenes para calificar.",
    notedExams: "Sin examen calificado.",
    noExam: "No tienes examen.",
    resendInvitation: "Reenviar invitacíon",
    Copyright: "Derechos de autor",
    rights: "Todos los derechos reservados.",
    noExamFound: "No se encontraron reseñas.",
    atteinedMaximum: "Ha alcanzado el número máximo permitido para un plan",
    updatePlan: "Actualice su plan para crear más formaciones.",
    noBill: "Sin factura.",
    finalExam: "Examen final .",
    selectAnImage:"SELECCIONA UNA IMAGEN",
    slideToAddAnImage:"Arrastre y suelte una foto para ilustrar el seminario web",
    yourWebinarsAreIntegrated:"Sus webinars están integrados",
    onThePlatform:"a la plataforma con el",
    offreProAndBussiness:"Ofertas Profesionales y Empresas",
    createWebinar:"Simplemente cree sus seminarios web directamente en Koors Select e invite directamente a sus estudiantes a participar (los nuevos estudiantes elegibles son invitados automáticamente).",
    findReplayAndPublishWebinar:"Encuentre las repeticiones de sus seminarios web y publíquelos en su capacitación.",
    improveMyOffer:'Mejorar mi oferta',
    soonWebinarsAvailable:"Pronto se le ofrecerán webinars.",
    willInformuSoon:"Te mantenemos informado.",
    courseResumeIntroduction:"Resumen introductorio del curso...",
    reprendre:"Retomar",
    freeOffreNoPaymentMethod:"No tienes métodos de pago. Está registrado para la oferta gratuita.",
    Credit:"crédito",
    DebitCard:"Tarjeta de débito",
    offre:"oferta",
    nameOnCard:"Nombre en la Tarjeta",
    traitement:"TRATAMIENTO...",
    paiement:"Pago",
    offersList:"Lista de ofertas",
    perYear:"por año",
    account:"cuenta",
    wrongBirthDate:"Fecha de nacimiento incorrecta",
    selectCountry:"Elige un país",
    cardAddedSuccessfully:"su tarjeta ha sido añadida con éxito",
    returnToParameters:"volver al parámetro",
    addNewPaymentMethod:"Agregar un nuevo método de pago",
    verifyPassword:"Contraseña incorrecta, ¿quieres verificar tu contraseña",
    returnToOffer:"Volver a mi oferta",
    subscriptionCancelled:"Su suscripción se ha cancelado correctamente",
    perMonthPayedAnually:"mes, pagado anualmente",
    downloadTemplate:"descargar plantilla",
    toSee:"Excel para ver un formato de ejemplo.",
    slideExcel:"Arrastra y suelta tu archivo de Excel",
    reposition:"REPOSICIÓN",
    importNewPhoto:"IMPORTAR UNA NUEVA FOTO",
    importPhoto:"IMPORTAR UNA FOTO",
    slideToSet:"arrastrar a la posición",
    publishMyOpinion:"Publicar mi reseña",
    noWebinarIsPlanned:"No hay seminarios web programados en este momento",
    perYearPayedAnually:"año, pagado anualmente",
    titleSessionPlaceholder:"indique aquí el título de la sesión...",
    titlePathPlaceholder:'indique aquí el título del curso...',
    addVideoUrl:"Añadir URL de vídeo",
    itegrateVideo:"incrustar video",
    enterValidUrl:"Asegúrese de haber ingresado una URL válida.",
    pasteVideoUrl:"Pegar enlace de vídeo...",
    addVideoToIntegrate:"Agregar URL de video para incrustar",
    creationDate:"fecha de creación",
    createdAt:"Creó el",
    generic_decline: "Pago rechazado por razones genéricas",
    insufficient_funds: "Los fondos de la tarjeta son insuficientes",
    lost_card: "La tarjeta se declara perdida",
    stolen_card: "La tarjeta es reportada como robada",
    expired_card: "la tarjeta ha caducado",
    incorrect_cvc: "El código CVC es incorrecto",
    processing_error: "Pago rechazado debido a un error de procesamiento",
    gender:"Género",
    male:"Hombre",
    female:"Mujeres",
    noCommentFound1:"Ningún comentario",
    lastUpdate:"Ultima publicación",
    lignes1:"línea",
    containErrors1:"contiene errores",  
    emptyTitleError:"Título del curso (requerido)",
    emptyPathTitleError:"Título del curso (requerido)",
    emptySessionTitleError:"Título de la sesión (requerido)" ,
    Webinar1: "seminarios web",
    notFound1:"Agregue más estudiantes y suba de nivel ",
    notFound2:"Koors reúne las mejores funciones para distribuir cursos en línea a la mayor cantidad de personas posible." ,
    notFound3:"Gracias a Koors, puedes administrar fácilmente tus comunidades de estudiantes/clientes y mantenerte en contacto en todo momento.",
    emailAlreadyUsed:"Esta dirección de correo electrónico ya está en uso",
    Clôturé: "Cerrado",
    Ouvert: "Abierto",
    TITREdusondage: "Título de la encuesta",
    Auteur: "Autor",
    CRÉELE: "Creado el",
    CloturéLE: "Cerrado el",
    RÉPONSEs: "Respuestas",
    Créerunsondage: "Crear una encuesta",
    sondages: "Encuestas",
    sondage: "Encuesta",
    inscribedInSchool:"Inscripción en la escuela",
    catalogVisits:"Visitas a la página del catálogo",
    currentStudentNumber:"Número de estudiantes actuales",
    currentStudentsWithDiploma:"Número de estudiantes de posgrado",
    noSchooling:"no mencionado",
    transcodeInProgress: 'La finalización de la importación está en curso. Puedes continuar para completar el contenido.',

}