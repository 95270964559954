import { ComponentProps, FC, useEffect, useState } from "react"
import { connect } from "react-redux"
import { AnyAction, bindActionCreators, Dispatch } from "redux"
import { AppState } from "../../../global-state/store/root-reducers";
import { getSubjectCommunity, setActualTabManageChat } from "../../../global-state/actions/chat-actions";
import ManageCommunitySessionComponent from '../../../components/community/session-community/manage-community-session-component';
import CommentCardLoading from '../../../components/community/course-community/comments/comment-card/comment-card-loading';
import ManageCommunityLoading from '../../../components/community/manage-community-loadding-component';
import {getSessionContent}from '../../../global-state/actions/course-actions';
import ListCardCommentSessionStudent from '../../../components/community/session-community/list-cards-comment-session-component';

let outsideTab = "";
let outsidepageDiscussion = 0;
let outsidepageQuestion = 0;
let outsideTrie = "";
let outsideFiltre = "";
let outsidesearchFilter = "";
let outsideAtBottom = false;

const SessionCommunityPage : FC <ReturnType<typeof mapStateToProps> & ReturnType <typeof mapDispatchToProps>& ComponentProps<any>> = (props: any) => {
    const [actualTab, setActualTab] = useState<string>("discussion")
    const [trie, setTrie] = useState("recent");
    const [filtre, setFiltre] = useState("all");
    const [searchFilter, setSearchFilter] = useState("");
    const [doSearch, setDoSearch] = useState(false);
    const [pageDiscussion, setPageDiscussion] = useState(0);
    const [pageQuestion, setPageQuestion] = useState(0);
    const [atBottom, setAtBottom] = useState(false);
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh]= useState(false);
    outsideTab = actualTab;
    outsidepageDiscussion = pageDiscussion;
    outsidepageQuestion = pageQuestion;
    outsideTrie = trie;
    outsideFiltre = filtre;
    outsidesearchFilter = searchFilter;
    outsideAtBottom = atBottom;
   
    useEffect(() => {

        setActualTab(props?.tabChatManager);
    }, [props?.tabChatManager]);
    useEffect(() => {
        setLoading(props?.loading)
    }, [props?.loading]);
    useEffect(() => {
        setRefresh(props?.refresh)
    }, [props?.refresh]);


    useEffect(() => {
        if(props?.sessionInfo!=undefined){
          changeList("recent", "all", "", 0, true, false)
        }
      }, [props?.sessionInfo])
      
    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        
        props?.getSessionContent(props?.idSession)
        //changeList("recent", "all", "", 0, true, false)
        setActualTab("discussion");
        return () => {
            window.removeEventListener('scroll', handleScroll);
            props?.setActualTabManageChat("discussion");
            setTrie("recent");
            setFiltre("all");
            setDoSearch(false);
            setSearchFilter("");
            setPageDiscussion(0);
            setPageQuestion(0);
        }
    }, [])
    const handleScroll = (event: any) => {
        if (!(outsideAtBottom) && isBottom()) {
            setAtBottom(true);
            changeList(outsideTrie, outsideFiltre, outsidesearchFilter, (outsideTab === 'discussion') ? outsidepageDiscussion + 1 : outsidepageQuestion + 1, false, false);
            if (outsideTab === 'discussion') {
                setPageDiscussion(outsidepageDiscussion + 1)
            } else {
                setPageQuestion(outsidepageQuestion + 1)
            }
        }
        if (!isBottom()) {
            setAtBottom(false);
        }
    }
    const isBottom = () => {
        return ((window.innerHeight + window.scrollY) >= document.body.offsetHeight);
    }

    
    const getSubject=(typeInput:string)=>{
        return [props?.idSession+" SESSION "+typeInput,
        props?.sessionInfo?.subjectsBySession?.map((obj: any) => {
           if (obj.course) {
               return [obj?.course?.id+" COURSE "+typeInput,
               obj.course.orders.map((chap: any) => {
                   return chap.chapter.id+" CHAPTER "+typeInput
               })
               ]
           }
           if (obj.path) {
               return [obj.path.id+" PATH "+typeInput,
               obj.path.subjectsByPath.map((chap: any) => {
                   if (chap.course) {
                       return [chap.course.id+" COURSE "+typeInput,
                       chap.course.orders.map((chap1: any) => {
                           return chap1.chapter.id+" CHAPTER "+typeInput
                       })
                       ]
                   }
               })
               ]
           }

       })].flat(Infinity)
    }
    const changeList = (trieInput: string, filter: string, searchFilterInput: string, page: number, isSearch: boolean, refreshInput: boolean) => {
        if (actualTab === "discussion") {
            if (filter === "all") {
                let object = {
                    subject: getSubject("FORUM"),
                    trie: trieInput,
                    searchFilter: searchFilterInput,
                    page: page,
                    refresh: refreshInput,
                }
                if(typeof object!== undefined && object?.subject?.length!=0){
                props?.getSubjectCommunity(object, props?.connectedUser?.idSchool, isSearch);
                }
            } else {
                let filtreObject = JSON.parse(filter);
                let subjects = filtreObject?.id + " " + filtreObject?.typeCategory + " FORUM"
                let object = {
                    subject: [subjects],
                    trie: trieInput,
                    searchFilter: searchFilterInput,
                    page: page,
                    refresh: refreshInput,
                }
                if(typeof object!== undefined && object?.subject?.length!=0){
                props?.getSubjectCommunity(object, props?.connectedUser?.idSchool, isSearch);
                }
            }


        } else if (actualTab === "question") {
            if (filter === "all") {
                let object = {
                    subject: getSubject("QUESTION"),
                    trie: trieInput,
                    searchFilter: searchFilterInput,
                    page: page,
                    refresh: refreshInput,
                }
                if(typeof object!== undefined && object?.subject?.length!=0){
                props?.getSubjectCommunity(object, props?.connectedUser?.idSchool, isSearch);
                }
            } else {
                let filtreObject = JSON.parse(filter);
                let subjects = filtreObject.id + " " + filtreObject.typeCategory + " QUESTION"
                let object = {
                    subject: [subjects],
                    trie: trieInput,
                    searchFilter: searchFilterInput,
                    page: page,
                    refresh: refreshInput,
                }
                if(typeof object!== undefined && object?.subject?.length!=0){
                props?.getSubjectCommunity(object, props?.connectedUser?.idSchool, isSearch);
                }
            }
        }
    }
    const Search = (doSearchInput: any) => {
        switch (outsideTab) {
            case 'discussion':
                setPageDiscussion(0);
                break;
            case 'question':
                setPageQuestion(0);
                break;
        }
        setTrie(doSearchInput.trie);
        setFiltre(doSearchInput.filtre);
        setSearchFilter(doSearchInput.searchFilter)
        setDoSearch(true);
        changeList(doSearchInput.trie, doSearchInput.filtre, doSearchInput.searchFilter, 0, true, true);
    }
    return (
        <>
        {!loading && <div className="page-wrapper-height">
            <ManageCommunitySessionComponent Searching={Search} />
            <div style={{ maxWidth: '1200px', margin: 'auto' }}>
                {refresh?
                <div className="col-md-10">
                <CommentCardLoading/>
                <CommentCardLoading/>
                <CommentCardLoading/>
                <CommentCardLoading/>
            </div>:
            <>
             <div className="row d-flex justify-content-center">
                    <div className="col-md-12 p-0 mt-5">
                        <ListCardCommentSessionStudent doSearch={doSearch} what={actualTab} postsList={props?.listPosts} />
                    </div>
                </div>
            </>}
            
            </div>
        </div>}
        {loading && <div className="page-wrapper-height">
            <ManageCommunityLoading title={props?.sessionInfo?.title}/>
            <div style={{maxWidth:'1200px', margin : 'auto'}}>
                <div className="row d-flex justify-content-center mt-5">
                    <div className="col-md-12 p-0" >
                        <CommentCardLoading />
                    </div>
                    <div className="col-md-12 p-0" >
                        <CommentCardLoading />
                    </div>
                    <div className="col-md-12 p-0" >
                        <CommentCardLoading />
                    </div>
                    <div className="col-md-12 p-0" >
                        <CommentCardLoading />
                    </div>
                </div>
            </div>
        </div>
        }
    </>
    )
} 
const mapStateToProps = (state: AppState) => ({
    tabChatManager: state.chatReducer.tabChatManager,
    sessionInfo:state.courseReducer.sessionInfo,
    connectedUser: state.authReducer.connectedUser,
    listPosts: state.chatReducer.listPosts,
    loading: state.chatReducer.loadingPosts,
    refresh: state.chatReducer.refreshPosts,
    idSession:state.studentTraining.currentTraining.id
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators({
        getSubjectCommunity,
        setActualTabManageChat,
        getSessionContent
        
    }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SessionCommunityPage);