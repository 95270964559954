import { ComponentProps, FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { useTranslation } from 'react-i18next';
import { getStudent } from '../../global-state/actions/profile-actions';
import { getStudentsOfProf, setActualTab } from '../../global-state/actions/professor-actions';
import { redirect, clearHistoryArray } from '../../global-state/actions/breadcrumb-actions';
import "../../translations/i18n";
import axios from '../../helpers/interceptors';
import { getMyStudentsByCourse, getStudents, getRegistrationForm, setRegistrationForm, setApplicationFile, getApplicationFile, getFollow, getStudentInfoById } from '../../global-state/actions/course-actions';
import TableElement from '../../elements/table/normal-table-element';
import { useHistory } from 'react-router-dom';
import EmptyListElement from '../../elements/empty-list/normal-empty-list-element';
import { returnStartPathUrl } from '../../helpers/domainCheck';
const TableStudentsListTrainingComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();
    const moment = require('moment');
    const history = useHistory();

    const [sizeGeneral, setSizeGeneral] = useState(20);
    const [sizeCandidates, setSizeCandidates] = useState(20);
    const [sizeCertified, setSizeCertified] = useState(20);
    const [sizeInscribed, setSizeInscribed] = useState(20);
    const [sizeSuspended, setSizeSuspended] = useState(20);
    const [sizeUnpaid, setSizeUnpaid] = useState(20);

    const [allDataLength, setAllDataLength] = useState<any>(null);
    const [candidateDataLength, setCandidateDataLength] = useState<any>(null);
    const [certifiedDataLength, setCertifiedDataLength] = useState<any>(null);
    const [inscribedDataLength, setInscribedDataLength] = useState<any>(null);
    const [suspendedDataLength, setSuspendedDataLength] = useState<any>(null);
    const [unpaidDataLength, setUnpaidDataLength] = useState<any>(null);

    const [allData, setAllData] = useState<any>(null);
    const [candidateData, setCandidateData] = useState<any>(null);
    const [certifiedData, setCertifiedData] = useState<any>(null);
    const [inscribedData, setInscribedData] = useState<any>(null);
    const [suspendedData, setSuspendedData] = useState<any>(null);
    const [unpaidData, setUnpaidData] = useState<any>(null);
    const [atBottom, setAtBottom] = useState<any>(false);
    const statusList = [
        {label: t('candidat'), value: 'CANDIDATE', color: 'primary'},
        {label: t('Refused'), value: 'REFUSED', color: 'danger'},
        {label: t('ONGOING'), value: 'INSCRIBED', color: 'success'},
        {label: t('certifiedDashStudents'), value: 'CERTIFIED', color: 'info'},
        {label: t('Suspendu'), value: 'DEATIVATED', color: 'warning'},
    ]
    const generalHeaders = [
        { title: t('students_2'), sorted: true, classNames: "w-35", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t('inscribedTimeStudent'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('Dossier'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('lastVisitProfile_1'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('statusFilter'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 ms-auto" },
    ]
    const suspendedHeaders = [
        { title: t('students_2'), sorted: true, classNames: "w-35", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t('Dossier'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('inscribedTimeStudent'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('suspendedAt'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 ms-auto" },
    ]
    const inscribedHeaders = [
        { title: t('students_2'), sorted: true, classNames: "w-35", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t('inscribedTimeStudent'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('Dossier'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('lastVisitProfile_1'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('progress'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 ms-auto me-3" },
    ]
    const candidateHeaders = [
        { title: t('students_2'), sorted: true, classNames: "w-22", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t('demandTable'), sorted: true, classNames: "w-20", scope: "col", buttonClasses: "d-flex button-trie px-0 " },
        { title: t('Dossier'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('statusFilter'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 ms-auto me-3" },
    ]
    const unpaidHeaders = [
        { title: t('students_2'), sorted: true, classNames: "w-80", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t('Fileacceptedon'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 " },
   ]
    const certifiedHeaders = [
        { title: t('students_2'), sorted: true, classNames: "w-17", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t('Dossier'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('inscribedTimeStudent'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('certifiedTitle'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('obtentionPeriod'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('diplomeButton'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto me-4" },
    ]
    const [actualTab, setActualTab] = useState((props?.lastTabClicked) ? props?.lastTabClicked : "All");
    const dateFormat = require('dateformat')
        
    const badgeHandler = (dateSent:any,status:string) => {
            if (dateSent < 3) {
                return 'secondary'
            } else if (dateSent >= 3 && dateSent < 6) {
                return 'warning'
            } else {
                return status
            }
    }
    useEffect(() => {
        let lang = localStorage.getItem("language") || "fr"
        if (lang !== "en") {
            require('moment/locale/' + lang);
            moment.defineLocale(lang, { parentLocale: lang });
        } else {
            require('moment/locale/' + 'fr');
            moment.defineLocale('fr', { parentLocale: 'fr' });
        }
    }, []);
    useEffect(() => {
        document.addEventListener('scroll', handleScroll);

        return () => {
            document.removeEventListener('scroll', handleScroll);
        }
    })
    const handleScroll = () => {

        if (!(atBottom) && (window.innerHeight + window.scrollY +1) >= document.documentElement.scrollHeight) {
            setAtBottom(true);
            if (actualTab === 'All') {
                if (allDataLength > sizeGeneral) {
                    props?.addDataList('general')
                }
            } else if (actualTab === 'Candidate') {
                if (candidateDataLength > sizeCandidates) {
                    props?.addDataList('candidates')
                }
            } else if (actualTab === 'Inscribed') {
                if (inscribedDataLength > sizeInscribed) {
                    props?.addDataList('inscribed')
                }
            }else 
                if (unpaidDataLength > sizeUnpaid) {
                    props?.addDataList('unpaid')
                }
            
            else {
                if (certifiedDataLength > sizeCertified) {
                    props?.addDataList('certified')
                }
            }
            document.removeEventListener('scroll', handleScroll);
        }
        if (((window.innerHeight + window.scrollY +1) >= document.documentElement.scrollHeight)) {
            setAtBottom(false);
        }
    }

    useEffect(() => {

        
        setSizeGeneral(props?.data?.sizeGeneral);
        setSizeCandidates(props?.data?.sizeCandidates);
        setSizeCertified(props?.data?.sizeCertified);
        setSizeInscribed(props?.data?.sizeInscribed);
        setSizeSuspended(props?.data?.sizeSuspended);
        setSizeUnpaid(props?.data?.sizeUnpaid);

    }, [props?.data])
    useEffect(() => {
        setActualTab(props?.lastTabClicked);
    }, [props?.lastTabClicked])
    useEffect(() => {
        const list = {
            headers: generalHeaders,
            body: props?.listeStudents.generalInfo?.list.map((student: any) => {
                const status = statusList.find(obj => obj?.value === student?.status);
                return {
                    row: {
                        student: student,
                        actionName: "goToPath",
                        classNames: "table-white",
                        columns: [
                            { content: student?.firstName + " " + student.lastName, classNames: "", actionName: '' },
                            { content: dateFormat(student?.inscriptionDate, "dd/mm/yyyy"), classNames: "text-center", actionName: '' },
                            {
                                content: (student?.registrationForm && student?.registrationForm !== null && student?.registrationForm !== 0)? <button style={{ position: 'absolute', top: '40%' }} className={`btn-Document ${(student?.status === 'CANDIDATE') ? '' :(student?.status === 'INSCRIBED')?'accepted': 'refuse'}`}>
                                    <span className="material-icons-outlined">description</span>
                                </button>:<button className="btn btn-Document" disabled> <span className="material-icons-outlined">highlight_off</span> </button>,
                                classNames: `text-center with-icon relative-position d-flex align-items-center justify-content-center`,
                                actionName: (student?.registrationForm && student?.registrationForm !== null && student?.registrationForm !== 0)?'goToRegistration': ''
                            },
                            {
                                content: (student?.lastVisit)?moment(student?.lastVisit).fromNow(): '-',
                                classNames: `text-center`,
                                actionName: ''
                            },
                            {
                                content: status?.label,
                                classNames: `text-end badge-text  ${status?.color}`,
                                actionName: ''
                            },
                        ]
                    }
                }
            })
        }
        setAllData(list);
        setAllDataLength(props?.listeStudents.generalInfo?.length);
    }, [props?.listeStudents?.generalInfo?.list])
    useEffect(() => {
        const list = {
            headers: unpaidHeaders,
            body: props?.listeStudents.unpaidInfo?.list.map((student: any) => {
                const dateSent = Math.ceil((new Date().getTime() - new Date(student?.dateupdate).getTime()) / (1000 * 3600 * 24));

                return {
                    row: {
                        student: student,
                        actionName: "goToPath",
                        classNames: "table-white",
                        columns: [
                            { content: student?.firstName + " " + student.lastName, classNames: "", actionName: '' },
                            {
                                content: <span>{dateFormat(student?.dateupdate, "dd/mm/yyyy ")} {(student?.status === 'UNPAID') ?
                                <span className={`badge-text ${badgeHandler(dateSent,'refuse')}`}>/ {t('TimePassed')} {dateSent} {t('joursVisit')}</span>
                                :
                                <span className={`badge-text secondary`}>/ {t('TimePassed')} {dateSent} {t('joursVisit')}</span>
                                   }
                                 </span>,
                                classNames: `text-end`,
                                actionName: ''
                            }                        ]
                    }
                }
            })
        }
        setUnpaidData(list);
        setUnpaidDataLength(props?.listeStudents.unpaidInfo?.length);
    }, [props?.listeStudents?.unpaidInfo?.list])
   
    useEffect(() => {
        const list = {
            headers: suspendedHeaders,
            body: props?.listeStudents.suspendedInfo?.list.map((student: any) => {
                const dateSent = Math.ceil((new Date().getTime() - new Date(student?.relation?.endDate).getTime()) / (1000 * 3600 * 24));

                return {
                    row: {
                        student: student,
                        actionName: "goToPath",
                        classNames: "table-white",
                        columns: [
                            { content: student?.firstName + " " + student.lastName, classNames: "", actionName: '' },
                            {
                                content: (student?.registrationForm && student?.registrationForm !== null && student?.registrationForm !== 0)? <button style={{ position: 'absolute', top: '40%' }} className={`btn-Document ${(student?.status === 'CANDIDATE') ? '' :(student?.status === 'DEACTIVATED')?'accepted': 'refuse'}`}>
                                    <span className="material-icons-outlined" >description</span>
                                </button>:<button className="btn btn-Document" disabled> <span className="material-icons-outlined">highlight_off</span> </button>,
                                classNames: `text-center with-icon relative-position d-flex align-items-center justify-content-center`,
                                actionName: (student?.registrationForm && student?.registrationForm !== null && student?.registrationForm !== 0)?'goToRegistration': ''
                            },
                            { content: dateFormat(student?.inscriptionDate, "dd/mm/yyyy"), classNames: "text-center", actionName: '' },
                            {
                                content: <span>{dateFormat(student?.relation.endDate, "dd/mm/yyyy")} 
                                         <span className={`badge-text ${badgeHandler(dateSent,'danger')}`}>/ {t('TimePassed')} {dateSent} {t('joursVisit')}</span></span>,
                                classNames: `text-end`,
                                actionName: ''
                            }
                        ]
                    }
                }
            })
        }
        setSuspendedData(list);
        setSuspendedDataLength(props?.listeStudents.suspendedInfo?.length);
    }, [props?.listeStudents?.suspendedInfo?.list])
    useEffect(() => {
        const list = {
            headers: candidateHeaders,
            body: props?.listeStudents.candidatesInfo?.list.map((student: any) => {
                const dateSent = Math.ceil((new Date().getTime() - new Date(student?.demandDate).getTime()) / (1000 * 3600 * 24));
                return {
                    row: {
                        student: student,
                        actionName: "goToPath",
                        classNames: "table-white",
                        columns: [
                            { content: student?.firstName + " " + student.lastName, classNames: "", actionName: '' },
                            {
                                content: <span>{dateFormat(student?.demandDate, "dd/mm/yyyy ")} {(student?.status === 'CANDIDATE') ?
                                    <span className={`badge-text ${badgeHandler(dateSent,'danger')}`}>/ {t('TimePassed')} {dateSent} {t('joursVisit')}</span>
                                    :
                                    <span className={`badge-text secondary`}>/ {t('TimePassed')} {dateSent} {t('joursVisit')}</span>
                                }
                                </span>,
                                classNames: "",
                                actionName: ''
                            },
                            {
                                content: (student?.registrationForm && student?.registrationForm !== null && student?.registrationForm !== 0)? <button style={{ position: 'absolute', top: '40%' }} className={`btn-Document ${(student?.status === 'CANDIDATE') ? '' : 'refuse'}`}>
                                    <span className="material-icons-outlined">description</span>
                                </button>:<button className="btn btn-Document" disabled> <span className="material-icons-outlined">highlight_off</span> </button>,
                                classNames: `text-center with-icon relative-position d-flex align-items-center justify-content-center`,
                                actionName: (student?.registrationForm && student?.registrationForm !== null && student?.registrationForm !== 0)?'goToRegistration': ''
                            },
                            {
                                content: (student.status === "CANDIDATE") ? t('candidat') : t('Refused'),
                                classNames: `text-end badge-text  ${(student?.status === "CANDIDATE") ? 'primary' : 'danger'}`,
                                actionName: ''
                            },
                        ]
                    }
                }
            })
        }
        setCandidateData(list);
        setCandidateDataLength(props?.listeStudents.candidatesInfo?.length);
    }, [props?.listeStudents?.candidatesInfo?.list])
    useEffect(() => {
        const list = {
            headers: certifiedHeaders,
            body: props?.listeStudents.certifiedInfo?.list.map((student: any) => {
                return {
                    row: {
                        student: student,
                        actionName: "goToPath",
                        classNames: "table-white",
                        columns: [
                            { content: student?.firstName + " " + student.lastName, classNames: "", actionName: '' },
                            {
                                content: (student?.registrationForm && student?.registrationForm !== null && student?.registrationForm !== 0)? <button style={{ position: 'absolute', top: '40%' }} className={`btn-Document accepted`}>
                                    <span className="material-icons-outlined">description</span>
                                </button>:<button className="btn btn-Document" disabled> <span className="material-icons-outlined">highlight_off</span> </button>,
                                classNames: `text-center with-icon relative-position d-flex align-items-center justify-content-center`,
                                actionName: (student?.registrationForm && student?.registrationForm !== null && student?.registrationForm !== 0)?'goToRegistration': ''
                            },
                            { content: dateFormat(student?.inscriptionDate, "dd/mm/yyyy"), classNames: "text-center", actionName: '' },
                            { content: dateFormat(student?.certificationDate, "dd/mm/yyyy"), classNames: "text-center", actionName: '' },
                            { content: t('In') + " " + Math.ceil((new Date(student?.certificationDate).getTime() - new Date(student?.inscriptionDate).getTime()) / (1000 * 3600 * 24)) + " " + t('joursVisit'), classNames: "text-center", actionName: '' },
                            {
                                content: <button style={{ position: "absolute", bottom: 20, right: 16 }} type="button" className="btn-Secondary medium icon-left">
                                    <span className="material-icons-outlined"> download </span>
                                    <div className="m-auto ps-2">{t('diplomeButton')}</div></button>, classNames: "text-end with-icon p-0 m-0 relative-position", actionName: 'downloadCertification'
                            },

                        ]
                    }
                }
            })
        }
        setCertifiedData(list);
        setCertifiedDataLength(props?.listeStudents.certifiedInfo?.length);
    }, [props?.listeStudents?.certifiedInfo?.list])
    useEffect(() => {
        const list = {
            headers: inscribedHeaders,
            body: props?.listeStudents.inscribedInfo?.list.map((student: any) => {
                const date = Math.ceil((new Date().getTime() - new Date(student?.lastVisit).getTime()) / (1000 * 3600 * 24))
                return {
                    row: {
                        student: student,
                        actionName: "goToPath",
                        classNames: "table-white",
                        columns: [
                            { content: student?.firstName + " " + student.lastName, classNames: "", actionName: '' },
                            { content: dateFormat(student?.inscriptionDate, "dd/mm/yyyy"), classNames: "text-center", actionName: '' },
                            {
                                content: (student?.registrationForm && student?.registrationForm !== null && student?.registrationForm !== 0)? <button style={{ position: 'absolute', top: '40%' }} className={`btn-Document accepted`}>
                                    <span className="material-icons-outlined">description</span>
                                </button>:<button className="btn btn-Document" disabled> <span className="material-icons-outlined">highlight_off</span> </button>,
                                classNames: `text-center with-icon relative-position d-flex align-items-center justify-content-center`,
                                actionName: (student?.registrationForm && student?.registrationForm !== null && student?.registrationForm !== 0)?'goToRegistration': ''
                            },
                            { content: (student?.lastVisit === null)? '-' : 
                            (date < 30)? <span style={{color: '#272727'}}>{date + " " + t('joursVisit')}</span>: 
                            (date>= 30 && date < 60)?<span style={{color: '#F5B95F'}}>{date + " " + t('joursVisit')}</span>: 
                            <span style={{color: '#E06A6A'}}>{date + " " + t('joursVisit')}</span>, classNames: "text-center", actionName: 'text-center' },
                            { content: (student?.progress === null)? '0%': student?.progress + '%', classNames: "text-end", actionName: '' },
                        ]
                    }
                }
            })
        }
        setInscribedData(list);
        setInscribedDataLength(props?.listeStudents.certifiedInfo?.length);
    }, [props?.listeStudents?.inscribedInfo?.list])
    const goToregistration = async (Student: any) => {

        props?.clearHistoryArray()
        await props?.getStudentInfoById(Student.id,props?.schoolInfo?.id);
        await props?.setApplicationFile(Student.applicationFile);
        await props?.setRegistrationForm(Student.registrationForm);
        await props?.getFollow(Student.idFollow);
        props?.redirect(t('Etudiant_1'), `${returnStartPathUrl()}${props?.linkRole}${props?.typeTraining}/students`)
        props?.redirect(Student?.firstName + " " + Student?.lastName, `${returnStartPathUrl()}/students/profile/student/${Student.id}`)
        props?.redirect(t('reponseInscrit'), `${returnStartPathUrl()}/courses/registrationPreview/${Student.idFollow}/${Student.id}/${Student.registrationForm.id}`)
         history.push( `${returnStartPathUrl()}/courses/registrationPreview/${Student.idFollow}/${Student.id}/${Student.registrationForm.id}` )
    }
    const addDataListFn = (tabText: string) => {
        props?.addDataList(tabText);
    }
    const downloadFile = (e: any, link: string) => {
        let filename = link.split('/')[3];
        axios.post(`${process.env.REACT_APP_BASE_URL4}download/file`, { filename: filename }, { responseType: 'blob' }).then((res: any) => {
            const urlLink = window.URL.createObjectURL(new Blob([res.data]));
            const linkHtml = document.createElement('a');
            linkHtml.href = urlLink;
            linkHtml.setAttribute('download', decodeURIComponent(filename));
            document.body.appendChild(linkHtml);
            linkHtml.click();
        }).catch(err => console.log(err));
    }
    const doAction = async (value: any) => {

        if (value.action === 'goToPath') {
            await props?.getStudentInfoById(value.row.student.id,props?.schoolInfo?.id)
            props?.clearHistoryArray()
            props?.redirect(t('students_2'), `${returnStartPathUrl()}${props?.linkRole}${props?.typeTraining}/students`)
            props?.redirect(value.row.student?.firstName + " " + value.row.student?.lastName, `${returnStartPathUrl()}/students/profile/student/${value.row.student.id}`)
             history.push(`${returnStartPathUrl()}/students/profile/student/${value.row.student.id}`)
        }
        if (value.action === 'downloadCertification') {
            downloadFile(null, value.row.student.linkCertification);
        }
        if (value.action === 'goToRegistration') {
            goToregistration(value.row.student);
        }
    }

    return (
        <div className="col-md-12 mt-2">

            {(actualTab === "All") && <div>
                {(allData?.body?.length > 0) && <>

                    <TableElement data={allData} doAction={(value: any) => { doAction(value) }} />
        
                </>
                }
                {(allData?.body?.length === 0) &&
                    <div className="w-100 text-center mt-5">
                        <EmptyListElement lines={[t('noStudentInscribed')]} />
                    </div>
                }
            </div>}

            {(actualTab === "Candidate") &&
                <div>
                    {(candidateData?.body?.length > 0) && <>
                        <TableElement data={candidateData} doAction={(value: any) => { doAction(value) }} />
            
                    </>
                    }
                    {(candidateData?.body?.length === 0) &&
                        <div className="w-100 text-center mt-5">
                            <EmptyListElement lines={[t('noCandidateFound')]} />
                        </div>
                    }
                </div>}

            {(actualTab === "Certified") &&
                <div>
                    {(certifiedData?.body?.length > 0) && <>
                        <TableElement data={certifiedData} doAction={(value: any) => { doAction(value) }} />
               
                    </>
                    }
                    {(certifiedData?.body?.length === 0) &&
                        <div className="w-100 text-center mt-5">
                            <EmptyListElement lines={[t('noCertifedFound')]} />
                        </div>
                    }
                </div>
            }
            {(actualTab === "Inscribed") &&
                <div>
                    {(inscribedData?.body?.length > 0) && <>
                        <TableElement data={inscribedData} doAction={(value: any) => { doAction(value) }} />
            
                    </>
                    }
                    {(inscribedData?.body?.length === 0) &&
                        <div className="w-100 text-center mt-5">
                            <EmptyListElement lines={[t('noStudentFound')]} />
                        </div>
                    }
                </div>
            }
            {(actualTab === "Suspended") &&
                <div>
                    {(suspendedData?.body?.length > 0) && <>
                        <TableElement data={suspendedData} doAction={(value: any) => { doAction(value) }} />
                  
                    </>
                    }
                    {(suspendedData?.body?.length === 0) &&
                        <div className="w-100 text-center mt-5">
                            <EmptyListElement lines={[t('noSuspendedFound')]} />
                        </div>
                    }
                </div>
            }
          {(actualTab === "Unpaid") &&
                <div>
                    {(unpaidData?.body?.length > 0) && <>
                        <TableElement data={unpaidData} doAction={(value: any) => { doAction(value) }} />
               
                    </>
                    }
                    {(unpaidData?.body?.length === 0) &&
                        <div className="w-100 text-center mt-5">
                            <EmptyListElement lines={[t('noUnpaidFound')]} />
                        </div>
                    }
                </div>
            }
        </div>
    );
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    myStudents: state.courseReducer.myStudents,
    schoolInfo:state.schoolInfoReducer.schoolInfo,
    nameSchool: state.authReducer.connectedSchoolInfo?.code,
    connectedUser: state.authReducer.connectedUser,
    listeStudents: state.profReducer.listeStudents,
    courseInfo: state.courseReducer.courseInfo,
    registration: state.courseReducer.registration,
    applicationFile: state.courseReducer.applicationFile,
    history: ownProps?.history,
    studentInfo: state.courseReducer.studentInfo,
    lastTabClicked: state.profReducer.lastTabClicked,
    addDataList: ownProps?.addDataList,
    data: ownProps?.data,
    typeTraining: ownProps?.typeTraining,
    linkRole: ownProps?.linkRole
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            getMyStudentsByCourse,
            getStudents,
            getApplicationFile,
            getRegistrationForm,
            getFollow,
            getStudent,
            getStudentsOfProf,
            setApplicationFile,
            setRegistrationForm,
            setActualTab,
            getStudentInfoById, redirect, clearHistoryArray
        },
        dispatch
    );


export default connect(mapStateToProps, mapDispatchToProps)(TableStudentsListTrainingComponent);

