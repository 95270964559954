import { ComponentProps, FC, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { useTranslation } from "react-i18next";
import { updatePath } from '../../../global-state/actions/breadcrumb-actions';
import { createChapter, updateChapter, getChapter } from '../../../global-state/actions/course-actions';
import ReactPlayer from 'react-player/lazy';
import CardFileElement from "../../../elements/card-file/card-file-element";


const ChapterPreviewComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const [courseForm, setCourseForm] = useState<any>();
    const [allListe, setAllListe] = useState<any[]>([]);
    const [refresh, setRefresh] = useState<boolean>(true);
    const [chapterForm, setChapterForm] = useState<any>();
    const [videoHeight,setVideoHeight] = useState<any>('')

    const showFile = (index: number) => {

    }
    useEffect(()=>{
        if(chapterForm?.video && (chapterForm?.video.includes('dai.ly')|| chapterForm?.video.includes('dailymotion.com'))){
            setVideoHeight('500px')
        }
        else{
            setVideoHeight('100%')
        }
    },[chapterForm?.video])
    useEffect(() => {
       if ((props?.chapterForm && props?.chapterForm.id !== chapterForm?.id)) {

            setChapterForm(props?.chapterForm);
        }
    }, [props?.chapterForm]);
    useEffect(() => {
        let compteurChapter = 1;
        let compteurTest = 1;
        const liste = props?.courseForm?.orders?.
            concat(props?.listTest?.filter((test: any) => test.qnaType === "KNOWLEDGETEST"))?.
            sort((a: any, b: any) => {
                if (a.index > b.index) { return 1; } else if (a.index < b.index) { return -1 } else { return 0 }
            })?.map((obj: any, index: number) => {
                if (obj?.chapter) {
                    obj['compteur'] = compteurChapter;
                    compteurChapter++;
                } else {
                    obj['compteur'] = compteurTest;
                    compteurTest++;
                }
                return { label: '', value: JSON.stringify(obj) };
            });
        if (props?.listTest?.filter((test: any) => test.qnaType === "FINALEXAM").length > 0) {
            liste.push(props?.listTest?.filter((test: any) => test.qnaType === "FINALEXAM")[0]);
        }
        setAllListe(liste);
        setCourseForm(props?.courseForm);
        if (props?.chapterForm) {
            setChapterForm(props?.chapterForm)
        }
    }, []);

    return (<div className="mt-4 ">
        {(chapterForm?.video !== '' && chapterForm?.video !== null) && <section style={{ backgroundColor: '#272727' }} >
            <div className='d-flex align-items-center justify-content-center' style={{ maxWidth: "1200px",minHeight: 500,position: 'relative', margin: 'auto' }}>
            {/* <ReactPlayer config={{ vimeo: { playerOptions: { pip: true } } }}
                width='1200px' height='500px' 
                controls={true} stopOnUnmount={false}
                url={chapterForm?.video } 
                /> */}
                {/* <iframe src={chapterForm?.video + "?controls=true&pip=true&"} frameBorder="0" allowFullScreen width='100%' height='100%' /> */}
                <ReactPlayer 
                                controls={true}
                                config={{
                                    vimeo: {
                                        playerOptions: {
                                            responsive: true,
                              
                                            
                                        },
                                    },
                                    youtube: {
                                        embedOptions: {
                                            height: '500px',
                                        }
                                    },
                                    dailymotion:{
                                       
                                    }
                                }}
                                width='80%'
                                height={videoHeight}
                                stopOnUnmount={false}
                                url={chapterForm?.video}

                            />
            </div>
        </section>}
        <section className="pt-3 mx-2">
            <div className="d-flex justify-content-center bg-with-border" style={{maxWidth: "1200px", margin: 'auto', borderRadius: "3px", backgroundColor:'#FFFFFF' }}>
                <div className="py-4" style={{ width: 'calc(100% - 408px)'}}>
                    <h3 className="H3-Headline mb-3 pb-2" >{t('chapterPresentation')}</h3>
                    <p className="body-md neutral-2" dangerouslySetInnerHTML={{__html: chapterForm?.text}}></p>
                </div>
            </div>
        </section>
        
        <section className="py-3" style={{ maxWidth: "1200px", margin: 'auto' }}>
            <div className="mb-4 mx-2">
                {chapterForm?.files?.map((file: any, i: number) => <div key={i}>
                    <CardFileElement filename={file.name} file={file.link} link={file.link} />
                    </div>
                    )}
            </div>
        </section>
    </div>)
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({ updatePath, getChapter, createChapter, updateChapter }, dispatch);
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    courseForm: state.courseReducer.courseInfo,
    listHistory: state.historyReducer.listHistoryBreadCrumb,
    history: ownProps?.history,
    chapterForm: state.courseReducer.chapterForm,
    indexChapter: state.courseReducer.indexChapter,
    listTest: state.courseReducer.listTest,
    indexContent: state.courseReducer.indexContent,
    title: ownProps?.title
});
export default connect(mapStateToProps, mapDispatchToProps)(ChapterPreviewComponent)