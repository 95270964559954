import qs from "querystring";
import * as tus from "tus-js-client";
import { fetchData } from "../base/functions/Functions";
import Axios from "axios";


interface UploadProcessBody {
    uploadStatus: UploadStatus,
    progress: number
}
type UploadStatus = "" | "UPLOADING" | "TRANSCODING" | "CHECKING" | "AVAILABLE" | "NOT_AVAILABLE" | "ERROR"

export const checkVideoAvalibility = async (videoId: string, uploadStatus: any): Promise<UploadProcessBody> => {
    try {
        const response: any = await Axios.request({
            url: "oembed.json?url=" + videoId + "&autoplay=false&muted=false&loop=false&playsinline=false&controls=true&autopause=false&byline=false&portrait=false&title=false&responsive=true",
            method: "get",
            baseURL: "https://vimeo.com/api/",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            data: qs.stringify({
                "url": videoId,

            })
        })

        if (response.status === 200) {
            if (uploadStatus.uploadStatus === "CHECKING" && uploadStatus.progress > 2) {
                return {
                    uploadStatus: "CHECKING",
                    progress: uploadStatus.progress + 1
                };
            } else {
                return {
                    uploadStatus: "AVAILABLE",
                    progress: 0
                };
            }
        } else {
            let result: UploadProcessBody = await checkVideoTranscode(videoId);
            return result;

        }


        // if (response.uri!== "") {
        //     return {
        //         uploadStatus: "AVAILABLE",
        //         progress: 0
        //     };
        // } else {
        //     return {
        //         uploadStatus: "NOT_AVAILABLE",
        //         progress: 0
        //     };
        // }

    } catch (error) {
        console.error("Error checking video availability:", error);
        return {
            uploadStatus: "NOT_AVAILABLE",
            progress: 0
        };
    }
};



export const checkVideoTranscode = async (videoUrl: string): Promise<UploadProcessBody> => {
    try {
        const transcodeStatus = await fetchData('POST', `${process.env.REACT_APP_BASE_URL4}checkPourcentage`, {
            link: '/videos/' + videoUrl.split('/')[4]
        });

        if (transcodeStatus.message === 'Your video is still transcoding.') {
            return {
                uploadStatus: "TRANSCODING",
                progress: 50
            };
        } else if (transcodeStatus.message === 'Your video finished transcoding.') {
            return {
                uploadStatus: "CHECKING",
                progress: 100
            };
        } else if (transcodeStatus.message === 'Your video encountered an error during transcoding.') {
            return {
                uploadStatus: "ERROR",
                progress: 0
            };
        } else {
            console.error("Unexpected transcode status: ", transcodeStatus);
            return {
                uploadStatus: "ERROR",
                progress: 0
            };
        }
    } catch (error) {
        console.error("Error checking video transcode:", error);
        return {
            uploadStatus: "ERROR",
            progress: 0
        };
    }
};


export const uploading = async ({
    createVideoLink,
    getVideoLink,
    domain,
    checkStopped,
    ...postBody
}: any) => {
    const { videoData, size } = postBody;
    let videoBody = {
        size: size,
        name: videoData.name,
        domain: domain,
    }

    let videoLinkResponse = await fetchData('POST', `${process.env.REACT_APP_BASE_URL4}getLinkVideo/vimeo`, {
        size: size,
        name: videoData.name,
        domain: domain
    });
    //await uploadToVimeo(videoLinkResponse?.videolink,videoLinkResponse?.linkToUploadVideo,videoData,size,0,videoLinkResponse?.uri);
    return videoLinkResponse;

}




const uploadToVimeo = async (videoUri: any,
    uploadLink: any,
    videoData: any,
    size: any,
    uploadOffset: any,
    uri: any) => {

    try {
        const upload: any = new tus.Upload(videoData, {
            endpoint: "https://api.vimeo.com/me/videos",
            uploadUrl: uploadLink,
            retryDelays: [0, 3000, 5000, 10000, 20000],
            metadata: {
                name: videoData.name,
                filetype: videoData.type,
            },
            headers: {},
            onProgress: function (bytesUploaded, bytesTotal) {
                let percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
            }
        });
        await upload.start()
        return "suceess"

    } catch (error) {
        console.error("Error checking video availability:", error);
        return {
            uploadStatus: "ERROR",
            progress: 0
        };
    }

}

export const getVideoSubtitles = async (videoId: number) => {
    try {
        return await fetchData('GET', `${process.env.REACT_APP_BASE_URL4}getAllSubtitles/${videoId}`, {});
    } catch (error) {
        console.error("Error checking video availability:", error);
        return null;
    }
}


