import { AppState } from '../../../../global-state/store/root-reducers';
import { ComponentProps, FC } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { getDuration } from '../../../../base/functions/Functions';


const ChapterSidebarComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {


    return (
        <>
            <li key={props?.indexChapter} className={` component-chapters-progress-chapters-list-chapter${(props?.content?.progress?.progress !== undefined && props?.content?.progress?.progress !== null && props?.content?.progress?.progress === "COMPLETED") && '-done'} 
                                                ${(props?.open === true) ? "component-chapters-progress-chapters-list-chapter" : "component-chapters-progress-chapters-list-chapter close"}
                                                ${(props?.sectionContent?.index === props?.indexChapter) && 'component-chapters-progress-chapters-list-chapter-current'}`}
                onClick={() => { props?.displayContent(props?.content, props?.index, "CHAPTER") }} >
                <div className={` d-flex flex-column w-100 ${(props?.open === true) ? "component-chapters-progress-description" : "component-chapters-progress-description close"}`}>
                    <div className="d-flex flex-row  justify-content-between" style={{ paddingTop: 15 }} >
                        <span className="component-chapters-progress-chapters-list-chapter-title"> {props?.content?.title}</span>
                        <span className="ms-auto component-chapters-progress-chapters-list-chapter-duration"> {" "} {getDuration(props?.content?.duration)}</span>
                    </div>
                </div>
                <div className="component-chapters-progress-chapters-list-chapter-progress-container" style={props.isLastChapter ? { width: "0px" } : {}}>
                    <div className="component-chapters-progress-chapters-list-chapter-progress-icon">
                        <span className="material-icons-round component-chapters-progress-chapters-list-chapter-progress-arc-svg">
                            check
                        </span>
                        <svg className="component-chapters-progress-chapters-list-chapter-progress-arc-left" width="23" height="42" viewBox="0 0 23 42" fill="none" >
                            <path d="M21.0968 2.46383C10.8218 2.46383 2.49219 10.7936 2.49219 21.0688C2.49219 31.3441 10.8218 39.6738 21.0968 39.6738" strokeWidth="3" strokeLinecap="round" />
                        </svg>
                        <svg className="component-chapters-progress-chapters-list-chapter-progress-arc-right" width="23" height="42" viewBox="0 0 23 42" fill="none" >
                            <path d="M2.10287 39.6739C12.3755 39.6739 20.7031 31.3461 20.7031 21.0733C20.7031 10.8004 12.3755 2.47266 2.10287 2.47265" strokeWidth="3" strokeLinecap="round" />
                        </svg>
                    </div>
                </div>
            </li>
            <style>
                {`
               body{
                   overflow:hidden;
                }
            `}
            </style>
        </>
    )
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
        },
        dispatch
    );
const mapStateToProps = (state: AppState, ownProps: any) => ({
    content: ownProps?.content,
    index: ownProps?.index,
    open: ownProps?.open,
    displayContent: ownProps?.displayContent,
    sectionContent: state.studentTraining.sectionContentDetails
});

export default connect(mapStateToProps, mapDispatchToProps)(ChapterSidebarComponent);

