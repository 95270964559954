/* eslint react/no-did-mount-set-state: 0 */
import { ComponentProps, FC, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../global-state/store/root-reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { clearHistoryArray, redirect } from '../../global-state/actions/breadcrumb-actions';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import withScrolling from 'react-dnd-scrollzone';
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
import { openModal } from '../../global-state/actions/modal-actions';
import { ModalIDS } from '../modals/modal-ids';
import { createTraining, getTrainings, addTest } from '../../global-state/actions/training-actions';
import FormationPathCardContainer from '../formations-card/formation-path-card-container';
import { useHistory } from 'react-router';
import { calculateDuration, getDuration } from '../../base/functions/Functions';
import PathExamCardComponent from '../formations-card/path-exam-card-component';
import { returnStartPathUrl } from '../../helpers/domainCheck';

const ScrollingComponent = withScrolling('div');
const ContentPathFormComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [durationTraining, setDurationTraining] = useState<any>(props?.training?.duration);
  const path = useMemo(() => {
    return props?.training
  }, [props?.training])
  const addTraining = async () => {

    let filter = {
      entityId: props?.connectedUser?.idSchool,
      entityType: "SCHOOL",
      idProf: props?.training?.idProf
    }
    await props?.getTrainings(filter).then(async(response:any)=>{
      if( !!response && !!response.data && response?.data?.length>0){
        await props?.openModal({ idModal: ModalIDS.ADD_PATH_TRAINING, size: "lg" })
      }
      else{
        await props?.openModal({ idModal: ModalIDS.PATH_EMPTY, size: "lg" })
      }
      
    })
   
  }
  const addNewTest = async () => {
    let index = path?.subjectsByPath?.length

    let test = {
      id: null,
      questionByQnaList: [],
      index,
      title: t('examTitleBegin'),
      duration: 15,
      scaleNote: 0,
      entityId: props?.training.id,
      entityType: "PATH",
      status: "ACTIVE",
      qnaType: "FINALEXAM",
      openQuestion: null
    }
    await props?.addTest(test)
    props?.clearHistoryArray()
    props?.redirect(t('Parcours'), `${returnStartPathUrl()}/courses/create-formation/path/content`)
    props?.redirect(path.title || t('InitialPathName'), `${returnStartPathUrl()}/courses/create-formation/path/content`)
    props?.redirect(t('Examen Final'), "")
    history.push({ pathname: `${returnStartPathUrl()}/courses/create-formation/path/test` })
  }


  useEffect(() => {
    setDurationTraining(calculateDuration(props?.training?.subjectsByPath, props?.training?.exams));
  }, [props?.training])


  return (
    <>
      {(path?.subjectsByPath?.length || path?.exams?.length) ? <>
        <div className=" justify-content-center form-course">
          <div className="px-4 py-4 completed-course mx-auto" style={{ maxWidth: 1248 }}>
            <div className="d-flex flex-row align-items-center flex-wrap justify-content-between my-2">
              <div className=" d-flex flex-row">
                <button className="btn btn-outline-dark btn-Primary large icon-left me-2" style={{ display: 'flex', alignItems: 'center' }} onClick={() => { addTraining() }} >
                  <span className="material-icons"  >add</span>
                  <span className="ms-2" style={{ width: 'max-content' }}>{t('ajouter un contenu')}</span></button>
                <div style={{ marginRight: "2%" }}></div>
                <button disabled={path?.exams?.length > 0} className="btn-Primary large icon-left me-3" style={{ display: 'flex', alignItems: 'center' }} onClick={() => addNewTest()} >
                  <span className="material-icons" >add</span>
                  <span className="ms-2" style={{ width: 'max-content' }}>{t('newFinalExam')}</span></button>
              </div>
              <div className=" d-flex flex-row align-items-center my-2 justify-content-end">
                <div className="row justify-content-end select-input">
                  <div className="d-flex flex-row">
                    <span className="material-icons-outlined me-1 " style={{ color: '#B1B1B1' }}>timer</span> <span className="body-md bold">{getDuration(durationTraining)}</span>
                  </div>
                </div>


              </div>
            </div>
          </div>

          {(path?.subjectsByPath?.length || path?.exams?.length) &&
            <>
              <DndProvider backend={HTML5Backend}>
                <ScrollingComponent className="px-4 d-flex flex-column justify-content-center mx-auto" style={{ maxWidth: 1248 }}>
                  <FormationPathCardContainer />
                </ScrollingComponent>
              </DndProvider>
              {path?.exams.length === 1 &&
              <div className="px-4 d-flex flex-column justify-content-center mx-auto" style={{ maxWidth: 1248 }}>
                    <PathExamCardComponent t={t} key={path?.subjectsByPath?.length } index={path?.subjectsByPath?.length } data={path?.exams[0]} options={[]} />
              </div>}
            </>}
        </div>
      </>
        : <>
          <div className="d-flex flex-column align-items-center justify-content-center px-4" style={{ marginTop: 160 }}>
            <h3 className="H3-Headline mb-2 text-center">{t('createContentText')}</h3>
            <div className=" mt-3 d-flex flex-row flex-wrap justify-content-center">
              <button onClick={() => { addTraining() }} className="mt-2 btn-Primary large icon-left mx-2">
                <span className="material-icons-outlined"> add </span>
                <div className="m-auto ps-2">{t('newContent')}</div>
              </button>
              <button className="mx-2 mt-2 btn-Primary large icon-left" onClick={() => addNewTest()} >
                <span className="material-icons-outlined"> add </span>
                <div className="m-auto ps-2">{t('newFinalExam')}</div>
              </button>
            </div>
          </div>
        </>}
    </>

  );
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      getTrainings,
      openModal,
      createTraining,
      addTest,
      clearHistoryArray, 
      redirect
    },
    dispatch
  );
const mapStateToProps = (state: AppState) => ({
  connectedUser: state.authReducer.connectedUser,
  training: state.trainingReducer.path,
  trainings: state.trainingReducer.trainings
});

export default connect(mapStateToProps, mapDispatchToProps)(ContentPathFormComponent);