import { ComponentProps, FC, useEffect, useState } from "react"
import { connect } from "react-redux";
import { AppState } from "../../global-state/store/root-reducers";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import "../../translations/i18n";
import CardActivityProf from "./card-prof/card-activity-prof";
import CardActivityProfLoading from "./card-prof/card-activity-prof-loading";
import CardActivityProfInitial from "./card-prof-initial/card-activity-prof-initial";
import { getCatalog } from "../../global-state/actions/catalog-duplicaction-actions";
import { fetchData } from "../../base/functions/Functions";
import { getActivityApi, getListCoursesApi, getNewCoursesApi, getSubscriptionApi } from "../../services/activity.service";
const ProfessorActivitiesCardComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const [loading, setLoading] = useState(true);
    const [courseList, setCourseList] = useState<any>([]);
    const [activities, setActitivities] = useState<any>();
    const [newCourses, setNewCourses ] = useState<any>();
    const [subscription, setSubscription ] = useState<any>();
    const [courses, setCourses] = useState<any>();

    const getListCourses = async (filter:any) =>{
        const result = await getListCoursesApi(filter)
        setCourses(result)
    }

    const getNewCourses = async (list:any) =>{
        if(!loading){
            setLoading(true)
        }
        const result = await getNewCoursesApi(list)

        setNewCourses(result)
        setLoading(false)
    }

    const getActivity = async (list: any)=>{
        if(!loading){
            setLoading(true)
        }
        const result = await getActivityApi(list)
        setActitivities(result)
        setLoading(false)
    }

    const getSubscription = async (list: any) => {
        if(!loading){
            setLoading(true)
        }
        const result = await getSubscriptionApi(list)
        setSubscription(result)
        setLoading(false)
    }

    useEffect(() => {
        let filter = {
            trainingType: "COURSE",
            entityId: props?.user?.idSchool,
            entityType: "SCHOOL",
            status: "PUBLISHED",
            idProf: props?.user?.idPerson,
        }
        if(courses?.length === 0 || courses === undefined){
            getListCourses(filter)
        }
    }, [])

    useEffect(() => {

        if (Array.isArray(courses) && courses?.length > 0) {
            let ids = courses?.map((e: any) => e.id)
            setCourseList(ids)
        }
    }, [courses])

    useEffect(() => {
        if (Array.isArray(courseList) && courseList.length > 0) {
            getActivity(courseList)
            getSubscription(courseList)
            getNewCourses(courseList)
        }
    }, [courseList])

    return (
        <>
            <div className="container-fluid p-0">
                <div className="d-flex flex-column justify-content-center">


                    <div className="my-4">



                        {!loading &&
                            (courses?.length > 0) &&
                            <CardActivityProf activities={activities} subscription={subscription} newCourses={newCourses} courseList={courseList} />
                        }
                        {(courses?.length === 0) &&
                            <CardActivityProfInitial activities={activities} subscription={subscription} newCourses={newCourses} courseList={courseList} />
                        }
                        {loading &&
                            (courses?.length > 0) && <div className="d-flex w-100 flex-wrap align-items-center justify-content-between ">
                                <CardActivityProfLoading />
                            </div>}

                    </div>
                </div>
            </div>

        </>

    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    myCourses: state.courseReducer.myCourses,
    connectedUser: state.authReducer.connectedUser,
    history: ownProps?.history,
    user: state.authReducer.connectedUser,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        { getCatalog },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(ProfessorActivitiesCardComponent);