import {ComponentProps, FC, useCallback, useEffect, useState } from "react"
import { connect } from "react-redux";
import { AppState } from "../../global-state/store/root-reducers";
import SelectElement from "../../elements/select/select-element";
import { useTranslation } from "react-i18next";
import InputSearch from '../../elements/inputs/input-search';
import { debounce } from "lodash";
import { ModalIDS } from '../modals/modal-ids';
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { openModal } from '../../global-state/actions/modal-actions';
interface Props{
    isSearching:any,
}
const FilterCommunityComponent: FC<ReturnType<typeof mapStateToProps>& ReturnType<typeof mapDispatchToProps>  &ComponentProps<any>>=(props)=>{
    const [tab,setTab] = useState<string>("");
    const [trieOption, setTrieOption]= useState<any[]>([]);
    const [filterOption, setFilterOption]= useState<any[]>([]);
    const [trie, setTrie]= useState<string>("recent");
    const [filtre, setFiltre]= useState<string>("all");
    const [searchFilter, setSearchFilter]= useState<string>("");
    const [drawerOpened, setDrawerOpened] = useState(false)
    const { t } = useTranslation();
   
    useEffect(() => {
        setTrie("recent");
        setFiltre("all");
        setSearchFilter("") 
        setTab(props?.tabChatManager)
        setFilterOption([{value:"all", label:t('allSubjects_2') },{value:`${JSON.stringify({id:props?.courseInfo?.id, typeCategory: 'COURSE'})}`, label:`${props?.courseInfo?.title}`},
        props?.courseInfo?.orders.map((obj:any)=>{
            return {value:`${JSON.stringify({id: obj.chapter.id, typeCategory: 'CHAPTER'})}`,label:`${obj.chapter.title}` }})].flat(Infinity))
        setTrieOption([{value:"recent",label:t('recent') },{value:"oldest", label:t('oldest')},{value:"maxResponses", label:t('maxResponses')}])
    }, [props?.tabChatManager])

    const sort= (trieSearch:string)=>{
        setTrie(trieSearch);
        props?.isSearching({trie: trieSearch, filtre: filtre, searchFilter: searchFilter});
    }
    const filter= (filterInput:string)=>{
        setFiltre(filterInput);
        props?.isSearching({trie: trie, filtre: filterInput, searchFilter: searchFilter});
 
    }
    const Search= (search:string)=>{
        setSearchFilter(search);
        props?.isSearching({trie: trie, filtre: filtre, searchFilter: search});
    }
    const debounceSearch = useCallback( debounce(Search, 300), []);
   
    const openModalFn = () => {
        if(tab==="discussion"){
            props?.openModal({idModal: ModalIDS.ADD_COMMENT, size: "md"});
        }else{
            props?.openModal({idModal: ModalIDS.ADD_QUESTION, size: "md"});
        }
      
    }
    return(
        <>
            {
                props.isMobile ? 
                <div>
                    <div className="d-flex justify-content-evenly align-items-center">
                        <div style={{width:"85%"}}>
                            <InputSearch value={searchFilter}  onChange={debounceSearch} placeholder={t('searchProfessors')}/>
                            

                        </div>
                        <div onClick={()=>{setDrawerOpened(true)}}>
                            <svg width="35" height="38" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_24525_5905)">
                                    <path d="M9.16667 17H12.8333V15.1667H9.16667V17ZM2.75 6V7.83333H19.25V6H2.75ZM5.5 12.4167H16.5V10.5833H5.5V12.4167Z" fill="#272727"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_24525_5905">
                                        <rect width="22" height="22" fill="white" transform="translate(0 0.5)"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>
                    <div style={{transform: drawerOpened ? "translateX(0)" : "translateX(100%)", transition:"transform 0.1s ease-in-out", zIndex:999,width: "85%",height:"calc(100vh - 184px)", backgroundColor:"white", position: "fixed", right:"0%", top:184, padding:"20px"}}>
                        <div className="d-flex justify-content-between">
                            <div onClick={()=>{setDrawerOpened(false)}}>
                                <svg width="35" height="35" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_24503_90855)">
                                        <path d="M14.1348 15.2678L15.2678 14.1348L10.7759 9.64282L15.2678 5.15086L14.1348 4.01782L9.64282 8.50979L5.15086 4.01782L4.01782 5.15086L8.50979 9.64282L4.01782 14.1348L5.15086 15.2678L9.64282 10.7759L14.1348 15.2678Z" fill="#323232"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_24503_90855">
                                            <rect width="19.2857" height="19.2857" fill="white" transform="matrix(0 1 -1 0 19.2856 0)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <div className="underline" onClick={()=>{
                                setTrie("recent");
                                setFiltre("all");
                            }}>
                                <span style={{borderBottom:"1px solid #272727"}}> Supprimer Filtres </span>
                            </div>
                        </div>
                        <div className="mt-4">
                            <div className="">
                                <div style={{color:"#CACBCE"}} className="mb-3">Trier par</div>
                                {
                                    trieOption.map((option, index) =>
                                        <div key={index}
                                            onClick={() => {
                                                option.value !== trie && sort(option.value)
                                            }}
                                            style={{
                                                marginTop: "16px",
                                                fontWeight: option.value === trie ? 400 : 300
                                            }}>

                                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                                <span>{option.label}</span>
                                                {option.value === trie &&
                                                    <span style={{ marginLeft: '4px' }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
                                                            < g clip-path="url(#clip0_24525_67190)">
                                                                <path d="M5.6251 10.392L3.01885 7.78579L2.13135 8.66704L5.6251 12.1608L13.1251 4.66079L12.2438 3.77954L5.6251 10.392Z" fill="#323232"/>
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_24525_67190">
                                                                    <rect width="15" height="15" fill="white" transform="translate(0 0.285767)"/>
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </span>
                                                }
                                            </span>
                                        </div>
                                    )
                                }


                                <div style={{color:"#CACBCE", marginTop: "40px"}} className="mb-3">Sujet</div>
                                {
                                    filterOption.length > 0 && filterOption.map((filt, index)=><div key={index} 
                                    onClick={()=>{ filter(filt.value)}}
                                    style={filt.value === filtre ? {marginTop:"16px", fontWeight:400}:{marginTop:"16px", fontWeight:300}}>
                                        {filt.label}
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className="row">
                    <div className="col-md-4  mt-5 d-flex justify-content-start text-right px-0">
                        <button onClick={() => openModalFn()} type="button" className="btn-Primary large icon-left">
                            <span className="material-icons-outlined">add</span>
                            <div className="m-auto ps-2">{tab==="question"?t('addQuestionButton'):t('addCommentButton')}</div>
                        </button>
                    </div>
                    <div className="col-md-8  mt-5 d-flex justify-content-end text-right px-0">
                        <div className="select-input me-2" style={{ width: '225px' }}>
                        
                        <SelectElement disabled={false} taille={'lg'} defaultValue={trie} options={trieOption}  placeholder ={""} onChange={sort} /> 
                        </div> 
                        <div className="select-input me-2" style={{ width: '225px' }}>
                            
                        <SelectElement disabled={false} taille={'lg'}  defaultValue={filtre} options={filterOption}  placeholder ={""}  onChange={filter} /> 
                        </div> 
                        <InputSearch  value={searchFilter}  onChange={debounceSearch} placeholder={t('searchProfessors')}/>
                    </div>
                </div>
            }
                        
        </>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    courseInfo: state.studentTraining.currentCourse,
    connectedUser: state.authReducer.connectedUser,
    tabChatManager: state.chatReducer.tabChatManager,
    isSearching: ownProps?.isSearching
  });
  const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
      {
        openModal,
      },
      dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FilterCommunityComponent);