/* eslint react/no-did-mount-set-state: 0 */
import { ChangeEvent, ComponentProps, FC, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../global-state/store/root-reducers';
import { updatePath } from '../../global-state/actions/breadcrumb-actions'
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { uploadImageCourse } from '../../global-state/actions/course-actions';
import { clearFormVideo } from '../../global-state/actions/upload-video-course-actions';
import { debounce } from 'lodash';
import ChipInput from 'material-ui-chip-input'
import { useTranslation } from 'react-i18next';
import TextareaWithoutBorderElement from '../../elements/text-area/text-area-without-border-element';
import UploadVideoCourseComponent from '../card-uploads/upload-video-course-component';
import { createTraining } from '../../global-state/actions/training-actions';
import CropImage from '../card-uploads/crop-image-course-component';
import InputUploadImage from '../../elements/inputs/input-upload-image';
import axiosInstance from '../../helpers/interceptors';
import HtmlTooltip from '../../elements/tooltip/Tooltip-Customized-element';
import ContainerUploadSubtitle from '../card-uploads/container-upload-subtitle-component';
import { getPathSubtitleVimeoUploaded } from '../../global-state/actions/training-actions';
import { useHistory } from "react-router";
import ReactQuill from 'react-quill';

const DescriptionPathFormComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const [path, setPath] = useState<any>();
    const [msgLimitInput, setmsgLimitInput] = useState("");
    const [withUpload, setWithUpload] = useState<any>()
    const { t } = useTranslation();
    const history = useHistory();

    const config = {
        entityType: "SCHOOL",
        entityId: props?.schoolInfo.id,
        creator: props?.connectedUser?.idPerson,
        idProf: props?.connectedUser.role === "role_adminstrator" ? props?.path.idProf : props?.connectedUser?.idPerson,
        // status: "DRAFT",
    }
    useEffect(() => {
        setPath({ ...props?.path, ...config });
        if (props?.path?.video != undefined && props?.path?.video?.length != 0) {
            props.getPathSubtitleVimeoUploaded(props?.path?.video?.split('/').pop())
        }
    }, [props?.path]);

    function deleteVideo(e: any) {
        e.preventDefault();
        const initialPath = path;
        initialPath['video'] = '';
        initialPath['type'] = 'PATH';
        setPath(initialPath);
        props?.createTraining("paths", initialPath)
    }
    const deleteFile = () => {
        const initialPath = path;
        initialPath['linkImg'] = '';
        initialPath['type'] = 'PATH';
        setPath(initialPath);
        props?.createTraining("paths", initialPath)
    }
    const addFile = async (file: any) => {

        const form = new FormData();



        form.append('file', file[0]);
        const initialPath = { ...path };
        initialPath['type'] = 'PATH';
        const baseUrl = process.env.REACT_APP_BASE_URL4;
        const link: any = await axiosInstance.post(baseUrl + "upload/image", form).then((res) => res.data.link);
        initialPath.linkImg = link
        await props?.createTraining("paths", initialPath)
        setPath(initialPath);
    };

    function uploadVideo(link: string) {
        const initialPath = { ...path };
        initialPath['video'] = link;
        initialPath['type'] = 'PATH';
        setPath(initialPath);
        props?.createTraining("paths", initialPath)
    }
    const textAreaAdjust = (element: any, tag: string) => {
        if (element?.value?.length === 254) setmsgLimitInput("Le titre du parcour ne doit pas dépasser les 254 caractères (Espaces inclus)")
        if (element?.value?.length < 254) setmsgLimitInput("")

        const initialPath = { ...props?.path };

        if (tag === 'text') {
            initialPath[element.id] = element.value;
        }
        else if (tag === 'themes') {
            let themesLength = 0
            element.value?.map((element: any) =>
                themesLength += element.length
            )
            if (themesLength + element.value.length > 255) {
                element.value.pop()
            }
            else {
                initialPath['theme'] = element.value;
            }
        }
        else {
            initialPath['skills'] = element;
            let elementToAdd = element[element?.length - 1]
            if (elementToAdd?.length > 254) {
                element.pop()
            }
        }
        initialPath['type'] = 'PATH';
        setPath(initialPath);
        if (initialPath.id !== null) {
            debouncedChangeHandler(initialPath)
        }

    }
    const createPathApi = (pathObject: any) => {
        props?.createTraining("paths", pathObject)
    }
    const debouncedChangeHandler = useCallback(debounce(createPathApi, 1000), []);

    const [isLoadingVideoToGetSubtitle, setIsLoadingVideoToGetSubtitle] = useState<{ from: string, isLoading: boolean }>({
        from: 'path', isLoading: false
    })

    const getSubtitleUploaded = () => {
        setIsLoadingVideoToGetSubtitle(prev => ({ ...prev, isLoading: true }));
        if (props?.path?.video != undefined && props?.path?.video?.length != 0) {
            props.getPathSubtitleVimeoUploaded(props?.path?.video?.split('/').pop())
        }
    }

    return (
        <>
            <style>
                {`
                .ql-container.ql-snow {
                    border:none;
                    background-color:white;
                    padding-top:8px;
                }
                .ql-editor {
                    min-height:30px !important;
                    padding:0;
                    font-size:16px;
                    font-family: "Public", sans-serif;
                }

                .ql-editor.ql-blank::before {
                    color: #cacbce;
                    font-style: normal; 
                    left: 0px;
                    right: 8px;
                    
                }
            `}
            </style>
            <div className=" px-4 py-4 completed-course mx-auto " style={{ maxWidth: 1248 }}>
                <section>
                    <div className="d-flex align-items-center justify-content-between mx-2">
                        <div className="d-flex flex-column w-100 my-5 position-relative">
                            <h3 className="H3-Headline mb-3" >{t('InitialPathName')} *</h3>
                            {/* parcour */}
                            <input
                                dir="ltr"
                                type="text"
                                id="title"
                                value={path?.title || ''}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => textAreaAdjust(e.target, 'text')}
                                className="input-title"
                                placeholder={t('titlePathPlaceholder')}
                                maxLength={254}
                            />
                            <span className='msg-error'>{msgLimitInput}</span>
                        </div>
                    </div>
                    <div>
                        <h3 className="H4-Subtitle mb-3" >Thématique du parcours </h3>
                        <ChipInput onChange={(theme) => textAreaAdjust({ id: "themes", value: theme }, 'themes')} defaultValue={path?.theme} classes={{ root: 'transparent', underline: 'transparent' }} fullWidth={true} alwaysShowPlaceholder={true} placeholder={"Saisir la thématique du cours"} />
                    </div>
                </section>
                <section style={{ backgroundColor: "#FFFFFF" }}>
                    <div className="py-5">
                        <div className="d-flex flex-column w-100">
                            <h3 className="H3-Headline mb-3" >{t('PathLevel')}</h3>
                            <div className="d-flex flex-row align-items-center justify-content-start flex-wrap w-100">
                                <input maxLength={254} dir="ltr" onClick={(e) => textAreaAdjust({ id: 'level', value: 'ANYLEVEL' }, 'text')} className="input-style-radio-button" type="radio" name="exampleRadios3" id="anyLevel" value="option1" defaultChecked={(path && path?.level === 'ANYLEVEL')} />
                                <label style={{ cursor: 'pointer', width: 170, textAlign: 'center' }} className='me-4' htmlFor="anyLevel">{t('anyLevelCourse')}</label>

                                <input maxLength={254} dir="ltr" onClick={(e) => textAreaAdjust({ id: 'level', value: 'BEGINNER' }, 'text')} className="input-style-radio-button" type="radio" name="exampleRadios3" id="beginner" value="option2" defaultChecked={(path && path?.level === 'BEGINNER')} />
                                <label style={{ cursor: 'pointer', width: 170, textAlign: 'center' }} className='me-4' htmlFor="beginner">{t('firstLevel')}</label>

                                <input maxLength={254} dir="ltr" onClick={(e) => textAreaAdjust({ id: 'level', value: 'INTERMEDIATE' }, 'text')} className="input-style-radio-button" type="radio" name="exampleRadios3" id="intermediate" value="option1" defaultChecked={(path && path?.level === 'INTERMEDIATE')} />
                                <label style={{ cursor: 'pointer', width: 170, textAlign: 'center' }} className='me-4' htmlFor="intermediate">{t('secondLevel')}</label>

                                <input maxLength={254} dir="ltr" onClick={(e) => textAreaAdjust({ id: 'level', value: 'ADVANCED' }, 'text')} className="input-style-radio-button" type="radio" name="exampleRadios3" id="expert" value="option2" defaultChecked={(path && path?.level === 'ADVANCED')} />
                                <label style={{ cursor: 'pointer', width: 170, textAlign: 'center' }} htmlFor="expert" >{t('thirdLevel')}</label>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="py-5">
                    <h3 className="H3-Headline mb-3">{t('PathDecriptionCreation')} *</h3>
                    <div className="d-flex justify-content-center w-100 bg-with-border" style={{ borderRadius: "3px" }}>
                        <div style={{ width: 'calc(100% - 408px)', marginTop: 56, marginBottom: 56 }}>
                            <div className='' style={{ width: "100%" }}>
                                <div className="d-flex align-items-start mb-2">
                                    <label className="body-md bold black-800">{t('resume')}*</label>
                                    <HtmlTooltip content={<div className='d-flex flex-column'><div>{t('pathResume')}</div></div>}>
                                        <span className="material-icons-outlined neutral-3 ms-2" style={{ cursor: 'pointer' }}>info</span>
                                    </HtmlTooltip >
                                </div>
                                <ReactQuill modules={{ "toolbar": false }} placeholder={t('textAreaPlaceholder')} value={path?.resume} onChange={(e: string) => textAreaAdjust({ id: 'resume', value: e }, 'text')} />
                            </div>
                            <div className=' mt-5' style={{ width: "100%" }}>
                                <div className="d-flex align-items-start mb-2">
                                    <label className="body-md bold black-800">{t('objectives')}</label>
                                    <HtmlTooltip content={<div className='d-flex flex-column'>
                                        <div>
                                            {t('pathObjectives')}</div>
                                    </div>}>
                                        <span className="material-icons-outlined neutral-3 ms-2" style={{ cursor: 'pointer' }}>info</span>
                                    </HtmlTooltip >
                                </div>
                                <TextareaWithoutBorderElement value={path?.objectives} id={"objectives"} placeholder={t('textAreaPlaceholder')} onChange={(e: string) => textAreaAdjust({ id: 'objectives', value: e }, 'text')} />
                            </div>
                            <div className=' mt-5' style={{ width: "100%" }}>
                                <div className="d-flex align-items-start mb-2">
                                    <label className="body-md bold black-800">{t('prerequisite1')}</label>
                                    <HtmlTooltip content={<div className='d-flex flex-column'>
                                        <div>
                                            {t('pathPreRequisite')}</div>
                                    </div>}>
                                        <span className="material-icons-outlined neutral-3 ms-2" style={{ cursor: 'pointer' }}>info</span>
                                    </HtmlTooltip >
                                </div>
                                <TextareaWithoutBorderElement value={path?.prerequisite} id={"prerequisite"} placeholder={t('textAreaPlaceholder')} onChange={(e: string) => textAreaAdjust({ id: 'prerequisite', value: e }, 'text')} />
                            </div>
                            <div className="skills-form-professor mt-5" style={{ width: "100%" }}>
                                <div className="d-flex align-items-start mb-2">
                                    <label className="body-md bold black-800">{t('skillsNeeded')}</label>
                                    <HtmlTooltip content={<div className='d-flex flex-column'>
                                        <div>
                                            {t('pathSkillsToLearn')}</div>
                                    </div>}>
                                        <span className="material-icons-outlined neutral-3 ms-2" style={{ cursor: 'pointer' }}>info</span>
                                    </HtmlTooltip >
                                </div>
                                <ChipInput classes={{ root: 'transparent', underline: 'transparent' }} fullWidth={true} alwaysShowPlaceholder={true} placeholder={t('skillsPlaceholder')} defaultValue={path?.skills} onChange={(chips) => textAreaAdjust(chips, 'chips')} />
                            </div>
                        </div>
                    </div>
                </section>
                <section style={{ backgroundColor: "#FFFFFF" }} className="py-5">
                    <div >
                        <h3 className="H3-Headline mb-3">{t('PathCoverDescription')} *</h3>
                        {/* <UploadImageCourseComponent /> */}
                        <>{!!path?.linkImg && path.linkImg !== '' ?
                            <CropImage file={path?.linkImg + "?cacheblock=true"} width={840} addFile={(image: any) => addFile(image)} deleteFile={deleteFile} />
                            : <InputUploadImage description={t('pathCoverPhot')} textButton={t('uploadImage_2')} addFile={(image: any) => addFile(image)} />}
                        </>
                    </div>
                </section>

                <section className="py-5">
                    {/* <h3 className="H3-Headline mb-3">{t('pathVideoDescription')}</h3> */}
                    <UploadVideoCourseComponent
                        titleComponent='parcours'
                        description={t('pathVideoDrop')}
                        buttonText1={t('uploadVideo_2')}
                        t={t}
                        deleteFile={deleteVideo}
                        uploadVideo={uploadVideo}
                        file={path?.video}
                        isLoadingVideoToGetSubtitle={isLoadingVideoToGetSubtitle}
                        setIsLoadingVideoToGetSubtitle={setIsLoadingVideoToGetSubtitle}
                        isUploading={() => setWithUpload(true)}
                        withUpload={withUpload}
                    />
                </section>
                {props?.path?.video?.length > 0 && <ContainerUploadSubtitle
                    getSubtitleUploaded={getSubtitleUploaded}
                    idVideo={props?.path.video.split('/').pop()}
                    type='isPath'
                />}
            </div>
        </>
    );
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators({
        createTraining,
        uploadImageCourse,
        updatePath,
        clearFormVideo,
        getPathSubtitleVimeoUploaded,
    }, dispatch);

const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    loggedIn: state.authReducer.loggedIn,
    courseTitle: ownProps?.courseTitle,
    path: state.trainingReducer.path,
    history: ownProps?.history,
    t: ownProps?.t,
});

export default connect(mapStateToProps, mapDispatchToProps)(DescriptionPathFormComponent);