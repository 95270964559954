import { FC } from "react";
import { connect } from "react-redux";
import { AnyAction, Dispatch, bindActionCreators } from "redux";
import { AppState } from "../../global-state/store/root-reducers";
import { handleSaveQuestion } from "../../global-state/actions/sondage-actions";

const FooterChoiceCardComponent: FC<
  ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
> = ({ questionByExam, handleSaveQuestion, sondageReducer, index }: any) => {

  return (
    <div className="footer-card-choice-sondage mb-4">
      {/* <button type="button" className="btn-Secondary large">
        ANNULER
      </button>
      <button
        type="button"
        className="btn-Primary large"
        onClick={() => handleSaveQuestion(sondageReducer, index)}
        // disabled={handleDisabled(questionByExam)}
      >
        ENREGISTRER
      </button>  */}
    </div>
  );
};

const mapStateToProps = (state: AppState, ownProps: any) => ({
  sondageReducer: state.examReducer.examToPass
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({
    handleSaveQuestion
  }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FooterChoiceCardComponent);
