import { ComponentProps, FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { AppState } from "../../../global-state/store/root-reducers";
import { setActualTabManageChat, getSubjectCommunity } from '../../../global-state/actions/chat-actions';
import FilterCommunityComponent from '../../filters/filter-community-cours-component';
interface Props {
    Searching: any
}
const ManageCommunityComponent = (props: any) => {
    const { t } = useTranslation();
    const [actualTab, setTab] = useState((props?.tabChatManager) ? props?.tabChatManager : "discussion")
    const [searching, setSearching] = useState(false);
    const setActualTab = (tab: string) => {
        props?.setActualTabManageChat(tab);

        if ((tab === "discussion") && (actualTab !== tab)) {
            let subjects = props?.courseInfo?.orders?.map((obj: any) => { return obj.chapter.id + " CHAPTER FORUM" })
            subjects.push(props?.courseInfo?.id + " COURSE FORUM")

            let object = {
                subject: subjects,
                trie: "recent",
                searchFilter: "",
                page: 0,
                refresh: false,
            }
            if (typeof object != undefined && object?.subject?.length != 0) {
                props?.getSubjectCommunity(object, props?.connectedUser?.idSchool, true);
            }
        } else if ((tab === "question") && (actualTab !== tab)) {
            let subjects = props?.courseInfo?.orders?.map((obj: any) => { return obj.chapter.id + " CHAPTER QUESTION" })
            subjects.push(props?.courseInfo?.id + " COURSE QUESTION")
            let object = {
                subject: subjects,
                trie: "recent",
                searchFilter: "",
                page: 0,
                refresh: false,
            }
            if (typeof object != undefined && object?.subject?.length != 0) {
                props?.getSubjectCommunity(object, props?.connectedUser?.idSchool, true);
            }

        }
    }
    const isSearching = (doSearch: any) => {
        props?.Searching(doSearch)
        setSearching(true)

    }
    return (
        <>
            <div >
                <div className="row justify-content-center m-0 w-100">
                    <div style={{ backgroundColor: "#FFFFFF" }} className="col-md-12">
                        <div style={{ maxWidth: '1200px', margin: 'auto'}} className="row justify-content-center">
                            <div className={props.isMobile ? "d-none": "col-md-12 d-flex justify-content-center mt-4 pt-2 px-0"}>
                                <h1 className="title-liste-student" style={{ color: '#272727' }}>{props?.courseInfo.title} <span style={{ marginLeft: '10px' }}></span></h1>
                            </div>
                            <div className="col-md-12 mb-5 d-flex justify-content-center text-left mt-4 px-0" style={props.isMobile && {width:'100%' }} >
                                <button type="button" style={props.isMobile && { width:'40%', fontSize: "16px", height: "50px", whiteSpace: "nowrap" }} className={(actualTab === 'discussion') ? "btn-Tabs me-2" : "btn-Tabs unselected me-2"} onClick={() => { setActualTab('discussion'); setTab('discussion') }}>{t('discussionButton')}</button>
                                <button type="button" style={props.isMobile && { width:'60%', fontSize: "16px", height: "50px", whiteSpace: "nowrap" }} className={(actualTab === 'question') ? "btn-Tabs ms-2 " : "btn-Tabs unselected me-2"} onClick={() => { setActualTab('question'); setTab('question') }}> {t('questionButton')}</button>
                            </div>
                        </div>
                    </div>
                    {(props?.listPosts.length !== 0 || (props?.listPosts.length === 0 && searching)) && <div style={{ maxWidth: '1200px', margin: 'auto' }} className="col-md-12">
                        <div className="row justify-content-between">
                            <FilterCommunityComponent isSearching={isSearching} isMobile={props.isMobile}/>
                        </div>
                    </div>}
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state: AppState) => ({
    courseInfo: state.studentTraining.currentCourse,
    tabChatManager: state.chatReducer.tabChatManager,
    connectedUser: state.authReducer.connectedUser,
    listPosts: state.chatReducer.listPosts,

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators({
        setActualTabManageChat,
        getSubjectCommunity
    }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ManageCommunityComponent);
