import { useState, useEffect, FC, useMemo } from 'react';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ComponentsProps } from '@material-ui/core/styles/props';
import stripeLogo from '../../styles/images/stripe_icon.png';
import { createOrUpdateShoolPaymentInfo, showPaymentSchoolInfo, createWebhook } from '../../global-state/actions/app-container-actions';
import { clearSaveReturnPath, saveReturnPath, RedirectPopup } from '../../global-state/actions/breadcrumb-actions'
import { useHistory, useParams } from 'react-router';
import axiosInstance from '../../helpers/interceptors';
import { openModal } from '../../global-state/actions/modal-actions';
import { ModalIDS } from '../modals/modal-ids';
import HtmlTooltip from '../../elements/tooltip/Tooltip-Customized-element';
import { migrateOffersApi } from '../../services/finance.service';



const countries = [
    { code: 'TN', label: 'TND - Dinar Tunisien', value: 'TND' },
    { code: 'EU', label: 'EUR - Euro', value: 'EUR' }
]
export interface Params {
    result: string
}
const StripeÎntegrationComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentsProps> = (props: any) => {
    const [schoolInfo, setSchoolInfo] = useState(props?.connectedSchool);
    const [paymentMethod, setPaymentMethod] = useState('none');
    const [currency, setCurrency]: any = useState(null);
    const [formChanged, setFormChanged] = useState(false);
    const history = useHistory();
    const { result } = useParams<Params>();
    const [connectToStripeLoading, setConnectToStripeLoading] = useState(false)

    let accountId = useMemo(() => props?.connectedSchool?.accountId, [props?.connectedSchool?.accountId])



    useEffect(() => {
        if (result && result === 'invalid' && (!props?.connectedSchool || props?.connectedSchool === null || !props?.connectedSchool.accountId || props?.connectedSchool.accountId === null)) {
            connectToStripe(null, "STRIPE");
        }
    }, [result])
    useEffect(() => {

        if (schoolInfo === null) {
            if ('none' === paymentMethod || currency === null) {
                setFormChanged(true);

            } else {
                setFormChanged(false);

            }
        } else if (schoolInfo !== null) {
            if (schoolInfo?.paymentEngine === paymentMethod && currency?.value === schoolInfo?.currency) {
                setFormChanged(true);
            } else {
                setFormChanged(false);
            }
        }

    }, [paymentMethod, currency])
    useEffect(() => {
        setSchoolInfo(props?.connectedSchool);
    }, [props?.connectedSchool])
    useEffect(() => {
        setSchoolInfo(props?.connectedSchool);
        setPaymentMethod((props?.connectedSchool === null) ? 'none' : props?.connectedSchool?.paymentEngine);
        setCurrency((props?.connectedSchool === null) ? null : countries?.find(obj => obj.value === props?.connectedSchool?.currency));
        setFormChanged(true);
        setConnectToStripeLoading(false)
    }, [])

    const connectToStripe = async (e: any, methodPayment: string) => {
        setConnectToStripeLoading(true)
        if (e !== null) { e.stopPropagation(); }
        let object: any = {};
        const copyInfo = { ...schoolInfo };
        if (schoolInfo !== null) {
            copyInfo.currency = methodPayment === 'STRIPE' ? 'EUR' : 'TND';
            copyInfo.paymentEngine = methodPayment;
            object = copyInfo;
        } else {
            object = {
                id: null,
                legalPersonId: props?.schoolInfo?.idSchool,
                currency: methodPayment === 'STRIPE' ? 'EUR' : 'TND',
                paymentEngine: methodPayment,
            }
        }
        if (methodPayment === 'STRIPE') {
            let connectToStripe = {
                "schoolId": props?.schoolInfo?.idSchool,
                "schoolDomain": props?.school?.domain
            }
    
            axiosInstance.post(`${process.env.REACT_APP_BASE_URL15}strip/createLinkAccount`, connectToStripe).then(async (response) => {
                object["accountId"] = response.data.accountId;
                object["isActivated"] = true;
                await props?.createOrUpdateShoolPaymentInfo(object);
                await props?.showPaymentSchoolInfo(object);
                if (!!response && !!response.data && response.data.accountId) {
                    let link = await migrateOffersApi(props?.schoolInfo?.idSchool)
                    window.location.href = link.linkAccount;
                }
                let webhookRequest = {
                    //stripeAccount: response.data.accountId,
                    url: "https://e980-41-226-24-181.ngrok-free.app/strip/schedule"
                }
                // await props?.createWebhook(webhookRequest);
                window.location.href = response.data.linkAccount;
            });
        } else {
            await props?.openModal({ idModal: ModalIDS.ID_ACCOUNT_PAYMEE, size: 'md', data: object })
        }
    }
    const disconnectCard = async (value: boolean) => {
        const copyInfo = { ...schoolInfo };
        copyInfo["isActivated"] = value;
        if (value === true && props?.redirectPopup) {
            history.goBack()
            props?.openModal({ idModal: ModalIDS.ACCEPT_TRAINING, size: 'md', data: { type: 'course' } })
            await props?.RedirectPopup(false)
        }
        await props?.createOrUpdateShoolPaymentInfo(copyInfo);
        await props?.showPaymentSchoolInfo(copyInfo);
    }


    const disconnectPaymentMethod = async () => {
        props?.openModal({ idModal: ModalIDS.DELETE_PAYMENT_METHOD, size: "md" });
    }

    useEffect(() => {
        if (schoolInfo != undefined) {
            verifyStripeAccount(schoolInfo?.accountId)
        }
    }, [])



    const verifyStripeAccount = (accountId: any) => {
        setConnectToStripeLoading(true)
        axiosInstance.get(`${process.env.REACT_APP_BASE_URL15}strip/checkAccount/` + schoolInfo?.accountId).then((resp: any) => {
            if (resp.data === false) {
                disconnectCard(false)
                setConnectToStripeLoading(false)
            }
        }).catch((err: any) => {
            console.error(err)
        })
        setConnectToStripeLoading(false)
    }

    useEffect(() => {
      if(!!accountId && accountId?.length!=0){
        setConnectToStripeLoading(false)
      }
    }, [accountId])
    

    return (
        <div>
            <div className="d-flex flex-column w-100  mb-3">
                {((!schoolInfo || schoolInfo === null || !schoolInfo?.accountId || schoolInfo?.accountId === null || schoolInfo === '') || (schoolInfo?.accountId !== '' && schoolInfo?.paymentEngine === "STRIPE")) &&
                    <div style={{ cursor: 'pointer', border: "1px solid #FFFFFF" }} onClick={() => setPaymentMethod('STRIPE')}
                        className={"bg-with-border white unchecked d-flex flex-wrap align-items-center justify-content-between mb-2"}  >
                        <div className="d-flex align-items-center justify-content-between w-100 p-4">
                            <div className='d-flex'>
                                <img width='auto' height="32px" src={stripeLogo} alt="stripe logo" />
                                <div className='d-flex align-items-start flex-column'>
                                    <p className="H3-Headline ms-3 d-flex align-items-center my-0">Stripe <span className='ms-2 body-sm bold'>(EUR - Euro)</span></p>


                                    {((!schoolInfo || schoolInfo?.accountId === '' || schoolInfo?.isActivated === false) && connectToStripeLoading === false) ?
                                        <p className="form-default ms-3 neutral-3 my-0">{props?.t('connectStripToSchool')}</p> :
                                        <p className="form-default ms-3 black-800 my-0">ID : {schoolInfo?.accountId} </p>}
                                </div>
                            </div>
                            {((!schoolInfo || schoolInfo?.accountId === '') && connectToStripeLoading === false) &&
                                <button className="btn-Primary medium icon-left align-self-end" onClick={(e) => connectToStripe(e, 'STRIPE')}>
                                    <span className="material-icons-outlined">
                                        sync_alt
                                    </span>

                                    <div className="m-auto ps-2">{props?.t('connect')}</div>
                                </button>
                            }
                            {(schoolInfo?.accountId !== '' && schoolInfo?.isActivated == true && connectToStripeLoading === false) &&
                                <>
                                    <div className='d-flex flex-row align-self-end'>
                                        <button disabled className="btn-Secondary medium icon-left me-1 " onClick={(e) => disconnectPaymentMethod()}>
                                            <span className="material-icons-outlined">
                                                close
                                            </span>
                                            <div className="m-auto ps-2">{props?.t('disconnect')}</div>
                                        </button>
                                        <div className=' mt-1'>
                                            <HtmlTooltip interactive={true}
                                                content={<div className='d-flex flex-column'>
                                                    <div className='content-tooltip'>
                                                        Vous ne pouvez pas vous déconnecter de votre compte Stripe. La déconnexion ne vous permettra plus d’accéder à vos formations payantes.
                                                    </div>

                                                </div>}>
                                                <span className="material-icons-outlined align-items-center" style={{ color: "#F5B95F", cursor: "pointer", fontSize: 26, position: 'static', width: 32, height: 32 }}>error</span>
                                            </HtmlTooltip >
                                        </div>

                                    </div>
                                </>}

                            {(schoolInfo?.accountId !== '' && schoolInfo?.isActivated === false && connectToStripeLoading === false) && <div className='d-flex'>


                                <button className="btn-Primary medium icon-left align-self-end mr-5" onClick={() => disconnectCard(true)}>
                                    <span className="material-icons-outlined">
                                        login
                                    </span>
                                    <div className="m-auto ps-2">{props?.t('reactivateCours')}</div>
                                </button>


                                <button className="btn-Primary medium icon-left align-self-end" onClick={(e) => connectToStripe(e, 'STRIPE')}>
                                    <span className="material-icons-outlined">
                                        sync_alt
                                    </span>
                                    <div className="m-auto ps-2">{props?.t('connect')}</div>
                                </button></div>}
                            {(connectToStripeLoading === true) &&
                                <button className="btn-Primary loaddata large align-items-center d-flex justify-content-center" style={{width:"141px", height:"36px"}}>
                                    <div className="threedots-large-Animation">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </button>
                            }

                        </div>

                    </div>}
            </div>
        </div>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedSchool: state.schoolInfoReducer.paymentSchoolInfo,
    returnPathPlateform: state.historyReducer.returnPathPlateform,
    schoolInfo: state.authReducer.connectedUser,
    redirectPopup: state.historyReducer.redirectPopup,
    school: state.schoolInfoReducer.schoolInfo,

});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>

    bindActionCreators(
        {
            createOrUpdateShoolPaymentInfo,
            clearSaveReturnPath,
            saveReturnPath,
            showPaymentSchoolInfo,
            openModal,
            RedirectPopup,
            createWebhook
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(StripeÎntegrationComponent));