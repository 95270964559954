/* eslint react/no-did-mount-set-state: 0 */
import { ComponentProps, FC, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../global-state/store/root-reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { clearHistoryArray, redirect } from '../../global-state/actions/breadcrumb-actions';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import withScrolling from 'react-dnd-scrollzone';
import { openModal } from '../../global-state/actions/modal-actions';
import { ModalIDS } from '../modals/modal-ids';
import { createTraining, getTrainings, addTest } from '../../global-state/actions/training-actions';
import FormationSessionCardContainer from '../formations-card/formation-session-card-container';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { calculateDuration, getDuration } from '../../base/functions/Functions';
import SessionExamCardComponent from '../formations-card/session-exam-card-component';
import { returnStartPathUrl } from '../../helpers/domainCheck';

const ScrollingComponent = withScrolling('div');
const ContentSessionFormComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
  const { t } = useTranslation();
  const [durationTraining, setDurationTraining] = useState<any>(0);
  const history = useHistory();
  const dateFormat = require('dateformat');

  const session = useMemo(() => {
    return props?.training
  }, [props?.training])
  
  const addTraining =async () => {

    let filter = {
      entityId: props?.connectedUser?.idSchool,
      entityType: "SCHOOL",
      idProf: props?.connectedUser?.idPerson
    }
    await props?.getTrainings(filter).then(async(response:any)=>{
      if( !!response && !!response.data && response?.data?.length>0){
        await props?.openModal({ idModal: ModalIDS.ADD_SESSION_TRAINING, size: "lg" })
      }
      else{
        await props?.openModal({ idModal: ModalIDS.PATH_EMPTY, size: "lg" })
      }
    })

  }
  const addNewTest = async () => {
    let index = session?.subjectsBySession?.length

    let test = {
      id: null,
      questionByQnaList: [],
      index,
      title: t('examTitleBegin'),
      duration: 15,
      scaleNote: 0,
      entityId: props?.training?.id,
      entityType: "SESSION",
      status: "ACTIVE",
      qnaType: "FINALEXAM",
      openQuestion: null
    }

    await props?.addTest(test)
    props?.clearHistoryArray()
    props?.redirect(t('Session'), `${returnStartPathUrl()}/courses/create-formation/session/content`)
    props?.redirect(session.title || t('SessionTitleCreation'), `${returnStartPathUrl()}/courses/create-formation/session/content`)
    props?.redirect(t('finalExam'), "")
    history.push({ pathname: `${returnStartPathUrl()}/courses/create-formation/session/test` })
  }

  const changeCloseDay = (date: any) => {
    let sessionCopy = { ...session }
    sessionCopy.closeDay = date
    props?.createTraining("sessions", sessionCopy)
  }

  const changeStartDay = (date: any) => {
    let sessionCopy = { ...session }
    sessionCopy.startDay = date
    props?.createTraining("sessions", sessionCopy)
  }


  useEffect(() => {
    setDurationTraining(calculateDuration(props?.training?.subjectsBySession, props?.training?.exams));
  }, [props?.training])

  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()

  const updateDate = (value: Date, status: string) => {
    if(status === 'startDate') {
      setStartDate(value)
      changeStartDay(value)
    }
    if (status === 'endDate') { 
      if(startDate && endDate && startDate > endDate) return
      setEndDate(value)
      changeCloseDay(value)
    }
  }

 const [durations] = useState<any>([])


 
  return (
    <>
      {(session?.subjectsBySession.length || session?.exams.length) ? <>
        <div className="justify-content-center form-course">
          <div className="px-4 py-4 completed-course mx-auto" style={{ maxWidth: 1248 }}>
            <div className="d-flex flex-row align-items-center flex-wrap justify-content-between my-2">
              <div className=" d-flex flex-row">
                <button className="btn btn-outline-dark btn-Primary large icon-left me-2" style={{ display: 'flex', alignItems: 'center' }} onClick={() => { addTraining() }} >
                  <span className="material-icons"  >add</span>
                  <span className="ms-2" style={{ width: 'max-content' }}>{t('ajouter un contenu')}</span></button>
                <div style={{ marginRight: "2%" }}></div>
                <button disabled={session?.exams?.length > 0} className="btn-Primary large icon-left me-3" style={{ display: 'flex', alignItems: 'center' }} onClick={async (e) => { await addNewTest() }}  >
                  <span className="material-icons" >add</span>
                  <span className="ms-2" style={{ width: 'max-content' }}>{t('newFinalExam')}</span></button>
              </div>
              <div className=" d-flex flex-row align-items-center my-2 justify-content-end">
                <span className="form-title mb-0 me-2">
                  {t("Du")}:
                </span>
                <input 
                  dir="ltr"
                  type="date" 
                  className="form-control input input-date text-default me-3 detail-liste-responsive-session-inputs-date" 
                  value={dateFormat(startDate, "yyyy-mm-dd")} 
                  onChange={(e: any) => { updateDate(e.target.value, 'startDate') }} 
                  min={dateFormat(startDate, "yyyy-mm-dd")}
                />

                <span className="form-title mb-0 me-2">
                  {t("Au")}:
                </span>
                <input 
                  dir="ltr"
                  type="date" 
                  className="form-control input  input-date text-default me-3 detail-liste-responsive-session-inputs-date" 
                  value={endDate !== undefined && dateFormat(endDate, "yyyy-mm-dd")} 
                  onChange={(e: any) => {  updateDate(e.target.value, 'endDate')}} 
                  // disabled={startDate === undefined}
                  min={dateFormat(startDate, "yyyy-mm-dd")}
                />

                <div className="d-flex flex-row ms-2">
                  <span className="material-icons-outlined me-1" style={{ color: '#B1B1B1' }}>timer</span> <span className="body-md bold">{getDuration(durationTraining)}</span>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>

          {(session?.subjectsBySession?.length || session?.exams?.length) &&
            <>
              <DndProvider backend={HTML5Backend}>
                <ScrollingComponent className="px-4 d-flex flex-column justify-content-center mx-auto" style={{ maxWidth: 1248 }}>
                  <FormationSessionCardContainer />
                </ScrollingComponent>
   
              </DndProvider>
              {session?.exams.length === 1 &&
                 <div className="px-4 d-flex flex-column justify-content-center mx-auto" style={{ maxWidth: 1248 }}>
                    <SessionExamCardComponent t={t} key={session?.subjectsBySession?.length } index={session?.subjectsBySession?.length } data={session?.exams[0]} options={[]} />
                 </div>}
      
  
            </>}
        </div>
      </>
        : <>
          <div className="d-flex flex-column align-items-center justify-content-center px-4" style={{ marginTop: 160 }}>
            <h3 className="H3-Headline mb-2 text-center">{t('createContentText')}</h3>
            <div className=" mt-3 d-flex flex-row flex-wrap justify-content-center">
              <button onClick={() => { addTraining() }} className="mt-2 btn-Primary large icon-left mx-2">
                <span className="material-icons-outlined"> add </span>
                <div className="m-auto ps-2">{t('newContent')}</div>
              </button>
              <button className="mx-2 mt-2 btn-Primary large icon-left" onClick={async (e) => { await addNewTest() }}>
                <span className="material-icons-outlined"> add </span>
                <div className="m-auto ps-2">{t('newFinalExam')}</div>
              </button>
            </div>
          </div>
        </>}
    </>

  );
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      getTrainings,
      openModal,
      createTraining,
      addTest,
      clearHistoryArray, 
      redirect
    },
    dispatch
  );
const mapStateToProps = (state: AppState) => ({
  connectedUser: state.authReducer.connectedUser,
  training: state.trainingReducer.session,
  trainings: state.trainingReducer.trainings
});

export default connect(mapStateToProps, mapDispatchToProps)(ContentSessionFormComponent);