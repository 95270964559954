import { ComponentProps, FC, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../global-state/store/root-reducers';
import { closeModal, openModal } from '../../global-state/actions/modal-actions';
import { loginUser, registerStudent } from '../../global-state/actions/auth-actions';
import { returnStartPathUrl } from '../../helpers/domainCheck';
import { useHistory } from 'react-router-dom';
import { clearHistory, saveReturnPath } from '../../global-state/actions/breadcrumb-actions';
import axiosInstance from '../../helpers/interceptors';
import axios from 'axios';

interface StudentRegisterObject {
    email: string,
    lastName: string,
    firstName: string,
    password: string,
    birthDay: string,
    photo: string,
    gender: StudentGender,
    levelStudy: string,
    country: string,
    city: string,
    link: string
}
type StudentGender = "FEMALE" | "MALE"

const SignUpPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    let [firstName, setFirstName] = useState<any>("")
    let [lastName, setLastName] = useState<any>("");
    let [gender, setGender] = useState<any>(null);
    const [email, setEmail] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [showP, setShowP] = useState(false);
    const history = useHistory();
    const [trainingUrl, setTrainingUrl] = useState<string>("")
    const [verifFirstName, setVerifFirstName] = useState<boolean>(false)
    const [verifLastName, setVerifLastName] = useState<boolean>(false)
    const [verifEmail, setVerifEmail] = useState<boolean>(false)
    const [emailError, setEmailError] = useState<string>("")
    const [emailClass, setEmailClass] = useState<any>()
    const pattern = /^(([^<>()[\]\\.,;:*\s@"]+(\.[^<>()[\]\\.,;:*\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const regx = RegExp(pattern)
    const [registerBtn, setRegisterBtn] = useState<boolean>(false)
    const [buttonLoader, setButtonLoader] = useState<boolean>(false)
    const [confirmPassword, setConfirmPassword] = useState<string>("")
    const [showP1, setShowP1] = useState(false);
    const [verifConfirmPassword, setVerifConfirmPassword] = useState<boolean>(false)
    const [passwordError, setPasswordError] = useState("")
    const [verifPassword, setverifPassword] = useState<boolean>(false)


    useEffect(() => {
        verifyPassword()
    }, [password, confirmPassword])

    useEffect(() => {
        if (firstName.length !== 0) {
            verifyFirstName(firstName)
        }
    }, [firstName])

    useEffect(() => {
        if (lastName.length !== 0) {
            verifyLastName(lastName)
        }
    }, [lastName])


    const verifyPassword = () => {
        if (password?.length !== 0 && password?.length < 8) {
            setVerifConfirmPassword(true)
            setPasswordError("Votre mot de passe doit comporter au moins 8 caractères")
        } else if (password?.length !== 0 && confirmPassword?.length !== 0 && password !== confirmPassword) {
            setVerifConfirmPassword(true)
        }
        else {
            setPasswordError("")
            setVerifConfirmPassword(false)
        }
    }

    const verifyFirstName = (value: string) => {
        if (value?.length === 0) {
            setVerifFirstName(true)
        } else {
            setVerifFirstName(false)
        }
    }

    const verifyLastName = (value: string) => {
        if (value?.length === 0) {
            setVerifLastName(true)
        } else {
            setVerifLastName(false)
        }
    }

    const updateMail = (mail: string) => {
        setEmail(mail)
        verifyUserInDataBase()
    }

    useEffect(() => {
        if (password?.length !== 0 && confirmPassword?.length !== 0 && password !== confirmPassword) {
            setVerifConfirmPassword(true)
        } else {
            setVerifConfirmPassword(false)
        }
    }, [password, confirmPassword])


    const allowRegister = () => {
        let studentAccount: StudentRegisterObject = {
            email: email.toLowerCase(),
            lastName: lastName,
            firstName: firstName,
            password: password,
            birthDay: "",
            photo: "",
            gender: gender,
            levelStudy: "",
            country: "",
            city: "",
            link: window.location.href
        }
        if (validateUser(studentAccount)) {
            setRegisterBtn(true)
        } else {
            setRegisterBtn(false)
        }
    }

    useEffect(() => {
        allowRegister()
    }, [email, lastName, firstName, gender, password, confirmPassword])

    useEffect(() => {
        if (props?.returnPathPlateform?.path !== undefined) {
            setTrainingUrl(props?.returnPathPlateform?.path)
        } else {
            let historyLength = props?.history?.length - 1
            let retrnUrl = props?.history[historyLength]?.path
            setTrainingUrl(retrnUrl)
        }

    }, [props?.returnPathPlateform])

    useEffect(() => {
        if (!regx.test(email) && email?.length !== 0) {
            verifyUserInDataBase()
            setVerifEmail(true)
        } else {
            setVerifEmail(false)
        }
    }, [email])

    function isValidGender(toTest: any): toTest is StudentGender {
        return toTest === "FEMALE" || toTest === "MALE";
    }

    function validateUser(student: StudentRegisterObject): boolean {
        return !!(
            student.firstName &&
            student.lastName &&
            student.gender &&
            student.email &&
            confirmPassword &&
            typeof student.firstName === 'string' &&
            typeof student.lastName === 'string' &&
            typeof student.email === 'string' &&
            typeof confirmPassword === 'string' &&
            student.password === confirmPassword &&
            student.firstName.length !== 0 &&
            student.lastName.length !== 0 &&
            isValidGender(student.gender) &&
            verifyUserInDataBase()
        );
    }

    useEffect(() => {
        verifyUserInDataBase()
    }, [email])

    const verifyUserInDataBase = async () => {

        if (email?.length === 0) {
            setEmailClass("form-control input text-default")
            setEmailError("")
        }
        else if (email?.length !== 0 && regx.test(email)) {

            const [verifAbstract, verifMailDatabase] = await Promise.all([
                axios.get("https://emailvalidation.abstractapi.com/v1/?api_key=" + "5eebac32491d4d94b37d5fc991cbc269" + "&email=" + email.toLowerCase())
                    .then((data: any) => {
                        if (data.data.deliverability === "DELIVERABLE") {
                            return true;
                        } else if (data.data.deliverability === "UNDELIVERABLE") {
                            return false;
                        }
                    }),

                axiosInstance.get(process.env.REACT_APP_BASE_URL2 + "person/getpersonbyemail/" + email?.toLowerCase())
                    .then((emailRes: any) => {
                        if (emailRes.data === "Email not found") {
                            return true
                        } else {
                            return false
                        }
                    })
            ])

            if (verifAbstract === true && verifMailDatabase === true) {
                setEmailClass("form-control input text-default")
                setVerifEmail(false)
                return true
            } else if (verifAbstract === false && verifMailDatabase === false) {
                setEmailError("Adresse e-mail invalide")
                setEmailClass("form-control input text-default error")
                setVerifEmail(true)
                return false;
            }

            else if (verifAbstract === false || verifMailDatabase === false) {
                if (verifMailDatabase === false) {
                    setEmailError("Email existe déja")
                    setVerifEmail(true)
                    return false;
                } else if (verifAbstract === false) {
                    setEmailError("Adresse e-mail invalide")
                    setEmailClass("form-control input text-default error")
                    setVerifEmail(true)
                    return false;
                }
            }
        }
        else if (regx.test(email) === false) {
            setEmailError("Adresse e-mail invalide")
            setVerifEmail(true)
            setEmailClass("form-control input text-default error")
        }
    }

    useEffect(() => {
        if (!verifEmail) {
            setEmailClass("form-control input text-default error")

        } else {
            setEmailClass("form-control input text-default")
            setEmailError("")
        }
    }, [verifEmail])


    const addNewStudent = async () => {
        await setButtonLoader(true)
        let studentAccount: StudentRegisterObject = {
            email: email.toLowerCase(),
            lastName: lastName,
            firstName: firstName,
            password: password,
            birthDay: "",
            photo: "",
            gender: gender,
            levelStudy: "",
            country: "",
            city: "",
            link: window.location.href
        }

        if (validateUser(studentAccount)) {
            await props?.registerStudent(studentAccount)
            const offer_token: any = localStorage.getItem("access_limitation");
            await localStorage.clear();
            await localStorage.setItem("access_limitation", offer_token);
            await props?.loginUser(email.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""), password)

        } else {
            if (firstName.length === 0) {
                setVerifFirstName(true)
            }
            if (lastName.length === 0) {
                setVerifLastName(true)
            }
        }

        await setButtonLoader(false)
    }

    // useEffect(() => {
    //     if (firstName.length != 0) {
    //         setVerifFirstName(false)
    //     }
    //     if (lastName.length != 0) {
    //         setVerifLastName(false)
    //     }
    // }, [firstName, lastName])



    const returnToTraining = async () => {
        props?.clearHistory()
        props?.saveReturnPath({ path: `${props?.envUrl}/auth/sign-up` });
        history.push({ pathname: `${trainingUrl}`, state: { redirectPopup: true } })
    }

    useEffect(() => {
        if (props?.connectedUser &&
            localStorage.getItem('access_token') !== null
            && localStorage.getItem('access_token') !== undefined
            && localStorage.getItem('access_token') !== '') {
            returnToTraining()
        }
    }, [props?.connectedUser])


    const signIn = () => {
        history.push(`${props?.envUrl}/auth/sign-in`);
    }

    return (
        < div style={{ maxWidth: "464px", maxHeight: "888px" }}>

            <div className="modal-body  w-100" style={{ paddingTop: 0, overflow: 'hidden' }}>
                <div className="row body-modal-publish" >
                    <div className="d-flex flex-column align-items-center w-100 mb-4" >
                        <div className="H3-Headline black-800" style={{ paddingLeft: "40px" }}>Inscription </div>
                        <span className="body-md  black-800" style={{ paddingLeft: "40px" }}>Finalisez votre achat et profitez du </span>
                        <span className="body-md  black-800" style={{ paddingLeft: "40px" }}>cours en créant un compte</span>
                        <div className=" w-100" style={{ paddingTop: "22px", paddingLeft: "40px" }}>

                            <div className="d-flex flex-row w-100 align-item-center justify-content-center" style={{ gap: "26px" }}>
                                <div>
                                    <div className="d-flex align-item-center">
                                        <input dir="ltr" className="input-style-radio" type="radio" id="test1" value={gender} name="gender" onChange={() => setGender("FEMALE")} />
                                        <label htmlFor="test1"></label>
                                        <span className="form-title ps-1 ms-2">{t('madame')}</span>
                                    </div>
                                </div>
                                <div>
                                    <div className=" d-flex ">
                                        <input dir="ltr" className="input-style-radio" type="radio" id="test2" value={gender} name="gender" onChange={() => setGender("MALE")} />
                                        <label htmlFor="test2"></label>
                                        <span className="form-title ps-1 ms-2">{t('monsieur')}</span>
                                    </div>
                                </div>
                            </div>

                            <div className=" w-100" style={{ paddingTop: "16px" }} >
                                <span className='body-md bold'>Prénom</span>
                                <input
                                    dir="ltr"
                                    type="text"
                                    className={verifFirstName ? "form-control input text-default error" : "form-control input text-default"}
                                    placeholder={"Prénom"}
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                />
                                {verifFirstName === true && <span style={{ color: "#E06A6A" }} > Champ obligatoire</span>}

                            </div>
                            <div className="w-100" style={{ paddingTop: "16px" }}>
                                <label className='body-md bold'>Nom</label>
                                <input
                                    dir="ltr"
                                    type="text"
                                    className={verifLastName ? "form-control input text-default error" : "form-control input text-default"}
                                    placeholder={"Nom"}
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                />
                                {verifLastName === true && <span style={{ color: "#E06A6A" }} > Champ obligatoire</span>}
                            </div>
                            <div className="w-100" style={{ paddingTop: "16px" }}>
                                <label className='body-md bold'>E-mail</label>
                                <input dir="ltr" type="text" autoComplete="false" className={verifEmail ? "form-control input text-default error" : "form-control input text-default"} placeholder={"E-mail"} value={email} onChange={(event) => { updateMail(event.target.value.trim()) }} onBlur={() => verifyUserInDataBase()} />
                                {verifEmail === true && <span style={{ color: "#E06A6A" }} >{emailError}</span>}
                            </div>
                            <div className="w-100" style={{ paddingTop: "16px" }}>
                                <label className='body-md bold'>Mot de passe</label>
                                <div className="input-form-control">
                                    <input autoComplete="new-password" dir="ltr" type={showP ? "text" : "password"} className={verifPassword ? "form-control input text-default error" : "form-control input text-default"} placeholder={"Mot de passe"} value={password} onChange={(event) => { setPassword(event.target.value) }} />
                                    {!showP && password === '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#B1B1B1' }} onClick={() => setShowP(!showP)}>visibility_off</span>}
                                    {showP && password === '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#B1B1B1' }} onClick={() => setShowP(!showP)}>visibility</span>}
                                    {!showP && password !== '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#272727' }} onClick={() => setShowP(!showP)}>visibility_off</span>}
                                    {showP && password !== '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#272727' }} onClick={() => setShowP(!showP)}>visibility</span>}
                                </div>

                                {passwordError?.length !== 0 && <span style={{ color: "#E06A6A" }} >{passwordError}</span>}
                            </div>

                            <div className="w-100" style={{ paddingTop: "16px" }}>
                                <label className='body-md bold'>Confirmer votre mot de passe</label>
                                <div className="input-form-control">
                                    <input autoComplete="new-password" dir="ltr" type={showP1 ? "text" : "password"} className={verifConfirmPassword ? "form-control input text-default error" : "form-control input text-default"} placeholder={"Mot de passe"} value={confirmPassword} onChange={(event) => { setConfirmPassword(event.target.value) }} />
                                    {!showP1 && confirmPassword === '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#B1B1B1' }} onClick={() => setShowP1(!showP1)}>visibility_off</span>}
                                    {showP1 && confirmPassword === '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#B1B1B1' }} onClick={() => setShowP1(!showP1)}>visibility</span>}
                                    {!showP1 && confirmPassword !== '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#272727' }} onClick={() => setShowP1(!showP1)}>visibility_off</span>}
                                    {showP1 && confirmPassword !== '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#272727' }} onClick={() => setShowP1(!showP1)}>visibility</span>}

                                </div>
                                {verifConfirmPassword === true && <span style={{ color: "#E06A6A" }} >Les mots de passes ne correspondent pas.</span>}

                            </div>

                            <div className="d-flex flex-column align-items-center" style={{ paddingTop: "32px" }}>
                                {buttonLoader === false ? <button type="button" className="btn-Primary large" onClick={(event) => { event.preventDefault(); addNewStudent() }} >
                                    Valider
                                </button> :

                                    <button className="btn-Primary loaddata large">
                                        <div className="threedots-large-Animation">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </div>
                                    </button>}

                            </div>
                            <div className="d-flex  flex-column align-items-center" style={{ paddingTop: "16px" }}>
                                <div className="text-center" >
                                    <span className="body-md bold black-800" >Vous avez déjà un compte ? </span>
                                    <button className="btn-text forgot-password" onClick={() => signIn()}>Me connecter</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ div>
    )
}
const mapStateToProps = (state: AppState) => ({
    content: state.modalReducer.modalContent,
    connectedUser: state.authReducer.connectedUser,
    courseInfo: state.courseReducer.courseInfo,
    formPaymentInfo: state.courseReducer.formPaymentInfo,
    paymentSchoolInfo: state.schoolInfoReducer.paymentSchoolInfo,
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    training: state.trainingReducer.path,
    trainingSession: state.trainingReducer.session,
    userInfo: state.authReducer.connectedUserInfo,
    courseTarificationForm: state.courseReducer.courseTarificationForm,
    actualOffer: state.courseReducer.actualOffer,
    lengthCourses: state.courseReducer.LengthCoursesPublished,
    returnPathPlateform: state.historyReducer.returnPathPlateform,
    history: state.historyReducer.history,
    envUrl: state.historyReducer.envUrl,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            loginUser,
            closeModal,
            openModal,
            registerStudent,
            clearHistory,
            saveReturnPath
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(SignUpPage);