import { ComponentProps, FC, useEffect, useMemo } from "react";
import { useParams } from "react-router";
import { connect } from 'react-redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import PrivateRoute from "../../../navigations/PrivateRoute";
import RouteWithBreadCrumb from "../../../navigations/RouteWithBreadCrumb";
import { getTrainingById } from '../../../global-state/actions/training-actions';
import NavbarSessionComponent from "../../../components/navigation-components/navbar-session-component";
import { useLocation } from 'react-router-dom';
import DescriptionSessionPage from "./description-session-page";
import AdmissionSessionPage from "./admission-session-page";
import ContentSessionPage from "./content-session-page";
import StudentsSessionPage from "./students-session-page";
import CustomSwitch from "../../../helpers/custom-switch";
import { getContent } from "../../../global-state/actions/course-actions";
import ExamPreviewContent from "../path-details/exam-preview-content";
import TarificationSessionPage from "./tarification-session-page"
import previewSessionPage from "../../catalog/preview-session-page";
const SessionDetailsContainer: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
  const { id } = useParams<any>();
  const location = useLocation()

  useEffect(() => {
    props?.getTrainingById('sessions', id);
    let filtre = {
      trainingId: id,
      trainingType: "SESSION",
      entityType: "SCHOOL",
      entityId: props?.connectedUser?.idSchool
    }
    props?.getContent(filtre);
  }, []);
  const activeTab: string = useMemo(() => {
    return (location.pathname.split("/").pop() as string);
  }, [location?.pathname])
  return (<>

    <NavbarSessionComponent activeTab={activeTab} type={'session'} />
    <CustomSwitch>
      {/* <PrivateRoute exact={true} path={`${props?.match.path}/description`} component={DescriptionSessionPage} /> */}
      <PrivateRoute exact={true} path={`${props?.match.path}/description`} component={previewSessionPage} />
      <PrivateRoute exact={true} path={`${props?.match.path}/content`} component={ContentSessionPage} />
      <PrivateRoute exact={true} path={`${props?.match.path}/inscription`} component={AdmissionSessionPage} />
      <PrivateRoute exact={true} path={`${props?.match.path}/students`} component={StudentsSessionPage} />
      <PrivateRoute exact={true} path={`${props?.match.path}/tarification`} component={TarificationSessionPage} />
      <RouteWithBreadCrumb exact={true} path={`${props?.match.path}/examPreview`} component={ExamPreviewContent} />

    </CustomSwitch>
  </>
  )
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ getTrainingById, getContent }, dispatch);
const mapStateToProps = (state: AppState, ownProps: any) => ({
  connectedUser: state.authReducer.connectedUser,
  courseForm: state.courseReducer.courseInfo,
  listHistory: state.historyReducer.listHistoryBreadCrumb,
  history: ownProps?.history,
  chapterForm: state.courseReducer.chapterForm,
  indexChapter: state.courseReducer.indexChapter,
  typeContent: state.courseReducer.typeContentCourse,
});
export default connect(mapStateToProps, mapDispatchToProps)(SessionDetailsContainer);