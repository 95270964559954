import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { AppState } from '../../global-state/store/root-reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { deleteTraining, clearTraining, handleErrors, createTraining } from '../../global-state/actions/training-actions';
import { ModalIDS } from '../modals/modal-ids';
import { openModal } from '../../global-state/actions/modal-actions';
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
import { returnStartPathUrl } from '../../helpers/domainCheck';
import { getOfferPricingCourse } from '../../global-state/actions/course-actions';

const labels = [
    { status: "DRAFT", label: "En cours d'édition" },
    { status: "PENDING", label: "En attente" },
    { status: "PUBLISHED", label: "En ligne" },
]
const statusList = [
    { value: "Published", color: "success" },
    { value: "Draft", color: "primary" },
    { value: "Pending", color: "warning" },
    { value: "Refused", color: "danger" },
    { value: "Deactivate", color: "secondary" },
    { value: "Archive", color: "#B1B1B1" },
    { value: "RequestDeactivation", color: "#F5B95F" },
    { value: "RequestActivation", color: "#F5B95F" },
    { value: "RequestDeletion", color: "#F5B95F" },

]
const NavbarSessionComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>> = (props) => {
    const history = useHistory();
    const { t } = useTranslation()
    const [status, setStatus] = useState<any>();
    const [training, setTraining] = useState<any>();
    const location = useLocation();
    const [idOffer, setIdOffer] = useState<any>();


    useEffect(() => {

        setTraining(props?.training);
        const stat = statusList.find(obj => obj?.value.toUpperCase() === props?.training?.status);
        if (stat) {
            setStatus(stat);
        } else {
            setStatus('');
        }

    }, [props?.training])
    const goBackToList = () => {
        if (props?.connectedUser?.role === 'role_director') {
            history.push(`${returnStartPathUrl()}/all-courses`);
        } else if (props?.connectedUser?.role === "role_adminstrator") {
            history.push(`${returnStartPathUrl()}/all-courses/assistant`);
        } else {
            history.push(`${returnStartPathUrl()}/courses/my-courses`);
        }
    }
    const goToSession = (where: string) => {
        if (props?.connectedUser?.role === 'role_director') {
            history.push(`${returnStartPathUrl()}/all-courses/session/${props?.training?.id}/${where}`);
        } else {
            history.push(`${returnStartPathUrl()}/courses/create-formation/session/${where}`);
        }
    }
    const isEmpty = (val: any) => {
        return (val === undefined || val == null || val.length <= 0) ? true : false;
    }
    const publicate = async () => {

        props?.createTraining("sessions", training)

        let errors: any = {};
        if (isEmpty(props?.training?.subjectsBySession)) {
            errors.content = t("errorContentLength")
        }
        if (isEmpty(props?.training?.linkImg)) {
            errors.linkImgError = t("errorSessionLinkImg")
        }
        // if (isEmpty(props?.training?.objectives) || isEmpty(props?.training?.resume) || isEmpty(props?.training?.prerequisite) || isEmpty(props?.training?.skills)) {
        if (isEmpty(props?.training?.resume)) {
            errors.descriptionError = t("errorSessionDescription")
        }
        if (props?.training?.title === '' || props?.training?.title === null){
            errors.titleTrainingError = t('emptySessionTitleError')
        }
        if (Object.keys(errors).length === 0) {
            await props?.openModal({ idModal: ModalIDS.PUBLISH_TRAINING, size: "md" });

        }
        else {
            await props?.handleErrors("SESSION", errors)
            await props?.openModal({ idModal: ModalIDS.CHECK_FORM_TRAINING_CONDITIONS, size: "md" })
        }
    }
    const deleteTrainingContent = async () => {
        await props?.deleteTraining("SESSION", props?.training?.id)
         history.push(`${returnStartPathUrl()}/courses/my-courses`)
    }
    const openModalFn = async (value: string) => {
        switch (value) {
            case 'DEACTIVATE':
                props?.openModal({ idModal: ModalIDS.DEACTIVATE_TRAINING, size: 'md', data: { type: 'session' } });
                break;
            case 'PUBLISHED':
                props?.openModal({ idModal: ModalIDS.REACTIVATE_TRAINING, size: 'md', data: { type: 'session' } });
                break;
            case 'REFUSED':
                props?.openModal({ idModal: ModalIDS.REFUSE_TRAINING, size: 'md', data: { type: 'session' } });
                break;
            case 'ACCEPTED':
                props.getOfferPricingCourse(idOffer)
                if (props?.courseTarificationForm?.pricings.length == 0 )
                {    
                    props?.openModal({ idModal: ModalIDS.CHECK_FORM_TARIFICATION_CONDITIONS, size: 'md', data: { type: 'session' } });
                }else {
                    props?.openModal({ idModal: ModalIDS.ACCEPT_TRAINING, size: 'md', data: { type: 'session' } });
                }
                break;
        }
    }
    const handleTransfer=async()=>{
        await   props?.openModal({ idModal: ModalIDS.TRANSFER_TRAINING, size: "md", data: { type: 'course' } });
       }
       const handleTransferDirector=async()=>{
        await   props?.openModal({ idModal: ModalIDS.TRANSFER_DIRECTOR_TRAINING, size: "md", data: { type: 'course' ,id:training?.id} });
       }  
       useEffect(() => {
        if (props.actualOffer?.id !== undefined && props.actualOffer?.id !== null) {
            setIdOffer(props.actualOffer?.id)
        }
      }, [props.actualOffer]);
    return (
        <>
            <div className="cours-nav" >
                <div className="navbar navbar-expand-lg box-shadow-navbar-bredcrumb d-flex justify-content-between p-0 flex-nowrap">
                    <div className="d-flex order-0">
                        <a onClick={() => { goBackToList() }} style={{ cursor: 'pointer' }} className="d-flex justify-content-center align-items-center nav-back">
                            <span className="material-icons-round">
                                chevron_left
                            </span>
                        </a>
                        <div className="d-flex px-4 align-items-center">
                            {(training?.status === 'PUBLISHED' || training?.status === 'DEACTIVATE') && <a style={{ cursor: 'pointer' }} onClick={() => goToSession('students')} className="d-flex justify-content-center align-items-center mx-1">
                                <span className={`nav-btn-black${(location.pathname.includes('/students')) ? '-active' : ''}`}>
                                    {t('Etudiants')}
                                </span>
                            </a>}
                            <a onClick={() => goToSession('description')} className="d-flex justify-content-center align-items-center mx-1">
                                <span className={props?.activeTab === "description" ? "nav-btn-black-active" : "nav-btn-black"}>
                                    {t('descriptionTitle')}
                                </span>
                            </a>
                            <a onClick={() => goToSession('content')} className="d-flex justify-content-center align-items-center mx-1">
                                <span className={props?.activeTab === "content" ? "nav-btn-black-active" : "nav-btn-black"}>
                                    {t('content').charAt(0).toUpperCase()+t('content').slice(1).toLowerCase()}
                                </span>
                            </a>
                            {(training?.status === 'PENDING' || training?.status === 'REFUSED') && <a style={{ cursor: 'pointer' }} onClick={() => goToSession('students')} className="d-flex justify-content-center align-items-center mx-1">
                                <span className={`nav-btn-black${(location.pathname.includes('/students')) ? '-active' : ''}`}>
                                    {t('Etudiants')}
                                </span>
                            </a>}
                            <a onClick={() => goToSession('inscription')} className="d-flex justify-content-center align-items-center mx-1">
                                <span className={props?.activeTab === "inscription" ? "nav-btn-black-active" : "nav-btn-black"}>
                                    {t('inscriptionTitle')}
                                </span>
                            </a>
                            {props?.connectedUser?.role === "role_director" &&
                            <a style={{ cursor: 'pointer' }} onClick={() => goToSession('tarification')} className="d-flex justify-content-center align-items-center mx-1">
                                <span className={`nav-btn-black${(location.pathname.includes('/tarification')) ? '-active' : ''}`}>
                                    Tarification
                                </span>
                            </a>}
                        </div>
                    </div>
                    {(props?.connectedUser?.role === 'role_director') ?
                        <div className="d-flex justify-content-end order-1 w-100">
                        
                        { (training?.idProf != 0 && training?.idProf != null) &&    <a style={{ cursor: 'pointer' }} onClick={() => handleTransferDirector()} className="d-flex justify-content-center align-items-center px-4 nav secondary-action">
                                <span>
                                    Transférer
                                </span>
                            </a>}
                            {(training?.status === 'PUBLISHED' || training?.status === 'DEACTIVATE') &&
                                <div style={{ cursor: 'pointer', border: 'none' }} className={`d-flex justify-content-center align-items-center nav-state-${(training?.status === 'PUBLISHED') ? 'enligne' : 'horsligne'}-prof`}>
                                    <input dir="ltr"readOnly={true} checked={training?.status === 'PUBLISHED'} className="input-style" onClick={() => openModalFn((training?.status === 'PUBLISHED') ? 'DEACTIVATE' : 'PUBLISHED')} type="checkbox" id="toggleBtn" />
                                    <div className="toggle-container" onClick={() => openModalFn((training?.status === 'PUBLISHED') ? 'DEACTIVATE' : 'PUBLISHED')}>
                                        <label className="label-style" htmlFor="toggleBtn">
                                            <span className="on">{t('online')}</span>
                                            <span className="off">{t('offline')} </span>
                                            <input dir="ltr"type="text" style={{ display: 'none' }} />
                                        </label>
                                    </div>
                                </div>
                            }
                            {(training?.status === 'PENDING') &&
                                <>
                            
                                    <button onClick={() => openModalFn('REFUSED')} style={{ cursor: 'pointer', border: 'none' }} className="d-flex justify-content-center align-items-center nav refuse">
                                        <span>{t('refuser')}</span>
                                    </button>
                                    <button onClick={() => openModalFn('ACCEPTED')} style={{ cursor: 'pointer', border: 'none' }} className="d-flex justify-content-center align-items-center nav publish">
                                        <span>{t('valider')}</span>
                                    </button>
                                </>
                            }
                            {(training?.status === 'REFUSED')
                                &&
                                <div style={{ border: 'none', textTransform: 'capitalize' }} className="d-flex justify-content-center align-items-center nav-state-refused">
                                    <span>{t('refuser_1').toLowerCase()}</span>
                                </div>
                            }
                        </div>
                        :
                        <div className="d-flex justify-content-end order-1 w-100">
                            <div className="d-flex justify-content-center align-items-center px-4">
                                <span className={`badge-text ${status?.color}`}>
                                    {t(status?.value)}
                                </span>
                            </div>
                            {(training?.status === 'DRAFT') && <button style={{ backgroundColor: "transparent", border: "none" }} onClick={() => { deleteTrainingContent() }} className="d-flex justify-content-center align-items-center px-4 nav secondary-action">
                                <span>
                                    {t('delete')}
                                </span>
                            </button>}
                            <button style={{ border: "none" }}  disabled={(training?.status !== 'DRAFT')}onClick={() => { publicate() }} className={`d-flex justify-content-center align-items-center nav publish ${(training?.status !== 'DRAFT') ? 'disabled' : ''}`}>
                                <span>
                                    {t('publishMyReview')}
                                </span>
                            </button>
                            {/* <a href="/" className="d-flex justify-content-center align-items-center nav publish disabled">
          <span>
            publier
          </span>
        </a> */}
                        </div>}
                </div>
            </div>

        </>
    );
}

const mapStateToProps = (state: AppState, ownProps:any) => ({
    connectedUser: state.authReducer.connectedUser,
    loggedIn: state.authReducer.loggedIn,
    historySession: state.historyReducer.listHistoryBreadCrumb,
    historySessionProfesseurs: state.historyReducer.listHistoryProfesseur,
    lastSession: state.historyReducer.lastPathBeforeBreadCrumb,
    training: state.trainingReducer.session,
    activeTab:ownProps?.activeTab,
    actualOffer: state.courseReducer.actualOffer,
    courseTarificationForm: state.courseReducer.courseTarificationForm,

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            deleteTraining,
            getOfferPricingCourse,

            clearTraining,
            openModal,
            handleErrors,
            createTraining
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(NavbarSessionComponent);