import { ComponentProps, FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { getCountStudent } from "../../global-state/actions/training-actions";
import { AppState } from "../../global-state/store/root-reducers";
import EmptyListElement from "../empty-list/normal-empty-list-element";
import RowExpandElementStudentTable from "./row-expanded-element-student-table";

interface Props {
  data: any
  doAction?: any
  height?: number | string
  size?: number
  trainingOffers:any
}
const StudentTableWithExpandElement: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
  const [open, setOpen] = useState<any>([]);
  const [index, setIndex] = useState(-1);
  let data = useMemo(() => props?.data, [props?.data])
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const trainingOffers = useMemo(() => props?.trainingOffers, [props?.trainingOffers])

  useEffect(() => {
    setLoading(props?.loading)
  }, [props?.loading]);


  const doAction = (e: any, actionName: string, row: any, index: number) => {
    if ((actionName == "openexpand")) {
      let updatedArray: any = [...open]; // Create a copy of the array
      updatedArray[index] = !updatedArray[index]; // Update the value at the specified index
      setOpen(updatedArray);
      e.stopPropagation();
    } else if (actionName !== '') {
      e.stopPropagation();
      const object = {
        row: row,
        action: actionName,
        index: index
      }
      props?.doAction(object);
    }
  }

  useEffect(() => {
    for (let index = 0; index < data?.body?.length; index++) {
      open[index] = false;
    }
  }, [data]);




  

  return (
    <>
      <div className="my-3" >
        <div className="table-responsive d-flex mx-auto" style={{ maxWidth: 1200 }}>
          <table className="w-100 custom-table">
            <thead className="mb-4">
              <tr>
                {data?.headers?.map((header: any) =>
                (<th key={header.title} scope={header.scope} className={header.classNames}>
                  <button className={header.buttonClasses}>
                    {t(header.title)}
                    {/* {header.sorted && <div className="d-flex flex-column">
                      <span className="material-icons-outlined trie-arrow up" >arrow_drop_up</span>
                      <span className="material-icons-outlined trie-arrow down" >arrow_drop_down</span>
                    </div>} */}
                  </button>
                </th>)
                )}
              </tr>
            </thead>
            <tbody className="w-100" style={{ height: 30, overflow: 'scroll' }}>
              {data?.body?.map((data: any, index: number) =>
              (
                <><tr style={{ cursor: 'pointer' }} onClick={(e) => doAction(e, data.row.actionName, data.row, index)} className={data.row.classNames}>
                  {data.row.columns.map((column: any, index1: number) => (
                    <td
                      key={index1 + 'column'}
                      onClick={(e) => doAction(e, column.actionName, data.row, index)}
                      className={column.classNames}
                      style={{ position: 'relative' }}
                      colSpan={column.colspan}
                    >
                      {(column.content != "Gratuit") ?
                        <div className="d-flex flex-row" style={{ gap: "12px" }}>
                          {column.content}
                          {(index1 === 6) && <div className="d-flex flex-row justify-content-end align-items-center black-800" onClick={(e) => { doAction(e, "openexpand", data.row, index) }}>
                            {open[index] ? <>
                              <span className="material-icons-outlined">expand_less</span>
                            </> :
                              <> <span className="material-icons-outlined">expand_more</span></>}
                          </div>}
                        </div> :
                        <div className="d-flex align-text-center">{column.content}</div>
                      }

                    </td>
                  )

                  )}
                </tr>
                  {open[index] ? <>
                    <RowExpandElementStudentTable data={data?.row?.columns} student={data?.row?.student}  trainingOffersList={trainingOffers} />
                  </> :
                    <>
                    </>}

                </>
              )
              )}
            </tbody>
          </table>
        </div>
        {data?.body?.length === 0 && (
          <div className="w-100 mt-7">
            <div className="msg-formation-list-empty">
              <EmptyListElement lines={[t('emptyListFormation1'), t('emptyListFormation2')]} />
            </div>
          </div>
        )}
      </div>
    </>
  );

}
const mapStateToProps = (state: AppState) => ({
  countStudent: state.trainingReducer.countStudent,
  loading: state.trainingReducer.loadingcountStudent,
  LengthCourses: state.courseReducer.LengthCourses,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    { getCountStudent }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentTableWithExpandElement);

