export const generateClassNamesSpotlight = async(
    isIncrement: number | null, 
    isMailChecked: boolean, 
    roleUser: string,
    notifications: any
) => {
    let reactJoyridePortalID = document.getElementById('react-joyride-portal')?.firstElementChild;
    let childReactJoyridePortalID: any = reactJoyridePortalID?.firstElementChild;

    // 68px height of mail checked
    // 48px height of notification item of list
    let totalTopSpotlight = 28
    if(isMailChecked === false && roleUser === 'role_director') totalTopSpotlight += 68;
    if(notifications.length > 0) totalTopSpotlight += 48 * notifications.length;

    let root: any = document.querySelector(':root');
    // let gcs = getComputedStyle(root);

    root.style.setProperty('--custom-totalTopSpotlight', `${totalTopSpotlight}px`);

    if(childReactJoyridePortalID){
        let totalTopMenuParam = 12;
        if(isMailChecked === true) totalTopMenuParam += 68; 
        if(notifications.length > 0) totalTopMenuParam += 48 * notifications.length;
        
        let root: any = document.querySelector(':root');
        root.style.setProperty('--custom-totalTopMenuParam', `${totalTopMenuParam}px`);

        return childReactJoyridePortalID?.classList.add(`react-joyride__spotlight--update-step-${isIncrement}`);
    }
}

export const generateClassNamesFloaterOpen = (isIncrement: number | null, isMailChecked: boolean, roleUser: string, notifications: any) => {
    let totalTopStep7 = -190;
    if(isMailChecked === true && roleUser === 'role_director') totalTopStep7 += -68; 
    if(notifications.length > 0) totalTopStep7 += -48 * notifications.length;
    
    let root: any = document.querySelector(':root');
    root.style.setProperty('--custom-totalTopStep7', `${totalTopStep7}px`);

    let selectMyFirstElementChild = document.getElementById(`react-joyride-step-${isIncrement}`)?.firstElementChild;
    const mailChecked = isMailChecked ? isMailChecked : false
    selectMyFirstElementChild?.classList.add(`__floater__open--update-${roleUser === 'role_prof' ? 'true' : mailChecked}`);
}

