export function getCodeFromUrl() {
  const parts = window.location.href.split("//")[1].split("/");
  const hostOrSubdomain = parts[0];
  const isLocalhost = hostOrSubdomain === "localhost:3000"
  const isAppDomain = hostOrSubdomain === `app${process.env.REACT_APP_BASE_SUBDOMAIN}`;
  let code;
  if (isLocalhost) {
    code = "lorem";
    //comment
  } else if (isAppDomain) {
    code = parts[1]
  } else {
    const domainParts = hostOrSubdomain.split(".");
    code = domainParts.length > 1 ? domainParts[0] : "";
  }
  return code
}

export function getCodeFromUrlWithParams(url: string) {
  const parts = url.split("//")[1].split("/");
  const hostOrSubdomain = parts[0];
  const isLocalhost = hostOrSubdomain === "localhost:3000"
  const isAppDomain = hostOrSubdomain === `app${process.env.REACT_APP_BASE_SUBDOMAIN}`;
  let code;
  if (isLocalhost) {
    code = "lorem";
  } else if (isAppDomain) {
    code = parts[1]
  } else {
    const domainParts = hostOrSubdomain.split(".");
    code = domainParts.length > 1 ? domainParts[0] : "";
  }
  return code
}


export function getDomainFromUrl() {
  const parts = window.location.href.split("//")[1].split("/");
  const hostOrSubdomain = parts[0];
  const isLocalhost = hostOrSubdomain === "localhost:3000"
  const isAppDomain = hostOrSubdomain === `app${process.env.REACT_APP_BASE_SUBDOMAIN}`;
  let code;
  if (isLocalhost) {
    code = "http://localhost:3000";
  } else if (isAppDomain) {
    code = parts[0] + "/" + parts[1]
  } else {
    code = parts[0]
  }
  return code
}

export function checkIsAppOrSubDoamin() {
  const domain = window.location.href.split("//")[1].split("/")[0];
  return domain === `app${process.env.REACT_APP_BASE_SUBDOMAIN}`;

}

export function returnStartPathUrl() {
  const domain = window.location.href.split("//")[1].split("/");
  if (domain[0] === `app${process.env.REACT_APP_BASE_SUBDOMAIN}`) {
    return `/${domain[1]}`
  } else {
    return '';
  };
}

export function returnDynamicValueFromUrl(index: number) {
  const domain = window.location.href.split("//")[1].split("/");
  if (domain[0] === `app${process.env.REACT_APP_BASE_SUBDOMAIN}`) {
    index = index + 1
  }
  return window.location.pathname.split("/")[index]
}