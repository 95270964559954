export const TRANSLATIONS_NL = {
    addCourse: "NIEUWE CURSUS",
    createCourse: "Maak een cursus",
    myCourses: "Mijn vakken",
    Published: "Online",
    Draft: "ongeorganiseerd",
    Pending: "In afwachting van goedkeuring door de directeur",
    RequestDeactivation: "In afwachting van deactivering door directeur",
    RequestActivation: "In afwachting van activering door directeur",
    RequestDeletion: "In afwachting van archivering door directeur",
    certified: "afgestudeerden",
    candidats: "KANDIDATEN",
    candidatsCapitalise: "Kandidaten",
    courseStatus_1: "De cursus blijft",
    courseStatus_2: "privé tot publicatie",
    Pending_2: "Wachten op validatie",
    RequestDeletion_2: "Wachten op archivering",
    RequestDeactivation_2: "Wachten op deactivering",
    RequestActivation_2: "wachten op activatie",
    actvity: "WERKZAAMHEID",
    students: "studenten",
    students_2: "Leerling",
    description: "OMSCHRIJVING",
    archive: "ARCHIEF",
    publish: "PUBLICEREN",
    inscription: "INSCHRIJVING",
    inscriptionTitle: "Opschrift",
    certifiedTitle: "afgestudeerd op",
    lastVisitProfile: "Laatste bezoek geleden",
    lastVisitProfile_1: "laatste bezoek",
    lastVisitProfile_2: "ik wil",
    descProf: "Korte beschrijving van de docent die in de presentatie van de cursus zal verschijnen",
    content: "INHOUD",
    titleCoursePlaceholder: "Vul hier de cursustitel in...",
    titleCourseForm: "Cursustitel",
    modalPublishCourse_1: "Ter goedkeuring naar directeur sturen",
    modalPublishCourse_1_1: "Valideer het bericht",
    modalRefusedCourse_1: "Publicatie weigeren",
    modalPublishCourse_2: "Voeg een opmerking toe voor de regisseur",
    modalPublishCourse_2_2: "Voeg een opmerking toe voor de leraar",
    modalPublishCourse_3: "commentator...",
    modalPublishCourse_4: "CURSUS VERZENDEN",
    modalPublishCourse_4_4: "CURSUS PUBLICEREN",
    modalRefusedCourse_4: "DE CURSUS WEIGEREN",
    modalPublishCourseSuccess: "De cursus is ter validatie naar de directeur gestuurd.",
    modalPublishPathSuccess: "De cursus is ter validatie naar de directeur gestuurd.",
    modalPublishSessionSuccess: "De sessie is ter validatie naar de directeur gestuurd.",
    modalPublishCourseSuccess_1: "De cursus wordt gepubliceerd. Het is nu online beschikbaar.",
    modalDeactivateCours: "De cursus is uitgeschakeld. Het is niet meer online beschikbaar.",
    modalRefusedCourseSuccess: "U heeft zojuist de publicatie van de cursus geweigerd. Je weigering is naar de docent gestuurd.",
    titleFormCourse_1: "Videopresentatie van de cursus",
    titleFormCourse_2: "Visueel om de cursus te illustreren",
    titleFormCourse_3: "Tekstpresentatie van de cursus",
    resume: "Inleidende samenvatting",
    textAreaPlaceholder: "Voeg hier tekst in...",
    placeholderInscriptionInput: "Voeg hier tekst in",
    prerequisite: "Vereisten om deze cursus te volgen",
    objectives: "educatieve doelen",
    skills: "Kennis en vaardigheden verworven tijdens deze cursus",
    skillsPlaceholder: "Voer een trefwoord in...",
    uploadVideo_1: "Versleep de cursusvideo die je wilt uploaden (mp4, mov)",
    uploadVideo_2: "SELECTEER EEN VIDEOBESTAND",
    uploadImage_1: "Sleep een foto om de cursus te illustreren (jpg, png)",
    uploadImage_2: "KIES EEN FOTO",
    downloadVideo: "Downloaden",
    awaitVideo: "In de wachtrij",
    whiletranscode: "Momenteel aan het bekijken",
    transcodingVideo: "Videoverwerking",
    breadCrumbChapter: "Hoofdstuk",
    chapitre: "Hoofdstuk",
    newTest: "NIEUWE TEST",
    newChapter: "NIEUW HOOFDSTUK",
    selectLevelCourse: "Niveau",
    selectLevelCourse_1: "Gevorderde",
    selectLevelCourse_2: "Tussenliggend",
    selectLevelCourse_3: "Bevestigd",
    listChaptersContent: "Om te beginnen, specificeert u het cursusniveau en maakt u uw eerste hoofdstuk.",
    chapterSelectFomCHapter: "HOOFDSTUK",
    saveChapter: "OPNEMEN",
    durationChapter: "Looptijd",
    placeholderTitleChapter: "Vul hier de titel van het hoofdstuk in...",
    titleFormChapter_1: "Hoofdstuk Video",
    titleFormChapter_2: "Bestanden om te downloaden",
    titleFormChapter_3: "Hoofdstuk tekst",
    textFileUpload_1: "Bestanden voor het uploaden van hoofdstukken slepen en neerzetten",
    textFileUpload_2: "(audio, PDF, PowerPoint, Word, Excel...)",
    fileUploadButton: "Selecteer bestand",
    uploadVideoChpter_1: "Versleep de video van het hoofdstuk dat je wilt uploaden (mp4, mov)",
    tests: "Testen",
    test: "Testen",
    resultat: "Resultaten",
    reponseInscrit: "Abonnementsverzoek",
    reponseAv: "Reageer eerder",
    refusedAt: "Geweigerd aan",
    Refused: "Weigeren",
    Unpaid: "Betalen",
    seeDemand: "Zie mijn verzoek",
    certifButton: "CERTIFICAAT",
    courseTable: "Vorming",
    DATEACHAT: "Aankoopdatum",
    exportListe: "EXPORT LIJST",
    filterProfessors: "Docent",
    statusFilter: "Toestand",
    search: "Zoeken",
    Tous: "Allemaal",
    Brouillon: "ongeorganiseerd",
    Avalider: "Valideren",
    Enligne: "Online",
    Archive: "Archief",
    Refuse: "Weigeren",
    Cours: "Cursus",
    COURSE: "Cursus",
    Professeurs: "Leraren",
    Demandes: "Verzoeken",
    filterCourse: "Alle cursussen",
    Certifies: "Gecertificeerd",
    Statut: "Toestand",
    PresProf: "Presentatie van de professor",
    dernVisite: "laatste bezoek",
    Enattente: "In afwachting",
    Admis: "geaccepteerd",
    Suspendu: "Geschorst",
    inviteProf: "NODIG EEN LERAAR UIT",
    inviteProf_2: "Nodig een leraar uit om te registreren",
    send: "sturen",
    inviteSuccess: "Je uitnodiging is naar de docent gestuurd.",
    myAccount: "mijn account",
    saveImg: "afbeelding opslaan",
    Profile: "Profiel",
    Nom: "Naam",
    NomObligatoire: "Naam*",
    PrenomObligatoire: "Voornaam*",
    Prenom: "Voornaam",
    country: "land van verblijf",
    Tunisie: "Tunesië",
    France: "Frankrijk",
    city: "woonplaats",
    birthdate: "Geboortedatum",
    birthdate_input: "Geboortedatum (mm/dd/jjjj)",
    NivScolaire: "School niveau",
    Primaire: "primair",
    College: "Middelbare school",
    Lycee: "Middelbare school",
    Bac: "Bac",
    Technicien2: "Technicus (Bac +2)",
    License: "Licentie (Bac+3)",
    Master: "Master of gelijkwaardig (BAC +4 of + 5)",
    Ingenieur: "Ingenieur",
    Doctorat: "doctoraat",
    emailAdd: "E-mailadres",
    updatePass: "Verander mijn wachtwoord",
    password: "Wachtwoord",
    actualPass: "huidig wachtwoord",
    newPass: "nieuw paswoord",
    enterPassword: "Minimaal 8 tekens",
    msgErrorPassword: "Voer a.u.b. minimaal 8 cijfers/letters in",
    msgErrorConfirmPassword: "Voer hetzelfde wachtwoord in",
    confimrNewPass: "Wachtwoordbevestiging",
    msgErrorMail: "Gelieve een geldig e-mailadres in te geven",
    confimrNewEmail: "Nieuw email adres",
    validPassButton: "Valideer mijn nieuwe wachtwoord",
    MainSchoolColor: "Kleur van de hoofdschool",
    AddYourColorToPersonalizeThePresentationOfTheCourses: "Voeg uw kleur toe om de presentatie van de cursussen te personaliseren",
    imgCrop_1: "Een foto slepen en neerzetten",
    imgCrop_3: "Sleep uw logo en zet het neer",
    imgCrop_2: "KIES EEN FOTO",
    pathBy: "Cursus gegeven door",
    sessionBy: "Sessie gegeven door",
    catalogCourses: "Cursus catalogus",
    Etudiants: "studenten",
    Ecole: "School",
    Catalogue: "Catalogus",
    Activite: "Werkzaamheid",
    lecole: "School",
    howItWorks: "Hoe het werkt",
    hello: "Welkom !",
    cnxButton: "Log in",
    createAccountMsg: "Maak mijn account",
    questionCreateAccount: "Heb je nog geen account aangemaakt?",
    forgotLogin: "Je wachtwoord vergeten ?",
    emailLogin: "E-mail",
    validInscription: "VALIDEER MIJN REGISTRATIE",
    activateAccount: "ACTIVEER MIJN ACCOUNT",
    madame: "DAMES",
    monsieur: "MENS",
    other: "ANDER",
    registerDone_1: "Er is rekening gehouden met uw registratieverzoek.",
    registerDone_2: "Er is een e-mail naar u verzonden.",
    registerDone_3: "Klik op de link in deze e-mail om het aanmaken van uw account te bevestigen.",
    registerDone_4: "Tot ziens !",
    validRegister: "bevestigen",
    activateMyAccount: "Activeer mijn account",
    directeur: "Regisseur",
    professeur: "Docent",
    invalidToken: "Deze link is eerder gebruikt en is verlopen. Neem indien nodig contact op met de ondersteuning.",
    questionAccount: "Heb je al een account?",
    verifMail_1: "Je e-mailadres is geverifieerd, je kunt nu inloggen.",
    verifMail_3: "Validatie van uw e-mail",
    changePass: "Verander wachtwoord",
    msgUpdatedPass: "Het wachtwoord is gewijzigd.",
    recPass: "Wachtwoord herstellen",
    reinMail: "Vul hieronder uw e-mailadres in. U ontvangt een link om uw wachtwoord opnieuw in te stellen.",
    reinBUttonPass: "Reset mijn wachtwoord",
    sendMailRein: "Er is een e-mail met uw reset-link naar u verzonden.",
    alreadyHaveAcc: "Heb je al een account?",
    followCourse: "Volg deze cursus",
    followPath: "Volg deze route",
    followSession: "Sessie volgen",
    objPEda: "educatieve doelen",
    preresuiPeda: "Vereisten voor deze cursus",
    preresuiPedaPath: "Vereisten voor deze cursus",
    preresuiPedaSession: "Vereisten voor deze sessie",
    skillsPreveiw: "Kennis en vaardigheden verworven tijdens deze cursus",
    skillsPreveiwPath: "Kennis en vaardigheden verworven tijdens deze cursus",
    skillsPreveiwSession: "Kennis en vaardigheden opgedaan tijdens deze sessie",
    courseProgramm: "Cursusprogramma",
    Chapitres: "hoofdstukken",
    videoCourse: "Videolessen",
    textCourse: "Cursustekst",
    downloadFiles: "Bestanden om te downloaden",
    testConn: "Kennis testen",
    aboutSchool: "Over de school",
    aboutProfessor: "Over de leraar",
    registerCourse: "Schrijf je in voor de cursus",
    registerPath: "Schrijf je in voor de cursus",
    registerSession: "Schrijf je in voor de sessie",
    testConn_2: "Test je kennis",
    progressView: "Volg je voortgang",
    previewText_1: "Vul je registratieverzoek in zodat de docent het kan valideren.",
    previewText_2: "Ga vooruit in je programma en leer nieuwe vaardigheden.",
    previewText_3: "Valideer uw kennis met tests en behaal uw certificaat.",
    Introduction: "Invoering",
    Documents: "Documenten",
    downloadFIleDetails: "Downloaden",
    Etudiant_1: "Leerling",
    Professeur_1: "Docent",
    Directeur_1: "Regisseur",
    annuler: "ANNULEREN",
    modifier: "Aanpasser",
    logout: "Uitloggen",
    connexionButton: "Verband",
    languageFR: "Frans",
    languageEN: "Engels",
    language: "Taal",
    Pending_3: "Valideren",
    CERTIFICAT: "CERTIFICAAT",
    refuseCourse: "PUBLICATIE WEIGEREN",
    joursVisit: "dagen",
    consulter: "CONSULTEREN",
    jourVisit: "Dag",
    Candidat: "Kandidaat",
    enCours: "STUDENTEN IN UITVOERING",
    certifiés: "AFGESTUDEERD",
    Dossier: "Dossier",
    Demande: "Verzoek",
    DemandeEnvoyéeIlYA: "Verzoek verzonden geleden",
    Resultats: "Resultaten",
    Certificat: "Certificaat",
    Tempsdobtention: "Het verkrijgen van",
    Tempsdobtention_2: "Tijd verkrijgen",
    refuseCourseDirector: "Publicatie weigeren",
    refuseCourseDirector_2: "Voeg een opmerking toe voor de leraar",
    refuseCourseDirector_3: "DE CURSUS WEIGEREN",
    modalRefuseCourseSuccess_1: "U heeft zojuist de publicatie van de cursus geweigerd.",
    modalRefuseCourseSuccess_2: "Je weigering is naar de docent gestuurd.",
    archiveCourseDirector: "Weet je zeker dat je deze cursus wilt archiveren?",
    archiveCourseDirectorSuccess: "De cursus wordt gearchiveerd.",
    archiveCourseDirector_2: "ARCHIEF DE CURSUS",
    nothingTOSay: "Niets te zeggen",
    online: "Online",
    offline: "Offline",
    validationNewPassword: "U kunt inloggen met uw nieuwe wachtwoord.",
    showMore: "TOON VOLGENDE",
    folderRegistrationTrueOrFalse: "Onder voorbehoud van validatie van het bestand.",
    nextCommentsFeedback: "Volgende tonen",
    emailUpdateButtonShH: "WIJZIG MIJN E-MAILADRES",
    conditionDeValidation: "Validatievoorwaarden van het verzoek tot inschrijving van de student door de professor.",
    Acceptation: "Acceptatie van automatische studentenregistratie.",
    AcceptationSousDossier: "Aanvaarding van de inschrijving van de student onder voorbehoud van validatie van het dossier.",
    questions: "Vragen om de student te stellen",
    documents: "Documenten op te vragen bij de student",
    titreQuestion: "Vul hier de titel van de vraag in",
    titreDocument: "Vermeld hier de titel van het document",
    AjouterQuestion: "Vraag toevoegen",
    AjouterDocument: "Document toevoegen",
    demandeDinscription: "Abonnementsverzoek",
    envoyerDemande: "STUUR MIJN VERZOEK IN",
    titreRegistration_1: "Vragen gesteld door de leraar",
    titreRegistration_2: "Documenten aangevraagd door de leraar",
    Inscribed: "Bezig",
    CertifiedStudent: "Gecertificeerd",
    DraftStudent: "Registratiebestand bezig",
    Candidate: "In afwachting van validatie van het bestand",
    finishRegistration: "VOLTOOI MIJN VERZOEK",
    noCoursesStudent: "Je bent voor geen enkele cursus ingeschreven.",
    discoverCatalog: "ONTDEK DE CATALOGUS",
    noCoursesCreated: "Er is nog geen training aangemaakt.",
    noCoursesCreated_2: "Maak om te beginnen uw eerste cursus aan.",
    noCourses_1: "Geen cursussen gevolgd",
    noCourses_2: "Geen registratieverzoek",
    noCourses_3: "Geen cursussen afgerond",
    noCoursesPublished: "Er zijn nog geen cursussen gepubliceerd.",
    deleteCompte: "Verwijder mijn account",
    searchProfessors: "Zoeken...",
    descriptionProfLabel: "Korte beschrijving van de leraar die zal verschijnen in de presentatie van zijn cursussen.",
    relaunchProfInvitation: "HERZIEN",
    noProfessorsList_1: "Geen leraar gevonden.",
    noProfessorsList_2: "Zoek opnieuw.",
    noProfessorsListDefault_1: "Er is geen docent geregistreerd.",
    noProfessorsListDefault_2: "Nodig een docent uit om zich aan te melden om aan de slag te gaan.",
    signatureProf: "handtekening van de professor",
    signatureDirector: "handtekening van de directeur",
    Deactivate: "Gehandicapt",
    breadCrumbTest: "Testen",
    placeholderTitleExam: "Geef hier de titel van de test aan...",
    exempleTitleExam: "Voorbeeld, Test van kennis van de eerste kennis",
    noQuestionTest: "Maak om te beginnen uw eerste vraag.",
    newQuestion: "NIEUW PROBLEEM",
    addQuestionText: "Vraag toevoegen",
    placeholderQuestionText: "Geef de vraag aan",
    answerQuestion: "Geef het antwoord aan",
    placeholderChoiceText: "Geef de tekst van de meerkeuzes aan",
    delete: "verwijderen",
    suppcomte: "Account verwijderen",
    selectMotif: "Selecteer een patroon",
    coursNeMeconvier: "Lessen passen niet bij mij",
    comm: "Voeg een reactie toe",
    DeleteSuccess: "Uw account is succesvol verwijderd. Tot ziens.",
    CreationAccountProfDirector: "Je account is aangemaakt. U kunt inloggen.",
    messageEnvoyerDemande_1: "Uw registratieverzoek is verzonden.",
    messageEnvoyerDemande_2: "Je krijgt een antwoord per e-mail",
    messageEnvoyerDemande_3: "in de komende 3 dagen.",
    candidat_status: "WACHTEN OP VALIDATIE",
    refuser: "WEIGEREN",
    refuser_1: "WEIGEREN",
    valider: "BEVESTIGEN",
    valider_1: "GEACCEPTEERD",
    refuserDemandeRegistration: "Studentenregistratie weigeren",
    ajouterCommentaireEtudiant: "Voeg een opmerking toe voor de leerling.",
    refuserLaDemande: "WEIGEREN HET VERZOEK",
    messageRefuseRegistrationCours: "De inschrijving van studenten voor de cursus is met succes afgewezen.",
    messageRefuseRegistrationPath: "De inschrijving van de student voor de cursus is met succes geweigerd.",
    messageRefuseRegistrationSession: "Studentregistratie voor sessie succesvol afgewezen.",
    messageValiderRegistration: "De student werd uitgenodigd om de cursus te gaan volgen.",
    messageValiderRegistrationParcours: "De student is uitgenodigd om de cursus te gaan volgen",
    messageValiderRegistrationSession: "De student is uitgenodigd om na de sessie te starten",
    SuspendProf: "Opschorten",
    textProfSuspend: "Deze docent heeft geen toegang meer tot de lessen. Je hebt de mogelijkheid om het te herstellen als je van gedachten verandert.",
    otherChoice: "Ander",
    commentProfSuspend: "Voeg een opmerking toe voor de leraar",
    questionLabel: "Vraag",
    choiceLabelBON: "Correct antwoord",
    addAnswerButton: "Voeg een antwoord toe",
    noQuestionTestPreview: "Geen vragen",
    passedTestAverage: "Slaagkans",
    param: "Instelling",
    presen: "Presentatie",
    commentSuspendInputProf: "Opmerking...",
    motifSuspendProf: "Selecteer een patroon",
    affectOtherPRof: "Selecteer een leraar om lessen aan toe te wijzen",
    ReintegrateProf: "reïntegreren",
    ReintegrateProfText: "Deze docent heeft toegang tot de lessen. Je hebt de mogelijkheid om de leraar te schorsen als je van gedachten verandert.",
    AcceptProf: "Accepteren",
    aArchiver: "Om te archiveren",
    aDesactiver: "Deactiveren:",
    aActiver: "Activeren",
    desactiverCourse: "DEACTIVERING BEVESTIGEN",
    refuseDesactivationCourse: "DEACTIVATIE WEIGEREN",
    activerCours: "ACTIVERING VALIDEREN",
    refuserLactivationCours: "ACTIVERING WEIGEREN",
    archiverCours: "ARCHIVERING VALIDEREN",
    refuserArchivationCours: "ARCHIVERING WEIGEREN",
    settingEcole: "Schoolinstellingen",
    nomEcole: "Naam van de school",
    logoEcole: "schoollogo",
    persenCourte: "Korte presentatie (deze tekst wordt getoond op de cursuspagina's)",
    listCandidates: "Registratieverzoeken",
    listFollowing: "Opleidingen gevolgd",
    listeCertificated: "Afgeronde trainingen",
    sendDate: "Het verzoek verzenden",
    importVideoUpload: "Video downloaden",
    waitingUpload: "Even geduld aub...",
    uploadFinishing: "De video is binnenkort beschikbaar",
    uploadPending: "Wordt verwerkt...",
    candidatures: "Toepassingen",
    certifcations: "CERTIFICERINGEN",
    dashboardAllStudents: "Leerling",
    certifiedDashStudents: "Afstuderen",
    YesWord: "Ja",
    NoWord: "Niet",
    obtentionPeriod: "Het verkrijgen van",
    In: "In",
    TimePassed: "ik wil",
    noStudentFound: "Geen huidige studenten",
    noCandidateFound: "Geen aanvragen in behandeling",
    noSuspendedFound: "Geen enkele leerling is geschorst",
    noCertifedFound: "Geen gecertificeerde of afgestudeerde studenten",
    textStudentSuspend: "Deze student heeft geen toegang meer tot de cursussen. Je hebt de mogelijkheid om het te herstellen als je van gedachten verandert.",
    commentStudentSuspend: "Voeg een opmerking toe voor de student",
    successSuspendStudent: "De student heeft geen toegang meer tot de cursussen.",
    ReintegrateStudentText: "Deze student krijgt toegang tot de cursussen.",
    ReintegrateStudentText_2: "Je hebt de mogelijkheid om de student uit te sluiten als je van gedachten verandert.",
    successReinstateStudent: "De student heeft nu toegang tot de cursussen.",
    successSuspendProf: "Professor succesvol geschorst",
    successSuspendAssitant: "Wizard succesvol opgeschort",
    successReinstateProf: "Professor succesvol gereïntegreerd",
    successReinstateAssistant: "Succesvolle Re-integratie Wizard",
    returnToProfProfile: "Terug NAAR PROFIEL",
    diplomeButton: "Diploma",
    validatedDiploma: "DIPLOMAS",
    Declcatalog: "ONTDEK DE CATALOGUS",
    deactivateCoursText: "Deze cursus is niet langer toegankelijk, je hebt de mogelijkheid om deze opnieuw te activeren als je van gedachten verandert.",
    modalDeactivateCoursTitle: "Deactivering van de cursus",
    deactivateCours: "Deactiveren",
    successdeactivateCours: "Is succesvol afgerond.",
    createBy: "Gegeven door",
    maleGender: "M.",
    femaleGender: "Mama",
    modalReactivateCoursTitle: "Cursus reactivering",
    modalReactivatePathTitle: "Cursus reactivering",
    modalReactivateSessionTitle: "Sessie opnieuw activeren",
    reactivateCours: "Opnieuw activeren",
    reactivateCoursText: "Deze cursus zal toegankelijk zijn.",
    reactivateCoursText_2: "Je hebt de mogelijkheid om het uit te schakelen als je van gedachten verandert.",
    reactivatePathText: "Deze route zal toegankelijk zijn.",
    reactivatePathText_2: "Je hebt de mogelijkheid om het uit te schakelen als je van gedachten verandert.",
    reactivateSessionText: "Deze sessie zal toegankelijk zijn.",
    reactivateSessionText_2: "Je hebt de mogelijkheid om het uit te schakelen als je van gedachten verandert.",
    champsObligatoires: "(*) Verplichte velden",
    continueCreatingCourse: "Sorry, je kunt deze cursus niet publiceren.",
    continueBUttonText: "DOORGAAN",
    errorChaptersLength: "Je moet minimaal één hoofdstuk hebben (vereist).",
    linkImgCourseError: "Visueel om de cursus te illustreren (verplicht).",
    descriptionCourseError: "Tekstpresentatie van de cursus (verplicht).",
    congratulation: "Gefeliciteerd met je afstuderen!",
    diplomaMsg : "Om uw diploma te kunnen downloaden, raadpleegt u de volledige inhoud in de hoofdstukken (inclusief video's)",
    onlineCOurses: "Online cursus",
    validatedCourses: "Cursus om te valideren",
    AllCoursesDirector: "Alle cursussen",
    BonneRéponses: "Goede antwoorden",
    Question: "Vraag",
    ReturnExam: "HERVAT MIJN HUIDIGE TEST",
    textExa: "Selecteer een of meer antwoorden, afhankelijk van de instructie. De test wordt slechts één keer uitgevoerd.",
    StartExam: "Beginnen",
    Réponse: "Antwoorden",
    QCU: "Slechts 1 juist antwoord mogelijk",
    QCM: "Veel mogelijke reacties",
    validCourse: "VALIDEER DE PUBLICATIE",
    refuseInscriptionStudent: "VERZOEK GEWEIGERD",
    BonneReponses: "Goede antwoorden",
    placeholderTextArea: "Voeg hier tekst in...",
    languageIT: "Italië",
    languageES: "Spaans",
    languageDE: "Duits",
    languageNL: "Nederlands",
    languageSV: "Zweeds",
    obligatoireFormCourse: "verplicht",
    Registered: "Geregistreerd",
    New: "Nieuw",
    Suspended: "Geschorst",
    CatalogueVisitorsInTheLast30Days: "Catalogusbezoekers in de afgelopen 30 dagen.",
    Course: "Cursus",
    Candidates: "Kandidaten",
    Teachers: "Leraren",
    ConversionRateOfApplicationsInTheLast30Days: "Conversieratio van bezoeken in de afgelopen 30 dagen.",
    MedianCourseNotes: "Mediane eindexamenscore",
    AverageAgeOfStudents: "Gemiddelde leeftijd van studenten",
    Male: "mannen",
    Female: "Vrouwen",
    MostRepresentedCities: "Meest vertegenwoordigde steden",
    VisitsAndApplicationsForRegistration: "BEZOEKEN & REGISTRATIE",
    RegistrationsAndCertifications: "INSCHRIJVINGEN & GRADEN",
    ConnectedStudents: "Verbonden studenten",
    LevelOfStudy: "studieniveau",
    numberOfVisits: "Overzicht pagina bezoeken",
    Applications: "Registratieverzoeken",
    Registration: "Aantal registraties",
    StudentsNumber: "Aantal aangesloten studenten",
    Jan: "Jan",
    Fev: "februari",
    Mar: "maart",
    Avr: "april",
    May: "Kunnen",
    Jui: "juni-",
    Jul: "Jui",
    Aug: "Mij",
    Sep: "september",
    Oct: "okt",
    Nov: "november",
    Dec: "december",
    Access: "Om toegang te krijgen",
    Year: "jaar",
    StudentsConnectedThisMonth: "Ingelogd in de afgelopen 30 dagen.",
    AverageTimeSpentOnThePlatformForGraduation: "Gemiddelde tijd doorgebracht op het platform voor afstuderen",
    AverageTimeBetweenRegistrationAndGraduation: "Gemiddelde tijd van inschrijving tot afstuderen",
    certification: "Certificering",
    waitingCertif: "Bezig met laden van je diploma, even geduld aub...",
    ListEtds: "Studentenlijst",
    listcand: "Lijst van kandidaten",
    listdipv: "Lijst van afgestudeerden",
    listInscribed: "Lijst van registranten",
    listSuspended: "Lijst van geschorst",
    Days: "dagen",
    noVideoToShow: "Geen video.",
    donnerAvis: "Het is belangrijk !",
    donnerAvis_2: "Geef je mening over de cursus",
    avisTitle: "titel van je recensie",
    avisText: "Schrijf je beoordeling",
    votreAvis: "Uw mening hier",
    publishMyReview: "Publiceren",
    monAvis: "Mijn mening over de cursus",
    avis: "De mening",
    Anonymous: "Anoniem",
    noFeedback: "Geen beoordelingen (voorlopig)",
    Finance: "Financiën",
    PublishedAt: "gepubliceerd op",
    PublishedAte: "gepubliceerd op",
    ActualPrice: "Laatste prijs van de periode",
    MoyPrix: "Gemiddelde prijs",
    discussionButton: "Discussies",
    questionButton: "Vragen aan de leraar",
    addCommentButton: "Voeg een reactie toe",
    addCommentForChapter: "Voeg een opmerking toe aan het hoofdstuk",
    addcommentForCourse: "Voeg een opmerking toe aan de cursus",
    addcommentForPath: "Voeg een opmerking toe aan de cursus",
    addCommentForSession: "Voeg een opmerking toe aan de sessie",
    commentTitle: "Titel van commentaar",
    yourComment: "Schrijf je commentaar",
    votreCommentaie: "Jouw reactie hier...",
    addQuestionButton: "Vraag toevoegen",
    addCommentSuccess: "Uw reactie is succesvol toegevoegd",
    responses: "antwoord(en)",
    noCommentFound: "Geen reacties (voorlopig)",
    repondre: "beantwoorden",
    labeldeletecompte: "Al uw gegevens worden permanent verwijderd",
    listdesProf: "Lijst van docenten",
    noCoursePayedDefault: "Geen betaalde cursus.",
    noCoursePayed_1: "Geen betaalde cursussen gevonden.",
    noCoursePayed_2: "Zoek opnieuw.",
    Courspayed: "Betaalde cursussen",
    CoursFree: "Gratis lessen",
    paie: "Lijst met betalingen",
    prunp: "Geef een periode op",
    Année: "Jaar",
    community: "Gemeenschap",
    thematic: "Kies een thema",
    comment: "Opmerking",
    NumberOfCertifications: "Aantal certificaten",
    inscriptionTypePayment: "Type d'inscriptie",
    registrationFees: "Registratiekosten",
    gratuit: "Vrij",
    payant: "betalend",
    addQuestionForChapter: "Stel een vraag aan de leraar",
    yourQuestion: "Schrijf je vraag",
    addQuestionSuccess: "Uw vraag is succesvol toegevoegd",
    noQuestionFound: "Geen vragen om mee om te gaan",
    noQuestionFound_4: "Geen vragen beantwoord",
    noQuestionFound_5: "U heeft geen vragen.",
    paymentTitle: "Betaling",
    paymentTitlePlural: "Betalingen",
    paymentMethodCourses: "Betaalmethode cursus",
    choiceCurerncy: "Kies valuta",
    Questions: "Vragen",
    unsettledQuestion: "Aan te pakken problemen",
    settledQuestion: "Aangepakte problemen",
    allQuestion: "Alle vragen",
    recent: "Het meest recente",
    oldest: "De minst recente",
    maxResponses: "Met zoveel mogelijk antwoorden",
    allSubjects: "Alle cursussen",
    allSubjects_2: "Alle onderwerpen",
    noQuestionFound_2: "Uw zoekopdracht kwam met geen enkele vraag overeen.",
    noQuestionFound_3: "Zoek opnieuw.",
    noCommentFound_2: "Uw zoekopdracht heeft geen reacties opgeleverd",
    noPaymentInfo: "Je moet de betaalmethode van je school kiezen",
    NoPaymentSchoolConfig_1: "Om de betaalmethode in te stellen.",
    NoPaymentSchoolConfig_2: "Klik hier",
    newResponses: "Nieuwe antwoord(en)",
    SortsMostRecent: "Meest recente",
    SortOldest: "De oudste",
    allStatus: "Alle statussen",
    WAITING: "Wachten op validatie",
    SOON: "Komen",
    ONGOING: "Bezig",
    ENDED: "Beëindigd",
    REFUSED: "Weigeren",
    allCourses: "Alle cursussen",
    NoWebinarHasBeenCreatedYetCreateYourFirstWebinarToGetStarted: "Er zijn nog geen webinars aangemaakt. Maak je eerste webinar om aan de slag te gaan.",
    ForStudentsOf: "Voor het opleiden van studenten",
    WebinarGivenBy: "Webinar gegeven door",
    ENTERTHEWebinar: "NEEM HET webinar in",
    StartTHEWebinar: "Start HET webinar",
    AddToMyAgenda: "Voeg toe aan mijn agenda",
    validate: "bevestigen",
    inMyAgenda: "in mijn dagboek",
    Edit: "Aanpasser",
    REVIEWTHEwebinar: "BEKIJK HET webinar",
    IWantTheWebinarToBeAccessibleInReplayWithThePublicConcerned: "Ik wil graag dat het webinar in replay toegankelijk is voor het betrokken publiek",
    NewWebinar: "Nieuw webinar",
    close: "SLUITEN",
    Webinar: "Webinars",
    WebinarTitle: "Titel van webinar",
    WebinarDate: "Datum je webinar",
    WebinarSchedule: "Webinar schema",
    ShortDescriptionOfTheWebinar: "Korte beschrijving van het webinar",
    Cancel: "annuleren",
    WaitingForValidation: "Wachten op validatie",
    ComingSoon: "binnenkort",
    LetsGo: "Laten we gaan",
    ToReview: "Beoordelen",
    inscriptionCourseFaq: "Schrijf je in voor een cursus",
    followCourseFaq: "Een vak volgen",
    certificationFaq: "De diploma-uitreiking",
    professorsFaq: "hoogleraren",
    stopCourseFaq: "Een les stoppen",
    paymentFaq: "Facturering",
    PAYED: "Betaald",
    PRIX: "Prijs",
    openQuestion: "Open vraag",
    Consignes: "instructies:",
    Valeurdelaquestion: "Vraagwaarde",
    point: "punt",
    Nouveau_test: "Nieuwe test",
    TEST_DE_CONNAISSANCE: "KENNIS TEST",
    Test_intermédiaire: "Tussentijdse evaluatietest",
    Non_éliminatoire: "niet eliminerend",
    Question_fermées: "Gesloten vragen",
    Test_de_fin_de_cours: "Einde cursus test",
    Validant_le_diplome: "Diploma valideren",
    Question_fermée_et_ou_ouvertes: "Gesloten en/of open vragen",
    modal_exam_title: "Welk type test wil je maken?",
    AJOUTER_EXPLICATION: "VOEG EEN UITLEG TOE",
    Explication: "Uitleg",
    add: "Toevoegen",
    Créer_votre_premiere_question: "Maak je eerste vraag",
    Examen_final_envoyé_le: "Eindexamen verzonden",
    En_attende_de_correction_par_le_professeur: "In afwachting van correctie door de leraar",
    Répondre_aux_questions_fermees_ou_ouvertes_en_respectant_les_consignes: "Beantwoord gesloten of open vragen door de instructies te volgen.",
    Le_test_seffectue_une_seule_fois_et_détermine_lobtention_de_votre_diplome: "De test wordt slechts één keer uitgevoerd en",
    Le_test_seffectue_une_seule_fois_et_détermine_lobtention_de_votre_diplome_2: "bepaalt je afstuderen.",
    Réuissi: "Succesvol",
    insuffisant: "niet voldoende",
    Valider_ma_réponse: "Bevestig mijn antwoord",
    Rendu_le: "Afgeleverd op",
    Par: "over",
    NOTER_LA_QUESTION: "OPMERKING VRAAG:",
    Examens_à_noter: "Examens om te scoren",
    Examen_notés: "beoordeelde examens",
    Tous_les_examens: "Alle examens",
    aucun_examen_à_noter: "Geen examen om te beoordelen",
    NOTE: "OPMERKING",
    à_noter: "noteren",
    Commentaire_du_professeur: "Commentaar van de leraar",
    Pas_de_commentaire: "Geen commentaar",
    Ajouter_un_commentaire: "Voeg een reactie toe",
    VALIDER_MA_NOTATION: "VALIDEER MIJN BEOORDELING",
    EXAMEN_FINAL: "EINDEXAMEN",
    exam1: "Vermeld hier de titel van het eindexamen ...",
    exam2: "Geef hier de titel van de kennistoets aan",
    votreMessage: "Schrijf je bericht...",
    noMessage: "Geen bericht",
    noMessageDispo: "nog niet beschikbaar...",
    message_1: "Je moet wachten op de leraar",
    message_2: "open het gesprek",
    message_1_prof: "Begin een gesprek door te typen",
    message_2_prof: "hieronder uw bericht.",
    textFaq: "Wat als ik meer wil weten over de beoordeling van mijn schriftelijke opdracht?",
    draftstatdateFollow: "Zaak begonnen op",
    finishdraftFollowButton: "Voltooi mijn bestand",
    gotocourseStudent: "Toegang tot de cursus",
    myFormations: "Mijn trainingen",
    SelectCourse: "Selecteer cursus",
    NoWebinarHasBeenCreatedYet: "Er zijn nog geen webinars aangemaakt.",
    TitleFormations: "formaties",
    InscriptionDate: "Registreer op",
    biography: "Biografie",
    labelMailProfessor: "E-mail docent",
    comments: "Opmerkingen",
    noStudentInscribed: "Er is nog geen student ingeschreven",
    demandTable: "Verzoek gedaan op",
    createNewFormation: "Nieuwe formatie",
    selectFormatFormation: "Selecteer een maat",
    pathTitle: "Parcours",
    createPath: "maak een route",
    sessionTitle: "Sessie",
    createSession: "een sessie maken",
    requiredLevelText: "Niveau vereist om deel te nemen aan de cursus",
    anyLevelCourse: "alle niveaus",
    firstLevel: "Beginner",
    secondLevel: "Tussenliggend",
    thirdLevel: "deskundige",
    ANYLEVEL: "alle niveaus",
    BEGINNER: "Beginner",
    courseDescriptionForm: "Beschrijf je cursus",
    descriptionTitle: "Beschrijving",
    courseContentTitle: "Cursusinhoud",
    pathContentTitle: "Cursusinhoud",
    sessionContentTitle: "Sessie inhoud",
    importVideoCourse: "Video importeren",
    createContentText: "Maak je eerste inhoud",
    chapterPresentation: "Presentatie van het hoofdstuk",
    textPresentationChapter: "Inleidende tekst van het hoofdstuk",
    groupes: "Groepen",
    newGroup: "Nieuwe groep",
    groupTitle: "Titel studentengroep",
    newTestModalTitle: "Nieuwe test",
    alreadyCreateExam: "Je hebt al een eindexamen gemaakt",
    finalExmBreadcrunbTitle: "Eindexamen",
    noGroupFound: "Geen studentengroep gemaakt",
    noGroupFound_2: "Uw zoekopdracht komt met geen enkele groep overeen",
    updateGroupTitle: "Titel bewerken",
    deleteGroup: "Groep verwijderen",
    updateGroup: "Groepstitel wijzigen",
    addSubGroup: "Een subgroep toevoegen",
    addStudentToGroup: "Studenten toevoegen",
    Actions: "Acties",
    textConnaissance: "Kennis test",
    points: "punten",
    connectedThereIs: "geleden ingelogd",
    messageDeleteGroup_1: "Deze groep wordt definitief verwijderd.",
    messageDeleteGroup_2: "Je hebt niet de mogelijkheid om deze groep te herstellen als je van gedachten verandert",
    messageDeleteGroup_3: "Groep succesvol verwijderd",
    closedQuestion: "Gesloten vragen",
    pointsQuestion: "punt(en)",
    explicationText: "Schrijf je uitleg...",
    allStudent: "Alle studenten",
    subGroup: "subgroep",
    changeGroup: "Groep wijzigen",
    addToGroup: "Aan groep toevoegen",
    deleteFromGroup: "Verwijderen uit de groep",
    documentTextForm: "Document",
    returnToCourse: "terug naar de klas",
    returnToPathButton: "terug naar cursus",
    returnToSessionButton: "terug naar sessie",
    sendPublishCoursePRofTitle: "Stuur naar de directeur om de publicatie te valideren",
    returnToStudentList: "Terug naar studentenlijst",
    Previsualiser: "Voorbeeld",
    editeMode: "Bewerk",
    withInscription: "Registratie onder bestand",
    freeTitleCourse: "aangeboden",
    questionPlaceholder: "Uw vraag hier...",
    responsePlceholder: "Schrijf hier een antwoord..",
    explicationPlaceholder: "Schrijf hier een uitleg...",
    consignesPlaceholder: "Uw instructies hier...",
    goToCatalogueError: "Om terug te keren naar de vorige pagina klikt u op onderstaande knop.",
    errorCode: "Foutcode",
    oupsWord: "Oeps!",
    errorInfoNotFound: "Sorry, de pagina die u zoekt kan niet worden gevonden.",
    errorInfoUnauthorized: "Geen rechten gevonden.",
    errorSubinfoUnauthorized: "Deze pagina is niet toegankelijk voor het publiek.",
    errorInfoAccessRefused: "Toegang geweigerd.",
    errorSubInfoAccessRefused: "U heeft geen toegang tot deze pagina.",
    serverProblem: "server probleem",
    tryLater: "Probeer het over een paar minuten.",
    unexpectedError: "Sorry, onverwachte fout.",
    errorInfoServerProblem: "De pagina die u zoekt is momenteel in onderhoud en zal spoedig terugkomen.",
    continueCreatingPath: "Sorry, je kunt deze cursus niet publiceren.",
    continueCreatingSession: "Sorry, je kunt deze sessie niet publiceren.",
    returnToPath: "terug naar cursus",
    returnToSession: "terug naar sessie",
    courseDesactivationSuccess: "Cursus succesvol gedeactiveerd",
    pathDesactivationSuccess: "Cursus succesvol gedeactiveerd",
    sessionDesactivationSuccess: "Sessie succesvol uitgeschakeld",
    textDeactivateCourse: "Cursus uitschakelen",
    textDeactivatePath: "Cursus uitschakelen",
    textDeactivateSession: "Sessie deactiveren",
    textreactiovationCourse: "Cursus succesvol geactiveerd",
    textreactiovationPath: "Route succesvol geactiveerd",
    textreactiovationSession: "Sessie succesvol geactiveerd",
    text_1_desactivateCourse: "Deze cursus is niet meer toegankelijk.",
    text_2_desactivateCourse: "Je hebt de mogelijkheid om de cursus opnieuw te activeren als je van gedachten verandert",
    text_1_desactivatePath: "Deze route is niet meer toegankelijk.",
    text_2_desactivatePath: "Je hebt de mogelijkheid om de cursus opnieuw te activeren als je van gedachten verandert",
    text_1_desactivateSession: "Deze sessie is niet meer toegankelijk.",
    text_2_desactivateSession: "Je hebt de mogelijkheid om de sessie opnieuw te activeren als je van gedachten verandert",
    refuseCourseText: "De publicatie van de cursus weigeren",
    refusePathText: "De publicatie van de cursus weigeren",
    refuseSessionText: "Sessiepublicatie weigeren",
    backToListCourses: "terug naar cursuslijst",
    textRefusedCourseSuccess: "Cursus succesvol afgewezen",
    textRefusedPathSuccess: "Pad succesvol geweigerd",
    textRefusedSessionSuccess: "Sessie succesvol geweigerd",
    acceptCourseText: "Valideer de publicatie van de cursus",
    acceptPathText: "Valideer de publicatie van de cursus",
    acceptSessionText: "Valideer de publicatie van de sessie",
    textAcceptCourse: "Deze cursus zal toegankelijk zijn voor alle studenten.",
    textAcceptPath: "Deze cursus zal toegankelijk zijn voor alle studenten.",
    textAcceptSession: "Deze sessie is toegankelijk voor alle studenten.",
    publishCourseSuccessModal: "Cursus succesvol gepubliceerd",
    publishPathSuccessModal: "Cursus succesvol gepubliceerd",
    publishSessionSuccessModal: "Sessie succesvol gepubliceerd",
    errorContentLength: "U moet ten minste één inhoud hebben (vereist).",
    errorPathLinkImg: "Visueel om de cursus te illustreren (verplicht).",
    errorSessionLinkImg: "Visueel om de sessie te illustreren (verplicht).",
    errorPathDescription: "Tekstpresentatie van de cursus (verplicht).",
    errorSessionDescription: "Tekstpresentatie van de sessie (verplicht).",
    typeTable: "type",
    parcoursTitle: "Parcours",
    PATH: "Parcours",
    progress: "vooruitgang",
    gotopathStudent: "Toegang tot de cursus",
    gotosessionStudent: "Toegang tot de sessie",
    newContent: "nieuwe inhoud",
    chapterTitleBegin: "Hoofdstuktitel",
    testTitleBegin: "Titel kennistest",
    examTitleBegin: "Examen titel",
    onlineFormations: "Online training",
    validateFormations: "Training om te worden gevalideerd",
    allFormations: "Alle cursussen",
    inscribedTimeStudent: "geregistreerd op",
    suspendedAt: "geschorst op",
    newFinalExam: "Een eindexamen toevoegen",
    backToMyrequest: "Terug naar mijn verzoek",
    beforeUploadText: "Versleep de bestanden die u wilt delen",
    uploadFile: "SELECTEER EEN BESTAND",
    Subscription: "Abonnementsverzoek",
    waiting: "In afwachting",
    fileValidated: "Bestand gevalideerd",
    fileRefused: "Bestand geweigerd",
    autorisationTraining: "Rechten",
    autorisationTrainingTable: "Toestemming",
    autorzedStatus: "Geautoriseerd",
    unauthorized: "Ongeautoriseerd",
    groupNameTable: "Naam van de groep",
    changeMyDecision: "Mijn beslissing wijzigen",
    backtodemand: "terug op aanvraag",
    cancelValidation: "Validatie annuleren",
    downloadFileCertif: "Download mijn diploma",
    nextChapterText: "Volgend hoofdstuk",
    getMyDiplome: "Mijn diploma",
    ListAllCourses: "Alle cursussen weergeven",
    ListOnlineCourses: "Lijst met online cursussen",
    ListPendingCourses: "Lijst met openstaande cursussen",
    ListStudents: "Lijst van studenten",
    courseBy: "Cursus gegeven door",
    SessionGivenBy: "Sessie gegeven door",
    PathGivenBy: "Cursus gegeven door",
    PaymentDetails: "Betalingsdetails",
    Completeyourpurchasebyprovidingyourpaymentinformation: "Rond uw aankoop af door uw betalingsgegevens op te geven.",
    LastNameandFirstName: "Voor-en achternaam",
    CreditCardNumber: "creditcardnummer",
    ExpirationDate: "Vervaldatum",
    confirmandpay: "bevestig en betaal",
    Yourpaymentwassuccessful: "Uw betaling is succesvol uitgevoerd.",
    StartThePath: "begin de reis",
    Yourpaymenthasbeendeclined: "Uw betaling is geweigerd",
    StartTheCourse: "start de cursus",
    StartTheSession: "sessie starten",
    selectLevelCourse_4: "Alle niveaus",
    progressTable: "vooruitgang",
    notationLabel: "notatie",
    singleClosedQuestion: "Vraag gesloten",
    Du: "Van",
    Au: "Bij",
    Examens: "examens",
    fromWhereTraining: "Komt van",
    congratsTraining: "Gefeliciteerd, je hebt de cursus afgerond.",
    donnerAvis_3: "Geef je mening over de cursus:",
    donnerAvis_4: "Geef je mening over de sessie:",
    donnerAvisButton: "Geef uw mening",
    InitialPathName: "Cursustitel",
    PathDecriptionCreation: "Beschrijf je achtergrond",
    prerequisite1: "Vereisten om deze cursus te volgen",
    skillsNeeded: "Kennis en vaardigheden verworven tijdens deze cursus",
    pathCoverPhot: "Sleep een foto om de cursus te illustreren (jpg, png)",
    pathVideoDescription: "Videopresentatie van de cursus",
    pathVideoDrop: "Versleep de cursusvideo die je wilt uploaden (mp4, mov)",
    PathLevel: "Niveau vereist om in te schrijven voor de cursus",
    SessionTitleCreation: "Sessie titel",
    SessionLevel: "Niveau vereist om te registreren voor de sessie",
    SessionDescriptionCreation: "Beschrijf je sessie",
    prerequisite2: "Vereisten om deze sessie te volgen",
    SessionSkills: "Kennis en vaardigheden opgedaan tijdens deze sessie",
    SessionCoverDescription: "Visueel om de sessie te illustreren",
    SessionCoverDrop: "Sleep een foto om de sessie te illustreren (jpg, png)",
    SessionDescriptionVideo: "Videopresentatie van de sessie",
    DragDropSession: "Versleep de sessievideo die je wilt uploaden (mp4, mov)",
    saveSchoolLogo: "Voeg een foto toe",
    descriptionChoiseCours: "Creëer uw cursus met zijn hoofdstukken, tests en eindexamen. Stel uw toelatingsdossier in",
    descriptionChoisePath: "Stel je cursussen en cursussen samen en creëer een training met de passerende voorwaarden",
    descriptionChoiseSession: "Stel je training samen met de op tijd ingestelde passagevoorwaarden",
    returnButton: "terugkeren",
    waitingToPayment: "In afwachting van betaling",
    noUnpaidFound: "Geen verwachting van betaling",
    schoolAssistant: "beheerder",
    passwordMandadory1: "Het wachtwoord moet bevatten:",
    passwordMandadory2: "Ten minste 8 tekens",
    passwordMandadory3: "Minimaal één hoofdletter en één kleine letter",
    passwordMandadory4: "Minimaal 1 cijfer",
    passwordMandadory5: "Ten minste één speciaal teken (#?!=@$%^&*-)",
    passConfirmation: "Wachtwoordbevestiging",
    RésultatCumulé: "Cumulatief resultaat",
    etudiantsInscrits: "Geregistreerde studenten",
    TarifActuel: "Huidige prijs",
    TarifMoyen: "Gemiddelde prijs",
    priceHistory: "Beoordeel geschiedenis",
    hidePricehistory: "Tariefgeschiedenis verbergen",
    saveModification: "Wijziging opgeslagen!",
    Total: "Totaal",
    TotalInscrits: "Totaal geregistreerd",
    periodMedian: "Gemiddelde prijs voor de periode",
    totalMedian: "Totale gemiddelde prijs",
    totalResult: "Totale maas in de wet",
    QUESTIONS_OUVERTES: "OPEN VRAGEN",
    notValid: "Niet geldig",
    validAction: "Gevalideerde actie",
    studentResponse: "Reactie van student",
    markUpdate: "modifier ma notatie",
    webinarEnded: "Webinar afgelopen",
    added: "Toegevoegd",
    warning: "Aandacht !",
    changePrice: "Je hebt zojuist de cursusprijs gewijzigd:",
    priceNow: "Huidige prijs:",
    priceAfter: "Nieuwe betrokken prijs:",
    saveUpdate: "WIJZIGING OPSLAAN",
    profchoice: "Selecteer een docent om de cursus(sen) aan toe te wijzen",
    deleteReason: "Reden voor verwijdering*",
    disableAssistant: "Deze beheerder heeft geen toegang meer tot het platform. Je hebt de mogelijkheid om het te herstellen als je van gedachten verandert.",
    addCommentToAssistant: "Voeg een opmerking toe voor de wizard",
    connectAccountPaymee: "Verbind je Paymee-account",
    connect: "AANSLUITEN",
    idPaymee: "ID-betaler",
    assistantInvitation: "Een beheerder uitnodigen om te registreren",
    mailAssistant: "beheerder e-mail",
    membreInvitation: "Nodig een lid uit",
    role: "Rol",
    newCourse: "Nieuwe cursus",
    newPath: "Nieuwe cursus",
    newSession: "Nieuwe sessie",
    profMandadotory: "Deze cursus moet worden toegewezen aan een docent.",
    profMandadtory2: "Selecteer een docent om deze cursus aan toe te wijzen*",
    rehireAssistant: "Deze beheerder heeft toegang tot het platform. Je hebt de mogelijkheid om de beheerder op te schorten als je van gedachten verandert.",
    dragToUpload: "Sleep je foto en zet hem neer",
    or: "OF",
    selectPhoto: "selecteer je foto",
    offerList: "Lijst met aanbiedingen",
    answer: "Uw antwoord",
    completedParts: "onderdelen gedaan",
    followPaths: "Cursus volgen",
    followSessions: "Cursus volgen",
    displayVideo: "Uw presentatie video of visual",
    toValidate: "Valideren",
    archiveSheet: "Archief",
    connectZoom: "Zoom verbinden",
    accountZoom: "zoom-account",
    connectAccount: "Mijn account koppelen",
    connectAccountToschool: "Verbind je zoom-account met je school",
    changeOffer: "Aanbod wijzigen",
    facture: "Facturen",
    paymentMethod: "Betaalmethodes",
    cancelSubscription: "Mijn abonnement opzeggen",
    offerPrice: "Aanbiedingskosten",
    nextDeadline: "Volgende betalingsvervaldag",
    deleteOffer: "Verwijdering van aanbieding",
    deleteMsgOffer: "Wilt u uw aanbieding verwijderen?",
    passwordUpdated: "Uw wachtwoord is veranderd",
    passwordNoMatch: "de twee wachtwoorden komen niet overeen",
    modifyProfil: "Mijn profiel bewerken",
    newEmail: "nieuw email adres",
    connectStripToSchool: "Verbind je Stripe-account met je school",
    disconnect: "verbinding verbreken",
    connectPaymeeToSchool: "Verbind je Paymee-account met je school",
    paymentMessage: "Rond uw aankoop af door uw betalingsgegevens op te geven.",
    paymentConfimation: "bevestig betaling",
    activate: "Inschakelen",
    month: "Maandelijks",
    yearly: "eenjarigen",
    offerMessage1: "Alles inbegrepen in het Basisplan, plus:",
    Choisir: "Uitkiezen",
    compareoffers: "Vergelijk alle functies",
    offer: "Bieden",
    monthlyFor: "Maandelijks voor",
    yearFor: "Eenjarigen voor",
    totalPrice: "Totale prijs:",
    valid: "Geldig",
    ProfList: "Prof lijst",
    StudentList: "Studentenlijst",
    CourseList: "Cursuslijst",
    Inscrits: "Geregistreerd",
    team: "Bemanning",
    ADMINSTRATOR: "beheerder",
    info: "Informatie",
    shortDescription: "korte beschrijving",
    shortDescription1: "Korte beschrijving van",
    nbrConx: "Aantal aansluitingen",
    inscDate: "Datum d'inschrijving",
    role1: "rol",
    totalRevenu: "Totaal inkomen",
    makeAPayment: "Maak een betaling",
    Fileacceptedon: "Bestand geaccepteerd op",
    params: "Instellingen",
    pricipalGroup: "hoofdgroep",
    addPhoto: "voeg je foto toe",
    changePhoto: "Verander je foto",
    deletePhoto: "Foto verwijderen",
    seletionner: "KIES",
    facturationDetails: "facturatie gegevens",
    closedSchool: "Deze school is momenteel gesloten",
    hold: "Alstublieft",
    contact1: "Contact opnemen met ondersteuning",
    contact2: "voor meer informatie.",
    question: "vraag",
    allTeam: "Het hele team",
    assistants: "beheerders",
    noTeam1: "Geen docent/beheerder gevonden.",
    noTeam2: "Zoek opnieuw.",
    noTeamDefault_1: "Er is geen teamlid geregistreerd.",
    noTeamDefault_2: "Nodig een lid uit om zich aan te melden om aan de slag te gaan.",
    noAssistant_1: "Geen beheerders gevonden.",
    noAssistant_2: "Zoek opnieuw.",
    noAssistantDefault_1: "Er is geen beheerder geregistreerd.",
    noAssistantDefault_2: "Nodig een beheerder uit om zich aan te melden om aan de slag te gaan.",
    TrainingToedit: "Bezig",
    webinarForm1: "Selecteer een docent om dit webinar aan toe te wijzen*",
    webinarForm2: "Selecteer een leraar",
    webinarForm3: "Voor cursusstudenten:",
    listeDesAssistants: "lijst van beheerders",
    listeTeam: "teamlijst",
    listOfUnpaidStudents: "lijst van onbetaalde studenten",
    Nounpaidstudent: "Geen onbetaalde student",
    memberMail: "E-mailadres van lid",
    integrations: "Integraties",
    monForfait: "Mijn pakketje",
    Facturation: "Facturering",
    Detailsduforfait: "Pakket details",
    Gerezoumodifiezvotreforfait: "Beheer of wijzig uw abonnement",
    Votreoffre: "Uw aanbod",
    Fraisdeloffre: "Aanbiedingskosten",
    Prochainedatedecheancedupaiement: "Volgende betalingsvervaldag",
    Modedepayement: "Betalingswijze",
    Changerleforfait: "Abonnement wijzigen",
    Annulermonabonnement: "Mijn abonnement opzeggen",
    Limitationduforfait: "Pakketbeperking:",
    Suivrelalimitationdevotreforfaitactuel: "Volg de beperking van uw huidige abonnement",
    Voirlesforfaits: "Zie pakketten",
    Vousavezatteintlalimitedaccesdevos: "U heeft de toegangslimiet van uw",
    Vouspouvezajouterdes: "Je kan toevoegen",
    endesactivantun: "door a . uit te schakelen",
    endesactivantune: "door a . uit te schakelen",
    actifouenameliorantvotreforfait: " door uw abonnement te upgraden",
    activeouenameliorantvotreforfait: "door uw abonnement te upgraden",
    Modesdepayement: "Betaalmethodes",
    Gérezoumodifiezvotremodesdepaiement: "Beheer of wijzig uw betaalmethoden",
    congratsSession: "Gefeliciteerd, je hebt de sessie voltooid.",
    avisTitle1: "Mijn mening over de cursus",
    avisTitle2: "Mijn mening over de sessie",
    FormationAValider: "Geen training om te valideren.",
    FormationEnLigne: "Geen online training.",
    emptyListFormation1: "Zoek opnieuw.",
    noTrainingSearch: "Deze leraar heeft nog geen opleiding genoten.",
    NoWebinarToValidate: "Geen webinar om te valideren.",
    NoWebinarIsComingSoon: "Geen aankomende webinars.",
    NoWebinarIsOnGoing: "Geen lopende webinars.",
    NoWebinarHasEnded: "Geen afgeronde webinars.",
    NoWebinarIsRefused: "Geen webinars geweigerd.",
    NoWebinarIsAssociatedToThisCourse: "Er is geen webinar gekoppeld aan deze cursus.",
    NoWebinarExists: "Geen webinars gevonden.",
    FirstWebinar: "Er zijn nog geen webinars aangemaakt.",
    FirstWebinar2: "Maak uw eerste webinar om aan de slag te gaan.",
    transformKnowledge: "Verander uw kennis in rijkdom",
    addToDescription: "Toevoegen aan beschrijving",
    importListOfStudents: "Importeer de lijst met studenten.*",
    Remplacer: "Vervangen",
    returnToStudentsList: "Terug naar studentenlijst",
    invite: "nodig uit",
    continue: "doorgaan",
    savedUsers: "Ik heb de toestemming van de gebruikers die in dit bestand zijn geregistreerd, in overeenstemming met de wet op de bescherming van persoonsgegevens van het van kracht zijnde land.",
    download: "Downloaden",
    loadInformation: "Uw gegevens worden geladen, een ogenblik geduld...",
    yourAccount: "Van uw account",
    emailSend: "Email verzonden",
    check: "controleren",
    verifyYourEmail: "Controleer je e-mail en open de link die je hebt ontvangen om verder te gaan",
    returnToConnexionPage: "terug naar inlogpagina",
    save: "Opnemen",
    coursResume: "Schrijf de samenvatting van deze cursus, presenteer de belangrijkste punten en toon de toegevoegde waarde voor het lid.",
    coursObjectives: "Maak een lijst van de doelstellingen die tijdens deze cursus moeten worden bereikt.",
    preRequisite: "Voeg de nodige vereisten toe om deze cursus te volgen.",
    skillsToLearn: "Maak een lijst van de vaardigheden die tijdens deze cursus moeten worden verworven in de vorm van trefwoorden.",
    pathResume: "Schrijf een samenvatting van deze cursus, waarin u de belangrijkste punten presenteert en de toegevoegde waarde voor het lid laat zien.",
    pathObjectives: "Maak een lijst van de doelstellingen die tijdens deze cursus moeten worden bereikt.",
    pathPreRequisite: "Voeg de nodige vereisten toe om dit pad te volgen.",
    pathSkillsToLearn: "Maak een lijst van de vaardigheden die tijdens deze cursus moeten worden verworven in de vorm van trefwoorden.",
    sessionResume: "Schrijf de samenvatting van deze sessie, presenteer de belangrijkste punten en laat de toegevoegde waarde voor het lid zien.",
    sessionObjectives: "Maak een lijst van de doelstellingen die tijdens deze sessie moeten worden bereikt.",
    sessionPreRequisite: "Voeg de nodige vereisten toe om deze sessie te volgen.",
    sessionSkillsToLearn: "Maak een lijst van de vaardigheden die tijdens deze sessie moeten worden verworven in de vorm van trefwoorden.",
    timeNotAvailable: "Dit schema is niet beschikbaar, kies een ander schema.",
    annulerImportation: "Importeren annuleren",
    annulerImportationConfirmation: "Weet u zeker dat u het importeren wilt annuleren?.",
    retourImportation: "Terug naar importeren",
    failedRequest: "Verzoek mislukt",
    lowerDeal: "Om over te schakelen naar een lager plan, moet u uw limiet aanpassen aan de limieten van het gekozen plan.",
    activeProfessors: "Actieve docenten",
    activeStudents: "Actieve studenten",
    activeAssistants: "Actieve beheerders",
    professorList: "Lijst van docenten",
    trainingList: "Lijst met cursussen",
    setAsPrincipal: "Instellen als hoofdgerecht?",
    replacePaymentMode: "Hiermee wordt uw huidige primaire betaalmethode vervangen.",
    deletePaymentMethod: "Betaalmethode verwijderen",
    deletePaymentMethodConfirmation: "Weet je zeker dat je de betaalmethode wilt verwijderen.",
    deleteInvitation: "Uitnodiging annuleren",
    invitationAnnulationOfYourSchool: "Als u de uitnodiging annuleert, kan die persoon niet langer lid worden van",
    yourSchool: "jouw school.",
    youCanReinvite: "Je kunt hem altijd terug uitnodigen.",
    keepInvitation: "bewaar de uitnodiging",
    noCourseFound: "Geen cursussen gevonden",
    wantCreateCourse: "Wil je een cursus maken?",
    cannotImportListOfStudents: "Je kunt geen leerlingenlijst importeren die fouten bevat.",
    firstName: "Voornaam",
    obligatoire: "is verplicht",
    name: "De naam",
    emailAddressNotValid: "Ongeldig e-mailadres.",
    emailFormat: "Het formaat van de e-mail moet zijn",
    emailExample: "votrenom@voorbeeld.com",
    importationResult: "Resultaat importeren",
    succesfulImportation: "succesvolle import",
    lignes: "lijnen",
    containErrors: "fouten bevatten",
    addStudent: "Voeg een leerling toe",
    importList: "Een lijst importeren",
    invitationSentSuccesfully: "Uitnodiging succesvol verzonden",
    invitationSuccessAssistant: "Uw uitnodiging is succesvol verzonden naar de beheerder.",
    userAlreadyExists: "De gebruiker met dit e-mailadres bestaat al.",
    enterValidEmail: "Gelieve een geldig e-mailadres in te geven.",
    professorLimitAtteined: "Je hebt de toegangslimiet van je docenten bereikt. Je kunt docenten toevoegen door een actieve docent te deactiveren of je abonnement te upgraden.",
    assistantLimitAtteined: "U heeft de toegangslimiet voor uw beheerders bereikt. U kunt beheerders toevoegen door een actieve beheerder uit te schakelen of uw abonnement te upgraden.",
    compareOffers: "Vergelijk aanbiedingen",
    schoolUses: "Je school gebruikt functies",
    notIncludedInOffer: "die niet worden ondersteund door het aanbod",
    passToOffer: "Als u overstapt op de aanbieding",
    allFonctionalities: ", alle functies",
    areDesactivated: "van uw school worden gedeactiveerd en ontoegankelijk.",
    invitationsSentSuccesfully: "uitnodigingen zijn succesvol verzonden",
    automaticSendOfInvitations: "Automatisch versturen van uitnodigingen",
    invitationSendConfirmation: "Bevestigt u het verzenden van uitnodigingen naar de lijst die u hebt geïmporteerd?",
    confirmAndSend: "Bevestig en verzend",
    principal: "Voornaam",
    noPaymentMethodAdded: "Geen betaalmethode toegevoegd",
    addPaymentMethod: "Voeg een Betalingsmethode toe",
    remainning: "je hebt nog",
    changePaymentMethod: "Verander betaalmethode",
    leaveMsg: "Het spijt ons je te zien gaan!",
    annulationWarning: "Zodra u annuleert, blijft uw account actief totdat uw facturering eindigt op",
    annulationWarning2: "Daarna verlies je de toegang tot je school.",
    annulationConfirmation: "Weet je zeker dat je je abonnement wilt opzeggen?",
    enterPasswordtoConfirm: "Voer uw wachtwoord in om te bevestigen",
    cancelationReasons: "Laat ons weten waarom je je abonnement opzegt. Uw opmerkingen, het zal ons helpen het product te verbeteren!",
    whyuCanceling: "Waarom zeg je je abonnement op?",
    selectLowerCase: "Selecteer",
    missingFonctionality: "Een functie die ik wil, ontbreekt",
    expensive: "Ik vind het te duur",
    notInteressted: "Je n'utilise plus koorts",
    movingToAnotherPlatform: "Ik stap over naar een ander platform",
    preciser: "Specificeer a.u.b.",
    wantToTellUsMore: "Wil je ons meer vertellen?",
    unlimited: "Onbeperkt",
    Cryptogramme: "Cryptogram",
    savePaymentMethod: "Bewaar deze betaalmethode in mijn profiel",
    paymentPassed: "Betaling gedaan",
    paymentSuccesfull: "Uw betaling is succesvol verwerkt.",
    returnToActivity: "Terug naar activiteit",
    twoFreeMonths: "2 maanden gratis",
    fourFreeMonths: "4 maanden gratis",
    essentialTools: "De essentiële tools om aan de slag te gaan, zonder verborgen kosten",
    forever: "voor alle tijden",
    actuelOffer: "Uw huidige aanbod",
    selectFreeOffer: "Kies de gratis aanbieding",
    pathAndSessions: "Routes en sessies",
    forProfessionalsAndNonProfessionals: "Voor professionele trainers en infopreneurs.",
    yearlyBill: "Jaarlijks gefactureerd",
    perMonth: "per maand",
    selectProOffer: "Kies de Pro-aanbieding",
    courses: "Klassen",
    illimitedAccess: "Om onbeperkt te genieten van Koors.",
    perStudent: "per leerling",
    minimum: "minimum",
    selectBussinessOffer: "Kies het Zakelijke aanbod",
    illimitedNumberOfStudents: "Onbeperkt aantal studenten",
    illimitedNumberOfCours: "Onbeperkt aantal lessen",
    illimitedNumberOfProfessors: "Onbeperkt aantal docenten",
    illimitedNumberOfAssistants: "Onbeperkt aantal beheerders",
    subscriptionPayments: "Abonnement betaling",
    Devise: "bedenken",
    FacturationCycle: "Factureringscyclus",
    monthly: "Maandelijks",
    yearly_2: "Jaarlijks",
    ofReduction: "vermindering",
    minimalBill: "Minimale facturering",
    nextBill: "Volgende facturering",
    confirmPayment: "Betalingsbevestiging",
    reductionOnCurrentPlan: "Korting op het jaarabonnement",
    verification: "Verificatie",
    noStudentFound_2: "Geen studenten gevonden",
    archivedTrainings: "Geen training gearchiveerd",
    refusedTrainings: "Geen enkele training geweigerd",
    desactivatedTrainings: "Geen training uitgeschakeld",
    editedTrainings: "Er wordt geen training bewerkt",
    noTrainingHaveBeenCreated: "Er zijn nog geen kazen gemaakt.",
    createFirstTraining: "Maak om te beginnen uw eerste formatie.",
    examsToNote: "Geen examens om te beoordelen.",
    notedExams: "Geen cijfermatig examen.",
    noExam: "Je hebt geen examen.",
    resendInvitation: "Verstuur de uitnodiging opnieuw",
    Copyright: "auteursrechten",
    rights: "Alle rechten voorbehouden.",
    noExamFound: "Geen beoordelingen gevonden.",
    atteinedMaximum: "Je hebt het maximaal toegestane aantal voor een abonnement bereikt",
    updatePlan: "Werk je plan bij om meer formaties te maken.",
    noBill: "Geen factuur.",
    finalExam: "Eindexamen .",
    selectAnImage: "KIES EEN AFBEELDING",
    slideToAddAnImage: "Sleep een foto om het webinar te illustreren",
    yourWebinarsAreIntegrated: "Uw webinars zijn geïntegreerd",
    onThePlatform: "naar het platform met de",
    offreProAndBussiness: "Professionele en zakelijke aanbiedingen",
    createWebinar: "Maak eenvoudig je webinars direct in Koors Select aan en nodig je studenten direct uit om deel te nemen (in aanmerking komende nieuwe studenten worden automatisch uitgenodigd).",
    findReplayAndPublishWebinar: "Vind de herhalingen van je webinars en publiceer ze in je training.",
    improveMyOffer: 'Verbeter mijn aanbod',
    soonWebinarsAvailable: "Binnenkort worden er webinars aangeboden.",
    willInformuSoon: "We houden u op de hoogte.",
    courseResumeIntroduction: "Inleidende samenvatting van de cursus...",
    reprendre: "Hervatten",
    freeOffreNoPaymentMethod: "U heeft geen betaalmethoden. U bent geregistreerd voor het gratis aanbod.",
    Credit: "krediet",
    DebitCard: "Debetkaart",
    offre: "Bieden",
    nameOnCard: "Naam op de kaart",
    traitement: "behandeling...",
    paiement: "Betaling",
    offersList: "Lijst met aanbiedingen",
    perYear: "per jaar",
    wrongBirthDate: "Verkeerde geboortedatum",
    selectCountry: "Kies een land",
    cardAddedSuccessfully: "uw kaart is succesvol toegevoegd",
    returnToParameters: "terug naar parameter",
    addNewPaymentMethod: "Een nieuwe betaalmethode toevoegen",
    verifyPassword: "Verkeerd wachtwoord, wil je je wachtwoord verifiëren",
    returnToOffer: "Terug naar mijn aanbod",
    subscriptionCancelled: "Uw abonnement is succesvol geannuleerd",
    perMonthPayedAnually: "maand, jaarlijks betaald",
    downloadTemplate: "download sjabloon",
    toSee: "excel om een ​​voorbeeldformaat te zien.",
    slideExcel: "Versleep uw Excel-bestand",
    reposition: "HERPLAATSING",
    importNewPhoto: "EEN NIEUWE FOTO IMPORTEREN",
    importPhoto: "EEN FOTO IMPORTEREN",
    slideToSet: "sleep naar positie",
    publishMyOpinion: "Publiceer mijn recensie",
    noWebinarIsPlanned: "Op dit moment zijn er geen webinars gepland",
    account: "account",
    perYearPayedAnually: "jaar, jaarlijks betaald",
    titleSessionPlaceholder: "geef hier de titel van de sessie aan...",
    titlePathPlaceholder: 'geef hier de titel van de cursus aan...',
    addVideoUrl: "Video-URL toevoegen",
    itegrateVideo: "video insluiten",
    enterValidUrl: "Zorg ervoor dat je een geldige URL hebt ingevoerd.",
    pasteVideoUrl: "Videolink plakken...",
    addVideoToIntegrate: "Video-URL toevoegen om in te sluiten",
    creationDate: "Aanmaakdatum",
    createdAt: "Creëerde de",
    generic_decline: "Betaling geweigerd om algemene redenen",
    insufficient_funds: "Kaarttegoed is onvoldoende",
    lost_card: "De kaart is verloren verklaard",
    stolen_card: "De kaart is als gestolen opgegeven",
    expired_card: "De kaart is verlopen",
    incorrect_cvc: "CVC-code is onjuist",
    processing_error: "Betaling geweigerd vanwege verwerkingsfout",
    gender: "Geslacht",
    male: "Man",
    female: "Vrouwen",
    noCommentFound1: "Geen commentaar",
    lastUpdate: "Laatste bericht",
    lignes1: "lijn",
    containErrors1: "bevat fouten",
    emptyTitleError: "Titolo del corso (richiesto)",
    emptyPathTitleError: "Titolo del corso (richiesto)",
    emptySessionTitleError: "Titolo della sessione (richiesto)",
    Webinar1: "Webinars",
    notFound1: "Voeg meer studenten toe en schakel een versnelling hoger ",
    notFound2: "Koors brengt de beste features samen om online cursussen onder zoveel mogelijk mensen te verspreiden",
    notFound3: "Dankzij Koors kun je eenvoudig je studenten-/klantengemeenschappen beheren en altijd contact houden.",
    emailAlreadyUsed: "Dit e-mailadres is deja utilisée",
    Clôturé: "Gesloten",
    Ouvert: "Open",
    TITREdusondage: "Titel van de enquête",
    Auteur: "Auteur",
    CRÉELE: "Gemaakt op",
    CloturéLE: "Gesloten op",
    RÉPONSEs: "Antwoorden",
    Créerunsondage: "Maak een enquête",
    sondages: "Enquêtes",
    sondage: "Enquête",
    inscribedInSchool:"Inschrijving op school",
    catalogVisits:"Bezoeken van cataloguspagina's",
    currentStudentNumber:"Aantal huidige studenten",
    currentStudentsWithDiploma:"Numero di studenti laureati",
    noSchooling:"niet genoemd",
    transcodeInProgress: "Het finaliseren van de import is bezig. U kunt doorgaan met het voltooien van de inhoud.",
}