import { useState, ComponentProps, FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { getCoursesForDirector, getCourse, getCourseTests, getRegistrationForm } from '../../global-state/actions/course-actions';
import { redirect, clearHistoryArray } from '../../global-state/actions/breadcrumb-actions';
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
import FilterAllCoursesComponent from '../filters/filter-all-courses-component';
import { useHistory } from 'react-router';
import Axios from '../../helpers/interceptors';
import { getTrainingById } from '../../global-state/actions/training-actions';
import TableWithExpandElement from '../../elements/table/table-with-expand-element';
import EmptyListElement from '../../elements/empty-list/normal-empty-list-element';
import DefaultTitle from '../../helpers/default-title'
import { returnStartPathUrl } from '../../helpers/domainCheck';
import { log } from 'console';



const TableAllCoursesComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
  const { t } = useTranslation();
  const [atBottom, setAtBottom] = useState<boolean>(false);
  const[all,setAll]=useState<any>()
  const status = [
    // { label: t("Tous"), value: "", color: "" },
    { label: t('Avalider'), value: "Pending", color: "warning" },
    { label: t('Enligne'), value: "PUBLISHED", color: "primary" },
    { label: t('Archive'), value: "Archive", color: "secondary" },
    { label: t('Refuse'), value: "Refused", color: "danger" },
    { label: t('Deactivate'), value: "Deactivate", color: "dark" }
  
  ]
  const [courses, setCourses] = useState<any>();
  const [filteredCourses, setFilteredCourses] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [pageNumber ,setPageNumber] = useState(props?.pageNumberAllCourses);
  const [isFilteredByProf, setIsFilteredByProf] = useState<boolean>(false);
  useEffect(() => {
    setPageNumber(props?.pageNumberAllCourses)
  }, [props?.pageNumberAllCourses]);

  const handleScroll = async () => {    
    if (!(atBottom) && (window.innerHeight + window.scrollY + 1) >= document.documentElement.scrollHeight && props?.allCourses?.length<props?.LengthCourses && !loading) {
        setAtBottom(true);
        let filter = {
          entityId: props?.user?.idSchool,
          entityType: 'SCHOOL',
          pageSize:12,
          pageNumber: pageNumber +1,
        }
      
        await  props?.getCoursesForDirector(filter,'no')
        
        document.removeEventListener('scroll', handleScroll);
    }
    if ((window.innerHeight + window.scrollY) <= document.documentElement.scrollHeight) {
        setAtBottom(false);
    }
}
useEffect(() => {

  setLoading(props?.loadingscroll)


}, [props?.loadingscroll]);

useEffect(() => {
  document.addEventListener('scroll', handleScroll);
  return () => {
      document.removeEventListener('scroll', handleScroll);
  }
})

  const history = useHistory();
  const doAction = async (value: any) => {

    
    if (value.action === 'goToPath') {

      if (value.row.course.type === 'COURSE') {
        await props?.getCourse(value.row.course.id);
        if (!!value.row.course.idRegistration && value.row.course.idRegistration !== 0 ) {
          await props?.getRegistrationForm(value.row.course.idRegistration);
        }
        await props?.getCourseTests(value.row.course.id);
        props?.clearHistoryArray()
        props?.redirect(value.row.course.title, `${returnStartPathUrl()}/all-courses/course/${value.row.course.id}/description`)
        history.push(`${returnStartPathUrl()}/all-courses/course/${value.row.course.id}/description`);
      } else if (value.row.course.type === 'PATH') {
        props?.clearHistoryArray()
        props?.redirect(value.row.course.title, `${returnStartPathUrl()}/all-courses/path/${value.row.course.id}/description`)
        await props?.getTrainingById('paths', value.row.course.id);
        if (!!value.row.course.idRegistration  && value.row.course.idRegistration !== 0) {
          await props?.getRegistrationForm(value?.row?.course?.idRegistration);
        }
         history.push(`${returnStartPathUrl()}/all-courses/path/${value.row.course.id}/description`);
      } else {
        props?.clearHistoryArray()
        props?.redirect(value.row.course.title, `${returnStartPathUrl()}/all-courses/session/${value.row.course.id}/description`)
        await props?.getTrainingById('sessions', value.row.course.id);
         history.push(`${returnStartPathUrl()}/all-courses/session/${value.row.course.id}/description`);
      }
    }
  }
  const doFilter = (filter: any) => {
    if(filter.searchProfessors) {
      if(filter.searchProfessors !== 0) {setIsFilteredByProf(true)} else {setIsFilteredByProf(false)}

  } 
  }

  const headers = [
    { title: t('TitleFormations'), sorted: true, classNames: "w-20 class1", scope: "col", buttonClasses: "d-flex button-trie px-0" },
    { title: t('Professeurs'), sorted: true, classNames: "w-20 class2", scope: "col", buttonClasses: "d-flex button-trie px-0" },
    { title: t('typeTable'), sorted: true, classNames: "w-15 class3", scope: "col", buttonClasses: "d-flex button-trie px-0" },
    { title: t("creationDate"), sorted: true, classNames: "w-15 class4", scope: "col", buttonClasses: "d-flex button-trie px-0 " },
    { title: t('statusFilter'), sorted: true, classNames: "w-15 class5", scope: "col", buttonClasses: "d-flex button-trie px-0 ms-auto" },
    //{ title: t('Actions'), sorted: true, classNames: "w-15 class6", scope: "col", buttonClasses: "d-flex button-trie px-0 ms-auto " },
  ]

  const contentCourseType = (course: any) => {
    if(course.type === 'COURSE') return t('Cours');
    if(course.type === 'PATH') return t('parcoursTitle')
    return t('sessionTitle');
  }


  
  useEffect(() => {        
      Axios.get(`${process.env.REACT_APP_BASE_URL2}person/all/${props?.user?.idSchool}/profs`).then(response => {
  
        const professors: any = []
        response?.data?.forEach((el: any) => {
          professors.push({ label: el.name, value: el.name, id: el.id })
        });
        
        const data = {
          headers: headers,
          body: props?.allCourses?.map((course: any) => {
            const actualStatus: any = status.find(obj => obj?.value?.toLowerCase() === course?.status?.toLowerCase());
            return {
              row: {
                course: course,
                actionName: "",
                classNames: "table-white",
                columns: [
                  { content:<DefaultTitle title={course?.title} type={course?.type}/>, classNames: "w-20 class1", actionName: 'goToPath' },
                  { content: course.personName /*(professors?.find((prof: any) => prof?.id === course.idProf) as any)?.value*/, classNames: "w-20 class2", actionName: 'goToPath' },
                  { 
                    content: contentCourseType(course), 
                    classNames: "w-15 class3", 
                    actionName: 'goToPath' 
                  },
                  { content: course?.creationDate?.substring(0,10).split("-").reverse().join("/"), clasNames: "w-15 class4", actionName: 'goToPath' },
                  { content: t(actualStatus?.label), classNames: `w-10 text-end badge-text ${actualStatus?.color} class5`, actionName: 'goToPath' },
  
                ],
    
              }
            }
          })
        }
            setCourses(data);
            setFilteredCourses(data);
      })
    
  }, [props?.allCourses]);

  return (

  <div className="container-fluid">
    <div className="row justify-content-center">
    {(props?.totalTrainingNbr === 0)?
      <div className="w-100 mt-5 pt-5">
         <EmptyListElement lines={[t('noCoursesCreated')]} />
       </div>
       : 
       <div>
        <div className="col-md-12">
          <FilterAllCoursesComponent doFilter={(filter: any) => doFilter(filter)} pageNumber={pageNumber} />
        </div>
        {filteredCourses?.body?.length===0 ? 
          <div className="w-100 mt-5 pt-5">
            <div className="msg-formation-list-empty">
              {(isFilteredByProf === true)? 
              <EmptyListElement lines={[t("noTrainingSearch"),t("emptyListFormation2")]} />
              : 
              <EmptyListElement lines={[t("emptyListFormation1"),t("emptyListFormation2")]} />
              }
              
            </div>
          </div> : 
          <div>
            <TableWithExpandElement data={filteredCourses} doAction={(value: any) => { doAction(value); } } size={props?.LengthCourses} />
            {(props?.allCourses?.length > 0 && loading) &&
                                    <div className="d-flex w-100 align-items-center justify-content-center">
                                        <div className="m-3">
                                            <svg className="spinner" viewBox="0 0 50 50">
                                                <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                                            </svg>
                                        </div>
                                    </div>
                                }
          </div>}
       </div>
  }        

    </div>
  </div>

  );
}

      

const mapStateToProps = (state: AppState) => ({
  allCourses: state.courseReducer.allCourses,
  user: state.authReducer.connectedUser,
  actualTabAllCourses: state.courseReducer.actualTabAllCourses,
  pageNumber:state.courseReducer.pageNumber,
  LengthCourses :state.courseReducer.LengthCourses,
  loadingscroll :state.courseReducer.loadingAllCoursesScroll,
  pageNumberAllCourses : state.courseReducer.pageNumberAllCourses,
  totalTrainingNbr: state.courseReducer.LengthCourses
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    { redirect, clearHistoryArray, getCoursesForDirector, getCourse, getCourseTests, getRegistrationForm, getTrainingById }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TableAllCoursesComponent);





