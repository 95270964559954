import { useEffect, useState, ComponentProps, FC } from "react"
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import EmptyListElement from "../../elements/empty-list/normal-empty-list-element";
import CardCourseVertical from "./card-course-student/card-course-vertical-component";
import CardCourseHorizontal from "./card-course-student/card-course-horizontal-component";
import CardCourseVerticalLoading from './card-course-student/card-course-vertical-loading-component';
import CardCourseHorizontalLoading from './card-course-student/card-course-horizontal-loading-component';
import { returnStartPathUrl } from "../../helpers/domainCheck";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { connect } from "react-redux";
import { AppState } from "../../global-state/store/root-reducers";
import { getMyFeedback } from '../../global-state/actions/feedback-actions';
import { redirect, clearHistoryArray } from '../../global-state/actions/breadcrumb-actions';
import { clearCurrentCourse, getCurrentCourse, getCurrentTraining } from "../../global-state/actions/student-training-actions";
import StudentTrainingCard from "../training-cards/student-training-card";
import { studentTrainingDetailsBody } from "../../data-structures/interfaces/trainings.interfaces";
import { setOpenChat } from "../../global-state/actions/chat-actions";
import { getApplicationFile, getContent, getCourse, getFollow, getRegistrationForm, getStudentById } from "../../global-state/actions/course-actions";
import { getFollowedTrainings } from "../../services/student.service";

let outsideAtBottom = false;

const StudentListCardComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {

    const history = useHistory();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [loadingScroll, setLoadingScroll] = useState<boolean>(false);
    const [atBottom, setAtBottom] = useState(false);
    outsideAtBottom = atBottom;
    const [isFetched, setFetched] = useState<boolean>(false)
    const [training, setTraining] = useState<any>([])
    const [pageNumber, setPageNumber] = useState(0)
    const [styleCardCourse, setStyleCardCourse] = useState('vertical')



    const fetchCourses = async () => {

        let filter = {
            studentId: props?.userInfo?.idPerson,
            entityId: props?.userInfo?.idSchool,
            entityType: "SCHOOL",
            pageSize: 6,
            pageNumber: pageNumber
        }
        try {

            //let fetchedCourses: never[] = await fetchData('POST', `${process.env.REACT_APP_BASE_URL14}training/filter`, filter)
            let fetchedCourses: studentTrainingDetailsBody[] = await getFollowedTrainings(filter);
            if (fetchedCourses.length < 5) {
                if (fetchedCourses.length > 0) {
                    setTraining([...training, ...fetchedCourses])

                }
                setLoading(false)
                setLoadingScroll(false)
                return;
            }
            setTraining([...training, ...fetchedCourses])
            setLoading(false)
            setLoadingScroll(true)


        } catch (err) {
            setLoadingScroll(false)
            console.log("error", err);
        }
    }

    const fetchFormationData = async () => {
        setFetched(false)
        await fetchCourses()
        let incremPageNumber = pageNumber + 1
        setPageNumber(incremPageNumber)
        setFetched(true)


    }
    useEffect(() => {
        fetchFormationData()
        setFetched(true)
    }, [])



    useEffect(() => {
        document.addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('scroll', handleScroll);
        }
    })

    const handleScroll = async () => {
        if (!(atBottom) && (window.innerHeight + window.scrollY + 1) > document.documentElement.scrollHeight) {
            setAtBottom(true);
            isFetched && loadingScroll && await fetchFormationData()
            document.removeEventListener('scroll', handleScroll);
        }
        if ((window.innerHeight + window.scrollY) <= document.documentElement.scrollHeight) {
            setAtBottom(false);
        }
    }

    const goToMyCourse = async (training: any) => {

        await props?.clearCurrentCourse()
        let status = training?.followUp?.statusList?.find((Status: any) => new Date(Status?.creationDate).getTime() === Math.max(...training?.followUp?.statusList?.map((e: any) => new Date(e.creationDate))))
        if (status?.status === "DRAFT" || status?.status === "CANDIDATE" || status?.status === "REFUSED" || status?.status === "UNPAID") {
            props?.clearHistoryArray()
            await props?.getCourse(Number(training.id));
            props?.redirect(training?.title, `${returnStartPathUrl()}/courses/preview/course/${training?.id}`)
            await props?.getCurrentCourse(training?.followUp?.id)
            history.push(`${returnStartPathUrl()}/courses/preview/course/${training.id}`)

        } else {
            let object = {
                idPerson: props?.userInfo?.idPerson,
                idSubject: training?.id,
                subjectType: training?.type,
            }

            await props?.getMyFeedback(object)
            props?.clearHistoryArray()
            switch (training.type) {
                case "COURSE":
                    await props?.getCurrentCourse(training?.followUp?.id);
                    history.push(`${returnStartPathUrl()}/passCourse/course/${training?.followUp?.id}`);
                    break;
                case "PATH":
                    await props?.getCurrentTraining(training?.followUp?.id);
                    history.push(`${returnStartPathUrl()}/passPath/progressPath/${training?.followUp?.id}`);
                    break;
                case "SESSION":
                    await props?.getCurrentTraining(training?.followUp?.id);
                    history.push(`${returnStartPathUrl()}/passSession/progressSession/${training?.followUp?.id}`);
                    break;
            }

        }

    }

    const gotToTraining= async (selectedTraining:studentTrainingDetailsBody)=>{
        await props?.clearCurrentCourse();
        let object = {
            idPerson: props?.userInfo?.idPerson,
            idSubject: selectedTraining.idFollowUp,
            subjectType: selectedTraining.trainingType,
        }

        await props?.getMyFeedback(object)
        props?.clearHistoryArray()
        
        switch (selectedTraining.trainingType) {
            case "COURSE":
                await props?.getCurrentCourse(selectedTraining.idFollowUp);
                history.push(`${returnStartPathUrl()}/passCourse/course/${selectedTraining.idFollowUp}`);
                break;
            case "PATH":
                await props?.getCurrentTraining(selectedTraining.idFollowUp);
                history.push(`${returnStartPathUrl()}/passPath/progressPath/${selectedTraining.idFollowUp}`);
                break;
            case "SESSION":
                await props?.getCurrentTraining(selectedTraining.idFollowUp);
                history.push(`${returnStartPathUrl()}/passSession/progressSession/${selectedTraining.idFollowUp}`);
                break;
        }
    }

    const goToApplicationFile = async (e: any , selectedTraining:studentTrainingDetailsBody) => {
        e.preventDefault();
        e.stopPropagation();
        props?.clearHistoryArray()
        await props?.getFollow(selectedTraining.idFollowUp);
        await props?.getApplicationFile(props?.connectedUser?.idPerson, selectedTraining.registrationFormId);
        await props?.getRegistrationForm(selectedTraining.registrationFormId);
        await props?.getStudentById(props?.connectedUser?.idPerson);
        selectedTraining.trainingType==="COURSE"?await props?.getCourse(selectedTraining.trainingId):await props?.getContent({ trainingType:selectedTraining.trainingType, trainingId:selectedTraining.trainingId});
        props?.redirect(selectedTraining.trainingName, `${returnStartPathUrl()}/courses/preview/${selectedTraining.trainingType}/${selectedTraining.idFollowUp}`)
        props?.redirect(t('reponseInscrit'), `${returnStartPathUrl()}/courses/registration/${props?.connectedUser?.idPerson}/${selectedTraining.registrationFormId}`)
        history.push(`${returnStartPathUrl()}/courses/registration/${selectedTraining.idFollowUp}/${props?.connectedUser?.idPerson}/${selectedTraining.registrationFormId}`)
    }

    const getPaid=async (e:any , selectedTraining:studentTrainingDetailsBody)=>{
        e.preventDefault();
        e.stopPropagation();
        props?.clearHistoryArray()
        props?.redirect(selectedTraining.trainingName, "")
        props?.redirect(t("paiement"), "")
        history.push(`${returnStartPathUrl()}/courses/payement/${selectedTraining.trainingType}/${selectedTraining.idFollowUp}`)
    }
    const enableChat=async()=>{
        await  props?.setOpenChat(true)
     }

    return (
        <>
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-md-12 text-center  py-5" style={{ backgroundColor: '#FFFFFF' }}>
                        <div style={{ maxWidth: '1200px', margin: 'auto' }}>
                            <h2 className="H2-Headline" style={{ display: 'inline-block' }}>{t('myFormations')}</h2>
                            <div className="d-flex align-items-center" style={{ float: 'right' }}>
                                <span className="material-icons-outlined mt-2" onClick={() => setStyleCardCourse('horizontal')} style={{ cursor: 'pointer', color: `${(styleCardCourse === 'horizontal') ? "#272727" : "#CACBCE"}`, fontSize: 30 }}> view_headline </span>
                                <span className="material-icons-outlined mt-2" onClick={() => setStyleCardCourse('vertical')} style={{ cursor: 'pointer', color: `${(styleCardCourse === 'vertical') ? "#272727" : "#CACBCE"}`, fontSize: 30 }}> apps </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 px-0" style={{ margin: "50px auto", maxWidth: '1200px' }}>
                        {!loading && <div className="d-flex w-100 flex-wrap align-items-center justify-content-between ">
                            {(training?.length === 0) ?
                                <div className="col-md-12 text-center mt-5">
                                    <EmptyListElement lines={[t('noCoursesStudent')]} >
                                        <button type="button" className="btn-Primary large" onClick={() => { history.push(`${returnStartPathUrl()}/catalog`) }}>
                                            {t('discoverCatalog')}
                                        </button>
                                    </EmptyListElement>
                                </div>
                                :
                                <div className={`${(styleCardCourse === "vertical") ? "grid-cards p-0" : "d-flex w-100 flex-wrap align-items-center justify-content-between"}  mx-2`}>
                                    {training?.map((training: any, index: number) => {
                                        //return (styleCardCourse === "vertical") ?
                                        return  <div className={`${(styleCardCourse === "vertical") ? "justify-content-center" : "col-md-12 mb-3"}`}>
                                                {/* <CardCourseVertical
                                                    key={"key " + training?.id}
                                                    training={training}
                                                    onClick={() => { goToMyCourse(training) }}
                                                /> */}
                                                <StudentTrainingCard
                                                    key={"key " + training?.trainingId}
                                                    followedTraining={training}
                                                    direction={styleCardCourse}
                                                    goToTraining={gotToTraining}
                                                    goToApplicationFile={goToApplicationFile}
                                                    getPaid={getPaid}
                                                    enableChat={enableChat}
                                                />
                                            </div> 
                                            // :
                                            // <div className="col-md-12 mb-3">
                                            //     <CardCourseHorizontal key={"key " + training?.id} training={training} onClick={() => { goToMyCourse(training) }} />
                                            // </div>
                                    })}
                                </div>
                            }
                        </div>}

                        {loading && <div className={`${(styleCardCourse === "vertical") ? "grid-cards p-0" : "d-flex w-100 flex-wrap align-items-center justify-content-between"} mx-2`}>
                            {(styleCardCourse === "vertical") ?
                                <>
                                    <div className="mb-3 justify-content-center">
                                        <CardCourseVerticalLoading />
                                    </div>
                                    <div className="mb-3 justify-content-center">
                                        <CardCourseVerticalLoading />
                                    </div>
                                    <div className="mb-3 justify-content-center">
                                        <CardCourseVerticalLoading />
                                    </div>
                                </> :
                                <>
                                    <div className="mb-3 justify-content-center w-100">
                                        <CardCourseHorizontalLoading />
                                    </div>
                                    <div className="mb-3 justify-content-center w-100">
                                        <CardCourseHorizontalLoading />
                                    </div>
                                    <div className="mb-3 justify-content-center w-100">
                                        <CardCourseHorizontalLoading />
                                    </div>
                                </>
                            }
                        </div>}
                        {(loadingScroll && training?.length > 0) &&
                            <div className="d-flex w-100 align-items-center justify-content-center">
                                <div className="m-3">
                                    <svg className="spinner" viewBox="0 0 50 50">
                                        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                                    </svg>
                                </div>
                            </div>}
                    </div>
                </div>
            </div>
        </>

    )
}

const mapStateToProps = (state: AppState, ownProps: any) => ({
    /*myCourses: state.courseReducer.myCourses,
    
    history: ownProps?.history,
    loading: state.courseReducer.loadingMyCourses,
    styleCardCourse: state.courseReducer.styleCardCourse,
    trainingNumber: state.courseReducer.trainingNumber,
    pageNumber: state.courseReducer.pageNumber,
    loadingScrollCourse:state.courseReducer.loadingScrollFollows*/
    connectedUser: state.authReducer.connectedUser,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            clearCurrentCourse,
            //getMyCoursesStudent, 
            //getCourse, 
            getMyFeedback,
            //changeStyleCardCourse, 
            getCurrentCourse,
            getCurrentTraining,
            //setOpenChat 
            redirect, clearHistoryArray,
            setOpenChat,
            getFollow,
            getApplicationFile,
            getRegistrationForm,
            getStudentById,
            getCourse,
            getContent  

        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(StudentListCardComponent);
