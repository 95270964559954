import { useState, useEffect, FC, useMemo, useContext } from 'react';
import SelectElementWithIcon from '../../elements/select/select-element-with-icons';
import { useWindowSize } from "../../helpers/use-window-size-hook";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { logout, getUserById, updateSchoolId } from '../../global-state/actions/user-auth-actions';
import { changeRole, getInscribedSchoolsList, getUserProfileDetails, resendEmail } from '../../global-state/actions/auth-actions';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { AppState } from '../../global-state/store/root-reducers';
import userLogo from './../../styles/imgs/Default_user_icon.svg';
import logoSchool from '../../styles/imgs/koorsK.png';
import visitorIcon from '../../styles/imgs/visitor-icon.png'
import { routesDirector, routesProfessor, routesStudent, routesVisitor, routesDirectorWithoutWebinar, routesProfessorWithoutWebinar, routesStudentWithoutWebinar, routesSchoolAssistant, routesSchoolAssistantWithoutWebinar } from "../../navigations/Routes"
import { getSubscriptions } from '../../global-state/actions/app-container-actions'
import { getActualTab } from '../../global-state/actions/auth-actions';
import { clearHistory } from '../../global-state/actions/breadcrumb-actions';
import {getDomainFromUrl, returnStartPathUrl } from '../../helpers/domainCheck';
import { UserGuideContext } from '../../context/user-guide-context';
import resumeGuide from '../../styles/imgs/user-guide/resume_guide.svg';
import { resumFirstLogin } from '../../global-state/actions/auth-actions'

const NavbarResponsive: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>> = (props: any) => {
    const history = useHistory();
    const { t } = useTranslation();
    const location = useLocation();
    let { width }: any = useWindowSize();
    const [open, setOpen] = useState(true);
    const [menuopen, setMenuopen] = useState(true);
    const [show, setShow] = useState(true);
    const photo = useMemo(() => { return (props?.connectedUserInfo?.photo) ? props?.connectedUserInfo?.photo : userLogo }, [props?.connectedUserInfo])
    let [role, setRole] = useState("")
    let [name, setName] = useState(props?.connectedUserInfo?.photo);
    const [connectedUser, setConnectedUser] = useState<any>(props?.connectedUser);
    const [routes, setRoutes] = useState<any>([]);
    const [sent, setSent] = useState<boolean>(false);
    const [creationDate, setcreationDate] = useState<any>();
    const [mailChecked, setMailChecked] = useState<any>(undefined);
    const [diffDate, setDiffDate] = useState<number>();
    const [mail, setMail] = useState("");
    const [widthData, setWidthData] = useState((connectedUser?.role === 'role_prof') ? 1100 : (connectedUser?.role === 'role_director') ? 1100 : (connectedUser?.role === 'role_adminstrator') ? 1100 : (connectedUser?.role === 'role_student') ? 730 : (connectedUser?.role === 'role_admin') ? 0 : 500);
    const [openListOfSchools, setOpenListOfSchools] = useState<boolean>(false)
    const [listInsribedSchools, setListInsribedSchools] = useState([])

    let schoolInfo = useMemo(() => props?.school?.schoolInfo, [props?.school?.schoolInfo])
    
    
    useEffect(() => {

        if (props?.school != undefined && props?.listOfInscribedSchools != undefined && props?.listOfInscribedSchools.length != 0 && listInsribedSchools != props?.listInsribedSchools) {
            let filtredSchoolList = props?.listOfInscribedSchools?.filter((inscribedSchool: any) => inscribedSchool?.idSchool != schoolInfo?.id)

            setListInsribedSchools(filtredSchoolList)
        } else {
            setListInsribedSchools(props?.listOfInscribedSchools)
        }
    }, [props?.listOfInscribedSchools])
    useEffect(() => {
        (width === undefined) ? width = window.innerWidth : width = undefined;
        setWidthData((connectedUser?.role === 'role_prof') ? 1100 : (connectedUser?.role === 'role_director') ? 1100 : (connectedUser?.role === 'role_adminstrator') ? 1100 : (connectedUser?.role === 'role_student') ? 730 : (connectedUser?.role === 'role_admin') ? 0 : 500)
    }, [])

    useEffect(() => {
        setWidthData((connectedUser?.role === 'role_prof') ? 1100 : (connectedUser?.role === 'role_director') ? 1100 : (connectedUser?.role === 'role_adminstrator') ? 1100 : (connectedUser?.role === 'role_student') ? 730 : (connectedUser?.role === 'role_admin') ? 0 : 500)
    }, [props])

    useEffect(() => {
        if ((width - 210) <= widthData) {
            setShow(false);
        } else {
            setShow(true)
        }
    }, [width])

    useEffect(() => {
        if (props?.connectedUser && props?.connectedUser !=null && connectedUser != undefined && props?.loggedIn==="true") {
            props?.getInscribedSchoolsList(connectedUser?.idPerson)
        }
    }, [connectedUser])


    useEffect(() => {
      if(connectedUser?.idPerson!=props?.schoolInfo?.id){
        props?.updateSchoolId(props?.schoolInfo?.id)
      }
    }, [connectedUser,props?.schoolInfo])
    


    const dateConverter = (startDate: string | number | Date, timeEnd: string | number | Date) => {
        const newStartDate = new Date(startDate);
        const newEndDate = new Date(timeEnd);
        const one_day = 1000 * 60 * 60 * 24;
        let result
        result = Math.ceil((newEndDate.getTime() - newStartDate.getTime()) / one_day)
        if (result < 0) { return 0 }
        return result
    }

    useEffect(() => {

        setMailChecked(props?.userProfileDetails?.isMailChecked)
        setMail(props?.userProfileDetails?.email)
        setcreationDate(props?.userProfileDetails?.creationDate)

        setDiffDate(dateConverter(props?.userProfileDetails?.creationDate, Date.now()))
    }, [props])

    useEffect(() => {
        setConnectedUser(props?.connectedUser);
        if (props?.connectedUser && props?.connectedUser != undefined ) {
            props?.getUserProfileDetails(connectedUser?.id, 'dont');
        }

        if (props?.connectedUser != undefined && props?.connectedUserInfo != undefined) {

            setName(props?.connectedUserInfo?.firstName + " " + props?.connectedUserInfo?.lastName);
            switch (props?.connectedUser?.role) {
                case "role_student":

                    if (props?.subscription?.limitations?.find((obj: any) => obj.key === 'webinar') && props?.subscription?.limitations?.find((obj: any) => obj.key === 'webinar')?.value === "true") {
                        setWidthData(730);
                        setRole(t('Etudiant_1'))
                        setRoutes(routesStudent);
                    } else {
                        setWidthData(730);
                        setRole(t('Etudiant_1'))
                        setRoutes(routesStudentWithoutWebinar);
                    }

                    break;
                case "role_prof":
                    if (props?.subscription?.limitations?.find((obj: any) => obj.key === 'webinar') && props?.subscription?.limitations?.find((obj: any) => obj.key === 'webinar')?.value === "true") {
                        setWidthData(1100);
                        setRole(t('Professeur_1'))
                        setRoutes(routesProfessor);
                    } else {
                        setWidthData(1100);
                        setRole(t('Professeur_1'))
                        setRoutes(routesProfessorWithoutWebinar);
                    }

                    break;
                case "role_director":


                    if (props?.subscription?.limitations?.find((obj: any) => obj.key === 'webinar') && props?.subscription?.limitations?.find((obj: any) => obj.key === 'webinar')?.value === "false") {
                        setWidthData(1100);
                        setRole(t('Directeur_1'))
                        setRoutes(routesDirector);
                    } else {
                        setWidthData(1100);
                        setRole(t('Directeur_1'))
                        setRoutes(routesDirectorWithoutWebinar);
                    }

                    break;
                case "role_adminstrator":
                    if (props?.subscription?.limitations?.find((obj: any) => obj.key === 'webinar') && props?.subscription?.limitations?.find((obj: any) => obj.key === 'webinar')?.value === "true") {
                        setWidthData(1100);
                        setRole(t('schoolAssistant'))
                        setRoutes(routesSchoolAssistant);
                    } else {
                        setWidthData(1100);
                        setRole(t('schoolAssistant'))
                        setRoutes(routesSchoolAssistantWithoutWebinar);
                    }
                    break;
                default:
                    setWidthData(500);
                    setRoutes(routesVisitor);
                    break;
            }
        } else {
            setWidthData(500);
            setRoutes(routesVisitor);
        }
    }, [props?.connectedUser, props?.subscription])

    useEffect(() => {
        setConnectedUser(props?.connectedUser)
        if (props?.connectedUser != undefined && props?.connectedUserInfo != undefined) {
            setName(props?.connectedUserInfo?.firstName + " " + props?.connectedUserInfo?.lastName);
            switch (props?.connectedUser?.role) {
                case "role_student":

                    if (props?.subscription?.limitations?.find((obj: any) => obj.key === 'webinar') && props?.subscription?.limitations?.find((obj: any) => obj.key === 'webinar')?.value === "true") {
                        setWidthData(730);
                        setRole(t('Etudiant_1'))
                        setRoutes(routesStudent);
                    } else {
                        setWidthData(730);
                        setRole(t('Etudiant_1'))
                        setRoutes(routesStudentWithoutWebinar);
                    }

                    break;
                case "role_prof":

                    if (props?.subscription?.limitations?.find((obj: any) => obj.key === 'webinar') && props?.subscription?.limitations?.find((obj: any) => obj.key === 'webinar')?.value === "true") {
                        setWidthData(1100);
                        setRole(t('Professeur_1'))
                        setRoutes(routesProfessor);
                    } else {
                        setWidthData(1100);
                        setRole(t('Professeur_1'))
                        setRoutes(routesProfessorWithoutWebinar);
                    }

                    break;
                case "role_director":
                    if (props?.subscription?.limitations?.find((obj: any) => obj.key === 'webinar') && props?.subscription?.limitations?.find((obj: any) => obj.key === 'webinar')?.value === "true") {
                        setWidthData(1100);
                        setRole(t('Directeur_1'))
                        setRoutes(routesDirector);
                    } else {
                        setWidthData(1100);
                        setRole(t('Directeur_1'))
                        setRoutes(routesDirectorWithoutWebinar);
                    }
                    break;
                case "role_adminstrator":
                    if (props?.subscription?.limitations?.find((obj: any) => obj.key === 'webinar') && props?.subscription?.limitations?.find((obj: any) => obj.key === 'webinar')?.value === "true") {
                        setWidthData(1100);
                        setRole(t('schoolAssistant'))
                        setRoutes(routesSchoolAssistant);
                    } else {
                        setWidthData(1100);
                        setRole(t('schoolAssistant'))
                        setRoutes(routesSchoolAssistantWithoutWebinar);
                    }

                    break;
                default:
                    setWidthData(500);
                    setRoutes(routesVisitor);
                    break;
            }
        } else {
            setWidthData(500);
            setRoutes(routesVisitor);
        }
    }, [props?.connectedUserInfo, props?.subscription])

    const deconnexion = async () => {
        try {
            await props?.logout();
            setOpen(true);
            const startPath = returnStartPathUrl();
            
            if (!history.location.pathname.endsWith('/catalog')) {
                history.push({ pathname: `${startPath}/auth/login` });
            }
        } catch (error) {
            console.error("Logout or redirection error:", error);
        }
    };
    

    const openCloseUserInfo = () => {
        setOpen(!open);
    }

    const menuResponsive = () => {
        setMenuopen(!menuopen);
    }
    
    const setActualTab = async (actualTab: any) => {
        await props?.getActualTab(actualTab);
        history.push(`${returnStartPathUrl()}/myaccount/info`)
    }

    const changeProfile = async (roles: any) => {

        await props?.changeRole(roles, props?.connectedUser);
        await props?.getUserById(props?.connectedUser?.id);
        await props?.clearHistory()
        // history.push(`${returnStartPathUrl()}/`)
        //history.push({ pathname: `${returnStartPathUrl()}/loading`, state: undefined });
        await history.push({ pathname: `${returnStartPathUrl()}/`, state: undefined });
    }

    const resendEmail = async () => {
        const data = await props?.resendEmail(props?.userProfileDetails?.email, getDomainFromUrl())

        if (data.data.data) {
            setSent(true)
        }
    }

    const goToSelectedSchool = (domain: string) => {

        //switch here
        window.location.href=domain
    }

    const goToAddSchool = () => {
        window.location.href = "http://koors.io"
    }

    const userGuideContext = useContext(UserGuideContext);
    const { userGuideState, setUserGuideState }: any = userGuideContext

 

    // useEffect(() => {        
    //     if (props?.connectedUser?.role == "role_director" && Array.isArray(props.contract) && props.contract.length === 0) {
    //         history.push({ pathname: `${returnStartPathUrl()}/offers/list`, state: undefined });
    //     }
    // }, [props.contract])
    useEffect(() => {
    setOpen(userGuideState.isOpenCloseUserInfo)
    }, [userGuideState.isOpenCloseUserInfo])

    const generateIdNavbarForUserGuide = (label: any) => {
        return {
            [label]: () => {
                if(props?.connectedUser?.role === "role_director") return `step-${label}-navbar_director`
                if(props?.connectedUser?.role === "role_prof") return `step-${label}-navbar_prof`
                return ''
            }
        }
    }

    const handleResumeGuide = () => {
        setOpen(!open);
        setUserGuideState((prev: any) => ({
            ...prev,
            isOpenCloseUserInfo: true,
            run: false,
            displayInitModal: true,
            endOfSteps: false,
            startGuide: false,
            stepIndex: 0,
            isIncrement: 0,
            resumeGuide: true
        }))

        history.push(`${returnStartPathUrl()}/activity`);

        props.resumFirstLogin()
    }

    return (
        <>
            {(mailChecked !== undefined && mailChecked === false) && (props?.connectedUser?.role == "role_director") && <div className='d-flex flex-row  d-flex align-items-center justify-content-between notif-warning' style={{ height: "68px" }}>
                {(Number(diffDate) <= 7) ? <div className='body-md'>
                    Veuillez confirmer votre adresse e-mail pour valider votre compte. Nous avons envoyé un email de confirmation à <span className='body-md bold'>{mail}.</span>
                </div> :
                    <div className='body-md' >
                        Veuillez valider votre adresse e-mail le plus tôt possible, sinon votre compte sera suspendu.
                    </div>}
                {!sent ? <div className="Button-md buttonmail" onClick={resendEmail}>
                    renvoyer la confirmation
                </div> :
                    <div className="Button-md buttonmail2">
                        email envoyé
                    </div>
                }
            </div>}
            <div onClick={() => openCloseUserInfo()} className={` navbar-submenu-list-background ${(open === true) ? "close" : ""}`} />
            <div onClick={() => menuResponsive()} className={` navbar-submenu-list-background ${(menuopen === true) ? "close" : ""}`} />
            <nav 
                className={`navbar navbar-expand-lg px-0 py-3 main-navbar mx-auto w-100 ${(show) ? '' : 'main-navbar-responsive'}`} 
                style={{ backgroundColor: `${props.userGuide.run ? 'rgba(125, 125, 126, 0.0)' : ''}`, boxShadow: `${props.userGuide.run ? 'none' : ''}`}}    
            >
                <div className={` navbar-content ${(show) ? '' : 'responsive'} w-100`}>
                    <div className="navbar-brand d-flex flex-row">

                        <a className='' onClick={() => { 
                            if(props?.connectedUser?.role === "role_director" || props?.connectedUser?.role === "role_prof"){
                                history.push(`${returnStartPathUrl()}/activity`) 
                            }
                            else{
                                history.push(`${returnStartPathUrl()}/catalog`) 
                            }
                        
                        }}>
                            <img style={{ maxWidth: '210px', maxHeight: '48px' ,objectFit: 'fill'  }} src={(props?.school.schoolInfo?.logo === null) ? logoSchool : props?.school.schoolInfo?.logo} alt="school img" />
                        </a>
                    </div>

                    {!show && <>
                        <div className="d-flex justify-content-end">
                            <input dir="ltr" type="checkbox" id="hamburgerButton" onClick={() => menuResponsive()} />
                            <label htmlFor="hamburgerButton" className="hamburger">
                                <span className="bar"></span>
                                <span className="bar"></span>
                                <span className="bar"></span>
                            </label>
                            <label htmlFor="hamburgerButton" className="close-hamburger">
                                <span className="material-icons-outlined" style={{ fontSize: '30px' }}>close</span>
                            </label>
                        </div></>}
                    {!show && <>
                        <div className={` navbar-links-resposive px-4 ${(menuopen === true) ? 'hide' : ''}`}>
                            <div className="navbar-nav flex-column navbar-liste-btn-responsive px-3">
                                {routes.map((item: any) => {
                                    return <>
                                        <Link key={"key "+item.path} to={item.path} className={`d-flex flex-row nav-btn-white${(location.pathname.includes(item.path)) ? "-active" : ""} my-1 `}>
                                            {t('' + item.label)}
                                            {(item.label == "Webinar1" && props?.subscription?.limitations?.find((obj: any) => obj.key === 'webinar') && props?.subscription?.limitations?.find((obj: any) => obj.key === 'webinar')?.value === "false") && <span className="material-icons align-self-center" style={{ color: "#F5B95F" }}>upgrade</span>}
                                            {(item.label == "Coupons" && props?.subscription?.limitations?.find((obj: any) => obj.key === 'coupon') && props?.subscription?.limitations?.find((obj: any) => obj.key === 'coupon')?.value === "false") && <span className="material-icons" style={{ color: "#F5B95F" }}>upgrade</span>}</Link>
                                    </>
                                })}
                            </div>
               
                            {connectedUser ? <div className="px-3 d-flex flex-column">
                                <hr className="hr-nav" />
                                <div className={`d-flex justify-content-between navbar-user flex-wrap ${(menuopen === true) ? 'hide' : ''}`} >
                                    <div className="d-flex align-items-lg-center mt-lg-0 navbar-user-info mb-2">
                                        <div className="d-flex justify-content-end float-end align-items-center navbar-user-contents">
                                            <div className="pe-0">
                                                <img className="img-profil main responsive" src={(photo === null) ? userLogo : photo} alt="profile img" />
                                            </div>
                                            <div className="d-flex flex-column ms-2 user-nabvar">
                                                <span className="user-navbar-name">{name}</span>
                                                <span className="body-sm-noir user-navbar-statu" >{role}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <div>
                                            <SelectElementWithIcon />
                                        </div>
                                    </div>
                                </div>
                                <div className="navbar-nav flex-column navbar-liste-btn-responsive mt-2 mb-3">

                                {/* <span className="body-md bold neutral-3" style={{marginLeft:"15px"}}>Utiliser {props?.schoolInfo?.name} en tant que :</span> */}


                                    {props?.connectedUser?.possibleRoles?.map((roles: any, index: number) => {
                                        return <div key={"key "+index}>  {(roles == "role_director") && (roles != props?.connectedUser?.role) && <div className="d-flex align-items-lg-center mt-lg-0 navbar-user-info p-2 navbar-submenu-list-button" onClick={() => { changeProfile(roles) }}>
                                            <div className="d-flex flex-row justify-content-between align-items-center w-100">
                                                <div className="d-flex align-items-lg-center mt-lg-0 navbar-user-info">
                                                    <div className="d-flex justify-content-end float-end align-items-center navbar-user-contents">
                                                        <div className="me-2 me-1">
                                                            <img className="img-profil small" src={photo} alt="profile img" />

                                                        </div>
                                                        <div className="d-flex flex-column user-nabvar">
                                                            <span className="body-sm bold black-800">{name}</span>

                                                            <span className="body-sm bold neutral-3" >{t('Directeur_1')}</span>


                                                        </div>
                                                    </div>
                                                </div>
                                                <span className="material-icons-outlined neutral-3"> swap_horiz</span>
                                            </div>
                                        </div>}
                                            {(roles == "role_prof") && (roles != props?.connectedUser?.role) && <div className="d-flex align-items-lg-center mt-lg-0 navbar-user-info p-2 navbar-submenu-list-button" onClick={() => { changeProfile(roles); }}>
                                                <div className="d-flex flex-row justify-content-between align-items-center w-100">
                                                    <div className="d-flex align-items-lg-center mt-lg-0 navbar-user-info">
                                                        <div className="d-flex justify-content-end float-end align-items-center navbar-user-contents">
                                                            <div className="me-2 me-1">
                                                                <img className="img-profil small" src={(props?.connectedUserInfo?.photo === null) ? userLogo : props?.connectedUserInfo?.photo} alt="profile img" />

                                                            </div>
                                                            <div className="d-flex flex-column user-nabvar">
                                                                <span className="body-sm bold black-800">{name}</span>

                                                                <span className="body-sm bold neutral-3" >{t('Professeur_1')}</span>


                                                            </div>
                                                        </div>
                                                    </div>
                                                    <span className="material-icons-outlined neutral-3"> swap_horiz</span>
                                                </div>
                                            </div>}
                                            {(roles == "role_student") && (roles != props?.connectedUser?.role) && <div className="d-flex align-items-lg-center mt-lg-0 navbar-user-info p-2 navbar-submenu-list-button" onClick={() => { changeProfile(roles) }}>
                                                <div className="d-flex flex-row justify-content-between align-items-center w-100">
                                                    <div className="d-flex align-items-lg-center mt-lg-0 navbar-user-info">
                                                        <div className="d-flex justify-content-end float-end align-items-center navbar-user-contents">
                                                            <div className="me-2 me-1">
                                                                <img className="img-profil small" src={(props?.connectedUserInfo?.photo === null) ? userLogo : props?.connectedUserInfo?.photo} alt="profile img" />

                                                            </div>
                                                            <div className="d-flex flex-column user-nabvar">
                                                                <span className="body-sm bold black-800">{name}</span>

                                                                <span className="body-sm bold neutral-3" >{t('Etudiant_1')}</span>


                                                            </div>
                                                        </div>
                                                    </div>
                                                    <span className="material-icons-outlined neutral-3"> swap_horiz</span>
                                                </div>
                                            </div>}
                                        </div>
                                    })}


                                    <hr className="neutral-4 mb-2 mt-0" />
                                    <div className="d-flex flex-column me-2 user-nabvar pointer pt-3 " >

                                        <div className='d-flex flex-row justify-content-between ' onClick={() => setOpenListOfSchools(!openListOfSchools)}>
                                            <span className="body-sm bold neutral-3" style={{marginLeft:"15px"}}>Afficher vos écoles</span>
                                            {openListOfSchools === true ? <span className="material-icons-outlined neutral-3" > expand_less </span> : <span className="material-icons-outlined neutral-3"> expand_more </span>}
                                        </div>
                                    </div>
                                    <div className={`${listInsribedSchools?.length === 0 ? "pt-3": ""}  navbar-submenu-list2 ${(openListOfSchools === false) ? "close" : ""}`}>
                                        {listInsribedSchools?.map((school: any, index: number) => {
                                            return <div key={"key "+school?.idSchool}>

                                                <div className="py-2 px-3 navbar-submenu-list-button " onClick={() => { goToSelectedSchool(school?.schoolDomain) }} >
                                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                                        <div className="d-flex align-items-lg-center mt-lg-0 navbar-user-info">
                                                            <div className="d-flex justify-content-end float-end align-items-center navbar-user-contents">
                                                                <div className="me-2 me-1">
                                                                    <img className="img-profil small" src={(school?.schoolLogo?.length === 0 || school?.schoolLogo === null) ? logoSchool : school?.schoolLogo} alt="profile img" />
                                                                </div>
                                                                <div className="d-flex flex-column user-nabvar">
                                                                    <span className="body-sm bold black-800 hide-rest-txt">{school?.schoolName}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {(school?.idSchool !== props?.schoolInfo?.id) && <span className="material-icons-outlined neutral-3"> swap_horiz</span>}
                                                    </div>

                                                </div>
                                            </div>
                                        })}
                                        <div className='d-flex justify-content-between align-items-center' style={{ paddingLeft: "16px"}}>
                                            <button type="button" className="btn-Primary medium icon-left `" onClick={() => { goToAddSchool() }}  >
                                                <div className='d-flex align-items-center'>
                                                    <span className="material-icons" style={{ marginRight: '8px' }}>add</span>Créer une école
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                    <hr className="neutral-4 mb-2 mt-3" />
                                    <a style={{ cursor: 'pointer' }} className="navbar-submenu-list-button d-flex align-items-center p-2 px-3" onClick={() => { setActualTab("Account") }}>
                                        <span className="material-icons-outlined">
                                            person_outline
                                        </span>
                                        <span className="body-md bold ms-2">{t('myAccount')}</span>
                                    </a>
                                    {(props?.connectedUser?.role === "role_director" || props?.connectedUser?.role === "role_prof") && <a style={{ cursor: 'pointer' }} className="navbar-submenu-list-button d-flex align-items-center p-2 px-3" onClick={() => { history.push(`${returnStartPathUrl()}/param`) }}>
                                        <span className="material-icons-outlined">
                                            settings
                                        </span>
                                        <span className="body-md bold ms-2">{t('params')}</span>
                                    </a>}

                                    <a style={{ cursor: 'pointer' }} className="navbar-submenu-list-button d-flex align-items-center p-2 px-3" onClick={deconnexion}>
                                        <span className="material-icons-outlined">
                                            power_settings_new
                                        </span>
                                        <span className="body-md bold ms-2">{t('logout')}</span>
                                    </a>
                                </div>
                            </div> :
                                <div className="px-3 d-flex flex-column">
                                    <hr className="hr-nav" />
                                    <div className={`d-flex justify-content-between navbar-user flex-wrap ${(menuopen === true) ? 'hide' : ''}`} >
                                        <div onClick={() => { history.push(`${returnStartPathUrl()}/auth/login`) }} className="d-flex align-items-lg-center mt-lg-0 navbar-user-info mb-2">
                                            <div className="d-flex justify-content-end float-end align-items-center navbar-user-contents">
                                                <div className="pe-0">
                                                    <img className="img-profil main responsive" src={visitorIcon} alt="profile img" />
                                                </div>
                                                <div className="d-flex flex-column ms-2 user-nabvar">
                                                    <span className="user-navbar-name">{t('connexionButton')}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column">
                                            <div>
                                                <SelectElementWithIcon />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>
                    </>}

                    <div className={`d-flex justify-content-center navbar-liste px-4 ${(show) ? '' : 'hide'}`}>
                        <div className="navbar-nav flex-row navbar-liste-btn mx-lg-auto" style={{ maxHeight: '36px' }}>
                            {routes.map((item: any) => {
                                return <Link key={"key "+item.path} to={item.path} className={`${item.label} d-flex flex-row nav-btn-white${(location.pathname.includes(item.path)) ? "-active" : ""}  mx-1`}>
                                        <span id={generateIdNavbarForUserGuide(item.label)[item.label]()}>{t(item.label)}</span>
                                        {(item.label == "Webinar1" && props?.subscription?.limitations?.find((obj: any) => obj.key === 'webinar') && props?.subscription?.limitations?.find((obj: any) => obj.key === 'webinar')?.value === "false") && <span className="material-icons" style={{ color: "#F5B95F" }}>upgrade</span>}
                                        {(item.label == "Coupons" && props?.subscription?.limitations?.find((obj: any) => obj.key === 'coupon') && props?.subscription?.limitations?.find((obj: any) => obj.key === 'coupon')?.value === "false") && <span className="material-icons" style={{ color: "#F5B95F" }}>upgrade</span>}
                                    </Link>
                            })}
                                {/* return (
                                    <Link 
                                        key={item.path} 
                                        to={item.path} 
                                        className={`${item.label} d-flex flex-row nav-btn-white${(location.pathname.includes(item.path)) ? "-active" : ""}  mx-1`}
                                        style={{backgroundColor: `${location.pathname.includes(item.path) ? '' : 'rgba(125, 125, 126, 0.0)'}`}}
                                    >
                                        <span 
                                            id={generateIdNavbarForUserGuide(item.label)[item.label]()}>{t(item.label)}</span>
                                        {(item.label == "Webinar1" && props?.subscription?.limitations?.find((obj: any) => obj.key === 'webinar') && props?.subscription?.limitations?.find((obj: any) => obj.key === 'webinar')?.value === "false") && <span className="material-icons" style={{ color: "#F5B95F" }}>upgrade</span>}
                                        {(item.label == "Coupons" && props?.subscription?.limitations?.find((obj: any) => obj.key === 'coupon') && props?.subscription?.limitations?.find((obj: any) => obj.key === 'coupon')?.value === "false") && <span className="material-icons" style={{ color: "#F5B95F" }}>upgrade</span>}
                                    </Link>
                            )})} */}

                        </div>
                    </div>
                    <div className={`d-flex justify-content-end float-end navbar-user ${(show) ? '' : 'hide'}`} >
                        {connectedUser ? <>
                            <div onClick={() => openCloseUserInfo()} className="d-flex align-items-lg-center mt-lg-0 navbar-user-info" id='step-change-role_director'>
                               <div className="d-flex justify-content-end float-end align-items-center navbar-user-contents">
                                    <div className="d-flex flex-column me-2 user-nabvar">
                                        <span className="user-navbar-name">{name}</span>
                                        <span className="body-sm-noir user-navbar-statu" style={{ textAlign: 'right' }}>{role}</span>
                                    </div>
                                    <div className="pe-0">
                                        <img className="img-profil main" src={(photo === null) ? userLogo : photo} alt="profile img" />
                                    </div>
                                </div>
                            </div>
                            <div className={` py-3 navbar-submenu-list ${(open === true) ? "close" : ""}`} id="step-go-to-professor-from-director">
                                <div className="d-flex flex-column">
                                {/* <span className="body-sm bold neutral-3" style={{marginLeft:"20px"}}>Utiliser {props?.schoolInfo?.name} en tant que :</span> */}

                                    {props?.connectedUser?.possibleRoles?.map((roles: any, index: number) => {
                                        return <div key={"key "+index}>
                                            {(roles === "role_director") && (roles != props?.connectedUser?.role) && <div className="py-2 px-3 navbar-submenu-list-button" onClick={() => { changeProfile(roles) }}>
                                                <div className="d-flex flex-row justify-content-between align-items-center">
                                                    <div className="d-flex align-items-lg-center mt-lg-0 navbar-user-info">
                                                        <div className="d-flex justify-content-end float-end align-items-center navbar-user-contents">
                                                            <div className="me-2 me-1">
                                                                <img className="img-profil small" src={(props?.connectedUserInfo?.photo === null) ? userLogo : props?.connectedUserInfo?.photo} alt="profile img" />
                                                            </div>
                                                            <div className="d-flex flex-column user-nabvar">
                                                                <span className="body-sm bold black-800">{name}</span>

                                                                <span className="body-sm bold neutral-3" >{t('Directeur_1')}</span>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <span className="material-icons-outlined neutral-3"> swap_horiz</span>
                                                </div>

                                            </div>}
                                            {(roles === "role_prof") && (roles != props?.connectedUser?.role) && <div className="py-2 px-3 navbar-submenu-list-button" id='go-to-prof-role' onClick={() => { changeProfile(roles) }}>
                                                <div className="d-flex flex-row justify-content-between align-items-center">
                                                    <div className="d-flex align-items-lg-center mt-lg-0 navbar-user-info">
                                                        <div className="d-flex justify-content-end float-end align-items-center navbar-user-contents">
                                                            <div className="me-2 me-1">
                                                                <img className="img-profil small" src={(props?.connectedUserInfo?.photo === null) ? userLogo : props?.connectedUserInfo?.photo} alt="profile img" />
                                                            </div>
                                                            <div className="d-flex flex-column user-nabvar">
                                                                <span className="body-sm bold black-800 hide-rest-txt">{name}</span>

                                                                <span className="body-sm bold neutral-3" >{t('Professeur_1')}</span>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <span className="material-icons-outlined neutral-3"> swap_horiz</span>
                                                </div>

                                            </div>}

                                            {(roles === "role_adminstrator") && (roles != props?.connectedUser?.role) && (props?.connectedUser?.possibleRoles?.findIndex((role: any) => role === "role_director") === -1) && <div className="py-2 px-3 navbar-submenu-list-button " onClick={() => { changeProfile(roles) }}>
                                                <div className="d-flex flex-row justify-content-between align-items-center">
                                                    <div className="d-flex align-items-lg-center mt-lg-0 navbar-user-info">
                                                        <div className="d-flex justify-content-end float-end align-items-center navbar-user-contents">
                                                            <div className="me-2 me-1">
                                                                <img className="img-profil small" src={(props?.connectedUserInfo?.photo === null) ? userLogo : props?.connectedUserInfo?.photo} alt="profile img" />
                                                            </div>
                                                            <div className="d-flex flex-column user-nabvar">
                                                                <span className="body-sm bold black-800">{name}</span>

                                                                <span className="body-sm bold neutral-3" >{t('schoolAssistant')}</span>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <span className="material-icons-outlined neutral-3"> swap_horiz</span>
                                                </div>

                                            </div>}

                                            {(roles === "role_student") && (roles != props?.connectedUser?.role) && <div className="py-2 px-3 navbar-submenu-list-button " onClick={() => { changeProfile(roles) }}>
                                                <div className="d-flex flex-row justify-content-between align-items-center">
                                                    <div className="d-flex align-items-lg-center mt-lg-0 navbar-user-info">
                                                        <div className="d-flex justify-content-end float-end align-items-center navbar-user-contents">
                                                            <div className="me-2 me-1">
                                                                <img className="img-profil small" src={(props?.connectedUserInfo?.photo === null) ? userLogo : props?.connectedUserInfo?.photo} alt="profile img" />
                                                            </div>
                                                            <div className="d-flex flex-column user-nabvar">
                                                                <span className="body-sm bold black-800 hide-rest-txt">{name}</span>

                                                                <span className="body-sm bold neutral-3" >{t('Etudiant_1')}</span>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <span className="material-icons-outlined neutral-3"> swap_horiz</span>
                                                </div>

                                            </div>}
                                        </div>
                                    })}

                                    <hr className="neutral-4 mb-2 mt-0" />
                                    <div className="d-flex flex-column me-3 user-nabvar pt-2" style={{ paddingLeft: "20px" }}>

                                        <div className='d-flex flex-row justify-content-between' onClick={() => setOpenListOfSchools(!openListOfSchools)}>
                                            <span className="body-sm bold neutral-3 user-navbar-statu d-flex justify-content-center align-item-center align-text-center " style={{ paddingRight: "45px", alignItems: "center" }}>Afficher vos écoles</span>
                                            {openListOfSchools === true ? <span className="material-icons-outlined neutral-3" > expand_less </span> : <span className="material-icons-outlined neutral-3"> expand_more </span>}
                                        </div>
                                    </div>
                                    <div className={`  navbar-submenu-list2 ${(openListOfSchools === false) ? "close" : ""}`}>

                                        {listInsribedSchools?.map((school: any, index: number) => {
                                            return <div key={"key "+school?.idSchool}>

                                                <div className="py-2 px-3 navbar-submenu-list-button " onClick={() => { goToSelectedSchool(school?.schoolDomain) }} >
                                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                                        <div className="d-flex align-items-lg-center mt-lg-0 navbar-user-info">
                                                            <div className="d-flex justify-content-end float-end align-items-center navbar-user-contents">
                                                                <div className="me-2 me-1">
                                                                    <img className="img-profil small" src={(school?.schoolLogo?.length === 0 || school?.schoolLogo === null) ? logoSchool : school?.schoolLogo} alt="profile img" />
                                                                </div>
                                                                <div className="d-flex flex-column user-nabvar">
                                                                    <span className="body-sm bold black-800 hide-rest-txt">{school?.schoolName}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {(school?.idSchool !== props?.schoolInfo?.id) && <span className="material-icons-outlined neutral-3"> swap_horiz</span>}
                                                    </div>

                                                </div>
                                            </div>
                                        })}

                                        <div className='d-flex justify-content-between align-items-center' style={{ paddingLeft: "16px", paddingTop: "21px" }}>
                                            <button type="button" className="btn-Primary medium icon-left `" onClick={() => { goToAddSchool() }}  >
                                                <div className='d-flex align-items-center'>
                                                    <span className="material-icons" style={{ marginRight: '8px' }}>add</span>Créer une école
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                    <hr className="neutral-4 mb-2 mt-3" />
                                    <a style={{ cursor: 'pointer' }} className="navbar-submenu-list-button d-flex align-items-center p-2 px-3" onClick={() => { setActualTab("Account") }}>
                                        <span className="material-icons-outlined">
                                            person_outline
                                        </span>
                                        <span className="body-md bold ms-2">{t('myAccount')}</span>
                                    </a>
                                    {
                                        (props?.connectedUser?.role === "role_director" || props?.connectedUser?.role === "role_prof") && <a style={{ cursor: 'pointer' }} className="navbar-submenu-list-button d-flex align-items-center p-2 px-3" onClick={() => { history.push(`${returnStartPathUrl()}/param`) }}>
                                            <span className="material-icons-outlined"> settings </span> <span className="body-md bold ms-2">{t('params')}</span> </a>
                                    }
                                    {props?.connectedUser?.role == "role_director" && <a className='navbar-submenu-list-button d-flex align-items-center p-2 px-3' onClick={() => handleResumeGuide()}>
                                        <span style={{ marginLeft: '4px', marginRight: '3px' }}><img src={resumeGuide} alt="" /></span> <span className='body-md bold ms-2'>Guide d'utilisation</span>
                                    </a>}
                                    <a style={{ cursor: 'pointer' }} className="navbar-submenu-list-button d-flex align-items-center p-2 px-3" onClick={deconnexion}>
                                        <span className="material-icons-outlined">
                                            power_settings_new
                                        </span>
                                        <span className="body-md bold ms-2">{t('logout')}</span>
                                    </a>
                                </div>
                                <div className="d-flex flex-column px-3 pt-2">
                                    <div>
                                        <span className="body-sm bold badge-text secondary d-flex mb-2">{t('language')}</span>
                                        <SelectElementWithIcon />
                                    </div>
                                </div>
                            </div>
                        </> :
                            <div className="d-flex align-items-lg-center mt-lg-0 navbar-user-info">
                                <div onClick={() => { history.push(`${returnStartPathUrl()}/auth/login`) }} className="d-flex justify-content-end float-end align-items-center navbar-user-contents">
                                    <div className="d-flex flex-column me-2 user-nabvar">
                                        <span className="user-navbar-name">{t('connexionButton')}</span>
                                    </div>
                                    <div className="pe-0">
                                        <img className="img-profil main" src={visitorIcon} alt="profile img" />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </nav>
        </>

    );
}
const mapStateToProps = (state: AppState) => ({
    connectedUser: state.authReducer.connectedUser,
    connectedUserInfo: state.authReducer.connectedUserInfo,
    userProfileDetails: state.authReducer.connectedUserInfo,
    school: state.schoolInfoReducer,
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    subscription: state.schoolInfoReducer.subscription,
    listOfInscribedSchools: state.authReducer.listOfInscribedSchools,
    userGuide: state.userGuideReducer.userGuide,
    loggedIn:state.authReducer.loggedIn,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>

    bindActionCreators(
        {
            changeRole,
            getUserProfileDetails,
            resendEmail,
            logout,
            getUserById,
            getSubscriptions,
            getActualTab,
            clearHistory,
            getInscribedSchoolsList,
            updateSchoolId,
            resumFirstLogin
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(NavbarResponsive);

