import { FC } from "react";
import PrivateRoute from "../../../navigations/PrivateRoute";
import NavbarCourseComponent from "../../../components/navigation-components/navbar-course-component";
import DescriptionTrainingPage from "./description-training-page";
import ContentTrainingPage from "./content-training-page";
import AdmissionTrainingPage from "./admission-training-page";
import StudentsCoursePage from "./students-course-page"; 
import CustomSwitch from "../../../helpers/custom-switch";
import TarificationTrainingPage from "./tarification-training-page"
import previewCoursePage from "../../catalog/preview-course-page";
const TrainingDetailsContainer: FC<any> = (props: any) => {
  return (<>

    <NavbarCourseComponent type={'course'} />
      <CustomSwitch>
         {/* <PrivateRoute exact={true} path={`${props?.match.path}/description`} component={DescriptionTrainingPage} />  */}
         <PrivateRoute exact={true} path={`${props?.match.path}/description`} component={previewCoursePage} /> 
         <PrivateRoute exact={true} path={`${props?.match.path}/content`} component={ContentTrainingPage} /> 
         <PrivateRoute exact={true} path={`${props?.match.path}/inscription`} component={AdmissionTrainingPage} />
         <PrivateRoute exact={true} path={`${props?.match.path}/students`} component={StudentsCoursePage} />
         <PrivateRoute exact={true} path={`${props?.match.path}/tarification`} component={TarificationTrainingPage} />
      </CustomSwitch>
  </>
  )
}
export default TrainingDetailsContainer;