import { ComponentProps, FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { AppState } from "../../global-state/store/root-reducers";
import userLogo from '../../styles/imgs/Default_user_icon.svg';
import { ModalIDS } from '../../components/modals/modal-ids';
import { openModal } from '../../global-state/actions/modal-actions';
import { getStudentInfoById } from '../../global-state/actions/course-actions';
import { fetchData } from "../../base/functions/Functions";

const HeaderStudentProfilPageComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const [student] = useState<any>(props?.student);
    const { t } = useTranslation();
    const dateFormat = require('dateformat');

    const SuspendStudentButton = async () => {
        await props?.openModal({ idModal: ModalIDS.DEACTIVATE_STUDENT, size: "md", data: { student: student } })
    }
    const reinstateStudentButton = () => {
        props?.openModal({ idModal: ModalIDS.REINTEGRATE_STUDENT, size: "md", data: { student: student } });
    }
    const resendStudentInvitation = async(studentId:number) =>{
        await fetchData('POST',`${process.env.REACT_APP_BASE_URL2}invitation/resend/${studentId}`,{})
    }
    const deleteStudentInvitation = async (studentId:number , schoolId:number)=>{
        await fetchData('POST',`${process.env.REACT_APP_BASE_URL2}invitation/delete/${studentId}/${schoolId}`,{})
    }
  
    return (
        <>
            <div style={{ backgroundColor: '#FFFFFF' }}>
                <div className="px-4 mx-auto py-3" style={{ maxWidth: '1248px' }}>
                    <div className="d-flex flex-row justify-content-between my-4">
                        <div className="d-flex align-items-center">
                            <div >
                                <img style={{ borderRadius: "50%", width: "120px", height: "120px", objectFit: 'cover' }} src={(student?.photo) ? student?.photo : userLogo} />
                            </div>
                            <div className="ms-4 ps-3" >
                                <div className="d-flex flex-column ">
                                    
                                    <div className="flex-row align-items-center justify-content-between flex-row">
                                       
                                            <span className="H2-Headline black-800">{(student !== undefined) ? (student?.firstName + " " + student?.lastName) : (props?.studentInfo?.firstName + " " + props?.studentInfo?.lastName)}</span>
                                        
                                            <span className={`badge-student ${student !== student?.relation?.endDate !== null ? "admis" : "suspended"}`} style={{ marginLeft: "16px" }}>{student?.relation?.endDate === null ? t('Admis') : t('Suspendu')}</span>
                                        
                                    </div>

                                    <div className="d-flex flex-row flex-wrap mt-2">
                                        {(student !== undefined && student?.inscription !== null) ?
                                            <div className="d-flex flex-row me-3">
                                                <span className="body-sm bold neutral-3 me-2">{t('inscriptionTitle')}</span>
                                                <span className="body-sm bold black-800">{dateFormat(student?.creationDate, "dd/mm/yyyy")}</span>
                                            </div> : <div className="d-flex flex-row me-3">
                                                <span className="body-sm bold neutral-3 me-2">{t('inscriptionTitle')}</span>
                                                <span className="body-sm bold black-800">{dateFormat(props?.studentInfo?.creationDate, "dd/mm/yyyy")}</span>
                                            </div>
                                        }
                                        <div className="d-flex flex-row me-3">
                                            <span className="body-sm bold neutral-3 me-2">{t('lastVisitProfile_1')}</span>
                                            <span className="body-sm bold black-800">{t('lastVisitProfile_2')} {(student !== undefined) ? (student?.lastConnexion) : (props?.studentInfo?.lastConnexion)} {t('joursVisit')}</span>
                                        </div>
                                        <div className="d-flex flex-row">
                                            <span className="body-sm bold neutral-3 me-2">mail</span>
                                            <span className="body-sm bold black-800">{student?.email}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {props?.connectionUser?.role === 'role_director' && student?.lastVisit != 0 &&



                            <div className="justify-content-end align-items-center d-flex">

                                {(student?.relation?.endDate === null) && <input dir="ltr" className="input-style" type="checkbox" style={{ display: 'none' }} id="toggleBtn" readOnly={true} onClick={() => { SuspendStudentButton() }} checked={true} />}
                                {(student?.relation?.endDate !== null) && <input dir="ltr" className="input-style" type="checkbox" id="toggleBtn" readOnly={true} style={{ display: 'none' }} onClick={() => { reinstateStudentButton() }} checked={false} />}
                                <div className="toggle-container" style={{backgroundColor:"#F0FAF2"}}>
                                    <label className="label-style" htmlFor="toggleBtn">
                                        <span className="on" style={{color:"#9DDBA9"}}>{t('Admis')}</span>
                                        <span className="off" style={{color:"#9DDBA9"}}>{t('Suspendu')}</span>


                                        <input dir="ltr" type="text" style={{ display: 'none' }} />
                                    </label>
                                </div>

                            </div>}
                        {(props?.connectionUser?.role === 'role_director' || props?.connectionUser?.role === 'role_prof') && student?.lastVisit == 0 &&
                            <div className="justify-content-end align-items-center d-flex">

                                {/* <div className="badge-withicon progres">
                                    <span className="material-icons-outlined">free_breakfast</span>
                                    <span className="ms-2">
                                        {t('waiting')}
                                    </span>
                                </div> */}
                                <div className='d-flex  flex-column'>
                                    <button type="button" className="btn-Secondary large icon-left" onClick={() => { resendStudentInvitation(student?.id) }} >{t('Renvoyer l’invitation')}</button>
                                    <div className=" justify-content-end align-items-end d-flex pt-2">
                                        <button className="btn-text-team outlined danger" onClick={() => { deleteStudentInvitation(student?.id, props?.schoolInfo?.id) }}>{t('deleteInvitation')}</button>
                                    </div>
                                </div>

                            </div>}
                        {props?.connectionUser?.role === 'role_prof' && student?.lastVisit != 0 &&
                            <div className="justify-content-end align-items-center d-flex">
                                <div className="slider-details">
                                    <label className="switch">
                                        {(student?.relation?.endDate === null) && <div className="badge-withicon validated">
                                            <span className="material-icons-round">done</span>
                                            <span className="ms-2">
                                                {t('Admis')}
                                            </span>
                                        </div>}
                                        {(student?.relation?.endDate !== null) && <div className="badge-withicon unvalide">
                                            <span className="material-icons-outlined">close</span>
                                            <span className="ms-2">
                                                {t('Suspendu')}
                                            </span>
                                        </div>}

                                    </label>
                                </div>
                            </div>}
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state: AppState) => ({
    student: state.courseReducer.studentInfo,
    connectionUser: state.authReducer.connectedUser,
    connectedUserInfo: state.authReducer.connectedUserInfo,
    content: state.modalReducer.modalContent,
    schoolInfo: state.schoolInfoReducer.schoolInfo

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators({
        openModal, getStudentInfoById,
    }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(HeaderStudentProfilPageComponent);

