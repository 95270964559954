import { FC, useContext, useEffect, useState } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../global-state/store/root-reducers';
import { closeModal } from '../global-state/actions/modal-actions';
import { getSchoolInfo, getSubscriptions } from '../global-state/actions/app-container-actions';
import AuthContainer from '../pages/auth/auth-container';
import ActivityContainer from '../pages/activities/activity-container';
import CatalogContainer from '../pages/catalog/CatalogContainer';
import PreviewSchoolContainer from '../pages/preview-school/SchoolContainer';
import logoSchoolsKorrs from '../styles/imgs/koorsK.png'
import FinanceContainer from '../pages/finance/FinanceContainer';
import SondageContainer from '../pages/sondage/sondageContainer';
import FaqContainer from '../pages/faq/faqContainer';
import MyCoursesContainer from '../pages/my-courses/my-courses-container';
import PaymentContainer from '../pages/subscription/payment-container';
import FacturationContainer from '../pages/subscription/facturation-container';
import CoursesContainer from '../pages/courses/courses-container';
import WebinarsContainer from '../pages/webinars/webinars-container';
import ProfessorsContainer from '../pages/professors/professors-container';
import { getModalContent } from '../base/functions/getModal';
import StudentsContainer from '../pages/students/students-container';
import Dialog from '@material-ui/core/Dialog';
import RouteChangeTracker from '../helpers/route-change-tracker';
import ExamsContainer from '../pages/exams/exams-container';
import AccountContainer from '../pages/account/accountContainer';
import SubscriptionContainer from '../pages/subscription/subscriptionContainer';
import NotFoundPage from '../pages/errors-pages/404-not-found-page';
import UnauthorizedErrorPage from '../pages/errors-pages/401-unauthorized-error-page';
import AccessRefusedPage from '../pages/errors-pages/403-access-refused-page';
import serverErrorPage from '../pages/errors-pages/server-error-page';
import PathContainerContainer from '../pages/trainings-passage/path-progress/path-content-container';
import PassExamContainer from '../pages/pass-exam/pass-exam-container';
import PassCourseContainerContainer from '../pages/trainings-passage/course-progress/course-content-container';
import QuestionContainer from '../pages/questions/question-container';
import ProgressSessionContainer from '../pages/trainings-passage/session-progress/session-content-container';
import historyBrowser from '../helpers/history-config';
import DisabledSchoolPage from '../pages/errors-pages/disabled-school-page';
import RouteWithNavbar from '../navigations/RouteWIthNavbar';
import ParametreContainer from '../pages/subscription/parametreContainer';
import WebinarsNotFoundContainer from '../pages/webinars/webinars-not-found-container';
import { getOffer, getContract } from '../global-state/actions/app-container-actions';
import FormationNotFoundCreationContainer from '../pages/my-courses/formation-creation/formation-not-found-container';
import PlatfomUpdatePage from '../pages/platformUpdate/platfomUpdatePage';
import axiosInstance from '../helpers/interceptors';
import LoadingComponent from '../components/auth-components/login-register-components/loading-component';
import InvitationContainer from './InvitationContainer';
import InvitationDirectorContainer from './DirectorInvitationContainer';
import cancelledInvitation from '../pages/auth/cancelled-invitation';
import AccountActivatedContainer from './AccountActivatedContainer';
import CouponContainer from '../pages/coupons/coupon-container';
import LoadingContainer from '../pages/loading/LoadingContainer';
import ModalUserGuide from '../components/modals/modal-user-guide/modal-user-guide';
import Joyride from "react-joyride"


import waving_hand from '../styles/imgs/user-guide/waving_hand.svg'
import { mySteps } from '../constants/mySteps';
import { checkIsAppOrSubDoamin, getCodeFromUrl, returnStartPathUrl } from '../helpers/domainCheck';
import { handleCallbackAction, handleStartProfUserGuide } from '../global-state/actions/user-guide-actions';
import { saveEnvUrl } from '../global-state/actions/breadcrumb-actions';
import { UserGuideContext } from '../context/user-guide-context';
import { generateClassNamesFloaterOpen } from '../helpers/user-guide/generate-classnames';
import { callBackUserGuideContext } from '../helpers/user-guide/user-guide-callBack';
import OfferContainer from '../pages/offer/offerContainer';

const AppContainer: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>> = (props) => {
  let link = window.location.href

  let [enabled, setEnabled] = useState<any>("Enabled")
  const [modalContent, setModalContent] = useState<any>();
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const [modalSize, setModalSize] = useState<any>("md");
  const [modalScroll, setModalScroll] = useState<any>("body");
  const [settings, setSettings] = useState<boolean>(true)
  const [notifications, setNotifications] = useState<any>([])
  const [loadingPage, setLoadingPage] = useState(true);
  const [isApp, setIsApp] = useState<string>(checkIsAppOrSubDoamin() ? `/${getCodeFromUrl()}` : "");

  const affectStatus = (status: any, dateDebut: any, dateFin: any) => {
    const now = new Date();
    const debut = new Date(dateDebut);
    const fin = new Date(dateFin);
    if (status === "INACTIF") {
      return false;
    }
    if (status === "ACTIF") {
      if (now < debut) {
        return false
      }
      if (now >= debut && now <= fin) {
        return true
      }
      return false
    }
  };
  const init = async () => {


    await axiosInstance.post(`${process.env.REACT_APP_BASE_URL20}settings/currentSettings`, { product: "APP" }).then(response => {
      setSettings(response?.data?.status)
    })
    await axiosInstance.get(`${process.env.REACT_APP_BASE_URL20}notification/getActif`)
      .then(response => {
        const filteredResponse = response.data.filter((item: any) => affectStatus(item.status, item.startDate, item.endDate))

        setNotifications(filteredResponse)
      })

    if (localStorage.getItem("language") === null) {
      localStorage.setItem("language", "fr");
    }
    let code = getCodeFromUrl();
    setIsApp(checkIsAppOrSubDoamin() ? `/${code}` : "");
    await props?.getSchoolInfo(code)
    await props?.closeModal();

    setLoadingPage(false)
    const domain = window.location.href.split("//")[1].split("/");

    if(checkIsAppOrSubDoamin()){
      if (domain[0] === `app${process.env.REACT_APP_BASE_SUBDOMAIN}`){
        await props?.saveEnvUrl(`/${domain[1]}`)
      }
    }else{
      await props?.saveEnvUrl("")
    }
      
  }

  useEffect(() => {
    init()
  }, [])




  useEffect(() => {
    setModalContent(props?.modalContent);
    setModalOpened(props?.modalOpened);
    if (props?.modalOpened) {
      if (props?.modalContent.scroll) {
        setModalScroll(props?.modalContent.scroll);
      }
      setModalSize(props?.modalContent.size);
      setModalContent(getModalContent(props?.modalContent.idModal, props));
    }
  }, [props?.modalContent]);

  useEffect(() => {
    if (!props?.modalOpened) {
      props?.closeModal();
    }
  }, [props?.modalOpened]);

  useEffect(() => {
    if (!!props?.schoolInfo && !!props?.schoolInfo?.id) {
      props?.getContract(props?.schoolInfo?.id, "SCHOOL")
    }


    const favicon: any = document.getElementById("favicon");

    document.title = props?.schoolInfo?.name;
    if (props?.schoolInfo?.logo !== null) {
      favicon.href = props?.schoolInfo?.logo;
    } else {
      favicon.href = logoSchoolsKorrs
    }
  }, [props?.schoolInfo])

  useEffect(() => {
    if (props?.schoolInfo?.id) {
      props?.getSubscriptions(props?.schoolInfo?.id)
    }
  }, [props?.schoolInfo?.id])
  


  useEffect(() => {
    if (typeof props?.schoolInfo != undefined && typeof props?.schoolInfo?.enabled != undefined) {
      setEnabled(props?.schoolInfo?.enabled)
    }
  }, [props?.schoolInfo?.enabled])


  useEffect(() => {
    if (!!props?.subscription && !!props?.subscription?.idOffer) {
      props?.getOffer(props?.subscription?.idOffer)
    }
  }, [props?.subscription])

  //CONTEXT STATE OF USER GUIDE
  const userGuideContext = useContext(UserGuideContext);
  const { userGuideState, setUserGuideState }: any = userGuideContext;

  useEffect(() => {
    generateClassNamesFloaterOpen(userGuideState.stepIndex, props?.userProfileDetails?.isMailChecked, props?.connectuser?.role, notifications);
  }, [userGuideState?.stepIndex])

  let rootId = document.getElementById('root');
  let initialStepId = document.getElementById('initial-step-user-guide');
  (userGuideState.run || initialStepId || userGuideState.resumeGuide) ? rootId?.classList.add(`root-check-user-guide`) : rootId?.classList.remove(`root-check-user-guide`);


  return (

    <>
      {
        (window.innerWidth > 1310)
        && props.loggedIn
        && !props.userGuide.endOfSteps
        && <Joyride
          callback={(data: any) => callBackUserGuideContext(
            data,
            historyBrowser,
            props?.userProfileDetails?.isMailChecked,
            props?.connectuser?.role,
            setUserGuideState,
            notifications,
          )}
          continuous
          run={userGuideState.run}
          stepIndex={userGuideState.stepIndex}
          steps={mySteps}
          showSkipButton
          disableOverlay={false}
          disableScrolling={true}
        />}
      {(loadingPage === true) ?
        <div>
          <LoadingComponent />
        </div>

        : <div>
          {settings === true ? <>
            {enabled === "Enabled" ?
              <>
                {
                  (window.innerWidth > 1310) &&
                  !userGuideState.startGuide
                  && props?.connectuser?.role !== undefined
                  && props?.connectuser?.role === 'role_director'
                  && link.split('/').pop() === 'activity'
                  && userGuideState.displayInitModal
                  && props?.connectionNumber?.data <= 2
                  && !userGuideState.resumeGuide

                  && <div>
                    <ModalUserGuide
                      icon={waving_hand}
                      title='Hey, bienvenue dans Koors !'
                      descTitle='Félicitations vous venez de créer votre école!'
                      body={<div>Nous vous avons conçu un guide d’utilisation rapide pour vous faire
                        découvrir les principales fonctionnalités qui vont vous aider pour
                        démarrer votre expérience avec Koors.</div>}
                      NbBody='Allons faire un tour dans votre nouvelle école!'
                      btnNext='ALLONS-Y!'
                      btnIgnore='Non, Merci'
                      lengthOfSteps={16}
                      countOfCurrentStep={1}
                      handleClose={() => setUserGuideState((prev: any) => ({ ...prev, displayInitModal: false }))}
                      handleNext={() => setUserGuideState((prev: any) => ({ ...prev, displayInitModal: false }))}
                    />
                  </div>}

                {/* MODAL RESUME GUIDE */}
                {
                  (window.innerWidth > 1310) &&
                  !userGuideState.startGuide
                  && props?.connectuser?.role !== undefined
                  && props?.connectuser?.role === 'role_director'
                  && link.split('/').pop() === 'activity'
                  && userGuideState.displayInitModal
                  && userGuideState.resumeGuide
                  && <div>
                    <ModalUserGuide
                      icon={waving_hand}
                      title='Contents de vous revoir !'
                      body={<div>Commençons par quelques éléments de base pour mieux naviguer et démarrer votre expérience avec Koors.</div>}
                      NbBody='Allons faire un tour dans votre école!'
                      btnNext='ALLONS-Y!'
                      btnIgnore='Non, Merci'
                      lengthOfSteps={16}
                      countOfCurrentStep={1}
                      handleClose={() => setUserGuideState((prev: any) => ({ ...prev, displayInitModal: false }))}
                      handleNext={() => setUserGuideState((prev: any) => ({ ...prev, displayInitModal: false }))}
                    />
                  </div>}

                {notifications && notifications.length > 0 && notifications?.map((notif: any, index: any) => {
                  return (
                    <div key={notif?.color} className="top-bar-notification" style={{ backgroundColor: notif?.color, height: '48px' }}>
                      <div className="d-flex justify-content-center px-4 flex-wrap">
                        <div className="mt-2 pt-1 mb-2 top-bar-notification-content">
                          <span className="body-md bold " style={{ color: (["#CACBCE", "000000"].includes(notif?.color)) ? "white" : "#272727" }}>{notif?.comment}</span>
                        </div>
                      </div>
                    </div>
                  )
                })
                }

                <Router history={historyBrowser} >
                  <Switch>
                    <Route path={`${isApp}/auth`} component={AuthContainer} />
                    <Route path={`${isApp}/courses`} component={MyCoursesContainer} />
                    <Route path={`${isApp}/all-courses`} component={CoursesContainer} />
                    <Route path={`${isApp}/activity`} component={ActivityContainer} />
                    <Route path={`${isApp}/catalog`} component={CatalogContainer} />
                    <Route path={`${isApp}/school`} component={PreviewSchoolContainer} />
                    <Route path={`${isApp}/not-found`} component={FormationNotFoundCreationContainer} />

                    {
                      ((props?.subscription?.limitations?.find((obj: any) => obj.key === 'webinar') && props?.subscription?.limitations?.find((obj: any) => obj.key === 'webinar')?.value === "true")) ?
                        <Route path={`${isApp}/webinars`} component={WebinarsContainer} /> : <Route path={`${isApp}/webinars`} component={WebinarsNotFoundContainer} />
                    }

                    <Route path={`${isApp}/finance`} component={FinanceContainer} />
                    <Route path={`${isApp}/survey`} component={SondageContainer} />
                    <Route path={`${isApp}/loading`} component={LoadingContainer} />
                    <Route path={`${isApp}/offers`} component={OfferContainer} />
                    <Route path={`${isApp}/faq`} component={FaqContainer} />
                    <Route path={`${isApp}/questions`} component={QuestionContainer} />
                    <Route path={`${isApp}/professors`} component={ProfessorsContainer} />
                    <Route path={`${isApp}/students`} component={StudentsContainer} />
                    <Route path={`${isApp}/examsDetails`} component={ExamsContainer} />
                    <Route path={`${isApp}/myaccount`} component={AccountContainer} />
                    <Route path={`${isApp}/path`} component={PathContainerContainer} />
                    <Route path={`${isApp}/list`} component={SubscriptionContainer} />
                    <Route path={`${isApp}/param`} component={ParametreContainer} />
                    <Route path={`${isApp}/offer`} component={PaymentContainer} />
                    <Route path={`${isApp}/customer`} component={FacturationContainer} />
                    <Route path={`${isApp}/404`} component={NotFoundPage} />
                    <Route path={`${isApp}/401`} component={UnauthorizedErrorPage} />
                    <Route path={`${isApp}/403`} component={AccessRefusedPage} />
                    <RouteWithNavbar history={historyBrowser} path={`${isApp}/500`} component={serverErrorPage} />
                    {/* <Route path={`${isApp}/param-school`} component={SchoolContainer} /> */}
                    <Route path={`${isApp}/passPath`} component={PathContainerContainer} />
                    <Route path={`${isApp}/passSession`} component={ProgressSessionContainer} />
                    <Route path={`${isApp}/passCourse`} component={PassCourseContainerContainer} />
                    <Route path={`${isApp}/exam`} component={PassExamContainer} />
                    <Route path={`${isApp}/invitation/expired`} component={cancelledInvitation} />
                    <Route path={`${isApp}/invitation/:code/:role`} component={InvitationContainer} />
                    <Route path={`${isApp}//invitation/:code/:role`} component={InvitationContainer} />

                    <Route path={`${isApp}/verify/:token`} component={InvitationDirectorContainer} />
                    <Route path={`${isApp}/activated`} component={AccountActivatedContainer} />
                    <Route path={`${isApp}/coupon`} component={CouponContainer} />
                    {/* <Route path={`${isApp}/servey`} component={SondageLayout} /> */}
                    {/* <Route path={`${isApp}/create-sondage`} component={CreateSondage} /> */}
                    <Redirect exact path={`${isApp}/`} to={`${isApp}/auth/login`} />
                    <Redirect exact path={`${isApp}`} to={`${isApp}/auth/login`} />

                    <Redirect to={`${isApp}/404`} />
                  </Switch>
                  <RouteChangeTracker />
                  <Dialog
                    style={{ width: '100%' }}
                    maxWidth={modalSize}
                    open={modalOpened}
                    onClose={(event, reason) => {
                      if(reason !== 'backdropClick' && reason !== 'escapeKeyDown' && (props?.modalContent?.idModal === "LOADING_VIDEO_UPLOAD_COURSE") ) {
                          // Set 'open' to false, however you would do that with your particular code.
                          setModalOpened(false)
                      }
                    }}
                    scroll={modalScroll}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                  >
                    {modalContent}
                  </Dialog>


                </Router>
              </>
              :
              <>
                <Router history={historyBrowser}>
                  <Switch>
                    <Route path={`${isApp}/closed`} component={DisabledSchoolPage} />
                    <Redirect to={`${isApp}/closed`} />
                  </Switch>
                </Router>
                <div style={{ marginBottom: "20%" }}></div>
                <div className="row justify-content-center">
                  <div className="col-md-6 justify-content-center">
                    <div className="form-login row justify-content-center form">
                      <div className="col-md-12 text-center">
                        <p className="title">Bonjour, vous êtes bien chez {link} - nous sommes actuellement fermés</p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            }
          </> :
            <>

              <PlatfomUpdatePage />
            </>
          }
        </div>
      }




    </>

  );
}

const mapStateToProps = (state: AppState) => ({
  schoolInfo: state.schoolInfoReducer.schoolInfo,
  connectuser: state.authReducer.connectedUser,
  loggedIn: state.authReducer.loggedIn,
  isFirstLogin: state.authReducer.isFirstLogin,
  modalOpened: state.modalReducer.modalOpened,
  modalContent: state.modalReducer.modalContent,
  subscription: state.schoolInfoReducer.subscription,
  userGuide: state.userGuideReducer.userGuide,
  userProfileDetails: state.authReducer.connectedUserInfo,
  connectionNumber: state.schoolInfoReducer.connectionNumber

});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
  getSchoolInfo,
  closeModal,
  getSubscriptions,
  getOffer,
  getContract,
  handleCallbackAction,
  handleStartProfUserGuide,
  saveEnvUrl
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
