import axios from "../../helpers/interceptors";
import { v4 as uuidv4 } from "uuid";
import * as tus from "tus-js-client";
import qs from "querystring";
import Axios from "axios";
import axiosInstance from "../../helpers/interceptors";

export enum EReduxActionTypes {
  POST_VIMEO_START_CHAPTER = "POST_VIMEO_START_CHAPTER",
  POST_VIMEO_SUCCESS_CHAPTER = "POST_VIMEO_SUCCESS_CHAPTER",
  POST_VIMEO_FAILED_CHAPTER = "POST_VIMEO_FAILED_CHAPTER",
  POST_VIMEO_PROGRESS_CHAPTER = "POST_VIMEO_PROGRESS_CHAPTER",
  POST_VIMEO_PROGRESS_TRANSCODE_CHAPTER = "POST_VIMEO_PROGRESS_TRANSCODE_CHAPTER",
  CLEAR_FORM_CHAPTER = "CLEAR_FORM",
  CHECK_VIDEO_AVALIBILITY = "CHECK_VIDEO_AVALIBILITY",
  SET_SUBTITLE_VIMEO_FAILED = "SET_SUBTITLE_VIMEO_FAILED",
  SET_SUBTITLE_VIMEO_SUCCESS = "SET_SUBTITLE_VIMEO_SUCCESS",
  GET_CHAPTER_SUBTITLE_VIMEO_UPLOADED_FAILED = 'GET_CHAPTER_SUBTITLE_VIMEO_UPLOADED_FAILED',
  GET_CHAPTER_SUBTITLE_VIMEO_UPLOADED_SUCCESS = 'GET_CHAPTER_SUBTITLE_VIMEO_UPLOADED_SUCCESS',
  GET_CHAPTER_SUBTITLE_VIMEO_UPLOADED_LOADING = 'GET_CHAPTER_SUBTITLE_VIMEO_UPLOADED_LOADING',
  // CLEAR_CHAPTER_SUBTITLE_VIMEO_UPLOADED_SUCCESS = 'CLEAR_CHAPTER_SUBTITLE_VIMEO_UPLOADED_SUCCESS'
}

export const clearFormVideoChapter = (): any => {
  return async (dispatch: any): Promise<any> => {
    dispatch({
      type: EReduxActionTypes.CLEAR_FORM_CHAPTER,
      payload: {
        uploading: false,
        transcoding: false,
        progress: 0,
        transcodingProgress: '',
        transcodeStatus: '',
        uploadStatus: '',
        videolink: '',
        idVideo: '',
        readyToDisplay: '',
        loading: true,
      },
    });
  };
};
export const clearLoadingVideo = (): any => {
  return async (dispatch: any): Promise<any> => {
    dispatch({
      type: EReduxActionTypes.CLEAR_FORM_CHAPTER,
      payload: {
        loading: false,
      },
    });
  };
};
export const uploadVimeo = ({
  createVideoLink,
  getVideoLink,
  domain,
  checkStopped,
  ...postBody
}: any) => {
  return async (dispatch: any): Promise<any> => {
    const failedDownload = () => {
      dispatch({
        type: EReduxActionTypes.POST_VIMEO_FAILED_CHAPTER,
        payload: {
          uploading: false,
          progress: 0,
          uploadStatus: "failed",
        },
      });
      return null;
    };
    const patchVimeoFunc = async (
      videoUri: any,
      uploadLink: any,
      videoData: any,
      size: any,
      uploadOffset: any,
      uri: any
    ): Promise<any> => {
      const upload: any = new tus.Upload(videoData, {
        endpoint: "https://api.vimeo.com/me/videos",
        uploadUrl: uploadLink,
        retryDelays: [0, 3000, 5000, 10000, 20000],
        metadata: {
          name: videoData.name,
          filetype: videoData.type,
        },
        headers: {},
        onError: function(error) {
          dispatch({
            type: EReduxActionTypes.POST_VIMEO_FAILED_CHAPTER,
            payload: {
              uploading: false,
              transcoding: false,
              uploadStatus: "failed",
              progress: 0,
            },
          });
        },
        onProgress: function(bytesUploaded, bytesTotal) {
          let percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
          dispatch({
            type: EReduxActionTypes.POST_VIMEO_PROGRESS_CHAPTER,
            payload: {
              transcoding: false,
              uploading: true,
              progress: percentage,
              uploadStatus: "progress",
            },
          });
        },
        onSuccess: function() {

          dispatch({
            type: EReduxActionTypes.POST_VIMEO_PROGRESS_CHAPTER,
            payload: {
              uploading: false,
              transcoding: false,
              loading: false,
              uploadStatus: 'success',
              progress: 100,
              idVideo: videoUri,
            },
          });
        },
      });
      upload.start();
    };
    try {
      dispatch({
        type: EReduxActionTypes.POST_VIMEO_START_CHAPTER,
        payload: {
          uploading: true,
        },
      });
      const { videoData, size } = postBody;

      const createVideoAPIResult = await axios.post(
        `${process.env.REACT_APP_BASE_URL4}getLinkVideo/vimeo`,
        {
          size: size,
          name: videoData.name,
          domain: domain,
        }
      );

      const createVideoResult = createVideoAPIResult.data;
      const uploadLink = createVideoResult.linkToUploadVideo;
      const videoUri = createVideoResult.videolink;
      const uri = createVideoResult.uri;
      dispatch({
        type: EReduxActionTypes.POST_VIMEO_PROGRESS_CHAPTER,
        payload: {
          idVideo: videoUri,
        },
      });
      return patchVimeoFunc(videoUri, uploadLink, videoData, size, 0, uri);
    } catch (err) {
      return failedDownload();
    }
  };
};

export const checkTranscode = (uri: any) => {
  return async (dispatch: any): Promise<any> => {
    const id = uuidv4();
    const res = await axios.post(`${process.env.REACT_APP_BASE_URL4}checkPourcentage`, { link: '/videos/' + uri.split('/')[4] }).then(response => {
      return response.data;
    })
    if (res.message === 'Your video is still transcoding.') {
      return dispatch({
        type: EReduxActionTypes.POST_VIMEO_PROGRESS_TRANSCODE_CHAPTER,
        payload: {
          transcoding: true,
          uploading: false,
          idVideo: uri,
          progress: 100,
          uploadStatus: '',
          id: id,
        }
      });
    } else if (res.message == "Your video finished transcoding.") {
      // checkVideoAvalibility(uri)
      return dispatch({
        type: EReduxActionTypes.POST_VIMEO_PROGRESS_TRANSCODE_CHAPTER,
        payload: {
          transcoding: false,
          transcodeStatus: '',
          uploadStatus: '',
          progress: 100,
          idVideo: uri,
          readyToDisplay: 'false'
        }
      });
    }
    else if (res.message === "Your video encountered an error during transcoding.") {
      return dispatch({
        type: EReduxActionTypes.POST_VIMEO_PROGRESS_TRANSCODE_CHAPTER,
        payload: {
          transcoding: false,
          uploadStatus: '',
          progress: 100,
          idVideo: ''
        }
      });
    }
  };
};
export const checkTranscodeOnLoad = (uri: any) => {
  return async (dispatch: any): Promise<any> => {
    const id = uuidv4();
    const res = await axios.post(`${process.env.REACT_APP_BASE_URL4}checkPourcentage`, { link: '/videos/' + uri.split('/')[4] }).then(response => {
      return response.data;
    })
    if (res.message === 'Your video is still transcoding.') {
      return dispatch({
        type: EReduxActionTypes.POST_VIMEO_PROGRESS_TRANSCODE_CHAPTER,
        payload: {
          transcoding: true,
          uploading: false,
          idVideo: uri,
          progress: 100,
          uploadStatus: '',
          // id: id
        }
      });
    } else if (res.message == "Your video finished transcoding.") {
      // checkVideoAvalibility(uri)
      return dispatch({
        type: EReduxActionTypes.POST_VIMEO_PROGRESS_TRANSCODE_CHAPTER,
        payload: {
          transcoding: false,
          transcodeStatus: '',
          uploadStatus: '',
          idVideo: uri,
          progress: 100,
          readyToDisplay: 'terminate'
        }
      });
    }
    else if (res.message === "Your video encountered an error during transcoding.") {
      return dispatch({
        type: EReduxActionTypes.POST_VIMEO_PROGRESS_TRANSCODE_CHAPTER,
        payload: {
          transcoding: false,
          uploadStatus: '',
          progress: 100,
          idVideo: '',
        }
      });
    }
  };
};

export const checkVideoAvalibility = (videoId: any, finish: string): any => {
  console.log("checkVideoAvalibility" , videoId);
  
  return async (dispatch: any): Promise<any> => {
    const id = uuidv4();
    Axios.request({
      url: "oembed.json?url=" + videoId + "&autoplay=false&muted=false&loop=false&playsinline=false&controls=true&autopause=false&byline=false&portrait=false&title=false&responsive=true",
      method: "get",
      baseURL: "https://vimeo.com/api/",
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: qs.stringify({
        "url": videoId,

      })
    }).then(() => {
      if (finish === 'terminate') {
        dispatch({
          type: EReduxActionTypes.CHECK_VIDEO_AVALIBILITY,
          payload: {
            status: 'terminate',
            idVideo: videoId,
            progress: 0,
          }
        });
      } else {
        dispatch({
          type: EReduxActionTypes.CHECK_VIDEO_AVALIBILITY,
          payload: {
            status: 'true',
            idVideo: videoId
          }
        });
      }

    }).catch(() => {
      dispatch({
        type: EReduxActionTypes.CHECK_VIDEO_AVALIBILITY,
        payload: {
          status: 'false',
          uuidReadyToDisplay: id,
          idVideo: videoId
        }
      });

    })

  }
};

export function uploadSubtitleVimeo(
  idVideo: any,
  dataFile: any,
  language: any
): any {
  return async (dispatch: any) => {
    const res = await axiosInstance
      .post(
        `${
          process.env.REACT_APP_BASE_URL4
        }subtitle-vimeo/${idVideo}?language=${language}`,
        dataFile
      )
      .then((response) => {
        return response.data;
      });

      if (!res) {
        return dispatch({
          type: EReduxActionTypes.SET_SUBTITLE_VIMEO_FAILED,
          data: {
            status: false,
          },
        });
      }
      return dispatch({
        type: EReduxActionTypes.SET_SUBTITLE_VIMEO_SUCCESS,
        data: {
          status: true,
        },
      });
  };
}

export function getChapterSubtitleVimeoUploaded(idVideo: any): any {
  return async (dispatch: any) => {
    dispatch({
      type: EReduxActionTypes.GET_CHAPTER_SUBTITLE_VIMEO_UPLOADED_LOADING,
    });
    const id = +idVideo.split("/").pop();

    const res = await axiosInstance
      .get(
        `${
          process.env.REACT_APP_BASE_URL4
        }getAllSubtitles/${id}`
      )
      .then((response) => {
        return response.data;
      });

    if (!res) {
      return dispatch({
        type: EReduxActionTypes.GET_CHAPTER_SUBTITLE_VIMEO_UPLOADED_FAILED,
      });
    }
    return dispatch({
      type: EReduxActionTypes.GET_CHAPTER_SUBTITLE_VIMEO_UPLOADED_SUCCESS,
      data: res.data,
    });
  };
}

