import { ComponentProps, FC, useEffect, useState} from "react"
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { AppState } from '../../global-state/store/root-reducers';
import FooterComponent from "../../components/footer-component";
import ServerErrorlogo from "./../../styles/images/errors/server-error.svg";
import { returnStartPathUrl } from "../../helpers/domainCheck";

const ServerErrorPage: FC<ReturnType<typeof mapStateToProps> &ComponentProps<any>> = (props: any) => {
    const history = useHistory();
    const { t } = useTranslation()
    const [path, setPath] = useState<string>("");
    useEffect(() => {
        setPath(props?.path);
    }, [props?.path])
    const goBack = () => {
        if(history.length === 2) {
            history.push(`${returnStartPathUrl()}/`);
        } else {
            history.go(-2);
        }
    }
    return (  <>      
            <div className="page-wrapper-height" style={{ minHeight: 'calc(100vh - 80px)' }}>
            <div className="error-description">
                <img className="error-img server" src={ServerErrorlogo} alt="404_img" />
                <div className="d-flex flex-column justify-content-center mx-auto px-4 text-center error-text server">
                <span className="H1-Headline black-800 mb-4">
                    {t('serverProblem')}
                </span>
                <span className="H3-Headline black-800 mb-4">
                    {t('unexpectedError')}<br/>
                    {t('errorInfoServerProblem')}
                </span>
                <div className="d-flex flex-column mb-2">
                    <span className="body-xl black-800 mb-3 mx-auto  mb-3">
                    {t('tryLater')}
                    </span>
                    {/* <button type="button" className="btn-Primary large mx-auto" style={{ width: 'max-content' }}>
                    {t('Catalogue')}
                    </button> */}
                </div>
                </div>
            </div>
            </div>
            <FooterComponent />
            </>)
}
const mapStateToProps = (state: AppState) => ({
    // path: state.errorReducer.path
  });
  
export default connect( mapStateToProps, null )(ServerErrorPage);