export const TRANSLATIONS_FR = {
    addCourse: "NOUVEAU COURS",
    createCourse: "Créer un cours",
    myCourses: "Mes cours",
    Published: "En ligne",
    Draft: "Brouillon",
    Pending: "En attente de validation par le directeur",
    RequestDeactivation: "En attente de désactivation par le directeur",
    RequestActivation: "En attente d'activation par le directeur",
    RequestDeletion: "En attente d'archivage par le directeur",
    certified: "Diplômés",
    candidats: "CANDIDATS",
    candidatsCapitalise: "Candidats",
    courseStatus_1: "Le cours restera",
    courseStatus_2: "privé jusqu'à sa publication",
    Pending_2: "En attente de validation",
    RequestDeletion_2: "En attente d'archivage",
    RequestDeactivation_2: "En attente de désactivation",
    RequestActivation_2: "En attente d'activation",
    actvity: "ACTIVITÉ",
    students: "Étudiants",
    students_2: "Étudiant",
    description: "DESCRIPTION",
    archive: "ARCHIVER",
    publish: "PUBLIER",
    inscription: "INSCRIPTION",
    inscriptionTitle: "Inscription",
    certifiedTitle: "Diplômé le",
    lastVisitProfile: "Dernière visite il y a",
    lastVisitProfile_1: "Dernière visite",
    lastVisitProfile_2: "Il y a",
    descProf: "Description courte du professeur qui apparaitra dans la présentation du cours",
    content: "CONTENU",
    titleCoursePlaceholder: "Indiquer le titre du cours ici...",
    titleCourseForm: 'Titre du cours',
    modalPublishCourse_1: "Envoyer au directeur pour validation",
    modalPublishCourse_1_1: "Valider la publication",
    modalRefusedCourse_1: "Refuser la publication",
    modalPublishCourse_2: "Ajouter un commentaire pour le directeur",
    modalPublishCourse_2_2: "Ajouter un commentaire pour le professeur",
    modalPublishCourse_3: "Commenter...",
    modalPublishCourse_4: "ENVOYER LE COURS",
    modalPublishCourse_4_4: "PUBLIER LE COURS",
    modalRefusedCourse_4: "REFUSER LE COURS",
    modalPublishCourseSuccess: " Le cours a été envoyé au directeur pour validation.",
    modalPublishPathSuccess: " Le parcours a été envoyé au directeur pour validation.",
    modalPublishSessionSuccess: " La session a été envoyée au directeur pour validation.",
    modalPublishCourseSuccess_1: "Le cours est publié. Il est maintenant disponible en ligne.",
    modalDeactivateCours: "Le cours est désactivé. Il n'est plus disponible en ligne.",
    modalRefusedCourseSuccess: "Vous venez de refuser la publication du cours. Votre refus a été envoyé au professeur.",
    titleFormCourse_1: "Présentation vidéo du cours",
    titleFormCourse_2: "Visuel pour illustrer le cours",
    titleFormCourse_3: "Présentation texte du cours",
    resume: "Résumé introductif",
    textAreaPlaceholder: "Indiquez le texte ici ...",
    placeholderInscriptionInput: "Indiquez le texte ici",
    prerequisite: "Prérequis pour suivre ce cours",
    objectives: "Objectifs pédagogiques",
    skills: "Connaissances et compétences que permet d'acquérir ce cours",
    skillsPlaceholder: "Saisir un mot clé...",
    uploadVideo_1: "Glissez-déposez la vidéo du cours que vous souhaitez mettre en ligne (mp4, mov)",
    uploadVideo_2: "SÉLECTIONNER UN FICHIER VIDÉO",
    uploadImage_1: "Glissez-déposez une photo pour illustrer le cours (jpg, png)",
    uploadImage_2: "SÉLECTIONNER UNE PHOTO",
    downloadVideo: "Téléchargement",
    awaitVideo: "Dans la file d'attente",
    whiletranscode: "En cours de visualisation",
    transcodingVideo: "Traitement de la vidéo",
    breadCrumbChapter: "Chapitre",
    chapitre: "Chapitre",
    newTest: "NOUVEAU TEST",
    newChapter: "NOUVEAU CHAPITRE",
    selectLevelCourse: "Niveau",
    selectLevelCourse_1: "Novice",
    selectLevelCourse_2: "Intermédiare",
    selectLevelCourse_3: "Confirmé",
    listChaptersContent: "Pour commencer, indiquez le niveau du cours et créez votre premier chapitre.",
    chapterSelectFomCHapter: "CHAPITRE",
    saveChapter: "ENREGISTRER",
    durationChapter: "Durée",
    placeholderTitleChapter: "Indiquer ici le titre du chapitre...",
    titleFormChapter_1: "Vidéo du chapitre",
    titleFormChapter_2: "Fichiers à télécharger",
    titleFormChapter_3: "Texte du chapitre",
    textFileUpload_1: "Glissez-déposez les fichiers que vous souhaitez mettre en ligne (audio, PDF, PPT, XLS...)",
    textFileUpload_2: "( audio, PDF, PowerPoint, Word, Excel...)",
    fileUploadButton: "Sélectionner un fichier",
    uploadVideoChpter_1: "Glissez-déposez la vidéo du chapitre que vous souhaitez mettre en ligne (mp4, mov)",
    tests: "Tests",
    test: "Test",
    resultat: "Résultat",
    reponseInscrit: "Demande d'inscription",
    reponseAv: "Réponse avant le",
    refusedAt: "Refusé le",
    Refused: "Refusé",
    Unpaid: "À payer",
    seeDemand: "Voir ma demande",
    certifButton: "CERTIFICAT",
    courseTable: "Formation",
    DATEACHAT: "DATE d'ACHAT",
    exportListe: "EXPORTER LA LISTE",
    filterProfessors: "Professeur",
    statusFilter: "Statut",
    search: "Recherche",
    Tous: "Tous",
    Brouillon: "Brouillon",
    Avalider: "À valider",
    Aaccepter: "À accepter",
    Enligne: "En ligne",
    Archive: "Archivé",
    Refuse: "Refusé",
    Cours: "Cours",
    COURSE: "Cours",
    Professeurs: "Professeurs",
    Demandes: "Demandes",
    filterCourse: "Tous les cours",
    Certifies: "Certifiés",
    Statut: "Statut",
    PresProf: "Présentation du Professeur",
    dernVisite: "Dernière visite",
    Enattente: "En attente",
    Admis: "Admis",
    Suspendu: "Suspendu",
    inviteProf: "INVITER UN PROFESSEUR",
    inviteProf_2: "Inviter un professeur à s'inscrire",
    send: "Envoyer",
    inviteSuccess: "Votre invitation a bien été envoyée au professeur.",
    myAccount: "Mon compte",
    saveImg: "Sauvgarder l'image",
    Profile: "Profil",
    Nom: "Nom",
    NomObligatoire: "Nom*",
    PrenomObligatoire: "Prénom*",
    Prenom: "Prénom",
    country: "Pays de résidence",
    Tunisie: "Tunisie",
    France: "France",
    city: "Ville de résidence",
    birthdate: "Date de naissance",
    birthdate_input: "Date de naissance (mm/jj/aaaa)",
    NivScolaire: "Niveau scolaire",
    Primaire: "Primaire",
    College: "Collège",
    Lycee: "Lycée",
    Bac: "Bac",
    Technicien2: "Technicien (Bac +2)",
    License: "Licence (Bac+3)",
    Master: "Master ou équivalent (BAC +4 ou + 5)",
    Ingenieur: "Ingénieur",
    Doctorat: "Doctorat",
    emailAdd: "Adresse email",
    updatePass: "Modifier mon mot de passe",
    password: "Mot de passe",
    actualPass: "Mot de passe actuel",
    newPass: "Nouveau mot de passe",
    enterPassword: "Minimum 8 caractères",
    msgErrorPassword: "Veuillez entrer au moins 8 chiffres/lettres",
    msgErrorConfirmPassword: "Veuillez entrer le même mot de passe",
    confimrNewPass: "Confirmation du mot de passe",
    msgErrorMail: "Veuillez saisir une adresse mail valide",
    confimrNewEmail: "Nouvelle adresse email",
    validPassButton: "Valider mon nouveau mot de passe",
    MainSchoolColor: 'Couleur principale de l’école',
    AddYourColorToPersonalizeThePresentationOfTheCourses: 'Ajouter votre couleur pour personnaliser la présentation des formations',
    imgCrop_1: "Glissez-déposez une photo",
    imgCrop_3: "Glissez-déposez votre logo",
    imgCrop_2: "SÉLECTIONNER UNE PHOTO",
    pathBy: "Parcours donné par",
    sessionBy: "Session donné par",
    catalogCourses: "Catalogue des cours",
    Etudiants: "Étudiants",
    Ecole: "École",
    ecole_2:"école",
    Catalogue: "Catalogue",
    Activite: "Activité",
    lecole: "École",
    howItWorks: "Comment ça marche",
    hello: "Bienvenue !",
    cnxButton: "Me connecter",
    createAccountMsg: "Créer mon compte",
    questionCreateAccount: "Vous n'avez pas encore créé de compte ?",
    forgotLogin: "Mot de passe oublié ?",
    emailLogin: "E-mail",
    validInscription: "VALIDER MON INSCRITION",
    activateAccount: "ACTIVER MON COMPTE",
    madame: "FEMME",
    monsieur: "HOMME",
    other: "AUTRE",
    registerDone_1: "Votre demande d'inscription a bien été prise en compte.",
    registerDone_2: "Un e-mail vient de vous être envoyé.",
    registerDone_3: "Cliquez sur le lien indiqué à l’intérieur de cet e-mail afin de confirmer la création de votre compte.",
    registerDone_4: "À tout de suite !",
    validRegister: "Valider",
    activateMyAccount: "Activation de mon compte",
    directeur: "Directeur",
    professeur: "Professeur",
    invalidToken: "Ce lien a été déjà utilisé et il est expiré, veuillez contacter le support en cas de besoin.",
    questionAccount: "Vous avez déja un compte ?",
    verifMail_1: "Votre email a bien été vérifié, vous pouvez désormais vous connecter.",
    verifMail_3: "Validation de votre e-mail",
    changePass: "Changer le mot de passe",
    msgUpdatedPass: "Le mot de passe a été modifié.",
    recPass: "Récupérer le mot de passe",
    reinMail: "Entrez votre adresse email ci-dessous. Vous recevrez un lien pour réinitialiser votre mot de passe.",
    reinBUttonPass: "Réinitialiser mon mot de passe",
    sendMailRein: "Un email contenant votre lien de réinitialisation vous a été envoyé.",
    alreadyHaveAcc: "Vous avez déjà un compte ?",
    followCourse: "Suivre ce cours",
    followPath: "Suivre ce parcours",
    objPEda: "Objectifs pédagogiques",
    preresuiPeda: "Prérequis pour ce cours",
    preresuiPedaPath: "Prérequis pour ce parcours",
    preresuiPedaSession: "Prérequis pour cette session",
    skillsPreveiw: "Connaissances et compétences que permet d'acquérir ce cours",
    skillsPreveiwPath: "Connaissances et compétences que permet d'acquérir ce parcours",
    skillsPreveiwSession: "Connaissances et compétences que permet d'acquérir cette session",
    courseProgramm: "Programme du cours",
    Chapitres: "Chapitres",
    videoCourse: "Cours vidéo",
    textCourse: "Texte du cours",
    downloadFiles: "Fichiers à télécharger",
    uploadFiles: "Fichiers à importer",
    testConn: "Tests de connaissance",
    aboutSchool: "À propos de l'école",
    aboutProfessor: "À propos du professeur",
    registerCourse: "S'inscrire au cours",
    registerPath: "S'inscrire au parcours",
    registerSession: "S'inscrire à la session",
    testConn_2: "Tester ses connaissances",
    progressView: "Suivre ses progrès",
    previewText_1: "Remplissez votre demande d’inscription pour que le professeur puisse la valider.",
    previewText_2: "Avancez dans votre programme et assimilez de nouvelles compétences.",
    previewText_3: "Validez vos connaissances grâce aux tests et obtenez votre diplôme.",
    Introduction: "Introduction",
    Documents: "Documents",
    downloadFIleDetails: "Télécharger",
    Etudiant_1: "Étudiant",
    Professeur_1: "Professeur",
    Directeur_1: "Directeur",
    annuler: "ANNULER",
    modifier: "Modifier",
    logout: "Déconnexion",
    connexionButton: 'Connexion',
    languageFR: "Français",
    languageEN: "Anglais",
    language: "Langue",
    Pending_3: "À valider",
    CERTIFICAT: "CERTIFICAT",
    refuseCourse: "REFUSER LA PUBLICATION",
    joursVisit: " Jour(s)",
    consulter: "CONSULTER",
    jourVisit: "Jour",
    Candidat: "Candidat",
    enCours: "ÉTUDIANTS EN COURS",
    certifiés: "DIPLÔMÉS",
    Dossier: "Dossier",
    Demande: "Demande",
    DemandeEnvoyéeIlYA: "Demande envoyée il y a",
    Resultats: "Résultats",
    Certificat: "Certificat",
    Tempsdobtention: "Obtention",
    Tempsdobtention_2: "Temps d'obtention",
    refuseCourseDirector: "Refuser la publication",
    refuseCourseDirector_2: "Ajouter un commentaire pour le professeur",
    refuseCourseDirector_3: "REFUSER LE COURS",
    modalRefuseCourseSuccess_1: "Vous venez de refuser la publicatiion du cours.",
    modalRefuseCourseSuccess_2: "Votre refus a été envoyé au professeur.",
    archiveCourseDirector: "Voulez-vous vraiment archiver ce cours ?",
    archiveCourseDirectorSuccess: "Le cours est archivé.",
    archiveCourseDirector_2: "ARCHIVER LE COURS",
    nothingTOSay: "Rien à dire",
    online: "En ligne",
    offline: "Hors ligne",
    validationNewPassword: "Vous pouvez vous connecter avec votre nouveau mot de passe.",
    showMore: "AFFICHER LES SUIVANTS",
    folderRegistrationTrueOrFalse: "Sous réserve de validation du dossier.",
    nextCommentsFeedback: "Afficher les suivants",
    emailUpdateButtonShH: "MODIFIER MON ADRESSE E-MAIL",
    conditionDeValidation: "Conditions de validation de la demande d'inscription des étudiants par le professeur.",
    Acceptation: "Acceptation de l'inscription de l'étudiant automatique.",
    AcceptationSousDossier: "Acceptation de l'inscription de l'étudiant sous réserve de validation du dossier.",
    questions: "Questions à poser à l'étudiant",
    documents: "Documents à demander à l'étudiant",
    titreQuestion: "Indiquez le titre de la question ici",
    titreDocument: "Indiquez le titre du document ici",
    AjouterQuestion: "Ajouter une question",
    AjouterDocument: "Ajouter un document",
    demandeDinscription: "Demande d'inscription",
    envoyerDemande: "ENVOYER MA DEMANDE",
    titreRegistration_1: "Questions posées par le professeur",
    titreRegistration_2: "Documents demandés par le professeur",
    Inscribed: "En cours",
    CertifiedStudent: "Certifié",
    DraftStudent: "Dossier d'inscription en cours",
    Candidate: "En attente de validation du dossier",
    finishRegistration: "TERMINER MA DEMANDE",
    noCoursesStudent: "Vous n’êtes inscrit à aucun cours.",
    discoverCatalog: "DÉCOUVRIR LE CATALOGUE",
    noCoursesCreated: "Aucune formation n’a encore été créée.",
    noCoursesCreated_2: "Pour commencer, créez votre premier cours.",
    noCourses_1: 'Aucun cours suivi',
    noCourses_2: 'Aucune demande d\'inscription',
    noCourses_3: 'Aucun cours terminé',
    noCoursesPublished: "Aucun cours n'a encore été publié.",
    deleteCompte: "Supprimer mon compte",
    searchProfessors: "Rechercher...",
    descriptionProfLabel: "Description courte du professeur qui apparaîtra dans la présentation de ses cours.",
    relaunchProfInvitation: "RELANCER",
    noProfessorsList_1: "Aucun(e) professeur(e) trouvé(e).",
    noProfessorsList_2: "Veuillez effectuer une nouvelle recherche.",
    noProfessorsListDefault_1: "Aucun(e) professeur(e) est inscrit.",
    noProfessorsListDefault_2: "Invitez un(e) professeur(e) à s’inscire pour commencer.",
    signatureProf: "Signature du professeur",
    signatureDirector: "Signature du directeur",
    Deactivate: "Désactivé",
    breadCrumbTest: "Test",
    placeholderTitleExam: "Indiquer ici le titre du test...",
    exempleTitleExam: "Exemple, Test de connaissances des premiers acquis",
    noQuestionTest: "Pour commencer, créez votre première question.",
    newQuestion: "NOUVELLE QUESTION",
    addQuestionText: "Ajouter une question",
    placeholderQuestionText: "Indiquez la question",
    answerQuestion: "Indiquez la réponse",
    placeholderChoiceText: "Indiquez le texte des choix multiples",
    delete: "Supprimer",
    suppcomte: "Suppression du compte",
    selectMotif: "Sélectionner un motif",
    coursNeMeconvier: "Les cours ne me conviennent pas",
    comm: "Ajouter un commentaire",
    DeleteSuccess: "Votre compte a bien été supprimé. Au revoir.",
    CreationAccountProfDirector: "Votre compte a bien été créé. Vous pouvez vous connecter.",
    messageEnvoyerDemande_1: "Votre demande d'inscription a bien été envoyée.",
    messageEnvoyerDemande_2: "Vous recevrez une réponse par e-mail",
    messageEnvoyerDemande_3: "dans les 3 prochains jours.",
    candidat_status: "EN ATTENTE DE VALIDATION",
    refuser: "REFUSER",
    refuser_1: "REFUSÉ",
    valider: "VALIDER",
    valider_1: "ACCEPTÉ",
    refuserDemandeRegistration: "Refuser l’inscription de l’étudiant",
    ajouterCommentaireEtudiant: "Ajouter un commentaire pour l'étudiant.",
    refuserLaDemande: "REFUSER LA DEMANDE",
    messageRefuseRegistrationCours: "Inscription de l’étudiant au cours refusé avec succès.",
    messageRefuseRegistrationPath: "Inscription de l’étudiant au parcours refusé avec succès.",
    messageRefuseRegistrationSession: "Inscription de l’étudiant au session refusé avec succès.",
    messageValiderRegistration: "L'étudiant a été invité à commencer à suivre le cours.",
    messageValiderRegistrationParcours: "L'étudiant a été invité a commencer à suivre le parcours",
    messageValiderRegistrationSession: "L'étudiant a été invité a commencer à suivre la session",
    SuspendProf: "Suspendre",
    textProfSuspend: "Ce professeur ne pourra plus avoir accès aux cours. Vous avez la possibilité de le réintégrer si vous changez d’avis.",
    otherChoice: "Autre",
    commentProfSuspend: "Ajouter un commentaire pour le professeur",
    questionLabel: "Question",
    choiceLabelBON: "Bonne réponse",
    addAnswerButton: "Ajouter une réponse",
    noQuestionTestPreview: "Pas de questions",
    passedTestAverage: "Taux de réussite",
    param: "Paramètre",
    presen: "Présentation",
    commentSuspendInputProf: "Commentaire...",
    motifSuspendProf: "Sélectionner un motif",
    affectOtherPRof: "Séctionner un professeur à qui attribuer les cours",
    ReintegrateProf: "Réintégrer",
    ReintegrateProfText: "Ce professeur pourra avoir accès aux cours. Vous avez la possibilité de suspendre le professeur si vous changez d’avis.",
    AcceptProf: "Accepter",
    aArchiver: "À archiver",
    aDesactiver: "À désactiver",
    aActiver: "À activer",
    desactiverCourse: "VALIDER LA DÉSACTIVATION",
    refuseDesactivationCourse: "REFUSER LA DÉSACTIVATION",
    activerCours: "VALIDER L'ACTIVATION",
    refuserLactivationCours: "REFUSER L'ACTIVATION",
    archiverCours: "VALIDER L'ARCHIVAGE",
    refuserArchivationCours: "REFUSER L'ARCHIVAGE",
    settingEcole: "Paramètres de l’école",
    nomEcole: "Nom de l'école",
    logoEcole: "Logo de l'école",
    persenCourte: "Présentation courte (texte affiché dans les formations)",
    listCandidates: "Demandes d’inscription",
    listFollowing: "Formations suivies",
    listeCertificated: "Formations terminées",
    sendDate: "Envoi de la demande",
    importVideoUpload: "Téléchargement de la vidéo",
    waitingUpload: "Veuillez patienter...",
    uploadFinishing: "La vidéo sera disponible dans un instant",
    uploadPending: "En cours de traitement...",
    candidatures: "Candidatures",
    certifcations: "CERTIFICATIONS",
    dashboardAllStudents: "Étudiant",
    certifiedDashStudents: "Diplômé",
    YesWord: "Oui",
    NoWord: "Non",
    obtentionPeriod: "Obtention",
    In: "En",
    TimePassed: "Il y a ",
    noStudentFound: "Aucun étudiant en cours",
    noCandidateFound: "Aucune candidature en attente",
    noSuspendedFound: "Aucun étudiant n'est suspendu",
    noCertifedFound: "Aucun étudiant certifié ou diplômé",
    textStudentSuspend: "Cet étudiant ne pourra plus avoir accès aux cours. Vous avez la possibilité de le réintégrer si vous changez d’avis.",
    commentStudentSuspend: "Ajouter un commentaire pour l’étudiant",
    successSuspendStudent: "L’étudiant n’a plus accès aux cours.",
    ReintegrateStudentText: "Cet étudiant pourra avoir accès aux cours.",
    ReintegrateStudentText_2: "Vous avez la possibilité d’exclure l’étudiant si vous changez d’avis.",
    successReinstateStudent: "L’étudiant a maintenant accès aux cours.",
    successSuspendProf: "Professeur suspendu avec succès",
    successSuspendAssitant: "Administrateur suspendu avec succès",
    successReinstateProf: "Professeur réintégrer avec succès",
    successReinstateAssistant:"Administrateur réintégrer avec succès",
    returnToProfProfile: "Retour AU PROFIL",
    diplomeButton: "Diplôme",
    validatedDiploma: "DIPLÔMES",
    Declcatalog: "DÉCOUVRIR LE CATALOGUE",
    deactivateCoursText: "Ce cours ne sera plus accessible, vous avez la possibilité de le réactiver si vous changez d'avis.",
    modalDeactivateCoursTitle: "Désactivation du cours",
    deactivateCours: "Désactiver",
    successdeactivateCours: "A été effectuée avec succès.",
    createBy: "Donné par ",
    maleGender: "M. ",
    femaleGender: 'Mme ',
    modalReactivateCoursTitle: "Réactivation de cours",
    modalReactivatePathTitle: "Réactivation de parcours",
    modalReactivateSessionTitle: "Réactivation de la session",
    reactivateCours: "Réactiver",
    reactivateCoursText: "Ce cours sera accessible.",
    reactivateCoursText_2: "Vous avez la possibilité de le désactiver si vous changez d'avis.",
    reactivatePathText: "Ce parcours sera accessible.",
    reactivatePathText_2: "Vous avez la possibilité de le désactiver si vous changez d'avis.",
    reactivateSessionText: "Cette session sera accessible.",
    reactivateSessionText_2: "Vous avez la possibilité de la désactiver si vous changez d'avis.",
    champsObligatoires: "(*) Champs obligatoires",
    continueCreatingCourse: "Désolé, vous ne pouvez pas publier ce cours.",
    continueBUttonText: "CONTINUER",
    errorChaptersLength: "Vous devez avoir au moins un chapitre (obligatoire).",
    linkImgCourseError: "Visuel pour illustrer le cours (obligatoire).",
    descriptionCourseError: "Présentation texte du cours (obligatoire).",
    congratulation: "Félicitations pour votre diplôme !",
    diplomaMsg : "Pour pouvoir télécharger votre diplôme, veuillez consulter tous les contenus dans les chapitres (Vidéos inclues)",
    onlineCOurses: "Cours en ligne",
    validatedCourses: "Cours à valider",
    AllCoursesDirector: "Tous les cours",
    BonneRéponses: "Bonnes Réponses",
    Question: "Question",
    ReturnExam: "REPRENDRE MON TEST EN COURS",
    textExa: "Sélectionnez une ou plusieurs réponses en fonction de la consigne. Le test s’effectue une seule fois mais n'est pas éliminatoire.",
    StartExam: "Commencer",
    Réponse: "Réponse",
    QCU: "1 seule bonne réponse possible",
    QCM: "Plusieurs réponses possibles",
    validCourse: "VALIDER LA PUBLICATION",
    refuseInscriptionStudent: 'DEMANDE REFUSÉE',
    BonneReponses: "Bonnes Réponses",
    placeholderTextArea: 'Indiquez le texte ici...',
    languageIT: 'Italien',
    languageES: 'Espagnol',
    languageDE: 'Allemand',
    languageNL: 'Néerlandais',
    languageSV: 'Suédois',
    obligatoireFormCourse: 'obligatoire',
    Registered: 'Inscrits',
    New: 'Nouveaux',
    Suspended: 'Suspendus',
    CatalogueVisitorsInTheLast30Days: 'Visiteurs du catalogue lors des 30 derniers jours.',
    Course: 'Cours',
    Candidates: 'Candidats',
    Teachers: 'Professeurs',
    ConversionRateOfApplicationsInTheLast30Days: "Taux de transformation des visites lors des 30 derniers jours.",
    MedianCourseNotes: "Note mediane des examens finaux",
    AverageAgeOfStudents: "Âge moyen des étudiants",
    Male: "Hommes",
    Female: "Femmes",
    MostRepresentedCities: "Villes les plus représentées",
    VisitsAndApplicationsForRegistration: "VISITES & INSCRIPTIONS",
    RegistrationsAndCertifications: "INSCRIPTIONS & DiplômEs",
    ConnectedStudents: "Étudiants connectés",
    LevelOfStudy: "Niveau d'études",
    numberOfVisits: "Visites de la page de présentation",
    Applications: "Demandes d'inscription",
    Registration: "Nombre d'inscriptions",
    StudentsNumber: "Nombre d'étudiants connectés",
    Jan: "Jan",
    Fev: "Feb",
    Mar: "Mar",
    Avr: "Apr",
    May: "May",
    Jui: "Juin",
    Jul: "Jui",
    Aug: "Aou",
    Sep: "Sep",
    Oct: "Oct",
    Nov: "Nov",
    Dec: "Dec",
    Access: "Accéder",
    Year: "ans",
    StudentsConnectedThisMonth: "Connectés les 30 derniers jours.",
    AverageTimeSpentOnThePlatformForGraduation: "Temps moyen passé sur la platforme pour l’obtention du diplôme",
    AverageTimeBetweenRegistrationAndGraduation: "Temps moyen entre l’inscription et l’obtention du diplôme",
    certification: "Certification",
    waitingCertif: "Chargement de votre diplôme, veuillez patienter...",
    ListEtds: "Liste étudiants",
    listcand: "Liste candidatures",
    listdipv: "Liste diplomés",
    listInscribed: 'Liste des inscrits',
    listSuspended: 'Liste des suspendus',
    Days: "jours",
    noVideoToShow: "Pas de vidéo.",
    donnerAvis: "C'est important !",
    donnerAvis_2: "Donner votre avis sur le cours",
    avisTitle: "Titre de votre avis",
    avisText: "Rédigez votre avis",
    votreAvis: "Votre avis ici",
    publishMyReview: "Publier",
    monAvis: "Mon avis sur le cours",
    avis: "Avis",
    Anonymous: "Anonyme",
    noFeedback: "Aucun avis (pour l'instant)",
    Finance: "Finance",
    PublishedAt: 'Publié le',
    PublishedAte: 'Publiée le',
    ActualPrice: 'Dernier prix de la période',
    MoyPrix: 'Prix Moyen',
    discussionButton: "Discussions",
    questionButton: "Questions au professeur",
    addCommentButton: "Ajouter un commentaire",
    addCommentForChapter: "Ajouter un commentaire au chapitre",
    addcommentForCourse: "Ajouter un commentaire au cours",
    addcommentForPath: "Ajouter un commentaire au parcours",
    addCommentForSession: "Ajouter un commentaire au session",
    commentTitle: "Titre du commentaire",
    yourComment: "Rédigez votre commentaire",
    votreCommentaie: "Votre commentaire ici...",
    addQuestionButton: "Ajouter une question",
    addCommentSuccess: "Votre commentaire a été ajouté avec succès",
    responses: "Réponse(s)",
    noCommentFound: "Aucun commentaire (pour l'instant)",
    repondre: "Répondre",
    labeldeletecompte: "Toutes vos données seront définitivement supprimées",
    listdesProf: "Liste professeurs",
    noCoursePayedDefault: "Aucun cours payant.",
    noCoursePayed_1: "Aucun cours payant trouvé.",
    noCoursePayed_2: "Veuillez effectuer une nouvelle recherche.",
    Courspayed: "Cours payants",
    CoursFree: "Cours gratuits",
    paie: "Liste des paiements",
    prunp: "Préciser une période",
    Année: "Année",
    community: "Communauté",
    thematic: "Selectionner une thématique",
    comment: "Commentaire",
    NumberOfCertifications: "Nombre de certifications",
    inscriptionTypePayment: "Type d’inscription",
    registrationFees: "Frais d’inscription",
    gratuit: "Gratuit",
    payant: "payant",
    addQuestionForChapter: "Poser une question au professeur ",
    yourQuestion: "Rédigez votre question ",
    addQuestionSuccess: "Votre question a été ajoutée avec succès",
    noQuestionFound: "Aucune question à traiter",
    noQuestionFound_4: "Aucune question traitée",
    noQuestionFound_5:"Vous n'avez aucune question.",
    paymentTitle: "Paiement",
    paymentTitlePlural: 'Paiements',
    paymentMethodCourses: 'Mode de paiement des formations',
    choiceCurerncy: 'Choisir la devise',
    Questions: "Questions",
    unsettledQuestion: "Questions à traiter",
    settledQuestion: "Questions traitées",
    allQuestion: "Toutes les questions",
    recent: "La plus récente",
    oldest: "La moins récente",
    maxResponses: "Avec le maximum de réponses",
    allSubjects: "Toutes les formations",
    allSubjects_2: "Tous les sujets",
    noQuestionFound_2: "Votre recherche ne correspond à aucune question.",
    noQuestionFound_3:"Veuillez effectuer une nouvelle recherche.",
    noCommentFound_2: "Votre recherche ne correspond à aucun commentaire",
    noPaymentInfo: "Vous devez choisir le mode de paiement de votre école",
    NoPaymentSchoolConfig_1: "Pour paramétrer le mode de paiement .",
    NoPaymentSchoolConfig_2: "Cliquez ici",
    newResponses: "Nouvelle(s) réponse(s)",
    SortsMostRecent: "Le plus récent",
    SortOldest: "Le plus ancien",
    allStatus: "Tous les statuts",
    WAITING: "En attente de validation",
    SOON: "A venir",
    ONGOING: "En cours",
    ENDED: "Terminé",
    REFUSED: "Refusé",
    allCourses: "Tous les cours",
    NoWebinarHasBeenCreatedYetCreateYourFirstWebinarToGetStarted: " Aucun webinaire n’a encore été créé. Créez votre premier webinaire pour commencer.",
    ForStudentsOf: "Pour les étudiants de la formation",
    WebinarGivenBy: "webinaire donné par",
    ENTERTHEWebinar: " ENTRER DANS LE WEBINAIRE",
    StartTHEWebinar: "Démarer LE WEBINAIRE",
    AddToMyAgenda: "Ajouter à mon agenda",
    validate: "Valider",
    inMyAgenda: "dans mon agenda",
    Edit: "Modifier",
    REVIEWTHEwebinar: "REVOIR LE WEBINAIRE",
    IWantTheWebinarToBeAccessibleInReplayWithThePublicConcerned: "Je souhaite que le webinaire soit accessible en replay auprès du public concerné",
    NewWebinar: " Nouveau webinaire",
    close: "FERMER",
    Webinar: "Webinaires",
    WebinarTitle: "Titre du webinaire",
    WebinarDate: "Date du webinaire",
    WebinarSchedule: "Horaire du webinaire",
    ShortDescriptionOfTheWebinar: "Description courte du webinaire",
    Cancel: "Annuler",
    WaitingForValidation: "Attente de validation",
    ComingSoon: "Prochainement",
    LetsGo: "C'est parti",
    ToReview: "À revoir",
    inscriptionCourseFaq: 'S’inscrire à un cours',
    followCourseFaq: 'Suivre un cours',
    certificationFaq: 'Le diplôme',
    professorsFaq: 'Les professeurs',
    stopCourseFaq: 'Arrêter un cours',
    paymentFaq: 'Facturation',
    PAYED: "Payé",
    PRIX: "Prix",
    openQuestion: "Question ouverte",
    Consignes: "Consignes",
    Valeurdelaquestion: "Valeur de la question",
    point: "point",
    Nouveau_test: "Nouveau test",
    TEST_DE_CONNAISSANCE: "TEST DE CONNAISSANCE",
    Test_intermédiaire: "Test intermédiaire d'évaluation",
    Non_éliminatoire: "Non éliminatoire",
    Question_fermées: "Questions fermées",
    Test_de_fin_de_cours: "Test de fin de cours",
    Validant_le_diplome: "Validant le diplôme",
    Question_fermée_et_ou_ouvertes: "Questions fermées et/ou ouvertes",
    modal_exam_title: "Quel type de test souhaitez vous créer ?",
    AJOUTER_EXPLICATION: "AJOUTER UNE EXPLICATION",
    Explication: "Explication",
    Créer_votre_premiere_question: "Créer votre première question",
    Examen_final_envoyé_le: "Examen final envoyé le",
    En_attende_de_correction_par_le_professeur: "En attente de correction par le professeur",
    Répondre_aux_questions_fermees_ou_ouvertes_en_respectant_les_consignes: "Répondre aux questions fermées ou ouvertes en respectant les consignes.",
    Le_test_seffectue_une_seule_fois_et_détermine_lobtention_de_votre_diplome: "Le test s'effectue une seule fois et ",
    Le_test_seffectue_une_seule_fois_et_détermine_lobtention_de_votre_diplome_2: "détermine l'obtention de votre diplôme.",
    Réuissi: "Réussi",
    insuffisant: "insuffisant",
    Valider_ma_réponse: "Valider ma réponse",
    Rendu_le: "Rendu le",
    Par: "par",
    NOTER_LA_QUESTION: "NOTER LA QUESTION",
    Examens_à_noter: "Examens à noter",
    Examen_notés: "Examens notés",
    Tous_les_examens: "Tous les examens",
    aucun_examen_à_noter: "Aucun examen à noter",
    NOTE: "NOTE",
    à_noter: "à noter",
    Commentaire_du_professeur: "Commentaire du professeur",
    Pas_de_commentaire: "Pas de commentaire",
    Ajouter_un_commentaire: "Ajouter un commentaire",
    VALIDER_MA_NOTATION: "VALIDER MA NOTATION",
    EXAMEN_FINAL: "EXAMEN FINAL",
    exam1: "Indiquer ici le titre de l'examen final ...",
    exam2: "Indiquer ici le titre du test de connaissance",
    votreMessage: "Écrivez votre message...",
    noMessage: "Aucun message",
    noMessageDispo: "n'est encore disponible...",
    message_1: "Vous devez attendre que le professeur ",
    message_2: "ouvre la conversation",
    message_1_prof: "Démarrez une conversation en tapant",
    message_2_prof: "votre message ci-dessous.",
    textFaq: "Comment faire si je veux en savoir plus au sujet de la notation d’un de mes desvoir rédigé ?",
    draftstatdateFollow: "Dossier commencé le",
    finishdraftFollowButton: "Terminer mon dossier",
    gotocourseStudent: "Accéder au cours",
    myFormations: "Mes formations",
    SelectCourse: "Sélectionnez le cours",
    NoWebinarHasBeenCreatedYet: "Aucun webinaire n’a encore été créé.",
    TitleFormations: "Formations",
    InscriptionDate: "Inscrit le",
    biography: "Biographie",
    labelMailProfessor: "Email du professeur",
    comments: "Commentaires",
    noStudentInscribed: "Aucun étudiant n’est encore inscrit",
    demandTable: 'Demande faite le',
    createNewFormation: 'Nouvelle formation',
    selectFormatFormation: 'Sélectionnez un format',
    pathTitle: 'Parcours',
    createPath: 'créer un parcours',
    sessionTitle: 'Session',
    createSession: 'créer une session',
    requiredLevelText: 'Niveau requis pour s’inscrire au cours',
    anyLevelCourse: 'Tous niveaux',
    firstLevel: 'Débutant',
    secondLevel: 'Intermédiaire',
    thirdLevel: 'Expert',
    ANYLEVEL: 'Tous niveaux',
    BEGINNER: 'Débutant',
    courseDescriptionForm: 'Décrivez votre cours',
    descriptionTitle: "Description",
    courseContentTitle: "Contenu du cours",
    pathContentTitle: 'Contenu du parcours',
    sessionContentTitle: 'Contenu de la session',
    importVideoCourse: "Importation de la vidéo",
    createContentText: "Créer votre premier contenu",
    chapterPresentation: "Présentation du chapitre",
    textPresentationChapter: "Texte de présentation du chapitre",
    groupes: "Groupes",
    newGroup: "Nouveau groupe",
    groupTitle: "Titre du groupe d'étudiants",
    newTestModalTitle: "Nouveau test",
    alreadyCreateExam: "Vous avez déja créé un examen final",
    finalExmBreadcrunbTitle: 'Examen final',
    noGroupFound: "Aucun groupe d'étudiant crée",
    noGroupFound_2: "Votre recherche ne correspond à aucun groupe",
    updateGroupTitle: "Modifier le titre",
    deleteGroup: "Supprimer le groupe",
    updateGroup: "Changer le titre du groupe",
    addSubGroup: "Ajouter un sous-groupe",
    addStudentToGroup: "Ajouter des étudiants",
    Actions: "Actions",
    textConnaissance: "Test de connaissance",
    points: "points",
    connectedThereIs: "connecté il y a",
    messageDeleteGroup_1: "Ce groupe sera définitivement supprimé.",
    messageDeleteGroup_2: "Vous n'avez pas la possibilité de restaurer ce groupe si vous changez d’avis",
    messageDeleteGroup_3: "Groupe supprimé avec succès",
    closedQuestion: "Questions fermées",
    pointsQuestion: "point(s)",
    explicationText: "Écrivez votre explication...",
    allStudent: "Tous les étudiants",
    subGroup: "sous-groupe",
    changeGroup: "Changer de groupe",
    addToGroup: "Ajouter à un groupe",
    deleteFromGroup: "Retirer du groupe",
    documentTextForm: "Document",
    returnToCourse: "retour au cours",
    returnToPathButton: "retour au parcours",
    returnToSessionButton: "retour à la session",
    sendPublishCoursePRofTitle: 'Envoyer au directeur pour valider la publication',
    returnToStudentList: "Retour à la liste des étudiants",
    Previsualiser: "Prévisualiser",
    editeMode: "Éditer",
    withInscription: "Inscription sous dossier",
    freeTitleCourse: "offerts",
    questionPlaceholder: "Votre question ici...",
    responsePlceholder: "Ecrire une réponse ici..",
    explicationPlaceholder: "Ecrire une explication ici...",
    consignesPlaceholder: "Vos consignes ici...",
    goToCatalogueError: "Pour retourner à la page précédente cliquez sur le bouton ci-dessous.",
    errorCode: "Code d'erreur",
    oupsWord: "Oups!",
    errorInfoNotFound: "Désole, la page que vous recherchez semble introuvable.",
    errorInfoUnauthorized: "Aucune autorisation trouvée.",
    errorSubinfoUnauthorized: "Cette page n'est pas accessible au public.",
    errorInfoAccessRefused: "Accès refusé.",
    errorSubInfoAccessRefused: "Vous n'êtes pas autorisé à accéder à cette page.",
    serverProblem: "Problème de serveur",
    tryLater: "S'il vous plaît essayez dans quelques minutes.",
    unexpectedError: "Désolé, erreur inattendue.",
    errorInfoServerProblem: "La page que vous recherchez est actuellement en maintenance et sera bientôt de retour.",
    continueCreatingPath: "Désolé, vous ne pouvez pas publier ce parcours.",
    continueCreatingSession: "Désolé, vous ne pouvez pas publier cette session.",
    returnToPath: "retour au parcours",
    returnToSession: "retour à la session",
    courseDesactivationSuccess: "Cours désactivé avec succès",
    pathDesactivationSuccess: "Parcours désactivé avec succès",
    sessionDesactivationSuccess: "Session désactivée avec succès",
    textDeactivateCourse: 'Désactiver le cours',
    textDeactivatePath: 'Désactiver le parcours',
    textDeactivateSession: 'Désactiver la session',
    textreactiovationCourse: 'Cours activé avec succès',
    textreactiovationPath: 'Parcours activé avec succès',
    textreactiovationSession: 'Session activée avec succès',
    text_1_desactivateCourse: 'Ce cours ne sera plus accessible.',
    text_2_desactivateCourse: ' Vous avez la possibilité de réactiver le cours si vous changez d’avis',
    text_1_desactivatePath: 'Ce parcours ne sera plus accessible.',
    text_2_desactivatePath: ' Vous avez la possibilité de réactiver le parcours si vous changez d’avis',
    text_1_desactivateSession: 'Cette session ne sera plus accessible.',
    text_2_desactivateSession: ' Vous avez la possibilité de réactiver la session si vous changez d’avis',
    refuseCourseText: "Refuser la publication du cours",
    refusePathText: "Refuser la publication du parcours",
    refuseSessionText: "Refuser la publication de la session",
    backToListCourses: "retour à la liste des Cours",
    textRefusedCourseSuccess: "Cours refusé avec succès",
    textRefusedPathSuccess: "Parcours refusé avec succès",
    textRefusedSessionSuccess: "Session refusée avec succès",
    acceptCourseText: "Valider la publication du cours",
    acceptPathText: "Valider la publication du parcours",
    acceptSessionText: "Valider la publication de la session",
    textAcceptCourse: "Ce cours sera accessible à tous les étudiants.",
    textAcceptPath: "Ce parcours sera accessible à tous les étudiants.",
    textAcceptSession: "Cette session sera accessible à tous les étudiants.",
    publishCourseSuccessModal: "Cours publié avec succès",
    publishPathSuccessModal: "Parcours publié avec succès",
    publishSessionSuccessModal: "Session publiée avec succès",
    errorContentLength: "Vous devez avoir au moins un contenu (obligatoire).",
    errorPathLinkImg: "Visuel pour illustrer le parcours (obligatoire).",
    errorSessionLinkImg: "Visuel pour illustrer la session (obligatoire).",
    errorPathDescription: "Présentation texte du parcours (obligatoire).",
    errorSessionDescription: "Présentation texte de la sesssion (obligatoire).",
    typeTable: "type",
    parcoursTitle: "Parcours",
    PATH: "Parcours",
    progress: "avancement",
    gotopathStudent: "Accéder au parcours",
    gotosessionStudent: "Accéder au session",
    newContent: "nouveau contenu",
    chapterTitleBegin: "Titre du chapitre",
    testTitleBegin: "Titre du test de connaissance",
    examTitleBegin: "Titre de l'examen",
    onlineFormations: 'Formations en ligne',
    validateFormations: 'Formations à valider',
    allFormations: 'Toutes les formations',
    inscribedTimeStudent: 'inscrit le',
    suspendedAt: 'Suspendu le',
    newFinalExam: "Ajouter un examen final",
    backToMyrequest: "Retour à ma demande",
    beforeUploadText: "Glissez-déposez le fichiers que vous souhaitez partager",
    uploadFile: "SÉLECTIONNER UN FICHIER",
    fileUploaded: "FICHIER SÉLECTIONNÉ",
    Subscription: "Demande d'inscription",
    waiting: "En attente",
    fileValidated: "Dossier validé",
    fileRefused: "Dossier refusé",
    autorisationTraining: "Autorisations",
    autorisationTrainingTable: "Autorisation",
    autorzedStatus: 'Autorisé',
    unauthorized: 'Non autorisé',
    groupNameTable: 'Nom du groupe',
    changeMyDecision: "Modifier ma décision",
    backtodemand: "retour à la demande",
    cancelValidation: "Annuler la validation",
    downloadFileCertif: 'Télécharger mon diplôme',
    nextChapterText: 'Chapitre suivant',
    getMyDiplome: 'Mon diplôme',
    ListAllCourses: "Liste tous les cours",
    ListOnlineCourses: "Liste des cours en ligne",
    ListPendingCourses: "Liste des cours en attente",
    ListStudents: "Liste des étudiants",
    courseBy: "Cours donné par",
    SessionGivenBy: "Session donné par",
    PathGivenBy: "Parcours donné par",
    PaymentDetails: "Détails de paiement",
    Completeyourpurchasebyprovidingyourpaymentinformation: "Finalisez votre achat en fournissant vos informations de paiement.",
    LastNameandFirstName: "Nom et prénom",
    CreditCardNumber: "Numéro de carte de crédit",
    ExpirationDate: "Date d'expiration",
    confirmandpay: "confirmer et payer",
    Yourpaymentwassuccessful: "Votre paiement a été effectué avec succès.",
    StartThePath: "commencer Le Parcours",
    Yourpaymenthasbeendeclined: "Votre paiement a été refusé",
    StartTheCourse: "commencer Le cours",
    StartTheSession: "commencer La session",
    selectLevelCourse_4: "Tous niveaux",
    progressTable: "Avancement",
    notationLabel: 'Notation',
    singleClosedQuestion: "Question fermée",
    Du: "Du",
    Au: "Au",
    Examens: "Examens",
    fromWhereTraining: 'Provient de',
    congratsTraining: "Félicitations, vous avez términé le parcours.",
    donnerAvis_3: "Donner votre avis sur le parcours:",
    donnerAvis_4: "Donner votre avis sur la session:",
    donnerAvisButton: 'Donner votre avis',
    InitialPathName: "Titre du parcours",
    PathDecriptionCreation: "Décrivez votre parcours",
    prerequisite1: "Prérequis pour suivre ce parcours",
    skillsNeeded: "Connaissances et compétences que permet d\'acquérir ce parcours",
    pathCoverPhot: "Glissez-déposez une photo pour illustrer le parcours (jpg, png)",
    pathVideoDescription: "Présentation vidéo du parcours",
    pathVideoDrop: "Glissez-déposez la vidéo du parcours que vous souhaitez mettre en ligne (mp4, mov)",
    PathLevel: "Niveau requis pour s’inscrire au parcours",
    SessionTitleCreation: "Titre de la session",
    SessionLevel: "Niveau requis pour s’inscrire au session",
    SessionDescriptionCreation: "Décrivez votre session",
    prerequisite2: "Prérequis pour suivre cette session",
    SessionSkills: "Connaissances et compétences que permet d\'acquérir cette session",
    SessionCoverDescription: "Visuel pour illustrer la session",
    SessionCoverDrop: "Glissez-déposez une photo pour illustrer la session (jpg, png)",
    SessionDescriptionVideo: "Présentation vidéo de la session",
    optional: 'facultatif',
    titleVideoUpload: 'Ajoutez une vidéo pour présenter votre',
    importVideo: 'Importation de la vidéo',
    importVideoSuccess: 'Importation de la vidéo effectuée avec succès',
    transcodeVideo: 'Finalisation en cours',
    transcodeInProgress: 'La finalisation de l’importation est en cours. Vous pouvez continuer à compléter le contenu.',
    DragDropSession: "Glissez-déposez la vidéo de la session que vous souhaitez mettre en ligne (mp4, mov)",
    saveSchoolLogo: "Ajouter une photo",
    descriptionChoiseCours: "Créez votre cours avec ses chapitres, tests et examen final. Paramétrer votre dossier d'admission",
    descriptionChoisePath: "Assembler vos cours et parcours et créer un parcours de formation avec les conditions de passage",
    descriptionChoiseSession: "Assembler vos formations avec les conditions de passage paramétrer dans le temps",
    returnButton: 'retourner',
    waitingToPayment: 'En attend de paiement',
    noUnpaidFound: "Aucune attente de paiement",
    schoolAssistant: "Administrateur",
    passwordMandadory1:"Le mot de passe doit contenir",
    passwordMandadory2:"Au moins 8 caractères",
    passwordMandadory3:"Au moins une lettre majuscule et une lettre minuscule",
    passwordMandadory4:"Au moins 1 chiffre",
    passwordMandadory5:"Au moins un caractère spécial (#?!=@$%^&*-)", 
    passConfirmation:"Confirmation du mot de passe",
    RésultatCumulé:"Résultat cumulé",
    etudiantsInscrits:"Étudiants inscrits",
    TarifActuel:"Tarif actuel",
    TarifMoyen:"Tarif moyen",
    priceHistory:"Historique des tarifs",
    hidePricehistory:"Masquer l’historique des tarifs",
    saveModification:"Modification enregistrée!",
    Total :"Total",
    TotalInscrits :"Total inscrits",
    periodMedian:"Prix moyen de la période",
    totalMedian:"Total prix moyen",
    totalResult:"Total résutat",
    QUESTIONS_OUVERTES:"QUESTIONS OUVERTES",
    notValid:"Non valide",
    validAction:"Action validée",
    studentResponse:"Réponse de l'étudiant",
    markUpdate:"modifier ma notation",
    webinarEnded:"webinaire terminé",
    warning:"Attention !",
    changePrice :"Vous venez de changer le prix du cours:",
    priceNow:"Prix actuel:",
    priceAfter:"Nouveau prix affecté:",
    saveUpdate:"ENREGISTRER LA MODIFICATION",
    profchoice:"Sélectionner un professeur à qui attribuer le(s) cours",
    deleteReason:"Motif de la supppression*",
    disableAssistant:"Cet Administrateur ne pourra plus avoir accès à la platforme. Vous avez la possibilité de le réintégrer si vous changez d’avis.",
    addCommentToAssistant:"Ajouter un commentaire pour l'Administrateur",
    connectAccountPaymee:"Connectez votre compte Paymee",
    connect:"CONNECTER",
    idPaymee:"ID Paymee",
    assistantInvitation:"Inviter un Administrateur à s'inscrire",
    mailAssistant:"Email du Administrateur",
    membreInvitation:"Inviter un membre",
    role:"Rôle",
    newCourse:"Nouveau cours",
    newPath:"Nouveau parcours",
    newSession:"Nouveau session",
    profMandadotory:"Ce cours doit être attribuer à un enseignant.",
    profMandadtory2:"Sélectionner un professeur à qui attribuer ce cours*",
    rehireAssistant:"Cet Administrateur pourra avoir accès à la patforme. Vous avez la possibilité de suspendre l'Administrateur si vous changez d’avis.",
    dragToUpload:" Glissez-déposez votre photo",
    or:"OU",
    selectPhoto:"sélectionner votre photo",
    offerList:"Liste des offres",
    answer:"Votre réponse",
    completedParts:"parties effectuées",
    followPaths:"Suivre Parcours",
    followSessions:"Suivre Parcours",
    displayVideo:"Votre vidéo ou visuel de présentation",
    toValidate:"A valider",
    archiveSheet:"Archive",
    connectZoom:"Connect Zoom",
    account:"Compte",
    accountZoom:"Compte zoom",
    connectAccount:"Connecter mon compte",
    connectAccountToschool:"Connectez votre compte zoom avec votre école",
    changeOffer:"Changer l’offre",
    facture :"Factures",
    paymentMethod:"Méthodes de payement",
    cancelSubscription:"Annuler mon abonnement",
    offerPrice:"Frais de l’offre",
    nextDeadline:"Prochaine date d'échéance du paiement",
    deleteOffer:"Supprission d’offre",
    deleteMsgOffer:"Voulez-vous supprimer votre offre ?",
    passwordUpdated:"votre mot de passe à été modifier",
    passwordNoMatch:"les deux mot de passe ne correspond pas",
    modifyProfil:"Modifier mon profil",
    newEmail:"Nouvelle adresse e-mail",
    connectStripToSchool:"Connectez votre compte Stripe avec votre école",
    disconnect:"Déconnecter",
    connectPaymeeToSchool:"Connectez votre compte Paymee avec votre école",
    paymentMessage:"Finalisez votre achat en fournissant vos informations de paiement.",
    paymentConfimation:"confirmer le paiement",
    activate:"Activer",
    month:"Mensuels",
    yearly:"Annuels",
    offerMessage1:"Tout ce qui est inclus dans le plan Basic, plus :",
    Choisir :"Choisir",
    compareoffers:"Comparer toutes les fonctionnalités",
    offer:"Offer",
    monthlyFor:"Mensuels pour",
    yearFor:"Annuels pour",
    totalPrice:"Prix total:",
    valid:"Valide",
    ProfList:"Prof List",
    StudentList:"Student List",
    CourseList:"Course List",
    Inscrits:"Inscrits",
    team:"Équipe",
    assistant:"Administrateur",
    info:"Informations",
    shortDescription:"Description courte",
    shortDescription1:"Description courte du",
    nbrConx:"Nombre de connexion",
    inscDate:"Date d'inscription",
    role1:"role",
    totalRevenu:"Revenu total",
    makeAPayment:"effectuer le paiement",
    Fileacceptedon:"Dossier accepté le",
    params: "Paramètres",
    pricipalGroup: "Groupe principal",
    addPhoto:"ajouter votre photo",
    changePhoto:"Changer votre photo",
    changePhoto_2:"CHANGER VOTRE PHOTO",
    deletePhoto:"Supprimer la photo",
    seletionner:"SÉLECTIONNER",
    facturationDetails:"Détails de facturation",
    closedSchool:"Cette école est fermée actuellement",
    hold:"Veuillez",
    contact1:"Contacter le support",
    contact2:"pour plus d'informations.",
    question:"question",
    allTeam:"Toute l'équipe",
    assistants:"administrateurs",
    noTeam1:"Aucun(e) professeur(e)/Administrateur(e) trouvé(e).",
    noTeam2:"Veuillez effectuer une nouvelle recherche.",
    noTeamDefault_1: "Aucun membre de l'équipe est inscrit.",
    noTeamDefault_2: "Invitez un membre à s’inscire pour commencer.",
    noAssistant_1: "Aucun(e) Administrateur(e) trouvé(e).",
    noAssistant_2: "Veuillez effectuer une nouvelle recherche.",
    noAssistantDefault_1: "Aucun(e) Administrateur(e) est inscrit.",
    noAssistantDefault_2: "Invitez un(e) Administrateur(e) à s’inscire pour commencer.",
    TrainingToedit:"En cours d'édition",
    webinarForm1:"Sélectionner un professeur à qui attribuer ce webinaire*",
    webinarForm2:"Sélectionner un professeur",
    webinarForm3:"Pour les étudiants de  la formation :",
    listeDesAssistants:"liste des administrators",
    listeTeam:"liste de l'equipe",
    listOfUnpaidStudents:"liste des etudiants non payé",
    Nounpaidstudent:"Aucun étudiant non payé",
    memberMail:"Email du membre",
    integrations:"Intégrations",
    monForfait:"Mon forfait",
    Facturation:"Facturation",
    Detailsduforfait:"Details du forfait",
    Gerezoumodifiezvotreforfait:"Gérez ou modifiez votre forfait",
    Votreoffre:"Votre offre",
    Fraisdeloffre:"Frais de l’offre",
    Prochainedatedecheancedupaiement:"Prochaine date d'échéance du paiement",
    Modedepayement:"Mode de paiement",
    Changerleforfait:"Changer le forfait",
    Annulermonabonnement:"Annuler mon abonnement",
    Limitationduforfait:"Limitation du forfait",
    Suivrelalimitationdevotreforfaitactuel:"Suivre la limitation de votre forfait actuel",
    Voirlesforfaits:"Voir les forfaits",
    Vousavezatteintlalimitedaccesdevos:"Vous avez atteint la limite d'accès de vos",
    Vouspouvezajouterdes:"Vous pouvez ajouter des",
    endesactivantun:"en désactivant un",
    endesactivantune:"en désactivant une",
    actifouenameliorantvotreforfait:"en améliorant votre forfait",
    activeouenameliorantvotreforfait:"en améliorant votre forfait",
    Modesdepayement:"Modes de paiement",
    Gérezoumodifiezvotremodesdepaiement:"Gérez ou modifiez votre modes de paiement",
    congratsSession: "Félicitations, vous avez términé la session.",
    avisTitle1:"Mon avis sur le parcours",
    avisTitle2:"Mon avis sur la session",
    FormationAValiderDefault : "Aucune formation à valider existe.",
    FormationAValider : "Aucune formation à valider.",
    FormationEnLigne : "Aucune formation en ligne.",
    FormationEnLigneNotFound : "Aucune formation en ligne trouvée.",
    emptyListFormation1 : "Aucune formation trouvée",
    emptyListFormation2 : "Veuillez effectuer une nouvelle recherche.",
    noTrainingSearch:"Ce professeur n’a aucune formation pour le moment.",
    NoWebinarToValidate:"Aucun webinaire à valider.",
    NoWebinarIsComingSoon:"Aucun webinaire à venir.",
    NoWebinarIsOnGoing:"Aucun webinaire en cours.",
    NoWebinarHasEnded:"Aucun webinaire terminé.",
    NoWebinarIsRefused:"Aucun webinaire refusé.",
    NoWebinarIsAssociatedToThisCourse:"Aucun webinaire associé a ce cours.",
    NoWebinarExists:"Aucun webinaire trouvé.",
    invitationLinkExpired:"Le lien de votre invitation a expiré",
    verifiyAccess:"Veuillez vérifier l’accès avec le directeur de votre ",
    FirstWebinar:"Aucun webinaire n’a encore été créé.",
    FirstWebinar2:"Créez votre premier webinaire pour commencer.",
    PathCoverDescription:"Visuel pour illustrer le parcours",                                  
    transformKnowledge:"Transformez votre savoir en richesse",
    addToDescription:"Ajouter à la description",
    importListOfStudents:"Importer la liste des étudiants",
    Remplacer:"Remplacer",
    returnToStudentsList:"Retour à la liste des étudiants",
    invite:"inviter",
    continue:"Continuer",
    savedUsers:"J'ai le consentement des utilisateurs enregistrés dans ce fichier, selon la loi de protection des données personnelles du pays en vigueur.",
    download:"télécharger",
    loadInformation:"Chargement de vos informations, merci de patienter...",
    yourAccount:"De votre compte",
    emailSend:"Email envoyé",
    check:"vérifier",
    verifyYourEmail:"Vérifiez votre e-mails et ouvrez le lien que vous avez récu pour continuer",
    returnToConnexionPage:"retour à la page de connexion",
    save:"Enregistrer",
    coursResume:"Rédigez le résumé de ce cours, en présentant ses points les plus importants et montrant sa valeur ajoutée pour l'adhérant.     ",
    coursObjectives:"Listez les objectifs à atteindre durant ce cours.     ",
    preRequisite:"Ajoutez les prérequis nécessaires pour suivre ce cours.    ",
    skillsToLearn:"Listez les compétences à acquérir durant ce cours sous forme des mots clés.    ",
    pathResume:"Rédigez le résumé de ce parcours, en présentant ses points les plus importants et montrant sa valeur ajoutée pour l'adhérant.     ",
    pathObjectives:"Listez les objectifs à atteindre durant ce parcours.     ",
    pathPreRequisite:"Ajoutez les prérequis nécessaires pour suivre ce parcours.    ",
    pathSkillsToLearn:"Listez les compétences à acquérir durant ce parcours sous forme des mots clés.    ",
    sessionResume:"Rédigez le résumé de cette session, en présentant ses points les plus importants et montrant sa valeur ajoutée pour l'adhérant.     ",
    sessionObjectives:"Listez les objectifs à atteindre durant cette session.     ",
    sessionPreRequisite:"Ajoutez les prérequis nécessaires pour suivre cette session.    ",
    sessionSkillsToLearn:"Listez les compétences à acquérir durant cette session sous forme des mots clés.    ",
    timeNotAvailable:"Cet horraire n’est pas disponible, veuillez choisir un autre.",
    add:"Ajouter",
    added:"Ajouté",
    annulerImportation:"Annuler l’importation",
    annulerImportationConfirmation:"Êtes vous sûr(e) de vouloir annuler l’importation?",
    retourImportation: "Retour à l’importation",
    failedRequest:"Demande échoué",
    lowerDeal:"Pour passer à un forfait inférieur vous devez régler votre limite en fonction des limites du forfait choisi.",
    activeProfessors:"Professeurs actif",
    activeStudents:"Étudiants actif ",
    activeAssistants:"administrators actif ",
    professorList:"Liste des professeurs",
    trainingList:"Liste des formations",
    setAsPrincipal:"Définir comme principale?",
    replacePaymentMode:"Cela remplacera votre mode de paiement principal actuel.",
    deletePaymentMethod:"Supprimer le mode de paiement",
    deletePaymentMethodConfirmation:"Êtes vous sure de vouloire supprimé le mode de paiement.",
    deleteInvitation:"Annuler l’invitation",
    invitationAnnulationOfYourSchool:"L'annulation de l'invitation ne permettra plus à cette personne de devenir membre de ",
    yourSchool:" votre école.",
    youCanReinvite:"Vous pouvez toujours le réinviter.",
    keepInvitation:"garder l'invitation",
    noCourseFound:"Aucun cours trouvé",
    wantCreateCourse:"Vous souhaitez créer un cours?",
    cannotImportListOfStudents:"Vous ne pouvez pas importer une liste d'étudiants qui contient des erreurs.",
    firstName:"Le prénom",
    obligatoire:"  est obligatoire",
    name:"Le nom ",
    emailAddressNotValid:"Adresse e-mail invalide.",
    emailFormat:"Le format de l'e-mail doit être ",
    emailExample:"votrenom@exemple.com ",
    importationResult:"Résultat de l’importation",
    succesfulImportation:"importations réussies",
    lignes:"lignes",
    containErrors:"contiennent des erreurs",
    addStudent:"Ajouter un étudiant",
    importList:"Importer une liste",
    invitationSentSuccesfully:"Invitation envoyée avec succès",
    invitationSuccessAssistant:"Votre invitation a bien été envoyée à l'administrateur.",
    userAlreadyExists:"L'utilisateur avec cet email existe déjà.",
    enterValidEmail:"Veuillez saisir une adresse email valide.",
    professorLimitAtteined:"Vous avez atteint la limite d'accès de vos professeurs. Vous pouvez ajouter des professeurs en désactivant un professeur actif ou en améliorant votre forfait.",
    assistantLimitAtteined:"Vous avez atteint la limite d'accès de vos administrateurs. Vous pouvez ajouter des administrateurs en désactivant un administrateur actif ou en améliorant votre forfait.",
    compareOffers:"Comparer les offres",
    schoolUses:"Votre école utilise des fonctionnalités ",
    notIncludedInOffer:" qui ne sont pas prises en charge par l'offre  ",
    passToOffer:"   Si vous passez à l'offre ",
    allFonctionalities:", toutes les fonctionnalités ",
    areDesactivated:" de votre école seront désactivées et inaccessibles.",
    invitationsSentSuccesfully:' invitations ont été envoyées avec succès',
    automaticSendOfInvitations:" Envoi automatique d'invitations",
    invitationSendConfirmation:"Confirmez-vous l'envoi des invitations à la liste que vous avez importée ?",
    confirmAndSend:"Confirmer et Envoyer",
    followSession:"Suivre Session",
    principal:"Principale",
    noPaymentMethodAdded:"Aucun moyen de paiement ajouté",
    addPaymentMethod:"Ajouter un mode de paiement",
    remainning:"il vous reste",
    changePaymentMethod:"Modifier le mode de paiement",
    leaveMsg:"Nous sommes désolés de vous voir partir!",
    annulationWarning:"Une fois que vous annulez, votre compte restera actif jusqu'à la fin de votre facturation le",
    annulationWarning2:"Après cela, vous perdrez l'accès à votre école.",
    annulationConfirmation:"Êtes-vous sûr de vouloir annuler votre abonnement?",
    enterPasswordtoConfirm:"Entrez votre mot de passe pour confirmer",
    cancelationReasons:"Veuillez nous faire savoir pourquoi vous annulez votre abonnement. Vos commentaires, cela nous aidera à améliorer le produit",
    whyuCanceling:"Pourquoi annulez-vous votre abonnement",
    selectLowerCase:"Sélectionner",
    missingFonctionality:"Il manque une fonctionnalité que je veux",
    expensive:"Je pense que c'est trop cher",
    notInteressted:"Je n'utilise plus koors",
    movingToAnotherPlatform:"Je passe à une autre platforme",
    preciser:"Veuillez préciser",
    wantToTellUsMore:"Voulez-vous nous en dire plus?",
    unlimited:"Illimité",
    Cryptogramme:'Cryptogramme',
    savePaymentMethod:"Enregistrer ce mode de paiement dans mon profil",
    paymentPassed:"Paiement effectué",
    paymentSuccesfull:"Votre paiement a été traité avec succès.",
    returnToActivity:"Retour à l’activité",
    twoFreeMonths:"2 mois gratuits",
    essentialTools:"Les outils essentiels pour démarrer, sans frais cachés",
    forever:"pour toujours",
    actuelOffer:"Votre offre actuelle",
    selectFreeOffer:"Choisir l'offre Free",
    pathAndSessions:"Parcours et sessions",
    forProfessionalsAndNonProfessionals:"Pour les formateurs professionnels et infopreneurs.",
    yearlyBill:"Facturé annuellement",
    perMonth:"Mois",
    perYear:"par an",
    selectProOffer:"Choisir l'offre Professionnelle",
    courses:"cours",
    illimitedAccess:"Pour profiter de Koors en illimité.",
    perStudent:'par étudiant ',
    minimum:"minimum",   
    selectBussinessOffer:"Choisir l'offre Business",    
    illimitedNumberOfStudents:"Nombre d'étudiants illimité",
    illimitedNumberOfCours:"Nombre de cours illimité",
    illimitedNumberOfProfessors:"Nombre de professeurs illimité",
    illimitedNumberOfAssistants:"Nombre d’administrateurs illimité",
    subscriptionPayments:"Paiement de l'abonnement",
    Devise:"Devise",
    FacturationCycle:"Période de facturation",
    monthly:"Mensuel",
    yearly_2:"Annuel",
    ofReduction:"de réduction",
    minimalBill:"Facturation minimal",
    nextBill:"Prochaine facturation",
    confirmPayment:"Confirmation de paiement",
    reductionOnCurrentPlan:"Réduction sur le plan annuel ",
    verification:"Verification",
    noStudentFound_2:"Aucun étudiant trouvé",
    archivedTrainings:"Aucune formation archivée",
    refusedTrainings:"Aucune formation refusée",
    desactivatedTrainings:"Aucune formation désactivée",
    editedTrainings:"Aucune formation en cours d'édition",
    noTrainingHaveBeenCreated:"Aucune fromation n’a encore été créé.",
    createFirstTraining:"Pour commencer, créez votre premiére formation.",
    examsToNote:"Aucun examen à noter.",
    notedExams:"Aucun examen noté.",
    noExam:"Vous n\’avez aucun examen.",
    resendInvitation:"Renvoyer l’invitation",
    Copyright:"Copyright",
    rights:"Tous droits réservés.",
    noExamFound:"Aucun examen trouvé.",
    atteinedMaximum:"Vous avez atteint le nombre maximum autorisé pour un plan",
    updatePlan:"Veuillez mettre à jour votre plan pour créer plus de formations.",
    noBill:"Aucune facture.",
    finalExam:"Examen final .",
    selectAnImage:"SÉLECTIONNER UNE IMAGE",
    slideToAddAnImage:"Glissez-déposez une photo pour illustrer le webinaire",
    yourWebinarsAreIntegrated:"Vos webinaires sont intégrés",
    onThePlatform:"à la plateforme avec les",
    offreProAndBussiness:"offres Pro et Business",
    createWebinar:"Créez simplement vos webinaires directement dans Koors Sélectionnez et invitez directement vos étudiants à participer (les nouveaux étudiants éligibles sont automatiquement invités).",
    findReplayAndPublishWebinar:"Retrouvez les replays de vos webinaires et publiez les dans vos formations.",
    improveMyOffer:'Améliorer mon offre',
    soonWebinarsAvailable:"Bientôt des webinaires vous seront proposés.",
    willInformuSoon:"On vous tient informé.",
    courseResumeIntroduction:"Résumé introductif du cours...",
    reprendre:"Reprendre",
    freeOffreNoPaymentMethod:"Vous n’avez pas des modes de paiement. Vous êtes inscrits à l’offre Free.",
    Credit:"Credit",
    DebitCard:"Debit card",
    offre:"Offre",
    nameOnCard:"Nom sur la carte",
    traitement:"TRAITEMENT...",
    paiement:"Paiement",
    offersList:"Liste des offres",
    fourFreeMonths:"4 mois gratuits",
    wrongBirthDate:"Date de naissance erronée",
    selectCountry:"Choisissez un pays",
    cardAddedSuccessfully:"votre carte a été ajoutée avec succès",
    returnToParameters:"retour au paramètre",
    addNewPaymentMethod:"Ajouter un nouveau mode de paiement",
    verifyPassword:"Mot de passe incorrect, voulez vous verifier votre password",
    returnToOffer:"Retour a mon offre",
    subscriptionCancelled:"Votre abonnement a été bien annulé",
    perMonthPayedAnually:"mois, payé annuellement",
    downloadTemplate:"télécharger le template",
    toSee:"excel pour voir un exemple de format.",
    slideExcel:"Glissez-déposez votre fichier Excel",
    reposition:"REPOSITIONNER",
    importNewPhoto:"IMPORTER UNE NOUVELLE PHOTO",
    importPhoto:"IMPORTER UNE PHOTO",
    slideToSet:"faites glisser pour positionner",
    publishMyOpinion:"Publier mon avis",
    noWebinarIsPlanned:"Aucun webinaire n’est prévu pour le moment",
    titleSessionPlaceholder:"indiquer le titre de la session ici...",
    titlePathPlaceholder:'indiquer le titre du parcours ici...',
    addVideoUrl:"Ajouter l'URL de vidéo",
    itegrateVideo:"intégrer la vidéo",
    enterValidUrl:"Assurez-vous d\'avoir saisi une URL valide.",
    pasteVideoUrl:"Coller le lien vidéo...",
    addVideoToIntegrate:"Ajouter une url de vidéo à intégrer",
    perYearPayedAnually:"an, payé annuellement",
    creationDate:"date de création",
    createdAt:"Créé le",
    generic_decline: "Paiement refusé pour des raisons génériques",
    insufficient_funds : "Les fonds de la carte sont insuffisants",
    lost_card : "La carte est déclarée perdue",
    stolen_card : "La carte est déclarée volée",
    expired_card : "La carte est expirée",
    incorrect_cvc : "Le code CVC est incorrect",
    processing_error : "Paiement refusé à cause d’erreur de traitement",
    gender:"Genre",
    male:"Homme",
    female:"Femme",
    noCommentFound1:"Aucun commentaire",
    lastUpdate:"Dernière publication",
    succesfulImportation1:"importation réussie",
    lignes1:"ligne",
    containErrors1:"contient des erreurs",
    schoolAssistant1: "Administrateurs",
    emptyTitleError:"Titre du cours (obligatoire)",
    emptyPathTitleError:"Titre du parcours (obligatoire)",
    emptySessionTitleError:"Titre de la session (obligatoire)",
    Webinar1: "Évènements",
    notFound1:"Ajoutez plus d’étudiants et passez la vitesse supérieure ",
    notFound2:"Koors regroupe les meilleures fonctionnalités pour  diffuser des cours en ligne au plus grand nombre." ,
    notFound3:"Grâce à Koors, vous gérez facilement vos communautés d'étudiants / clients et vous gardez le contact en permanence.",
    emailAlreadyUsed:"Cette adresse e-mail est déja utilisée",
    reduce_discount:"% de réduction",
    reduce_amount:"€ de réduction",
    coupons:"coupons",
    createAndPersonalizeCoupon:"Créez et personnalisez vos coupons en illimité",
    couponGeneration:"La génération de coupon n’a jamais été aussi simple avec ",
    couponGeneration2:"Koors! Générez des coupons en quelques clics et décidez",
    couponGeneration3:" de leur diffusion.",
    withKoors:"Avec Koors, vous pouvez créer, personnaliser et définir les",
    withKoors2:" délais et dates d'expiration de vos coupons.",
    tarificationTitle: "Tarification",
    MONTH:"mois",
    YEAR:"ans",
    WEEK:"semaine",
    QUARTER:"tremestre",
    SEMESTER:"semestre",
    Clôturé:"Clôturé",
    Ouvert:"Ouvert",
    TITREdusondage:"TITRE du sondage",
    Auteur:"Auteur",
    CRÉELE:"CRÉE LE",
    CloturéLE:"Cloturé LE",
    RÉPONSEs:"RÉPONSEs",
    Créerunsondage:"Créer un sondage",
    sondages:"Sondages",
    sondage:"Sondage",
    CompletedStudent: "Terminé",
    inscribedInSchool:"Inscription dans l'école",
    catalogVisits:"Visites de la page catalogue",
    currentStudentNumber:"Nombre d'étudiants en cours",
    currentStudentsWithDiploma:"Nombre d'étudiants diplômés",
    noSchooling:"non mentioné"


};
