/* eslint react/no-did-mount-set-state: 0 */
import { ComponentProps, FC, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../global-state/store/root-reducers';
import { updatePath } from '../../global-state/actions/breadcrumb-actions'
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { uploadImageCourse } from '../../global-state/actions/course-actions';
import { clearFormVideo } from '../../global-state/actions/upload-video-course-actions';
import { debounce } from 'lodash';
import ChipInput from 'material-ui-chip-input'
import { useTranslation } from 'react-i18next';
import TextareaWithoutBorderElement from '../../elements/text-area/text-area-without-border-element';
import UploadVideoCourseComponent from '../card-uploads/upload-video-course-component';
import { createTraining, getSessionSubtitleVimeoUploaded } from '../../global-state/actions/training-actions';
import axiosInstance from '../../helpers/interceptors';
import CropImage from '../card-uploads/crop-image-course-component';
import InputUploadImage from '../../elements/inputs/input-upload-image';
import HtmlTooltip from '../../elements/tooltip/Tooltip-Customized-element';
import ContainerUploadSubtitle from '../card-uploads/container-upload-subtitle-component';
import ReactQuill from 'react-quill';
import {
    // getAllSubtitleVimeoUploaded, 
    // clearChapterSubtitleVimeoUploaded 
} from '../../global-state/actions/upload-video-chapter-actions'

const DescriptionSessionFormComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const [session, setSession] = useState<any>();
    const [msgLimitInput, setmsgLimitInput] = useState("");
    const [withUpload, setWithUpload] = useState<any>()
    const { t } = useTranslation();

    const config = {
        entityType: "SCHOOL",
        entityId: props?.schoolInfo.id,
        creator: props?.connectedUser?.idPerson,
        idProf: props?.connectedUser?.idPerson,
        // status: "DRAFT",
        // isDiplomat:false
    }
    useEffect(() => {
        setSession({ ...props?.session, ...config });
    }, [props?.session]);

    function deleteVideo(e: any) {
        e.preventDefault();
        const initialSession = { ...session };
        initialSession['video'] = '';
        initialSession['type'] = 'SESSION';
        setSession(initialSession);
        props?.createTraining("sessions", initialSession)
    }

    function uploadVideo(link: string) {
        const initialSession = { ...session };
        initialSession['video'] = link;
        initialSession['type'] = 'SESSION';
        setSession(initialSession);
        props?.createTraining("sessions", initialSession)
    }

    const textAreaAdjust = (element: any, tag: string) => {
        if (element?.value?.length === 254) setmsgLimitInput("Le titre de la session ne doit pas dépasser les 254 caractères (Espaces inclus)")
        if (element?.value?.length < 254) setmsgLimitInput("")

        const initialSession = { ...props?.session };
        if (tag === 'text') {
            initialSession[element.id] = element.value;
            setSession(initialSession);
        } else if (tag === 'themes') {
            let themesLength = 0
            element.value?.map((element: any) =>
                themesLength += element.length
            )
            if (themesLength + element.value.length > 255) {
                element.value.pop()
            }
            else {
                initialSession['theme'] = element.value;
            }
        }
        else {
            initialSession['skills'] = element;
            let elementToAdd = element[element?.length - 1]
            if (elementToAdd?.length > 254) {
                element.pop()
            }
        }
        initialSession['type'] = 'SESSION';
        debouncedChangeHandler(initialSession)
    }

    const createSessionApi = async (sessionObject: any) => {
        await props?.createTraining("sessions", sessionObject)
    }

    const deleteFile = () => {
        const initialSession = { ...session };
        initialSession['linkImg'] = '';
        initialSession['type'] = 'PATH';
        setSession(initialSession);
        props?.createTraining("paths", initialSession)
    }

    const addFile = async (file: any) => {
        const form = new FormData();
        form.append('file', file[0]);
        const initialSession = { ...session };
        initialSession['type'] = 'SESSION';
        const baseUrl = process.env.REACT_APP_BASE_URL4;
        const link: any = await axiosInstance.post(baseUrl + "upload/image", form).then((res) => res.data.link);
        initialSession.linkImg = link
        await props?.createTraining("sessions", initialSession)
        setSession(initialSession);
    };

    const debouncedChangeHandler = useCallback(debounce(createSessionApi, 1000), []);

    useEffect(() => {
        //     // getAllSubtitleVimeoUploaded(props?.session.video.split('/').pop())
        if (props?.session?.video != undefined && props?.session?.video?.length != 0) {
            props.getSessionSubtitleVimeoUploaded(props?.session?.video.split('/').pop())
        }
    }, [props?.session.video]);


    const [isLoadingVideoToGetSubtitle, setIsLoadingVideoToGetSubtitle] = useState<{ from: string, isLoading: boolean }>({
        from: 'session', isLoading: false
    })

    const getSubtitleUploaded = () => {
        setIsLoadingVideoToGetSubtitle(prev => ({ ...prev, isLoading: true }));

        if (props?.session?.video != undefined && props?.session?.video?.length != 0) {
            props.getSessionSubtitleVimeoUploaded(props?.session?.video.split('/').pop())
        }
    }

    return (
        <>
            <style>
                {`
                .ql-container.ql-snow {
                    border:none;
                    background-color:white;
                    padding-top:8px;
                }
                .ql-editor {
                    min-height:30px !important;
                    padding:0;
                    font-size:16px;
                    font-family: "Public", sans-serif;
                }
                .ql-editor.ql-blank::before {
                    color: #cacbce;
                    font-style: normal; 
                    left: 0px;
                    right: 8px;
                    
                }
            `}
            </style>
            <div className=" px-4 py-4 completed-course mx-auto " style={{ maxWidth: 1248 }}>
                <section >
                    <div className="d-flex flex-column w-100 my-5 position-relative">
                        <h3 className="H3-Headline mb-3" >{t('SessionTitleCreation')} *</h3>
                        <input
                            dir="ltr"
                            type="text"
                            id="title"
                            value={session?.title || ''}
                            onChange={(e) => textAreaAdjust(e.target, 'text')}
                            className="input-title"
                            placeholder={t('titleSessionPlaceholder')}
                            maxLength={254}
                        />
                        <span className='msg-error'>{msgLimitInput}</span>
                    </div>
                    <div>
                        <h3 className="H4-Subtitle mb-3" >Thématique de la session </h3>
                        <ChipInput onChange={(theme) => textAreaAdjust({ id: "themes", value: theme }, 'themes')} defaultValue={session?.theme} classes={{ root: 'transparent', underline: 'transparent' }} fullWidth={true} alwaysShowPlaceholder={true} placeholder={"Saisir la thématique du cours"} />
                    </div>
                </section>
                <section style={{ backgroundColor: "#FFFFFF" }}>
                    <div className="py-5">
                        <div className="d-flex flex-column w-100">
                            <h3 className="H3-Headline mb-3" >{t('SessionLevel')}</h3>
                            <div className="d-flex flex-row align-items-center justify-content-start flex-wrap w-100">
                                <input maxLength={254} dir="ltr" onClick={(e) => textAreaAdjust({ id: 'level', value: 'ANYLEVEL' }, 'text')} className="input-style-radio-button" type="radio" name="exampleRadios3" id="anyLevel" value="option1" defaultChecked={(session && session?.level === 'ANYLEVEL')} />
                                <label style={{ cursor: 'pointer', width: 170, textAlign: 'center' }} className='me-4' htmlFor="anyLevel">{t('anyLevelCourse')}</label>

                                <input maxLength={254} dir="ltr" onClick={(e) => textAreaAdjust({ id: 'level', value: 'BEGINNER' }, 'text')} className="input-style-radio-button" type="radio" name="exampleRadios3" id="beginner" value="option2" defaultChecked={(session && session?.level === 'BEGINNER')} />
                                <label style={{ cursor: 'pointer', width: 170, textAlign: 'center' }} className='me-4' htmlFor="beginner">{t('firstLevel')}</label>

                                <input maxLength={254} dir="ltr" onClick={(e) => textAreaAdjust({ id: 'level', value: 'INTERMEDIATE' }, 'text')} className="input-style-radio-button" type="radio" name="exampleRadios3" id="intermediate" value="option1" defaultChecked={(session && session?.level === 'INTERMEDIATE')} />
                                <label style={{ cursor: 'pointer', width: 170, textAlign: 'center' }} className='me-4' htmlFor="intermediate">{t('secondLevel')}</label>

                                <input maxLength={254} dir="ltr" onClick={(e) => textAreaAdjust({ id: 'level', value: 'ADVANCED' }, 'text')} className="input-style-radio-button" type="radio" name="exampleRadios3" id="expert" value="option2" defaultChecked={(session && session?.level === 'ADVANCED')} />
                                <label style={{ cursor: 'pointer', width: 170, textAlign: 'center' }} htmlFor="expert" >{t('thirdLevel')}</label>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="py-5">
                    <h3 className="H3-Headline mb-3">{t('SessionDescriptionCreation')} *</h3>
                    <div className="d-flex justify-content-center w-100 bg-with-border" style={{ borderRadius: "3px" }}>
                        <div style={{ width: 'calc(100% - 408px)', marginTop: 56, marginBottom: 56 }}>
                            <div className='' style={{ width: "100%" }}>
                                <div className="d-flex align-items-start mb-2">
                                    <label className="body-md bold black-800">{t('resume')}*</label>
                                    <HtmlTooltip content={<div className='d-flex flex-column'><div>{t('sessionResume')}</div></div>}>
                                        <span className="material-icons-outlined neutral-3 ms-2" style={{ cursor: 'pointer' }}>info</span>
                                    </HtmlTooltip >
                                </div>
                                <ReactQuill modules={{ "toolbar": false }} placeholder={t('textAreaPlaceholder')} value={session?.resume} onChange={(e: string) => textAreaAdjust({ id: 'resume', value: e }, 'text')} />
                            </div>
                            <div className=' mt-5' style={{ width: "100%" }}>
                                <div className="d-flex align-items-start mb-2">
                                    <label className="body-md bold black-800">{t('objectives')}</label>
                                    <HtmlTooltip content={<div className='d-flex flex-column'>
                                        <div>
                                            {t('sessionObjectives')}</div>
                                    </div>}>
                                        <span className="material-icons-outlined neutral-3 ms-2" style={{ cursor: 'pointer' }}>info</span>
                                    </HtmlTooltip >
                                </div>
                                <TextareaWithoutBorderElement value={session?.objectives} id={"objectives"} placeholder={t('textAreaPlaceholder')} onChange={(e: string) => textAreaAdjust({ id: 'objectives', value: e }, 'text')} />
                            </div>
                            <div className=' mt-5' style={{ width: "100%" }}>
                                <div className="d-flex align-items-start mb-2">
                                    <label className="body-md bold black-800">{t('prerequisite2')}</label>
                                    <HtmlTooltip content={<div className='d-flex flex-column'>
                                        <div>
                                            {t('sessionPreRequisite')}</div>
                                    </div>}>
                                        <span className="material-icons-outlined neutral-3 ms-2" style={{ cursor: 'pointer' }}>info</span>
                                    </HtmlTooltip >
                                </div>
                                <TextareaWithoutBorderElement value={session?.prerequisite} id={"prerequisite"} placeholder={t('textAreaPlaceholder')} onChange={(e: string) => textAreaAdjust({ id: 'prerequisite', value: e }, 'text')} />
                            </div>
                            <div className="skills-form-professor mt-5" style={{ width: "100%" }}>
                                <div className="d-flex align-items-start mb-2">
                                    <label className="body-md bold black-800">{t('SessionSkills')}</label>
                                </div>
                                <ChipInput classes={{ root: 'transparent', underline: 'transparent' }} fullWidth={true} alwaysShowPlaceholder={true} placeholder={t('skillsPlaceholder')} defaultValue={session?.skills} onChange={(chips) => textAreaAdjust(chips, 'chips')} />
                            </div>
                        </div>
                    </div>
                </section>
                <section style={{ backgroundColor: "#FFFFFF" }} className="py-5">
                    <section style={{ backgroundColor: "#FFFFFF" }} className="py-5">
                        <div >
                            <h3 className="H3-Headline mb-3">{t('SessionCoverDescription')} *</h3>
                            {/* <UploadImageCourseComponent /> */}
                            <>{!!session?.linkImg && session.linkImg !== '' ?
                                <CropImage file={session?.linkImg + "?cacheblock=true"} width={840} addFile={(image: any) => addFile(image)} deleteFile={deleteFile} />
                                : <InputUploadImage description={t('SessionCoverDrop')} textButton={t('uploadImage_2')} addFile={(image: any) => addFile(image)} />}
                            </>

                        </div>
                    </section>
                </section>
                <section className="py-5">
                    {/* <h3 className="H3-Headline mb-3">{t('SessionDescriptionVideo')}</h3> */}
                    <UploadVideoCourseComponent
                        titleComponent='session'
                        description={t('DragDropSession')}
                        buttonText1={t('uploadVideo_2')}
                        t={t}
                        deleteFile={deleteVideo}
                        uploadVideo={uploadVideo}
                        file={session?.video}
                        isLoadingVideoToGetSubtitle={isLoadingVideoToGetSubtitle}
                        setIsLoadingVideoToGetSubtitle={setIsLoadingVideoToGetSubtitle}
                        isUploading={() => setWithUpload(true)}
                        withUpload={withUpload}
                    />
                </section>
                {props?.session?.video?.length > 0 && <ContainerUploadSubtitle
                    getSubtitleUploaded={getSubtitleUploaded}
                    idVideo={props?.session.video.split('/').pop()}
                    type='isSession'
                />}
            </div>
        </>
    );
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators({
        createTraining,
        uploadImageCourse,
        updatePath,
        clearFormVideo,
        getSessionSubtitleVimeoUploaded,
    }, dispatch);

const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    loggedIn: state.authReducer.loggedIn,
    courseTitle: ownProps?.courseTitle,
    session: state.trainingReducer.session,
    history: ownProps?.history,
    t: ownProps?.t,
    idVideo: state.uploadChapterReducer.idVideo,

});

export default connect(mapStateToProps, mapDispatchToProps)(DescriptionSessionFormComponent);