import { ComponentProps, FC, useEffect, useState } from 'react'
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { connect } from "react-redux";
import "../../translations/i18n";
import { useTranslation } from 'react-i18next';
import { getUserProfileDetails } from '../../global-state/actions/auth-actions';
import AddAndUpdateZoomAccountCredentialComponent from '../../components/profile-components/add-and-update-zoom-account-credential-component';
import FinanceProfileComponent from '../../components/profile-components/finance-profile-component';
import { getActualTab } from '../../global-state/actions/auth-actions';
import OfferListComponent from '../../components/subscription/offer-list-component';

const SubscriptionPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(props?.loadingProfile);
    const [userProfileDetails, setUserProfileDetails] = useState<any>(props?.userProfileDetails);
    useEffect(() => {
        props?.getUserProfileDetails(props?.connectedUser?.id)
        setLoading(props?.loadingProfile)
    }, [])

    useEffect(() => {
        setLoading(props?.loadingProfile);
    }, [props?.loadingProfile])

    useEffect(() => {
        setUserProfileDetails(props?.userProfileDetails);
    }, [props?.userProfileDetails])


    useEffect(() => {
        setUserProfileDetails(props?.userProfileDetails);
    }, [props?.userProfileDetails?.photo])


    return (
        // <div className="container-fluid" style={{ backgroundColor: "#FFFFFF" }}>

        //     {loading === false ? (

        //         <>
        //             <div className="container-fluid">
        //                 <div className="row justify-content-center">
        //                     <div className="col-md-12" style={{ backgroundColor: '#FFFFFF' }}>

        //                         <div className="d-flex align-items-center flex-wrap px-2 pb-4 mt-3 mx-auto" style={{ maxWidth: '1200px' }}  >
        //                             <>
        //                                 <button className={`btn-Tabs ${(props?.actualTab === 'Account') ? '' : 'unselected'} me-3 d-flex align-items-center`} onClick={() => { props?.getActualTab("Account")  }} style={{ marginRight: "16px" }}>Mon compte </button>
        //                                 <button className={`btn-Tabs ${(props?.actualTab  === 'Integration') ? '' : 'unselected'} me-3 d-flex align-items-center`} onClick={() => {props?.getActualTab("Integration") }} style={{ marginRight: "16px" }}>Integrations</button>
        //                                 <button className={`btn-Tabs ${(props?.actualTab  === 'Finanace') ? '' : 'unselected'} me-3 d-flex align-items-center`} onClick={() => { props?.getActualTab("Finanace") }} >Détails de facturation
        //                                 </button>
        //                             </>
        //                         </div>
        //                     </div>
        //                 </div >
        //             </div>
        //             <div style={{ marginBottom: 24 }}></div>

        //             {props?.actualTab  === 'Account' ?
        //                 <> <div className="row" style={{ maxWidth: "1200px", margin: 'auto' }}>
        //                     <h2 className="H2-Headline pt-3 pb-3" > {t("myAccount")}</h2>
        //                 </div>
        //                     <div>
        //                         <div>
        //                             <UpdateProfileInfosComponent />
        //                         </div>
        //                         <div style={{ paddingTop: "33px" }}>
        //                             <UpdateUserMailComponent />
        //                         </div>
        //                         <div style={{ paddingTop: "33px" }}>
        //                             <UpdatePasswordComponent />
        //                         </div>
        //                         {(props?.connectedUser?.role?.includes("role_director") || props?.connectedUser?.role?.includes("role_prof")) &&
        //                             <div style={{ paddingTop: "33px" }}>
        //                                 <AddAndUpdateZoomAccountCredentialComponent />
        //                             </div>}
        //                         <div style={{ paddingTop: "33px", paddingBottom: "56px" }}>
        //                             <DeleteAccountComponent />
        //                         </div>
        //                     </div>
        //                 </>


        //                 : (props?.actualTab  === 'Integration') ? <div>fsdfqsfsdfsdf</div> : <><div className="row" style={{ margin: 'auto', backgroundColor: '#FBFBFD' }}>
        //                          <div className="d-flex align-items-center flex-wrap px-2 pb-4 mt-3 mx-auto" style={{ maxWidth: '1200px' }}  >
        //                     <h2 className="H2-Headline pt-3 pb-3" >Détails de facturation
        //                     </h2> </div>      <FinanceProfileComponent />
        //                 </div>
        //                     <div>

        //                     </div></>
        //             }

        //         </>



        //     ) : (
        //         <div>
        //             <div>
        //                 <UpdateProfileInfosLoadingComponent />
        //             </div>
        //             <div style={{ paddingTop: "33px" }}>
        //                 <UpdateUserMailLoadingComponent />
        //             </div>
        //             <div style={{ paddingTop: "33px" }}>
        //                 <UpdatePasswordLoadingComponent />
        //             </div>
        //             <div style={{ paddingTop: "33px", paddingBottom: "56px" }}>
        //                 <DeleteAccountLoadingComponent />
        //             </div>
        //         </div>
        //     )}
        // </div>
        <OfferListComponent/>
    )
}

const mapStateToProps = (state: AppState, ownProps: any) => ({
    loadingProfile: state.authReducer.loadingProfile,
    userProfileDetails: state.authReducer.connectedUserInfo,
    connectedUser: state.authReducer.connectedUser,
    actualTab:state.authReducer.actualTab
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    getUserProfileDetails,getActualTab
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPage);