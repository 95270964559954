import { useState, useEffect } from 'react'
import ReactPlayer from 'react-player';
import InputUploadVideo from "../../elements/inputs/input-upload-video";
import { checkVideoAvalibility, checkVideoTranscode, getVideoSubtitles, uploading } from '../../services/video.uploader.service';
import LoadingUploadVideoModalComponent from '../modals/modal-forms/loading-upload-video-modal-component';
import ContainerUploadSubtitle from "./container-upload-subtitle-component";
import * as tus from "tus-js-client";
import Skeleton from '@material-ui/lab/Skeleton'



{/* old component : UploadVideoChapterComponent*/ }

interface UploadProcessBody {
    uploadStatus: UploadStatus,
    progress: number
}
type UploadStatus = "" | "UPLOADING" | "TRANSCODING" | "CHECKING" | "AVAILABLE" | "NOT_AVAILABLE" | "ERROR" | "LOADING"
function VideoUploader(props: any) {

    const [videoHeight, setVideoHeight] = useState<string>()
    const [videoUrl, setvideoUrl] = useState<string>('')
    const [uploadSubtitles, setUploadSubtitles] = useState<boolean>(false)
    const [withSubtitles, setWithSubtitles] = useState<boolean>(false)
    const [uploadStatus, setUploadStatus] = useState<UploadProcessBody>({
        uploadStatus: "",
        progress: 0
    })
    const [videoId, setVideoId] = useState<number>()


    useEffect(() => {
        return () => {
            setvideoUrl("")
            setUploadStatus({
                uploadStatus: "",
                progress: 0
            })
        }
    }, [])



    useEffect(() => {
        if (withSubtitles !== props?.withSubtitles) {
            setWithSubtitles(props.withSubtitles)
        }
    }, [props?.withSubtitles])


    useEffect(() => {
        if (props?.file?.length !== 0 || props?.file !== videoUrl) {
            verifyVideo(props.file)

        }
    }, [props?.file])

    useEffect(() => {
        if (props?.uploadSubtitles !== uploadSubtitles) {
            setUploadSubtitles(props?.uploadSubtitles)
        }
    }, [props?.uploadSubtitles])


    const verifyVideo = (file: string) => {
        setvideoUrl(file)
        if (props?.file && (props?.file.includes('dai.ly') || props?.file.includes('dailymotion.com'))) {
            setVideoHeight('502px');
        }
        else {
            setVideoHeight('100%')
            setUploadStatus({
                uploadStatus: "CHECKING",
                progress: 100
            })
        }
        setUploadStatus({
            uploadStatus: "AVAILABLE",
            progress: 0
        });
        const match = props?.file.match(/\/video\/(\d+)/);
        setVideoId(match ? match[1] : null);
    }

    const onDelete = (e: any) => {
        props?.deleteFile(e);
        setvideoUrl('');
        setUploadStatus({
            uploadStatus: "",
            progress: 0
        });

    }

    const onDrop = async (accepted: any) => {
        const { createVideoLink, getVideoLink }: any = props;
        setUploadStatus({
            uploadStatus: "UPLOADING",
            progress: 1
        })
        let result = await uploading({ createVideoLink, getVideoLink, checkStopped: false, domain: window.location.host, videoData: accepted[0], size: accepted[0].size });
        await props.uploadVideo(result.videolink)
        await uploadToVimeo(result?.videolink, result?.linkToUploadVideo, accepted[0], accepted[0].size, 0, result?.uri)
        await setvideoUrl(result?.videolink)
    };

    useEffect(() => {
        setTimeout(() => {
            checkVideo()
        }, 2000)
    }, [uploadStatus])

    const checkVideo = async () => {
        if (uploadStatus.uploadStatus === 'UPLOADING' && uploadStatus.progress === 100) {
            setUploadStatus({
                uploadStatus: "TRANSCODING",
                progress: 50
            })
        }
        else if (uploadStatus.uploadStatus === "TRANSCODING" && uploadStatus.progress === 50) {
            let result = await checkVideoTranscode(videoUrl);
            setUploadStatus(result)
        } else if (uploadStatus.uploadStatus === "CHECKING" && uploadStatus.progress === 100) {
            const result: UploadProcessBody = await checkVideoAvalibility(videoUrl, {
                uploadStatus: "CHECKING",
                progress: 0
            })
            setUploadStatus(result)
        }
        else if (uploadStatus.uploadStatus === "NOT_AVAILABLE") {
            setUploadStatus({
                uploadStatus: "CHECKING",
                progress: 100
            })
        } else if (uploadStatus.uploadStatus === "ERROR") {
            setUploadStatus({
                uploadStatus: "",
                progress: 0
            })
            setvideoUrl('')
        }
    }

    const uploadToVimeo = async (videoUri: any,
        uploadLink: any,
        videoData: any,
        size: any,
        uploadOffset: any,
        uri: any) => {

        try {
            setUploadStatus({
                uploadStatus: "UPLOADING",
                progress: 1
            })
            const upload: any = new tus.Upload(videoData, {
                endpoint: "https://api.vimeo.com/me/videos",
                uploadUrl: uploadLink,
                retryDelays: [0, 3000, 5000, 10000, 20000],
                metadata: {
                    name: videoData.name,
                    filetype: videoData.type,
                },
                headers: {},
                onProgress: function (bytesUploaded, bytesTotal) {
                    let percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
                    setUploadStatus({
                        uploadStatus: "UPLOADING",
                        progress: parseFloat(percentage)
                    })
                }
            });
            await upload.start()
            return "suceess"

        } catch (error) {
            console.error("Error checking video availability:", error);
            return {
                uploadStatus: "ERROR",
                progress: 0
            };
        }

    }

    // const handleVimeoError = () => {
    //     if(uploadStatus.uploadStatus==='AVAILABLE' ){
    //         setUploadStatus({
    //         uploadStatus: "CHECKING",
    //         progress: 100
    //     })
    //     }
    // }

    useEffect(() => {
        console.log("uploadStatus", uploadStatus);

    }, [uploadStatus])



    return (
        <div>
            {(videoUrl.length === 0 && uploadStatus.uploadStatus === "") &&
                <div>
                    <InputUploadVideo addFile={(video: any) => onDrop(video)} description={props?.description} textButton2={props?.buttonText2} textButton1={props?.buttonText1} onUrlAdd={props?.onUrlAdd} />
                </div>}

            {(videoUrl.length != 0 && uploadStatus.uploadStatus === "AVAILABLE") &&
                <div>
                    <div className="bg-full-black d-flex align-items-center justify-content-center" style={{ minHeight: '502px', position: 'relative' }}>
                        <button onClick={(e) => onDelete(e)} className="btn-QuickActions white" style={{ position: 'absolute', right: 32, top: 32 }}><span className="material-icons-outlined" color="white">delete</span></button>
                        <ReactPlayer
                            controls={true}
                            config={{
                                vimeo: {
                                    playerOptions: {
                                        responsive: true,
                                    },
                                },
                                youtube: {
                                    embedOptions: {
                                        height: '502px',
                                    }
                                },
                                dailymotion: {}
                            }}
                            width='80%'
                            height={videoHeight}
                            stopOnUnmount={false}
                            url={decodeURI(videoUrl)}
                        //onError={()=>{ handleVimeoError()}}
                        />
                    </div>
                    <div>
                        {uploadSubtitles === true && videoUrl?.length !== 0 &&
                            <ContainerUploadSubtitle
                                getSubtitleUploaded={getVideoSubtitles}
                                idVideo={videoId}
                                type='isChapter'
                            />
                        }
                    </div>
                </div>
            }
            {(uploadStatus.uploadStatus === "TRANSCODING" || uploadStatus.uploadStatus === "UPLOADING") &&
                <div>
                    <LoadingUploadVideoModalComponent
                        progress={uploadStatus.uploadStatus === "UPLOADING" ? uploadStatus.progress : 100}
                        transcoding={uploadStatus.uploadStatus === "UPLOADING" ? false : true}
                        readyToStream={""}
                        uploading={uploadStatus.uploadStatus === "UPLOADING" ? true : false}
                    />
                </div>
            }
            {(uploadStatus.uploadStatus === "LOADING" || uploadStatus.uploadStatus === 'CHECKING') &&
                <div>
                    <Skeleton variant="rect" animation="wave" width={'70%'} height={502} className="skeleton w-100 skeleton-main col-xs-7 me-4 second-card-list-activities" />
                </div>
            }
            {/* {videoUrl.length > 0 && <ContainerUploadSubtitle
                getSubtitleUploaded={()=>{}}
                idVideo={videoUrl.split("/").pop()}
                type='isChapter'
            />} */}
        </div>
    )
}

export default VideoUploader