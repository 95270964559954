import { ComponentProps, FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { useTranslation } from 'react-i18next';
import { getStudent } from '../../../global-state/actions/profile-actions';
import { redirect, clearHistoryArray } from '../../../global-state/actions/breadcrumb-actions';
import { getStudents, getStudentInfoById } from '../../../global-state/actions/course-actions';
import TableElement from '../../../elements/table/normal-table-element';
import { useHistory } from 'react-router-dom';
import EmptyListElement from '../../../elements/empty-list/normal-empty-list-element';
import TableStudentsListLoadingComponent from '../table-students-list-loading-component';
import { returnStartPathUrl } from '../../../helpers/domainCheck';


const StudentsSuspendedInfoTableComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();
    const moment = require('moment');
    const history = useHistory();
    const [loadingTable, setLoadingTable] = useState<boolean>(true);
    const [sizeSuspended, setSizeSuspended] = useState(20);
    const [suspendedDataLength, setSuspendedDataLength] = useState<any>(null);
    const [suspendedData, setSuspendedData] = useState<any>(null);
    const [atBottom, setAtBottom] = useState<any>(false)
    const [emptysearch, setemptysearch] = useState<any>(false);

    const suspendedHeaders = [
        { title: t('students_2'), sorted: true, classNames: "w-35", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t('inscribedTimeStudent'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('suspendedAt'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 ms-auto" },
    ]
    const dateFormat = require('dateformat');
    useEffect(() => {
        let lang = localStorage.getItem("language") || "fr"
        if (lang !== "en") {
            require('moment/locale/' + lang);
            moment.defineLocale(lang, { parentLocale: lang });
        } else {
            require('moment/locale/' + 'fr');
            moment.defineLocale('fr', { parentLocale: 'fr' });
        }
    }, []);

    useEffect(() => {
      
        if (!props?.data?.searchSuspended && props?.studentsList?.length === 0 && props?.followCount?.SUSPENDED > 0) 
        {
            setemptysearch(true)
        }else{
            setemptysearch(false)
        }
    }, [props?.data])

    useEffect(() => {
        document.addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('scroll', handleScroll);
        }
    })

    const handleScroll = () => {
        if (!(atBottom) && (window.innerHeight + window.scrollY+1) >= document.documentElement.scrollHeight) {
            setAtBottom(true);
                if (suspendedDataLength > sizeSuspended) {
                    props?.addDataList('suspended')
                }
            document.removeEventListener('scroll', handleScroll);
        }
        if (!(((window.innerHeight + window.scrollY) -5) < document.documentElement.scrollHeight)) {
            setAtBottom(false);
        }
    }

    useEffect(() => {
        setSizeSuspended(props?.data?.sizeSuspended);
    }, [props?.data])
    
    useEffect(() => {
        const list = {
            headers: suspendedHeaders,
            body: props?.studentsList?.list.map((student: any) => {

                return {
                    row: {
                        student: student,
                        actionName: "goToPath",
                        classNames: "table-white",
                        columns: [
                            { content: student?.firstName + " " + student.lastName, classNames: "", actionName: '' },
                            { content: dateFormat(student?.inscription, "dd/mm/yyyy"), classNames: "text-center", actionName: '' },
                            {
                                content: dateFormat(student?.relation.endDate, "dd/mm/yyyy"),
                                classNames: `text-end`,
                                actionName: ''
                            }
                        ]
                    }
                }
            })
        }
        setSuspendedData(list);
        setSuspendedDataLength(props?.studentsList?.length);
        setLoadingTable(false)
    }, [props?.studentsList?.list])

    const doAction = async (value: any) => {
        if (value.action === 'goToPath') {
            await props?.getStudentInfoById(value.row.student.id, props?.schoolInfo?.id);
            await props?.getStudent(value.row.student);
            props?.clearHistoryArray()
            props?.redirect(t('students_2'),`${returnStartPathUrl()}/students/all`)
            props?.redirect(value.row.student?.firstName + " " + value.row.student?.lastName, `${returnStartPathUrl()}/students/profile/student/${value.row.student.id}`)
             history.push(`${returnStartPathUrl()}/students/profile/student/${value.row.student.id}`)
        }
    }

    return (<>
        {
            !loadingTable ?
            <div>
                {(suspendedData?.body?.length > 0) && <>
                    <TableElement data={suspendedData} doAction={(value: any) => { doAction(value) }} />
                    {(atBottom 
                        && (props?.studentsList?.list?.length < props?.followCount?.SUSPENDED ))
                        && <div className="d-flex w-100 align-items-center justify-content-center">
                            <div className="m-3">
                                <svg className="spinner" viewBox="0 0 50 50">
                                    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                                </svg>
                            </div>
                        </div>}
                </>
                }
                {(suspendedData?.body?.length === 0 ) &&
                    <div className="w-100 text-center mt-5 pt-5">
                        <EmptyListElement lines={[t('noSuspendedFound')]} />
                    </div>
                }
                {/* {(emptysearch) &&
                    <div>
                        <TableStudentsListLoadingComponent />
                    </div>
                } */}
                {(suspendedData?.body?.length === 0 && props?.data?.searchSuspended) &&
                    <div className="w-100 text-center mt-5 pt-5">
                        <EmptyListElement lines={["Aucun étudiant trouvé","Veuillez effectuer une nouvelle recherche."]} />
                </div>}
            </div> :
            <div>
                <TableStudentsListLoadingComponent />
            </div>
        }
</>);
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    myStudents: state.courseReducer.myStudents,
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    listeStudents: state.profReducer.listeStudents,
    registration: state.courseReducer.registration,
    studentInfo: state.courseReducer.studentInfo,
    loadStudentsSuspended: state.profReducer.loadStudentsSuspended,
    lastTabClicked: state.profReducer.lastTabClicked,
    addDataList: ownProps?.addDataList,
    data: ownProps?.data
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            getStudents,
            getStudentInfoById,
            getStudent,
            redirect, clearHistoryArray
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(StudentsSuspendedInfoTableComponent);