import { ComponentProps, FC, useMemo } from "react"
import StudentListCardComponent from "../../components/list-cards-webinars/student-list-card-component";
import { connect } from "react-redux";
import { AppState } from "../../global-state/store/root-reducers";
import { clearAllData } from "../../global-state/actions/course-actions";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import ProfessorListCardComponent from "../../components/list-cards-webinars/professor-list-card-component";
import { redirect, clearHistoryArray } from '../../global-state/actions/breadcrumb-actions'
import not from "../../styles/images/not.png"
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { saveActionHistory } from "../../services/history.service";
import { returnStartPathUrl } from "../../helpers/domainCheck";
const StudentsNotFoundPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const {t} = useTranslation()
    const user = useMemo(() => { return props?.connectedUser }, [props?.connectedUser]);
    const history = useHistory()
    const goTo = async () => {
        props?.clearHistoryArray()
        props?.redirect(t("offersList"), "")
        await saveActionHistory("koors_upgrade_students",props?.connectedUser?.idPerson,props?.connectedUser?.idSchool)

         history.push(`${returnStartPathUrl()}/list`);
    }
    return (<>
        <>
            <div className="container-fluid" >
                <div className="row justify-content-center">
                    <div className="col-md-12" style={{ backgroundColor: '#ffffff' }}>
                        <div className="row mx-auto" style={{ maxWidth: '1200px' }} >
                            <div className=" col-md-6 mt-5" >
                                <div className="d-flex flex-column py-4">
                                    <span className="body-xl bold mb-4" style={{ color: "#F5B95F" }}>{t('students')}</span>
                                    <span className="H2-Headline mb-4" >{t('notFound1')}</span>
                                    <span style={{maxWidth:487}}className="body-xl mb-3" >{t('notFound2')}</span>
                                    <span style={{maxWidth:487}} className="body-xl mb-5">{t('notFound3')}</span>


                                    <button type="button" className="btn-Primary large icon-right mt-2" onClick={()=>{goTo()}} >
                                        <div className="m-auto pe-2">{t('improveMyOffer')}</div>
                                        <span className="material-icons-outlined">
                                        keyboard_arrow_right
                                        </span>
                                    </button>

                                    {/* <button type="button" className="btn-Primary large icon-right mt-2" style={{border:"2px solid #5693EE" , backgroundColor:"white"}} disabled={true} >
                                        <div style={{color:"#5693EE"}}>Améliorer mon offre</div>
                                    </button> */}
                                </div>
                                <div className="py-4 w-100">

                                </div>



                            </div>
                            <div className="col-md-6 mt-5 py-4" >
                            


                                <img className="ps-4"  src={not} alt="koors logo" />

                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </>
    </>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    history: ownProps?.history
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({ redirect, clearHistoryArray, clearAllData }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StudentsNotFoundPage);