import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function CardClosedQuestionPreviewComponent(props: any) {
  const [open, setOpen] = useState(true);
  const { t } = useTranslation();
  const openAccordion = () => {
    setOpen(!open);
  }
  return (
    <div className="d-flex flex-column mb-4" style={{backgroundColor: '#FFFFFF'}}>
      <div className="bg-accordion-border p-4">
        <div className="d-flex align-items-center justify-content-between  accordion-head-open mx-2">
          <div className="d-flex flex-wrap align-items-center justify-content-between w-83">
            <div className="d-flex flex-column" >
              <div className="d-flex flex-row flex-wrap align-items-center" style={{ marginBottom: 14 }}>
                <span className="H3-Headline me-2 pe-1" ><span className="H3-Headline neutral-3">{t('questionLabel')} </span> {props?.index}</span>
                <span className="body-sm bold"><span className="body-sm bold neutral-3">{t('singleClosedQuestion')} / </span> 1 {t('pointsQuestion')}</span>
              </div>
              <span className="H4-Subtitle bold ">{props?.question?.question?.questionStatement}</span>
            </div>
            {/* <div className="d-flex align-items-center flex-no-wrap">
              <span className="body-md bold pe-2 black-800">72%</span>
              <span className=" body-md bold neutral-3">Taux de réussite</span>
            </div> */}
          </div>
          <div className="d-flex align-items-center flex-no-wrap my-2">
            <button onClick={() => openAccordion()} type="button" className="ms-3 btn-QuickActions">
              {!open ? <>
                <span className="material-icons-outlined">expand_less</span>
              </> :
                <> <span className="material-icons-outlined">expand_more</span></>
              }
            </button>
          </div>
        </div>
      </div>
      {!open && <>
        <div className="bg-accordion-border accordion-body pb-3" style={{backgroundColor: '#FFFFFF'}}>
          <div className="d-flex flex-column">
            {props?.question?.question?.questionChoice?.map((answer: any, j: number) => {
              return <div  className="d-flex flex-row mb-4">
                <div className={`badge-square ${(answer.choiceState) ? 'success' : 'danger'} me-4`}>
                  <span className="material-icons-outlined">{(answer.choiceState) ? 'check' : 'close'}</span>
                </div>
                <div className="d-flex flex-column">
                  <span className="form-title mb-2">{t('Réponse')} {(j + 1)}</span>
                  <span className="body-md neutral-2">{answer.choice} </span>
                </div>
              </div>
            })}
            {(props?.question?.question?.questionAnswerExplanation !== '' && props?.question?.question?.questionAnswerExplanation !== null) && <div className="d-flex flex-row mb-4">
              <div className="badge-square info me-4">
                <span className="material-icons-outlined">info</span>
              </div>
              <div className="d-flex flex-column">
                <span className="form-title mb-2">{t('Explication')}</span>
                <span className="body-md neutral-2">{props?.question?.question?.questionAnswerExplanation}</span>
              </div>
            </div>}
          </div>
        </div>
      </>}
    </div>
  );
}
