
import { ComponentProps, FC, useState } from 'react';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { openModal, closeModal, clearModal } from '../../../global-state/actions/modal-actions';
import { getProfessor, } from '../../../global-state/actions/professor-actions';
import AddOneStudentComponent from '../../add-student/add-one-student-component';
import AddMutipleStudentComponent from '../../add-student/add-mutiple-student-component';
const InviteStudentModalComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const [actualTab, setActualTab] = useState("one");
    return (
        <>
            <div className="modal-header" style={{ paddingBottom: 0, borderBottom: "none" }}>
                <div className="w-100 d-flex align-items-center justify-content-end p-3">
                    <button className="btn-QuickActions" onClick={() => {props?.setModalOpened(false)}}><span className="material-icons">close</span></button>
                </div>
            </div>
            <div className="modal-body mb-3" style={{ paddingTop: 0, overflow:'hidden' }}>
                <div className="row justify-content-center">
                    <div className="col-md-10" style={{ margin: 'auto' }}>
                        <div className="row">
                            <div className="col-md-12 mb-4">
                                <h3 className="H3-Headline">{t('addStudent')}</h3>
                            </div>
                            <div className="row justify-content-between">
                                <div className="d-flex w-100 align-items-center jsutify-content-start">
                                    <button className={`btn-Tabs ${(actualTab === 'one') ? '' : 'unselected'} me-3 d-flex align-items-center`} onClick={() => { setActualTab("one"); }} style={{ marginRight: "16px", textTransform: 'capitalize' }}>{t('invite') }</button>
                                    <button className={`btn-Tabs ${(actualTab === 'multiple') ? '' : 'unselected'} me-3 d-flex align-items-center`} onClick={() => { setActualTab("multiple"); }} style={{ marginRight: "16px"}}>{t('importList')}</button>

                                </div>
                            </div>
                            <div className="col-md-12 mt-4">
                           {actualTab==="one" && <AddOneStudentComponent setModalOpened={props?.setModalOpened} general={props?.general} setGeneral={props?.setGeneral} studentsList={props?.studentsList} setStudentsList={props?.setStudentsList}/>}
                           {actualTab==="multiple" && <AddMutipleStudentComponent setModalOpened={props?.setModalOpened} setInviteStudentsList={props.setInviteStudentsList} setModalListInviteOpened={props.setModalListInviteOpened} />} 
                     
                                {/* <input dir="ltr"type="text" className={`form-control input text-default ${(!regx.test(email) && (email !== '')) ? "error" : "success"}`} placeholder={t('emailLogin')} value={email} onChange={(e) => setEmail(e.target.value)} /> */}
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state: AppState, ownProps: any) => ({
    profs: state.profReducer.profs,
    director: state.authReducer.connectedUser,
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    history: ownProps?.history,
    loadingAllProfessors: state.profReducer.loadingAllProfessors
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            openModal,
            closeModal,
            getProfessor,
            clearModal
        },
        dispatch
    );
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InviteStudentModalComponent);