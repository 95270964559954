import { ComponentProps, FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import TableElement from '../../../elements/table/normal-table-element';
import { createTraining, addSubject, deleteSubject, getTrainings } from '../../../global-state/actions/training-actions';
import { useHistory } from 'react-router';
import { closeModal } from '../../../global-state/actions/modal-actions';
import axiosInstance from '../../../helpers/interceptors';
const headers = [
    { title: 'formations', sorted: true, classNames: "w-38", scope: "col", buttonClasses: "d-flex button-trie px-0" },
    { title: 'professeur', sorted: true, classNames: "w-23", scope: "col", buttonClasses: "d-flex button-trie px-0" },
    { title: 'Type', sorted: true, classNames: "w-20", scope: "col", buttonClasses: "d-flex button-trie px-0" },
    { title: 'actions', sorted: false, classNames: "w-10", scope: "col", buttonClasses: "d-flex button-trie px-0 ms-auto me-5 pe-1" },
]

const AddSessionTrainingModalComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const types = [{ label: t("Course"), value: "COURSE" }, { label: t("parcoursTitle"), value: "PATH" }]
    const [data, setData] = useState<any>({ headers })
    const session = useMemo(() => {
        return props?.session
    }, [props?.session?.subjectsBySession , props?.trainings])

    const listOfTrainings = useMemo(() => {
        return props?.trainings
    }, [props?.trainings])


    const getSessionAllowedContent = async () =>{
        
        let filter = {
            entityId: props?.connectedUser?.idSchool,
            entityType: "SCHOOL",
            idProf: props?.session?.idProf
          }
          await props?.getTrainings(filter)
          
    }
    useEffect(() => {
       getSessionAllowedContent()
    }, [])
 
    const history = useHistory()
    const addContent = async (id: number, type: string) => {

        let subjectBySession = {
            entityType: type,
            entityId: id,
            index: session?.subjectsBySession?.length,
            mandatories: [],
            startDay: null
        }

        await props?.addSubject("SESSION", subjectBySession)
            .then(() => {
                if (session?.status === "PUBLISHED") {
                    let updateFollows =
                    {
                        "action": "ADD",
                        "trainingType": session?.type,
                        "trainingId": session?.id,
                        "idSubject": id,
                        "typeSubject": type === "Cours" ? "COURSE" : "PATH"
                    }

                    axiosInstance.post(process.env.REACT_APP_BASE_URL14 + "followUp/updateStudentFollowsAfterPublishedTrainingUpdate", updateFollows)

                }
            })
    }
    const redirect = () => {
        props?.createTraining("sessions", props?.session)
        props?.closeModal()
        //history.push(`${returnStartPathUrl()}/courses/preview/${id}`)
    }

    const deleteContentTraining = async (id: any, e: any) => {

        await props?.deleteSubject(id, session?.subjectsBySession?.find((subjectBySession: any) => subjectBySession?.entityId === e?.id)?.id)
            .then(() => {
                if (session?.status === "PUBLISHED") {
                    let updateFollows =
                    {
                        "action": "DELETE",
                        "trainingType": session?.type,
                        "trainingId": session?.id,
                        "idSubject": e?.id,
                        "typeSubject": e?.type === "Cours" ? "COURSE" : "PATH"
                    }

                    axiosInstance.post(process.env.REACT_APP_BASE_URL14 + "followUp/updateStudentFollowsAfterPublishedTrainingUpdate", updateFollows)

                }
            })

    }

 
    

    
   

    const formatTrainings =()=>{
        if (Array.isArray(listOfTrainings)) {
            let name = (props?.connectedUserInfo?.firstName as string).charAt(0).toUpperCase().concat(". ").concat((props?.connectedUserInfo?.lastName as string).charAt(0).toUpperCase()).concat((props?.connectedUserInfo?.lastName as string).substr(1, (props?.connectedUserInfo?.lastName as string).length));
             setData({
                ...data, body: listOfTrainings?.map((e: any) => {
                    return {
                        row: {
                            actionName: "",
                            classNames: "table-white",
                            columns: [
                                { content: e?.title, classNames: "", actionName: '' },
                                { content: name, classNames: "", actionName: '' },
                                { content: types.find((type: any) => type?.value === e?.type)?.label, classNames: "", actionName: '' },
                                {
                                    content: <div className="d-flex justify-content-end">

                                        {!!session?.subjectsBySession?.find((subjectBySession: any) => subjectBySession?.entityId === e?.id) ?
                                            <button onClick={() => {deleteContentTraining(props?.session?.id,e)}} type="button" className="btn-Secondary medium icon-left adding-done">
                                                <span className="material-icons-outlined">
                                                    check
                                                </span>
                                                <div className="my-auto" style={{ paddingLeft: 6 }}>{t('added')}</div>
                                            </button>
                                            :
                                            <button type="button" onClick={() => { addContent(e?.id, e?.type) }} className="btn-Secondary medium icon-left" style={{ width: 116 }}>
                                                <span className="material-icons-outlined">
                                                    add
                                                </span>
                                                <div className="my-auto" style={{ paddingLeft: 6 }}>{t('add')}</div>
                                            </button>}
                                    </div>, classNames: "text-end with-icon", actionName: ''
                                },
                            ]
                        }
                    }
                }
                )
            })
        }
    }

    useEffect(() => {
        formatTrainings()

    }, [session])
    useEffect(() => {
        formatTrainings()

    }, [listOfTrainings, session])


    return (
        <div style={{ backgroundColor: "#F8F8FA" }}>

            <div className="modal-header" style={{ paddingBottom: 0, borderBottom: "none" }}>
                <div className="w-100 d-flex align-items-center justify-content-between p-3">
                    <span className='H3-Headline black-800'>{t("Ajouter un contenu")}</span>
                    <button className="btn-QuickActions" onClick={() => redirect()}><span className="material-icons">close</span></button>
                </div>
            </div>
            <div className='m-3'>

                <TableElement data={data} />

            </div>


            <div className="modal-footer" style={{ paddingBottom: 0, borderTop: "none", overflow:'hidden' }}>
            </div>

        </div>
    )
}
const mapStateToProps = (state: AppState) => ({
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    connectedUser: state.authReducer.connectedUser,
    connectedUserInfo: state.authReducer.connectedUserInfo,
    trainings: state.trainingReducer.allowedTrainings,
    session: state.trainingReducer.session,

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            createTraining,
            getTrainings,
            addSubject,
            deleteSubject,
            closeModal
        },
        dispatch
    );
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddSessionTrainingModalComponent);