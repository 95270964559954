import { fetchData } from "../base/functions/Functions";

export const checkPaymentApi = async (tokentockeck: any) => {
    const paymentStatus: any = fetchData("GET",process.env.REACT_APP_BASE_URL16 + "paymee/checkPayment/" + tokentockeck?.token,{}).then(response => { return response; }).catch(err => { return console.error(err) });
    return paymentStatus;
}

export const handlePaymeePaymentApi =  async (courseInfoId: any, idPerson: any, token: any) => {
    const baseUrl3 = process.env.REACT_APP_BASE_URL11
        const baseUrl = process.env.REACT_APP_BASE_URL12;
        const baseUrl1 = process.env.REACT_APP_BASE_URL2;
        const baseUrl2 = process.env.REACT_APP_BASE_URL13
        const offerId = await fetchData("GET",baseUrl3 + `offers/offerId/` + courseInfoId.toString(),{}).then(res1 => { return res1 }).catch(err => console.error(err));
        const pricings = await fetchData("GET",baseUrl + `static/` + offerId,{}).then(res2 => { return res2 }).catch(err => console.error(err));
        const pricingsId = pricings?.id;
        const amount = pricings?.price;
        const emailPaymee = await fetchData("GET",baseUrl1 + `person/email/` + idPerson,{}).then(res3 => { return res3 }).catch(err => console.error(err));
        const currency = pricings?.currency;

        await fetchData("POST",baseUrl2 + `payment/createPaymeePayment`, {
            status: "PAYED",
            amount: Number(amount),
            pricingsId: pricingsId,
            paymeeEmail: emailPaymee,
            personId: idPerson,
            currency: currency,
            offerId: offerId
        }).then(res4 => { return res4 }).catch(err => console.error(err));
}

export const getActiveStudentsApi = async (schoolId: any) => {
    const studentRequest: any = fetchData("GET",`${process.env.REACT_APP_BASE_URL14}offer/getActiveStudent/${schoolId}`,{}).then(response => { return response; }).catch(err => { return console.error(err) });
    return studentRequest;
}

export const getPaymentSettingApi = async (idUser: any, type: string)=>{
    const paymentSetting: any = await fetchData("GET",`${process.env.REACT_APP_BASE_URL13}paymentsetting/getPaymentSetting/` + idUser + `/` + type, {}).then(async (res: any) => {
        return res
    })
    return paymentSetting
}

export const getProductListApi = async (type: any) => {
    const response: any = await fetchData("GET",process.env.REACT_APP_BASE_URL19 + "product/code/" + type,{}).then(response => { return response; }).catch(err => { return console.error(err) });
    return response
}

export const getSchoolInformationApi = async (idEntity: any)=>{
    const verify: any = await fetchData("GET",`${process.env.REACT_APP_BASE_URL14}offer/getSchoolInformation/${idEntity}`,{}).then(response => { return response; }).catch(err => { return console.error(err) });
    return verify
}

export const getLimitationsApi = async (idEntity: any, offerId: any) =>{
    const limitations = await fetchData("GET",process.env.REACT_APP_BASE_URL14 + "offer/getLimitations/" + idEntity + "/" + offerId,{}).then(response => { return response; }).catch(err => { return console.error(err) });
    return limitations
}

export const getCountsApi = async (idEntity: number) =>{
    const counts = await fetchData("GET",process.env.REACT_APP_BASE_URL14 + "offer/getActiveProfiles/" + idEntity, {}).then(response => {
        return response;
    }).catch(err => { return console.error(err) });
    return counts
}

export const getSizePaimentFinanceApi = async (filter: any, idSchool: any) =>{
    const baseURL = process.env.REACT_APP_BASE_URL13;
    const res = await fetchData("POST",baseURL + "payment/getPaymentSizeBySchool/" + idSchool, filter).then((response) => {
        return response
    })
    return res
}

export const getAllPayedCourseFinanceApi = async (filter: any, idSchool: any, action: any) =>{
    if (action === "first") {
        filter.pageNumber = 0;
    }
    const baseURL = process.env.REACT_APP_BASE_URL13;
    const res = await fetchData("POST",baseURL + "payment/getPaymentListBySchool/" + idSchool, filter).then((response) => {
        return response.paymentDtoList
    })
    return res
}

export const getListPaymentsApi = async (filter: any, action: any) => {
    if (action === "first") {
        filter.pageNumber = 0;
    }
    const response: any = await fetchData("POST",process.env.REACT_APP_BASE_URL14 + "offer/getPaymentListWithFilter/", filter).then(response => { return response.paymentDtoList; }).catch(err => { return [] });
    return response
}

export const getSizeSubApi = async (filter: any) =>{
    const response: any = await fetchData("POST",process.env.REACT_APP_BASE_URL14 + "offer/getSize", filter).then(response => { return response; }).catch(err => { return 0 });
    return response
}

export const getCummulSalesByOfferFinanceApi = async (filter: any, idOffer: any) => {
    const baseURL = process.env.REACT_APP_BASE_URL12;
    const res = await fetchData("POST",baseURL + "static/get_details_cumul_sales_by_offer/" + idOffer, filter).then((response) => {
        return response
    })
    return res
}

export const getAllOfferPayedFinanceApi = async (filter: any, idSchool: any, action: any) =>{
    if (action === "first") {
        filter.pageNumber = 0;
    }
    const baseURL = process.env.REACT_APP_BASE_URL11;
    const res = await fetchData("POST",baseURL + "offers/get_details_offer_by_school/" + idSchool, filter).then((response) => {
        return response.financeDtos
    })
    return res
}

export const getSizePayedFinanceApi = async (filter: any, idSchool: any) =>{
    const baseURL = process.env.REACT_APP_BASE_URL11;
    const res = await fetchData("POST",baseURL + "offers/get_size_offer_by_school/" + idSchool, filter).then((response) => {
        return response
    })
    return res
}

export const getOffersApi = async () => {
    const offers = await fetchData("GET",process.env.REACT_APP_BASE_URL11 + "offers/getOffers/", {}).then(response => { return response; }).catch(err => { return console.error(err) });
    return offers
}

export const getPaymentFilterApi = async (filter: any, idSchool: any) =>{
    const baseURL = process.env.REACT_APP_BASE_URL13;
    const res = await fetchData("POST",baseURL + "payment/getPaymentFilterBySchool/" + idSchool, filter).then((response) => {
        return response
    })
    return res
}

export const getAllProfessorsByIdSchoolApi = async (idSchool: number) =>{
    const res = await fetchData("GET",`${process.env.REACT_APP_BASE_URL2}person/all/${idSchool}/profs`,{}).then(response => {
        return response?.map((el: any) => {
            return { label: el.name, value: el.id };
        });
    });
    return res
}

export const createPaymentRequestApi = async (payment: any) =>{
    const paymentRequest = await fetchData('POST',process.env.REACT_APP_BASE_URL16 + "paymee/createPayment", payment).then(response => { return response; }).catch(err => { return console.error(err) });
    return paymentRequest
}

export const deleteContractApi = async (id: any, status: any, schoolId: any, object: any, paymentEngine: any) =>{
    let baseURL = process.env.REACT_APP_BASE_URL17;
        const baseUrl1 = process.env.REACT_APP_BASE_URL2;
        let contract: any;
        if (paymentEngine === "STRIPE") {
            contract = fetchData("GET",`${process.env.REACT_APP_BASE_URL13}paymentsetting/getPaymentSetting/` + schoolId + `/CONTRACT`,{}).then(async (res: any) => {
                fetchData("GET",`${process.env.REACT_APP_BASE_URL18}strip/delete-subscription/` + res.data.engineId, {}).then(async (response: any) => {
                    await fetchData("POST",baseURL + "contracts/createorupdatecontract", {
                        contractIdentifier: {
                            contractReference: id
                        },

                        status: [{
                            statusType: status
                        }]

                    }).then(response => { return response.data; })
                    // .then((resp: any) =>
                    //     //axios.post(baseUrl1 + "person/updatelegalperson", object)
                    //     )
                })

            })


        } else {
            contract = fetchData("GET",`${process.env.REACT_APP_BASE_URL13}paymentsetting/getPaymentSetting/` + schoolId + `/CONTRACT`, {}).then(async (res: any) => {
                await fetchData("POST",baseURL + "contracts/createorupdatecontract", {
                    contractIdentifier: {
                        contractReference: id
                    },

                    status: [{
                        statusType: status
                    }]

                }).then(response => { return response.data; })
            })


        }
}

export const migrateOffersApi = async (entityId: any) => {
    let baseURL = process.env.REACT_APP_BASE_URL13;
    try {
        const result = await fetchData("POST", baseURL + "paymentsetting/getMigrationsStripeByEntityId/" + entityId, {});
        return result;
    } catch (error) {
        console.error('Error migrating offers:', error);
        return { linkAccount: '#' }; 
    }
}