import { ComponentProps, FC, useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { useParams } from "react-router";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
import OpenQuestionToCorretComponent from '../list-cards-exam/open-question-cards/open-question-to-correct-component';
import OpenQuestionCorrectedComponent from '../list-cards-exam/open-question-cards/open-question-corrected-component';
import CloseQuestionComponent from '../list-cards-exam/close-question-cards/close-question-component';

let dateFormat = require('dateformat');

interface Params {
    id: any
}
const CorrectionExamBodyComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {

    const params = useParams<Params>();
    const { t } = useTranslation();
    const [studentName] = useState(props?.examForm?.studentName);
    const [submissionDate] = useState(props?.examForm?.progressQna?.createDate);
    const [responseList, setResponseList] = useState(props?.examForm?.studentAnswerList.sort((a: any, b: any) => a.id - b.id))

    useEffect(() => {
        if (props?.examForm?.studentAnswerList?.length != 0) {
            setResponseList(props?.examForm?.studentAnswerList.sort((a: any, b: any) => a.id - b.id))
        }
        console.log(responseList)
    }, [props?.examForm?.studentAnswerList])




    return (
        <div className="mt-3 page-exam-submission-height" style={{ backgroundColor: "#FBFBFD", paddingTop: "240px", minWidth: "88%", paddingBottom: "56px" }}>
            <div style={{ maxWidth: "1200px", margin: "auto" }}>
                <div className="row mt-3 mb-3">
                    <span className=" body-sm bold neutral-3">{t("Rendu_le")} <span style={{ color: "#272727" }}>{dateFormat(submissionDate, "dd/mm/yyyy")}</span> {t("Par")} <span style={{ color: "#272727" }}>{studentName}</span></span>
                </div>
                <div className="row">
                    {responseList && responseList.length > 0 && responseList?.map((obj: any, i: number) => {
                        return <div key={obj?.id}  >
                            {obj?.type === "CQA" ? (
                                <div style={{ marginTop: "16px" }}>
                                    < CloseQuestionComponent numQuestion={i + 1} obj={obj} />
                                </div>
                            ) : (
                                <div style={{ marginTop: "16px" }}>
                                    {obj.questionMark === null ? (
                                        <OpenQuestionToCorretComponent numQuestion={i + 1} obj={obj} Idprogress={params?.id} />
                                    ) : (
                                        < OpenQuestionCorrectedComponent numQuestion={i + 1} obj={obj} />
                                    )}
                                </div>
                            )}
                        </div>
                    })
                    }
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: AppState, ownProps: any) => ({
    examsList: state.examReducer.examsList,
    connectedUserDetails: state.authReducer.connectedUser,
    history: ownProps?.history,
    examStats: state.examReducer.examStats,
    actualTab: state.examReducer.actualTab
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {

        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(CorrectionExamBodyComponent);


