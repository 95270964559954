import { createPortal } from "react-dom";
import { mySteps } from "../../../constants/mySteps";
import { connect } from "react-redux";
import { FC, useContext } from "react";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { AppState } from "../../../global-state/store/root-reducers";
import {
  closeUserGuide,
  handleStartUserGuide,
} from "../../../global-state/actions/user-guide-actions";
import { UserGuideContext } from "../../../context/user-guide-context";

interface IPropsModalUserGuide {
  icon?: string;
  title: string;
  descTitle?: string;
  body: any;
  NbBody: string;
  btnNext: string;
  btnIgnore: string;
  lengthOfSteps: number;
  countOfCurrentStep: number;
  handleClose: () => void;
  handleNext: () => void;
  handleStartUserGuide: any;
  closeUserGuide: () => void;
}

const Portal = ({ children }: any) => {
  // const {
  //   userGuide: { run },
  // } = useAppContext();

  const myInitialStep: any = document.getElementById("react-joyride-step-initial");

  return createPortal(children, myInitialStep);
};

const ModalUserGuide: FC<
  ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> &
    IPropsModalUserGuide
> = ({
  icon,
  title,
  descTitle,
  body,
  NbBody,
  btnNext,
  btnIgnore,
  lengthOfSteps,
  countOfCurrentStep,
  handleClose,
  handleNext,
  handleStartUserGuide,
  closeUserGuide,
}: IPropsModalUserGuide) => {
  const userGuideContext = useContext(UserGuideContext);
  const { userGuideState, setUserGuideState }: any = userGuideContext;

  const handleClickStart = () => {
    // handleStartUserGuide()
    setUserGuideState((prev: any) => ({
      ...prev,
      run: true,
      tourActive: true,
      startGuide: true,
    }));
  };

  const handleCloseModal = () => {
    closeUserGuide();
    handleClose();
  };

  return (
    <Portal>
      <div id="initial-step-user-guide">
        <div className={`${descTitle ? 'modal-user-guide' : 'modal-user-guide--update'}`}>
          <div className="header-modal-user-guide">
            <p className="H3-Headline tac">
              <span className="">{title}</span>
              <img src={icon} alt="" />{" "}
            </p>
            <p className="body-sm tac">{descTitle}</p>
          </div>
          <div className="body-modal-user-guide">
            <p className={`${descTitle ? 'mt-25' : ''}`}>{body}</p>
            <p className="mt-10 body-md bold">{NbBody}</p>
          </div>
          <hr />
          <div className="footer-modal-user-guide">
            <button
              className="btn-text small btn-no-thanks"
              onClick={() => handleCloseModal()}
            >
              {btnIgnore}
            </button>
            <p className="pagination body-sm">
              {countOfCurrentStep} sur {lengthOfSteps}
            </p>
            <button
              className="btn-Primary medium"
              id="initial-step"
              onClick={() => {
                handleNext();
                handleClickStart();
              }}
            >
              {btnNext}
            </button>
          </div>
        </div>
      </div>
    </Portal>
  );
};

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      handleStartUserGuide,
      closeUserGuide,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalUserGuide);
