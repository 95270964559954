import { ComponentProps, FC, useEffect, useMemo } from "react";
import { useParams } from "react-router";
import { connect } from 'react-redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import PrivateRoute from "../../../navigations/PrivateRoute";
import RouteWithBreadCrumb from "../../../navigations/RouteWithBreadCrumb";
import { getTrainingById } from '../../../global-state/actions/training-actions';
import NavbarPathComponent from "../../../components/navigation-components/navbar-path-component";
import descriptionPathPage from "./description-path-page";
import { useLocation } from 'react-router-dom';
import AdmissionPathPage from "./admission-path-page";
import ContentPathPage from "./content-path-page";
import StudentsPathPage from "./students-path-page";
import CustomSwitch from "../../../helpers/custom-switch";
import { getContent } from "../../../global-state/actions/course-actions";
import examPreviewContent from "./exam-preview-content";
import TarificationPathPage from "../path-details/tarification-path-page"
import previewPathPage from "../../catalog/preview-path-page";


const PathDetailsContainer: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { id } = useParams<any>();
    const location = useLocation()

    useEffect(() => {
        props?.getTrainingById('paths', id);
        let filtre = {
            trainingId: id,
            trainingType: "PATH",
            entityType: "SCHOOL",
            entityId: props?.connectedUser?.idSchool
        }
        props?.getContent(filtre);

    }, []);
    const activeTab: string = useMemo(() => {
        return (location.pathname.split("/").pop() as string);
    }, [location?.pathname])
    return (<>

        <NavbarPathComponent activeTab={activeTab} type={'path'} />
        <CustomSwitch>
            {/* <PrivateRoute exact={true} path={`${props?.match.path}/description`} component={descriptionPathPage} /> */}
            <PrivateRoute exact={true} path={`${props?.match.path}/description`} component={previewPathPage} />
            <PrivateRoute exact={true} path={`${props?.match.path}/content`} component={ContentPathPage} />
            <PrivateRoute exact={true} path={`${props?.match.path}/inscription`} component={AdmissionPathPage} />
            <PrivateRoute exact={true} path={`${props?.match.path}/students`} component={StudentsPathPage} />
            <PrivateRoute exact={true} path={`${props?.match.path}/tarification`} component={TarificationPathPage} />
            <RouteWithBreadCrumb exact={true} path={`${props?.match.path}/examPreview`} component={examPreviewContent} />


        </CustomSwitch>
    </>
    )
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators({ getTrainingById, getContent }, dispatch);
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    courseForm: state.courseReducer.courseInfo,
    listHistory: state.historyReducer.listHistoryBreadCrumb,
    history: ownProps?.history,
    chapterForm: state.courseReducer.chapterForm,
    indexChapter: state.courseReducer.indexChapter,
    typeContent: state.courseReducer.typeContentCourse,
});
export default connect(mapStateToProps, mapDispatchToProps)(PathDetailsContainer);
