import { fetchData } from "../base/functions/Functions";

export const getStudentsListApi = async (role: any, filter: any) =>{
    const payload = {
        "entityId": filter.entityId,
        "entityType": filter.entityType,
        "role": role,
        "idprof": role === 'role_prof' ? filter.idProf : null
    }

    const listeStudents: any = await fetchData("POST",`${process.env.REACT_APP_BASE_URL}training/getTrainingsBreveDetails`, payload)
    .then(async (resCourses: any) => {
      return resCourses?.map((course: any) => { 
        return { id: course.id, title: course.title, type: course.type, idRegistration: course.idRegistrationForm } });
    })
    .catch(error => { return [] });
    return listeStudents
}

export const getAllFollowsByGeneralApi = async (role: any, filter: any, search: any, size: any, listeStudents: any) =>{  
    const result = fetchData("POST",`${process.env.REACT_APP_BASE_URL14}followUp/allFollowsByGeneral/${filter.entityId}/${size.sizeGeneral}`, { coursesId: (role === "role_prof") ? listeStudents : [], search: search.generalFilter, idProf: filter.idProf })
      .then((generalData) => {
        return generalData
      })
      .catch(err => { return { list: [] } });

    return result
}

export const getAllFollowsBySuspendedApi = async (role: any, filter: any, search: any, size: any, listeStudents: any) =>{
    const result = fetchData("POST",`${process.env.REACT_APP_BASE_URL14}followUp/allFollowsBySuspended/${filter.entityId}/${size.sizeSuspended}`, { coursesId: (role === "role_prof") ? listeStudents : [], search: search.suspendedFilter, idProf: filter.idProf })
      .then((suspendedData) => {
        return suspendedData
      })
      .catch(err => { return { list: [], length: 0 } });
      return result 
}

export const getAllFollowsByCandidateApi = async (filter: any, search: any, size: any, listeStudents: any) =>{
    const result = fetchData("POST",`${process.env.REACT_APP_BASE_URL14}followUp/allFollowsByCandidate/${filter.entityId}/${size.sizeCandidates}`, { coursesId: listeStudents, search: search.candidatesFilter, idProf: filter.idProf })
      .then((candidateData) => {
        return candidateData
      })
      .catch(err => { return { list: [], length: 0 } });
    return result
}

export const getAllFollowsByCertifiedApi = async (filter: any, search: any, size: any, listeStudents: any) =>{
    const result = fetchData("POST",`${process.env.REACT_APP_BASE_URL14}followUp/allFollowsByCertified/${filter.entityId}/${size.sizeCertified}`, { coursesId: listeStudents, search: search.certifiedFilter, idProf: filter.idProf })
      .then((certifiedData) => {
        return certifiedData
      })
      .catch(err => { return { list: [], length: 0 } });
    return result
}

export const getAllFollowsUnpaidApi = async (filter: any, search: any, size: any, listeStudents: any) =>{
    const result = fetchData("POST",`${process.env.REACT_APP_BASE_URL14}followUp/allFollowsUnpaid/${filter.entityId}/${size.sizeUnpaid}`, { coursesId: listeStudents, search: search.unpaidFilter, idProf: filter.idProf })
      .then((unpaidData) => {
        return unpaidData
      })
      .catch(err => { return { list: [], length: 0 } });
    return result
}

export const getAllFollowsByInscribedApi = async (filter: any, search: any, size: any, listeStudents: any) =>{
    const result = fetchData("POST",`${process.env.REACT_APP_BASE_URL14}followUp/allFollowsByInscribed/${filter.entityId}/${size.sizeInscribed}`, { coursesId: listeStudents, search: search.inscribedFilter, idProf: filter.idProf })
      .then((inscribedData) => {
        return inscribedData
      })
      .catch(err => { return { list: [], length: 0 } });
    return result
}

export const getAllFollowsCountApi = async (filter: any, listeStudents: any) =>{
    const result = fetchData("POST",`${process.env.REACT_APP_BASE_URL14}followUp/allFollowsCount/${filter.entityId}/20`, { coursesId: listeStudents, search: "" }).then(res => {return res})
    return result 
}