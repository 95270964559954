import { ThunkAction, ThunkDispatch } from "redux-thunk";
import axios from "../../helpers/interceptors";
import { IReduxSondageState } from "../reducers/sondage-reducer";
import axiosInstance from "../../helpers/interceptors";

export enum EReduxActionTypes {
  ALL_SERVEYS = "ALL_SERVEYS",
  LOADING_SERVEY = "LOADING_SERVEY",
  LOADING_SERVEY_SCROLL = "LOADING_SERVEY_SCROLL",
  GET_SIZE = "GET_SIZE",
  ADD_SERVEYS = "ADD_SERVEYS",
  ADD_NEW_RESPONSE = "ADD_NEW_RESPONSE",
  SAVE_QUESTION = "SAVE_QUESTION",
  TITLE_DESC_SONDAGE = "TITLE_DESC_SONDAGE",
  VALUE_QUESTION = "VALUE_QUESTION",
  VALUE_RESPONSE = "VALUE_RESPONSE",
  CHANGE_MODAL_SHARE = "CHANGE_MODAL_SHARE",
  CHANGE_MODAL_SHARE_IS_PRIVATE = "CHANGE_MODAL_SHARE_IS_PRIVATE",
  CHANGE_MODAL_SHARE_SELECT_STUDENTS = "CHANGE_MODAL_SHARE_SELECT_STUDENTS",
  CHANGE_MODAL_SHARE_CORP_MAIL = "CHANGE_MODAL_SHARE_CORP_MAIL",
  CHANGE_MODAL_SHARE_MAIL = "CHANGE_MODAL_SHARE_MAIL",
  CREATE_OR_UPDATE_SURVEY = "CREATE_OR_UPDATE_SURVEY",
  GET_SURVEY = "GET_SURVEY",
  CLEAR_SURVEY_FORM="CLEAR_SURVEY_FORM",
  GET_SURVEY_BY_ID="GET_SURVEY_BY_ID",
  DELETE_SURVEY="DELETE_SURVEY",

}

interface IReduxBaseAction {
  type: EReduxActionTypes;
}
export interface IReduxListServeysAction extends IReduxBaseAction {
  type:
    | EReduxActionTypes.ALL_SERVEYS
    | EReduxActionTypes.LOADING_SERVEY
    | EReduxActionTypes.LOADING_SERVEY_SCROLL;
  data: any;
  pageNumber:any
  scrollOrNot: any
}
export interface IReduxGetSizeQnasAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_SIZE;
  data: any;
}

export interface IReduxAddServeysAction extends IReduxBaseAction {
  type: EReduxActionTypes.ADD_SERVEYS;
  data: any;
}

export interface IReduxAddNewResponseAction extends IReduxBaseAction {
  type: EReduxActionTypes.ADD_NEW_RESPONSE;
  data: any;
}

export interface IReduxSaveQuestionAction extends IReduxBaseAction {
  type: EReduxActionTypes.SAVE_QUESTION;
  data: any;
}

export interface IReduxTitleDescSondageAction extends IReduxBaseAction {
  type: EReduxActionTypes.TITLE_DESC_SONDAGE;
  data: any;
}

export interface IReduxQuestionAction extends IReduxBaseAction {
  type: EReduxActionTypes.VALUE_QUESTION;
  data: any;
}

export interface IReduxResponseAction extends IReduxBaseAction {
  type: EReduxActionTypes.VALUE_RESPONSE;
  data: any;
}

export interface IReduxAddNewServeyAction extends IReduxBaseAction {
  type: EReduxActionTypes.CREATE_OR_UPDATE_SURVEY;
  data: any;
}

export interface IReduxGetSurveyAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_SURVEY;
  data: any;
}

export interface IReduxClearSurveyFormAction extends IReduxBaseAction {
  type: EReduxActionTypes.CLEAR_SURVEY_FORM;
  
}

export interface IReduxGetSurveyByIdAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_SURVEY_BY_ID;
  data:any;
}

export interface IReduxChangeModalShareAction extends IReduxBaseAction {
  type: EReduxActionTypes.CHANGE_MODAL_SHARE 
    | EReduxActionTypes.CHANGE_MODAL_SHARE_IS_PRIVATE
    | EReduxActionTypes.CHANGE_MODAL_SHARE_SELECT_STUDENTS
    | EReduxActionTypes.CHANGE_MODAL_SHARE_CORP_MAIL
    | EReduxActionTypes.CHANGE_MODAL_SHARE_MAIL;
  data: any;
}

export interface IReduxDeleteSurveyAction extends IReduxBaseAction {
  type: EReduxActionTypes.DELETE_SURVEY;
  data: any;
}

export function getListServeys(
  filter: any,
  action: any
): ThunkAction<
  Promise<IReduxListServeysAction>,
  IReduxSondageState,
  undefined,
  IReduxListServeysAction
> {
  return async (
    dispatch: ThunkDispatch<
      IReduxSondageState,
      undefined,
      IReduxListServeysAction
    >
  ) => {
    if(action === 'first') {
      dispatch({type:EReduxActionTypes.LOADING_SERVEY,data:undefined,pageNumber:undefined, scrollOrNot: undefined});
    } else {
      dispatch({type:EReduxActionTypes.LOADING_SERVEY_SCROLL,data:undefined,pageNumber:undefined, scrollOrNot: undefined});
    }


    let baseURL = process.env.REACT_APP_BASE_URL6;
    const res = await axiosInstance
      .post(baseURL + "exams/getQnaWithFilter", filter)
      .then((response) => {
        return response.data;
      });
      let pageNumber=res? filter?.pageNumber+1:filter?.pageNumber
    return dispatch({
      type: EReduxActionTypes.ALL_SERVEYS,
      data: res,
      pageNumber,
      scrollOrNot: action,
    });
  };
}
export function getSizeQnas(
  filter: any
): ThunkAction<
  Promise<IReduxGetSizeQnasAction>,
  IReduxSondageState,
  undefined,
  IReduxGetSizeQnasAction
> {
  return async (
    dispatch: ThunkDispatch<
      IReduxSondageState,
      undefined,
      IReduxGetSizeQnasAction
    >
  ) => {
    let baseURL = process.env.REACT_APP_BASE_URL6;
    const res = await axiosInstance
      .post(baseURL + "exams/getSizeQnas", filter)
      .then((response) => {
        return response.data;
      });
    return dispatch({
      type: EReduxActionTypes.GET_SIZE,
      data: res,
    });
  };
}

export function createOrUpdateSurvey(surveyForm: any): ThunkAction<Promise<IReduxAddNewServeyAction>,IReduxSondageState,undefined,IReduxAddNewServeyAction> {
  return async (dispatch: ThunkDispatch< IReduxSondageState,undefined,IReduxAddNewServeyAction>) => {
    
    let baseURL = process.env.REACT_APP_BASE_URL6;
    const survey = await axiosInstance
      .post(baseURL + "exams/createOrUpdateExam", surveyForm)
      .then((response) => {
        return response.data;
      });
      
    return dispatch({
      type: EReduxActionTypes.CREATE_OR_UPDATE_SURVEY,
      data: survey,
    });
  };
}

export function addServeys(
  serveys: any,
  idSchool: any
): ThunkAction<
  Promise<IReduxAddServeysAction>,
  IReduxSondageState,
  undefined,
  IReduxAddServeysAction
> {
  return async (
    dispatch: ThunkDispatch<
      IReduxSondageState,
      undefined,
      IReduxAddServeysAction
    >
  ) => {
    return dispatch({
      type: EReduxActionTypes.ADD_SERVEYS,
      data: { serveys, idSchool },
    });
  };
}

export function handleAddNewResponse(
  idQuestionByExam: any,
  newResponse: any,
  index: any
): ThunkAction<
  Promise<IReduxAddNewResponseAction>,
  IReduxSondageState,
  undefined,
  IReduxAddNewResponseAction
> {
  return async (
    dispatch: ThunkDispatch<
      IReduxSondageState,
      undefined,
      IReduxAddNewResponseAction
    >
  ) => {
    return dispatch({
      type: EReduxActionTypes.ADD_NEW_RESPONSE,
      data: { idQuestionByExam, newResponse, index },
    });
  };
}

export function handleSaveQuestion(
  sondageReducer: any,
  index: any
): ThunkAction<
  Promise<IReduxSaveQuestionAction>,
  IReduxSondageState,
  undefined,
  IReduxSaveQuestionAction
> {
  return async (
    dispatch: ThunkDispatch<
      IReduxSondageState,
      undefined,
      IReduxSaveQuestionAction
    >
  ) => {
    let newSondageReducer = { ...sondageReducer };
    
    newSondageReducer.questionByQnaList = [
      ...sondageReducer.questionByQnaList
      // sondageReducer.questionByQnaList[index],
    ];


    let baseURL = process.env.REACT_APP_BASE_URL6;
    const newres = await axiosInstance
      .post(baseURL + "exams/createOrUpdateExam", newSondageReducer)
      .then((response) => {
        return response.data;
      });
  

    return dispatch({
      type: EReduxActionTypes.SAVE_QUESTION,
      data: { newres },
    });
  };
}

export function handleChangeTitleDescSondage(
  value: any,
  from: any
): ThunkAction<
  Promise<IReduxTitleDescSondageAction>,
  IReduxSondageState,
  undefined,
  IReduxTitleDescSondageAction
> {
  return async (
    dispatch: ThunkDispatch<
      IReduxSondageState,
      undefined,
      IReduxTitleDescSondageAction
    >
  ) => {
    return dispatch({
      type: EReduxActionTypes.TITLE_DESC_SONDAGE,
      data: { value, from },
    });
  };
}

export function handleChangeQuestion(
  valueQuestion: any,
  myindex: any
): ThunkAction<
  Promise<IReduxQuestionAction>,
  IReduxSondageState,
  undefined,
  IReduxQuestionAction
> {
  return async (
    dispatch: ThunkDispatch<IReduxSondageState, undefined, IReduxQuestionAction>
  ) => {
    return dispatch({
      type: EReduxActionTypes.VALUE_QUESTION,
      data: { valueQuestion, myindex },
    });
  };
}

export function handleChangeResponse(
  valueResponse: any,
  indexQuestion: any,
  indexResponse: any
): ThunkAction<
  Promise<IReduxResponseAction>,
  IReduxSondageState,
  undefined,
  IReduxResponseAction
> {
  return async (
    dispatch: ThunkDispatch<IReduxSondageState, undefined, IReduxResponseAction>
  ) => {
    return dispatch({
      type: EReduxActionTypes.VALUE_RESPONSE,
      data: { valueResponse, indexQuestion, indexResponse },
    });
  };
}

export function handleChangeModalShare(
  type: string,
  data: any
): ThunkAction<
  Promise<IReduxChangeModalShareAction>,
  IReduxSondageState,
  undefined,
  IReduxChangeModalShareAction
> {
  return async (
    dispatch: ThunkDispatch<IReduxSondageState, undefined, IReduxChangeModalShareAction>
  ) => {
    if(type === 'isPrivate') {
      return dispatch({
        type: EReduxActionTypes.CHANGE_MODAL_SHARE_IS_PRIVATE,
        data: {  },
      });
    }

    if(type === 'selectStudents') {

      return dispatch({
        type: EReduxActionTypes.CHANGE_MODAL_SHARE_SELECT_STUDENTS,
        data: { data },
      });
    }

    if(type === 'mail') {

      return dispatch({
        type: EReduxActionTypes.CHANGE_MODAL_SHARE_MAIL,
        data: { data },
      });
    }

    if(type === 'corpMail') {

      return dispatch({
        type: EReduxActionTypes.CHANGE_MODAL_SHARE_CORP_MAIL,
        data: { data },
      });
    }
    

    return dispatch({
      type: EReduxActionTypes.CHANGE_MODAL_SHARE,
      data: { },
    });
  };
}


export function getSurvey(survey:any): ThunkAction<Promise<IReduxGetSurveyAction>,IReduxSondageState,undefined,IReduxGetSurveyAction> {
  return async (
    dispatch: ThunkDispatch<IReduxSondageState, undefined, IReduxGetSurveyAction>
  ) => {
    return dispatch({
      type: EReduxActionTypes.GET_SURVEY,
      data: survey,
    });
  };
}

export function clearSurveyForm(): ThunkAction<Promise<IReduxClearSurveyFormAction>,IReduxSondageState,undefined,IReduxClearSurveyFormAction> {
  return async (
    dispatch: ThunkDispatch<IReduxSondageState, undefined, IReduxClearSurveyFormAction>
  ) => {
    return dispatch({
      type: EReduxActionTypes.CLEAR_SURVEY_FORM,
      
    });
  };
}


export function getSurveyById(id:any): ThunkAction<Promise<IReduxGetSurveyByIdAction>,IReduxSondageState,undefined,IReduxGetSurveyByIdAction> {
  return async (dispatch: ThunkDispatch<IReduxSondageState, undefined, IReduxGetSurveyByIdAction>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL6;
    let survey:any=await axios.get(baseUrl + "exams/findby/" + id)
        .then(response => { return response.data; })
        .catch(err => { return undefined });
    return dispatch({
      type: EReduxActionTypes.GET_SURVEY_BY_ID,
      data:survey
    })
  }
}


export function deleteSurvey(id:any): ThunkAction<Promise<IReduxDeleteSurveyAction>,IReduxSondageState,undefined,IReduxDeleteSurveyAction> {
  return async (dispatch: ThunkDispatch<IReduxSondageState, undefined, IReduxDeleteSurveyAction>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL6;
    let deleted:any=await axios.post(baseUrl + "survey/deleteSurvey/" + id)
        .then(response => { return id })
        .catch(err => { return undefined });
    return dispatch({
      type: EReduxActionTypes.DELETE_SURVEY,
      data:id
    })
  }
}