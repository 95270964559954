import { ComponentProps, FC, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { closeModal, openModal } from '../../../global-state/actions/modal-actions';
import { clearMyTrainings } from '../../../global-state/actions/training-actions';
import { updateChapter, publishCourse,UploadVideoCourse,createCourse } from '../../../global-state/actions/course-actions';
const AddVideoUrlModalComponent: FC<ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    let [msg, setMsg] = useState("")
    let [urlError,setUrlError]=useState(true)
    const [url,setUrl]=useState('')
    const regx = new RegExp(
        '^([a-zA-Z]+:\\/\\/)?' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$', // fragment locator
        'i'
      );
    
   
    const openVideo = () => {       
        //props?.UploadVideoCourse(url)
        if(props?.content.data.courseForm){
            const copyCourseForm = { ...props?.content?.data?.courseForm };
            copyCourseForm['video'] = url;
            copyCourseForm['type'] = 'COURSE';
            if (props?.connectedUser?.role === "role_prof") {
                copyCourseForm['idProf'] = props?.connectedUser?.idPerson;
                copyCourseForm['creator'] = props?.connectedUser?.idPerson;
            }
            props?.createCourse(copyCourseForm, props?.connectedUser)
            props?.clearMyTrainings()
        } else if (props?.content.data.chapterForm){
            const copyChapter = { ...props?.content.data.chapterForm };
            copyChapter.video = url;
            props?.updateChapter(copyChapter, props?.courseForm.id);

        }
        props?.closeModal();

    }
    useEffect(()=>{
        if(url!=='' && !regx.test(url)){
            setMsg(t('enterValidUrl'));
            setUrlError(true)
        } else if(regx.test(url)){
            setUrlError(false)
            setMsg('')
        }
    },[url])
    return (
        <>
            <div className="d-flex w-100 align-items-center justify-content-end pt-4 pe-4">
                <button className="btn-QuickActions" onClick={() => props?.closeModal()}><span className="material-icons">close</span></button>
            </div>
            <div className="modal-body" style={{ paddingTop: 0, marginLeft: '10px', overflow:'hidden' }}>
                <div className="row justify-content-center ">
                    <div className="col-md-10 text-left" >
                        <h3 className="H3-Headline black-800 " style={{ textAlign: 'left' }}>{t('addVideoUrl')}</h3>
                        <div className="mt-2 mb-4">
                <input type="text" className="form-control input text-default mt-5 " style={{backgroundColor:msg===''?'':"#fae9e9"}} placeholder={t("pasteVideoUrl")} onChange={(e) => { setUrl(e.target.value); }} />
                <div className='mt-1'>
                    {(msg !== '') && <span style={{ color: '#E06A6A',}}>{msg}</span>}
                </div>
          
            </div>
                    </div>

                </div>
            </div>
            <div className="modal-footer" style={{ border: 'none' }}>
                <div className="row">
                    <div className='d-flex mt-3 mb-5 me-5 pe-3'>
                        <button type="button" onClick={() => props?.closeModal()} className="btn-Secondary large me-3">{t('annuler')}</button>
                        <button type="button"  disabled={ urlError} className="btn-Primary large" onClick={()=>openVideo()}>{t('itegrateVideo')}</button>
                    </div>
                </div>
            </div>
        </>

    )

}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators({
        closeModal, openModal, publishCourse,UploadVideoCourse,clearMyTrainings,createCourse, updateChapter
    }, dispatch);
const mapStateToProps = (state: AppState) => ({
    courseForm: state.courseReducer.courseForm,
    connectedUser: state.authReducer.connectedUser,
    profs: state.profReducer.profs,
    content: state.modalReducer.modalContent
});
export default connect(mapStateToProps, mapDispatchToProps)(AddVideoUrlModalComponent);