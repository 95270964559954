import { forEach } from "lodash";
import axios from "../../helpers/interceptors";

export const getDataFromURL = (url: string | null, filename: string | null = null) => {
  if (url) {
    let file = !!filename ? filename : decodeURIComponent(url.split('/')[3]);
    axios.post(`${process.env.REACT_APP_BASE_URL4}download/file`, { filename: decodeURIComponent(url.split('/')[3]) }, { responseType: 'blob' }).then((res: any) => {
      const urlLink = window.URL.createObjectURL(new Blob([res.data]));
      const linkk = document.createElement('a');
      linkk.href = urlLink;
      linkk.setAttribute('download', file);
      document.body.appendChild(linkk);
      linkk.click();
    }).catch(err => console.error(err));
  }

}
export const getImageTraining = async (url: string | null) => {
  if (url) {
    return await axios.post(`${process.env.REACT_APP_BASE_URL4}download/file`, { filename: decodeURIComponent(url.split('/')[3]) }, { responseType: 'blob' }).then((res: any) => {
      const blobObject = new Blob([res.data]);
      return new File([blobObject], decodeURIComponent(url.split('/')[3]), { type: blobObject.type, lastModified: Date.now() });
    }).catch(err => console.error(err));
  }

}
export const getDuration = (period: number) => {
  return Math.abs(Math.floor(Number(period / 60))) + "H" + (period % 60 > 9 ? Math.abs(Math.round(period % 60)) : "0" + Math.abs(Math.round(period % 60)))
}
export const getDate = (stringDate: string): string => {
  let date = new Date(stringDate)

  let formattedDate = date.getDate() + '/' + Number(date.getMonth() + 1) + '/' + date.getFullYear()
  return formattedDate

}
export const getTime = (stringDate: string, duration: number = 0): string => {
  let date = new Date(new Date(stringDate).getTime() + duration * 60000)
  return date.toLocaleTimeString('it-IT').substr(0, 5).replace(":", "h")
}
export const getCertif = (course: any, schoolInfo: any, student: any, certificationDate: any): any => {

  if ((course?.followUp?.certificationLink === null || course?.followUp?.certificationLink === "")) {
    let signingBy = new Promise(function (resolve) {
      let signature = course.diploma !== null ? course.diploma?.signingBy : null
      if (course?.profId !== signature) {
        axios.get(`${process.env.REACT_APP_BASE_URL2}person/school/${schoolInfo?.id}`).then((response) => {
          resolve(response.data.firstName + " " + response.data.lastName)
        }).catch(() => {
          resolve(course?.personName)
        })
      }
      resolve(course?.personName)

    });
    let graduationDate = getDate(!!certificationDate ? certificationDate : new Date().toString())

    return signingBy.then(data => {


      let diploma = {
        code: course?.followUp.id,
        student: student.firstName + " " + student.lastName,
        professor: course?.personName,
        signature: data,
        course: course?.title,
        graduationDate: graduationDate,
        schoolLogo: schoolInfo?.logo
      }


      return axios.post(`${process.env.REACT_APP_BASE_URL5}generate`, diploma).then(response => {
        if (response.data.status === 200 && response.data.links?.length === 1) {
          return axios.put(`${process.env.REACT_APP_BASE_URL3}follow/certification`, { id: course?.followUp?.id, certificationLink: response.data.links[0].link }).then(httpResponse => {

            return httpResponse.data
          }

          )
        }
      })
    })
  }

}
export const getPeriod = (startTime: string, endTime: string): any => {

  let start = startTime.split(":")
  let end = endTime.split(":")
  let fistDate = new Date(0, 0, 0, Number(start[0]), Number(start[1]), 0)
  let lastDate = new Date(0, 0, 0, Number(end[0]), Number(end[1]), 0)
  let diff = ((lastDate.getTime() - fistDate.getTime()) / 60000)
  return diff > 0 ? diff : diff + 1440

}
export const getPeriodDiff = (startTime: string, endTime: string): any => {

  let start = startTime.split(":")
  let end = endTime.split(":")
  let fistDate = new Date(0, 0, 0, Number(start[0]), Number(start[1]), 0)
  let lastDate = new Date(0, 0, 0, Number(end[0]), Number(end[1]), 0)
  return ((lastDate.getTime() - fistDate.getTime()) / 60000)


}
export const formatNumber = (value: number): string => {
  return value < 10 ? '0' + value : '' + value
}
export const sortEducationLevel = (array: Array<String>): Array<String> => {
  let levelArray: String[] = ['Non mentionné', 'Primaire', 'Collège', 'Lycée', 'Bac', 'Technicien (Bac +2)', 'Licence (Bac+3)', 'Master ou équivalent (BAC +4 ou + 5', 'Ingénieur', 'Doctorat'];
  const output: String[] = levelArray?.filter((item) => array?.includes(item))
  return output;
}
export function isValidEmail(email: string) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export const calculateDuration = (data: Array<Object>, exams?: Array<any>): Number => {
  let time = 0
  //console.log(data,exams)
  data.forEach((element: any) => {
    if (element?.entityType === "COURSE") {
      time += element?.course?.duration
    }
    else if (element?.entityType === "PATH") {
      time += element?.path?.duration
    }
  })
  if (!!exams) {
    time += exams[0]?.duration;
  }
  return time
}

export const calculatePathPageDuration = (data: any): Number => {
  let time = 0
  let tempDataCoursesPaths = data.hasOwnProperty('subjectsByPath') ? data.subjectsByPath : data.subjectsBySession
  let tempExams = data.hasOwnProperty('exams') ? data.exams : []

  tempDataCoursesPaths.map((element: any) => {
    if (element.hasOwnProperty('path')) {
      time = time + element?.path?.duration
    }
    else if (element.hasOwnProperty('course')) {
      time = time + element?.course?.duration
    }
  })
  tempExams.map((element: any) => {
    time = time + element?.duration
  })
  return time
}

export const formatVideoDurationChapter = (duration: number) => {
  const quotient = Math.floor(duration / 60);
  const remainder = duration % 60
  if (remainder >= 0 && remainder < 10) {
    return quotient + ":0" + remainder
  }
  return quotient + ":" + remainder
}

export const fetchData = async (method: string, url: string, payload: Object) => {

  const headers: any = {
    "Content-Type": "application/json",
    'Access-Limitations': localStorage.getItem('access_limitation'),
    Apikey: `${process.env.REACT_APP_API_KEY_KONG}`,
    Authorization: localStorage.getItem('access_token')?.replace(/"/g, '') ? `Bearer ` + localStorage.getItem('access_token')?.replace(/"/g, '') : ''

  }

  switch (method) {
    case 'GET': {
      try {
        const response = await fetch(
          url, { headers }
        );
        const data = await response.json();
        return data;
      }
      catch (error: any) {
        throw new Error(error)
      }
    }
    case 'POST' || 'PUT' || 'PATCH': {
      try {
        const response = await fetch(
          url, {
          method
          , headers,
          body: JSON.stringify(payload),
        }
        );
        const data = await response.json();
        return data;
      }
      catch (error: any) {
        throw new Error(error)
      }
    }
    case 'DELETE': {
      try {
        const response = await fetch(
          url, headers
        );
        if (response.status === 200)
          return { message: "element is deleted !" }
      }
      catch (error: any) {
        throw new Error(error)
      }
    }


  }

}


export const formatJavaDate = (date: string) => {
  const [weekday, monthStr, dayStr, timeStr, tz, yearStr] = date.split(' ');
  const months: { [key: string]: number } = {
    'Jan': 0, 'Feb': 1, 'Mar': 2, 'Apr': 3,
    'May': 4, 'Jun': 5, 'Jul': 6, 'Aug': 7,
    'Sep': 8, 'Oct': 9, 'Nov': 10, 'Dec': 11
  };
  const day = parseInt(dayStr, 10);
  const year = parseInt(yearStr, 10);
  const month = months[monthStr];
  const dateObj = new Date(Date.UTC(year, month, day));
  const dayFormatted: string = day < 10 ? '0' + day : day.toString();
  const monthFormatted: string = (month + 1) < 10 ? '0' + (month + 1) : (month + 1).toString();
  const formattedDate: string = `${dayFormatted}/${monthFormatted}/${year}`;

  return formattedDate;
}

export const calculateDateDifference = (date: string) => {

  const [weekday, monthStr, dayStr, timeStr, tz, yearStr] = date.split(' ');
  const months: { [key: string]: number } = {
    'Jan': 0, 'Feb': 1, 'Mar': 2, 'Apr': 3,
    'May': 4, 'Jun': 5, 'Jul': 6, 'Aug': 7,
    'Sep': 8, 'Oct': 9, 'Nov': 10, 'Dec': 11
  };
  const day = parseInt(dayStr, 10);
  const year = parseInt(yearStr, 10);
  const month = months[monthStr];
  const givenDate = new Date(Date.UTC(year, month, day));
  const today = new Date();
  const currentDate = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate()));
  const diffTime = currentDate.getTime() - givenDate.getTime();
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

  return diffDays;

}


export const timeConvert = (minutes: number) => {
  if (minutes < 60) {
    return minutes + ' MN';
  } else {

    return Math.floor(minutes / 60) + 'H' + (((minutes % 60) < 10 ? '0' : '') + (minutes % 60));
  }
}


export const getListYearAndMonthsToDay = () => {
  const moment = require('moment');
  moment.locale('fr')
  let i = 1

  const res = moment.months()?.map((month: any) => {
    return { label: month, value: i++ };
  })
  for (let id = 1; id <= 4; id++) {
    res.push({ label: new Date().getFullYear() - id, value: new Date().getFullYear() - id })
  }
  return res
}
