import { ComponentProps, FC, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { openModal, closeModal } from '../../../global-state/actions/modal-actions';
import SelectElement from "../../../elements/select/select-element";
import { ModalIDS } from '../modal-ids';
import { createPost } from "../../../global-state/actions/chat-actions";
import TextareaUnderlineElement from '../../../elements/text-area/text-area-underline-element';

const AddCommentModalComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const [thematicOption, setThematicOption] = useState<any[]>([]);
    const [thematic, setThematic] = useState("none");
    const [title, setTitle] = useState("");
    const [comment, setComment] = useState("");
    const [screenSize, setScreenSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    const [isMobile, setIsMobile] = useState<boolean>(true)

    const handleResize = () => {
        if (window.innerWidth !== undefined && window.innerWidth < 768) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
        setScreenSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };

    useEffect(() => {
        if (screenSize?.width !== undefined && screenSize?.width < 768) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [screenSize])

    useEffect(() => {
        const array = [{ value: JSON.stringify({ id: props?.courseInfo?.id, typeCategory: 'COURSE' }), label: props?.courseInfo?.title }];
        props?.courseInfo?.orders.map((obj: any) => {
            array.push({ value: JSON.stringify({ id: obj.chapter.id, typeCategory: 'CHAPTER' }), label: obj.chapter.title });
        });
        setThematicOption(array);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const changeThematic = (thematicInput: string) => {
        setThematic(thematicInput);
    }

    const changeInputValue = (value: string) => {
        setComment(value)
    }

    const addPost = async () => {
        let thematicObj = JSON.parse(thematic);
        const exist = props?.listPosts?.find((obj: any) => (obj.subject.idSubject === thematicObj.id && obj.subject.category === thematicObj.typeCategory && obj.subject.type === "FORUM"));
        let object = {
            subjectId: (exist) ? exist.id : null,
            idAuthor: props?.connectedUser?.idPerson,
            title: title,
            type: "POST",
            comment: {
                description: comment,
                type: "TEXT"
            },
            subject: [thematicObj.id+" "+thematicObj.typeCategory+" FORUM"]
        }
        await props?.createPost(object, { idSubject: thematicObj.id, category: thematicObj.typeCategory, type: "FORUM" }, props?.connectedUser?.idSchool);
        await props?.openModal({ idModal: ModalIDS.SUCCESS_MODAL_WITH_BUTTON, size: "md", buttonText: 'ok', lines: [t('addCommentSuccess')] });
    }

    return (
        <>

            { !isMobile && <div className="modal-header" style={{ paddingBottom: 0, borderBottom: "none" }} >
                <div className="w-100 d-flex align-items-center justify-content-end p-3">
                    <button className="btn-QuickActions" onClick={() => props?.closeModal()}><span className="material-icons">close</span></button>
                </div>
            </div>}

            <div className="modal-body p-5" style={{ paddingTop: 0, zIndex: 1000, overflow:'hidden' }}>
                <div className="row">
                    <div className="col-md-10 center" style={{ margin: 'auto' }}>
                        <div className="row">
                            <div className="col-md-12 mb-4">
                                <h3 className={isMobile ? "heading-h3-noirstd text-center":"heading-h3-noirstd"}>{t('addcommentForCourse')}</h3>
                            </div>
                            <div className="col-md-12 mb-1 mt-2">
                                <p className="body-md bold">{t('thematic')}</p>
                            </div>
                            <div className="col-md-12  mb-4" style={{ position: "relative" }}>
                                <SelectElement disabled={false} taille={'lg'} defaultValue={thematic} options={thematicOption} placeholder={t('thematic')} onChange={(e: string) => changeThematic(e)} />
                            </div>
                            <div className="col-md-12 mb-1 mt-2">
                                <p className="body-md bold">{t('commentTitle')}</p>
                            </div>
                            <div className="col-md-12  mb-4 ">
                                <input dir="ltr"type="text" className="form-control input text-default" value={title} onChange={(e) => { setTitle(e.target.value) }} placeholder={t('commentTitle')} />
                            </div>
                            <div className="col-md-12 mb-1 mt-2">
                                <p className="body-md bold">{t('yourComment')}</p>
                            </div>
                            <div className="col-md-12  mb-1 ">
                                <TextareaUnderlineElement id="commentId" value={comment} onChange={changeInputValue} placeholder={t('votreCommentaie')} />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-footer" style={{ paddingBottom: 0, borderTop: "none", justifyContent:isMobile ? 'center' :'end'}}>
                <div className="row mt-3 mb-5">
                    <div className={isMobile ? 'd-flex col-12' : 'col-md-9 d-flex justify-content-end'} >
                        <button className="btn-Secondary large me-3" onClick={() => { props?.closeModal(); setTitle(""); setComment(""); setThematic("") }}>{t('annuler')}</button>
                        <button type="button" className="btn-Primary large" style={{ textTransform: 'uppercase', marginRight: '2%' }} onClick={() => { addPost() }} disabled={!(comment !== '' && thematic !== '')}>{t('publishMyReview')}</button>
                    </div>
                </div>
            </div>

        </>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    courseInfo: state.studentTraining.currentCourse,
    connectedUser: state.authReducer.connectedUser,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            openModal,
            closeModal,
            createPost
        },
        dispatch
    );
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddCommentModalComponent);