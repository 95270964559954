import { useEffect, useState } from 'react';

interface Props {
    data: any
    doAction: any
    height?: number | string
  }
export default function TableScrollElement(props: Props) {
    const [data, setData] = useState<any>();
    useEffect(() => {
      setData(props?.data)
    }, [props?.data]);
  const doAction = (e: any, actionName: string, row: any, index: number) => {
    if(actionName !== '') {
      e.stopPropagation();
      const object = {
        row : row,
        action: actionName,
        index: index
      }
      props?.doAction(object);
    }
  }
  return (
    <>

    <div className="table-responsive d-flex flex-column mx-auto" style={{ maxWidth: 1136 ,height:props?.height}}>
      <div>
      <table className="w-100 custom-table">
        <thead className="mb-4">
          <tr>
            {data?.headers?.map((header: any) =>
              (<th scope={header.scope} className={header.classNames}>
              <button className={header.buttonClasses}>
                {header.title}
                {/* {header.sorted && <div className="d-flex flex-column">
                  <span className="material-icons-outlined trie-arrow up" >arrow_drop_up</span>
                  <span className="material-icons-outlined trie-arrow down" >arrow_drop_down</span>
                </div>} */}
              </button>
            </th>)
            )}
           </tr>
        </thead>
        </table>
        </div>
        <div className="w-100 body-table-scroll">
        <table className="w-100 custom-table px-2" >
        <tbody>
        {data?.body?.map((dataItem: any, index: number) =>
              (
          <tr style={{cursor: (dataItem.row.actionName === "")? 'default': 'pointer'}} onClick={(e) => doAction(e , dataItem.row.actionName , dataItem.row, index)} className={dataItem.row.classNames}>
              {dataItem.row.columns.map((column: any, indexItem:number)=>
              (
                  <td key={indexItem + 'column'} onClick={(e) => doAction(e, column.actionName, dataItem.row, indexItem)} className={column.classNames} colSpan={column.colspan}>{column.content}</td>
              )
              )}
          </tr>
              )
        )}
        </tbody>
      </table>
      </div>
    </div>
    </>
  );
}
