import { ComponentProps, FC, useCallback, useEffect, useMemo, useState } from "react";
import { connect } from 'react-redux';
import { AppState } from '../../global-state/store/root-reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import SelectElement from "../../elements/select/select-element";
import { useTranslation } from "react-i18next";
import { updatePath, redirect, goToRedirection } from '../../global-state/actions/breadcrumb-actions';
import { getChapter, createChapter, getCreateOrUpdate, setIndexContent, setTypeFormContentCourse, updateChapter, deleteChapter, clearChapter, updateExam, getExam, createExam, deleteExam, } from '../../global-state/actions/course-actions';
import { useHistory } from "react-router-dom";
import { debounce } from "lodash";
import { clearFormVideoChapter } from "../../global-state/actions/upload-video-chapter-actions";
import { returnStartPathUrl } from "../../helpers/domainCheck";
import { TextField, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    input: {
        padding: '3px',
        paddingTop: "5px",
        paddingBottom: "0px !important"
    },
});
const times = [
    { value: "15", label: '15 mn' },
    { value: "30", label: '30 mn' },
    { value: "45", label: '45 mn' },
    { value: "60", label: '1h00' },
    { value: "75", label: '1h15' },
    { value: "90", label: '1h30' },
    { value: "165", label: '2h45' },
    { value: "180", label: '3h00' },
]
const HeaderChapterExmFormComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const classes = useStyles();

    const { t } = useTranslation();
    const history = useHistory();
    const typeContent = useMemo(() => { return props?.typeContent }, [props?.typeContent]);
    const [courseForm, setCourseForm] = useState<any>();
    const [allListe, setAllListe] = useState<any[]>([]);
    const [actualIndex, setActualIndex] = useState<number>(0);
    const [refresh, setRefresh] = useState<boolean>(true);
    const [listeSelect, setListeSelect] = useState<any[]>([]);
    const [listeTest, setListeTest] = useState<any[]>([]);
    const [minute, setMinute] = useState<any>("00");
    const [hour, setHour] = useState<any>("00");

    const [testForm, setTestForm] = useState<any>({
        id: null,
        questionByQnaList: [],
        index: props?.indexContent + 1,
        title: (props?.typeContent === 'FINALEXAM') ? t('examTitleBegin') : t('testTitleBegin'),
        duration: 0,
        scaleNote: 0,
        entityId: props?.courseForm.id,
        entityType: "COURSE",
        status: "ACTIVE",
        qnaType: props?.typeContent,
        openQuestion: null
    });

    const [chapterForm, setChapterForm] = useState<any>({
        id: null,
        status: "ACTIVE",
        title: t('chapterTitleBegin'),
        duration: 0,
        text: "",
        files: [],
        video: ''
    });

    const [msgLimitInput, setmsgLimitInput] = useState("")

    useEffect(() => {
        let data = (typeContent === "FINALEXAM" || typeContent === "KNOWLEDGETEST") ? props?.testForm?.duration : props.chapterForm?.duration
        if (data) {
            const hours = Math.floor(data / 60);
            const minutes = data % 60;

            setHour(hours < 10 ? "0" + hours : "" + hours);
            setMinute(minutes < 10 ? "0" + minutes : "" + minutes);
        }
    }, [props.chapterForm]);


    useEffect(() => {
        let compteurChapter = 1;
        let compteurTest = 1;
        const liste = props?.courseForm?.orders?.
            concat(props?.listTest?.filter((test: any) => test.qnaType === "KNOWLEDGETEST"))?.
            sort((a: any, b: any) => {
                if (a.index > b.index) { return 1; } else if (a.index < b.index) { return -1 } else { return 0 }
            })?.map((obj: any, index: number) => {
                if (obj?.chapter) {
                    obj['compteur'] = compteurChapter;
                    compteurChapter++;
                } else {
                    obj['compteur'] = compteurTest;
                    compteurTest++;
                }
                return obj;
            });
        if (props?.listTest?.filter((test: any) => test.qnaType === "FINALEXAM").length > 0) {
            liste.push(props?.listTest?.filter((test: any) => test.qnaType === "FINALEXAM")[0]);
        }
        const listSelet = liste?.map((obj: any) => {
            if (obj.chapter) {
                return { label: obj.chapter.title, value: JSON.stringify({ id: obj.chapter.id, type: 'CHAPTER' }) };
            } else {
                return { label: obj.title, value: JSON.stringify({ id: obj.id, type: obj.qnaType }) };
            }
        });
        setListeSelect(listSelet)
        setAllListe(liste);
        setCourseForm(props?.courseForm);
        setListeTest(props?.listTest);
        if (props?.chapterForm) {
            setChapterForm(props?.chapterForm)
        }
        if (props?.testForm) {
            const copyTestForm = { ...props?.testForm };
            if (copyTestForm.duration === 0 && props?.typeContent === 'FINALEXAM') {
                copyTestForm.duration = 0;
            }
            setTestForm(copyTestForm);
        }
        return () => {
            props?.getCreateOrUpdate('');
        }
    }, []);

    useEffect(() => {
        let index = 0;
        if (props?.typeContent === 'CHAPTER') {
            index = allListe.findIndex((obj: any) => obj?.chapter?.id === props?.chapterForm?.id);
        } else {
            index = allListe.findIndex((obj: any) => (obj?.id === props?.testForm?.id && obj.chapter === undefined));
        }
        if (index !== -1) {
            setActualIndex(index);
        }

    }, [props?.typeContent, allListe]);

    useEffect(() => {
        if (props?.testForm) {
            const copyTest = { ...testForm };
            copyTest.index = props?.indexContent + 1
            if (copyTest.duration === 0 && props?.typeContent === 'FINALEXAM') {
                copyTest.duration = 0;
            }
            if (copyTest.title !== testForm.title) {
                copyTest.title = testForm.title;
            }
            setTestForm(copyTest);
        }
    }, [props?.indexContent]);

    const deleteChapterFn = async () => {
        await props?.deleteChapter(chapterForm.id, courseForm.id);
        await props?.clearChapter();
        history.push(`${returnStartPathUrl()}/courses/create-formation/course/content`);
    }

    const deleteExamFn = async () => {
        if (testForm && testForm.id) {
            const index = listeTest.findIndex((obj: any) => obj.id === testForm.id);
            await props?.deleteExam(testForm.id, index);
        }

        history.push(`${returnStartPathUrl()}/courses/create-formation/course/content`);
    }

    useEffect(() => {
        if (refresh) {
            setChapterForm(props?.chapterForm)
            setRefresh(false);
        }
    }, [props?.chapterForm]);

    useEffect(() => {
        if (props?.testForm) {
            const copyTestForm = { ...props?.testForm };
            if (copyTestForm.duration === 0 && props?.typeContent === 'FINALEXAM') {
                copyTestForm.duration = 0;
            }
            if (copyTestForm.title !== testForm.title) {
                copyTestForm.title = props?.testForm?.title;
            }
            setTestForm(copyTestForm)
        }
    }, [props?.testForm]);

    const changeContent = (value: any) => {
        const object = JSON.parse(value);
        if (object.type === "CHAPTER") {
            props?.clearFormVideoChapter();
            const chapter = courseForm?.orders?.find((obj: any) => obj?.chapter?.id === object?.id);
            const index = courseForm?.orders?.findIndex((obj: any) => obj?.chapter?.id === object?.id)
            const indexAll = allListe.findIndex((obj: any) => obj?.chapter?.id === object?.id);
            setActualIndex(indexAll);
            props?.getChapter(chapter.chapter, index)
            props?.setIndexContent(index);
            props?.setTypeFormContentCourse('CHAPTER');
            // props?.updatePath({ title: t('breadCrumbChapter') + " " + (index + 1), index: 2 });
        } else {
            const exam = listeTest.find((obj: any) => obj.id === object.id);
            const index = listeTest.findIndex((obj: any) => obj.id === object.id);
            const indexAll = allListe.findIndex((obj: any) => (obj?.id === object.id && object.chapter === undefined));
            setActualIndex(indexAll);
            props?.setIndexContent(index);
            props?.getExam(exam, index);
            props?.setTypeFormContentCourse(exam.qnaType);
            // const title = (exam.qnaType === "FINALEXAM") ? t('finalExmBreadcrunbTitle') : t("breadCrumbTest") + " " + (index + 1);
            // props?.updatePath({ title: title, index: 2 });
        }
    }

    const goPreviousChapter = () => {
        if (allListe[actualIndex - 1].chapter) {
            props?.clearFormVideoChapter();
            const index = courseForm?.orders?.findIndex((obj: any) => obj?.chapter?.id === allListe[actualIndex - 1].chapter.id)
            props?.getChapter(allListe[actualIndex - 1].chapter, index)
            props?.setIndexContent(index);
            props?.setTypeFormContentCourse('CHAPTER');
            // props?.updatePath({ title: t('breadCrumbChapter') + " " + (index + 1), index: 2 });
        } else {
            const index = listeTest.findIndex((obj: any) => obj.id === allListe[actualIndex - 1].id);
            props?.setIndexContent(index);
            props?.getExam(allListe[actualIndex - 1], index);
            props?.setTypeFormContentCourse(allListe[actualIndex - 1].qnaType);
            // const title = (allListe[actualIndex - 1].qnaType === "FINALEXAM") ? t('finalExmBreadcrunbTitle') : t("breadCrumbTest") + " " + (index + 1);
            // props?.updatePath({ title: title, index: 2 });
        }
        setActualIndex(actualIndex - 1);
    }

    const goNextChapter = () => {
        if (allListe[actualIndex + 1].chapter) {
            props?.clearFormVideoChapter();
            const index = courseForm.orders.findIndex((obj: any) => obj?.chapter.id === allListe[actualIndex + 1].chapter.id);
            props?.getChapter(allListe[actualIndex + 1].chapter, index)
            props?.setIndexContent(index);
            props?.setTypeFormContentCourse('CHAPTER');
            // props?.updatePath({ title: t('breadCrumbChapter') + " " + (index + 1), index: 2 });
        } else {
            const index = listeTest.findIndex((obj: any) => obj.id === allListe[actualIndex + 1].id);
            props?.setIndexContent(index);
            props?.getExam(allListe[actualIndex + 1], index);
            props?.setTypeFormContentCourse(allListe[actualIndex + 1].qnaType);
            // const title = (allListe[actualIndex + 1].qnaType === "FINALEXAM") ? t('finalExmBreadcrunbTitle') : t("breadCrumbTest") + " " + (index + 1);
            // props?.updatePath({ title: title, index: 2 });
        }
        setActualIndex(actualIndex + 1);
    }

    const changeTitle = async (value: string) => {

        if (value.length === 254) {
            setmsgLimitInput(`Le titre ${typeContent === 'CHAPTER' ? 'du chapitre' : typeContent === 'KNOWLEDGETEST' ? 'du test' : typeContent === 'FINALEXAM' ? "de l'examen" : ''} ne doit pas dépasser les 254 caractères (Espaces inclus)`)
        }
        if (value.length < 254) setmsgLimitInput("")

        if (typeContent === "CHAPTER") {
            const copyChapter = { ...chapterForm };
            copyChapter.title = value;
            await setChapterForm(copyChapter);
        } else {
            const copyTest = { ...testForm };
            copyTest.title = value;
            if (copyTest.duration === 0 && props?.typeContent === 'FINALEXAM') {
                copyTest.duration = 0;
            }
            await setTestForm(copyTest);
        }
        await debouncedChangeHandler(value)
    }

    const CourseApi = async (title: any) => {
        await props?.changeTitle(title)
    }

    const updateBreadCrumbChapterTitle = async () => {
        const newArrayHistory = props?.historyBreadCrumb.slice(0, props?.historyBreadCrumb.length - 1);
        props?.goToRedirection(newArrayHistory)
        if (typeContent === "CHAPTER") {
            props?.redirect(chapterForm.title, "")
        }
        else {
            props?.redirect(testForm.title, "")
        }
    }

    const debouncedChangeHandler = useCallback(debounce(CourseApi, 1000), []);
    const saveDataDuration = (type: string, hour: string, minute: string) => {


        let value = Number(hour) * 60 + Number(minute)
        if (type === "CHAPTER") {
            const chapterCopy = { ...chapterForm };
            chapterCopy.duration = value;
            if (chapterCopy?.id !== null) {
                let chapterToUpdate = {
                    id: props?.chapterForm?.id,
                    status: "ACTIVE",
                    title: props?.chapterForm?.title?.length === 0 ? t('chapterTitleBegin') : props?.chapterForm?.title,
                    duration: value,
                    text: props?.chapterForm?.text?.length === 0 ? "" : props?.chapterForm?.text,
                    files: props?.chapterForm?.files,
                    video: props?.chapterForm?.video
                }
                props?.updateChapter(chapterToUpdate, props?.courseForm?.id);
            } else {
                chapterCopy['index'] = props?.indexContent;
                props?.createChapter(chapterCopy, props?.courseForm?.id);
            }
            setChapterForm(chapterCopy);
        } else {
            const testCopy = { ...testForm };
            testCopy.duration = value;
            const index = listeTest.findIndex((obj: any) => (obj?.id === props?.testForm?.id));
            if (testCopy.id !== null) {
                props?.updateExam(testCopy, index);
            } else {
                testCopy['index'] = props?.indexContent;
                props?.createExam(testCopy);
            }
            setTestForm(testCopy);
        }

    }

    const displayButtonDelete = () => {
        if (typeContent === "CHAPTER") return <button onClick={() => deleteChapterFn()} type="button" className="btn-QuickActions me-3">
            <span className="material-icons-outlined">delete</span>
        </button>
        return <button type="button" onClick={() => { deleteExamFn() }} className="btn-QuickActions me-3">
            <span className="material-icons-outlined">delete</span>
        </button>
    }

    const valueSelectElement = () => {
        if (typeContent === "CHAPTER") return ` ${chapterForm.duration}`
        if (testForm.duration === 0) return ` ${0}`
        return ` ${testForm.duration}`
    }

    // const displayAccordingType = () => {
    //     if (typeContent === "KNOWLEDGETEST") return <div className="d-flex align-items-center">
    //         <span className="material-icons-outlined me-2" style={{ color: "#CACBCE" }}>timer</span>
    //         <span className="body-md bold black-800">{testForm?.duration} mn</span>
    //     </div>
    //     return <SelectElement
    //         disabled={true}
    //         taille={"lg"}
    //         onChange={(value: string) => { saveDataDuration(typeContent, value) }}
    //         defaultValue={valueSelectElement()}
    //         options={times}
    //         placeholder={'time'}
    //     />
    // }

    const displayValueInput = () => {
        if (typeContent === "CHAPTER") return chapterForm.title
        return testForm.title
    }

    const displayPlaceholderInput = () => {
        if (typeContent === 'CHAPTER') return t('placeholderTitleChapter');
        if (typeContent === 'FINALEXAM') return t('exam1');
        return `${t('exam2')}...`;
    }


    return (<div style={{ backgroundColor: '#FFFFFF' }} className="w-100 py-4">
        <div style={{ maxWidth: "1200px", margin: 'auto' }} className="row">
            <div className="d-flex  justify-content-between">
                <div className="d-flex align-items-center flex-wrap mb-3">
                    {displayButtonDelete()}
                    <div style={{ width: '320px' }}>
                        {/* disabled={(createOrUpdate === 'create')} */}
                        <SelectElement disabled={true} taille={"lg"} onChange={(value: string) => { changeContent(value) }} defaultValue={listeSelect[actualIndex]?.value} options={listeSelect} placeholder={'Sélectionnez un chapitre ou un examen'} />
                    </div>
                    {/* disabled={(actualIndex === 0) || (createOrUpdate === 'create')} */}
                    <button disabled={true} onClick={() => goPreviousChapter()} type="button" className="btn-QuickActions mx-3" >
                        <span className="material-icons-outlined">chevron_left</span>
                    </button>
                    {/* disabled={(actualIndex === allListe.length - 1) || (createOrUpdate === 'create')} */}
                    <button disabled={true} onClick={() => goNextChapter()} type="button" className="btn-QuickActions">
                        <span className="material-icons-outlined">chevron_right</span>
                    </button>
                </div>
                <div style={{ width: '165px' }}>
                    {/*(typeContent === "KNOWLEDGETEST") ?
                        <div className="d-flex align-items-center">
                            <span className="material-icons-outlined me-2" style={{ color: "#CACBCE" }}>timer</span>
                            <span className="body-md bold black-800">{testForm?.duration} mn</span>
                        </div>
                        :*/
                        <div className="horloge d-flex" style={{ alignItems: "end" }}>
                            <div className="frame">
                                <div className="d-flex flex-row  align-items-center">
                                    <span className="material-icons-outlined me-2" style={{ color: "#CACBCE" }}>timer</span>


                                    <TextField
                                        type="number"
                                        size="small"
                                        placeholder="00"
                                        style={{ paddingTop: "0px!important" }}
                                        value={hour}
                                        onChange={(e: any) => { saveDataDuration(typeContent, e.target.value, minute) }}
                                        onInput={(e: any) => {

                                            let value = e.target.value;
                                            if (parseInt(value) < 10) {
                                                value = "0" + value;
                                            } else {
                                                value = Math.min(99, Math.max(0, parseInt(value))).toString().slice(0, 2);
                                            }
                                            e.target.value = value;
                                            setHour(e.target.value)
                                        }}
                                        InputProps={{

                                            className: classes.input,
                                            disableUnderline: true,
                                            style: {
                                                height: "24px",
                                                paddingTop: "0px!important",
                                                backgroundColor: "#F2F2F5",
                                                width: "26px",
                                                margin: 0,
                                                borderRadius: "3px",
                                                color: "black",
                                                textAlign: "center",
                                                appearance: "textfield",
                                                MozAppearance: "textfield",
                                                WebkitAppearance: "none",
                                            }
                                        }}
                                    />
                                    <span className="me-1 ms-1 body-md  ">h :</span>
                                    <TextField
                                        type="number"
                                        size="small"
                                        placeholder="00"
                                        style={{ paddingTop: "0px!important" }}
                                        value={minute}
                                        onChange={(e: any) => { saveDataDuration(typeContent, hour, e.target.value) }}

                                        onInput={(e: any) => {
                                            let value = e.target.value;
                                            if (parseInt(value) < 10) {
                                                value = "0" + value;
                                            } else {
                                                value = Math.min(59, Math.max(0, parseInt(value))).toString().slice(0, 2);
                                            }
                                            e.target.value = value;
                                            setMinute(e.target.value)
                                        }}
                                        InputProps={{

                                            className: classes.input,
                                            disableUnderline: true,
                                            style: {
                                                height: "24px",
                                                paddingTop: "0px!important",
                                                backgroundColor: "#F2F2F5",
                                                width: "26px",
                                                margin: 0,
                                                borderRadius: "3px",
                                                color: "black",
                                                textAlign: "center",
                                                appearance: "textfield",
                                                MozAppearance: "textfield",
                                                WebkitAppearance: "none",
                                            }
                                        }}
                                    />
                                    <span className="body-md ms-1">min</span>
                                </div>
                            </div>
                        </div>
                    }



                </div>


            </div>
            <div className="d-flex w-100 position-relative" onBlur={() => { updateBreadCrumbChapterTitle() }}>
                {/* cours chapter */}
                <input
                    dir="ltr"
                    type="text"
                    id="title"
                    value={displayValueInput()}
                    onChange={(e) => { changeTitle(e.target.value) }}
                    className="input-title"
                    placeholder={displayPlaceholderInput()}
                    maxLength={254}
                />
                <span className='msg-error'>{msgLimitInput}</span>
            </div>
        </div>
    </div>)
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    updatePath,
    getChapter,
    createChapter,
    updateChapter,
    deleteChapter,
    clearChapter,
    updateExam,
    getExam,
    createExam,
    setIndexContent,
    setTypeFormContentCourse,
    deleteExam,
    getCreateOrUpdate,
    clearFormVideoChapter,
    redirect,
    goToRedirection
}, dispatch);
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    courseForm: state.courseReducer.courseForm,
    listHistory: state.historyReducer.listHistoryBreadCrumb,
    history: ownProps?.history,
    chapterForm: state.courseReducer.chapterForm,
    testForm: state.courseReducer.testForm,
    indexChapter: state.courseReducer.indexChapter,
    indexTest: state.courseReducer.indexTest,
    listTest: state.courseReducer.listTest,
    typeContent: state.courseReducer.typeContentCourse,
    indexContent: state.courseReducer.indexContent,
    changeTitle: ownProps?.changeTitle,
    createOrUpdate: state.courseReducer.createOrUpdate,
    historyBreadCrumb: state.historyReducer.history,
});
export default connect(mapStateToProps, mapDispatchToProps)(HeaderChapterExmFormComponent)
