import { ComponentProps, FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { openModal, closeModal } from '../../../global-state/actions/modal-actions';
import { publishCourse, saveFormPaymentInfo, publishCourseWithOfferInit, clearFormPayment, updateCoursesLists, getOfferCourse, getOfferPricingCourse, getCoursesPublishedLength } from '../../../global-state/actions/course-actions';
import { ModalIDS } from '../modal-ids';
import TextareaUnderlineElement from '../../../elements/text-area/text-area-underline-element';
import { saveReturnPath, RedirectPopup } from '../../../global-state/actions/breadcrumb-actions';
import { getDomainFromUrl } from '../../../helpers/domainCheck';
import { valideTraining, publishTraining, createTraining } from '../../../global-state/actions/training-actions';
import { createCourse } from '../../../global-state/actions/course-actions';


const AcceptTrainingModalComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const [courseInfo, setCourseInfo] = useState<any>();
    const [type, setType] = useState<any>();


    const [comment, setComment] = useState<string>("");
    const [freeOrPaid, setFreeOrPaid] = useState<boolean>(false);
    const [training, setTraining] = useState<any>("");
    const [trainingSession, setTrainingSession] = useState<any>("");
    const [offerCourse, setOfferCourse] = useState<any>({
        id: null,
        idEntity: props?.connectedUser?.idSchool,
        label: props?.courseInfo?.title,
        status: "VALIDE",
        typePricing: "STATIC",

        rules: [
            {
                id: null,
                key: "id_course",
                value: props?.courseInfo?.id,
                valueType: "number",
                code_product: "CRS"
            }
        ],
        paymentEngine: props?.paymentMethod?.paymentEngine,
        pricings: [{
            id: null,
            period: "ONESHOT",
            status: "ACTIVE",
            price: (props?.formPaymentInfo) ? props?.formPaymentInfo?.price : 0,
            currency: props?.paymentMethod?.currency,
            startDate: new Date(),
            endDate: null
        }]
    })
    const orderTraining = useMemo(() => props?.lengthCourses + 1, [props?.lengthCourses])

    useEffect(() => {
        if (props?.formPaymentInfo && props?.formPaymentInfo !== null) {
            setComment(props?.formPaymentInfo?.comment);
            const copyOfferCourse = { ...offerCourse };
            copyOfferCourse.pricings[0].price = props?.formPaymentInfo?.price;
            setOfferCourse(copyOfferCourse);
            setFreeOrPaid(props?.formPaymentInfo?.freeOrPaid);
        }
    }, [props?.formPaymentInfo]);

    useEffect(() => {
        const courseForm: any = { ...props?.courseInfo };
        courseForm['index'] = orderTraining;
        setCourseInfo(courseForm)

    }, [props?.courseInfo]);



    useEffect(() => {
        const trainingForm: any = { ...props?.training };
        trainingForm['index'] = orderTraining;
        setTraining(trainingForm);

    }, [props?.training])

    useEffect(() => {
        const trainingSession: any = { ...props?.trainingSession };
        trainingSession['index'] = orderTraining;
        setTrainingSession(trainingSession)
    }, [props?.trainingSession])

    useEffect(() => {
        setType(props?.content?.data?.type);

        const copyOfferCourse = { ...offerCourse };
        switch (props?.content?.data?.type) {
            case 'course':
                copyOfferCourse.label = props?.courseInfo?.title
                copyOfferCourse.paymentEngine = props?.paymentSchoolInfo?.paymentEngine;
                copyOfferCourse.idEntity = props?.schoolInfo?.id;
                copyOfferCourse.pricings[0].currency = props?.paymentSchoolInfo?.currency;
                copyOfferCourse.rules = [
                    {
                        id: null,
                        key: "id_course",
                        value: props?.courseInfo?.id,
                        valueType: "number",
                        code_product: "CRS"
                    }
                ]
                break;
            case 'path':
                copyOfferCourse.label = props?.training?.title
                copyOfferCourse.paymentEngine = props?.paymentSchoolInfo?.paymentEngine;
                copyOfferCourse.idEntity = props?.schoolInfo?.id;
                copyOfferCourse.pricings[0].currency = props?.paymentSchoolInfo?.currency;
                copyOfferCourse.rules = [
                    {
                        id: null,
                        key: "id_path",
                        value: props?.training?.id,
                        valueType: "number",
                        code_product: "PTH"
                    }
                ]
                break;
            case 'session':
                copyOfferCourse.label = props?.trainingSession?.title
                copyOfferCourse.paymentEngine = props?.paymentSchoolInfo?.paymentEngine;
                copyOfferCourse.idSchool = props?.schoolInfo?.id;
                copyOfferCourse.pricings[0].currency = props?.paymentSchoolInfo?.currency;
                copyOfferCourse.rules = [
                    {
                        id: null,
                        key: "id_session",
                        value: props?.trainingSession?.id,
                        valueType: "number",
                        code_product: "SSN"
                    }
                ]
                break;

        }
        setOfferCourse(copyOfferCourse);
    }, [props?.content])
    const validateCours = async (value: string) => {
        let code = getDomainFromUrl()
        let obj = {
            comment: comment,
            idCours: (type === "course") ? courseInfo?.id : (type === "path") ? training?.id : trainingSession?.id,
            status: "",
            title: (type === "course") ? courseInfo?.title : (type === "path") ? training?.title : trainingSession?.title,
            code: code,
            userRole: props?.connectedUser?.role,
            idSchool: props?.connectedUser?.idSchool,
            trainingType: (type === "course") ? courseInfo?.type : (type === "path") ? training?.type : trainingSession?.type,
        }
        await props?.clearFormPayment();

        switch (type) {
            case 'course':
                const courseForm: any = { ...courseInfo };
                courseForm['isVisible'] = true;
                courseForm['index'] = orderTraining;
                await props?.createCourse(courseForm, props?.connectedUser)
                await props?.publishCourseWithOfferInit(value, courseForm?.idProf, props?.connectedUser?.idPerson, obj, offerCourse);
                await props?.openModal({ idModal: ModalIDS.SUCCESS_MODAL_WITH_BUTTON, size: "md", buttonText: t('returnToCourse'), lines: [t('publishCourseSuccessModal')] });
                break;
            case 'path':
                const pathForm: any = { ...training };
                pathForm['isVisible'] = true;
                pathForm['diploma'] = null;
                pathForm['index'] = orderTraining;
                await props?.createTraining("paths", pathForm)
                await props?.publishTraining(value, props?.connectedUser?.idPerson, pathForm?.idProf, obj)
                await props?.valideTraining("paths", { ...pathForm, status: "PUBLISHED" }, offerCourse);
                await props?.openModal({ idModal: ModalIDS.SUCCESS_MODAL_WITH_BUTTON, size: "md", buttonText: t('returnToPathButton'), lines: [t('publishPathSuccessModal')] });
                break;
            case 'session':
                const sessionForm: any = { ...trainingSession };
                sessionForm['isVisible'] = true;
                sessionForm['diploma'] = null;
                sessionForm['index'] = orderTraining;
                await props?.createTraining("sessions", sessionForm)
                await props?.publishTraining(value, props?.connectedUser?.idPerson, sessionForm?.idProf, obj)
                await props?.valideTraining("sessions", { ...sessionForm, status: "PUBLISHED" }, offerCourse);
                await props?.openModal({ idModal: ModalIDS.SUCCESS_MODAL_WITH_BUTTON, size: "md", buttonText: t('returnToSessionButton'), lines: [t('publishSessionSuccessModal')] });
                break;
        }
    }


    const [typeTarification, setTypeTarification] = useState<any>();
    const [offerTraining, setOfferTraining] = useState<any>(props?.courseTarificationForm);


    let [defaultValueAbn, setDefaultValueAbn] = useState("none");
    const [priceAbn, setPriceAbn] = useState<any>();

    const [unique, setUnique] = useState<boolean>(false);
    const [priceUnique, setPriceUnique] = useState<any>();

    const [defaultValue, setDefaultValue] = useState("none");
    const [payRules, setPayRules] = useState<any>();
    const [idOffer, setIdOffer] = useState<number>();


    const [easy, setEasy] = useState<boolean>(false);
    const [items, setItems] = useState([
        { index: 0, mp: undefined, due: undefined },
    ]);

    const init = () => {
        if (props?.courseTarificationForm != undefined) {
            setOfferTraining(props?.courseTarificationForm)
            let index = 0;

            if (offerTraining?.pricings?.length > 0) {
                for (const pricing of offerTraining?.pricings) {
                    if (pricing.period === "ONESHOT" && pricing.nbPeriod === null && pricing.price != 0) {
                        setTypeTarification('Pay')
                        setUnique(true)
                        setPriceUnique(pricing.price)
                    } if (pricing.period === "MONTH" && pricing.nbPeriod != null) {
                        setTypeTarification("Pay")
                        setEasy(true)

                        const newItem = { index, mp: pricing.price, due: pricing.nbPeriod };
                        index++;
                        setItems(prevItems => {
                            const updatedItems = [
                                ...prevItems.filter(item => item.mp !== undefined && item.due !== undefined),
                                newItem
                            ];
                            const uniqueItems: { index: number; mp: any; due: any; }[] = [];
                            updatedItems.forEach(item => {
                                if (!uniqueItems.some(t => t.mp === item.mp && t.due === item.due)) {
                                    uniqueItems.push(item);
                                }
                            });
                            return uniqueItems;
                        });

                    }
                    if (pricing.price === 0 && pricing.period === "ONESHOT" && pricing.nbPeriod === null) {
                        setTypeTarification("Free")
                    }
                    if (["MONTH", "WEEK", "QUARTER", "SEMESTER"].includes(pricing.period) && pricing.nbPeriod == null) {
                        setDefaultValueAbn(pricing.period)
                        setPriceAbn(pricing.price)
                        setTypeTarification("Abn")
                    }
                }
            }
            if (offerTraining?.rules.length > 0) {
                for (const rules of offerTraining?.rules) {
                    if (rules.key === "nb_month_access") {
                        if (rules.value > 0) {
                            setPayRules(rules.value)
                            setDefaultValue("limited")
                        } else if (rules.value == -1) {
                            setDefaultValue("illimited")
                        }
                    }
                }

            }

        }
    }

    useEffect(() => {
        if (props?.courseTarificationForm !== undefined && props?.courseTarificationForm !== null) {
            init()
        }
    }, [props?.courseTarificationForm]);
    useEffect(() => {
        if (idOffer !== undefined && idOffer !== null && typeof idOffer === 'number') {
            props?.getOfferPricingCourse(idOffer)
        }
    }, [idOffer]);

    useEffect(() => {
        if(props?.actualOffer?.id !==undefined){
            setIdOffer(props?.actualOffer?.id)
        }
    }, [props?.actualOffer]);

    useEffect(() => {
        if(props?.connectedUser !== undefined){
            props?.getCoursesPublishedLength({
                entityId: props?.connectedUser?.idSchool,
                entityType: 'SCHOOL',
                pageSize: 12,
                pageNumber: 0,
            })
        }
    }, [props?.connectedUser])
    

    return (
        <>
            <div className="modal-header p-4 pb-0" style={{ border: "none" }}>
                <div className="ms-auto">
                    <button className="btn-QuickActions" onClick={() => props?.closeModal()}>
                        <span className="material-icons-outlined" >close</span>
                    </button>
                </div>
            </div>
            <div className="modal-body mb-3" style={{ paddingTop: 0, overflow: 'hidden' }}>
                <div className="row body-modal-publish">
                    <div className="col-md-10" style={{ margin: 'auto' }}>
                        <div className="d-flex justify-content-start flex-column mb-3">
                            <span className="H3-Headline black-800"> {t((props?.content?.data?.type === 'course') ? 'acceptCourseText' : (props?.content?.data?.type === 'path') ? 'acceptPathText' : 'acceptSessionText')}</span>
                            <span className="H4-Subtitle neutral-3" style={{ marginTop: '4px' }}> {(type === 'course') ? courseInfo?.title : (type === 'path') ? training?.title : trainingSession?.title} </span>
                            <span className="body-sm black-800 mt-3">{(type === 'course') ? "Ce cours" : (type === 'path') ? "Ce parcours" : "Cette session"}  sera accessible à tous les étudiants.
                            </span>


                        </div>
                        <div className="col-md-12 mt-4 input-full">
                            <p className="form-title mb-2">{t('modalPublishCourse_2_2')}</p>
                            <div className="input-text-form-control">
                                <TextareaUnderlineElement type="text" id={"commentReactvateCourse"} value={comment} onChange={(e: string) => { setComment(e) }} placeholder={t('commentSuspendInputProf')} />
                            </div>
                        </div>
                        {/* <div style={{ maxWidth: '587px', marginRight: '10px' }}>
                        <p className="form-title mb-2">Sélectionnez l’auteur de cette formation</p>
                        <SelectElement disabled={false} taille={'lg'} defaultValue={defaultValueProfessors} options={professors} onChange={(e: any) => { handleChangeProf(e);
                       }} placeholder={t('filterProfessors')} />

                        </div> */}
                        <div className="">
                            <h1 className="form-title mt-5" style={{ marginBottom: "30px" }}>Tarification</h1>
                            <div className="d-flex flex-column">
                                {typeTarification == "Free" &&
                                    <span className="body-md " style={{ marginBottom: "12px" }}>Gratuite</span>
                                }
                                {typeTarification == "Abn" &&


                                    <div className='d-flex flex-row ' style={{ marginBottom: "19px" }}>
                                        <div className='d-flex flex-column ' style={{ alignSelf: "center", width: "40%", height: "100%" }}>
                                            <span className="body-md " >Paiement par abonnement</span>

                                        </div>
                                        <div className='d-flex flex-column ' style={{ width: "10%" }}>
                                            <div className='vl' style={{ height: "-webkit-fill-available" }}></div>
                                        </div>
                                        <div className='d-flex flex-column align-items-top'  >
                                            <span className="H3-Headline " style={{ marginBottom: "5px" }}>{priceAbn} &euro; <span className='body-md'> (TTC) / {defaultValueAbn === "MONTH" ? "Mois" : "Semaine"}</span></span>

                                        </div>

                                    </div>

                                }
                                {typeTarification == "Pay" &&

                                    unique === true &&
                                    <div className='d-flex flex-row ' style={{ marginBottom: "19px" }}>
                                        <div className='d-flex flex-column ' style={{ alignSelf: "center", width: "40%", height: "100%" }}>
                                            <span className="body-md " >Paiement unique</span>

                                        </div>
                                        <div className='d-flex flex-column ' style={{ width: "10%" }}>
                                            <div className='vl' style={{ height: "-webkit-fill-available" }}></div>
                                        </div>
                                        <div className='d-flex flex-column align-items-top'  >
                                            <span className="H3-Headline " style={{ marginBottom: "5px" }}>{priceUnique} &euro; <span className='body-md'> (TTC) </span></span>
                                            <span className="body-md  neutral-2 bold">Accessible : {defaultValue === "limited" ?
                                                <span className="body-md neutral-2">{payRules} mois</span> : <span className="body-md neutral-2"> Illimité</span>}</span>
                                        </div>

                                    </div>




                                }
                                {typeTarification == "Pay" &&

                                    easy === true &&


                                    <div className='d-flex flex-row ' style={{ marginBottom: "19px" }}>
                                        <div className='d-flex flex-column ' style={{ alignSelf: "center", width: "40%", height: "100%" }}>

                                            <span className="body-md " >Paiement par facilité</span>
                                        </div>
                                        <div className='d-flex flex-column ' style={{ width: "10%" }}>
                                            <div className='vl' style={{ minHeight: "-webkit-fill-available" }}></div>
                                        </div>
                                        <div className='d-flex flex-column align-items-top'  >
                                            {items.map((ligne) => (
                                                <span className="H3-Headline "> {ligne.mp} &euro;
                                                    <span className='body-md'> (TTC) x {ligne.due} mois </span>
                                                </span>
                                            ))}
                                            <span className="body-md  neutral-2 bold">Accessible : {defaultValue === "limited" ?
                                                <span className="body-md neutral-2">{payRules} mois</span> : <span className="body-md neutral-2"> Illimité</span>}</span>
                                        </div>

                                    </div>

                                }
                            </div>
                        </div>

                        <div className="d-flex flex-column w-100 mt-5 mb-4 ">
                            <div className='d-flex flex-row justify-content-end'>
                                <button type="button" className="btn-Secondary large me-3" onClick={() => { props?.closeModal() }}>{t('annuler')}</button>
                                <button type="button" className="btn-Primary large success" onClick={() => { validateCours("PUBLISHED"); }}
                                >{t('publish')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}
const mapStateToProps = (state: AppState) => ({
    content: state.modalReducer.modalContent,
    connectedUser: state.authReducer.connectedUser,
    courseInfo: state.courseReducer.courseInfo,
    formPaymentInfo: state.courseReducer.formPaymentInfo,
    paymentSchoolInfo: state.schoolInfoReducer.paymentSchoolInfo,
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    training: state.trainingReducer.path,
    trainingSession: state.trainingReducer.session,
    userInfo: state.authReducer.connectedUserInfo,
    courseTarificationForm: state.courseReducer.courseTarificationForm,
    actualOffer: state.courseReducer.actualOffer,
    lengthCourses: state.courseReducer.LengthCoursesPublished,

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            openModal,
            closeModal,
            publishCourse,
            updateCoursesLists,
            saveFormPaymentInfo,
            publishCourseWithOfferInit,
            clearFormPayment,
            getOfferCourse,
            getOfferPricingCourse,
            saveReturnPath,
            valideTraining,
            publishTraining,
            RedirectPopup,
            createCourse,
            createTraining,
            getCoursesPublishedLength
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(AcceptTrainingModalComponent);