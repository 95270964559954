import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import axios from '../../helpers/interceptors';
import { IReduxFinanceState } from '../reducers/finance-reducer';
import globalAxios from 'axios';
import axiosInstance from '../../helpers/interceptors';

export enum EReduxActionTypes {
    UPDATE_FINANCE_COURSES = 'UPDATE_FINANCE_COURSES',
    ALL_COURSES = 'ALL_COURSES',
    ALL_OFFER_PAYED = 'ALL_OFFER_PAYED',
    ALL_PAYED_COURSE = 'ALL_Payed_COURSE',
    ALL_PROFESSORS_BY_SCHOOL = 'ALL_PROFESSORS_BY_SCHOOL',
    CUMUL_SALES_OFFER = 'CUMUL_SALES_OFFER',
    ALL_YEARS_MONTH = 'ALL_YEARS_MONTH',
    UPDATE_PRICE = 'UPDATE_PRICE',
    LOADING_OFFER = 'LOADING_OFFER',
    LOADING_OFFER_SCROLL = 'LOADING_OFFER_SCROLL',
    LOADING_PAYMENT = 'LOADING_PAYMENT',
    LOADING_PAYMENT_SCROLL = 'LOADING_PAYMENT_SCROLL',
    CREATE_PAYMENT = 'CREATE_PAYMENT',
    CHECK_PAYMENT = 'CHECK_PAYMENT',
    HANDLE_PAYMEE_PAYMENT = 'HANDLE_PAYMEE_PAYMENT',
    CLEAR_PAYMENT_STATUS = 'CLEAR_PAYMENT_STATUS',
    MODIFY_OFFER_PRICE_CONFRIMATION = 'MODIFY_OFFER_PRICE_CONFRIMATION',
    CANCEL_PRODUCT_OFFER_UPDATE = 'CANCEL_PRODUCT_OFFER_UPDATE',
    CLEAR_OFFER_TO_UPDATE = 'CLEAR_OFFER_TO_UPDATE',
    GET_OFFER = 'GET_OFFER',
    GET_OFFERS = 'GET_OFFERS',
    GET_CONTRACT = 'GET_CONTRACT',
    DELETE_CONTRACT = 'DELETE_CONTRACT',
    GET_COURSE_STATUS = 'GET_COURSE_STATUS',
    GET_PROF_STATUS = 'GET_PROF_STATUS',
    GET_STUDENT_STATUS = 'GET_STUDENT_STATUS',
    GET_ACTIVE_STUDENTS = 'GET_ACTIVE_STUDENTS',
    GET_LOCALISATION = 'GET_LOCALISATION',
    GET_PRICE_ID = 'GET_PRICE_ID',
    GET_COUNTS = 'GET_COUNTS',
    GET_PAYMENT_SETTING = 'GET_PAYMENT_SETTING',
    GET_LIMITATIONS = 'GET_LIMITATIONS',
    GET_SIZE = 'GET_SIZE',
    GET_SIZE_PAYMENT = 'GET_SIZE_PAYMENT',
    VERIFY_API = "VERIFY_API",
    GET_PAYMENT_FILTER = 'GET_PAYMENT_FILTER',
    GET_PRODUCT_LIST = 'GET_PRODUCT_LIST',
    GET_OFFER_TRAININGS = 'GET_OFFER_TRAININGS',
    GET_LIST_PAYMENTS = 'GET_LIST_PAYMENTS',
    LOADING_SUBSCIPTION = 'LOADING_SUBSCIPTION',
    LOADING_SUBSCIPTION_SCROLL = 'LOADING_SUBSCIPTION_SCROLL',
    GET_SIZE_SUB="GET_SIZE_SUB",
    SAVE_CHANGE_TO="SAVE_CHANGE_TO",
    MIGRATE_OFFERS="MIGRATE_OFFERS"

}

interface IReduxBaseAction {
    type: EReduxActionTypes;
}

export interface IReduxGetSizeAction extends IReduxBaseAction {
    type: EReduxActionTypes.GET_SIZE;
    data: any;
}

export interface IReduxMigrateOffersAction extends IReduxBaseAction {
    type: EReduxActionTypes.MIGRATE_OFFERS;
}
export interface IReduxSaveChangeToAction extends IReduxBaseAction {
    type: EReduxActionTypes.SAVE_CHANGE_TO;
    data: any;
}

export interface IReduxGetSizeSubAction extends IReduxBaseAction {
    type: EReduxActionTypes.GET_SIZE_SUB;
    data: any;
    filter:any;
}
export interface IReduxGetListPaymentsAction extends IReduxBaseAction {
    type: EReduxActionTypes.GET_LIST_PAYMENTS | EReduxActionTypes.LOADING_SUBSCIPTION | EReduxActionTypes.LOADING_SUBSCIPTION_SCROLL;
    data: any;
    filter: any;
    scrollOrNot: any;
}

export interface IReduxGetOfferTrainingsAction extends IReduxBaseAction {
    type: EReduxActionTypes.GET_OFFER_TRAININGS;
    data: any;
}
export interface IReduxGetProductListAction extends IReduxBaseAction {
    type: EReduxActionTypes.GET_PRODUCT_LIST;
    data: any;
}
export interface IReduxGetPaymentFiltereAction extends IReduxBaseAction {
    type: EReduxActionTypes.GET_PAYMENT_FILTER;
    data: any;
}

export interface IReduxGetSizePaymentAction extends IReduxBaseAction {
    type: EReduxActionTypes.GET_SIZE_PAYMENT;
    data: any;
}

export interface IReduxClearOfferToUpdateAction extends IReduxBaseAction {
    type: EReduxActionTypes.CLEAR_OFFER_TO_UPDATE;
    data: any;

}
export interface IReduxVerifyApiAction extends IReduxBaseAction {
    type: EReduxActionTypes.VERIFY_API;
    data: any;

}
export interface IReduxGetLimitationsAction extends IReduxBaseAction {
    type: EReduxActionTypes.GET_LIMITATIONS;
    data: any;

}
export interface IReduxGetPaymentSettingAction extends IReduxBaseAction {
    type: EReduxActionTypes.GET_PAYMENT_SETTING;
    data: any;

}
export interface IReduxGetCountsAction extends IReduxBaseAction {
    type: EReduxActionTypes.GET_COUNTS;
    data: any;

}
export interface IReduxGetPriceIdAction extends IReduxBaseAction {
    type: EReduxActionTypes.GET_PRICE_ID;
    data: any;

}

export interface IReduxGetLocalisationAction extends IReduxBaseAction {
    type: EReduxActionTypes.GET_LOCALISATION;
    data: any;

}
export interface IReduxGetActiveStudentsAction extends IReduxBaseAction {
    type: EReduxActionTypes.GET_ACTIVE_STUDENTS;
    data: any;
}

export interface IReduxGetOffersAction extends IReduxBaseAction {
    type: EReduxActionTypes.GET_OFFERS;
    data: any;
}
export interface IReduxGetCourseStatusAction extends IReduxBaseAction {
    type: EReduxActionTypes.GET_COURSE_STATUS;
    data: any;
}
export interface IReduxGetProfStatusAction extends IReduxBaseAction {
    type: EReduxActionTypes.GET_PROF_STATUS;
    data: any;
}
export interface IReduxGetStudentStatusAction extends IReduxBaseAction {
    type: EReduxActionTypes.GET_STUDENT_STATUS;
    data: any;
}
export interface IReduxDeleteContratAction extends IReduxBaseAction {
    type: EReduxActionTypes.DELETE_CONTRACT;
    data: any;
}
export interface IReduxGetOfferAction extends IReduxBaseAction {
    type: EReduxActionTypes.GET_OFFER;
    data: any;
}
export interface IReduxGetContractAction extends IReduxBaseAction {
    type: EReduxActionTypes.GET_CONTRACT;
    data: any;
}
export interface IReduxCancelOfferUpdateAction extends IReduxBaseAction {
    type: EReduxActionTypes.CANCEL_PRODUCT_OFFER_UPDATE;
    data: any;
}
export interface IReduxConfirmUpdateOfferAction extends IReduxBaseAction {
    type: EReduxActionTypes.MODIFY_OFFER_PRICE_CONFRIMATION;
    data: any;
}

export interface IReduxClearPaymentStatusAction extends IReduxBaseAction {
    type: EReduxActionTypes.CLEAR_PAYMENT_STATUS;
    data: any;
}

export interface IReduxHandlePaymeePaymentAction extends IReduxBaseAction {
    type: EReduxActionTypes.HANDLE_PAYMEE_PAYMENT;

}

export interface IReduxCheckPaymeePayementAction extends IReduxBaseAction {
    type: EReduxActionTypes.CHECK_PAYMENT;
    data: any;
}

export interface IReduxCreatePaymeePayementAction extends IReduxBaseAction {
    type: EReduxActionTypes.CREATE_PAYMENT;
    data: any;
}

export interface IReduxGetFreeCoursesAction extends IReduxBaseAction {
    type: EReduxActionTypes.ALL_COURSES;
    data: any;
}
export interface IReduxUpdatePriceAction extends IReduxBaseAction {
    type: EReduxActionTypes.UPDATE_PRICE;
    data: any;
}

export interface IReduxUpdateCourseFinanceAction extends IReduxBaseAction {
    type: EReduxActionTypes.UPDATE_FINANCE_COURSES;
    data: any;
}

export interface IReduxAllOfferPayedFinanceAction extends IReduxBaseAction {
    type: EReduxActionTypes.ALL_OFFER_PAYED | EReduxActionTypes.LOADING_OFFER | EReduxActionTypes.LOADING_OFFER_SCROLL;
    data: any;
    scrollOrNot: any;
}


export interface IReduxAllPayedCourseFinanceAction extends IReduxBaseAction {
    type: EReduxActionTypes.ALL_PAYED_COURSE | EReduxActionTypes.LOADING_PAYMENT | EReduxActionTypes.LOADING_PAYMENT_SCROLL;
    data: any;
    scrollOrNot: any;
}
export interface IReduxCumulSalesByOfferFinanceAction extends IReduxBaseAction {
    type: EReduxActionTypes.CUMUL_SALES_OFFER;
    data: any;
}

export interface IReduxAllProfessorsByIdSchoolAction extends IReduxBaseAction {
    type: EReduxActionTypes.ALL_PROFESSORS_BY_SCHOOL;
    data: any;
}
export interface IReduxGetYearsMonthAction extends IReduxBaseAction {
    type: EReduxActionTypes.ALL_YEARS_MONTH;
    data: any;
}

export function cancelOfferUpdate(cancel: boolean): ThunkAction<Promise<IReduxCancelOfferUpdateAction>, IReduxFinanceState, undefined, IReduxCancelOfferUpdateAction> {
    return async (dispatch: ThunkDispatch<IReduxFinanceState, undefined, IReduxCancelOfferUpdateAction>) => {

        return dispatch({
            type: EReduxActionTypes.CANCEL_PRODUCT_OFFER_UPDATE,
            data: cancel
        });
    };
}

export function clearOfferToUpdate(): ThunkAction<Promise<IReduxClearOfferToUpdateAction>, IReduxFinanceState, undefined, IReduxClearOfferToUpdateAction> {
    return async (dispatch: ThunkDispatch<IReduxFinanceState, undefined, IReduxClearOfferToUpdateAction>) => {

        return dispatch({
            type: EReduxActionTypes.CLEAR_OFFER_TO_UPDATE,
            data: undefined
        });
    };
}

export function confirmUpdateOffer(offerDetails: any): ThunkAction<Promise<IReduxConfirmUpdateOfferAction>, IReduxFinanceState, undefined, IReduxConfirmUpdateOfferAction> {
    return async (dispatch: ThunkDispatch<IReduxFinanceState, undefined, IReduxConfirmUpdateOfferAction>) => {

        return dispatch({
            type: EReduxActionTypes.MODIFY_OFFER_PRICE_CONFRIMATION,
            data: offerDetails
        });
    };
}

export function getPriceId(priceId: any): ThunkAction<Promise<IReduxGetPriceIdAction>, IReduxFinanceState, undefined, IReduxGetPriceIdAction> {
    return async (dispatch: ThunkDispatch<IReduxFinanceState, undefined, IReduxGetPriceIdAction>) => {

        return dispatch({
            type: EReduxActionTypes.GET_PRICE_ID,
            data: priceId
        });
    };
}


export function handlePaymeePayment(courseInfoId: any, idPerson: any, token: any): ThunkAction<Promise<IReduxHandlePaymeePaymentAction>, IReduxFinanceState, undefined, IReduxHandlePaymeePaymentAction> {
    return async (dispatch: ThunkDispatch<IReduxFinanceState, undefined, IReduxHandlePaymeePaymentAction>) => {
        const baseUrl3 = process.env.REACT_APP_BASE_URL11
        const baseUrl = process.env.REACT_APP_BASE_URL12;
        const baseUrl1 = process.env.REACT_APP_BASE_URL2;
        const baseUrl2 = process.env.REACT_APP_BASE_URL13
        let offerId = await axios.get(baseUrl3 + `offers/offerId/` + courseInfoId.toString()).then(res1 => { return res1.data }).catch(err => console.error(err));
        let pricings = await axios.get(baseUrl + `static/` + offerId).then(res2 => { return res2.data }).catch(err => console.error(err));
        let pricingsId = pricings?.id;
        let amount = pricings?.price;
        let emailPaymee = await axios.get(baseUrl1 + `person/email/` + idPerson).then(res3 => { return res3.data }).catch(err => console.error(err));
        let currency = pricings?.currency;

        await axios.post(baseUrl2 + `payment/createPaymeePayment`, {
            status: "PAYED",
            amount: Number(amount),
            pricingsId: pricingsId,
            paymeeEmail: emailPaymee,
            personId: idPerson,
            currency: currency,
            offerId: offerId
        }).then(res4 => { return res4.data }).catch(err => console.error(err));


        return dispatch({
            type: EReduxActionTypes.HANDLE_PAYMEE_PAYMENT,

        });
    };
}


export function createPaymentRequest(payment: any): ThunkAction<Promise<IReduxCreatePaymeePayementAction>, IReduxFinanceState, undefined, IReduxCreatePaymeePayementAction> {
    return async (dispatch: ThunkDispatch<IReduxFinanceState, undefined, IReduxCreatePaymeePayementAction>) => {
        let paymentRequest: any = await axiosInstance.post(process.env.REACT_APP_BASE_URL16 + "paymee/createPayment", payment).then(response => { return response.data; }).catch(err => { return console.error(err) });


        return dispatch({
            type: EReduxActionTypes.CREATE_PAYMENT,
            data: paymentRequest?.data?.token
        });
    };
}

export function saveChangeTo(changeTo: any): ThunkAction<Promise<IReduxSaveChangeToAction>, IReduxFinanceState, undefined, IReduxSaveChangeToAction> {
    return async (dispatch: ThunkDispatch<IReduxFinanceState, undefined, IReduxSaveChangeToAction>) => {
            return dispatch({
            type: EReduxActionTypes.SAVE_CHANGE_TO,
            data: changeTo
        });
    };
}
export function getProductList(type: any): ThunkAction<Promise<IReduxGetProductListAction>, IReduxFinanceState, undefined, IReduxGetProductListAction> {
    return async (dispatch: ThunkDispatch<IReduxFinanceState, undefined, IReduxGetProductListAction>) => {
        let response: any = await axiosInstance.get(process.env.REACT_APP_BASE_URL19 + "product/code/" + type).then(response => { return response.data; }).catch(err => { return console.error(err) });


        return dispatch({
            type: EReduxActionTypes.GET_PRODUCT_LIST,
            data: response
        });
    };
}

export function getListPayments(filter: any, action: any): ThunkAction<Promise<IReduxGetListPaymentsAction>, IReduxFinanceState, undefined, IReduxGetListPaymentsAction> {
    return async (dispatch: ThunkDispatch<IReduxFinanceState, undefined, IReduxGetListPaymentsAction>) => {
        if (action == "first") {
            filter.pageNumber = 0;
            dispatch({ type: EReduxActionTypes.LOADING_SUBSCIPTION, data: undefined, filter: filter, scrollOrNot: undefined })
        }
        else if (action == "no") {
            dispatch({ type: EReduxActionTypes.LOADING_SUBSCIPTION_SCROLL, data: undefined, filter: filter, scrollOrNot: undefined })
        }

        let response: any = await axiosInstance.post(process.env.REACT_APP_BASE_URL14 + "offer/getPaymentListWithFilter/", filter).then(response => { return response.data.paymentDtoList; }).catch(err => { return [] });

        return dispatch({
            type: EReduxActionTypes.GET_LIST_PAYMENTS,
            data: response,
            filter: filter,
            scrollOrNot: action
        });
    };
}

export function getSizeSub(filter: any): ThunkAction<Promise<IReduxGetSizeSubAction>, IReduxFinanceState, undefined, IReduxGetSizeSubAction> {
    return async (dispatch: ThunkDispatch<IReduxFinanceState, undefined, IReduxGetSizeSubAction>) => {
       
        let response: any = await axiosInstance.post(process.env.REACT_APP_BASE_URL14 + "offer/getSize", filter).then(response => { return response.data; }).catch(err => { return 0 });

        return dispatch({
            type: EReduxActionTypes.GET_SIZE_SUB,
            data: response,
            filter: filter,
       
        });
    };
}

export function getSchoolInformation(idEntity: any): ThunkAction<Promise<IReduxVerifyApiAction>, IReduxFinanceState, undefined, IReduxVerifyApiAction> {
    return async (dispatch: ThunkDispatch<IReduxFinanceState, undefined, IReduxVerifyApiAction>) => {
        let verify: any = await axios.get(`${process.env.REACT_APP_BASE_URL14}offer/getSchoolInformation/${idEntity}`).then(response => { return response.data; }).catch(err => { return console.error(err) });


        return dispatch({
            type: EReduxActionTypes.VERIFY_API,
            data: verify
        });
    };
}

export function getOffersTrainings(idEntity: any): ThunkAction<Promise<IReduxGetOfferTrainingsAction>, IReduxFinanceState, undefined, IReduxGetOfferTrainingsAction> {
    return async (dispatch: ThunkDispatch<IReduxFinanceState, undefined, IReduxGetOfferTrainingsAction>) => {
        let offers: any = await axios.get(`${process.env.REACT_APP_BASE_URL11}offers/getOffersTrainings/${idEntity}`).then(response => { return response.data; }).catch(err => { return [] });


        return dispatch({
            type: EReduxActionTypes.GET_OFFER_TRAININGS,
            data: offers
        });
    };
}
export function getLimitations(idEntity: any, offerId: any): ThunkAction<Promise<IReduxGetLimitationsAction>, IReduxFinanceState, undefined, IReduxGetLimitationsAction> {
    return async (dispatch: ThunkDispatch<IReduxFinanceState, undefined, IReduxGetLimitationsAction>) => {
        let limitations: any = await axios.get(process.env.REACT_APP_BASE_URL14 + "offer/getLimitations/" + idEntity + "/" + offerId).then(response => { return response.data; }).catch(err => { return console.error(err) });


        return dispatch({
            type: EReduxActionTypes.GET_LIMITATIONS,
            data: limitations
        });
    };
}
export function getCounts(idEntity: any): ThunkAction<Promise<IReduxGetCountsAction>, IReduxFinanceState, undefined, IReduxGetCountsAction> {
    return async (dispatch: ThunkDispatch<IReduxFinanceState, undefined, IReduxGetCountsAction>) => {
        let counts: any = await axios.get(process.env.REACT_APP_BASE_URL14 + "offer/getActiveProfiles/" + idEntity).then(response => {
            return response.data;
        }).catch(err => { return console.error(err) });


        return dispatch({
            type: EReduxActionTypes.GET_COUNTS,
            data: counts
        });
    };
}
export function getPaymentSetting(idUser: any, type: string): ThunkAction<Promise<IReduxGetPaymentSettingAction>, IReduxFinanceState, undefined, IReduxGetPaymentSettingAction> {
    return async (dispatch: ThunkDispatch<IReduxFinanceState, undefined, IReduxGetPaymentSettingAction>) => {



        let paymentSetting: any = await axios.get(`${process.env.REACT_APP_BASE_URL13}paymentsetting/getPaymentSetting/` + idUser + `/` + type).then(async (res: any) => {
            return res.data
        })
        return dispatch({
            type: EReduxActionTypes.GET_PAYMENT_SETTING,
            data: paymentSetting
        });
    };
}
export function getActiveStudents(schoolId: any): ThunkAction<Promise<IReduxGetActiveStudentsAction>, IReduxFinanceState, undefined, IReduxGetActiveStudentsAction> {
    return async (dispatch: ThunkDispatch<IReduxFinanceState, undefined, IReduxGetActiveStudentsAction>) => {
        let studentRequest: any = await axios.get(`${process.env.REACT_APP_BASE_URL14}offer/getActiveStudent/${schoolId}`).then(response => { return response.data; }).catch(err => { return console.error(err) });
        return dispatch({
            type: EReduxActionTypes.GET_ACTIVE_STUDENTS,
            data: studentRequest
        });
    };
}
export function getCourseStatus(status: any): ThunkAction<Promise<IReduxGetCourseStatusAction>, IReduxFinanceState, undefined, IReduxGetCourseStatusAction> {
    return async (dispatch: ThunkDispatch<IReduxFinanceState, undefined, IReduxGetCourseStatusAction>) => {
        return dispatch({
            type: EReduxActionTypes.GET_COURSE_STATUS,
            data: status
        });
    };
}
export function getProfStatus(status: any): ThunkAction<Promise<IReduxGetProfStatusAction>, IReduxFinanceState, undefined, IReduxGetProfStatusAction> {
    return async (dispatch: ThunkDispatch<IReduxFinanceState, undefined, IReduxGetProfStatusAction>) => {
        return dispatch({
            type: EReduxActionTypes.GET_PROF_STATUS,
            data: status
        });
    };
}
export function getStudentStatus(status: any): ThunkAction<Promise<IReduxGetStudentStatusAction>, IReduxFinanceState, undefined, IReduxGetStudentStatusAction> {
    return async (dispatch: ThunkDispatch<IReduxFinanceState, undefined, IReduxGetStudentStatusAction>) => {
        return dispatch({
            type: EReduxActionTypes.GET_STUDENT_STATUS,
            data: status
        });
    };
}

export function clearPaymentStatus(): ThunkAction<Promise<IReduxClearPaymentStatusAction>, IReduxFinanceState, undefined, IReduxClearPaymentStatusAction> {
    return async (dispatch: ThunkDispatch<IReduxFinanceState, undefined, IReduxClearPaymentStatusAction>) => {

        return dispatch({
            type: EReduxActionTypes.CLEAR_PAYMENT_STATUS,
            data: undefined
        });
    };
}

export function checkPayment(tokentockeck: any): ThunkAction<Promise<IReduxCheckPaymeePayementAction>, IReduxFinanceState, undefined, IReduxCheckPaymeePayementAction> {


    return async (dispatch: ThunkDispatch<IReduxFinanceState, undefined, IReduxCheckPaymeePayementAction>) => {
        let paymentStatus: any = await axios.get(process.env.REACT_APP_BASE_URL16 + "paymee/checkPayment/" + tokentockeck?.token).then(response => { return response.data; }).catch(err => { return console.error(err) });

        return dispatch({
            type: EReduxActionTypes.CHECK_PAYMENT,
            data: paymentStatus
        });
    };
}
export function getOffers(): ThunkAction<Promise<IReduxGetOffersAction>, IReduxFinanceState, undefined, IReduxGetOffersAction> {


    return async (dispatch: ThunkDispatch<IReduxFinanceState, undefined, IReduxGetOffersAction>) => {
        let offers: any = await axios.get(process.env.REACT_APP_BASE_URL11 + "offers/getOffers/").then(response => { return response.data; }).catch(err => { return console.error(err) });

        return dispatch({
            type: EReduxActionTypes.GET_OFFERS,
            data: offers
        });
    };
}
export function getOffer(id: any): ThunkAction<Promise<IReduxGetOfferAction>, IReduxFinanceState, undefined, IReduxGetOfferAction> {


    return async (dispatch: ThunkDispatch<IReduxFinanceState, undefined, IReduxGetOfferAction>) => {

        let baseURL = process.env.REACT_APP_BASE_URL11;
        let offer: any = await axios.get(baseURL + "offers/getOffer/" + id).then(response => { return response.data; }).catch(err => { return console.error(err) });

        return dispatch({
            type: EReduxActionTypes.GET_OFFER,
            data: offer
        });
    };
}
export function getContract(idEntity: any, entityType: string): ThunkAction<Promise<IReduxGetContractAction>, IReduxFinanceState, undefined, IReduxGetContractAction> {


    return async (dispatch: ThunkDispatch<IReduxFinanceState, undefined, IReduxGetContractAction>) => {

        let baseURL = process.env.REACT_APP_BASE_URL17;
        let contract: any = await axios.post(baseURL + "contracts/getContractsWithFilter", {
            entityId: idEntity,
            entityType: entityType,
            paymentEngine: null,
            paymentMethod: null
        }).then(response => {

            return response.data;
        }).catch(err => { return console.error(err) });

        return dispatch({
            type: EReduxActionTypes.GET_CONTRACT,
            data: contract
        });
    };
}
export function getLocalisation(): ThunkAction<Promise<IReduxGetLocalisationAction>, IReduxFinanceState, undefined, IReduxGetLocalisationAction> {


    return async (dispatch: ThunkDispatch<IReduxFinanceState, undefined, IReduxGetLocalisationAction>) => {
        const localisation = await fetch("https://ipgeolocation.abstractapi.com/v1/?api_key=b6078e4a8db0480488ea97bd57ee755f").then(response => response?.json())
            .then(async (data: any) => {
                return data.country_code
            })
        return dispatch({
            type: EReduxActionTypes.GET_LOCALISATION,
            data: localisation
        });
    };
}
export function deleteContract(id: any, status: any, schoolId: any, object: any, paymentEngine: any): ThunkAction<Promise<IReduxGetContractAction>, IReduxFinanceState, undefined, IReduxGetContractAction> {


    return async (dispatch: ThunkDispatch<IReduxFinanceState, undefined, IReduxGetContractAction>) => {
        let baseURL = process.env.REACT_APP_BASE_URL17;
        const baseUrl1 = process.env.REACT_APP_BASE_URL2;
        let contract: any;
        if (paymentEngine == "STRIPE") {
            contract = axios.get(`${process.env.REACT_APP_BASE_URL13}paymentsetting/getPaymentSetting/` + schoolId + `/CONTRACT`).then(async (res: any) => {
                axios.get(`${process.env.REACT_APP_BASE_URL18}strip/delete-subscription/` + res.data.engineId).then(async (response: any) => {
                    await axios.post(baseURL + "contracts/createorupdatecontract", {
                        contractIdentifier: {
                            contractReference: id
                        },

                        status: [{
                            statusType: status
                        }]

                    }).then(response => { return response.data; })
                    // .then((resp: any) =>
                    //     //axios.post(baseUrl1 + "person/updatelegalperson", object)
                    //     )
                })

            })


        } else {
            contract = axios.get(`${process.env.REACT_APP_BASE_URL13}paymentsetting/getPaymentSetting/` + schoolId + `/CONTRACT`).then(async (res: any) => {
                await axios.post(baseURL + "contracts/createorupdatecontract", {
                    contractIdentifier: {
                        contractReference: id
                    },

                    status: [{
                        statusType: status
                    }]

                }).then(response => { return response.data; })
            })


        }


        return dispatch({
            type: EReduxActionTypes.GET_CONTRACT,
            data: contract
        });
    };
}
export function getCoursesFinance(filter: any): ThunkAction<Promise<IReduxGetFreeCoursesAction>, IReduxFinanceState, undefined, IReduxGetFreeCoursesAction> {
    return async (dispatch: ThunkDispatch<IReduxFinanceState, undefined, IReduxGetFreeCoursesAction>) => {
        const courses = await axios.post(`${process.env.REACT_APP_BASE_URL}courses`, filter).then(res => { return res.data; }).catch(err => [])
        const profs = await axios.get(`${process.env.REACT_APP_BASE_URL2}person/all/${filter.idSchool}/profs`)
        globalAxios.all([profs, courses]).then(globalAxios.spread((...responses) => {
            return (responses[1].data.map((course: any) => {
                return {
                    professor: {
                        name: responses[0].data.find((prof: any) => prof.id === course.idProf)?.name
                    },
                    rating: {
                        rating: responses[2].data.find((rate: any) => Number(rate.idSubject) === Number(course.id))?.rating


                    },
                    ...course
                }
            }
            ))
        })).catch(errors => {
            console.error(errors)
        })
        return dispatch({
            type: EReduxActionTypes.ALL_COURSES,
            data: undefined
        })
    }
}

export function updateCourseFinance(): ThunkAction<Promise<IReduxUpdateCourseFinanceAction>, IReduxFinanceState, undefined, IReduxUpdateCourseFinanceAction> {
    return async (dispatch: ThunkDispatch<IReduxFinanceState, undefined, IReduxUpdateCourseFinanceAction>) => {
        return dispatch({
            type: EReduxActionTypes.UPDATE_FINANCE_COURSES,
            data: undefined
        })
    }
}


export function getAllOfferPayedFinance(filter: any, idSchool: any, action: any): ThunkAction<Promise<IReduxAllOfferPayedFinanceAction>, IReduxFinanceState, undefined, IReduxAllOfferPayedFinanceAction> {
    return async (dispatch: ThunkDispatch<IReduxFinanceState, undefined, IReduxAllOfferPayedFinanceAction>) => {

        if (action == "first") {
            filter.pageNumber = 0;
            dispatch({ type: EReduxActionTypes.LOADING_OFFER, data: undefined, scrollOrNot: undefined })
        }
        else if (action == "no") {
            dispatch({ type: EReduxActionTypes.LOADING_OFFER_SCROLL, data: undefined, scrollOrNot: undefined })
        }

        let baseURL = process.env.REACT_APP_BASE_URL11;
        const res = await axiosInstance.post(baseURL + "offers/get_details_offer_by_school/" + idSchool, filter).then((response) => {
            return response.data.financeDtos
        })

        return dispatch({
            type: EReduxActionTypes.ALL_OFFER_PAYED,
            data: res,
            scrollOrNot: action
        })
    }
}

export function getSizePayedFinance(filter: any, idSchool: any): ThunkAction<Promise<IReduxGetSizeAction>, IReduxFinanceState, undefined, IReduxGetSizeAction> {
    return async (dispatch: ThunkDispatch<IReduxFinanceState, undefined, IReduxGetSizeAction>) => {
        let baseURL = process.env.REACT_APP_BASE_URL11;
        const res = await axiosInstance.post(baseURL + "offers/get_size_offer_by_school/" + idSchool, filter).then((response) => {
            return response.data
        })
        return dispatch({
            type: EReduxActionTypes.GET_SIZE,
            data: res
        })
    }
}
export function migrateOffers(entityId: any): ThunkAction<Promise<IReduxMigrateOffersAction>, IReduxFinanceState, undefined, IReduxMigrateOffersAction> {
    return async (dispatch: ThunkDispatch<IReduxFinanceState, undefined, IReduxMigrateOffersAction>) => {
        let baseURL = process.env.REACT_APP_BASE_URL13;
         await axiosInstance.post(baseURL + "paymentsetting/getMigrationsStripeByEntityId/" + entityId).then((response) => {
            return response.data
        })
        return dispatch({
            type: EReduxActionTypes.MIGRATE_OFFERS,
        
        })
    }
}
export function getSizePaimentFinance(filter: any, idSchool: any): ThunkAction<Promise<IReduxGetSizePaymentAction>, IReduxFinanceState, undefined, IReduxGetSizePaymentAction> {
    return async (dispatch: ThunkDispatch<IReduxFinanceState, undefined, IReduxGetSizePaymentAction>) => {
        let baseURL = process.env.REACT_APP_BASE_URL13;
        const res = await axiosInstance.post(baseURL + "payment/getPaymentSizeBySchool/" + idSchool, filter).then((response) => {
            return response.data
        })
        return dispatch({
            type: EReduxActionTypes.GET_SIZE_PAYMENT,
            data: res
        })
    }
}

export function getPaymentFilter(filter: any, idSchool: any): ThunkAction<Promise<IReduxGetPaymentFiltereAction>, IReduxFinanceState, undefined, IReduxGetPaymentFiltereAction> {
    return async (dispatch: ThunkDispatch<IReduxFinanceState, undefined, IReduxGetPaymentFiltereAction>) => {
        let baseURL = process.env.REACT_APP_BASE_URL13;
        const res = await axiosInstance.post(baseURL + "payment/getPaymentFilterBySchool/" + idSchool, filter).then((response) => {
            return response.data
        })
        return dispatch({
            type: EReduxActionTypes.GET_PAYMENT_FILTER,
            data: res
        })
    }
}


export function getAllProfessorsByIdSchool(idSchool: number): ThunkAction<Promise<IReduxAllProfessorsByIdSchoolAction>, IReduxFinanceState, undefined, IReduxAllProfessorsByIdSchoolAction> {
    return async (dispatch: ThunkDispatch<IReduxFinanceState, undefined, IReduxAllProfessorsByIdSchoolAction>) => {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL2}person/all/${idSchool}/profs`).then(response => {
            return response?.data?.map((el: any) => {
                return { label: el.name, value: el.id };
            });
        });
        return dispatch({
            type: EReduxActionTypes.ALL_PROFESSORS_BY_SCHOOL,
            data: res
        })
    }
}

export function getListYearAndMonthsToDay(): ThunkAction<Promise<IReduxGetYearsMonthAction>, IReduxFinanceState, undefined, IReduxGetYearsMonthAction> {
    return async (dispatch: ThunkDispatch<IReduxFinanceState, undefined, IReduxGetYearsMonthAction>) => {
        const moment = require('moment');
        moment.locale('fr')
        let i = 1

        const res = moment.months()?.map((month: any) => {
            return { label: month, value: i++ };
        })
        for (let id = 1; id <= 4; id++) {
            res.push({ label: new Date().getFullYear() - id, value: new Date().getFullYear() - id })
        }

        return dispatch({
            type: EReduxActionTypes.ALL_YEARS_MONTH,
            data: res
        })
    }
}

export function getCummulSalesByOfferFinance(filter: any, idOffer: any): ThunkAction<Promise<IReduxCumulSalesByOfferFinanceAction>, IReduxFinanceState, undefined, IReduxCumulSalesByOfferFinanceAction> {
    return async (dispatch: ThunkDispatch<IReduxFinanceState, undefined, IReduxCumulSalesByOfferFinanceAction>) => {


        let baseURL = process.env.REACT_APP_BASE_URL12;
        const res = await axios.post(baseURL + "static/get_details_cumul_sales_by_offer/" + idOffer, filter).then((response) => {
            return response.data
        })

        return dispatch({
            type: EReduxActionTypes.CUMUL_SALES_OFFER,
            data: res
        })
    }
}


export function getAllPayedCourseFinance(filter: any, idSchool: any, action: any): ThunkAction<Promise<IReduxAllPayedCourseFinanceAction>, IReduxFinanceState, undefined, IReduxAllPayedCourseFinanceAction> {
    return async (dispatch: ThunkDispatch<IReduxFinanceState, undefined, IReduxAllPayedCourseFinanceAction>) => {

        if (action == "first") {
            filter.pageNumber = 0;
            dispatch({ type: EReduxActionTypes.LOADING_PAYMENT, data: undefined, scrollOrNot: undefined })
        }
        else if (action == "no") {
            dispatch({ type: EReduxActionTypes.LOADING_PAYMENT_SCROLL, data: undefined, scrollOrNot: undefined })
        }
        let baseURL = process.env.REACT_APP_BASE_URL13;
        const res = await axios.post(baseURL + "payment/getPaymentListBySchool/" + idSchool, filter).then((response) => {
            return response.data.paymentDtoList
        })

        return dispatch({
            type: EReduxActionTypes.ALL_PAYED_COURSE,
            data: res,
            scrollOrNot: action
        })
    }
}


export function updatePrice(offer: any): ThunkAction<Promise<IReduxUpdatePriceAction>, IReduxFinanceState, undefined, IReduxUpdatePriceAction> {
    return async (dispatch: ThunkDispatch<IReduxFinanceState, undefined, IReduxUpdatePriceAction>) => {

        let baseURL_offer = process.env.REACT_APP_BASE_URL11;

        let finance = await axios.post(baseURL_offer + "offers/createOrUpdateOffer", offer).then(response => {
            return response.data;
        })
        return dispatch({
            type: EReduxActionTypes.UPDATE_PRICE,
            data: finance
        })
    }
}
