import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  data: any
  doAction?: any
  height?: number | string
}
export default function TableElement(props: Props) {
  const [data, setData] = useState<any>();
  const { t } = useTranslation();
  useEffect(() => {
    setData(props?.data)
  }, [props?.data]);

  return (
    <>
      <div className="my-3" >
        <div className="table-responsive d-flex mx-auto" style={{ maxWidth: 1200 }}>
          <table className="w-100 custom-table">
            <thead className="mb-4">
              <tr>
                {data?.headers?.map((header: any) =>
                (<th key={header.title} scope={header.scope} className={header.classNames}>
                  <button className={header.buttonClasses}>
                    {t(header.title)}
                    {/* {header.sorted && <div className="d-flex flex-column">
                      <span className="material-icons-outlined trie-arrow up" >arrow_drop_up</span>
                      <span className="material-icons-outlined trie-arrow down" >arrow_drop_down</span>
                    </div>} */}
                  </button>
                </th>)
                )}
              </tr>
            </thead>
            <tbody className="w-100" style={{ height: 30, overflow: 'scroll' }}>
              {data?.body?.map((data: any, index: number) =>
              (
                <tr key={index + 'row'} style={{ cursor: (data.row.actionName === "") ? 'default' : 'pointer' }} className={data.row.classNames}>
                  {data.row.columns.map((column: any, index: number) =>
                  (
                    <td key={index + 'column'} className={column.classNames} colSpan={column.colspan}>{column.content}</td>
                  )
                  )}
                </tr>
              )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
