/* eslint react/no-did-mount-set-state: 0 */
import { ComponentProps, FC, useEffect , useState} from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import HeaderPathProgress from '../../../components/headers-page/header-path-progress';
import PathProgresscontentComponent from '../../../components/path-components/path-progress-content-component';
import {  getCurrentTraining } from '../../../global-state/actions/student-training-actions';
import { useParams } from 'react-router-dom';
import LoadingHeaderPathSessionComponent from '../../../components/headers-page/loading-header--path-session-component';
import LoadingPathSessionContentComponent from '../../../components/path-components/path-course-components/loading-path-session-content-component';

interface Params {
    idPath: string
}
const PathProgressPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { idPath } = useParams<Params>();
    const [loadingPage, setLoadingPage] = useState<boolean>(true)
    const [training, setTraining] = useState()
    


    useEffect(() => {

        if (props?.currentTraining !== training ) {
            setTraining(props?.currentTraining)
        }
        setLoadingPage(false)
    }, [props?.currentTraining])

    useEffect(() => {
        props?.getCurrentTraining(Number(idPath))
        setLoadingPage(true)
    }, [])
    

    

    return (
        <div  style={{ maxWidth: "1200px", margin: "auto", marginTop: "64px" }}> 
        {loadingPage ?  
        <div>
            <LoadingHeaderPathSessionComponent />
            <LoadingPathSessionContentComponent />
        </div>

        :<div>
            <HeaderPathProgress />
            <PathProgresscontentComponent />
        </div>}

        </div>
    );
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
         getCurrentTraining,
        },
        dispatch
    );
const mapStateToProps = (state: AppState) => ({
    connectedUserDetails: state.authReducer.connectedUser,
    currentTraining: state.studentTraining.currentTraining
});

export default connect(mapStateToProps, mapDispatchToProps)(PathProgressPage);