import { ComponentProps, FC, useEffect, useState } from "react"
import { connect } from "react-redux";
import { AppState } from "../../global-state/store/root-reducers";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import "../../translations/i18n";
import CardActivityDirector from "./card-director/card-activity-director";
import CardActivityProfLoading from "./card-prof/card-activity-prof-loading";
import CardInitialActivity from "./initial-card/card-initial-activity";
import { getSubscriptions } from "../../global-state/actions/app-container-actions";
import { getTeamNumber } from "../../global-state/actions/professor-actions";
import { getActivityApi, getActivityDirectorApi, getGenderStatsApi, getListCoursesApi, getNewCoursesApi, getSubscriptionApi } from "../../services/activity.service";

const DirectorActivitiesCardComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const [loading, setLoading] = useState(true);
    const [courseList, setCourseList] = useState<any>([]);

    const [activities, setActitivities] = useState<any>();
    const [newCourses, setNewCourses] = useState<any>();
    const [subscription, setSubscription] = useState<any>();
    const [activityDirector, setActivityDirector] = useState<any>();
    const [genderStat, setGenderStat] = useState<any>();
    const [courses, setCourses] = useState<any>();


    const getActivity = async (list: any) => {

        const result = await getActivityApi(list)
        setActitivities(result)
    }

    const getActivitySubscription = async (list: any) => {

        const result = await getSubscriptionApi(list)
        setSubscription(result)
    }

    const getNewCourses = async (list: any) => {
        const result = await getNewCoursesApi(list)
        setNewCourses(result)
    }

    const getActivityDirector = async (list: any) => {
        const result = await getActivityDirectorApi(list)
        setActivityDirector(result)
    }

    const getGenderStats = async (schoolId: any) => {
        const result = await getGenderStatsApi(schoolId)
        setGenderStat(result)
    }

    const getListCourses = async (filter: any) => {
        const result = await getListCoursesApi(filter)
        setCourses(result)
    }

    useEffect(() => {
        let filter = {
            entityId: props?.user?.idSchool,
            entityType: "SCHOOL",
            pageNumber: null,
            pageSize: null

        }
        if (courses?.length === 0 || courses === undefined) {
            getListCourses(filter)
        }
        props?.getSubscriptions(props?.schoolInfo?.id)
        props?.getTeamNumber(props?.user?.idSchool);
    }, [])

    useEffect(() => {
        if (Array.isArray(courses) && courses?.length > 0) {
            let ids = courses?.filter((a: any) => a?.entityId === props?.user?.idSchool).filter((b: any) => b?.status === "PUBLISHED").map((e: any) => e.id);
            setCourseList(ids)
        }

    }, [props?.user, courses]);

    useEffect(() => {
        setLoading(true)
        if (Array.isArray(courseList) && courseList?.length >= 0 && props?.user?.idSchool != undefined) {
            getActivity(courseList)
            getActivitySubscription(props?.user?.idSchool)
            getActivityDirector(props?.user?.idSchool)
            getNewCourses(courseList)
            props?.getTeamNumber(props?.user?.idSchool)
            getGenderStats(props?.user?.idSchool)

            setLoading(false)
        }


    }, [courseList, props?.user])

    return (
        <>
            <div className="container-fluid p-0">
                <div className="d-flex flex-column justify-content-center">


                    <div className="my-4">
                        {!(activities && newCourses && subscription && activityDirector && genderStat && courses) ? <div className="d-flex w-100 flex-wrap align-items-center justify-content-between ">
                            <CardActivityProfLoading />
                        </div> : <>
                            {(courses?.length === 0) ?
                                <CardInitialActivity activities={activities} subscription={subscription} newCourses={newCourses} courseList={courseList} activityDirector={activityDirector} />
                                : <CardActivityDirector activities={activities} subscription={subscription} newCourses={newCourses} courseList={courseList} activityDirector={activityDirector} sizeProfs={props?.sizeProfs} genderStat={genderStat} />

                            }
                        </>}

                    </div>
                </div>
            </div>

        </>

    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    myCourses: state.courseReducer.myCourses,
    connectedUser: state.authReducer.connectedUser,
    history: ownProps?.history,
    user: state.authReducer.connectedUser,
    schoolInfo: state.schoolInfoReducer.schoolInfo,

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            getSubscriptions,
            getTeamNumber,
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(DirectorActivitiesCardComponent);