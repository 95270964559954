import moment from 'moment';
import { ComponentProps, FC, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import CustomizedSelectsMultiple from './customized-select-multiple';
import DateAndTimeComponent from './date-time-bloc.component';
import WhiteCardComponent from './white-card.component';
import NavbarCouponComponent from './navbar-coupon-component';
import { generatedCode } from '../../helpers/commons';
import { debounce } from 'lodash';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../helpers/interceptors';
import { createCoupon,getCouponsByProductAndPromoCode} from '../../services/coupon.service';
import Skeleton from '@material-ui/lab/Skeleton';
import { useLocation } from 'react-router';
interface LocationState {
  couponData: any;
}
const CreateCouponsComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
  const location = useLocation<LocationState>();
  const couponData = location.state?.couponData;
  const [couponBody, setCouponBody] = useState<any>(couponData ??
    ({
      product: "KOORS",
      promotion: {
        promoCode: null,
        promoType: "DISCOUNT",
        promoValue: "",
      },
      referenceCode: null,
      startAt: null,
      expireAt: null,
      status: "DRAFT",
      offers: [],
      entityId: props?.user?.idSchool,
      entityType: "SCHOOL",
    })
  );

  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [checked, setChecked] = useState(false);
  const [offerOptions, setOfferOptions] = useState<any[]>([]);
  const [offersList, setOffersList] = useState<any[]>([]);
  const [valide, setValide] = useState(true);
  const [msg, setMsg] = useState("")
  const [allowed, setAllowed] = useState(true);
  const [msgAllowed, setMsgAllowed] = useState("");
  const { i18n } = useTranslation();
  moment.locale(i18n.language)
  const [loadTrainnigs, setLoadTrainnigs] = useState<boolean>(true)


  const getOffersList = async () => {
    await axiosInstance.get(`${process.env.REACT_APP_BASE_URL11}offers/getOffersPublishedTrainings/${props?.user.idSchool}`)
      .then(response => {
        setOfferOptions(response.data)
        setLoadTrainnigs(false)
      })
      .catch(err => { return [] });
  }
  useEffect(() => {
    if (!!couponData) {
      setCouponBody(couponData)
      if (!!couponData?.expireAt) {
        setChecked(true)
      }
    }
  }, [couponData])

  useEffect(() => {
    if (props.couponStatus === false) {
      setCouponBody((prevState: any) => ({ ...prevState, status: 'INACTIVE' }));
    } else if (props.couponStatus === true) {
      setCouponBody((prevState: any) => ({ ...prevState, status: 'ACTIVE' }));
    }
  }, [props?.couponStatus]);

  useEffect(() => {
    getOffersList();
    if (!!couponData?.offers && couponData?.offers?.length > 0 && couponData?.offers?.length != offersList?.length) {

      setOffersList(couponData?.offers)
    }
  }, []);

  useEffect(() => {
    if (!!couponBody?.startAt) {
      debouncedChangeHandler(couponBody)
    }
  }, [couponBody?.startAt])
  useEffect(() => {
    if (!!couponBody?.expireAt) {

      debouncedChangeHandler(couponBody)
    }
  }, [couponBody?.expireAt])

  useEffect(() => {

    setCouponBody((prevState: any) => ({ ...prevState, offers: offersList, entityType: "SCHOOL" }));

    if (couponBody?.offers?.length != offersList?.length) {
      const copForm: any = { ...couponBody };
      copForm['offers'] = offersList
      debouncedChangeHandler(copForm)
    }



  }, [offersList]);

  const createCouponApi = async (couponBodyObject: any) => {
    if (!!couponBodyObject?.promotion?.promoCode) {
     createCoupon(couponBodyObject).then(async(responseCoupon:any) => { setCouponBody(responseCoupon) })
      setSnackBarOpen(true);
    }


  }

  const createOrUpdateCouponCode = async (couponBodyObject: any) => {
  
    await getCouponsByProductAndPromoCode("KOORS",couponBodyObject?.promotion?.promoCode).then(async (response: any) => {

      if (response?.id == null) {
        setValide(true)
        await createCouponApi(couponBodyObject);

      } else {
        setValide(false)
        setMsg("Ce code existe déjà. Veuillez saisir ou générer un autre code. ")

      }

    })
  }
  const debounceVerifyPromoCode = useCallback(debounce(createOrUpdateCouponCode, 1000), []);
  const debouncedChangeHandler = useCallback(debounce(createCouponApi, 1000), []);
  const handleCoupon = async (e: any) => {
    if (e !== "") {
      const copForm: any = { ...couponBody };
      const copPromotion = { ...couponBody.promotion }
      copPromotion['promoCode'] = e;
      copForm['promotion'] = copPromotion;
      setCouponBody(copForm);
      await debounceVerifyPromoCode(copForm);
    }
  }
  const handleClose = () => {
    setSnackBarOpen(false)
  }
  useEffect(() => {
    if (/^\d+$/.test(couponBody?.promotion?.promoValue) || couponBody?.promotion?.promoValue == "" || couponBody?.promotion?.promoValue == null) {
      setAllowed(true)
    } else {
      setAllowed(false);
      setMsgAllowed("Veuillez saisir un nombre composé de chiffres")
    }
  }, [couponBody])
  const handleKeyPress = (event: any) => {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    }
  };
  return (
    <>
      <NavbarCouponComponent valide={valide} createdCoupon={couponBody} liveCoupon = {couponBody} />
      <div className="container-fluid">
        <div className="justify-content-center">
          <div className="row  px-2" style={{ backgroundColor: "#FFFFFF" }}>
            <div className=" d-flex align-items-center justify-content-center  mx-auto" style={{ width: 1200 }}>
              <div className="w-100 d-flex flew-row justify-content-between ">
                <div className="d-flex flex-column py-4 mt-2" >
                  <span className="H3-Headline neutral-3 mb-2 pb-1">Détails du coupon</span>
                  <span className="H3-Headline">{!!couponBody?.promotion?.promoCode ? couponBody?.promotion?.promoCode : "Nouveau Coupon"}</span>
                </div>
              </div>
            </div>

          </div>

          <div
            className="pt-56 d-flex justify-content-between mx-auto flex-wrap"
            style={{ backgroundColor: "#FBFBFD", maxWidth: 1200 }}
          >
            <div className="w-100 mb-5 pb-2 mt-4" style={{ maxWidth: 792 }}>
              <div className="d-flex flex-column col-8 w-auto ">
                <div className="p-4 mb-4 bg-with-border white">
                  <div className=" d-flex flex-column  flex-wrap">
                    <span className="H3-Headline mb-4 pb-3">Code et valeur du coupon</span>
                    <div className=" d-flex flex-column  flex-wrap mr-3 mb-4 pb-2 ">
                      <span className="form-title">Code du coupon</span>
                      <div className="d-flex flex-row">
                        <input
                          type="text"
                          className={`form-control input text-default ${valide ? '' : 'error'}`}
                          value={couponBody.promotion?.promoCode}
                          onChange={(e) => handleCoupon(e.target.value)}
                          id="promo-code"
                        />
                        <button
                          type="button"
                          className="btn-Secondary large ms-2"
                          onClick={() => handleCoupon(generatedCode())} >
                          Générer
                        </button>
                      </div>

                      {!valide && <div className="mt-1">
                        <label className="error-input mt-1">{msg}</label>


                      </div>

                      }
                    </div>
                  </div>
                  <div className=" d-flex flex-column  flex-wrap mr-3">
                    <label className="form-title">Valeur de réduction</label>
                    <div className="d-flex flex-row flex-wrap mb-3">
                      <div className='me-2'>
                        <input
                          className="input-style-radio-button-medium"
                          type="radio"
                          name="exampleRadios3"
                          id="outlinedRadiosbuttonmedium1"
                          value="option1"
                          checked={couponBody.promotion?.promoType === "DISCOUNT"}
                          onChange={async () => {

                            const copForm: any = { ...couponBody };
                            const copPromotion = { ...couponBody.promotion }
                            copPromotion['promoType'] = "DISCOUNT";
                            copForm['promotion'] = copPromotion
                            await setCouponBody(copForm);
                            await debouncedChangeHandler(copForm);

                          }

                          }
                        />
                        <label htmlFor="outlinedRadiosbuttonmedium1">Pourcentage</label>
                      </div>
                      <div className='me-2'>
                        <input
                          className="input-style-radio-button-medium"
                          type="radio"
                          name="exampleRadios3"
                          id="outlinedRadiosbuttonmedium2"
                          value="option2"
                          checked={couponBody.promotion?.promoType === "CASHBACK"}
                          onClick={async () => {
                            const copForm: any = { ...couponBody };
                            const copPromotion = { ...couponBody.promotion }
                            copPromotion['promoType'] = "CASHBACK";
                            copForm['promotion'] = copPromotion
                            await setCouponBody(copForm);
                            await debouncedChangeHandler(copForm);
                          }
                          }
                        />
                        <label htmlFor="outlinedRadiosbuttonmedium2" className="btn-outlined">
                          Montant fixe
                        </label>
                      </div>
                      <div style={{ width: "100%", maxWidth: 410 }}>
                        <div className="input-form-control">
                          <input
                            type="text"
                            style={{
                              MozAppearance: 'textfield',
                              appearance: 'textfield'
                            }}
                            className="form-control input text-default"
                            value={couponBody.promotion?.promoValue}
                            onKeyPress={handleKeyPress}
                            onChange={async (e) => {
                              const copForm: any = { ...couponBody };
                              const copPromotion = { ...couponBody.promotion }
                              copPromotion['promoValue'] = e.target.value;
                              copForm['promotion'] = copPromotion
                              await setCouponBody(copForm);
                              await debouncedChangeHandler(copForm);





                            }

                            }
                            id="promo-value"
                          />
                          {couponBody.promotion?.promoType === "DISCOUNT" && (
                            <span style={{ alignItems: "center" }}>%</span>
                          )}
                        </div>

                      </div>
                    </div>
                    <hr style={{ color: "#cacbce" }} />
                  </div>
                  <div className=" d-flex flex-column  flex-wrap mr-3 mt-3">
                    <label  htmlFor="custom-select">
                      <span className="form-title">S'applique à</span> <span className="font-weight-normal">(Uniquement pour les formations à paiement unique)</span>
                    </label>
                    <div className="d-flex flex-row">
                      <div className="col-12">
                        {/* {loadTrainnigs === false ? */}
                          <CustomizedSelectsMultiple
                            taille="lg"
                            optionsProps={offerOptions}
                            status="coming"
                            offerList={offersList}
                            setOfferList={setOffersList}

                          />
                          {/*:

                         <Skeleton className="skeleton square me-3" animation="wave" variant="rect" width={"742px"} height={48} />
                        }*/}
                      </div>
                    </div>
                  </div>
                </div>

                <WhiteCardComponent>
                  <span className="H3-Headline mb-4 pb-3">Date d'activation</span>
                  <DateAndTimeComponent
                    titleDate="Date de début*"
                    titleTime="Heure de début*"
                    status="active"
                    fieldValue={couponBody?.startAt}
                    setField={setCouponBody}
                    startOrEnd="startAt"
                  />
                  <div className="d-flex flex-row mt-4  mb-4">
                    <input
                      className="input-style-checkbox"
                      type="checkbox"
                      id="checkBtn"
                      checked={checked}
                      onChange={() => setChecked(!checked)}
                    />
                    <label htmlFor="checkBtn" />
                    <span className="body-sm ms-2">Ajouter une date de fin</span>
                  </div>
                  {checked && (
                    <DateAndTimeComponent
                      titleDate="Date de fin*"
                      titleTime="Heure de fin"
                      status="active"
                      fieldValue={couponBody.expireAt}
                      setField={setCouponBody}
                      startOrEnd="expireAt"
                    />
                  )}
                </WhiteCardComponent>
              </div>
              <div>
                <hr style={{ color: "#cacbce" }} />
              </div>
            </div>
            <div className="w-100 d-flex flex-column mt-4" style={{ maxWidth: 384 }}>
              <WhiteCardComponent>
                <span className="H4-Subtitle mb-4">Récap</span>
                <div className=" d-flex flex-column  flex-wrap pb-2 ">
                  {couponBody.promotion?.promoCode === "" ? (
                    <div className="body-md neutral-2">Pas de code du coupon pour l’instant</div>
                  ) : (
                    <span className="body-xl bold">{couponBody.promotion?.promoCode}</span>
                  )}
                </div>
                <div className="body-md bold">Détails</div>
                <div className="mt-1">
                  {couponBody?.promotion?.promoCode === "" ||
                    couponBody.promotion?.promoValue === "" ||
                    couponBody.startAt === null ? (
                    <span className="neutral-2">...</span>
                  ) : (
                    <ul>
                      {couponBody?.promotion?.promoValue && (
                        <li className="body-md neutral-2">
                          Réduction de {couponBody?.promotion?.promoValue}{" "}
                          {couponBody?.promotion?.promoType === "DISCOUNT" ? "%" : "euro"}
                        </li>
                      )}
                      {couponBody?.startAt !== null && (
                        <li className="body-md neutral-2">
                          Actif à partir du {moment(couponBody?.startAt).locale(i18n.language).format("Do MMMM YYYY")} à{" "}
                          {String(moment(couponBody?.startAt).format("HH:mm"))}
                        </li>
                      )}
                      {couponBody?.expireAt !== null && (
                        <li className="body-md neutral-2">
                          Jusqu'au {String(moment(couponBody?.expireAt).locale(i18n.language).format("Do MMMM YYYY"))} à{" "}
                          {String(moment(couponBody?.expireAt).format("HH:mm"))}
                        </li>
                      )}
                    </ul>
                  )}
                </div>
              </WhiteCardComponent>
              <WhiteCardComponent>
                <span className="H4-Subtitle mb-4">Utilisations</span>
                <div className=" d-flex flex-column  flex-wrap pb-2 ">
                  <span className="body-md neutral-2">La réduction n’est pas encore activée</span>
                </div>
              </WhiteCardComponent>
            </div>
          </div>
        </div>
      </div>



      <Snackbar open={snackBarOpen} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <div style={{ marginTop: 50 }}>
          <Alert severity="success" onClose={handleClose}>
            Modifications enregistrées !
          </Alert>
        </div>
      </Snackbar>


    </>
  );
}

const mapStateToProps = (state: AppState) => ({
  user: state.authReducer.connectedUser,
  couponStatus:state.formReducer.couponStatus
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      getCouponsByProductAndPromoCode,
      createCoupon

    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateCouponsComponent);
